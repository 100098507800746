.internal-login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	width: 100vw;
	background: #edeff3 url('../../assets/bg-providers.svg') center/100%;
	color: #262837;

	.form-wrapper {
		width: 384px;
		min-height: 544px;
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
		background-color: white;
		padding: 16px 25px;
		margin: 0 auto;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.btn-submit {
		display: block;
		background-color: #5B43BD;
		width: 100%;
		min-width: 160px;
		height: 48px;
		line-height: 48px;
		padding: 0 30px;
		color: white;
		border-radius: 4px;
		border: none;
		font: normal bold 16px/1.38 'RionaSans-Bold', Arial, Helvetica, sans-serif;
		text-transform: capitalize;
		margin-top: 24px;
		margin-bottom: 24px;
		cursor: pointer;
		-webkit-font-smoothing: antialiased;
	}

	.login-title {
		font: normal 500 24px/1.42 'RionaSans-Medium', Arial, Helvetica, sans-serif;
		margin: 24px 0 4px;
		display: inline-block;
		color: #262837;
		-webkit-font-smoothing: antialiased;
	}

	.login-desc {
		font: normal 14px / 1.43 'RionaSans', Arial, Helvetica, sans-serif;
		text-align: center;
		color: #262837;
		margin-bottom: 24px;
	}

	.genome-logo {
		display: inline-block;
		background-image: url('../../logo.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 126px;
		height: 63px;
	}

	.error-container {
		flex-grow: 1;
		display: flex;
		align-items: center;
	}

	.error-message {
		width: 320px;
		margin: 0 auto;
		background-color: #f4a4a5;
		border-radius: 4px;
		padding: 8px 16px;
		align-items: flex-start;
		display: flex;
		gap: 8px;

		color: #400404;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		p {
			margin: 0px;
		}

		.title {
			margin-bottom: 0px;
			font-weight: 700;
		}
	}

	.form-group {
		width: 100%;
		margin-bottom: 8px;
	}

	.label-field {
		font: normal 500 14px/1.43 'RionaSans-Medium', Arial, Helvetica, sans-serif;
		color: #262837;
		margin-top: 8px;
		margin-bottom: 7px;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		max-width: 100%;
	}

	input {
		height: 40px;
		padding: 3px 8px;
		font-family: 'RionaSans', Arial, Helvetica, sans-serif;
		font-size: 16px !important;
		border: 1px solid #bac3ce;
		color: #262837;

		&::placeholder {
			font-size: 16px !important;
		}
	}
}
