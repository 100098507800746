.expandedSearchbarContainer {
	height: 36px;
	max-width: 200px;
	border-radius: 36px;
	border: 1px solid #d8dde4;
	background-color: white;
	transition: width 0.2s ease-out;
	padding: 8px 6px 6px 16px;
	display: flex;
	overflow: hidden;
	margin-left: 8px;
	cursor: pointer;
}

.expandedSearchbarContainer:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.expandedSearchbarContainerActive {
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3) !important;
}

.expandedSearchbarInput {
	width: 0px;
	height: 18px;
	font-size: 13px;
	font-family: 'RionaSans-Medium';
	color: #262837;
	margin-right: 0px;
	padding: 0px;
	border: none;
	opacity: 1;
	transition: width 0.5s, margin-right 0.5s, opacity 0.3s;
}

.expandedSearchbarInput::placeholder {
	font-size: 13px !important;
	font-family: 'RionaSans-Medium';
	color: #a0abb9;
	-webkit-font-smoothing: antialiased;
}

.expandedSearchBarXIcon {
	position: relative;
	bottom: 3px;
	right: 4px;
	transform: scale(0.63);
	height: 24px;
	width: 24px;
	border-radius: 12px;
}

.expandedSearchBarXIcon:hover {
	background-color: #edeff3;
}

.expandedSearchBarXIcon:active {
	background-color: #d8dde4;
}

.expandedSearchBarXIcon > div > svg {
	position: relative;
	bottom: 1px;
	right: 1px;
}

.expandedSearchBarXIcon > div > svg > g > g {
	fill: #262837;
}

.retractedSearchbarContainer {
	height: 36px;
	width: 36px;
	border-radius: 36px;
	border: 1px solid #d8dde4;
	background-color: white;
	cursor: pointer;
	margin-right: 8px;
	transition: width 0.5s;
}

.retractedSearchbarContainer:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.retractedSearchBarSearchIcon > div > svg {
	position: relative;
	top: 7px;
	left: 10px;
}

.retractedSearchBarSearchIcon > div > svg > path {
	fill: #262837;
}

.singleSelectContainer,
.multi-select-checkbox-container,
.dateRangeContainer {
	position: relative;
}
.dateRangeContainer {
	margin-left: 8px;
}

.selectPillContainer {
	height: 36px;
	min-width: 36px;
	border-radius: 36px;
	border: 1px solid #d8dde4;
	background-color: white;
	cursor: pointer;
	margin-left: 8px;
	padding: 9px 12px;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: #262837;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.selectPillContainer:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.selectPillContainer:active,
.selectPillContainerActive {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.selectPillArrow {
	position: relative;
	bottom: 2px;
	width: 20px;
	height: 20px;
}

.selectPillArrow > div > svg {
	transform: scale(0.7);
}

.selectPillArrow > div > svg > g > g {
	fill: #262837;
}

.selectPillText {
	max-width: 200px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre;
	user-select: none;
}

.singleSelectModalContainer,
.multi-select-checkbox-modal-container {
	position: absolute;
	top: 40px;
	right: 8px;
	width: 368px;
	max-height: 520px;
	border-radius: 4px;
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	background-color: white;
	z-index: 10;
}

.patient-state-select-dropdown .multi-select-checkbox-modal-container,
.referral-programs-select-dropdown .multi-select-checkbox-modal-container {
	left: 8px;
}

.moreFiltersModalContainer {
	position: absolute;
	top: 40px;
	right: 8px;
	width: 536px;
	max-height: 520px;
	border-radius: 4px;
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	background-color: white;
	z-index: 10;
}

.moreFiltersModalContainerWide {
	width: 630px;
}

.singleSelectModalTopRow,
.multiSelectCheckboxModalTopRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #edeff3;
	padding-left: 16px;
	height: 40px;
}

.moreFiltersModalTopRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #edeff3;
	padding-left: 16px;
	height: 56px;
}

.singleSelectModalTopRowTitle,
.multiSelectCheckboxModalTopRowTitle {
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	color: #262837;
	padding-top: 2px;
}

.moreFiltersModalTopRowTitle {
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	padding-top: 2px;
}

.singleSelectModalXIcon,
.multiSelectCheckboxModalXIcon {
	width: 40px;
	height: 40px;
	border-top-right-radius: 4px;
	cursor: pointer;
}

.singleSelectModalXIcon:hover,
.multiSelectCheckboxModalXIcon:hover {
	background-color: #edeff3;
}

.singleSelectModalXIcon:active,
.multiSelectCheckboxModalXIcon:active {
	background-color: #d8dde4;
}

.singleSelectModalXIcon > div > svg > g > g > path,
.multiSelectCheckboxModalXIcon > div > svg > g > g > path {
	fill: #262837;
}

.singleSelectModalXIcon > div > svg,
.multiSelectCheckboxModalXIcon > div > svg {
	position: relative;
	top: 6px;
	left: 6px;
	transform: scale(0.75);
}

.moreFiltersModalXIcon {
	width: 56px;
	height: 54px;
	border-top-right-radius: 4px;
}

.moreFiltersModalXIcon:hover {
	background-color: #edeff3;
}

.moreFiltersModalXIcon:active {
	background-color: #d8dde4;
}

.moreFiltersModalXIcon > div > svg > g > g > path {
	fill: #262837;
}

.moreFiltersModalXIcon > div > svg {
	position: relative;
	top: 14px;
	left: 16px;
	transform: scale(1);
}

.singleSelectModalBodyContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-bottom: 2px solid #edeff3;
	max-height: 432px;
	overflow-y: auto;
	padding: 8px 6px;
}

.multi-select-checkbox-modal-body-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	border-bottom: 2px solid #edeff3;
	max-height: 432px;
}

.multi-select-checkbox-grouping {
	margin-top: 16px;
	margin-bottom: 8px;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	color: #262837;
	padding-left: 20px;
}

.multi-select-checkbox-selected {
	width: 100%;
	/* display: flex; */
	flex-direction: column;
	overflow-y: auto;
}

.multi-select-checkbox-selected .multi-select-checkbox-selected-header {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	margin: 12px 12px 8px;
	text-transform: uppercase;
}

.multi-select-checkbox-selected > .multi-select-checkbox-selected-item {
	margin: 0 12px 4px 12px;
	border-radius: 4px;
	border: solid 1px #bd92e4;
	background-color: #ddcaef;
	display: flex;
	align-items: center;
	width: fit-content;
}

.multi-select-checkbox-selected
	> .multi-select-checkbox-selected-item
	> .multi-select-checkbox-selected-item-name {
	margin: 2px 2px 3px 4px;
	font-family: RionaSans-Medium;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: left;
	color: #491678;
	max-width: 156px;
	word-break: break-word;
}

.multi-select-checkbox-x-icon,
.multi-select-checkbox-x-icon > div,
.multi-select-checkbox-x-icon > div > svg {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.multi-select-checkbox-x-icon > div > svg > g > g {
	fill: #491678;
}

.multi-select-checkbox-actions {
	width: 100%;
	border-right: 1px solid #edeff3;
}

.multi-select-checkbox-actions > fieldset {
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	max-height: 384px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.multi-select-checkbox-actions
	> div:not(.multi-select-checkbox-modal-row-select-all) {
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	max-height: 384px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.moreFiltersModalBodyContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-bottom: 2px solid #edeff3;
	max-height: 600px;
	overflow-y: auto;
	padding: 22px 0;
}

.moreFiltersSubGroup {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 16px 0;
}

.moreFiltersSubTitle {
	font-family: 'RionaSans-Bold';
	font-size: 14px;
	color: #262837;
	-webkit-font-smoothing: antialiased;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
}

.moreFiltersSubTitle > span {
	margin-right: 24px;
}

.moreFiltersOptions > div {
	max-height: 32px;
	display: inline-block;
	padding: 8px 16px;
	border-radius: 24px;
	border: solid 1px #edeff3;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	color: #262837;
	margin-right: 8px;
	margin-bottom: 8px;
	cursor: pointer;
}

.moreFiltersOptions > div.selected {
	border: solid 1px #e2d3f2;
	background-color: #e2d3f2;
	color: #491678;
}

.moreFiltersOptions > div:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.moreFiltersOptions > div:active {
	border: solid 1px #e2d3f2;
	background-color: #e2d3f2;
	color: #491678;
}

.moreFiltersDateRangePicker {
}

.singleSelectModalRow,
.multi-select-checkbox-modal-row {
	width: 100%;
	min-height: 48px;
}

.moreFiltersSubGroup > .singleSelectModalRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.multi-select-checkbox-modal-row.chargeICDSearchResultRow {
	border-top: unset;
	cursor: pointer;
}

.singleSelectModalRow:not(.rowVariantRadioButton):hover,
.multi-select-checkbox-modal-row:hover {
	background-color: #edeff3;
}

.singleSelectRadioLabel,
.multiSelectCheckboxLabel {
	font-size: 14px !important;
	color: #262837;
	font-weight: 500;
	line-height: 1.3;
	font-family: 'RionaSans-Medium';
}

.singleSelectRadioRow,
.multiSelectCheckboxRow {
	padding-left: 11px;
	margin: 0px !important;
}

.singleSelectRadioButton {
	padding: 0px !important;
	padding-left: 3px !important;
	height: 12px !important;
	margin-right: 16px !important;
}

.singleSelectRadioButton:hover {
	color: #7b26c9 !important;
}

.singleSelectRadioButton > span > svg {
	width: 20px !important;
	height: 20px !important;
}

.singleSelectBottomRowContainer,
.multiSelectBottomRowContainer {
	min-height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border-top: 2px solid #edeff3;
	align-items: center;
	padding: 0px 8px;
}

.moreFiltersBottomRowContainer {
	height: 60px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border-top: 2px solid #edeff3;
	align-items: center;
	padding: 2px 14px;
}

.singleSelectReset,
.multiSelectReset {
	font-family: 'RionaSans-Medium';
	color: #7b26c9;
	font-size: 13px;
	cursor: pointer;
	width: 50px;
}

.singleSelectReset:hover,
.multiSelectReset:hover {
	text-decoration: underline;
}

.singleSelectSave,
.multiSelectSave {
	color: white;
	background-color: #7b26c9;
	border-radius: 4px;
	width: 160px;
	height: 32px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	padding-top: 7px;
	cursor: pointer;
	user-select: none;
}

.singleSelectSave:hover {
	background-color: #9c5dd7;
}

.singleSelectSave:active {
	background-color: #621ea0;
}

.singleSelectSaveDisabled,
.singleSelectSaveDisabled:hover,
.singleSelectSaveDisabled:active,
.multiSelectSaveDisabled,
.multiSelectSaveDisabled:hover,
.multiSelectSaveDisabled:active {
	background-color: #7b26c9;
	opacity: 0.35;
	cursor: no-drop;
}

.multi-select-search-container {
	height: 48px;
	/* margin-bottom: 8px; */
	border-bottom: 1px solid #edeff3;
}

.multi-select-search-row-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
}

.multi-select-search-bar-container {
	width: 67%;
	border-radius: 4px;
	height: 32px;
	background-color: #edeff3;
	padding: 8px 5px 7px 8px;
	display: -ms-flexbox;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0 16px;
}

.multi-select-search-input {
	border: none;
	background-color: transparent;
	width: calc(100% - 5px);
	font-size: 12px;
}

.multi-select-search-input::placeholder {
	font-size: 12px !important;
	color: #7b8291;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
}

.multi-select-search-clear,
.multi-select-search-clear > div,
.multi-select-search-clear > div > svg {
	width: 28px;
	height: 28px;
	cursor: pointer;
}

.multi-select-search-clear > div > svg > g > g {
	fill: #262837;
}

.multi-select-search-toggle {
	font-family: 'RionaSans-Medium';
	font-size: 12px;
	color: #7b26c9;
	white-space: pre;
	position: relative;
	cursor: pointer;
	margin-left: 4px;
}

.multi-select-search-toggle:hover {
	text-decoration: underline;
}

.customTick {
	width: 18px;
	height: 18px;
	margin: 3px;
	border: 1px solid;
	border-radius: 3px;
	padding: 1px;
}

.customTick > div {
	height: 15px;
	width: 16px;
	position: relative;
	bottom: 1px;
	right: 1px;
}

/* Date ranger picker styling */

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> div.el-date-range-picker__header
	> button.el-picker-panel__icon-btn::before {
	color: #7b26c9 !important;
}

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> div.el-date-range-picker__header
	> div {
	font-family: 'RionaSans-Medium' !important;
	-webkit-font-smoothing: antialiased !important;
	font-size: 14px !important;
	color: black !important;
}

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> table
	> tbody
	> tr
	> th {
	font-family: 'RionaSans' !important;
	font-size: 12px !important;
	color: #262837 !important;
}

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> table
	> tbody
	> tr
	> td.available.in-range {
	background-color: #e2d3f2;
}

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> table
	> tbody
	> tr
	> td.available.in-range {
	background-color: #e2d3f2 !important;
}

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> table
	> tbody
	> tr
	> td.available.in-range.start-date,
.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> table
	> tbody
	> tr
	> td.available.in-range.end-date {
	background-color: #7b26c9 !important;
}

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> table
	> tbody
	> tr
	> td.available.in-range.today:not(.end-date):not(.start-date) {
	color: #7b26c9 !important;
}

.el-date-range-picker
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__body
	> div.el-picker-panel__content
	> table
	> tbody
	> tr
	> td.available.in-range.today:not(.end-date):not(.start-date)::before {
	border-top: 0.5em solid #7b26c9 !important;
}

/* start: reset filter */
.reset-filter-container {
	display: flex;
	height: 36px;
	width: 36px;
	padding: 8px 6px 6px 16px;
	margin-left: 8px;
	border-radius: 36px;
	border: 1px solid #d8dde4;
	background-color: white;
	transition: width 0.2s ease-out;
	overflow: hidden;
	cursor: pointer;
}

.reset-filter-container:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.reset-filter-container:active {
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.reset-filter-container .reset-filter-icon {
	position: relative;
	bottom: 2px;
	right: 10px;
	transform: scale(0.8, 0.8);
}
/* end: reset filter */
