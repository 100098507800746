.insurance-card-container {
	padding: 20px;
	display: flex;
	width: 100%;
	height: 78px;
	border-radius: 8px;
	background-color: rgba(169, 225, 246, 0.25);
	align-items: center;
	.card-display-btn {
		background: transparent;
		border: none;
		color: #49c0ef;
		font-family: RionaSans-Bold;
		font-size: 14px;
	}
	.card-numdetails {
		margin-left: 12px;
		font-family: RionaSans;
		font-size: 14px;
		color: #2a2d3e;
		span.card_name {
			font-family: RionaSans-Bold;
		}
	}
	.insurance-carddetail-leftpart {
		display: flex;
		flex: 1;
		align-items: center;
		img {
			width: 56px;
			height: 30px;
			border: solid 1px #c2cad4;
		}
	}
}
