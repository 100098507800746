@use '../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.container {
		display: flex;
		align-items: flex-start;
		.buttons {
			display: flex;
			align-items: center;
			gap: system.$singleUnit;

			.export-button {
				user-select: none;
				&.disabled {
					opacity: 0.35;
					text-decoration: none;
					cursor: no-drop;
				}
			}
		}
	}
}
