.reschedule-proceed-dialog,
.reschedule-reason-dialog {
	padding: 20px;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: #ffffff;
}

.reschedule-proceed-dialog {
	width: 400px;
}

.reschedule-proceed-dialog .reschedule-proceed-note {
	font: normal bold 12px RionaSans;
	color: #7a8292;
	margin-bottom: 12px;
}

.title-new-appointment {
	font: normal bold 14px RionaSans-Bold;
	color: #262838;
}

.reschedule-proceed-dialog .consultation-type {
	font: normal 500 12px RionaSans;
	color: #262838;
	margin-top: 4px;
}

.reschedule-proceed-dialog .encounter-timestamp {
	font: normal normal 12px RionaSans;
	color: #262837;
}

.reschedule-proceed-dialog .encounter-state-visit {
	font-weight: bold;
	font-family: RionaSans-Bold;
}

.reschedule-proceed-dialog .specialty {
	font: normal normal 12px RionaSans;
	color: #262838;
}

.reschedule-proceed-dialog .provider-img {
	border-radius: 4px;
	margin-right: 4px;
	width: 20px;
	height: 20px;
	border: 1px solid #d4dfe7;
}

.reschedule-proceed-dialog .modality-container .modality-icon .videoic > div,
.reschedule-proceed-dialog .modality-container .modality-icon .videoic svg {
	height: 18px;
	width: 18px;
}

.reschedule-proceed-dialog
	.modality-container
	.modality-icon
	.videoic
	svg
	path.GMIPhoneIcon {
	fill: #7b8291;
}

.reschedule-proceed-dialog
	.modality-container
	.modality-icon
	.videoic
	svg
	path.GMIVideoIcon {
	fill: #000000;
}

.reschedule-proceed-dialog .providerinfo-container,
.reschedule-proceed-dialog .modality-container {
	display: flex;
	width: 50%;
	align-items: center;
}

.reschedule-proceed-dialog .provider-name {
	font: normal 500 11px RionaSans;
	color: #262837;
}

.reschedule-proceed-dialog .modality-icon img {
	height: 18px;
	width: 18px;
}

.reschedule-proceed-dialog .modality-text {
	font: normal 500 12px RionaSans;
	color: #262837;
	text-transform: capitalize;
}

/* start: override element react style */
.reschedule-proceed-dialog .el-dialog__header,
.reschedule-reason-dialog .el-dialog__header{
	padding: 0;
}
.reschedule-proceed-dialog .el-dialog__title {
	display: flex;
	justify-content: left;
	margin-right: 5px;
}
.reschedule-proceed-dialog .el-dialog__title .warning-image {
	margin-right: 5px;
}
.reschedule-proceed-dialog .el-dialog__title .warning-image > div,
.reschedule-proceed-dialog .el-dialog__title .warning-image > div > svg {
	height: 19px;
	width: 19px;
}
.reschedule-proceed-dialog .el-dialog__title,
.reschedule-reason-dialog .el-dialog__title{
	font: normal bold 16px RionaSans-Bold;
	color: #26273a;
}

.reschedule-proceed-dialog .el-dialog__body,
.reschedule-reason-dialog .el-dialog__body {
	padding: 0;
	margin-top: 19px;
}

.reschedule-reason-dialog .el-dialog__body .el-radio-group {
	display: flex;
	flex-direction: column;
}

.reschedule-reason-dialog .el-dialog__body .el-radio-group .el-radio {
	width: 160px;
}

.reschedule-reason-dialog .el-dialog__body .el-radio__inner {
	width: 12px;
	height: 12px;
	border-color: #a0abba;
	background: transparent;
}

.reschedule-reason-dialog .el-dialog__body .el-radio__inner::after {
	background-color: #20a0ff;
}

.reschedule-reason-dialog .el-dialog__body .el-radio__label {
	font: normal 13px RionaSans;
	color: #262837;
}

.reschedule-reason-dialog .el-dialog__body .el-radio + .el-radio {
	margin-left: 0;
}


.reschedule-proceed-dialog .el-dialog__footer,
.reschedule-reason-dialog .el-dialog__footer {
	display: flex;
	justify-content: space-between;
	padding: 0;
	margin-top: 20px;
}

.reschedule-proceed-dialog .el-dialog__footer .el-button--default,
.reschedule-reason-dialog .el-dialog__footer .el-button--default {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border-radius: 6px;
	border: 1px solid #7b8291;
	font: normal 500 13px RionaSans-Medium;
	color: #7b8291;
}

.reschedule-proceed-dialog .el-dialog__footer .el-button--default:hover,
.reschedule-reason-dialog .el-dialog__footer .el-button--default:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.reschedule-proceed-dialog .el-dialog__footer .el-button--primary,
.reschedule-reason-dialog .el-dialog__footer .el-button--primary {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border-radius: 6px;
	background-color: #40b9ec;
	border: none;
	margin-left: 0;
	font: normal 500 13px RionaSans-Medium;
	color: #ffffff;
}

.reschedule-proceed-dialog .el-dialog__footer .el-button--primary:hover,
.reschedule-reason-dialog .el-dialog__footer .el-button--primary:hover {
	background-color: #70cbf1;
}

.reschedule-proceed-dialog .el-dialog__footer .el-button--default {
	width: 46px;
	height: 36px;
}

.reschedule-reason-dialog .el-dialog__footer .el-button--default {
	width: 70px;
	height: 36px;
}

.reschedule-proceed-dialog .el-dialog__footer .el-button--primary {
	width: 98px;
	height: 36px;
}

.reschedule-reason-dialog .el-dialog__footer .el-button--primary {
	width: 96px;
	height: 36px;
}
/* end: override element react style */

/* appointment alert box dailog css start*/
.reschedule-proceed-dialog.appointment-alert {
	width: 368px;
	padding: 0px;
}

.reschedule-proceed-dialog.appointment-alert
	.el-dialog__header
	.el-dialog__title {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.reschedule-proceed-dialog.appointment-alert .el-dialog__header,
.reschedule-proceed-dialog.appointment-alert .el-dialog__body,
.reschedule-proceed-dialog.appointment-alert .el-dialog__footer {
	padding-left: 20px;
	padding-right: 20px;
}
.reschedule-proceed-dialog.appointment-alert .el-dialog__header {
	padding-top: 18px;
	padding-bottom: 18px;
}

.reschedule-proceed-dialog.appointment-alert .el-dialog__body {
	border-top: solid 1px #d8dde4;
	border-bottom: solid 1px #d8dde4;
	padding-top: 32px;
	padding-bottom: 32px;
	margin: 0px;
}
.reschedule-proceed-dialog.appointment-alert .reschedule-proceed-note {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}
.reschedule-proceed-dialog.appointment-alert .el-dialog__footer {
	padding-top: 12px;
	padding-bottom: 12px;
	margin: 0px;
}

.reschedule-proceed-dialog.appointment-alert
	.el-dialog__footer
	.el-button--primary {
	width: 160px;
	background-color: #7b26c9;
	border-radius: 6.5px;
}
.reschedule-proceed-dialog.appointment-alert
	.el-dialog__footer
	.el-button--default {
	width: 160px;
	color: #7b26c9;
	border-radius: 6.5px;
	border: solid 1px #7b26c9;
}

/* appointment alert box dailog css style end */
