.inputFieldContainer {
	position: relative;
	display: flex;
	flex-direction: column;
}

.inputFieldHeader {
	font-size: 12px;
	color: #262837;
}

.inputFieldRequired {
	font-family: RionaSans-Bold;
	font-size: 12px;
	color: #e74c3c;
	position: absolute;
	top: 0;
	left: -6px;
}

.inputFieldRecommended {
	font-family: RionaSans-Bold;
	font-size: 12px;
	color: #7b8291;
	position: absolute;
	top: 0;
	left: -6px;
}

.inputField {
	border-radius: 4px;
	color: #262837;
	margin-bottom: 19px;
}

.inputFieldError {
	font-family: 'RionaSans-Medium';
	font-size: 10px;
	color: #e74c3c;
	position: absolute;
	top: 48px;
	left: 1px;
	-webkit-font-smoothing: antialiased;
}

.inputFieldText,
div.el-input.encounterFieldText > input.el-input__inner,
.erFieldText > div.el-input > input.input__inner,
.erFieldText.is-disabled > input.el-input__inner,
div.el-input.erFieldText > input.el-input__inner,
.inputFieldDatePicker.el-date-editor.is-have-trigger
	> div.el-input.el-date-editor
	> input.el-input__inner {
	height: 32px;
	width: 200px;
	border: 1px solid #bac3ce;
	padding-left: 8px;
	font-size: 13px !important;
	font-family: 'RionaSans';
	border-radius: 4px;
}

.erFieldTextWider.is-disabled > input.el-input__inner,
.erFieldTextWider > div.el-input > input.el-input__inner,
div.el-input.erFieldTextWider > input.el-input__inner,
.inputFieldDatePickerWider.el-date-editor.is-have-trigger
	> div.el-input.el-date-editor
	> input.el-input__inner {
	width: 100% !important;
}

div.el-input.encounterFieldText > input.el-input__inner,
.erFieldText > div.el-input > input.el-input__inner,
div.erFieldText.el-input.inputFieldTextTaller > input.el-input__inner,
.inputFieldTextTaller,
.inputFieldDatePickerTaller.el-date-editor.is-have-trigger
	> div.el-input.el-date-editor
	> input.el-input__inner {
	width: 100%;
	height: 36px;
}

.inputFieldText:hover {
	border: solid 1px #70cbf1;
}

.inputFieldText:focus {
	border: solid 1.5px #40b9ec;
}

.inputFieldTextArea {
	border-radius: 4px;
	color: #262837;
	margin-bottom: 19px;
	height: 88px;
	border: 1px solid #bac3ce;
	padding: 8px;
	font-size: 13px !important;
	resize: none;
	overflow: auto;
}

.inputFieldTextArea:hover {
	border: 1px solid #70cbf1 !important;
}

.inputFieldTextArea:focus {
	border: 1px solid #40b9ec !important;
	outline: unset !important;
}

.inputFieldText::placeholder,
.inputFieldTextArea::placeholder,
.inputFieldDropdown > div.el-input > input.el-input__inner::placeholder,
div.el-input.encounterFieldText > input.el-input__inner::placeholder,
.erFieldText > div.el-input > input.el-input__inner::placeholder,
div.erFieldText.el-input > input.el-input__inner::placeholder,
.rdt > input::placeholder,
.inputFieldDatePicker.el-date-editor.is-have-trigger
	> div.el-input.el-date-editor
	> input.el-input__inner::placeholder {
	font-family: 'RionaSans' !important;
	font-size: 13px !important;
	color: #bac3ce !important;
	-webkit-font-smoothing: antialiased;
	opacity: 1 !important;
}

.inputFieldDropdown {
}

.inputFieldDropdown > div.el-input {
	height: 32px;
}

.inputFieldDropdown > div.el-input > input.el-input__inner {
	font-size: 13px !important;
	height: 32px;
	font-family: 'RionaSans' !important;
}

.inputFieldDropdownTaller > div.el-input > input.el-input__inner {
	height: 36px !important;
}

.inputFieldDropdown > div.el-input > input.el-input__inner:hover {
	border: solid 1px #70cbf1;
}

.inputFieldDropdown > div.el-input > input.el-input__inner:focus {
	border: solid 1.5px #40b9ec;
}

.inputFieldDropdown
	> div.el-select-dropdown
	> .el-scrollbar
	.el-select-group__title {
	color: #262837;
	font-family: 'RionaSans-Bold' !important;
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
}

.inputFieldTextErrorState,
.inputFieldDateError > input,
.inputFieldDateTimeError > input,
.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-textarea__inner {
	border: 1px solid #fb3a2e !important;
	color: #fe5c50 !important;
	background-color: hsla(4, 98%, 77%, 0.5);
}

.inputFieldTextErrorState::placeholder,
.inputFieldDateError > input::placeholder,
.inputFieldDateTimeError > input::placeholder,
.encounterFieldTextErrorState > input::placeholder,
.el-form-item.is-error
	> div
	> div.erFieldText
	> input.el-input__inner::placeholder {
	color: #fe5c50 !important;
	opacity: 0.65 !important;
}

.inputFieldDropdownError > div.el-input > input.el-input__inner {
	border: 1px solid #fb3a2e !important;
	color: #fe5c50 !important;
	background-color: rgba(254, 145, 137, 0.5);
}

.inputFieldDropdownError > div.el-input > input.el-input__inner::placeholder,
.el-form-item.is-error
	> div
	> div.inputFieldDropdown
	> div
	> input.el-input__inner::placeholder {
	color: #fe5c50 !important;
	opacity: 0.65 !important;
}

.encounterFieldDropdown > div.el-input {
	height: unset !important;
}

.encounterFieldDropdown > div.el-input > input.el-input__inner {
	font-size: 13px !important;
	height: 36px !important;
}

.oneQuarterWidth {
	width: calc(25% - 8px);
}

.oneQuarterAbsoluteWidth {
	width: 92px;
}

.twoQuarterWidth {
	width: calc(50% - 16px);
}

.threeQuarterWidth {
	width: calc(75% - 8px);
}

.fourQuarterWidth,
.fullWidth {
	width: 100%;
}

.singleSelectPill {
	height: 32px;
	border-radius: 32px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	padding: 7px 16px 7px 16px;
	margin-right: 8px;
	user-select: none;
	cursor: pointer;
}

.singleSelectPillSelected {
	border: 1px solid #e2d3f2;
	background-color: #e2d3f2;
	color: #491678;
}

.singleSelectPillUnselected {
	border: 1px solid #edeff3;
	background-color: white;
	color: #262837;
}

.singleSelectPill:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.formMultiselect > div.el-input > input.el-input__inner {
	min-height: 32px !important;
	max-height: 60px !important;
	overflow-y: auto;
}

.formMultiselect > div.el-input > input.el-input__inner:hover {
	border: solid 1px #70cbf1;
}

.formMultiselect > div.el-input > input.el-input__inner:active {
	border: solid 1.5px #40b9ec;
}

.formMultiselect > div.el-input > input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.formMultiselect > div.el-select__tags {
	top: unset !important;
	transform: unset !important;
	max-height: 60px;
	overflow-y: auto;
}

.formMultiselect > div.el-select__tags > .el-tag {
	margin: 7px 0px 3px 6px;
}

.formMultiselect > div.el-select__tags > div.el-input {
	max-height: 60px;
}

.formMultiselect
	> div
	> span.el-tag.el-tag--primary
	> i.el-tag__close.el-icon-close {
	top: unset;
	margin-top: unset;
}

.inputFieldCascader > div.el-cascader-menus > ul > li {
	font-size: 13px !important;
}

.inputFieldCascader > div.el-cascader-menus > ul > li.is-active {
	background-color: #1c8de0 !important;
}

.inputFieldCascader > span > div.el-input > span.el-cascader__label,
.inputFieldCascader > span > span.el-cascader__label > label {
	font-size: 13px !important;
	font-weight: unset !important;
}

.inputFieldCascader > span > div.el-input > input.el-input__inner::placeholder {
	font-size: 13px !important;
	opacity: 0.5 !important;
	color: #262837 !important;
}

.prependedSelect > div.el-input-group__prepend {
	/* padding: 0px; */
}

.simpleCheckboxOption {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 4px;
	margin-top: 4px;
}

.simpleCheckboxOptionLabel {
	font-size: 12px;
}

/******************************************************************
            Fill Purple Button Element React Style Override
*******************************************************************/
.gmi-fill-purple-button.el-button.is-disabled {
	background-color: #7b26c9 !important;
	opacity: 0.35;
	border: unset;
}

.gmi-fill-purple-button.el-button.is-disabled:focus {
	background-color: #7b26c9 !important;
}

.gmi-fill-purple-button.el-button.is-disabled:hover {
	background-color: #9c5dd7 !important;
}

/*****************************************************************
            Element React Textarea Style Override
******************************************************************/
.gmi-el-textarea .el-textarea__inner:hover {
	border-color: #70cbf1;
	color: #262837 !important;
}

.cfdna-cancer-associated-container .modal-body .el-row {
	margin-bottom: 19px;
}
