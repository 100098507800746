@use '../../../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.gm-form-field {
		font-size: 14px;
	}

	.section-title {
		font-family: RionaSans-Bold;
		font-size: 16px;
		color: #525968;
	}

	.separator {
		margin-bottom: system.$singleUnit;
		margin-top: system.$oneHalfUnit;
	}

	.payment-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.payment-details {
		display: flex;
		align-items: center;
	}

	.payment-text {
		font-size: 14px;
		line-height: 110%;
	}

	.payment-value {
		font-family: RionaSans-Bold;
		font-size: 28px;
		line-height: 110%;
		margin-left: 16px;
	}

	.btn-add-coupon-code {
		font-size: 14px;
		line-height: 16.8px;
		border: none;
		color: #5b43bd;
		background: none;
		cursor: pointer;
		padding: 0;
		padding-left: 15px;
	}

	.patient-account-link {
		display: flex;
		align-items: center;
		gap: system.$halfUnit;
		margin-top: system.$singleUnit;

		.link-icon {
			height: 16px;
			svg {
				height: 16px;
				width: 16px;
				path:not([fill='none']) {
					fill: #5b43bd;
				}
			}
		}
		& > * {
			color: #5b43bd;
			font-size: 16px;
			font-weight: 500;
		}
	}

	.payment-section {
	}
	.submit-button {
		margin-top: system.$singleUnit;
	}
}
