.request-another-test-button-wrapper {
	margin-right: 18px;
}

.request-another-test-button-wrapper .request-another-test-button-container {
	height: 36px;
	border-radius: 6.5px;
	border: solid 1px #7b26c9;
	background-color: #ffffff;
	padding-right: 18px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.request-another-test-button-wrapper
	.request-another-test-button-container:hover {
	background-color: rgba(123, 38, 201, 0.15);
}
.request-another-test-button-wrapper
	.request-another-test-button-container:active {
	background-color: rgba(123, 38, 201, 0.3);
}
.request-another-test-button-wrapper
	.request-another-test-button-container:focus {
	background-color: rgba(123, 38, 201, 0.3);
	border: solid 2px rgba(123, 38, 201, 0.5);
}

.request-another-test-button-wrapper
	.request-another-test-button-container
	.request-another-test-button
	.request-another-img {
	width: 28px;
	height: 28px;
	margin-left: 8px;
}

.request-another-test-button-wrapper
	.request-another-test-button-container
	.request-another-test-button
	.request-another-text {
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #7b26c9;
	margin-left: 8px;
}

.request-another-test-button-wrapper.disable-request-another-test {
	opacity: 0.5;
	cursor: not-allowed;
}

.request-another-test-button-wrapper.disable-request-another-test
	.request-another-test-button-container {
	pointer-events: none;
}
