.user-delete-dialog {
	z-index: 100000 !important;
	position: fixed !important;
	width: inherit !important;
}

.user-delete-dialog .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.user-delete-dialog .el-dialog__body {
	padding: 16px;
}

.user-delete-dialog .el-dialog__footer {
	padding: 12px 16px;
	border-top: 1px solid #edeffe;
	display: flex;
	justify-content: space-between;
}

.user-delete-dialog .el-dialog__body .dob {
	margin-top: 19px;
}

.user-delete-dialog .chk-confirm-delete-user {
	margin-top: 19px;
	display: inline-flex;
	white-space: inherit;
}

.user-delete-dialog .chk-confirm-delete-user .el-checkbox__label {
	padding-left: 9px !important;
	font: normal 13px/1.38 RionaSans-Medium;
}

.user-delete-dialog .el-tooltip__popper {
	text-align: left;
}

.user-delete-dialog .el-tooltip__rel div {
	pointer-events: none;
}

.user-delete-dialog .title {
	color: #464646;
	font-size: 1.31vw;
	margin-bottom: 5px;
}

.user-delete-dialog .error-title {
	color: #e74c3c;
}

.user-delete-dialog .error-summary {
	background: #e74c3c;
	color: #ffffff;
	padding: 0.87vw;
	text-align: center;
}

.user-delete-dialog .error-image {
	border-radius: 1.09vw;
	height: 1.61vw;
	border: solid 0.07vw #ffff;
	margin-right: 0.21vw;
}

.user-delete-dialog #patient-form-dialog-title {
	padding: 0 !important;
}

.user-delete-dialog .dialog-content {
	font-size: 0.87vw;
}

.user-delete-dialog .encounters {
	margin-right: 20%;
}

.user-delete-dialog .el-dialog__footer button {
	font: normal 500 13px/1.38 RionaSans-Medium;
	border-radius: 6px;
	height: 36px;
	width: 140px;
}

button.delete-user,
button.delete-user:hover {
	background: #e74c3c;
	color: #ffffff;
	text-transform: none;
	box-shadow: none;
	border: 0.07vw solid #ccc;
}

button.cancel-user,
button.cancel-user:hover {
	background: #ffffff;
	color: #979197;
	text-transform: none;
	box-shadow: none;
	border: 1px solid #ccc;
}

.dialog-content fieldset textarea {
	font-size: 0.87vw;
	font-family: 'RionaSans', serif !important;
	line-height: 1.5vw;
}

.clear {
	clear: 'both';
}

.el-notification__content {
	text-align: initial !important;
}

.delete-reason-label {
	font-weight: normal;
}

.delete-reason-label.required::before {
	color: #e74c3c;
	content: "*";
	margin-left: -3px;
}

.el-notification {
	font-family: 'RionaSans';
}

button.delete-user,
button.delete-user:hover {
	background: #e74c3c;
	color: #ffffff;
	text-transform: none;
	box-shadow: none;
	border: 0.07vw solid #cccccc;
}

button.cancel-patient,
button.cancel-patient:hover,
button.cancel-patient:active,
button.cancel-patient:focus {
	background: #ffffff;
	color: #979197;
	text-transform: none;
	box-shadow: none;
	border: 1px solid #cccccc;
}

.delete-user.is-disabled,
.delete-user.is-disabled:hover,
.delete-user:active,
.delete-user:focus {
	opacity: 0.45;
	background: #f26058 !important;
	cursor: not-allowed;
	color: #ffffff;
}
