.billing-segment-wrapper .encntr-link-related-info-container .body {
	padding: 16px 0px 20px 0px !important;
	display: flex;
	flex-wrap: wrap;
}
.billing-segment-wrapper
	.encntr-link-related-info-container
	.body
	.patient-card-wrapper:not(:last-child) {
	margin-right: 0;
}
.billing-segment-wrapper
	.encntr-link-related-info-container
	.patient-card-wrapper {
	max-width: 263px;
	width: 100%;
	flex: 0 50%;
	padding: 8px 8px 4px 8px;
}
.billing-segment-wrapper
	.encntr-link-related-info-container
	.patient-card-wrapper
	.patient-card {
	max-width: 263px;
	width: 100%;
}
