.api-error-modal .header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #d8dde4;
	padding: 16px 16px 13px 16px;
}

.api-error-modal .el-dialog__header {
	padding: 0px;
}

.api-error-modal .header .close-icon {
	cursor: pointer;
}

.api-error-modal .header .warning-icon {
	margin-right: 10px;
}

.api-error-modal .header .warning-icon .GMIWarningCircleIcon {
	fill: red;
}

.api-error-modal .header .error-title {
	flex-grow: 1;
}
