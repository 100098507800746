.extrnl-dashbrd-graphs-wrapper .encounter-charts-wrapper .topsection {
}
.extrnl-dashbrd-graphs-wrapper .encounter-charts-wrapper .chartfiltersection {
	margin-top: 24px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard {
	width: calc((100% - 22.5px) / 4);
	margin-left: 7.5px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	padding: 8px 8px;
	display: flex;
	flex-direction: column;
	height: 85px;
	cursor: pointer;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard:first-child {
	margin-left: 0px;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.titlerow {
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	margin-top: 8px;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.titlerow
	span {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: left;
	color: #525968;
	-webkit-font-smoothing: antialiased;
	white-space: pre-wrap;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.datarow {
	margin-top: 7px;
	display: flex;
	justify-content: space-between;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.datarow
	.total {
	font-family: RionaSans-Bold;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.1;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.datarow
	.change
	img {
	height: 10px;
	width: 10px;
	margin-bottom: 3px;
	margin-right: 1px;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.datarow
	.change
	img.down {
	transform: rotate(90deg);
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.datarow
	.change {
	font-family: RionaSans-bold;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	-webkit-font-smoothing: antialiased;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.titlerow
	span.check {
	margin-left: 5px;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.check.getstarted
	.el-checkbox__inner {
	border: 1px solid #bd92e4;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard:active
	.check.getstarted
	.el-checkbox__inner {
	border: 1px solid #7b26c9;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.check.getstarted
	.el-checkbox__inner::after {
	border: 1.5px solid #7b26c9 !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 8px !important;
	width: 3px;
	left: 4px !important;
	top: 0px !important;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.check.ror
	.el-checkbox__inner {
	border: 1px solid #9fdcf5;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard:active
	.check.ror
	.el-checkbox__inner {
	border: 1px solid #40b9ec;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.check.ror
	.el-checkbox__inner::after {
	border: 1.5px solid #40b9ec !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 8px !important;
	left: 4px !important;
	width: 3px;
	top: 0px !important;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.check.other
	.el-checkbox__inner {
	border: 1px solid #a0abb9;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard:active
	.check.other
	.el-checkbox__inner {
	border: 1px solid #262837;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.check.other
	.el-checkbox__inner::after {
	border: 1.5px solid #262837 !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 8px !important;
	left: 4px !important;
	width: 3px;
	top: 0px !important;
}

.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.datarow
	.change.green {
	color: #00d65b;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard
	.datarow
	.change.red {
	color: #e74c3c;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard:hover {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard:focus,
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard:active {
	border: solid 2px #e2d3f2;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard-readonly,
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard-readonly:hover,
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard-readonly:focus,
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.filtercard-readonly:active {
	box-shadow: none !important;
	border: solid 1px #edeff3 !important;
	cursor: auto;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.disabled-filtercard,
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.disabled-filtercard:hover,
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.disabled-filtercard:focus,
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.disabled-filtercard:active {
	box-shadow: none !important;
	border: solid 1px #edeff3 !important;
	cursor: not-allowed !important;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.datarow
	.gray {
	color: #bac3ce !important;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-charts-wrapper
	.chartfiltersection
	.titlerow
	.is-disabled
	.el-checkbox__inner {
	background: #edeff3 !important;
}
.extrnl-dashbrd-graphs-wrapper .encounter-charts-wrapper .bottomsection {
}
.extrnl-dashbrd-graphs-wrapper .headerow {
	margin-top: 17px;
}
.extrnl-dashbrd-graphs-wrapper .headerow h3 {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	margin-bottom: 0px;
	-webkit-font-smoothing: antialiased;
}

/* scorecard section*/

.extrnl-dashbrd-graphs-wrapper .headerow h5 {
	width: 50px;
	height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #a0abb9;
	margin-top: 0px;
}
