@media screen and (min-width: 1025px) and (max-width: 1190px) {
	.appointmentschedule-insurancesection
		.appointmentschedule-insurancedetail
		.health-insurance-part3
		.insuranceadd_width
		.el-input__inner {
		width: 692px !important;
	}
	.appointmentschedule-insurancesection
		.appointmentschedule-insurancedetail
		.health-text-container,
	.appointmentschedule-insurancesection
		.appointmentschedule-insurancedetail
		.health-container
		.full-width-company
		input.el-input__inner {
		width: 512px !important;
	}
	.appointmentschedule-insurancesection
		.appointmentschedule-insurancedetail
		.health-other-textbox,
	.appointmentschedule-insurancesection
		.appointmentschedule-insurancedetail
		.health-other-textbox
		input.el-input__inner {
		width: 365px !important;
	}

	.appointmentschedule-insurancesection
		.appointmentschedule-insurancedetail
		.health-insurance-part2
		.el-input__inner,
	.appointmentschedule-insurancedetail
		.health-insurance-part4
		.el-input__inner,
	.appointmentschedule-insurancedetail
		.health-insurance-part6
		.insurance-state-select
		.el-input__inner,
	.appointmentschedule-insurancedetail
		.health-insurance-part7
		.el-input__inner,
	.appointmentschedule-companydetail .insuranceid-block .el-input__inner {
		width: 160px !important;
	}
	.appointmentschedule-insurancedetail .insurancebox {
		width: 160px !important;
	}
	.appointmentschedule-insurancedetail
		.insurancedetail-part1
		.el-input__inner,
	.appointmentschedule-insurancedetail .insurancedetail-part1 .insurancebox {
		width: 320px !important;
	}
	.appointmentschedule-insurancedetail .insurancedetail-part2 .insurancebox {
		width: 152px !important;
	}
	.appointmentschedule-insurancedetail
		.insurancedetail-part2
		.el-input__inner {
		width: 145px !important;
	}
	input {
		font-size: 13px !important;
	}
}

.insurancebox
	> div
	> div.insurance-state-select.el-select
	> div.el-input
	> input::placeholder,
.insurancebox > div.health-text-container > div > input::placeholder,
.schedulingPaymentInput > input::placeholder,
.health-insurance-select > div.el-input > input::placeholder,
.insurancedetail-part1
	> div.insurancebox
	> div
	> div.el-input
	> input::placeholder,
.insurancedetail-part2
	> div.insurancebox
	> div
	> div.el-input
	> input::placeholder,
.insurancedetail-part1
	> div.insurancebox
	> div
	> div
	> div
	> input::placeholder,
.insurancedetail-part2
	> div.insurancebox
	> div
	> div
	> div
	> input::placeholder {
	font-size: 13px !important;
}
