.outreach-group-hdr-wrapper {
	display: flex;
	margin: 34px 0 8px;
	align-items: center;
}

.outreach-group-hdr-wrapper .divider-horizotal-line {
	flex-grow: 1;
	height: 1px;
	background-color: #edeff3;
	margin: 0 8px;
}

.outreach-group-hdr-wrapper .divider-dot {
	margin: 0 8px 5px;
	font: normal bold 13px/1.38 RionaSans-Bold;
	color: #7b8291;
}

.outreach-group-hdr-wrapper .btn-disable {
	pointer-events: none;
	opacity: 0.45;
}

.outreach-group-hdr-wrapper:first-child {
	margin-top: 0;
}

.outreach-group-hdr-wrapper .outreach-group-hdr {
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #262837;
	margin-right: 5px;
}

.outreach-group-hdr-wrapper .btn-stop-outreach .el-button {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #e74c3c;
}

.outreach-group-hdr-wrapper .btn-stop-outreach .el-button.is-disabled {
	background-color: transparent !important;
	border-color: transparent;
	padding: 0;
	opacity: 0.45;
}

.outreach-group-hdr-wrapper .btn-add-outreach.el-button.is-disabled {
	background-color: transparent !important;
	border-color: transparent;
	padding: 0;
	opacity: 0.45;
	color: #7b26c9;
	text-decoration: none;
}

.outreach-group-hdr-wrapper .btn-stop-outreach .el-button.is-disabled:hover {
	color: #e74c3c;
}

.outreach-group-hdr-wrapper .btn-stop-outreach .el-button .el-icon-caret-bottom {
	position: relative;
	bottom: -8px;
	font-size: 20px;
	font-weight: 700;
}

.outreach-group-hdr-wrapper .btn-stop-outreach .el-button .el-icon-caret-bottom:before {
	content: '\02C7';
}

.outreach-group-hdr-wrapper .btn-stop-outreach .el-dropdown-menu {
	width: 160px;
	margin: 0;
	padding: 2px 0;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 35%);
}

.outreach-group-hdr-wrapper .btn-stop-outreach .el-dropdown-menu li {
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.outreach-group-hdr-wrapper .btn-add-outreach {
	margin-top: 4px;
}

.outreach-group-hdr-wrapper .button-text {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	padding: 0;
}

.outreach-group-hdr-wrapper .button-text:hover,
.outreach-group-hdr-wrapper .button-text:active,
.outreach-group-hdr-wrapper .button-text:focus {
	text-decoration: underline;
	color: #7b26c9;
}

.outreach-group-hdr-wrapper .link-mark-fu-declined {
	margin-left: auto;
}

.outreach-group-hdr-wrapper .link-mark-fu-declined {
	margin-left: auto;
	color: #e74c3c;
}

.outreach-group-hdr-wrapper .link-mark-fu-declined:hover,
.outreach-group-hdr-wrapper .link-mark-fu-declined:active,
.outreach-group-hdr-wrapper .link-mark-fu-declined:focus {
	margin-left: auto;
	color: #e74c3c;
}

.outreach-group-encntrtest-wrapper {
	display: flex;
	margin: 12px 0 16px;
	align-items: center;
}

.outreach-group-encntr-detail,
.outreach-group-test-detail {
	display: flex;
	flex-flow: column nowrap;
}

.outreach-group-encntr-detail {
	margin-right: 16px;
	padding-right: 16px;
	border-right: 1px solid #edeff3;
}

.outreach-group-encntr-detail .lable,
.outreach-group-test-detail .lable {
	font: normal 12px/1.42 RionaSans;
	color: #262837;
}

.outreach-group-encntr-detail .encntr-info,
.outreach-group-test-detail .test-name {
	font: normal 500 14px/1.43 RionaSans-Medium;
	color: #262837;
}

.outreach-group-subtitle {
	margin: -8px 0 8px 0;
}

.outreach-group-subtitle .label {
	font: normal 700 12px RionaSans-Medium;
	color: #525968;
}