.chargeModalContainer {
	width: 650px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: fixed;
	min-height: 150px;
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
}

.chargeModalContainer .exceptionLoading {
	border-radius: 4px;
}

.chargeModalTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 9px 16px;
	height: 58px;
	border-bottom: 1.5px solid #edeff3a2;
}

.chargeModalHeaderBreadcrumbs {
	display: flex;
	flex-direction: column;
}

.chargeModalHeader {
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #262837;
	margin-bottom: 2px;
}

.chargeModalBreadcrumbs {
	display: flex;
	flex-direction: row;
	align-content: center;
	margin: 0px 1px;
	font-size: 12px;
	color: #bac3cd;
}

.chargeModalBreadcrumbsCurrent {
	color: #262837 !important;
}

.chargeModalBreadcrumbArrow {
	transform: scale(0.65);
	position: relative;
	bottom: 6px;
}

.chargeModalClose {
	transform: scale(1.4);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	top: 8px;
	cursor: pointer;
}

.chargeModalClose:hover {
	background-color: #ececec;
}

.chargeModalClose:active {
	background-color: #c7c7c7;
}

.chargeModalClose > div > div > svg > g > g {
	fill: black !important;
}

.chargeICDContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 175px);
	overflow-y: auto;
}

.chargeICDContainer::-webkit-scrollbar-corner {
	background-color: #bac3ce !important;
}

.chargeICDSearchAndSelectContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: calc(100% - 60px);
}

.chargeICDSearchContainer {
	width: 60%;
	background-color: white;
	position: relative;
}

.chargeICDSearchRowContainer {
	width: calc(100% - 1px);
	padding-right: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 8px;
	height: 48px;
}

.chargeICDSearchRowContainerLessPadding {
	padding-right: 16px;
}

.chargeICDSearchToggle {
	font-family: 'RionaSans-Medium';
	font-size: 12px;
	color: #7b26c9;
	margin: 0px 12px;
	white-space: pre;
	position: relative;
	top: 7px;
	left: 5px;
	cursor: pointer;
}

.chargeICDSearchToggle:hover {
	text-decoration: underline;
}

.chargeICDSearchBarContainer {
	width: calc(100% - 32px);
	border-radius: 4px;
	height: 32px;
	background-color: #edeff3;
	padding: 8px 5px 7px 8px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 8px;
	margin-left: 16px;
	margin-bottom: 8px;
}

.refProgMultiselectSearchbarContainer {
	margin-bottom: 0px !important;
}

.chargeICDSearchInput {
	border: none;
	background-color: transparent;
	width: calc(100% - 5px);
	font-size: 12px;
}

.chargeICDSearchInput::placeholder {
	font-size: 12px !important;
	color: #7b8291;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
}

.chargeICDSearchIcon > div > svg > path {
	fill: #7b8291;
}

.chargeICDSearchXIcon {
	cursor: pointer;
}

.chargeICDSearchXIcon > div > svg > g > g {
	fill: #262837;
}

.chargeICDFilterContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-evenly;
	padding: 0px 8px;
	margin-bottom: 6px;
}

.chargeICDFilterHeader {
	font-family: 'RionaSans-Medium';
	font-size: 12px;
	color: #525968;
	margin: 9px 0px 2px 18px;
	-webkit-font-smoothing: antialiased;
}

.chargeICDFilterContainer > .simpleCheckboxOption > .el-checkbox {
	margin-right: 5px !important;
}

.chargeICDFilterContainer > .simpleCheckboxOption > .simpleCheckboxOptionLabel {
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
}

.chargeICDSearchResultsContainer {
	width: 100%;
	max-height: calc(100% - 49px);
	/* margin-top: 8px; */
	overflow-y: auto;
}

.chargeICDSearchPlaceholderContainer {
	width: 100%;
	height: calc(100% - 48px);
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 8px;
	overflow-y: auto;
	font-family: 'RionaSans-Medium';
	font-size: 15px;
	line-height: 1.4;
	color: #bac3ce;
	text-align: center;
}

.chargeICDSearchPlaceholderContainerLoading > div {
	z-index: 0 !important;
}

.chargeICDSearchPlaceholderIconAndText {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 227px;
	margin-top: calc(40% + 12px);
}
.chargeICDSearchPlaceholderIconAndText .placeholder-text {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: center;
	color: #a0abb9;
}

.chargeICDSearchResultRow {
	width: 100%;
	border-top: 1.5px solid #edeff3a2;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.chargeICDSearchResultRowReverse {
	flex-direction: row-reverse;
	justify-content: space-between;
	padding-left: 14px;
	flex-wrap: wrap;
}

.chargeICDSearchResultRowReverse > .chargeICDSearchResultRowTitle {
	font-size: 13px;
}

.chargeICDSearchResultRowAltCheckbox > .chargeICDSearchResultRowTitle {
	max-width: 170px;
	align-self: center;
	position: unset;
	min-height: unset;
	padding-top: unset;
}

.chargeICDSearchResultRowTextEnabled {
	padding-top: 8px;
}

.chargeICDSearchResultRowInput {
	width: calc(100% - 16px) !important;
	margin: 8px 16px 16px 2px;
}

.chargeICDSearchResultRow:hover {
	background-color: #edeff3;
	cursor: pointer;
}

.chargeICDSearchResultRowCheckbox {
	margin-left: 19px;
	margin-right: 19px;
	position: relative;
	top: 3px;
}

.chargeICDSearchResultRowDisabled {
	opacity: 0.5;
	cursor: no-drop !important;
}

.chargeICDSearchResultRowTitle {
	font-family: 'RionaSans-Medium';
	font-size: 14px;
	color: #262837;
	min-width: 79px;
	position: relative;
	top: 2px;
	min-height: 48px;
	padding-top: 13px;
}

.chargeICDSearchResultRowAltCheckboxContainer {
	display: flex;
	margin-right: 3px;
}

.chargeICDSearchResultRowAltCheckboxContainer > .simpleCheckboxOption {
	margin-right: 8px;
}

.chargeICDSearchResultRowAltCheckboxContainer
	> .simpleCheckboxOption
	> .el-checkbox {
	margin-right: 6px !important;
}

.chargeICDSearchResultRowDescription {
	font-family: 'RionaSans';
	line-height: 1.4;
	font-size: 10px;
	color: #7b8291;
	padding: 11px 10px 8px 0px;
}

.chargeICDSearchResultRowCheckbox
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 1.7px solid #7b26c9 !important;
	border-left: 0 !important;
	border-top: 0 !important;
}

.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 1.7px solid #7b26c9 !important;
	border-left: 0 !important;
	border-top: 0 !important;
}

label.el-checkbox.chargeICDSearchResultRowCheckbox
	> span.el-checkbox__input
	> span.el-checkbox__inner:hover,
label.el-checkbox.chargeICDSearchResultRowCheckbox
	> span.el-checkbox__input.is-focus
	> span.el-checkbox__inner {
	border-color: #9c5dd7 !important;
}

.chargeICDSearchResultRowSelectedText {
	color: #7b26c9 !important;
	font-family: 'RionaSans-Medium';
}

.chargeICDSelectedContainer {
	width: 40%;
	background-color: #edeff3;
	padding: 10px 16px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.chargeICDSelectedContainerHeader {
	font-family: 'RionaSans-Bold';
	font-size: 14px;
	color: #262837;
	margin-bottom: 8px;
}

.chargeICDSelectedTag {
	height: 20px;
	border-radius: 4px;
	border: 1px solid #bd92e4;
	background-color: #ddcaef;
	font-family: 'RionaSans-Medium';
	font-size: 11px;
	padding: 2px 3px 4px 4px;
	color: #491678;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0px 4px 4px 0px;
	width: min-content;
}

.chargeICDSelectedTagLongVariant {
	white-space: unset !important;
	height: unset !important;
	width: unset !important;
	justify-content: space-between;
}

.chargeICDSelectedTagX {
	height: 20px;
	width: 20px;
}

.chargeICDSelectedTagX > div > svg {
	height: 20px;
	width: 20px;
	position: relative;
	top: 0px;
	left: 3px;
	border-radius: 15px;
	cursor: pointer;
}

.chargeICDSelectedTagX > div > svg > g > g {
	fill: #491678;
}

.chargeICDBillingContainer {
	padding: 0px 0px 15px 0px;
	width: 100%;
	height: calc(100% - 58px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.chargeICDBillingFieldCollectionContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100% - 47px);
	max-height: calc(100% - 47px);
	overflow-y: auto;
	padding: 32px 15px 32px 15px;
}

.chargeICDBillingFieldContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 19px;
}

.chargeICDBillingRequiredField {
	color: red;
	font-size: 12px;
	font-family: 'RionaSans-Bold';
	top: 0px;
	left: -6px;
	position: absolute;
}

.chargeICDBillingTextField {
	font-size: 12px;
	color: #262837;
}

.chargeICDBillingTextFieldInput {
	width: 355px;
	height: 32px;
	border-radius: 4px;
	font-size: 14px !important;
	border: solid 1px #bac3ce;
	padding-left: 8px;
}

.chargeICDBillingTextFieldInput::placeholder {
	opacity: 0.5;
	font-size: 13px !important;
	color: #262837;
}

.chargeICDBillingTextFieldInput:hover {
	border: solid 1px #70cbf1 !important;
	outline: none;
}

.chargeICDBillingTextFieldInput:focus {
	border: solid 1.5px #40b9ec !important;
	outline: none;
}

.chargeICDBillingTextFieldError {
	position: absolute;
	top: 50px;
	left: 0px;
	font-size: 10px;
	color: red;
}

.chargeICDBillingTextFieldInputUneditable {
	height: 32px;
	width: 355px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: #edeff3;
	font-size: 13px;
	font-family: 'RionaSans';
	color: #bac3ce;
	padding: 7px 0px 0px 7px;
	cursor: no-drop;
	user-select: none;
}

.chargeModalICDButtonRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	position: absolute;
	bottom: 0px;
	right: 0px;
	padding: 12px 15px;
	font-size: 13px;
	color: white;
	border-top: 1.5px solid #edeff3a2;
}

.chargeModalICDButtonRowBilling {
	justify-content: space-between;
}

.chargeModalActionButton {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	text-align: center;
	padding-top: 9px;
	cursor: pointer;
	user-select: none;
	margin-left: auto;
}

.chargeModalActionButton:hover {
	background-color: #9c5dd7;
}

.chargeModalActionButton:active {
	background-color: #621ea0;
}

.chargeModalActionButtonDisabled,
.chargeModalActionButtonDisabled:hover,
.chargeModalActionButtonDisabled:active {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
}

.chargeModalBackButton {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: white;
	color: #7b26c9;
	text-align: center;
	border: 1px solid #7b26c9;
	padding-top: 9px;
	cursor: pointer;
	user-select: none;
}

.chargeModalBackButton:hover {
	background-color: rgba(123, 38, 201, 0.15);
}

.chargeModalBackButton:active {
	background-color: rgba(123, 38, 201, 0.3);
}
