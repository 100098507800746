.listModalContainer {
	width: 368px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-height: calc(100vh - 120px);
}

.listModalTopRowContainer {
	width: 100%;
	height: 56px;
	padding: 9px 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #edeff3;
}

.listModalTopRowTitleSubtitleContainer {
	display: flex;
	flex-direction: column;
}

.listModalTopRowTitle {
	font-family: 'RionaSans-Bold';
	font-size: 16px;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.listModalTopRowSubtitle {
	font-size: 12px;
	color: #262837;
	max-width: 310px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.listModalBodyContainer {
	width: 100%;
	max-height: calc(100vh - 180px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding: 16px;
}

.listModalBodyRow {
	font-size: 12px;
	color: #262837;
	margin-bottom: 8px;
}
