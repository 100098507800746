.na-slot {
	background-image: url('./diagonal-stripes.svg');
	background-size: 30px 30px;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.na-slot:hover .na-hover {
	display: flex;
}

.na-hover {
	display: none;
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 0.8;
	font-size: 11px;
	line-height: 12px;
	color: white;
	cursor: not-allowed;
}

.na-hover .na-text {
	display: inline-block;
	margin: auto;
	cursor: not-allowed;
}