.CTAWarningModalContainer {
	width: 368px;
	min-height: 200px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: calc(50vh - 100px);
	left: calc(50vw - 184px);
	overflow: hidden;
}

.CTAWarningModalTitleRow {
	width: 100%;
	height: 56px;
	padding: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 1px solid #edeff3;
}

.CTAWarningModalTitle {
	padding-top: 3px;
}

.CTAWarningModalBody {
	padding: 32px 16px;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	color: #262837;
	font-size: 13px;
}

.CTAWarningModalButtonRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
}
