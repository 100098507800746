.red-flag-audittrail-dialog {
	width: 512px !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	height: calc(100% - 160px);
	max-height: 568px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	-webkit-font-smoothing: antialiased;
}

/*************************************************************** 
                        Header  
***************************************************************/
.red-flag-audittrail-dialog .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.red-flag-audittrail-dialog .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.red-flag-audittrail-dialog .el-dialog__title .title-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.red-flag-audittrail-dialog .el-dialog__title .close-btn-icon {
	transform: scale(1.2);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	bottom: 2px;
	cursor: pointer;
}

.red-flag-audittrail-dialog .el-dialog__title .close-btn-icon:hover {
	background-color: #ececec;
}

.red-flag-audittrail-dialog .el-dialog__title .close-icon > div,
.red-flag-audittrail-dialog .el-dialog__title .close-icon > div > svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.red-flag-audittrail-dialog .el-dialog__title .close-icon svg .GMICloseIcon {
	fill: #262837;
}

/*************************************************************** 
                        Body  
****************************************************************/
.red-flag-audittrail-dialog .el-dialog__body {
	margin: 28px 0;
	padding: 0 16px !important;
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	height: calc(100% - 110px);
	overflow-y: auto;
}

.red-flag-audittrail-dialog .patient-info-container {
	display: flex;
	border-radius: 4px;
	padding: 12px;
	margin-bottom: 20px;
	background-color: #edeff3;
}

.red-flag-audittrail-dialog .contacts-icon {
	margin-right: 13px;
}

.red-flag-audittrail-dialog .patient-info-wrapper {
	display: flex;
	flex-direction: column;
	font: normal 12px/1.42 RionaSans;
}

.red-flag-audittrail-dialog .audittrail-flag-patient-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
	margin-bottom: 4px;
}

.red-flag-audittrail-dialog .patient-gender {
	text-transform: capitalize;
}

.red-flag-audittrail-dialog .refral-prgrm-val {
	font: normal bold 12px/1.42 RionaSans-Bold;
	margin-left: 8px;
}

/*************************************************************** 
                        red flag detail  
***************************************************************/
.red-flag-audittrail-dialog .red-flag-detail-container,
.red-flag-audittrail-dialog .green-flag-detail-container {
	border-radius: 8px;
	border: 1px solid #d8dde4;
	margin-bottom: 20px;
}

.red-flag-audittrail-dialog .red-flag-test-info-head,
.red-flag-audittrail-dialog .green-flag-test-info-head {
	border-radius: 8px 8px 0 0;
	background-color: #edeff3;
	width: 100%;
	display: flex;
	padding: 10px 16px 10px;
}

.red-flag-test-info-head .audittrail-red-flag-icon,
.green-flag-test-info-head .audittrail-green-flag-icon {
	margin-right: 8px;
	margin-top: 3px;
}

.red-flag-audittrail-dialog
	.red-flag-test-info-head
	.audittrail-red-flag-icon
	svg,
.red-flag-audittrail-dialog
	.green-flag-test-info-head
	.audittrail-green-flag-icon
	svg {
	height: 18px;
	width: 18px;
}

.red-flag-audittrail-dialog
	.red-flag-test-info-head
	.red-flag-test-info-wrapper,
.red-flag-audittrail-dialog
	.green-flag-test-info-head
	.green-flag-test-info-wrapper {
	display: flex;
	flex-direction: column;
}

.red-flag-audittrail-dialog .red-flag-test-info-head .test-name,
.red-flag-audittrail-dialog .green-flag-test-info-head .label-flag-update {
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.red-flag-test-info-head .lab-name,
.green-flag-test-info-head .created-name,
.green-flag-test-info-head .green-flag-created-at,
.red-flag-test-info-head .red-flag-created-at {
	font: normal 12px/1.42 RionaSans;
	color: #525968;
}

.red-flag-audittrail-dialog .red-flag-detail-container .flag-reason-list-body {
	padding: 16px 50px 24px;
}

.red-flag-audittrail-dialog
	.red-flag-detail-container
	.flag-reason-list-body
	ul {
	margin: 0;
	padding: 0;
}

.red-flag-audittrail-dialog
	.green-flag-detail-container
	.change-green-flag-body {
	padding: 16px 20px 24px;
}
