@use 'system';

header.page-header {
	padding: system.$oneHalfUnit system.$singleUnit;

	h1 {
		font-family: RionaSans-Bold;
		font-size: 23px;
		margin: 0px;
	}
}
