@use '../core' as core;

.#{core.$scopeClass} {
	.notes-and-documents {

		.two-col-div {
			@include core.twoColumnLayout;
		}
	}
}
