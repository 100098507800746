.doc-detail-header-section {
	padding: 16px 28px 20px;
	/* border-bottom: 1px solid #d7dde5; */
}

.doc-detail-header-section .doc-detail-toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.doc-detail-toolbar .doc-detail-toolbar-left .doc-detail-doc-name {
	font-family: RionaSans-Bold;
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
}

.doc-detail-doc-name {
	text-transform: capitalize;
}

.doc-detail-toolbar-right {
	display: flex;
	align-items: center;
}

.doc-detail-toolbar-right .icon-gap {
	margin: 0 4px;
}

.doc-detail-toolbar-right .icon-gap button {
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
}

.doc-detail-toolbar-right .icon-gap :disabled {
	cursor: not-allowed;
}

.doc-detail-toolbar-right .icon-gap :disabled img {
	opacity: 0.25;
}

.doc-detail-toolbar-right .mr-8 {
	margin-right: 8px;
}

.doc-detail-toolbar-right .doc-toolbar-button {
	width: 125px;
	height: 36px;
	background-color: #40b9ec;
	border-radius: 6px;
	border: none;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	text-align: left;
	padding: 0;
}

.doc-detail-toolbar-right .doc-toolbar-button:disabled {
	opacity: 0.35;
	cursor: not-allowed;
}

.doc-detail-toolbar-right
	button.el-button.el-button--default.is-disabled.finalize-dropdown {
	background-color: unset !important;
}

.doc-detail-toolbar-right .doc-toolbar-button .doc-button-check {
	margin: 0 8px;
}

.doc-detail-toolbar-right .doc-cloudicon {
}

.doc-detail-toolbar-right .doc-printicon {
	width: 28px;
	height: 28px;
}

.icon-divider {
	margin-right: 24px;
	margin-left: 24px;
	width: 1px;
	height: 36px;
	border-left: 1px solid #bac3ce;
}

.doc-detail-header-section .doc-detail-navigation {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #40b9ec;
	display: flex;
	height: 20px;
	cursor: pointer;
}

.doc-detail-header-section .doc-detail-navigation .material-icons {
	font-size: 16px;
	margin-left: -5px;
}

.doc-detail-body-section {
	background-color: #edeff3;
	position: relative;
}

.printBtnB {
	/*width: 32px;
    height: 33px;*/
	width: 100%;
	height: 62px;
	background-color: white;
	position: absolute;
	top: 2px;
	/*left: 77px;*/
	z-index: 1;
}

.printBtnB.preview {
	position: absolute;
	top: 8px;
	right: 12px;
	z-index: 1;
	height: 45px;
	width: 41px;
	background-color: #d1d1d1;
	border: none;
}

@media only screen and (max-width: 1330px) {
	.printBtnB.preview {
		top: 12px;
		right: 12px;
		background-color: #ffffff;
	}
}

.doc-detail-body-section iframe#google_docs_iframe {
	height: calc(100vh - 0px);
	border: none;
	/* box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.19); */
	background-color: #ffffff;
	margin: 0 auto;
	display: table;
}

.document-action-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 20px;
	padding-left: 20px;
}

.document-action-buttons-container {
	display: flex;
}

.documentSlidersContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}

.documentSliderLabel {
	font-size: 12px;
	color: #474e53;
	opacity: 0.82;
	margin-right: 8px;
	position: relative;
	top: 3px;
}

.el-switch,
.el-switch__label,
.el-switch__label * {
	font-size: unset !important;
}

.documentSliderContainer > label.el-switch {
	top: 3px !important;
	font-size: 10px !important;
	margin-bottom: 0px !important;
}

.documentSliderContainer
	> label.el-switch--wide
	> .el-switch__label.el-switch__label {
	top: 2px !important;
	font-size: 10px !important;
}

.documentSliderContainer
	> label.el-switch--wide
	> .el-switch__label.el-switch__label--left
	> span {
	left: 8px !important;
	top: 4px !important;
}

.documentSliderContainer
	> label.el-switch--wide
	> .el-switch__label.el-switch__label--right
	> span {
	right: 6px !important;
}

.documentListArchivedRow {
	cursor: no-drop;
}

.documentListArchivedRowText {
	opacity: 0.5;
}

.documentListArchivedRow:hover {
	background-color: unset !important;
}

.documentListArchivedRowFileName {
	color: #ff0000b8 !important;
	opacity: 0.5;
	/* opacity: 1.0; */
}

.documentSliderAsterisk {
	font-size: 13px;
	color: red;
	position: relative;
	top: 3px;
	right: 3px;
}

.archiveClassFileNameAsterisk {
	float: none;
	display: inline-block;
}

.documentAffiliationDocsContainer {
	margin-bottom: 28px;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 24px;
}

.documentAffiliationDocsHeader {
	margin-bottom: 13px;
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.38;
	color: #262837;
	text-transform: capitalize;
}

.documentAffiliationDocsRow {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.documentAffiliationDocsRow .LinkPopover,
.documentAffiliationDocsRow .LinkPopover .LinkPopoverContentMain {
	width: 100%;
	min-height: 85px;
}

.documentAffiliationDocContainer {
	width: 46%;
	/* min-width: 478px; */
	height: 100px;
	padding: 12px;
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
}

.documentAffiliationDocHeaderRow {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.documentAffiliationDocHeaderRowTitles {
	display: flex;
	flex-direction: column;
	max-width: calc(100% - 68px);
	overflow: hidden;
	text-overflow: ellipsis;
}

.documentAffiliationDocHeaderRowTitlesTitle {
	font-size: 14px;
	color: #525968;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.documentAffiliationDocHeaderRowTitlesSubheader {
	font-family: 'RionaSans-Bold';
	color: #525968;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.documentAffiliationDocHeaderRowButtons {
	display: flex;
	flex-direction: row;
}

.documentAffiliationDocHeaderRowButtons > div > div > svg > g > g {
	fill: #40b9ec;
}

.documentAffiliationDocDescriptionRow {
	font-size: 11px;
	color: #7a8193;
	max-width: 100%;
	max-height: 32px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.documentExpandArrow {
	cursor: pointer;
	height: 20px;
	width: 20px;
	float: right;
	position: relative;
	right: 0px;
	top: 0px;
}

.documentExpandArrow > div > svg > g > g {
	fill: #262837;
}

/* Create and Upload Documents */

/* Below is old document create */

.createDocumentContainer {
	width: 716px;
	height: 480px;
	position: absolute;
	top: calc(50vh - 240px);
	left: calc(50vw - 358px);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
	/* border: 1px solid #edeff3; */
	background-color: #ffffff;
	display: flex;
	flex-direction: row;
}

.createDocumentSelectionContainer {
	width: 288px;
	padding: 24px;
	display: flex;
	flex-direction: column;
}

.createDocumentHeader {
	font-size: 20px;
	font-family: 'RionaSans-Bold';
	color: #262838;
	margin-bottom: 32px;
}

.createDocumentInputContainer,
.finalize-amend-doc-input-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 19px;
	position: relative;
}

.createDocumentRequired,
.finalize-amend-doc-required {
	color: red;
	font-size: 15px;
	position: absolute;
	bottom: -20px;
	left: -7px;
	margin-right: 2px;
}

.createDocumentInputHeader,
.finalize-amend-doc-input-header {
	font-size: 12px;
	color: #525968;
}

.createDocumentInputContainer > div.el-select > div.el-select-dropdown,
.finalize-amend-doc-input-container > div.el-select > div.el-select-dropdown {
	top: unset !important;
	left: unset !important;
}

.createDocumentInputContainer
	> div.createDocumentInputSelect
	> div.el-input
	> input.el-input__inner,
.finalize-amend-doc-input-container
	> div.createDocumentInputSelect
	> div.el-input
	> input.el-input__inner {
	font-size: 13px !important;
	text-overflow: ellipsis;
}

.createDocumentInputSelect
	> div.el-select-dropdown
	> div.el-scrollbar
	> div.el-select-dropdown__wrap
	> ul.el-select-dropdown__list
	> li.el-select-dropdown__item {
	font-size: 13px !important;
}

.createDocumentInputContainer
	> .createDocumentInputSelect.createDocumentV2TypeSelect
	> div.el-cascader-menus
	> ul.el-cascader-menu,
.finalize-amend-doc-input-container
	> .createDocumentInputSelect.createDocumentV2TypeSelect
	> div.el-cascader-menus
	> ul.el-cascader-menu {
	max-height: 205px !important;
	height: unset !important;
}

.createDocumentInputSelect > div.el-input > input.el-input__inner,
.uploadDocumentDescription {
	transition: border-color 0.01s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.createDocumentInputSelect > div.el-input > input.el-input__inner:hover,
.uploadDocumentDescription:hover {
	border: solid 1px #70cbf1 !important;
}

.createDocumentInputSelect > div.el-input > input.el-input__inner::placeholder,
.uploadDocumentDescription::placeholder {
	font-size: 13px !important;
	opacity: 0.5 !important;
	color: #262837 !important;
}

.uploadDocumentDescription:focus {
	border: solid 1.5px #40b9ec !important;
	outline: unset !important;
}

.createDocumentButtonRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
}

.createDocumentCancelButton,
.createDocumentCreateButton {
	cursor: pointer;
	border-radius: 6px;
	padding: 9px 14px;
	width: 70px;
	height: 36px;
	font-size: 13px;
}

.createDocumentCancelButton {
	border: 1px solid #7b8291;
	color: #7b8291;
}

.createDocumentCreateButton {
	background-color: #40b9ec;
	color: white;
}

.createDocumentCreateButtonInactive {
	cursor: no-drop !important;
	opacity: 0.5;
	background-color: #7b26c9 !important;
}

.createDocumentTemplatesContainer {
	width: 428px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	background-color: #edeff3;
	position: relative;
}

.createDocumentTemplatesContainerSelectContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: calc(65% - 100px);
	left: calc(50% - 93.5px);
}

.createDocumentTemplatesContainerSelectText {
	font-family: 'RionaSans-Bold';
	font-size: 18px;
	color: #9eabbb;
}

.createDocumentTemplatesTableContainer {
	display: flex;
	flex-direction: column;
	max-height: 100%;
}

.createDocumentTemplatesSearchContainer {
	width: 380px;
	height: 38px;
	border-radius: 6px;
	border: 1px solid #d8dde4;
	background-color: #f6f7f9;
	margin-bottom: 34px;
	display: flex;
	flex-direction: row;
	padding: 9px 9px 9px 4px;
}

.createDocumentTemplatesSearchInput {
	background: none;
	border: none;
	font-size: 13px;
	width: inherit;
}

.createDocumentTemplatesSearchInput::placeholder {
	font-size: 13px !important;
}

.createDocumentTemplatesTableHeader {
	padding: 7px 15px;
	font-size: 13px;
	font-family: 'RionaSans-Bold';
	color: #525968;
	border-bottom: 2px solid #d8dde4;
	height: 32px;
	display: flex;
	flex-direction: row;
}

.createDocumentTemplatesSortIcon > div > svg > g > g {
	fill: #525968 !important;
}

.createDocumentsTemplatesList {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: auto;
	max-height: 100%;
}

.createDocumentTemplatesTableRow {
	padding: 17px 15px 11px 15px;
	font-size: 14px;
	color: #525968;
	/* height: 48px; */
	border-bottom: 1px solid #d8dde4;
	cursor: pointer;
}

.createDocumentTemplatesTableRow:hover {
	background-color: #dfe3ecd0;
}

.createDocumentTemplatesTableRowSelected {
	font-family: 'RionaSans-Bold';
	background-color: rgba(0, 187, 241, 0.115) !important;
}

/* Above is older document create */

/* Change related encounter */

.editDocumentContainer {
	width: 368px;
	min-height: 304px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
}
.editDocumentContainer .editDocumentBodyContainer {
	height: 100%;
	padding: 32px 16px 0px 16px;
}

.editDocumentContainer
	.editDocumentBodyContainer
	span.el-cascader
	> span
	> span.el-cascader__label
	> label {
	font-weight: 400;
	font-family: 'RionaSans';
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #262837;
	cursor: pointer;
}

.editDocumentBodyContainer
	span.el-cascader.is-opened.doc-type-input
	> div.el-cascader-menus
	> ul.el-cascader-menu
	> li.el-cascader-menu__item.is-active {
	background-color: #20a0ff !important;
	color: white !important;
}
.editDocumentBodyContainer
	span.el-cascader.is-disabled
	> span
	> span.el-cascader__label {
	cursor: no-drop !important;
}
.editDocumentBodyContainer
	span.el-cascader.is-disabled
	> span
	> span.el-cascader__label
	> label {
	cursor: no-drop !important;
	color: #bbb;
}
.editDocumentBodyContainer
	span.el-cascader.is-opened.doc-type-input
	> div.el-cascader-menus
	> ul.el-cascader-menu {
	max-height: 186px;
	min-width: 146px;
}
.editDocumentBodyContainer
	span.el-cascader.is-opened.doc-type-input
	> div.el-cascader-menus
	> ul.el-cascader-menu
	> li.el-cascader-menu__item {
	height: 29px;
	padding: 6px 10px;
	font-family: 'RionaSans';
	font-size: 13px;
	color: #262837;
}
.editDocumentBodyContainer
	span.el-cascader.is-opened.doc-type-input
	> div.el-cascader-menus
	> ul.el-cascader-menu
	> li.el-cascader-menu__item.is-active {
	background-color: #20a0ff !important;
	color: white !important;
}
/* Below is new document create */

.createDocumentContainerV2 {
	width: 520px;
	min-height: 150px;
	position: fixed;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.createDocumentV2TopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 9px 16px;
	height: 58px;
	border-bottom: 1.5px solid #edeff3a2;
}

.createDocumentV2HeaderBreadcrumbs {
	display: flex;
	flex-direction: column;
}

.createDocumentV2Header {
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #262837;
	margin-bottom: 2px;
	-webkit-font-smoothing: antialiased;
}

.createDocumentV2Breadcrumbs {
	display: flex;
	flex-direction: row;
	align-content: center;
	margin: 0px 1px;
	font-size: 12px;
	color: #bac3cd;
}

.createDocumentV2BreadcrumbsCurrent {
	color: #262837 !important;
}

.createDocumentV2BreadcrumbArrow {
	transform: scale(0.65);
	position: relative;
	bottom: 6px;
}

.createDocumentV2Close {
	transform: scale(1.4);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	cursor: pointer;
	position: relative;
	top: 7px;
}

.createDocumentV2Close:hover {
	background-color: #ececec;
}

.createDocumentV2Close:active {
	background-color: #c7c7c7;
}

.createDocumentV2Close > div > div > svg > g > g {
	fill: black !important;
}

.createDocumentV2TypeContainer {
	padding: 32px 16px 12px 16px;
	display: flex;
	flex-direction: column;
}

.createDocumentV2TypeSelect {
	width: 100%;
}

.uploadDocumentBody
	> .createDocumentInputContainer
	> span.el-cascader.createDocumentInputSelect,
.finalize-amend-doc-body
	> .finalize-amend-doc-input-container
	> span.el-cascader.createDocumentInputSelect {
	width: unset;
}

.createDocumentV2TypeSelect > div.el-cascader-menus > ul > li {
	font-size: 13px !important;
}

.createDocumentV2TypeSelect > div.el-cascader-menus > ul > li.is-active {
	background-color: #1c8de0 !important;
}

.createDocumentV2TypeSelect > span > div.el-input > span.el-cascader__label,
.createDocumentV2TypeSelect > span > span.el-cascader__label > label {
	font-size: 13px !important;
	font-weight: unset !important;
}

.createDocumentV2TypeSelect
	> span
	> div.el-input
	> input.el-input__inner::placeholder {
	font-size: 13px !important;
	opacity: 0.5 !important;
	color: #262837 !important;
}

.createDocumentV2TypeButtonRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 12px 16px;
	font-size: 13px;
	color: white;
	height: 60px;
	border-top: 1px solid #edeff3;
}

.createDocumentV2TypeButtonRow .verify-doc-buttons {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.createDocumentV2TypeButtonRow .verify-doc-buttons .cancel-verification {
	border-radius: 6.5px;
	width: 160px;
	height: 36px;
	border: solid 1px #7b26c9;
	color: #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: 0;
}
.createDocumentV2TypeButtonRow .verify-doc-buttons .cancel-verification:hover {
	border: solid 1px #7b26c9 !important;
	background-color: rgba(123, 38, 201, 0.15) !important;
}
.createDocumentV2TypeButtonRow .verify-doc-buttons .cancel-verification:active {
	border: solid 1px #7b26c9 !important;
	background-color: rgba(123, 38, 201, 0.3) !important;
}
.createDocumentV2TypeButtonRow .verify-doc-buttons .cancel-verification:focus {
	border: solid 2px rgba(123, 38, 201, 0.5) !important;
	background-color: rgba(123, 38, 201, 0.15) !important;
}

.createDocumentV2TypeButtonRow .verify-doc-buttons .verify-doc {
	color: white;
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	text-align: center;
	padding-top: 9px;
	cursor: pointer;
	border: solid 1px #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: 0;
}
.createDocumentV2TypeButtonRow .verify-doc-buttons .verify-doc:hover {
	background-color: #9c5dd7;
}
.createDocumentV2TypeButtonRow .verify-doc-buttons .verify-doc:active {
	background-color: #621ea0;
}

.createDocumentV2TypeContainerBody {
	overflow-y: auto;
	height: 514px;
	width: 100%;
}

.createDocumentRelatedEncounter {
	font-family: 'RionaSans-MediumItalic';
	font-size: 13px;
	color: #262837;
	margin-top: 19px;
	max-width: 350px;
}

.createDocumentV2ActionButton {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	text-align: center;
	padding-top: 9px;
	cursor: pointer;
}

.createDocumentV2ActionButton:hover {
	background-color: #9c5dd7;
}

.createDocumentV2ActionButton:active {
	background-color: #621ea0;
}

.createDocumentV2ActionButtonDisabled,
.createDocumentV2ActionButtonDisabled:hover,
.createDocumentV2ActionButtonDisabled:active {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
}

.createDocumentV2BackButton {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: white;
	color: #7b26c9;
	text-align: center;
	border: 1px solid #7b26c9;
	padding-top: 9px;
	cursor: pointer;
}

.createDocumentV2BackButton:hover {
	background-color: rgba(123, 38, 201, 0.15);
}

.createDocumentV2BackButton:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.createDocumentV2TemplateContainer {
	padding: 8px 16px 0px 16px;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 240px);
	overflow-y: auto;
	width: 100%;
}

.createDocumentV2TemplateSearchContainer {
	width: 100%;
	border-radius: 4px;
	height: 32px;
	background-color: #edeff3;
	padding: 8px 5px 7px 8px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.createDocumentV2TemplateSearchInput {
	border: none;
	background-color: transparent;
	width: calc(100% - 5px);
	font-size: 12px;
}

.createDocumentV2TemplateSearchInput::placeholder {
	font-size: 12px !important;
}

.createDocumentV2TemplateToggleRow {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0px 8px;
	align-items: center;
	font-size: 11px;
	color: #262837;
	margin-top: 2px;
	height: 44px;
}

.createDocumentV2TemplateRowsContainer {
	/* display: flex; */
	flex-direction: column;
	width: 100%;
	max-height: 425px;
	overflow-y: auto;
}

.createDocumentV2TemplateContainer .gmi-radio-group label.el-radio {
	width: 100%;
	border-top: 1.5px solid rgba(237, 239, 243, 0.6352941176470588);
	min-height: 48px;
	margin: 0;
	padding: 0;
}

.createDocumentV2TemplateContainer .gmi-radio-group {
	width: 98%;
}

.createDocumentV2TemplateContainer .gmi-radio-group .el-radio .el-radio__label {
	font-size: 14px !important;
	color: #262837;
	font-weight: 500;
	line-height: 1.3;
	font-family: 'RionaSans-Medium';
	margin: 0px 0px 0px 16px;
	padding: 0;
}

.createDocumentV2TemplateContainer
	.gmi-radio-group
	.el-radio__input.is-checked
	.el-radio__inner::after {
	height: 9px;
	width: 9px;
}

.createDocumentV2TemplateContainer
	.gmi-radio-group
	.el-radio__input
	.el-radio__inner {
	border: 2px solid rgba(0, 0, 0, 0.3);
}

.createDocumentV2TemplateContainer
	.gmi-radio-group
	.el-radio__input
	.el-radio__inner:hover {
	border: 2px solid #7b26c9;
}

.createDocumentV2TemplateContainer
	.gmi-radio-group
	.el-radio__input.is-checked
	.el-radio__inner {
	border: 2px solid #7b26c9;
}

.createDocumentV2TemplateContainer
	.gmi-radio-group
	.el-radio__input.is-checked
	+ .el-radio__label {
	color: #7b26c9;
}

.createDocumentV2TemplateRow {
	width: 100%;
	border-top: 1.5px solid #edeff3a2;
	min-height: 48px;
}

.documentRadioLabel {
	font-size: 14px !important;
	color: #262837;
	font-weight: 500;
	line-height: 1.3;
	font-family: 'RionaSans-Medium';
}

.documentRadioRow {
	padding-left: 11px;
	padding-top: 7px;
}

.documentRadioButton {
	padding: 0px !important;
	padding-left: 3px !important;
	height: 12px !important;
	margin-right: 16px !important;
}

.documentRadioButton:hover {
	color: #7b26c9 !important;
}

.documentRadioButton > span > svg {
	width: 20px !important;
	height: 20px !important;
}

/* Above is new document create */

.uploadDocumentContainer {
	max-height: calc(100vh - 120px);
	width: 368px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	border: 1px solid #edeff3;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	min-height: 164px;
}

.uploadDocumentHeader {
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	border-bottom: 1px solid #edeff3;
	padding: 8px 16px;
}

.uploadDocumentHeaderXIcon {
	transform: scale(1.38);
	position: relative;
	left: 8px;
}

.uploadDocumentBody {
	padding: 32px 16px 16px 16px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: calc(100vh - 240px);
}

.uploadDocumentDescription {
	width: 100%;
	height: 66px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	padding: 8px;
	resize: none;
	font-size: 13px;
}

.uploadDocumentDescription::placeholder {
	color: #97a8be;
}

.createDocumentTextArea {
	width: 355px !important;
}

.uploadDocumentUploadDropContainer {
	width: 100%;
	height: 120px;
	border-radius: 4px;
	border: 1px dashed #bac3ce;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.uploadDocumentUploadDropContainer:hover {
	border: 1px dashed #7b26c9;
}

.uploadDocumentUploadDropContainerDragActive {
	background-color: #f8f4fc;
	border: 2px dashed #7b26c9;
}

.uploadDocumentUploadDropContainerInactive {
	width: 100%;
	height: 120px;
	border-radius: 4px;
	border: 1px dashed #bac3ce;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	opacity: 0.35;
	cursor: no-drop;
}

.uploadDocumentUploadDropText {
	font-size: 12px;
	color: #7b8291;
	display: flex;
	flex-direction: row;
}

.uploadDocumentUploadDropTextPurple {
	color: #7b26c9;
	position: relative;
	left: 4px;
	cursor: pointer;
	font-family: 'RionaSans-Medium';
}

.uploadDocumentUploadDropTextPurple:hover {
	text-decoration: underline;
}

.fieldErrorMessageDocumentCreateUpload {
	bottom: -14px !important;
	left: 1px !important;
	position: absolute;
	color: #e74c3c;
	font-size: 10px;
}

.uploadDocumentButtonRow {
	padding: 11px 16px 12px 16px;
	width: 100%;
	border-top: 1px solid rgb(237, 239, 243);
	height: 60px;
}

.uploadDocumentButtonSave {
	margin-left: auto;
	height: 36px;
	padding-top: 9px;
}

.doc-detail-header-section .finalize-dropdown {
	width: 125px;
	height: 36px;
	border-radius: 4px;
	border: solid 1px #9eabbb;
	text-align: left;
	padding: 0;
	display: flex;
	align-items: center;
}

.doc-detail-header-section .finalize-dropdown .finaldoc-text {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7a8193;
	margin-left: 2px;
}

.doc-detail-header-section .el-dropdown-menu {
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: #ffffff;
	width: 125px;
	display: flex;
	align-items: center;
	height: 36px;
	margin-top: 4px;
}

.doc-detail-header-section .check-grayicon {
	margin-left: 6px;
}

.doc-detail-header-section .finaldoc-droparrow {
	position: absolute;
	right: 11px;
	top: 11px;
}

.doc-detail-header-section .el-dropdown-menu .el-dropdown-menu__item {
	padding: 0;
	width: 100%;
}

.doc-detail-header-section .finaldoc-dropmenutext {
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
}

.pencil-docicon {
	width: 22px;
	height: 22px;
	margin-left: 6px;
	margin-right: 3px;
}

.testRadioLabel {
	font-size: 13px !important;
	color: #262837 !important;
}

.testRadioRow {
	padding-left: 11px;
	padding-top: 4px;
}

.testRadioButton {
	padding: 0px !important;
	padding-left: 3px !important;
	height: 12px !important;
	margin-right: 4px !important;
}

.testRadioButton:hover {
	color: #40b9ec !important;
}

/* .testRadioButton[class*="MuiRadio-checked"] {
    color: #40b9ec !important;
} */

.testRadioButton > span > svg {
	width: 14px !important;
	height: 14px !important;
}

.archivePopupButton {
	background-color: #e74c3c;
	color: white;
	font-size: 13px;
	display: flex;
	flex-direction: row;
	border-radius: 5px;
	cursor: pointer;
	height: 36px;
	width: 123px;
	padding-top: 9px;
	padding-left: 13px;
}

.popupModal {
	width: 100vw;
	height: 100vh;
	z-index: 100000;
	background-color: rgba(0, 0, 0, 0.35);
	position: fixed;
	top: 0px;
	left: 0px;
}

.documentsTabModalContainer {
	width: 400px;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: calc(50% - 200px);
	top: calc(50% - 80px);
	padding: 20px;
}

.documentsTabModalHeaderRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #26273a;
	margin-bottom: 20px;
}

.documentsTabModalDescription {
	font-size: 12px;
	color: #7a8292;
	margin-bottom: 28px;
	width: 100%;
}

.documentsTabModalButtonRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.delete-button {
	height: 40px;
	padding: 4px 8px;
	border-radius: 4px;
	border: 1px solid #ddd;
	background-color: white;
	cursor: pointer;
	color: #dc2626;
	margin-left: auto;
	font-size: 14px;
	white-space: nowrap;
	display: flex;
	align-items: center;
	gap: 4px;
	transition: all 0.2s ease;
  }
  
.delete-button:hover {
	background-color: #fee2e2; /* Light red background on hover */
	border-color: #dc2626; /* Red border on hover */
  }
  
  /* Optional: if you want the icon to have a different color on hover */
  .delete-button:hover svg {
	color: #b91c1c; /* Darker red for the icon on hover */
  }

.archivePopupButton > div > div > svg > g > use,
.archivePopupButton > div > div > svg > g > g {
	fill: white;
}

.documentsTabModalCancelButton {
	width: 70px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #7b8291;
	background-color: white;
	cursor: pointer;
	padding-top: 9px;
	padding-left: 12px;
	font-size: 13px;
	color: #7b8291;
}

.amend-dailog .el-dialog {
	width: 400px;
	height: fit-content;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: #ffffff;
	padding: 20px;
	min-height: 194px;
}

.amend-dailog .el-dialog .el-dialog__header {
	padding: 0px;
}

.amend-dailog .el-dialog__title {
	color: #26273a;
	font-family: RionaSans-Bold;
	padding-bottom: 20px;
	display: block;
}

.amend-dailog .el-dialog__title img {
	margin-right: 4px;
	height: 19px;
	width: 19px;
}

.amend-dailog .el-dialog__body {
	padding: 0;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7a8292;
	padding-bottom: 28px;
	display: block;
}

.amend-dailog .el-dialog__footer {
	padding: 0;
	display: flex;
	justify-content: space-between;
}

.amend-dailog .cancel-btn {
	width: 70px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b8291;
	display: flex;
	justify-content: center;
	padding: 8px 0px 0px 0px;
}

.amend-dailog .cancel-btn span {
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7b8291;
	font-family: RionaSans-Medium;
}

.amend-dailog .edit-btn {
	width: 121px;
	height: 36px;
	border-radius: 6px;
	background-color: #f1c40f;
	display: flex;
	padding: 4px 0px 0px 5px;
	border: none;
	text-align: left;
	margin-left: 0 !important;
}

.amend-dailog .edit-btn span {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	display: flex;
	align-items: center;
}

.amend-dailog .edit-btn span .pencil-icon svg {
	display: flex;
	align-items: center;
	margin: 0 8px;
	height: 28px;
	width: 28px;
}

.inlineDocumentViewContainer {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
}

.inlineDocumentViewTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	padding: 12px 24px 0px 16px;
}

.inlineDocumentViewTitleArrow {
	display: flex;
	flex-direction: row;
	font-size: 14px;
	color: white;
	position: relative;
	top: 7px;
}

.inlineDocumentTooltip {
	position: relative;
}

.inlineDocumentViewSeparator {
	height: 34px;
	width: 1px;
	border: 1px solid rgb(186, 195, 206);
	margin: 0px 24px;
	position: relative;
	top: 3px;
}

.inlineDocumentViewButtons {
	display: flex;
	flex-direction: row;
	padding-right: 8px;
	position: relative;
	bottom: 0px;
}

.inlineDocumentViewButton {
	cursor: pointer;
	width: 28px;
	height: 28px;
	position: relative;
}

.inlineDocumentViewButton > div > svg > g > use {
	fill: white;
}

.inlineDocumentViewButtons > div > div.el-tooltip__popper.is-dark {
	top: unset !important;
}

.inlineDocumentViewGradientRow {
	width: 100%;
	height: 80px;
	background-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.8) 0%,
		rgba(0, 0, 0, 0)
	);
}

.inlineDocumentViewDocumentContainer {
	height: calc(100vh - 80px);
	/* margin: auto; */
	width: 100%;
	position: absolute;
	bottom: 20px;
	overflow-y: scroll;
}

.inlineDocumentViewDocument {
	height: calc(100vh - 60px);
	margin: auto;
	width: 100%;
	border: none;
}

.documentlst-popovr .popovr-cntnt .popovr-segment img {
	width: 18px;
	height: 18px;
}
.documentlst-popovr .popovr-cntnt .popovr-segment .without-subtype-disable {
	opacity: 0.5;
	float: left;
	width: 90%;
	cursor: no-drop;
}
.documentlst-popovr .popovr-cntnt .popovr-segment .required-subtype-tooltip {
	float: right;
	width: 10%;
}

.documentlst-popovr
	.popovr-cntnt
	.popovr-segment
	.required-subtype-tooltip
	.el-tooltip__popper.is-light {
	width: 250px !important;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: white;
	font-size: 12px;
	color: #262837;
	border: none;
}

.threedotaction > div > div > svg > path:nth-child(2) {
	fill: #262837 !important;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.threedotaction
	.onhover {
	display: none;
}

.document-list-section
	.document-list-body-row
	.document-list-data-row:hover
	.threedotaction
	.default,
.document-list-section
	.document-list-body-row
	.document-list-data-row-expanded:hover
	.threedotaction
	.default {
	display: none;
}

.document-list-section
	.document-list-body-row
	.document-list-data-row:hover
	.threedotaction
	.onhover,
.document-list-section
	.document-list-body-row
	.document-list-data-row-expanded:hover
	.threedotaction
	.onhover {
	display: block;
	background-color: rgba(123, 130, 145, 0.15);
	border-radius: 3px;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.col-added-by
	.el-tooltip
	.el-tooltip__rel
	.ellipsed {
	white-space: normal;
	word-break: break-all;
}
.col-capitalize {
	text-transform: capitalize;
}
.document-list-section
	.document-list-body-row
	.document-list-row-line
	.el-popover[x-placement^='bottom'] {
	margin-top: 4px;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.el-popover {
	min-width: 179px;
	padding: 0;
	border-radius: 4px;
	z-index: 9;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.el-popover
	.popovr-cntnt {
	width: 100%;
	padding: 4px 0;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.el-popover
	.popovr-cntnt
	.popovr-segment {
	width: 100%;
	padding: 9px 8px;
	font-size: 13px;
	font-family: RionaSans;
	color: #262837;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.el-popover
	.popovr-cntnt
	.popovr-segment:hover {
	background-color: #efefef;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.el-popover
	.popovr-cntnt
	.popovr-segment:active {
	background-color: #efefef;
}

.document-list-section
	.document-list-body-row
	.document-list-row-line
	.el-popover
	.popovr-cntnt
	.popovr-segment
	img {
	margin-right: 8px;
}

.document-list-section .document-list-body-row .el-tooltip__popper {
	pointer-events: none;
}

.document-list-body-row
	.document-list-data-row
	.documentListArchivedRowFileName
	span {
	display: inline-block !important;
}

/* document share pop up modal */

.shareDocumentContainer {
	min-height: 150px;
	width: 368px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 120px);
}

.shareDocumentContainer .shareDocumentTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 9px 16px;
	min-height: 58px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shareDocumentContainer .shareDocumentTopRow .shareDocumentHeader {
	display: flex;
	flex-direction: column;
	width: calc(100% - 36px);
	align-content: center;
	margin: 0px 1px;
}

.shareDocumentContainer
	.shareDocumentTopRow
	.shareDocumentHeader
	.shareDocumentTitle {
	width: 100%;
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.shareDocumentContainer
	.shareDocumentTopRow
	.shareDocumentHeader
	.sharedocumentname {
	width: 100%;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.shareDocumentContainer
	.shareDocumentTopRow
	.shareDocumentClose
	.el-dialog__close {
	font-size: 12px;
	font-weight: bold;
}

.shareDocumentContainer .shareDocumentTopRow .shareDocumentClose {
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
}

.shareDocumentContainer .shareDocumentBodySection {
	padding: 32px 16px;
	display: flex;
	flex-direction: column;
	align-content: center;
	overflow-y: auto;
	max-height: calc(100vh - 255px);
}

.shareDocumentContainer .shareDocumentFooterSection {
	display: flex;
	padding: 12px 16px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	justify-content: flex-end;
}

.shareDocumentContainer .shareDocumentFooterSection .sharebtn,
.shareDocumentContainer
	.shareDocumentFooterSection
	.el-button.is-disabled:hover {
	border: 1px solid #7b26c9;
	color: #ffffff;
	font-size: 13px;
}

.shareDocumentContainer .shareDocumentFooterSection .sharebtn:hover {
	background-color: #9c5dd7 !important;
	border: solid 1px #9c5dd7 !important;
}

.shareDocumentContainer
	.shareDocumentFooterSection
	.el-button.is-disabled:hover {
	background-color: #7b26c9 !important;
}

.sharedocumentinputcontainer .shareDocumentmultiSelect .el-tag .el-icon-close {
	top: 1px;
}

.shareDocumentContainer .shareDocumentFooterSection .sharebtn:active {
	border: #7b26c9;
	border: 1px solid #7b26c9;
	color: #ffffff;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharebodysectioncol.share-not-mb16 {
	margin-bottom: 35px;
}

.shareDocumentContainer .shareDocumentBodySection .sharebodysectioncol {
	margin-bottom: 19px;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharebodysectioncol:last-child {
	margin-bottom: 0px;
}

.shareDocumentContainer .shareDocumentBodySection .sharebodysectioncol .note {
	width: 100%;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharebodysectioncol
	.sharedocumentinputcontainer {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharebodysectioncol
	.sharedocumentinputcontainer
	.sharedocumentInputHeaderContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharebodysectioncol
	.sharedocumentinputcontainer
	.shareDocumentRequired {
	text-indent: -6px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #e74c3c;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharebodysectioncol
	.sharedocumentinputcontainer
	.shareDocumentInputHeader {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect {
	max-height: fit-content;
	outline: 0;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags {
	max-height: 118px !important;
	max-width: calc(100% - 30px) !important;
	min-width: calc(100% - 30px) !important;
	overflow-x: auto;
	overflow-y: auto;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select-dropdown {
	width: 100%;
	top: unset !important;
	left: unset !important;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select-dropdown__wrap {
	max-height: 258px;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags
	.el-tag {
	display: inline-flex;
	max-width: calc(100% - 40px);
	align-items: center;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags
	.el-tag
	.el-select__tags-text {
	overflow: hidden;
	max-width: calc(100% - 15px);
	text-overflow: ellipsis;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags
	.el-tag
	.el-icon-close {
	top: 0;
	right: 0;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select-dropdown__item {
	padding-right: 30px !important;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-input {
	max-height: 120px !important;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-input
	.el-input__inner,
.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-textarea
	.el-textarea__inner {
	border-color: #bac3ce;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-input
	.el-input__inner::placeholder,
.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.el-textarea
	.el-textarea__inner::placeholder {
	font-size: 13px !important;
	color: #bac3ce;
	font-family: RionaSans;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-input
	.el-input__inner:active,
.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.el-textarea
	.el-textarea__inner:active,
.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-input
	.el-input__inner:focus,
.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.el-textarea
	.el-textarea__inner:focus {
	border-radius: 4px !important;
	border: solid 1.5px #40b9ec !important;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-input
	.el-input__inner:hover,
.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.el-textarea
	.el-textarea__inner:hover {
	border-radius: 4px !important;
	border: solid 1px #70cbf1 !important;
}
.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags::-webkit-scrollbar {
	width: 0px;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags::-moz-scrollbar {
	width: 0px;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags::-o-scrollbar {
	width: 0px;
}

.shareDocumentContainer
	.shareDocumentBodySection
	.sharedocumentinputcontainer
	.shareDocumentmultiSelect
	.el-select__tags::-ms-scrollbar {
	width: 0px;
}

.shareDocumentContainer .sharedocumentinputcontainer .sharebtn {
	border-radius: 6.5px;
	background-color: #7b26c9 !important;
	width: 160px;
	height: 36px;
	text-align: center;
}

.sharedocumentinputcontainer .sharebtn.is-disabled {
	border-radius: 6.5px;
	background-color: #7b26c9 !important;
	width: 160px;
	height: 36px;
	text-align: center;
}
.el-select.shareDocumentmultiSelect:focus-within .el-input__inner,
.el-select.shareDocumentmultiSelect:active .el-input__inner,
.el-select.shareDocumentmultiSelect:hover .el-input .el-input__inner {
	border-radius: 4px !important;
	border: solid 1.5px #40b9ec !important;
}
.shareDocumentmultiSelect .el-select__input {
	width: calc(100% - 40px) !important;
}

/* document share over */

/* Audit Trail modal start */
.auditTrailContainer {
	width: 368px;
	position: fixed;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
	height: auto !important;
}

.auditTrailBody {
	overflow-y: auto;
	max-height: calc(100vh - 180px);
}

.auditTrailContainer > .loading > div {
	border-radius: 4px;
}

.auditTrailContainer .topRow {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px;
	height: 56px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.auditTrailContainer .topRow .header {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.38;
	color: #262837;
}

.auditTrailContainer .topRow .close {
	transform: scale(1.4);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	cursor: pointer;
	position: relative;
	opacity: 1;
}

.auditTrailContainer .topRow .close:hover {
	background-color: #ececec;
}

.auditTrailContainer .topRow .close:active {
	background-color: #c7c7c7;
}

.auditTrailContainer .topRow .close > div > div > svg > g > g {
	fill: black !important;
}

.auditTrailContainer .content {
	padding: 32px 20px;
	max-height: calc(100vh - 180px);
	overflow-y: auto;
}

.auditTrailContainer .content > .log {
	padding-bottom: 24px;
	border-left: solid 2px #edeff3;
}

.auditTrailContainer .content > .log:last-of-type {
	border-left: solid 2px white !important;
}

.auditTrailContainer .content > .log {
	padding-left: 20px;
}

.auditTrailContainer .content > .log > div {
	display: block;
	font-family: RionaSans;
	font-size: 12px;
	line-height: 1.42;
	color: #7b8291;
}

.auditTrailContainer .content > .log > .title {
	font-size: 14px;
	line-height: 1.43;
	color: #262837;
	position: relative;
}

.auditTrailContainer .content > .log > .title::before {
	position: absolute;
	content: '';
	display: inline-block;
	background: #262837;
	width: 18px;
	height: 18px;
	left: -30px;
	border-radius: 50%;
	border: 5px solid white;
}

.auditTrailContainer .content > .log > .title > span {
	font-family: RionaSans-Bold;
	font-weight: bold;
}

.auditTrailContainer .content > .log > .status {
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 12px;
	font-style: italic;
	color: #e74c3c;
}

.auditTrailContainer .content > .log > .retract {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #e74c3c;
}

.auditTrailContainer .content > .log > .retract span {
	cursor: pointer;
}

.auditTrailContainer .content > .log > .retract span:hover {
	text-decoration: underline;
}

.auditTrailContainer .content > .log > .retract > div {
	white-space: pre;
}

.auditTrailContainer .content > .log > .retract > div .dot {
	display: inline-block;
	width: 3px;
	height: 3px;
	background-color: black;
	vertical-align: middle;
	border-radius: 50%;
}

/* Audit Trail modal end */

/*******************************************
 * Finalize and Amend Document Popup
 *******************************************/

.finalize-amend-doc-container {
	max-height: calc(100vh - 120px);
	height: 396px;
	min-height: 164px;
	width: 368px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	border: 1px solid #edeff3;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
}

.finalize-amend-doc-header {
	font: normal bold 16px/1.38 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	color: #262837;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	border-bottom: 1px solid #edeff3;
	padding: 8px 16px;
}

.finalize-amend-doc-headerXIcon {
	transform: scale(1.38);
	position: relative;
	left: 8px;
	cursor: pointer;
}

.finalize-amend-doc-body {
	padding: 32px 16px 16px 16px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 280px;
}

.finalize-amend-doc-body .doc-type-error {
	color: #ff4949;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
}

.finalize-amend-warn-msg {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.38;
	letter-spacing: normal;
	color: #262837;
	padding-top: 8px;
}

.finalize-amend-doc-buttonrow {
	display: flex;
	padding: 11px 16px 12px 16px;
	width: 100%;
	border-top: 1px solid rgb(237, 239, 243);
	height: 60px;
}

.finalize-amend-doc-buttonrow .finalize-amend-doc-save {
	margin-left: auto;
}

.finalize-amend-doc-cancel {
	border: 1px solid #7b26c9;
	background-color: #fff;
	padding: 9px 12px;
	border-radius: 6.5px;
	color: #7b26c9;
	width: 160px;
	height: 36px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.finalize-amend-doc-cancel:hover {
	background-color: rgba(123, 38, 201, 0.15);
}

.finalize-amend-doc-cancel:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.finalize-amend-doc-save {
	color: white;
	background-color: #7b26c9;
	padding: 9px 12px;
	border-radius: 6.5px;
	width: 160px;
	height: 36px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.finalize-amend-doc-save:hover {
	background-color: #9c5dd7;
}

.finalize-amend-doc-save:active {
	background-color: #621ea0;
}

.finalize-amend-doc-button-inactive {
	cursor: no-drop !important;
	opacity: 0.5;
	background-color: #7b26c9 !important;
}

.createDocumentInputContainer
	.gmi-radio-group
	.el-radio__input
	.el-radio__inner {
	height: 12px;
	width: 12px;
}
.createDocumentInputContainer
	.gmi-radio-group
	.el-radio__input:not(.is-checked)
	.el-radio__inner {
	border: 1px solid rgba(0, 0, 0, 0.54);
}
.createDocumentInputContainer
	.gmi-radio-group
	.el-radio__input.is-checked
	.el-radio__inner::after {
	border: 1px solid #bd92e4 !important;
	background-color: #7b26c9;
	height: 7px;
	width: 7px;
}

/*******************************************************************
				Start: Combine document dialog
*******************************************************************/
.combine-document-dialog {
	max-height: calc(100vh - 120px);
	width: 448px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	border: 1px solid #edeff3;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	min-height: 164px;
}

.combine-document-dialog-header {
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	border-bottom: 1px solid #edeff3;
	padding: 8px 16px;
}

.combine-document-dialog-header-XIcon {
	transform: scale(1.38);
	position: relative;
	left: 8px;
}

.combine-document-dialog-body {
	padding: 16px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: calc(100vh - 240px);
}

.combine-document-dialog-body h2 {
	display: flex;
    align-items: center;
	width: 100%;
	margin: 0 0 16px;
    font: normal 14px RionaSans-Bold, serif;
	color: #000000;
}

.combine-document-dialog-body h2.document-order-header {
	margin: 0 0 2px;
}

.combine-document-dialog-body h2.document-info-header::after {
	margin: 0 0 0 10px;
	flex: 1 0 20px;
	border-top: 1px solid #edeff3;
	content: '';
}

.combine-document-dialog-body .document-reorder-header {
	font: normal 12px/1.42 RionaSans-Medium, serif;
	color: #525968;
}

.combine-document-dialog-body .combine-document-required::before {
	color: #e74c3c;
    content: '*';
    font: normal bold 12px/1.42 RionaSans-Regular, serif;
    margin-left: -3px;
}

.combine-document-dialog-body .el-cascader-menu__item.is-active {
	color: #ffffff;
	background-color: #1c8de0 !important;
}

.combine-document-dialog-body .document-order-list {
	margin: 10px 0 0;
	padding: 0 12px;
	color: #262837;
	font: normal 16px/120% RionaSans-Medium, serif;
}

.combine-document-dialog-body .document-order-list li {
	margin-bottom: 16px;
	margin-left: 2px;
}

.combine-document-dialog-body .document-order-list li .up-arrow-button,
.combine-document-dialog-body .document-order-list li .down-arrow-button {
	background: none;
    border: none;
    align-self: self-start;
	cursor: pointer;
}

.combine-document-dialog-body .document-order-list .disable-arrow-button {
	pointer-events: none;
	opacity: 0.35;
}

.combine-document-description {
	width: 100%;
	height: 66px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	padding: 8px;
	resize: none;
	font-size: 13px;
}

.combine-document-description::placeholder {
	color: #97a8be;
}

.combine-document-input-header {
	font-size: 12px;
	color: #525968;
}

.combine-document-dialog-footer {
	padding: 16px;
	width: 100%;
	border-top: 1px solid rgb(237, 239, 243);
}

.combine-document-save-button {
	margin-left: auto;
	color: white;
	background-color: #5b43bd;
	border-radius: 4px;
	font: normal 13px/120% RionaSans-Medium, serif;
	text-align: center;
	cursor: pointer;
	user-select: none;
	display: flex;
	width: 172px;
	height: 32px;
	padding: 0 24px;
	justify-content: center;
	align-items: center;
}

.combine-document-save-button:hover,
.combine-document-save-button:active {
	background-color: #43349c;
}

.combine-document-inactive-save-button {
	cursor: no-drop !important;
	opacity: 0.5;
	background-color: #a89cdd !important;
}

.singleSelectSaveDisabled,
.singleSelectSaveDisabled:hover,
.singleSelectSaveDisabled:active {
	background-color: #a89cdd;
	opacity: 0.35;
	cursor: no-drop;
}

/*******************************************************************
				End: Combine document dialog
*******************************************************************/
