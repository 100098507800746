/* stylelint-disable selector-class-pattern */
.view-cfdna-cancer-gene-form {
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: #ffffff;
	padding: 16px;
}

.view-cfdna-cancer-gene-form .cfDNA-title {
	border-bottom: 1px solid #edeff3;
	margin-bottom: 16px;
	padding-bottom: 14px;
	font: normal bold 20px RionaSans-Bold, sans-serif;
	color: #262837;
}

.view-cfdna-cancer-gene-form .cfDNA-result-section {
	border-right: 1px solid #edeff3;
	padding-right: 0;
}

.view-cfdna-cancer-gene-form .cfDNA-result-details-label,
.view-cfdna-cancer-gene-form .cfDNA-clinical-comments-label {
	margin-bottom: 24px;
	font: normal 500 18px RionaSans-Medium, sans-serif;
	color: #262837;
}

.view-cfdna-cancer-gene-form .view-all-clinical-comments {
	/* width: 73px;
	margin: 21px 15px 24px 72px; */
	font: normal 500 14px RionaSans-Medium, sans-serif;
	color: #7b26c9;
}

.view-cfdna-cancer-gene-form label {
	color: #262838;
	font: normal 12px RionaSans, sans-serif;
	margin-bottom: 0;
}

.view-cfdna-cancer-gene-form .field-val {
	font: normal 500 14px RionaSans-Medium, sans-serif;
	color: #262838;
	line-height: normal;
	width: 100%;
	display: inline-block;
}

.view-cfdna-cancer-gene-form .lab-comment {
	white-space: pre;
}

.view-cfdna-cancer-gene-form .margin-bottom-19 {
	margin-bottom: 19px;
}

.view-cfdna-cancer-gene-form .edit-details-button {
	padding: 3px 12px;
	margin-top: 61px;
	border: 1px solid #7b26c9;
	border-radius: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #7b26c9;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	text-align: center;
	cursor: pointer;
}

.view-cfdna-cancer-gene-form .edit-details-button:hover {
	background-color: rgb(123 38 201 / 15%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.view-cfdna-cancer-gene-form .edit-details-button:active {
	background-color: rgb(123 38 201 / 30%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.view-cfdna-cancer-gene-form .cfDNA-result-edit-icon .edit {
	fill: #7b26c9;
}

.view-cfdna-cancer-gene-form .cfDNA-result-edit-icon {
	padding-right: 7px;
	height: 28px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}

.view-cfdna-cancer-gene-form .request-another-img {
	padding-right: 7px;
	height: 28px;
}

.view-cfdna-cancer-gene-form .clinical-comments-container {
	position: relative;
}
