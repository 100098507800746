/* start: add gene page */

.gene-result {
	background-color: #edeff3;
	border-bottom: 4px solid #edeff3;
	margin: 32px 14px 0;
}

.gene-result-container {
	padding: 16px;
}

.head-gene-result {
	font: normal bold 12px RionaSans-Bold;
	color: #51596a;
	margin-bottom: 14px;
}

.addgene-form,
.viewgene-form {
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: #ffffff;
	padding: 20px 12px;
}

.addgene-form .el-form-item__label,
.addgene-form .el-radio__label,
.addgene-form .lbl-clinvar-alle {
	font: normal 12px/1.38 RionaSans;
}

.addgene-form .el-form-item__label,
.addgene-form .el-radio__label,
.addgene-form .lbl-clinvar-alle {
	color: #525968;
}

.addgene-form input::placeholder,
.addgene-form textarea::placeholder {
	font: normal 13px RionaSans;
	color: #262837;
	opacity: 0.5;
}

.addgene-form
	> div.el-row
	> div.el-col
	> div.el-row
	> div.el-col
	> div.el-form-item {
	margin-bottom: 12px !important;
}

.addgene-form .lab-comments {
	min-height: 150px;
}

.addgene-form .geneAddLoader {
	height: 100%;
}

.errorMessage {
	font: normal 13px RionaSans;
	color: #ff4949;
}

.addgene-form .errorMSGMargin {
	margin-bottom: 0;
}

.viewgene-form label {
	color: #262838;
	font: normal 12px RionaSans;
	margin-bottom: 0;
}

.viewgene-form span {
	font: normal 500 14px RionaSans-Medium;
	color: #262838;
	line-height: normal;
	width: 100%;
	display: inline-block;
}

.gene-header,
.mutation-header,
.risk-score-header,
.interprete-header,
.additional-info-header {
	font: normal bold 18px RionaSans-Bold;
	color: #262838;
	text-transform: uppercase;
}

.addgene-form h3 {
	margin-bottom: 16px;
}

.one-more-fields-required {
	text-align: end;
}

.one-more-fields-required,
.addition-sub-title {
	font: normal 500 10px RionaSans-Medium;
	color: #525968;
}

.el-tooltip .more-info {
	width: 11px;
	height: 11px;
}

.tooltip-clinvar {
	margin-left: 4px;
	display: inline-block;
}

.addgene-form .rdoResultType .el-radio {
	white-space: break-spaces;
}

.addgene-form .el-radio__inner {
	width: 12px;
	height: 12px;
}

.addgene-form .el-radio__input.is-checked .el-radio__inner {
	border-color: #9eddf6;
	background: #ffffff;
}

.addgene-form .el-radio__input.is-checked .el-radio__inner::after {
	background-color: #40b9ec;
}

.zygosity .el-radio + .el-radio {
	margin-left: 0;
}

.toggle_icon {
	width: 28px;
	height: 28px;
}

.rotate {
	transition: all 0.5s linear;
}

.toggle-arrow img {
	-o-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.gene-result-container .delete-button {
	font: normal 500 13px RionaSans-Medium;
	border-radius: 6px;
	border-style: none;
	color: #ffffff;
	cursor: pointer;
	background-color: #e74c3c;
	width: 160px;
	height: 36px;
	margin-left: auto;
}

.gene-result-container .delete-button:hover,
.gene-result-container .delete-button:focus {
	color: #ffffff;
}

.addgene-form .divider {
	width: 1px;
	height: 32px;
	border: 1px solid #bac3ce;
	display: inline-block;
	margin: -12px 18px;
}

.gene-result-container .save-button {
	width: 160px;
	border-radius: 6.5px;
	border-style: none;
	cursor: pointer;
	padding: 9px 12px;
	font: normal 500 13px RionaSans-Medium;
	color: #ffffff;
	display: inline-block;
	background-color: #7b26c9 !important;
}

.gene-result-container .addanother-button {
	width: 160px;
	border-radius: 6.5px;
	cursor: pointer;
	padding: 9px 12px;
	font: normal 500 13px RionaSans-Medium;
	color: #7b26c9;
	display: inline-block;
	border: 1px solid #7b26c9;
}

.gene-result-container .save-button:hover {
	background-color: #9c5dd7 !important;
	color: #ffffff !important;
}

.gene-result-container .save-button:active,
.gene-result-container .save-button:focus {
	background-color: #621ea0 !important;
	color: #ffffff !important;
}

.gene-result-container .addanother-button:hover {
	background-color: rgba(123, 38, 201, 0.15);
	color: #7b26c9 !important;
	border-color: #7b26c9 !important;
}

.gene-result-container .addanother-button:active {
	background-color: rgba(123, 38, 201, 0.3);
	color: #7b26c9 !important;
	border-color: #7b26c9 !important;
}

.gene-result-container .addanother-button:focus {
	background-color: rgba(123, 38, 201, 0.15);
	color: #7b26c9 !important;
	border: 2px dotted rgba(123, 38, 201, 0.5);
}

.gene-result-container .cancel-button {
	cursor: pointer;
	padding: 8px;
	font: normal 500 13px RionaSans-Medium;
	color: #7b26c9;
}

.gene-result-container .cancel-button.el-button:hover,
.gene-result-container .cancel-button.el-button:focus {
	color: #7b26c9;
}

.gene-result-container .addanother-button .plusIcon {
	width: 27px;
	height: 27px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
}

.gene-result-container .result-type-question {
	font: normal bold 16px/1.38 RionaSans-Bold;
	color: #202d3d;
	padding: 11px 0 5px 0px;
}

.gene-result-container .el-radio + .el-radio {
	margin-left: 0;
}

.gene-result-container .disable-section {
	opacity: 0.45;
	cursor: no-drop;
}

.gene-result-container .disable-section .el-input,
.gene-result-container .disable-section .el-textarea,
.gene-result-container .disable-section .el-radio-group,
.gene-result-container .disable-section .additional-info-header {
	pointer-events: none;
}

.gene-result-container .disable-section .el-radio__label,
.gene-result-container .disable-section .el-radio__inner {
	cursor: no-drop;
}

.gene-result-container .rdoResultType .el-radio__label {
	font: normal 12.5px/1.41 RionaSans;
	padding-left: 3px;
}
/* end: add gene page */

/* start: view gene page */

.viewgene-form .gene-header,
.viewgene-form .mutation-header,
.viewgene-form .risk-score-header,
.viewgene-form .interprete-header,
.viewgene-form .additional-info-header {
	margin-bottom: 8px;
}

.viewgene-form .risk-score-header,
.viewgene-form .interprete-header,
.viewgene-form .additional-info-header,
.viewgene-form .clinician-comments-label {
	margin-top: 28px;
}

.view-gene-container .el-input {
	width: 100%;
	display: inline-block;
}

.viewgene-form .gene-name {
	font: normal bold 18px RionaSans-Bold;
	color: #262838;
}

.viewgene-form .showRiskScore {
	margin-top: 0 !important;
}

/* end: view gene page */

/* start: edit gene page */

.gene-result-edit-icon {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}

/* end: edit gene page */

/* Start: To override element react style */

.double-asterisk label::before,
.double-asterisk-hint::before {
	content: '**' !important;
	color: #ff4949;
	margin-right: 1px;
	margin-left: -8px;
	font-family: 'RionaSans-Bold';
	font-size: 15px;
}

.el-form-item.is-required .el-form-item__label:before {
	margin-left: -8px;
}

.addgene-form .required .el-form-item__label::before {
	color: #ff4949;
	content: '*' !important;
	margin-left: -5px;
	font-family: 'RionaSans-Bold';
	font-size: 15px;
}

.addgene-form .el-form-item__label {
	padding-bottom: 0 !important;
}

.el-form-item
	> div.el-form-item__content
	> div.el-input
	> input.el-input__inner::placeholder {
	font-size: 13px;
	opacity: 0.5;
	color: #262837;
}

.tooltip-clinvar .el-tooltip__popper {
	width: 148px !important;
	font: normal 12px RionaSans !important;
	top: -40px !important;
}

.tooltip-clinvar .el-tooltip__popper a {
	color: #ffffff !important;
}

.interpretation .el-form-item__label {
	float: none;
}

.addgene-form .el-input__inner {
	font: normal 13px RionaSans;
	color: #262837;
}

.el-form-item
	> div.el-form-item__content
	> div.el-select
	> div.el-input
	> input.el-input__inner::placeholder,
.el-form-item
	> div.el-form-item__content
	> div.el-input
	> input.el-input__inner::placeholder {
	font-size: 13px !important;
	color: #262837;
	opacity: 0.5;
}

.addgene-form .el-radio {
	width: 100%;
}

.addgene-form .lab-comments textarea {
	padding: 5px 7px 13px !important;
}

.gene-result-delete-dlg,
.gene-result-cancel-dlg {
	width: 400px !important;
	border-radius: 5px !important;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35) !important;
	padding: 20px;
}

.gene-result-delete-dlg .el-dialog__header,
.gene-result-cancel-dlg .el-dialog__header {
	padding: 0 0 20px 0;
}

.gene-result-delete-dlg .el-dialog__header .el-icon-close,
.gene-result-cancel-dlg .el-dialog__header .el-icon-close,
.gene-no-result-cancel-dlg .el-dialog__header .el-icon-close {
	width: 16px;
	height: 16px;
	font-size: 11px;
}

.gene-result-delete-dlg .el-dialog__header .el-icon-close:hover,
.gene-result-cancel-dlg .el-dialog__header .el-icon-close:hover,
.gene-no-result-cancel-dlg .el-dialog__header .el-icon-close:hover {
	color: #bfcbd9;
}

.gene-result-delete-dlg .el-dialog__title,
.gene-result-cancel-dlg .el-dialog__title {
	font: normal bold 16px RionaSans-Bold;
	color: #262739;
}

.gene-result-cancel-dlg .el-dialog__title img {
	height: 19px;
	width: 19px;
	margin-right: 4px;
}

.gene-result-delete-dlg .el-dialog__body,
.gene-result-cancel-dlg .el-dialog__body {
	padding: 0;
	font-size: 12px;
	color: #7a8292;
}

.gene-result-delete-dlg .el-dialog__footer,
.gene-result-cancel-dlg .el-dialog__footer {
	padding: 28px 0 0 0;
	text-align: start;
}

.gene-result-delete-dlg .dialog-footer button,
.gene-result-cancel-dlg .dialog-footer button {
	font: normal 500 13px RionaSans-Medium;
	text-transform: capitalize;
	border-radius: 6px;
	padding-top: 4px;
	padding-bottom: 4px;
	min-height: 36px;
}

.gene-result-delete-dlg .dialog-footer button:first-child,
.gene-result-cancel-dlg .dialog-footer button:first-child {
	padding-left: 12px;
	padding-right: 12px;
	border: 1px solid #a0abb9;
	color: #a0abb9;
}

.gene-result-delete-dlg .dialog-footer button:last-child {
	width: 145px;
	background-color: #e74c3c;
	color: #ffffff;
	float: right;
}

.gene-result-cancel-dlg .dialog-footer button:last-child {
	width: 80px;
	background-color: #20a0ff;
	color: #ffffff;
	float: right;
}

.btn-gene-delete-icon {
	display: inline-block;
	width: 22px;
	height: 22px;
}

.gene-no-result-cancel-dlg .el-dialog__header {
	height: 56px;
	padding: 16px !important;
	border-bottom: 1px solid #edeff3;
}

.gene-no-result-cancel-dlg {
	width: 368px !important;
	height: 270px;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	background-color: #ffffff;
}

.gene-no-result-cancel-dlg .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	color: #262837;
}

.gene-no-result-cancel-dlg .el-dialog__title img {
	height: 19px;
	width: 19px;
	margin-right: 16px;
}

.gene-result-delete-dlg .el-dialog__body,
.gene-result-cancel-dlg .el-dialog__body {
	padding: 0;
	font-size: 12px;
	color: #7a8292;
}

.gene-no-result-cancel-dlg .el-dialog__body {
	padding: 0 16px;
	font: normal 500 13px/1.38 RionaSans;
	color: #262837;
	margin-top: 32px;
	margin-bottom: 32px;
}

.gene-no-result-cancel-dlg .dialog-footer button:first-child {
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 6px;
	border: 1px solid #7b26c9;
	background-color: #ffffff;
	color: #7b26c9;
	font: normal 500 13px/1.38 RionaSans-Medium;
	text-transform: capitalize;
	padding-top: 4px;
	padding-bottom: 4px;
	width: 160px;
	height: 36px;
}

.gene-no-result-cancel-dlg .dialog-footer button:last-child {
	background-color: #7b26c9;
	color: #ffffff;
	float: right;
	color: #ffffff;
	width: 160px;
	height: 36px;
	font: normal 500 13px/1.38 RionaSans-Medium;
}

.gene-no-result-cancel-dlg .el-dialog__footer {
	padding: 18px 16px;
	text-align: start;
	border-top: 1px solid #edeff3;
}
/* End: To override element react style */
