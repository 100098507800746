.createTestOrderModalContainer {
	width: 650px;
	height: min(calc(100% - 120px), 1472px);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: absolute;
	left: calc(50% - 325px);
	top: 60px;
}

.createTestOrderModalContainer > div:first-of-type {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.createTestOrderModalTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 9px 16px;
	height: 56px;
	border-bottom: 1.5px solid #edeff3a2;
}

.createTestOrderModalHeaderBreadcrumbs {
	display: flex;
	flex-direction: column;
}

.createTestOrderModalHeader {
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #262837;
	margin-bottom: 2px;
}

.createTestOrderModalBreadcrumbs {
	display: flex;
	flex-direction: row;
	align-content: center;
	margin: 0px 1px;
	font-size: 12px;
	color: #bac3cd;
	font-family: RionaSans;
}

.createTestOrderModalBreadcrumbsCurrent {
	color: #262837 !important;
}

.createTestOrderModalBreadcrumbArrow {
	transform: scale(0.65);
	position: relative;
	bottom: 6px;
}

.createTestOrderModalClose {
	transform: scale(1.4);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	top: 8px;
	cursor: pointer;
}

.createTestOrderModalClose:hover {
	background-color: #ececec;
}

.createTestOrderModalClose:active {
	background-color: #c7c7c7;
}

.createTestOrderModalClose > div > div > svg > g > g {
	fill: black !important;
}

.createTestOrderModalButtonRow {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 12px 16px;
	font-size: 13px;
	color: white;
	border-top: 1px solid #edeff3a2;
	height: 60px;
	background-color: #fff;
	position: relative;
	border-radius: 0 0 4px 4px;
}

.justify-content-end {
	justify-content: flex-end;
}

.justify-content-between {
	justify-content: space-between;
}
.createTestOrderModalButtonRow .createTestOrderModalBackButton.el-button,
.createTestOrderModalButtonRow .createTestOrderModalActionButton.el-button {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
}

.createTestOrderModalButtonRow .createTestOrderModalActionButton.el-button {
	margin-left: auto;
}

.createTestOrderModalButtonRow
	.createTestOrderModalActionButton.el-button:hover {
	font-family: RionaSans-Medium;
	font-weight: 500;
}

.createTestOrderModalButtonRow .save-alert-msg {
	color: #e74c3c;
	font: normal 500 13px/1.38 RionaSans-Medium;
}

.createTestOrderModalBackButton.el-button {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: white !important;
	color: #7b26c9 !important;
	text-align: center;
	border: 1px solid #7b26c9 !important;
	padding-top: 9px;
	cursor: pointer;
	user-select: none;
	padding: 9px 12px;
}

.createTestOrderModalBackButton.el-button:hover {
	background-color: rgba(123, 38, 201, 0.15) !important;
}

.createTestOrderModalBackButton.el-button:active {
	background-color: rgba(123, 38, 201, 0.3) !important;
}

.createTestOrderModalActionButton.el-button {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	color: #ffffff !important;
	border: none;
}
.createTestOrderModalActionButton.el-button.is-disabled,
.createTestOrderModalActionButton.el-button.is-disabled:active,
.createTestOrderModalActionButton.el-button.is-disabled:hover {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
}

.createTestOrderModalActionButton.el-button:hover {
	background-color: #9c5dd7;
	color: #ffffff !important;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	border: none;
}

.createTestOrderModalActionButton.el-button:active {
	background-color: #621ea0;
	color: #ffffff !important;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	border: none;
}

.pre-auth-test-rqst-dialog-body {
	height: calc(100% - 116px);
}

.crte-test-rqst-dialog .crte-test-rqst-hdr-wrapper {
	display: flex;
	padding: 9px 16px;
	border-bottom: 1px solid #bac3cd;
}

.crte-test-rqst-dialog .crte-test-rqst-dialog-body {
	height: 100%;
}

.crte-test-rqst-dialog .crte-test-rqst-hdr-wrapper h3 {
	font: normal bold 16px/1.38 RionaSans;
	color: #262837;
	margin: 0;
}

.crte-test-rqst-hdr-txt-wrapper {
	width: 594px;
}

.crte-test-rqst-close {
	color: #262837;
	width: 12px;
	height: 12px;
	font-weight: bold;
	font-size: 14px;
	margin: 10px;
	cursor: pointer;
}

.crte-test-rqst-hdr-wrapper .crte-test-rqst-bc {
	font: normal 12px/1.42 RionaSans;
	color: #bac3cd;
}

.crte-test-rqst-hdr-wrapper .crte-test-rqst-bc .current-item {
	color: #262837;
}

.crte-test-rqst-hdr-wrapper .crte-test-rqst-bc .bc-divider {
	width: 28px;
	height: 28px;
	opacity: 0.5;
}

.crte-test-rqst-dialog .btn-next:hover {
	color: #fff;
	border: none;
}

.crte-test-rqst-fields-container {
	display: flex;
	height: 100%;
}
.crte-test-rqst-fields-container .el-tabs {
	background-color: #edeff3;
}

.crte-test-rqst-fields-container .el-tabs .el-tabs__content {
	height: calc(100% - 40px);
	overflow-y: auto;
}

.crte-test-rqst-dialog .crte-test-rqst-dialog-footer {
	display: flex;
	justify-content: flex-end;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	color: #ffffff;
}

.crte-test-rqst-dialog .crte-test-rqst-dialog-footer .btn-next {
	width: 160px;
	height: 36px;
	padding: 9px 12px;
	margin: 12px 16px;
	border-radius: 6px;
	background-color: #7b26c9;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	color: #ffffff;
	border: none;
}

.crte-test-rqst-dialog .crte-test-rqst-dialog-footer .disabled-btn-next,
.crte-test-rqst-dialog .crte-test-rqst-dialog-footer .disabled-btn-next:hover,
.crte-test-rqst-dialog .crte-test-rqst-dialog-footer .disabled-btn-next:active {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
}

.pre-auth-test-rqst-dialog-body .pre-auth-test-rqst-labinfo-container {
	overflow: auto;
	height: 100%;
	padding: 32px 16px;
}

.create-test-rqst-labinfo-container .aoe-questions-type-phone {
	width: 100%;
	padding: 8px 10px;
	border-radius: 4px;
    border: 1px solid #bfcbd9;
	font: normal 12px/1.42 RionaSans !important;
    color: #1f2d3d;
    line-height: 1;
}

.create-test-rqst-labinfo-container .el-cascader.is-disabled .el-input,
.create-test-rqst-labinfo-container
	.el-cascader.is-disabled
	.el-cascader__label {
	cursor: not-allowed;
}

.create-test-rqst-labinfo-container .el-input {
	font-size: 13px !important;
}

/*****************************************************
               Lab Section
******************************************************/
.create-test-rqst-labinfo-container {
	height: 100%;
	width: 400px;
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	overflow-y: auto;
	padding-bottom: 0;
}

.crte-test-rqst-dialog-v2 .create-test-rqst-labinfo-container {
	padding: 8px 16px 32px;
}

.edit-test-rqst-dialog-body .create-test-rqst-labinfo-container {
	padding: 16px 16px;
}

.create-test-rqst-labinfo-container .el-row {
	margin-bottom: 19px;
}

.create-test-rqst-labinfo-container .order-request-status.el-row {
	margin-top: 19px;
}

.create-test-rqst-labinfo-container .required::before {
	content: '*';
	margin-left: -6px;
	margin-right: 2px;
	font: normal bold 12px/1.42 RionaSans;
	color: #e74c3c;
}

.create-test-rqst-labinfo-container .el-select {
	font: normal 13px/1.38 RionaSans;
	color: #262837;
}

.create-test-rqst-labinfo-container .el-select .el-input .el-input__inner {
	color: #262837;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
}

.create-test-rqst-labinfo-container .el-select-dropdown {
	max-width: 345px;
	left: 0 !important;
}

.create-test-rqst-labinfo-container
	.smaller-dropdown
	.el-select-dropdown__wrap {
	max-height: 212px;
}

.create-test-rqst-labinfo-container .el-cascader {
	width: 100%;
}
.create-test-rqst-labinfo-container .el-cascader .el-cascader__label {
	width: 100%;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}
.create-test-rqst-labinfo-container
	.el-cascader
	.el-cascader-menu__item.is-active {
	background-color: #20a0ff !important;
}
.create-test-rqst-labinfo-container
	.el-cascader
	.el-cascader-menu__item.is-active:hover {
	background-color: #1c8de0 !important;
}

.pre-auth-test-rqst-dialog-body .el-cascader.is-opened .el-cascader-menus {
	left: 0 !important;
}

.create-test-rqst-labinfo-container .el-date-editor.el-input {
	width: auto;
}

.create-test-rqst-labinfo-container.aoe-questions .el-date-editor {
	width: 100%;
}

.create-test-rqst-labinfo-container.aoe-questions .placeholder-text {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: center;
	color: #a0abb9;
	margin-top: 10px;
}

.create-test-rqst-labinfo-container .pre-auth-dispatch-date,
.create-test-rqst-labinfo-container
	.pre-auth-dispatch-date
	.el-date-editor.el-input {
	width: 100%;
}

.create-test-rqst-labinfo-container .row-checkbox-order-test-manually {
	border-radius: 4px;
	width: 100%;
}

.create-test-rqst-labinfo-container
	.row-checkbox-order-test-manually.send-via-integration
	> div {
	padding: unset !important;
}

.create-test-rqst-labinfo-container .is-hold-result-release-checked {
	border: 1px solid #7b26c9;
}

.edit-test-rqst-labinfo-container-v2 .is-hold-result-release-checked:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.create-test-rqst-labinfo-container
	.chk-order-test-manually
	.el-checkbox__label {
	padding-left: 9px !important;
	font: normal 13px/1.38 RionaSans-Medium;
}

.create-test-rqst-labinfo-container .chk-order-test-manually {
	margin-bottom: 0 !important;
}

.row-checkbox-order-test-manually .chk-order-test-manually .is-disabled.is-checked,
.row-checkbox-order-test-manually .chk-order-test-manually .is-disabled + .el-checkbox__label {
    cursor: not-allowed;
	opacity: 0.7;
}

.create-test-rqst-labinfo-container .tt-hold-release-results {
	display: inline-block;
	margin-left: 4px;
}

.create-test-rqst-labinfo-container
	.tt-hold-release-results
	.el-tooltip__popper.is-dark {
	width: 150px !important;
}

.create-test-rqst-labinfo-container
	.tt-result-actionable
	.el-tooltip__popper.is-dark
	.more-details {
	color: #59afe1;
}

.create-test-rqst-labinfo-container .el-popover.pp-result-actionable,
.create-test-rqst-labinfo-container .el-popover.pp-finding-result {
	background-color: #000000;
	color: #ffffff;
}

.result-selection-label .tooltip-info,
.result-actionable-label .tooltip-info {
	margin-left: 3px;
}

.result-select-radio-button-wrapper {
	margin-top: 4px;
}

.el-popover.pp-result-actionable[x-placement^='top'] .popper__arrow::after,
.el-popover.pp-finding-result[x-placement^='top'] .popper__arrow::after {
	border-top-color: #000000;
}

.el-popover.pp-result-actionable[x-placement^='bottom'] .popper__arrow::after,
.el-popover.pp-finding-result[x-placement^='bottom'] .popper__arrow::after {
	border-bottom-color: #000000;
}
/************************************************************
          significant findings to report radio button
*************************************************************/
.edit-test-rqst-labinfo-container-v2 .result-selection-label {
	font: normal 500 13px/1.38 RionaSans-Medium;
}

.edit-test-rqst-labinfo-container-v2 .el-radio {
	padding: 12px;
	border: 1px solid #edeff3;
	border-radius: 4px;
	width: 100%;
}

.edit-test-rqst-labinfo-container-v2 .el-radio:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.edit-test-rqst-labinfo-container-v2 .el-radio:active {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.edit-test-rqst-labinfo-container-v2 .el-radio__label {
	font: normal 500 13px/1.38 RionaSans-Medium !important;
}

.edit-test-rqst-labinfo-container-v2 .el-radio__inner {
	width: 16px !important;
	height: 16px !important;
}

.edit-test-rqst-labinfo-container-v2 .el-radio + .el-radio {
	margin-left: 0 !important;
}

.edit-test-rqst-labinfo-container-v2
	.el-radio__input.is-checked
	.el-radio__inner {
	border-color: #bd92e4;
	background: none;
}

.edit-test-rqst-labinfo-container-v2
	.el-radio__input.is-checked
	.el-radio__inner::after {
	background: #7b26c9;
}

.edit-test-rqst-labinfo-container-v2 .radio-is-checked {
	border: 1px solid #7b26c9;
}

.edit-test-rqst-labinfo-container-v2 .radio-is-checked:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

/************************************************************
          results actionable selection
*************************************************************/
.edit-test-rqst-labinfo-container-v2 .result-actionable-label {
	font: normal 500 13px/1.38 RionaSans-Medium;
	margin-top: 19px;
}

.edit-test-rqst-labinfo-container-v2
	.result-actionable-label
	.el-popover.pp-result-actionable
	a {
	color: #337ab7;
}

.edit-test-rqst-labinfo-container-v2 .result-actionable-yesno-button-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 16px;
}

.result-actionable-yesno-button-wrapper .result-actionable-yesno-button {
	width: 72px;
	height: 32px;
	border-radius: 32px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	padding-top: 7px;
	user-select: none;
	cursor: pointer;
}

.result-actionable-yesno-button-wrapper .yesno-button-unselected {
	border: 1px solid #edeff3;
	background-color: white;
	color: #262837;
}

.result-actionable-yesno-button-wrapper .yesno-button-selected {
	border: 1px solid #e2d3f2;
	background-color: #e2d3f2;
	color: #491678;
}

.result-actionable-yesno-button-wrapper .yes-button {
	margin-right: 10px;
}

/*****************************************************
                Encounter Summary Tab
******************************************************/
.crte-test-rqst-encntr-container,
.test-rqst-info-tab-container {
	width: 250px;
	padding: 32px 16px;
	background-color: #edeff3;
	color: #262837;
	font: normal 12px/1.42 RionaSans;
	height: 100%;
}

.crte-test-rqst-dialog-v2 .crte-test-rqst-encntr-container {
	padding: 8px 16px 32px;
	overflow-y: auto;
}

.crte-test-rqst-encntr-container .enctr-sumry-title {
	font: normal bold 14px/1.43 RionaSans-Bold;
	margin-bottom: 24px;
	display: inline-block;
}

.crte-test-rqst-encntr-container .patient-name {
	font: normal bold 13px/1.38 RionaSans;
	margin-bottom: 8px;
}

.crte-test-rqst-encntr-container .patient-phone {
	margin-bottom: 8px;
}

.crte-test-rqst-encntr-container .consultation-type {
	font: normal bold 13px/1.38 RionaSans;
	padding: 16px 0 8px;
	border-top: 1px dashed #d8dde4;
	margin-top: 16px;
}

.crte-test-rqst-encntr-container .patient-refral-prgrm {
	font-weight: 500;
	font-family: RionaSans-Medium;
	overflow-wrap: break-word;
}

.crte-test-rqst-encntr-container .enctr-sumry-state {
	font-weight: bold;
	font-family: RionaSans-Bold;
}

.crte-test-rqst-encntr-container .enctr-sumry-prvdr-name,
.crte-test-rqst-encntr-container .enctr-sumry-tm-platform {
	font: normal 11px/1.36 RionaSans;
}
.crte-test-rqst-encntr-container .enctr-sumry-tm-platform {
	padding: 1px 0;
	text-transform: capitalize;
	margin-top: 2px;
}
.crte-test-rqst-encntr-container
	.enctr-sumry-tm-platform
	.aptmnt-smry-modality-icon {
	margin-right: 4px;
}

.crte-test-rqst-encntr-container .enctr-sumry-prvdr-name {
	font-weight: bold;
	display: flex;
	align-items: center;
}

/* start: override element react style  */
.crte-test-rqst-dialog .el-dialog__header {
	padding: 0 !important;
}

.crte-test-rqst-dialog .el-dialog__body {
	padding: 0 !important;
}

.create-test-rqst-labinfo-container .el-select {
	display: block;
}

.create-test-rqst-labinfo-container
	.el-select:not(.test-request-multiselect)
	.el-input__inner,
.create-test-rqst-labinfo-container .el-cascader .el-input__inner,
.create-test-rqst-labinfo-container .el-date-editor .el-input__inner,
.create-test-rqst-labinfo-container .el-input .el-input__inner {
	padding-top: 8px;
	padding-bottom: 8px;
}

.crte-test-rqst-dialog .el-dialog__footer {
	padding: 12px 16px 12px;
}

.crte-test-rqst-encntr-container .el-col-24 {
	word-wrap: break-word;
}

.create-test-rqst-labinfo-container
	.el-cascader.is-disabled
	.el-cascader__label {
	color: #bbbbbb !important;
}
/* end: override element react style  */

/*****************************************************
                Test Request Tab
******************************************************/
.test-rqst-info-tab-container .field-value {
	font: normal 500 14px/1.43 RionaSans-Medium;
	padding-bottom: 16px;
}

.test-rqst-info-tab-container .icd-10-containers {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}
.test-rqst-info-tab-container .test-request-code {
	margin-bottom: 4px;
}
.test-rqst-info-tab-container .icd-10-containers .icd10-bubble {
	border: 1px solid #bd92e4;
	border-radius: 4px;
	height: 20px;
	margin-right: 8px;
	margin-bottom: 4px;
	padding: 3px 4px;
	background-color: #ddcaef;
	font: normal 500 11px/1.36 RionaSans;
	color: #491678;
}

.test-rqst-info-tab-container .padding-bottom-22 {
	padding-bottom: 22px;
}
/* override the style of date time picker*/

.crte-test-rqst-dialog .testrequestdatefield.rdt .rdtPicker {
	padding: 12px;
	box-shadow: 0 2px 6px #ccc;
	border: 1px solid #f9f9f9;
}

.crte-test-rqst-dialog .testrequestdatefield.rdt .rdtPicker .rdtDays table tr,
.crte-test-rqst-dialog .testrequestdatefield.rdt .rdtPicker .rdtMonths table tr,
.crte-test-rqst-dialog .testrequestdatefield.rdt .rdtPicker .rdtYears table tr {
	border-bottom: 0 !important;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th {
	font-size: 14px;
	vertical-align: inherit;
	border-bottom: 0;
	font-weight: 400;
	color: #48576a;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th.rdtPrev,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th.rdtNext,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtPrev,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtNext,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtPrev,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtNext {
	font-size: 21px;
	color: #97a8be;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:first-child
	th:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr:first-child
	th:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr:first-child
	th:hover {
	background-color: transparent;
	color: #20a0ff;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:nth-child(2)
	th {
	font-size: 12px;
	vertical-align: inherit;
	color: #8391a5;
	height: 30px;
	padding-top: 15px;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td {
	font-size: 12px;
	width: 32px;
	height: 32px;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear {
	color: #48576a;
	font-size: 12px;
	height: 32px;
	width: 48px;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear:hover {
	background-color: #e4e8f1;
	text-decoration: underline;
}

/*prev-month and next-month css*/

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtOld,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtOld,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew {
	color: #ddd;
}

/*prev-month and next-month css on hover*/

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtOld:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew:hover {
	background: transparent !important;
}

/*rdt day active .rdtActive rdtDay rdtActive*/

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive:hover {
	background-color: #20a0ff;
	color: #ffffff;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtToday:before {
	content: ' ';
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	border-top: 0.5em solid #20a0ff;
	border-left: 0.5em solid transparent;
	bottom: unset;
	border-bottom: 0;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay:hover {
	background-color: #e4e8f1;
}

/*date and time*/

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot {
	border-top: 0;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td {
	font-size: 14px;
	font-weight: 400;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td:hover {
	background-color: transparent;
	color: #20a0ff;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th {
	height: 32px;
	font-size: 14px;
	font-weight: 400;
	color: #48576a;
	border-bottom: 0;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th:hover,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th:hover {
	background-color: transparent;
	color: #20a0ff;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr {
	border-bottom: 0 !important;
}

.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn,
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtCount {
	font-size: 14px;
	color: #48576a;
}

.crte-test-rqst-dialog
	.testrequestdatefield
	.calendar-right-encounter.rdtPicker {
	right: 0;
}

.crte-test-rqst-dialog
	.testrequestdatefield
	.form-control:not(:disabled)::placeholder {
	font-family: RionaSans;
	font-size: 13px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	opacity: 0.5;
}
.crte-test-rqst-dialog .testrequestdatefield .form-control:hover {
	border: solid 1px #70cbf1 !important;
}
.crte-test-rqst-dialog .testrequestdatefield .form-control:not(:disabled):focus,
.crte-test-rqst-dialog
	.testrequestdatefield
	.form-control:not(:disabled):active {
	border: solid 1.5px #40b9ec !important;
}
.create-test-rqst-labinfo-container
	.el-cascader:hover
	.el-input__inner:not(:disabled),
.create-test-rqst-labinfo-container
	.el-select:hover
	.el-input__inner:not(:disabled),
.create-test-rqst-labinfo-container
	.el-cascader
	.el-input__inner:not(:disabled):hover::placeholder,
.create-test-rqst-labinfo-container
	.el-select:hover
	.el-input__inner:not(:disabled)::placeholder,
.crte-test-rqst-dialog
	.testrequestdatefield
	.form-control:not(:disabled):hover::placeholder,
.create-test-rqst-labinfo-container
	.el-select
	.el-input
	.el-input__inner:not(:disabled):hover::placeholder {
	color: #262837 !important;
	border-color: #70cbf1;
}
.crte-test-rqst-dialog .testrequestdatefield.rdt .form-control,
.edit-test-rqst-dialog-body .testrequestdatefield.rdt .form-control {
	border: 1px solid #bfcbd9;
	height: 36px;
	font-size: 13px !important;
	color: #262837;
	box-shadow: unset;
	font-family: RionaSans;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
}

.crte-test-rqst-dialog .testrequestdatefield.rdt .form-control:disabled:hover {
	border-color: #bfcbd9 !important;
}
.crte-test-rqst-dialog .testrequestdatefield.rdt .form-control:hover {
	border-color: #8391a5;
}
.crte-test-rqst-dialog
	.testrequestdatefield.rdt
	.form-control:not(:disabled):focus {
	border-color: #20a0ff;
	box-shadow: unset;
}
.crte-test-rqst-dialog .testrequestdatefield.rdt::before {
	display: none;
}

.create-test-rqst-labinfo-container .el-row .icd-10-btn-container {
	width: 100%;
	display: flex;
	flex-flow: wrap;
	overflow-y: auto;
	max-height: 110px;
}

.create-test-rqst-labinfo-container .el-input .el-input__inner::placeholder,
.create-test-rqst-labinfo-container .aoe-questions-type-phone::placeholder {
	color: #262837;
	opacity: 0.5;
}

.create-test-rqst-labinfo-container
	.el-input.is-disabled
	.el-input__inner::placeholder,
.create-test-rqst-labinfo-container .form-control:disabled::placeholder {
	opacity: 1;
}

.create-test-rqst-labinfo-container .el-input.is-disabled .el-input__inner,
.create-test-rqst-labinfo-container
	.el-input.is-disabled
	.el-input__inner::placeholder,
.create-test-rqst-labinfo-container .form-control:disabled,
.create-test-rqst-labinfo-container .form-control:disabled::placeholder {
	background-color: #edeff3;
	color: #bac3ce !important;
}

.create-test-rqst-labinfo-container .el-input .el-input__inner::placeholder,
.create-test-rqst-labinfo-container .form-control::placeholder,
.create-test-rqst-labinfo-container .aoe-questions-type-phone::placeholder {
	font: normal 13px RionaSans !important;
}

.create-test-rqst-labinfo-container .integration-supported svg {
	transform: scale(1.46, 1.46);
    margin-top: 4px;
}

.create-test-rqst-labinfo-container .integration-supported svg > path {
	fill: #4b3700 !important;
}

/*****************************************************
          Edit Mode: edit test header  section
******************************************************/
.edit-test-order-modal-top-row {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 34px;
}

.edit-test-order-modal-header-wrapper {
	display: flex;
	flex-direction: row;
	padding: 9px 16px 0;
}

.edit-test-order-modal-close {
	transform: scale(1.4);
	height: 27px;
	width: 27px;
	border-radius: 16px;
	position: relative;
	top: 6px;
	cursor: pointer;
	margin-left: auto;
	z-index: 1;
}

.edit-test-order-modal-close:hover {
	background-color: #ececec;
}

.edit-test-order-modal-close:active {
	background-color: #c7c7c7;
}

.edit-test-order-modal-close > div > div > svg > g > g {
	fill: black !important;
}

.edit-test-order-modal-header-wrapper .edit-test-order-tabs-wrapper {
	margin: 0 0 0 -18px;
}

.edit-test-order-tabs-wrapper {
	height: 100%;
}

.edit-test-order-tabs-wrapper .el-tabs__header {
	margin: 0;
}

.edit-test-order-tabs-wrapper .el-tabs__nav-wrap {
	padding-left: 16px;
}

.edit-test-order-tabs-wrapper .el-tabs__content {
	height: calc(100% - 28px);
}

.edit-test-order-tabs-wrapper .el-tabs__content .el-tab-pane {
	height: 100%;
}

.edit-test-order-tabs-wrapper .el-tabs__item {
	height: 24px;
	line-height: 24px;
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b8291;
	margin-right: 16px;
	padding: 0;
}

.edit-test-order-tabs-wrapper .el-tabs__item:hover {
	color: #7b26c9;
}

.edit-test-order-tabs-wrapper .el-tabs__item:active {
	color: #7b8291;
	height: 24px;
	border-bottom: 5px solid #7b8291;
}

.edit-test-order-tabs-wrapper .el-tabs__item.is-active {
	padding: 0;
	border-bottom: 5px solid #7b26c9;
	color: #7b26c9;
}

.edit-test-order-tabs-wrapper .el-tabs__active-bar {
	width: 0 !important;
}

/* To hide audit trail in test order popup */
.edit-test-order-tabs-wrapper div[name='AuditTrail'] {
	display: none;
}

/*****************************************************
          Encounter and Test Requeset Tab
******************************************************/
.crte-test-rqst-fields-container
	.test-order-encntr-testrequest-tabs-wrapper
	.el-tabs__header {
	border-bottom: 1px solid #edeff3;
	margin: 0 !important;
}

.test-order-encntr-testrequest-tabs-wrapper .el-tabs__nav-wrap {
	padding-left: 0;
}

.test-order-encntr-testrequest-tabs-wrapper .el-tabs__item {
	width: 126px;
	height: 40px;
	padding-top: 11px !important;
	text-align: center;
	font: normal 12px RionaSans;
	background-color: #edeff3;
	border-bottom: 1px solid #d8dde4;
	color: #7b8291;
	margin-right: 0;
}

.crte-test-rqst-fields-container
	.test-order-encntr-testrequest-tabs-wrapper
	.el-tabs__item:active {
	background-color: transparent;
	color: #7b8291;
	opacity: 1;
	height: 40px;
}

.test-order-encntr-testrequest-tabs-wrapper .el-tabs__item.is-active {
	margin-bottom: 0;
}

.crte-test-rqst-fields-container
	.test-order-encntr-testrequest-tabs-wrapper
	.el-tabs__item.is-active,
.edit-test-order-tabs-wrapper
	.test-order-encntr-testrequest-tabs-wrapper
	.el-tabs__item.is-active {
	color: #7b26c9;
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: center;
}

.crte-test-rqst-fields-container
	.test-order-encntr-testrequest-tabs-wrapper
	.el-tabs__active-bar {
	width: 125px;
	background-image: linear-gradient(to right, #9551d3, #680cc6);
}

/*****************************************************
          Create/Edit Mode: lab/test section
******************************************************/

.create-test-rqst-labinfo-container-v2 {
	overflow-y: auto;
}

.edit-test-rqst-labinfo-container-v2 {
	overflow-y: auto;
	height: 100%;
}

.crte-test-rqst-dialog-v2 {
	height: calc(100% - 116px);
}

.edit-test-rqst-dialog-body {
	height: calc(100% - 94px);
}

.edit-test-rqst-dialog-v2 {
	height: 100%;
}

.edit-test-rqst-dialog-v2 .el-cascader.is-opened .el-cascader-menus {
	left: 0 !important;
}

.edit-test-rqst-dialog-v2 .cascade-edit-test-order.is-opened .el-cascader-menus .el-cascader-menu,
.create-test-rqst-labinfo-container-v2 .el-cascader.is-opened .el-cascader-menus .el-cascader-menu {
	height: auto;
}

.edit-test-rqst-dialog-v2 .cascade-edit-test-order.is-opened .el-cascader-menus .el-cascader-menu:last-child,
.create-test-rqst-labinfo-container-v2 .el-cascader.is-opened .el-cascader-menus .el-cascader-menu:last-child {
	border-left: 1px solid #d1dbe5;
}

.edit-test-rqst-dialog-v2
	.cascade-edit-test-order.is-opened
	.el-cascader-menus
	.el-cascader-menu__item {
	max-width: 166px;
	height: fit-content;
	white-space: break-spaces;
}

.pre-auth-test-rqst-dialog-body
	.cascade-edit-test-order.is-opened
	.el-cascader-menus
	.el-cascader-menu__item {
	max-width: 205px;
	height: fit-content;
	white-space: break-spaces;
}

.edit-test-rqst-dialog-v2 .chargeICDSearchPlaceholderContainer {
	justify-content: center;
}

.edit-test-rqst-dialog-v2
	.chargeICDSearchPlaceholderContainer
	.chargeICDSearchPlaceholderIconAndText {
	margin-top: initial;
}

.crte-test-rqst-dialog-v2 .crte-test-rqst-fields-container {
	display: flex;
}

.crte-test-rqst-fields-container .testrequestdatefield.rdt::before {
	border-bottom: transparent;
}

.create-test-rqst-labinfo-container h2.module-header {
	display: flex;
	width: 100%;
	align-items: center;
	font: normal bold 14px/1.43 RionaSans-Bold;
	margin: 35px 0 16px;
}

.create-test-rqst-labinfo-container h2.module-header::after {
	margin: 0 0 0 10px;
	flex: 1 0 20px;
	border-top: 1px solid #edeff3;
	content: '';
}

.create-test-rqst-labinfo-container h2.module-header:first-child {
	margin: 0 0 16px;
}

/* pull calendar left side */
.edit-test-rqst-labinfo-container-v2 .calendar-pull-left .rdtPicker {
	right: 0;
}

/* pull calendar right side */
.edit-test-rqst-labinfo-container-v2 .calendar-pull-right .rdtPicker,
.pre-auth-test-rqst-labinfo-container .calendar-pull-right .rdtPicker {
	left: 0;
}

/* pull calendar up side */
.edit-test-rqst-labinfo-container-v2 .calendar-pull-up .rdtPicker,
.pre-auth-test-rqst-labinfo-container .calendar-pull-up .rdtPicker {
	bottom: 36px;
}

/* show pill for reflex order */
.edit-test-rqst-labinfo-container-v2 .reflex-order-pill {
	margin: 35px 0 -28px;
}

.create-test-rqst-labinfo-container
	.el-select
	.el-scrollbar
	.sample-type-other
	.el-select-group__title {
	color: #262837;
	font-family: RionaSans-Bold !important;
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
}

.create-test-rqst-labinfo-container-v2 .select-lab,
.edit-test-rqst-labinfo-container-v2 .select-lab {
	display: flex;
	height: 32px;
	padding: 7px 8px;
}

.create-test-rqst-labinfo-container-v2 .select-lab .lab-name,
.edit-test-rqst-labinfo-container-v2 .select-lab .lab-name {
	font: normal 13px/1.38 RionaSans;
}

.create-test-rqst-labinfo-container-v2 .select-lab .lab-paper-trf,
.edit-test-rqst-labinfo-container-v2 .select-lab .lab-paper-trf {
	width: 48px;
	height: 14px;
	margin: 2px 0 2px auto;
	font: normal 10px/1.4 RionaSans;
}

/**************************************************************
                    encounter audit trail
**************************************************************/
.encounter-audit-trail-wrapper {
	display: flex;
	height: 100%;
}

.encounter-audit-trail-wrapper > .loader {
	width: 398px;
	padding: 22.5px 16px;
	height: 100%;
	overflow-y: auto;
}

.encounter-audit-trail-wrapper > .loader > .last-updated {
	width: 100%;
	height: 34px;
	padding: 8px;
	border-radius: 4px;
	background-color: #edeff3;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	color: #262837;
	font-style: italic;
}

.encounter-audit-trail-wrapper > .loader > .logs {
	padding: 18px 10px;
	max-height: calc(100vh - 180px);
}

.encounter-audit-trail-wrapper > .loader > .logs > div {
	padding-left: 20px;
	padding-bottom: 24px;
	border-left: 2px solid #edeff3;
}

.encounter-audit-trail-wrapper > .loader > .logs > div:last-of-type {
	border-left: 2px solid #fff !important;
}

.encounter-audit-trail-wrapper > .loader > .logs > div > div {
	font-family: RionaSans;
	font-size: 12px;
	line-height: 1.42;
	color: #7b8291;
}

.encounter-audit-trail-wrapper > .loader > .logs > div > div.title {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	color: #262837;
	position: relative;
}

.encounter-audit-trail-wrapper > .loader > .logs > div > div.title::before {
	position: absolute;
	content: '';
	display: inline-block;
	background: #262837;
	width: 18px;
	height: 18px;
	left: -30px;
	border-radius: 50%;
	border: 5px solid #fff;
}

/* encounter audit trail end */

.row-checkbox-order-test-manually
	> .chk-order-test-manually
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	border-color: #bfcbd9 !important;
}

.row-checkbox-order-test-manually
	.chk-order-test-manually
	> span.el-checkbox__input.is-checked
	> span.el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.row-checkbox-order-test-manually .el-checkbox__input.is-checked .el-checkbox__inner {
	background-color: #7b26c9 !important;
}

.row-checkbox-order-test-manually
	.chk-order-test-manually
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}

/*****************************************************************************
          Create/Edit Mode: Add another test name in test info section
******************************************************************************/
.create-test-rqst-labinfo-container .add-another-test-radio .el-radio {
	display: inline-flex !important;
    margin-right: 32px !important;
	margin-bottom: 0 !important;
	border: 0;
	padding: 0;
	width: inherit;
	box-shadow: none;
}

/*****************************************************************************
        	Internal test order error popup
******************************************************************************/
.internal-test-order-error-modal-container {
	width: 368px;
	min-height: 150px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: fixed;
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
}

.internal-test-order-error-modal-top-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 9px 16px;
	height: 56px;
	border-bottom: 1.5px solid #edeff3a2;
}

.internal-test-order-error-modal-header {
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #262837;
	margin-bottom: 2px;
	position: unset;
	top: 5px;
	left: 5px;
	margin-top: 8px;
}

.internal-test-order-error-modal-body {
	max-height: calc(100vh - 180px);
	padding: 32px 16px;
	overflow-y: auto;
	font: normal 500 13px/1.38 RionaSans-MediumItalic;
	color: #262837;
}

.internal-test-order-error-modal-buttonrow {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 12px 16px;
	font-size: 13px;
	color: white;
	border-top: 1px solid #edeff3a2;
	height: 60px;
	background-color: #fff;
	position: relative;
}

.internal-test-order-error-refresh-button.el-button {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	color: #ffffff !important;
	border: none;
}

.internal-test-order-error-cancel-button.el-button {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #ffffff;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	color: #5b43bd !important;
	border: 1px solid #5b43bd;
}

.internal-test-order-error-cancel-button.el-button:hover,
.internal-test-order-error-cancel-button.el-button:focus {
	border: 1px solid #5b43bd;
}

.internal-test-order-error-modal-close {
	transform: scale(1.4);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	top: 8px;
	cursor: pointer;
}

.internal-test-order-error-modal-close:hover {
	background-color: #ececec;
}

.internal-test-order-error-modal-close:active {
	background-color: #c7c7c7;
}

.internal-test-order-error-modal-close > div > div > svg > g > g {
	fill: black !important;
}

/**************************************************************
      		Start: No insurance warning message
**************************************************************/
.noinsunrace-info-container {
	width: 100%;
	min-height: 52px;
	border-radius: 4px;
	background-color: #f4a4a5;
}

.noinsunrace-info-container .noinsunrace-info-body {
	display: flex;
	justify-content: center;
	padding: 10px 14px 6px 14px;
}

.noinsunrace-info-container .noinsunrace-info-body .noinsunrace-info-text {
	width: calc(100% - 24px);
	min-height: 36px;
	font-family: "RionaSans-MediumItalic", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	text-align: left;
	color: #4b3700;
	padding-left: 8px;
}

.create-test-rqst-labinfo-container .add-insuranceinfo-button {
	display: flex;
	align-items: center;
	height: 36px !important;
	border-radius: 6.5px;
	border: unset;
	padding: 0;
}

.create-test-rqst-labinfo-container .add-insuranceinfo-button .addIcon {
	width: 28px;
	height: 28px;
}

.create-test-rqst-labinfo-container .add-insuranceinfo-button span {
	font-family: "RionaSans-Medium", sans-serif;
	font-size: 15px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #5b43bd;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
}

.create-test-rqst-labinfo-container .add-insuranceinfo-button .add-icon {
	margin-top: 5px;
}

.create-test-rqst-labinfo-container .add-insuranceinfo-button .add-icon svg {
	height: 22px;
}

.create-test-rqst-labinfo-container .add-insuranceinfo-button .add-icon svg > g > g {
	fill: #5b43bd;
}

/**************************************************************
      		End: No insurance warning message
**************************************************************/
