.App {
	/* margin-top: 70px; */
	margin-top: 15px;
	margin-left: 0vw;
	padding: 0vw;
	width: 100%;
}

#client-snackbar {
	font-size: 13px;
}

.App .navbar-brand {
	font-weight: bold;
}

.container-fluid {
	width: 95vw;
}

.snack_notification > div > span,
span#client-snackbar {
	font-family: 'RionaSans', serif !important;
}

/*Auto refresh message box starts*/

button.auto-refresh-confirm {
	text-align: center !important;
	margin-left: 0px !important;
	width: 100% !important;
}

.refresh-message .el-message-box__btns {
	border-top: 1px solid #ccc !important;
}

/*Auto refresh message box ends*/

.fullModalBackground {
	background-color: rgba(0, 0, 0, 0.35);
	height: 100vh;
	width: 100vw;
	border-radius: 0px;
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 100000;
}

.fullModalBackgroundFlex {
	background-color: rgba(0, 0, 0, 0.35);
	height: 100vh;
	width: 100vw;
	border-radius: 0px;
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 100000;
	display: flex;
}

.envBanner {
	width: 100vw;
	text-align: center;
	z-index: 10000000;
	position: fixed;
	font-size: 14px;
	top: -2px;
	height: 17px;
}

.qa {
	background-color: rgb(241, 196, 15);
}

.dev {
	background-color: rgb(24, 188, 155);
}

.el-notification,
.snack_notification,
.export_notification {
	z-index: 1000000 !important;
}

.el-dialogue__wrapper,
.el-dialog__wrapper {
	z-index: 100000 !important;
}

.v-modal {
	z-index: 99999 !important;
}

.dropdownRightEdgeAligned > div.el-select-dropdown:nth-child(2) {
	left: unset !important;
	right: 0px !important;
}

.dropdownLeftEdgeAligned > div.el-select-dropdown:nth-child(2) {
	left: 0px !important;
	right: unset !important;
}

.sortedHeaderField:hover,
.sortedHeaderField.current_sort {
	color: #262837 !important;
	font-family: 'RionaSans-Bold' !important;
	font-size: 14px !important;
}

.sortedHeaderField:hover > .sortIcon_pop,
.sortedHeaderField.current_sort > .sortIcon_pop {
	opacity: 1;
}

.scrollableModalDropShadowBottom {
	box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.15);
}

.scrollableModalDropShadowTop {
	box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.15);
}

.scrollableModalDropShadowTopInside {
	box-shadow: 0px 8px 9px -7px rgba(0, 0, 0, 0.15);
}

.scrollableModalDropShadowTopAbsolute {
	width: 100%;
	height: 16px;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
}

.scrollableModalDropShadowBottomAbsolute {
	width: 100%;
	height: 16px;
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	position: absolute;
	left: 0px;
	top: calc(100% - 16px);
	z-index: 1;
}

.purpleElement:hover {
	color: #7b26c9 !important;
}

.inputTextAreaDisabled,
.inputTextAreaDisabled:hover,
.inputTextAreaDisabled:focus {
	cursor: no-drop;
	border: 1px solid #bac3ce !important;
	background-color: #edeff3 !important;
}

.purpleText {
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: #7b26c9;
	cursor: pointer;
}

.purpleText:hover {
	text-decoration: underline;
}

.purpleTextSmall {
	font-family: 'RionaSans-Bold';
	font-size: 10px;
}

.reqAst {
	font-family: RionaSans-Bold;
	font-size: 12px;
	color: #e74c3c;
	position: relative;
	bottom: 6px;
}

.switchArrow {
	font-size: 18px;
	position: relative;
	top: 5px;
	left: 2px;
	transition: transform 0.5s;
}

.bg-red {
	background-color: #f4a4a5;
}

.blackWarningAlertIcon {
	transform: scale(1.25);
	position: relative;
	top: 3px;
}

.blackWarningAlertIcon > div > div > svg > g > path {
	fill: #400404;
}

.patientTag {
	max-width: 175px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.modalButton {
	width: 160px;
	height: 36px;
	padding-top: 9px;
	text-align: center;
	user-select: none;
	border-radius: 6.5px;
	background-color: #7b26c9;
	cursor: pointer;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: white;
}

.modalButton:hover {
	background-color: #9c5dd7;
}

.modalButton:active {
	background-color: #621ea0;
}

.modalButtonDisabled {
	opacity: 0.35 !important;
	background-color: #7b26c9 !important;
	cursor: no-drop;
}

.modalButtonRed {
	width: 160px;
	height: 36px;
	padding-top: 9px;
	text-align: center;
	user-select: none;
	border-radius: 6.5px;
	background-color: #e74c3c;
	cursor: pointer;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: white;
}

.modalButtonRed:hover {
	background-color: #ec6759;
}

.modalButtonRed:active {
	background-color: #ad1505;
}

.modalButtonRedDisabled {
	opacity: 0.35 !important;
	background-color: #e74c3c !important;
	cursor: no-drop;
}

.modalCancelButtonRed {
	width: 160px;
	height: 36px;
	padding-top: 9px;
	text-align: center;
	user-select: none;
	border-radius: 6.5px;
	border: 1px solid #e74c3c;
	cursor: pointer;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: #e74c3c;
}

.modalCancelButtonRed:hover {
	background-color: rgba(231, 76, 60, 0.16);
}

.modalCancelButtonRed:active {
	background-color: rgba(231, 76, 60, 0.3);
}

.modalCancelButtonRedDisabled {
	opacity: 0.35 !important;
	background-color: white !important;
	cursor: no-drop;
}

.modalCancelButtonPurple {
	width: 160px;
	height: 36px;
	padding-top: 9px;
	text-align: center;
	user-select: none;
	border-radius: 6.5px;
	border: 1px solid #7b26c9;
	cursor: pointer;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: #7b26c9;
}

.modalCancelButtonPurple:hover {
	background-color: rgba(123, 38, 201, 0.16);
}

.modalCancelButtonPurple:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.modalCancelButtonPurpleDisabled {
	opacity: 0.35 !important;
	background-color: white !important;
	cursor: no-drop;
}

.modalButtonPurple {
	width: 160px;
	height: 36px;
	padding-top: 9px;
	text-align: center;
	user-select: none;
	border-radius: 6.5px;
	background-color: #7b26c9;
	cursor: pointer;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: white;
}

.modalButtonPurple:hover {
	background-color: #9c5dd7;
}

.modalButtonPurple:active {
	background-color: #621ea0;
}

.modalButtonPurpleDisabled {
	opacity: 0.35 !important;
	background-color: #7b26c9 !important;
	cursor: no-drop;
}

.modalTitle {
	font-family: 'RionaSans-Bold';
	font-size: 16px;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.modalTitleIcon {
	position: relative;
	transform: scale(1.3);
	margin-right: 14px;
	top: 5px;
}

.modalTitleIconRed {
	position: relative;
	transform: scale(1.3);
	margin-right: 14px;
	top: 5px;
}

.modalTitleIconYellow > div > svg > g > path {
	fill: #f1c40f;
}

.textEllipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.whiteBackgroundTooltip > div.is-light {
	color: black;
}

.refreshModalCheckboxRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	color: #262837;
	margin-top: 20px;
}

.grayBorderTop {
	border-top: 1px solid #edeff3;
}

.el-date-range-picker.has-sidebar
	> div.el-picker-panel__body-wrapper
	> div.el-picker-panel__sidebar
	> button.el-picker-panel__shortcut {
	color: #7b26c9;
	font-family: 'RionaSans-Medium';
}

.halfWidth {
	width: 50% !important;
}

.tooltipWidth {
	max-width: 100% !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	/* .top-mobi
  
  {
    margin-top: 9vh !important;
  } */
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.stage_title {
		font-size: 2.7vw !important;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	input {
		font-size: 2.3vw !important;
	}
	i.material-icons.search {
		font-size: 3vw;
	}
	i.material-icons.logged_in_user {
		font-size: 3vw;
	}
	.stage_title {
		font-size: 2vw !important;
	}
	i.material-icons.stage {
		font-size: 2.8vw !important;
		line-height: 1.4 !important;
	}
	.stage_patients_count {
		font-size: 2.5vw !important;
	}
	.btn-info {
		font-size: 1.8vw !important;
	}
}

.no-click {
	pointer-events: none;
	text-decoration: none;
	cursor: default;
}
