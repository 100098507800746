@use 'system';

$sidebarWidth: 60px;
$navbarHeight: 46px;

.page-container {
	width: calc(100% - $sidebarWidth) !important;
	margin-left: $sidebarWidth !important;
	margin-top: $navbarHeight !important;
	min-height: calc(100vh - $navbarHeight);
	position: relative;

	.box {
		background-color: white;
		color: #262837;
		border-radius: system.$quarterUnit;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
		font-size: 12px;
	}

	.column-layout-3 {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: system.$singleUnit;
	}

	.column-layout-2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: system.$singleUnit;
	}
}
