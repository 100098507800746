.gmi-el-pagination.el-pagination .btn-prev,
.gmi-el-pagination.el-pagination .btn-next {
	border: none !important;
	background-color: transparent !important;
	color: #a067d6 !important;
}

.gmi-el-pagination.el-pagination .btn-prev.disabled,
.gmi-el-pagination.el-pagination .btn-next.disabled {
	pointer-events: none;
	opacity: 0.45;
}

.gmi-el-pagination.hide-button button {
	display: none;
}

.gmi-el-pagination.el-pagination button:hover {
	background-color: rgba(160, 171, 185, 0.08) !important;
	color: #97a8be !important;
}

.gmi-el-pagination.el-pagination button.disabled:hover {
	background-color: transparent;
}

.gmi-el-pagination .el-pager li {
	border: none;
	padding: 4px 4px 0;
	font: normal bold 15px RionaSans, serif !important;
	color: #a0abb9 !important;
	cursor: pointer !important;
	background-color: transparent;
}

.gmi-el-pagination .el-pager li.active {
	background-color: transparent;
	color: #7b26c9 !important;
}

.gmi-el-pagination .el-pager li.active:hover {
	background-color: rgba(123, 38, 201, 0.08);
}

.gmi-el-pagination .el-pager li:hover {
	background-color: rgba(160, 171, 185, 0.08);
}

.gmi-el-pagination .el-pager li:last-child {
	border-right: none;
}

.gmi-el-pagination .el-pager [class*=' el-icon-'],
.gmi-el-pagination .el-pager[class^='el-icon-'] {
	font-family: element-icons !important;
	font-size: 10px !important;
	padding-top: 12px;
}

.gmi-el-pagination .el-pager .el-icon-d-arrow-right::before {
	pointer-events: none;
	content: '\e61a';
}

.gmi-el-pagination .el-pager .el-icon-more,
.gmi-el-pagination .el-pager .el-icon-more:before {
	pointer-events: none;
}
