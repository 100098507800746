.LinkPopover {
	width: fit-content;
}
.LinkPopover .LinkPopoverContentMain {
	width: fit-content;
}
.LinkPopover .el-popover .LinkPopoverContent {
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-font-smoothing: antialiased;
}
.LinkPopover .el-popover {
	padding: 8px;
	border-radius: 8px;
	background-color: #ffffff;
	min-width: 48px !important;
}
.LinkPopover .el-popover[x-placement^='bottom'],
.LinkPopover .el-popover[x-placement^='top'] {
	margin: 4px !important;
}
.LinkPopover .el-popover .LinkPopoverContent .redirectURL {
	max-width: 370px;
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #262837;
	text-transform: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 16px;
}
.LinkPopover .el-popover .LinkPopoverContent .LinkIcon > div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	cursor: pointer;
}
.LinkPopover .el-popover .LinkPopoverContent .LinkIcon > div:hover {
	background-color: #d8dde4;
}
.LinkPopover .el-popover .LinkPopoverContent .LinkIcon > div:active {
	background-color: #bac3ce;
}
.LinkPopover .el-popover .LinkPopoverContent .separator {
	margin: 0px 8px 0px 8px;
	height: 24px;
	border-left: 1px solid #d8dde4;
}
.LinkPopover.single-line,
.LinkPopover.single-line .LinkPopoverContentMain {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: fit-content !important;
}
