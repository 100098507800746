.user-account-management-wrapper {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 0 0 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.user-account-management-wrapper .three-dots-wrapper > span {
	display: flex;
}

.user-account-management-wrapper .three-dots-wrapper button,
.user-account-management-wrapper .three-dots-wrapper button * {
	width: 32px;
	height: 32px;
}

.user-account-management-wrapper table td p {
	margin: unset;
}

.user-account-management-wrapper table td .sub {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.user-account-management-wrapper table td .three-dots-open li:last-child {
	border-top: 1.5px solid rgba(184, 190, 203, 0.2);
	padding: 8px;
}
