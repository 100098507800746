.appointment-summary-title {
	font: normal bold 20px RionaSans-Bold;
	color: #262838;
	margin-bottom: 24px;
}

.scheduleAppointmentSideContainer .patient-name {
	width: 100%;
	margin-bottom: 12px;
	font: normal bold 14px RionaSans-Bold;
	color: #262838;
}

.patient-apt-summary-email,
.patient-apt-summary-phone,
.patient-apt-summary-dob,
.patient-apt-summary-program,
.patient-apt-summary-age {
	font: normal 12px RionaSans;
	color: #262838;
}

.patient-apt-summary-program {
	font-weight: bold;
}

.completed-visit-text {
	border-top: 1px solid #d8dde4;
	padding-top: 20px;
	margin: 64px 0 20px;
	font: normal bold 14px RionaSans-Bold;
	color: #525968;
	text-transform: uppercase;
}
