.encntr-detail .header-wrapper {
	margin: 16px 20px;
}

.encntr-detail .header-container {
	display: flex;
	flex-direction: column;
}

.encntr-detail .enctr-list-backlink,
.encntr-detail .copy-host-link {
	font: normal 12px/1.42 RionaSans;
	color: #40b9ec;
}

.encntr-detail .enctr-list-backlink {
	cursor: pointer;
	width: 94px;
}

.encntr-detail .copy-host-link {
	margin-left: auto;
	margin-right: 65px;
}

.encntr-detail .second-row-wrapper,
.encntr-detail .third-row-wrapper {
	display: flex;
	align-items: center;
}

.header-container
	> div.second-row-wrapper
	> .appointmentMenuContainer:nth-child(2) {
	margin-left: auto;
}

.encntr-detail .enctr-typr-cnslt-type {
	font: normal bold 18px/1.39 RionaSans-Bold;
	color: #262837;
	padding: 4px 0;
}

.encntr-detail .btn-complete-visit,
.encntr-detail .btn-join-zoom {
	display: flex;
	align-items: center;
	height: 36px;
	border-radius: 6px;
	border: none;
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #ffffff;
	background-color: #7b26c9;
	text-transform: capitalize;
	cursor: pointer;
}

.encntr-detail .btn-complete-visit:hover,
.encntr-detail .btn-join-zoom:hover {
	background-color: #9c5dd7;
}

.encntr-detail .btn-complete-visit:active,
.encntr-detail .btn-join-zoom:active {
	background-color: #621ea0;
}

.encntr-detail .btn-visit-to-scp,
.encntr-detail .btn-scp-to-visit {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	background-color: #ffffff;
	text-transform: capitalize;
	margin-left: auto;
}

.btn-visit-to-scp:nth-child(2),
.btn-join-zoom:nth-child(2) {
	margin-left: auto !important;
}

.encntr-detail .btn-visit-to-scp:hover,
.encntr-detail .btn-scp-to-visit:hover,
.encntr-detail .btn-visit-to-scp:focus,
.encntr-detail .btn-scp-to-visit:focus {
	background-color: rgba(123, 38, 201, 0.15);
}

.encntr-detail .btn-visit-to-scp:active,
.encntr-detail .btn-scp-to-visit:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.encntr-detail .btn-visit-to-scp {
	min-width: 172px;
}

.encntr-detail .btn-scp-to-visit.disabled:hover {
	background-color: #ffffff;
}

.encntr-detail .btn-complete-visit {
	width: 151px;
}

.encntr-detail .btn-join-zoom {
	width: 124px;
}

.encntr-detail .icon-complete-visit,
.encntr-detail .icon-join-zoom,
.encntr-detail .icon-visit-to-scp,
.encntr-detail .icon-scp-to-visit {
	margin-right: 8px;
}

.encntr-detail .icon-scp-to-visit svg {
	vertical-align: middle;
}

.encntr-detail .icon-scp-to-visit path {
	fill: #7b26c9;
}

.encntr-detail .aptmnt-status {
	font: normal bold 13px/1.38 RionaSans-Bold;
	text-transform: capitalize;
	display: flex;
	align-items: center;
}

.encntr-detail .divider {
	width: 2px;
	height: 36px;
	background: #bac3ce;
	margin: 0 24px;
}

.encntr-detail .visitstatus-icon {
	margin-right: 4px;
	width: 18px;
	height: 18px;
}

/********************************
  encounter visit status color 
*********************************/
.encntr-detail .status-yellow {
	color: #f1c40f !important;
}

.encntr-detail .status-red {
	color: #e74c3c !important;
}

.encntr-detail .status-green {
	color: #00d65b !important;
}

.encntr-detail .status-blue {
	color: #40b9ec !important;
}

.encntr-detail .status-gray {
	color: #a0abb9 !important;
}

.encntr-detail .status-gray-dark {
	color: #525968 !important;
}
