.noaccesstext {
	margin-top: 12%;
	margin-left: 30%;
	margin-right: 16%;
	margin-bottom: 16%;
	padding: 0vw;
	width: 36%;
	text-align: center;
}

.noaccess_goback {
	text-decoration: underline;
	color: #337ab7;
	cursor: pointer;
}

.no-access .nav .container {
	padding-left: 64px;
	padding-right: 64px;
	margin-top: 10px;
	display: flex;
}

.no-access .nav .container .mob-logo {
	float: left;
}
