.test-order-status-wrapper {
	max-width: fit-content;
	min-height: 24px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	border-radius: 24px;
	padding: 3px 12px 3px 12px !important;
	text-transform: capitalize;
}
.test-order-status-wrapper .test-order-status.ellipsed {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.test-order-status-wrapper .test-order-status {
	max-width: fit-content;
	font: 13px/1.38 RionaSans-Bold;
	color: #4b3700;
}

/*Red*/
.test-order-status-wrapper.red {
	background-color: #f4a4a5;
}
.test-order-status-wrapper.red .order_status_tooltip {
	color: #400404;
}

/*Green*/
.test-order-status-wrapper.green {
	background-color: #a2ffad;
}
.test-order-status-wrapper.green .order_status_tooltip {
	color: #004e09;
}

/*Blue*/
.test-order-status-wrapper.blue {
	background-color: #9fdcf5;
}
.test-order-status-wrapper.blue .order_status_tooltip {
	color: #00394b;
}

/*Yellow*/
.test-order-status-wrapper.yellow {
	background-color: #fde776;
}
.test-order-status-wrapper.yellow .order_status_tooltip {
	color: #4b3700;
}

/*Gray*/
.test-order-status-wrapper.gray {
	background-color: #edeff3;
}
.test-order-status-wrapper.gray .order_status_tooltip {
	color: #262837;
}
