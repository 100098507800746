@use '../core' as core;
@use '../../../../Common/V3/system';
$white: #ffffff;
$cyan-blue: #edeff3;
$baritone-blue: #262837;

.#{core.$scopeClass} {
	.documents {
		.upload-document-filename-row {
			padding: system.$halfUnit;
			background-color: $white;
			margin-bottom: system.$singleUnit;

			&.has-file {
				border-radius: 4px;
			}
		}

		.upload-document-filename-content {
			display: flex;
			align-items: center;
			margin-bottom: system.$halfUnit;
			background-color: $cyan-blue;

			.react-svg-icon {
				cursor: pointer;
				margin-right: 2px;
			}

			.upload-document-filename-icon {
				margin-right: 2px;
			}

			.upload-document-filename-text {
				color: $baritone-blue;
				font-size: 12px;
				font-family: RionaSans;
			}
		}

		.button-content {
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateY(-10px);
		}

		.upload-button-text {
			line-height: 1;
			margin-left: system.$halfUnit;
		}
	}
}
