.btn-export-rise {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #7b26c9;
	width: 120px;
	min-width: 120px;
	height: 36px;
	color: #ffffff;
	padding-left: 8px;
	padding-bottom: 6px;
	border-radius: 6px;
	padding-top: 9px;
	margin-left: 20px;
	cursor: pointer;
	font: normal 500 13px/1.38 RionaSans-Medium;
	margin-right: 39px;
	color: #ffffff;
	background-color: #7b26c9;
}
.btn-export-rise.disabled {
	opacity: 0.5;
	cursor: default;
}

.btn-export-rise:hover {
	background-color: #9c5dd7;
}

.btn-export-rise:active {
	background-color: #621ea0;
}

.btn-export-rise-txt {
	padding: 16px 9px 16px 9px;
	user-select: none;
}

.dashboard-cards-cont {
	width: 100%;
}

.dashboard-grid-cont {
	display: grid !important;
	flex-wrap: wrap;
	grid-template-columns: auto auto auto auto auto;
	width: 100%;
	display: flex;
	box-sizing: border-box;
	margin: 0;
	flex-grow: 0;
	max-width: 100%;
	flex-basis: 100%;
}
.main-grid-container {
	display: flex;
	align-items: center;
	padding: 0 6px 0 52px;
}
.left-icon {
	transform: rotate(180deg);
}
.left-icon svg,
.right-icon svg {
	width: auto !important;
	height: 34px !important;
}
.right-icon {
	margin-left: -40px;
}
.dashboard-grid-container {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	max-width: 100%;
	flex-flow: wrap;
}
.rise-stage-card {
	margin: 0;
	box-sizing: border-box;
	padding: 0 15px;
	width: calc(100% / 3 - 15px);
	cursor: pointer;
}
.rise-wrapper {
	width: 100%;
	margin: 46px 0;
}
.rise-wrapper .custom-table,
.rise-wrapper .custom-table > table {
	width: 100% !important;
	margin: 0 auto;
	max-width: calc(100% - 65px);
	min-width: auto;
}

.rise-dashrd-list {
	margin-top: 30px;
}

.rise-stage-card > div {
	margin-left: 0 !important;
	margin-bottom: 2vw !important;
	border-radius: 0.4vw !important;
	-o-box-shadow: 0 0.073vw 0.29vw 0 rgba(0, 0, 0, 0.14) !important;
	box-shadow: 0 0.073vw 0.29vw 0 rgb(0 0 0 / 14%) !important;
	padding: 0 !important;
	display: flex;
	position: relative;
	word-wrap: break-word;
	flex-direction: column;
	background-color: white;
	transition: all 300ms linear;
	margin-right: 0;
	width: 100%;
	height: auto;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
}
.rise-stage-card .bucket_cards:hover {
	background-color: white !important;
}

.rise-stage-card .bucket_cards .contentbox:hover {
	box-shadow: 0px -15px 0px 0px #7b26c9 inset !important;
}
.rise-stage-card .meets_criteria .contentbox:hover {
	box-shadow: 0px -15px 0px 0px #e74c3c inset !important;
}

.rise-stage-card .contentbox {
	height: auto;
	margin: 0;
	padding: 0;
}

.rise-stage-card .contentbox-disable {
	opacity: 0.5;
	cursor: default;
}
.rise-stage-card .card-title {
	font-size: 18px;
	margin-top: 25px;
	padding-top: 0px;
	min-height: 51px;
	padding: 0 10px;
}

.rise-stage-card .card-number {
	color: #7b26c9;
	font-size: 60px;
	padding-bottom: 15px;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
}

.rise-dashboard-fltr-wraper {
	justify-content: space-between;
	width: 100%;
	max-width: calc(100% - 135px);
	margin: 0 auto;
	padding: 0;
}
.rise-dashboard-fltr-wraper .btn-export-rise {
	margin-left: 0;
}

.rise-stage-card .meets_criteria .card-number {
	color: #e74c3c !important;
}
.ext_patient_id:hover {
	text-decoration: underline;
	cursor: pointer;
}
.smry_clickable:hover {
	cursor: pointer;
	text-decoration: underline;
}
