.externalProviderModalContainer {
	width: 400px;
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	overflow: hidden;
	position: fixed;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.externalProviderModalLoading {
	position: relative;
	top: 50%;
}

.externalProviderModalTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 56px;
	border-bottom: 1px solid #edeff3;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	padding: 17px 16px;
}

.externalProviderTopRowXIcon {
	width: 43px !important;
	height: 43px !important;
	border-top-right-radius: 4px;
	transform: scale(1.3);
	position: relative;
	bottom: 11px;
	left: 10px;
}

.externalProviderTopRowXIcon > div > svg {
	top: 8px !important;
	left: 9px !important;
}

.externalProviderModalBody {
	width: 100%;
	padding: 32px 16px 0px 16px;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 240px);
	overflow-y: overlay;
}

.externalProviderModalFieldsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.externalProviderModalFieldsSwitch {
	margin-top: 16px;
	margin-bottom: 29px;
	user-select: none;
	width: 175px;
}

.externalProviderModalArrow {
	font-size: 18px;
	position: relative;
	top: 5px;
	left: 2px;
	transform: rotate(90deg);
	transition: transform 0.5s;
}

.externalProviderModalSelectPillHeader {
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px !important;
	color: #262837;
}

.externalProviderModalSelectPillContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 16px;
	margin-top: 2px;
}

.inputFieldDropdown.externalProviderOneQuarterWidthDropdown > div.el-input,
.externalProviderOneQuarterWidthText {
	width: 72px !important;
}

.inputFieldDropdown.externalProviderTwoQuarterWidthDropdown > div.el-input,
.externalProviderTwoQuarterWidthText {
	width: 160px !important;
}

.inputFieldDropdown.externalProviderThreeQuarterWidthDropdown > div.el-input,
.externalProviderThreeQuarterWidthText {
	width: 247px !important;
}

.inputFieldDropdown.externalProviderFourQuarterWidthDropdown > div.el-input,
.externalProviderFourQuarterWidthText {
	width: 336px !important;
}

.externalProviderModalButtonRow {
	width: 100%;
	padding: 11.5px 16px;
	border-top: 1px solid #edeff3;
}
