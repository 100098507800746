html {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;

	/*font-family: "Riona Sans", sans-serif;*/
	font-family: 'RionaSans', serif !important;
	font-size: 16px;
	color: #333;
	height: 100%;
	background: #f5f5f5;
	background-color: #edeff3;
}
div#root {
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	/*font-family: "PT Serif", serif;*/
}

select.form-control,
textarea.form-control,
input.form-control {
	font-size: 16px;
}
input[type='file'] {
	width: 100%;
}

nav.navbar.navbar-default {
	background-color: transparent;
	border-color: transparent;
	/* width: 18vw; */
}
.navbar .navbar-default .container {
	width: 85%;
}

/*----------------- changes---------------------*/

.browstext {
	display: none;
}

.rootscroll {
	/* overflow-x: hidden;
		overflow-y: hidden;
		min-height: 800px; */
}

/*----------------- media---------------------*/

@media only screen and (min-width: 0px) and (max-width: 1292px) {
	.rootscroll {
		/* overflow-x: scroll !important; */
		min-height: 800px;
	}

	.EncounterList {
		width: 100%;
	}

	.testDetailViewContainer {
		width: 992px;
	}

	.patients_dashboard.self_service_report {
		/* min-width: 1364px; */
		margin-left: 720px;
	}
}

@media only screen and (min-width: 0px) and (max-width: 1440px) {
	body {
		/* overflow-x: scroll; */
	}
	.rootscroll {
		/* overflow-x: scroll !important; */
		min-height: 800px;
	}
	.patients_dashboard.self_service_report {
		/* min-width: 1364px; */
		margin-left: 720px;
	}
}

@media only screen and (min-width: 0px) and (max-width: 1060px) {
	.patients_list_part {
		width: 1008px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.rootscroll {
		overflow-x: auto;
	}
}

@media only screen and (min-width: 0px) and (max-width: 319px) {
	.lessres {
		display: none;
	}

	.browstext {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 45%;
		-moz-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		display: block;
	}
	.App {
		margin-top: 18.42vw;
	}
}
