@import './../../colors.css';
.leftNavContainer {
	width: 6vw;
	background-color: var(--left-nav-color);
	top: 0;
	left: 0;
	height: 100%;
	z-index: 1;
	display: block;
	position: fixed;
	background-size: cover;
	box-shadow:
		0 10px 30px -12px rgba(0, 0, 0, 0.42),
		0 4px 25px 0px rgba(0, 0, 0, 0.5),
		0 8px 10px -5px rgba(0, 0, 0, 0.2);
	text-align: center;
	bottom: 0;
	min-height: 100vh;
}

.overlayDimmer {
	position: fixed;
	top: -10px;
	width: 100%;
	height: calc(100% + 10px);
	background-color: rgb(0, 0, 0, 0.35);
	transition: opacity 0.25s;
}

.leftNavContainerExpanded {
	width: 220px;
	min-width: 220px;
	background-color: #ffffff;
	transition:
		width 0.18s,
		min-width 0.18s;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 11000;
	display: block;
	position: fixed;
	background-size: cover;
	/* box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.5), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
	box-shadow: 1px 0 0 0 #d8dde4;
	text-align: center;
	bottom: 0;
	min-height: 100vh;
	padding: 20px 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	cursor: pointer;
	/*overflow-y: scroll;*/
}

.leftNavContainerRetracted {
	width: 60px;
	min-width: 60px;
	background-color: #ffffff;
	transition:
		width 0.18s,
		min-width 0.18s;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 9000;
	display: block;
	position: fixed;
	background-size: cover;
	/* box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.5), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
	box-shadow: 1px 0 0 0 #d8dde4;
	text-align: center;
	bottom: 0;
	min-height: 100vh;
	padding: 20px 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	cursor: pointer;
}

.logoMin {
	width: 30px;
	height: 27px;
	margin-bottom: 35px;
	margin-left: 8px;
}

.logoMax {
	width: 82px;
	height: 40px;
	margin-bottom: 22px;
	margin-left: 8px;
}

.menuItemCollection {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	/* padding-bottom: 10px; */
	width: 100%;
}

.menuItemCollectionExpanded {
	/* border-bottom-color: rgb(216, 221, 228);
	border-bottom-width: 1px;
	border-bottom-style: solid; */
	/* margin-bottom: 9px; */
}

.menuItemCollectionHeader {
	align-self: flex-start;
	height: 12px;
	font-size: 12px;
	color: rgb(78, 83, 94);
	margin: 24px 0px 8px;
	font-family: 'RionaSans-Bold';
	transition:
		right 0.1s,
		opacity 0.1s;
}

.menuItemsContainer {
	display: flex;
	flex-direction: column;
}

.menuItem {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	border-radius: 5px;
	padding: 2px 8px;
}

.menuItem.menuItemTextActive {
	background-color: #e2d3f2;
}

.menuItem:not(.menuItemTextActive):hover {
	background-color: rgb(239, 239, 239);
}

.menuItemIconContainer {
	margin-right: 8px;
	position: relative;
}
.menuItemIconContainer .badge {
	position: absolute;
	top: 0;
	padding: 4px;
	border-radius: 50%;
	background-color: red;
	left: 18px;
}

.menuItemIcon {
	width: 28px;
	height: 28px;
	align-self: center;
}

.menuItemIconActive > div > svg > g > path,
.menuItemIconActive > div > svg > path,
.menuItemIconActive > div > svg > g > g > path {
	/* filter: invert(90%) sepia(50%) saturate(10000%) hue-rotate(250deg) brightness(99%) contrast(120%); */
	fill: #8200d0;
}

.menuItemIcon.menuItemIconActive > div > svg > g > path,
.menuItemIcon.menuItemIconActive > div > svg > path,
.menuItemIcon.menuItemIconActive > div > svg > g > g > path {
	fill: #491678 !important;
}

.expandedText {
	right: 0px;
}

.menuItemText {
	align-self: center;
	position: relative;
	transition:
		right 0.1s,
		opacity 0.1s;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	text-align: left;
	color: #525968;
}

.menuItem.menuItemTextActive > .menuItemText {
	font-family: RionaSans-Medium;
	color: #491678;
}

/* .sideHeight {
	display: flex;
	flex-direction: column;
	align-items: center;
} */

.navigation_titles {
	color: white;
	padding: 1vw;
	font-size: 0.8vw;
	height: 5vw;
}

i.material-icons.navigation_icons {
	color: white;
	font-size: 1.5vw;
}

a.MuiButtonBase-root-45.MuiIconButton-root-39.navigation_links.title:focus {
	outline: 0px !important;
}

a.MuiButtonBase-root-45.MuiIconButton-root-39.navigation_links.title:hover {
	color: white;
}

.navigation_links.title.active:focus {
	outline: 0px !important;
}

button.MuiButtonBase-root-7.MuiIconButton-root-1.menu {
	color: white;
}

.nav_container {
	display: flex;
}

.nav_container .fix-header {
	z-index: 1000 !important;
}

.patient_search_bar {
	width: 40%;
	margin-top: 1vw;
	float: right;
}

#leftNavContainer1 .gmi-version {
	visibility: hidden;
	opacity: 0;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 8px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	color: #525968;
}

#leftNavContainer1 .gmi-version.expanded {
	visibility: visible;
	opacity: 1;
	transition:
		visibility 0.1s,
		opacity 0.1s;
}

/* .fullheight
	{
		height: calc(100vh);
	} */

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.sideheight {
		padding-top: 8.5vw;
		/* float: left; */
	}
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.sideheight {
		padding-top: 8.5vw;
		/* float: left; */
	}
}
