.provider-link-dialog {
	width: 368px !important;
	position: fixed !important;
	max-height: calc(100vh - 120px);
	min-height: 150px;
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}
.provider-link-dialog .provider-wrapper .provider-loading {
	width: 100% !important;
}
.provider-link-dialog .provider-wrapper .provider-loading > div {
	height: 99%;
}
.provider-link-dialog .el-dialog__header {
	height: 56px;
	border-bottom: 1px solid #edeff3;
	padding: 0;
}
.provider-link-dialog .el-dialog__header .close-btn-icon {
	width: 56px;
	height: 56px;
	border-top-right-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.provider-link-dialog .el-dialog__header .close-btn-wrapper.isReadOnly {
	cursor: not-allowed;
}
.provider-link-dialog
	.el-dialog__header
	.close-btn-wrapper.isReadOnly
	span.close-btn-icon {
	pointer-events: none;
}
.provider-link-dialog .el-dialog__header .close-btn-icon:hover {
	background-color: #edeff3;
}
.provider-link-dialog .el-dialog__header .close-btn-icon:active {
	background-color: #d8dde4;
}
.provider-link-dialog .el-dialog__title {
	display: flex;
	flex-direction: row;
}
.provider-link-dialog .el-dialog__title .header {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.provider-link-dialog .el-dialog__title .dialog-title {
	font: bold 16px/1.38 RionaSans-Bold;
	text-align: left;
	color: #262837;
	margin: 9px 0px 8px 16px;
}
.provider-link-dialog .el-dialog__title .dialog-title.no-breadcrumbs {
	align-items: center;
	display: flex;
}
.provider-link-dialog .el-dialog__title .breadcrumbs {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b8291;
	display: flex;
	justify-content: center;
	align-items: center;
}
.provider-link-dialog .el-dialog__title .breadcrumbs .active {
	color: #262837;
}
.provider-link-dialog .el-dialog__title .breadcrumbs .right-arrow-thin {
	margin-left: 4px;
	margin-right: 4px;
}
.provider-link-dialog .el-dialog__title .breadcrumbs .right-arrow-thin > div,
.provider-link-dialog
	.el-dialog__title
	.breadcrumbs
	.right-arrow-thin
	> div
	> svg {
	width: 16px;
	height: 16px;
}

.provider-link-dialog .el-dialog__title .close-icon > div,
.provider-link-dialog .el-dialog__title .close-icon > div > svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
}
.provider-link-dialog .el-dialog__title .close-icon svg .GMICloseIcon {
	fill: #262837;
}
.provider-link-dialog .el-dialog__body {
	padding: 0;
}
.provider-link-dialog .el-dialog__footer {
	height: 60px;
	padding: 12px 16px;
	border-top: 1px solid #edeff3;
}
.provider-link-dialog .el-dialog__footer .cancel-button {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
}
.provider-link-dialog .el-dialog__footer .cancel-button:hover,
.provider-link-dialog .el-dialog__footer .cancel-button:focus {
	background-color: rgba(123, 38, 201, 0.15);
}
.provider-link-dialog .el-dialog__footer .cancel-button:active {
	background-color: rgba(123, 38, 201, 0.3);
}
.provider-link-dialog .el-dialog__footer .cancel-button > span {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
}
.provider-link-dialog .el-dialog__footer .confirm-button:not(.is-disabled) {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
	background-color: #7b26c9;
}
.provider-link-dialog
	.el-dialog__footer
	.confirm-button:not(.is-disabled):hover,
.provider-link-dialog
	.el-dialog__footer
	.confirm-button:not(.is-disabled):focus {
	background-color: #9c5dd7;
}
.provider-link-dialog
	.el-dialog__footer
	.confirm-button:not(.is-disabled):active {
	background-color: #7b26c9;
}
.provider-link-dialog .el-dialog__footer .confirm-button > span {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #ffffff;
}

.provider-link-dialog .el-dialog__footer .confirm-button.is-disabled {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	opacity: 0.35 !important;
	background-color: #7b26c9 !important;
	cursor: no-drop;
}
