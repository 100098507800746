.payment-info-tab {
	background: #edeff3;
	width: 100%;
	float: left;
	padding: 16px 28px 37px;
}

.payment-info-tab > div:not(.payment-leftblock) > div.el-dialog__wrapper {
	z-index: 10000 !important;
}

.payment-info-tab > div:not(.payment-leftblock) > div.v-modal {
	z-index: 9999 !important;
}

.payment-leftblock {
	width: 70%;
	float: left;
	padding-right: 20px;
}

.payment-rightblock {
	width: 30%;
	float: left;
}

.fullscreenSpinner {
	z-index: 100000;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0px;
}

.payment-info-tab .insurance-info {
	width: 100%;
	padding: 28px 24px;
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: #ffffff;
	float: left;
	display: flex;
	flex-direction: column;
}

.payment-info-tab .insurance-header {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.divBorderBottom {
	border-top: 1px solid #d8dde4;
	margin: 24px 0 0;
    display: inline-block;
	width: 100%;
}

.divBorderBottomb0 {
	border-top: 1px solid #d8dde4;
	margin: 50px 0px 0px 0px;
}

.divBorderBottomInfo {
	border-top: 1px solid #d8dde4;
	margin: 24px 0;
}

.threeDotMenu {
	height: 32px;
	width: 32px;
	border-radius: 4px;
	position: relative;
	bottom: 3px;
	left: 2px;
}

.threeDotMenuOpen {
	background-color: rgba(216, 216, 216, 0.4);
}

.threeDotMenu > div > svg {
	position: relative;
	left: 4px;
	top: 3px;
}

.threeDotMenu > div > svg > g > g {
	fill: #7b8291 !important;
}

.threeDotMenu:hover {
	background-color: rgba(216, 216, 216, 0.4);
}

.threeDotMenu:active {
	background-color: rgb(210, 210, 210);
}

.insuranceCTAContainer {
	position: relative;
}

.insuranceCTAMenuContainer {
	padding: 4px 0px;
	width: 186px;
	border-radius: 4px;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
	position: absolute;
	top: 30px;
	right: 0px;
	z-index: 1;
	margin-top: 4px;
	background-color: white;
}

.insuranceMenuOption {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #262837;
	font-size: 13px;
	font-family: 'RionaSans';
	padding: 0px 8px;
	height: 30px;
	width: 100%;
	background-color: #ffffff;
	cursor: pointer;
}

.insuranceMenuOptionDisabled {
	opacity: 0.35;
	cursor: no-drop;
}

.insuranceMenuOption:hover {
	background-color: #edeff3 !important;
}

.insuranceMenuOptionIcon {
	position: relative;
	top: 2px;
	margin-right: 4px;
	width: 28px;
}

.insuranceMenuOptionText {
	position: relative;
	top: 1px;
}

.payment-info-tab .insurance-header-separator {
	min-height: 32px;
}

.payment-info-tab .insurance-label {
	min-height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.payment-info-tab .no-insurance-label {
	min-height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7b8291;
}

.payment-info-tab .insurance-text {
	min-height: 20px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.payment-info-tab .insurance-text-separator {
	min-height: 16px;
}

.clearfix {
	overflow: auto;
}

.payment-info-tab .col-12 {
	width: 100%;
	margin-bottom: 16px;
}

.payment-info-tab .col-4 {
	float: left;
	width: 33.33%;
}

.payment-info-tab .col-6 {
	float: left;
	width: 50%;
}

.payment-info-tab .col-8 {
	float: left;
	width: 66.66%;
}

.payment-info-tab .pad-right-20px {
	padding-right: 20px;
}

.payment-info-tab .hr {
	padding-top: 32px;
	padding-bottom: 32px;
}

.payment-info-tab .front {
	min-height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.payment-info-tab .back {
	height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.payment-info-tab .stripe-info {
	width: 100%;
	min-height: 565px;
	float: left;
}

.payment-info-tab .stripe-info-container {
	min-height: 565px;
	border-radius: 4px;
	border: solid 1px #d7dde5;
	background-color: #ffffff;
}

.payment-info-tab .stripe-payment-bg-content-inner {
	padding-top: 24px;
	padding-left: 20px;
	padding-right: 20px;
}

.payment-info-tab .stripe-payment-bg-content {
	height: 84px;
	width: 100%;
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	background-clip: content-box;
}

.payment-info-tab .stripe-payment-text1 {
	height: 22px;
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	padding-top: 3px;
	float: left;
}

.payment-info-tab .stripe-payment-text2 {
	height: 36px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
}

.payment-info-tab .open-stripe-button {
	margin-top: 28px;
	width: 100%;
	text-align: center;
	height: 36px;
	background-color: #7b26c9;
	border-color: #9c5dd7;
}

.payment-info-tab .open-stripe-button:hover {
	background-color: #9c5dd7;
	border-color: #9c5dd7;
}

.payment-info-tab .open-stripe-button span {
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.payment-info-tab .info-badge {
	padding-left: 8px;
	padding-top: 5px;
	float: left;
	width: 25px;
}

.payment-info-tab .stripe-payment-content {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 16px;
}

.patientbox-shadow {
	overflow: auto;
	-webkit-box-shadow: 11px -8px 31px -12px rgba(0, 0, 0, 0.75);
	box-shadow: 6px -8px 31px -12px rgba(0, 0, 0, 0.75);
}

.patient-info-tab .ucase {
	text-transform: uppercase;
}

@media only screen and (max-width: 767px) and (min-width: 480px) {
	.patient_payment_list {
		height: auto !important;
	}
}

@media only screen and (max-width: 990px) {
	.patient_payment_list {
		height: auto !important;
	}
}

.patient_payment_list {
	overflow: hidden;
}

.paymentlist {
	overflow: inherit;
}

.EncounterList {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.MuiTabs-fixed-117 {
	overflow-x: inherit !important;
}

@media only screen and (max-width: 1100px) {
	.payment-leftblock {
		width: 60%;
		float: left;
	}
	.payment-rightblock {
		width: 35%;
		float: left;
	}
}

@media only screen and (max-width: 990px) {
	.payment-leftblock {
		width: 100%;
		float: left;
		padding-right: 0px;
	}
	.payment-rightblock {
		width: 100%;
		float: left;
		margin-top: 10px;
	}
}

.payment-info-tab .insurance-add-button {
	width: 191px;
	height: 36px;
	background-color: #40b9ec;
	margin-top: 24px;
	border-radius: 6px;
	cursor: pointer;
}

.payment-info-tab .insurance-add-button-image {
	padding-top: 4px;
	padding-left: 8px;
	padding-bottom: 4px;
}

.payment-info-tab .insurance-add-button-text {
	width: 115px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	margin-left: 8px;
}

.payment-info-tab .insurance-edit-button {
	margin-left: 8px;
	cursor: pointer;
	padding-top: 6px;
}

.payment-info-tab .loading {
	opacity: 0;
}

.payment-info-tab .wrap {
	white-space: pre-wrap;
	/* CSS3 */
	white-space: -moz-pre-wrap;
	/* Firefox */
	white-space: -pre-wrap;
	/* Opera <7 */
	white-space: -o-pre-wrap;
	/* Opera 7 */
	word-wrap: break-word;
	/* IE */
}

.payment-info-tab .insurance-info .insurance_address_text {
	clear: both;
}

.payment-info-tab .payment-separator {
	height: 12px;
	clear: both;
}

.payment-info-tab .auth-info {
	padding: 24px;
	min-width: 227px;
	border-radius: 4px;
	border: solid 1px #d7dde5;
	background-color: #ffffff;
}

.payment-info-tab .auth-content {
	margin-top: 24px;
	padding-bottom: 18.5px;
	border-bottom: solid 1px #d8dde4;
}

.payment-info-tab .auth-border {
	border-radius: 4px;
	border: solid 1px #d7dde5;
	background-color: #ffffff;
}

.payment-info-tab .auth-header {
	font-family: RionaSans-Bold;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
	white-space: pre-wrap; /* CSS3 */
	white-space: -moz-pre-wrap; /* Firefox */
	white-space: -pre-wrap; /* Opera <7 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* IE */
}

.auth-info-list {
	padding: 0;
	margin: 0;
}

.auth-info-list li {
	list-style-type: none;
}

.auth-info-list .auth-content:last-child {
	border-bottom: none;
}

.auth-info-container .auth-content .pkg-id {
	font-family: RionaSans-Medium;
	font-size: 12px;
	color: #262838;
	display: flex;
	align-items: center;
}

.auth-info-container .auth-content .pkg-id img {
	margin-left: 12px;
	margin-top: -2px;
}

.auth-info-container .auth-content .insurance-auth {
	font-family: RionaSans;
	font-size: 11px;
	color: #525968;
}

.auth-info-container .auth-content .insurance-date {
	font-family: RionaSans;
	font-size: 11px;
	color: #525968;
}

.payment-info-tab .auth-button {
	height: 36px;
	background-color: #40b9ec;
	border-radius: 4px;
	cursor: pointer;
}

.payment-info-tab .auth-button-text {
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	padding: 9px;
}

.payment-info-tab .edit-auth-link {
	cursor: pointer;
}
