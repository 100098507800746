@use '../../../Common/V3/system';
@use '../ScheduleAppointment/core';

$purple: #7b26c9;
$light-purple: #9c5dd7;

.#{core.$scopeClass} {
	.reschedule-reason-dialog {
		.el-radio__inner::after {
			background-color: $purple;
		}
		.el-dialog__footer .el-button--primary {
			background-color: $purple;
			&.is-disabled,
			&.is-disabled:hover {
				// Have to use !important due to coupon-code.css defines
				// unscoped global override for this property
				background-color: $light-purple !important;
			}
		}
	}
}
