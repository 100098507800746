.extrnl-dashbrd-wrapper {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	margin: 68px 0 0 76px;
	overflow: auto;
}

.extrnl-dashbrd-left-panel {
	width: 66%;
	min-width: 748px;
}

.extrnl-dashbrd-right-panel {
	min-width: 456px;
	width: 33%;
}

.extrnl-dashbrd-graphs-wrapper {
	border: solid 1px #edeff3;
	background-color: white;
	height: fit-content;
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	min-width: 100%;
	min-height: calc(100vh - 84px);
}
.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper {
	height: auto;
	border-right: solid 1px #edeff3;
	width: 26%;
	min-width: 235px;
	margin-left: 16px;
	padding-right: 16px;
}

.extrnl-dashbrd-graphs-wrapper .encounter-charts-wrapper {
	width: 74%;
	display: flex;
	flex-direction: column;
	margin-left: 16px;
	margin-right: 16px;
}
