.document-action-row {
	margin-top: 16px;
	margin-bottom: 28px;
	height: 36px;
	padding-left: 24px;
	display: flex;
}

.document-action-row > .document-action-buttons-container > button {
	margin-right: 12px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.document-action-row > .document-action-buttons-container > button:last-child {
	margin-right: 0px;
}

button.createdocbtn {
	width: 144px;
	background-color: #7b26c9;
	height: 36px;
	cursor: pointer;
}

button.createdocbtn:hover {
	background-color: #9c5dd7;
}

.document-action-row > .document-action-buttons-container > button.uploaddocbtn {
	width: 146px;
	background-color: #7b26c9;
	height: 36px;
	cursor: pointer;
}

.document-action-row
	> .document-action-buttons-container
	> button.uploaddocbtn:hover {
	background-color: #9c5dd7;
}

button.createdocbtn:active,
.document-action-row
	> .document-action-buttons-container
	> button.uploaddocbtn:active {
	background-color: #621ea0;
}

.document-action-buttons-container .tooltip-max-document-combine .el-tooltip__popper.is-dark {
	width: 185px;
}

.document-action-buttons-container button.combine-doc-button {
	height: 36px;
	cursor: pointer;
	border: 1px solid #a89cdd;
	border-radius: 6px;
	color: #5b43bd;
	background-color: #ffffff !important;
	display: flex;
	align-items: center;
}

.document-action-buttons-container button.combine-doc-button:hover {
	background-color: #f5f3fb !important;
	color: #5b43bd;
}

.document-action-buttons-container button.combine-doc-button:active {
	background-color: #e4e2ee !important;
	color: #5b43bd;
}

.document-action-buttons-container .combine-doc-button.is-disabled:hover {
	background-color: #ffffff !important;
    color: #5b43bd;
    border-color: #a89cdd;
}

.document-action-buttons-container > button.combine-doc-button.is-disabled:active {
	background-color: #f5f3fb;
}

.combine-doc-button-section .combine-docs-max-number-file-error {
	font-family: 'RionaSans-Bold';
	color: #e74c3c;
	font-size: 12px;
}

.document-tab-section > .switch-to-new-version {
	margin: 32px 20px 0 24px;
	background-color: #faedac;
	border-radius: 4px;
	padding: 8px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	color: #4b3700;
}

.document-tab-section > .switch-to-new-version > span {
	cursor: pointer;
}

.document-tab-section > .switch-to-new-version > span:hover {
	text-decoration: underline;
}

.document-action-row .text_space {
	margin-left: 8px;
}

.document-list-section {
	margin-top: 47px;
	padding: 0px 8px;
}

.document-list-header-row {
	display: flex;
	position: relative;
}

.document-list-body-row {
	border-top: solid 2px #d8dde4;
	margin-top: 8px;
}

.document-list-header-row > .columnheader {
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	padding-left: 20px;
}

.document-list-body-row .columnheader {
	font-family: RionaSans;
	font-size: 14px;
	font-style: normal;
	font-weight: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	padding-left: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.document-list-body-row .columnheader:last-child {
	padding-left: 0px;
	justify-content: flex-end;
	flex: 1;
	overflow: inherit;
}

.documentDescriptionSaveButton {
	width: 53px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: white;
	text-align: center;
	padding-top: 9px;
	user-select: none;
	cursor: pointer;
}

.documentDescriptionSaveButton:hover {
	background-color: #9c5dd7;
}

.documentDescriptionSaveButton:active {
	background-color: #621ea0;
}

.document-list-data-row {
	border-bottom: solid 1px #d8dde4;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}

.document-list-data-row-expanded {
	border: solid 1px #e4e7ec;
	/* background: #d9f1fb; */
	border-top: none;
}

.document-list-data-row-expanded:active,
.document-list-data-row-expanded:focus {
	background: #d9f1fb;
}

.document-list-data-row-expanded:hover {
	background: #ecf8fd;
}

.document-list-data-row:hover {
	background: #ecf8fd;
}

.document-list-data-row:active {
	background: #d9f1fb;
}

.document-list-section .col-date-added {
	min-width: 105px;
	width: 10%;
}

.document-list-section .col-name {
	min-width: 160px;
	width: 25%;
}

.document-list-section .col-name span {
	float: left;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 90%;
	white-space: normal;
}

.document-list-section .col-name span.new-pill {
	float: unset;
	width: 34px;
	min-height: 20px;
	border-radius: 4px;
	border: solid 1px #40b9ec;
	background-color: #9fdcf5;
	margin-left: 5px;
	text-transform: uppercase;
	position: absolute;
	bottom: 8px;
}
.document-list-section .col-name span.new-pill span {
	float: unset;
	width: 26px;
	min-height: 15px;
	font-family: RionaSans-Medium;
	font-size: 11px;
	line-height: 1.36;
	text-align: left;
	color: #00394b;
	padding: 3px;
}

.document-list-section .col-type {
	min-width: 100px;
	width: 17%;
	padding-left: 2px;
	overflow: visible;
	padding-bottom: 7px;
}

.columnheader.col-type
	> span.el-cascader
	> span
	> div.el-input
	> i.el-input__icon {
	font-size: 12px;
	line-height: 16px;
	top: 20%;
	cursor: pointer;
	padding-bottom: 4px;
}

.columnheader.col-type
	> span.el-cascader
	> span
	> div.el-input
	> i.el-input__icon.el-icon-caret-bottom {
	/* transform: translateY(20%); */
}

.columnheader.col-type
	> span.el-cascader
	> span
	> div.el-input
	> i.el-input__icon.el-icon-caret-bottom.is-reverse {
	transform: rotateZ(180deg) translateY(15%);
}

.columnheader.col-type > span.el-cascader > span > div.el-input {
	padding: 4px 4px 0px 0px;
}

.columnheader.col-type > span.el-cascader > span > span.el-cascader__label {
	padding-left: 6px;
}

.columnheader.col-type
	> span.el-cascader
	> span
	> span.el-cascader__label
	> label {
	font-weight: 400;
	font-family: 'RionaSans';
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #262837;
	cursor: pointer;
}

.columnheader.col-type
	> span.el-cascader.is-disabled
	> span
	> span.el-cascader__label
	> label {
	cursor: no-drop !important;
}

.columnheader.col-type
	> span.el-cascader.is-opened.select-docs
	> div.el-cascader-menus {
	left: -40px !important;
}

.columnheader.col-type
	> span.el-cascader.is-opened.select-docs
	> div.el-cascader-menus
	> ul.el-cascader-menu {
	max-height: 186px;
	min-width: 146px;
}

.columnheader.col-type
	> span.el-cascader.is-opened.select-docs
	> div.el-cascader-menus
	> ul.el-cascader-menu
	> li.el-cascader-menu__item {
	height: 29px;
	padding: 6px 10px;
	font-family: 'RionaSans';
	font-size: 13px;
	color: #262837;
}

.columnheader.col-type
	> span.el-cascader.is-opened.select-docs
	> div.el-cascader-menus
	> ul.el-cascader-menu
	> li.el-cascader-menu__item.is-active {
	background-color: #20a0ff !important;
	color: white !important;
}

.document-list-section .head-col-type {
	padding-left: 8px;
}

.document-list-section .col-last-modified {
	min-width: 118px;
	width: 12%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.document-list-section .col-kind {
	min-width: 52px;
	width: 8%;
}

.col-uppercase {
	text-transform: uppercase;
}

.document-list-section .col-added-by {
	min-width: 130px;
	width: 18%;
}

.document-list-section .col-action {
	display: flex;
	min-width: 40px;
	padding-left: 0;
	padding-right: 8px;
	padding-top: 2px;
}

.col-name img {
	margin-left: 4px;
	cursor: pointer;
}

.document-expand-view {
	display: flex;
}

.document-list-row-line {
	display: flex;
	align-items: center;
	min-height: 48px;
	position: relative;
	padding: 8px 0px 8px 0px;
}

.document-list-row-line .el-select.select-docs {
	position: static !important;
}

.document-list-section .select-docs .el-input {
	width: 108px;
}

/* .document-list-section .select-docs .el-input:hover {
    border: 1px solid #70cbf1 !important;
} */

.document-list-section .select-docs .el-input__inner {
	height: 26px;
	border-radius: 4px;
	padding-left: 8px;
	padding-right: 23px;
	border: transparent;
	/* background-color: #edeff3; */
	color: #262837 !important;
	text-overflow: ellipsis;
}

.document-list-data-row-expanded:active .el-input__inner,
.document-list-data-row:active .el-input__inner {
	background-color: #edeff3 !important;
	border-radius: 4px !important;
}

.document-list-data-row-expanded:hover .el-input__inner,
.document-list-data-row:hover .el-input__inner {
	background-color: #edeff3 !important;
	border-radius: 4px !important;
}

.document-list-data-row-expanded:hover .select-docs .el-input__inner,
.document-list-data-row:hover .select-docs .el-input__inner {
	background-color: transparent;
}

.document-list-section .select-docs .el-select-dropdown__list {
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.document-list-section .select-docs .el-select-dropdown__item {
	width: 146px;
	height: 29px;
	padding: 6px 10px;
	font-family: RionaSans !important;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.document-list-section .select-docs .el-select-dropdown__item.selected {
	color: #ffffff;
}

.document-list-section .select-docs .el-select-dropdown__wrap {
	max-height: 186px !important;
}

.documentListArchivedRow .el-select.select-docs .el-input__icon {
	cursor: no-drop !important;
}

.columnheader.col-doc-description {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7a8193;
	margin-top: 4px;
	width: calc(100% - 33%);
	min-width: 700px;
}

.document-view-dropdown {
	margin-top: 8px;
	margin-bottom: 12px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #40b9ec;
	cursor: pointer;
	width: max-content;
}

.document-view-dropdown img {
	width: 10px;
	height: 10px;
	transform: rotate(180deg);
	margin-right: 5px;
}

.document-list-section .ellipsed {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.document-list-section .col-last-modified .date-part {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

.document-list-section .col-last-modified .time-part {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgba(71, 78, 83, 0.82);
	display: block;
}

@media only screen and (max-width: 1280px) {
	.document-list-section .col-action {
		padding-right: 0;
	}
	.document-list-section .col-name span {
		max-width: 80%;
	}
}

.document-list-section .sortableLabelpatientrecords:hover {
	font-family: RionaSans-Bold !important;
	color: #262837 !important;
}

.createDocumentInputContainer .error-type {
	color: #ff4949;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
}
