.update-flag-test-request-dialog {
	width: 512px !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	-webkit-font-smoothing: antialiased;
}

/*************************************************************** 
                        Header  
***************************************************************/
.update-flag-test-request-dialog .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.update-flag-test-request-dialog .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.update-flag-test-request-dialog .el-dialog__title .title-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.update-flag-test-request-dialog .el-dialog__title .close-btn-icon {
	transform: scale(1.2);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	bottom: 2px;
	cursor: pointer;
}

.update-flag-test-request-dialog .el-dialog__title .close-btn-icon:hover {
	background-color: #ececec;
}

.update-flag-test-request-dialog .el-dialog__title .disabled-close-btn-icon {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.4;
}

.update-flag-test-request-dialog .el-dialog__title .close-icon > div,
.update-flag-test-request-dialog .el-dialog__title .close-icon > div > svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.update-flag-test-request-dialog
	.el-dialog__title
	.close-icon
	svg
	.GMICloseIcon {
	fill: #262837;
}

/*************************************************************** 
                        Body  
****************************************************************/
.update-flag-test-request-dialog .el-dialog__body {
	margin: 28px 0;
	padding: 0 16px !important;
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	height: calc(100% - 180px);
	overflow-y: auto;
}

.update-flag-test-request-dialog .update-flag-patient-info-container {
	display: flex;
	border-radius: 4px;
	padding: 12px;
	background-color: #edeff3;
	margin-bottom: 20px;
}

.update-flag-test-request-dialog .contacts-icon {
	margin-right: 13px;
}

.update-flag-test-request-dialog .patient-info-wrapper {
	display: flex;
	flex-direction: column;
	font: normal 12px/1.42 RionaSans;
}

.update-flag-test-request-dialog .update-flag-patient-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
	margin-bottom: 4px;
}

.update-flag-test-request-dialog .patient-gender {
	text-transform: capitalize;
}

.update-flag-test-request-dialog .refral-prgrm-val {
	font: normal bold 12px/1.42 RionaSans-Bold;
	margin-left: 8px;
}

.update-flag-test-request-dialog .update-flag-test-info-container {
	border-radius: 8px;
	border: 1px solid #d8dde4;
	margin-bottom: 20px;
}

.update-flag-test-request-dialog .update-flag-test-info-head {
	border-radius: 8px 8px 0 0;
	background-color: #edeff3;
	width: 100%;
	display: flex;
	padding: 10px 16px 10px;
}

.update-flag-test-info-head .update-flag-red-flag-icon {
	margin-right: 8px;
	margin-top: 3px;
}

.update-flag-test-info-head .update-flag-red-flag-icon svg {
	height: 18px;
	width: 18px;
}

.update-flag-test-info-head .update-flag-test-info-wrapper {
	display: flex;
	flex-direction: column;
}

.update-flag-test-info-head .test-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.update-flag-test-info-head .lab-name,
.update-flag-test-info-head .red-flag-created-date-timestamp {
	font: normal 12px/1.42 RionaSans;
	color: #525968;
}

.update-flag-new-flag-status-container {
	display: flex;
}

.update-flag-new-flag-status-container .label-new-flag-status {
	font: normal bold 13px/1.43 RionaSans-Bold;
}

.update-flag-new-flag-status-container .update-flag-green-flag-icon {
	margin-left: 8px;
}

.update-flag-new-flag-status-container .update-flag-green-flag-icon svg {
	height: 18px;
	width: 18px;
}

.update-flag-test-request-dialog .lbl-flag-change-reason {
	font: normal 12px/1.42 RionaSans;
	margin-bottom: 6px;
}

.update-flag-test-request-dialog .lbl-flag-change-reason::before {
	color: #e74c3c;
	content: '*';
	font: normal 700 12px/1.42 RionaSans;
}

.flag-reason-list-body {
	padding: 16px 50px 24px;
}

.flag-reason-list-body ul {
	margin: 0;
	padding: 0;
}

.update-flag-test-request-dialog .el-dialog__body .required::before {
	color: #e74c3c;
	content: '*';
	margin-right: 2px;
}

/*************************************************************** 
                            Footer  
****************************************************************/
.update-flag-test-request-dialog .el-dialog__footer {
	padding: 12px 16px;
	border-top: 1px solid #edeffe;
	display: flex;
	justify-content: space-between;
}

.update-flag-test-request-dialog .el-dialog__footer button {
	width: 160px;
	border-radius: 6px;
	padding: 8px 12px !important;
	font: normal 500 13px/1.38 RionaSans-Medium;
}

.update-flag-test-request-dialog .el-dialog__footer .btn-cancel {
	margin-right: 16px;
	color: #7b26c9;
	border: 1px solid #7b26c9;
	background-color: #ffffff !important;
}

.update-flag-test-request-dialog .el-dialog__footer .btn-cancel:hover {
	background-color: rgba(123, 38, 201, 0.15) !important;
}

.update-flag-test-request-dialog .el-dialog__footer .btn-cancel:active {
	background-color: rgba(123, 38, 201, 0.3) !important;
}

.update-flag-test-request-dialog .el-dialog__footer .btn-cancel:focus {
	background-color: rgba(123, 38, 201, 0.15) !important;
}

.update-flag-test-request-dialog .el-dialog__footer .btn-update-flag {
	border: 1px solid #7b26c9;
	background-color: #7b26c9 !important;
	color: #ffffff;
	margin-left: 0 !important;
}

.update-flag-test-request-dialog
	.el-dialog__footer
	.btn-update-flag:not(:disabled):hover {
	border: 1px solid #9c5dd7;
	background-color: #9c5dd7 !important;
}

.update-flag-test-request-dialog
	.el-dialog__footer
	.el-button.is-disabled:hover {
	border: 1px solid #7b26c9;
	background-color: #7b26c9 !important;
	color: #ffffff;
}

.update-flag-test-request-dialog .el-dialog__footer .btn-update-flag:active {
	border: 1px solid #621ea0;
	background-color: #621ea0;
}

.update-flag-test-request-dialog .el-dialog__footer .btn-update-flag:focus {
	border: 2px solid rgba(189, 146, 228, 0.5);
	background-color: #7b26c9 !important;
}
