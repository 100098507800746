/* stylelint-disable selector-class-pattern */
.circle-badge-wrapper {
	width: 32px;
	height: 32px;
	float: right;
}

.circle-badge {
	width: 32px;
	height: 32px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 8px 7px 9px;
	background-color: #edeff3;
}

.circle-badge.active {
	background-color: #e2d3f2;
}

.circle-badge,
.circle-badge-tooltip .el-tooltip__rel {
	width: 32px;
	height: 32px;
}

.circle-badge-tooltip .el-tooltip__popper {
	width: 122px;
}

.circle-badge .circle-badge-text {
	font: bold 12px/1.67 RionaSans-Bold, sans-serif;
	color: #262837;
}

.circle-badge.active .circle-badge-text {
	font: bold 12px/1.67 RionaSans-Bold, sans-serif;
	color: #491678;
}

/* For cards using GCA badges */
.circle-badge-wrapper.reverse {
	width: 32px;
	height: 32px;
	float: none;
}

.circle-badge-wrapper.reverse .circle-badge {
	padding: 0;
}

.circle-badge-wrapper .GMITooltip.el-tooltip .el-tooltip__popper {
	width: fit-content;
	padding: 16px;
}
