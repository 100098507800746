.referral-programs-container {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 0 0 60px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	margin: 46px auto;
}

.referral-programs-container > div > .custom-table > table {
	margin-top: 16px;
}
.referral-programs-container .list-loading .el-loading-spinner {
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	background: transparent;
	min-width: inherit;
}

.referral-programs-container .el-pagination {
	margin-left: auto;
}

.referral-programs-container .el-pagination .el-pager li.active {
	border-color: transparent;
	background-color: transparent;
	color: #000;
	cursor: default;
	font-weight: bold;
}

.col-self-scheduling-yes {
	color: #00d656;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	display: flex;
}

.col-self-scheduling-yes .LinkPopover {
	cursor: auto !important;
}

.regUrlCopyContainer {
	color: #7b26c9;
	font: normal 500 13px/1.38 RionaSans-Medium;
	padding-left: 6px;
	cursor: pointer;
}

.regUrlCopyContainer:hover {
	text-decoration: underline;
}

/* end:  override element react style */

.loadingrow {
	border-bottom: none !important;
}
