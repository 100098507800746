.patient-card-wrapper {
	width: 285px;
	min-height: 122px;
	-webkit-font-smoothing: antialiased;
}
.patient-card-wrapper .patient-card {
	border-radius: 5px;
	border: solid 1px #d8dde4;
	padding: 12px 16px;
}

.patient-card-wrapper .patient-card.clickable {
	cursor: pointer;
}

body .patient-card-wrapper:not(:last-child) {
	margin-right: 16px;
}

.patient-card-wrapper .patient-card.clickable:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}

.patient-card-wrapper .patient-card .type {
	font: 13px/1.38 RionaSans-Medium;
	font-weight: 500;
	margin-bottom: 8px;
	color: #525968;
}

.patient-card-wrapper .patient-card .type.primary {
	color: #0f80b0;
}

.patient-card-wrapper .patient-card .link-icon {
	height: 12px;
	width: 12px;
	background-color: #7e0be8;
	-webkit-mask: url('../../assets/quick-view-link.svg') no-repeat center;
	mask: url('../../assets/quick-view-link.svg') no-repeat center;
	/* filter: grayscale(100%); */
}

.patient-card-wrapper .patient-card .name {
	font: 14px/1.43 RionaSans-Bold;
	color: #262837;
	display: flex;
	align-items: center;
}

.patient-card-wrapper .patient-card .date {
	font: 14px/1.43 RionaSans;
	color: #262837;
}
