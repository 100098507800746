@use '../core';

.#{core.$scopeClass} {
	.additional-patient-info {		
		.consent-to-treat-dropdown-wrapper {
			@include core.threeColumnLayout;
			.el-select {
				width: 100%;
			}
		}
		.patient-auth-to-recontact-wrapper {
			margin-top: 12px;
			.is-disabled {
				.el-checkbox__inner {
					border-color: #d1dbe5 !important;
				}
			}
		}
		.consent-to-text-description {
			&.consent-to-text-description-disabled {
				opacity: 0.35;
				cursor: no-drop !important;
			}
		}
	}
}
