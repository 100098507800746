.appointment_page_wrapper {
	margin-top: 46px;
	margin-left: 60px;
	width: 100%;
	background-color: #edeff3;
	min-height: 350px;
}

input,
button:focus {
	outline: none;
}

.appointment_page_wrapper .filter_containter {
	margin-top: 16px;
	width: 100%;
	float: left;
	padding: 0 28px;
}

.appointment_page_wrapper .filter_header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.filter_containter .filter_header .search-box {
	display: flex;
	position: relative;
}

.filter_containter .filter_header .search-box .search-icon {
	position: absolute;
	left: 6px;
	top: 6px;
	padding-left: 6px;
	background: #ffff;
}

.filter_containter .filter_header .search-box .search-icon img {
	width: 14px;
	height: 22px;
}

.filter_containter .filter_header .search-box .search-textbox {
	width: 140px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #d8dde4;
	background-color: #ffffff;
	font-family: RionaSans-Medium !important;
	color: rgba(82, 89, 104, 0.5);
	font-size: 13px;
	padding-left: 30px;
	color: #1f2d3d;
}

.filter_containter .filter_header .search-box .large-search-textbox {
	width: 261px;
}

.filter_containter .filter_header .search-box .search-textbox::placeholder {
	color: rgba(82, 89, 104, 0.5);
	font-size: 13px !important;
}

.filter_containter .filter_header .search-box .hide-search-textbox,
.filter_containter .filter_header .search-box .hide-search-icon {
	display: none;
}

.filter-rightcontainer {
	display: flex;
}

.btn-invite-pt-to-schedule {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	width: 203px;
	height: 36px;
	color: #7b26c9;
	border-radius: 6px;
	border: 1px solid #7b26c9;
	cursor: pointer;
	font: normal 500 13px/1.38 RionaSans-Medium;
}

.btn-schedule-appointment {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #7b26c9;
	width: 203px;
	height: 36px;
	color: #ffffff;
	border-radius: 6px;
	cursor: pointer;
	font: normal 500 13px/1.38 RionaSans-Medium;
}

.btn-schedule-appointment .appointmentAddIcon {
	height: 28px;
	width: 28px;
}

.btn-schedule-appointment .appointmentAddIcon svg path.GMICalendarUserPlus {
	fill: #ffffff;
}

.btn-schedule-appointment>span {
	padding: 16px 9px 16px 9px;
	user-select: none;
}

.btn-schedule-appointment:hover {
	background-color: #9c5dd7;
}

.btn-schedule-appointment:active {
	background-color: #621ea0;
}

.appointment-pgnation-wrapper {
	margin-top: 24px;
	height: 32px;
	width: 100%;
	display: flex;
	margin-bottom: 24px;
}

.appointment-pagination>button {
	width: 2vw !important;
	padding-top: 0vw !important;
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.appointment-pagination {
	margin-left: auto;
}

.appointment-pagination button:first-child,
.appointment-pagination button:last-child {
	color: rgba(160, 103, 214);
}

/* start: switch insurance selection on appointment list page */

.lbl-insurance-select {
	font-size: 0.97rem;
}

/* end: switch insurance selection on appointment list page */

.filter_containter .filter_header .dropdown-gap {
	margin-right: 12px;
}

.filter_containter .filter_header .book-dropdown .el-select .el-input__inner {
	width: 140px;
	/*height: 38px !important;*/
	border-radius: 6px;
	border: solid 1px #d8dde4;
	background-color: #ffffff;
	font-family: RionaSans-Medium !important;
	color: #525968;
	font-size: 13px;
	padding: 10px 20px 10px 7px;
	text-overflow: ellipsis;
}

.appointment_page_wrapper .filter-rightcontainer .el-select-dropdown__wrap {
	max-height: 251px;
}

.filter_containter .filter_header .book-dropdown .el-select .el-input__inner::placeholder {
	color: #525968 !important;
	font-family: RionaSans-Medium;
	font-size: 13px !important;
}

.book-dropdown>div.appointmentMultiSelect.el-select {
	max-height: 36px !important;
}

/* start: appintment filter dropdown */

.appointment_page_wrapper .book-dropdown>div.appointmentMultiSelect.el-select:hover .el-input__inner,
.appointment_page_wrapper .filter_header .modality-dropdown .el-select:hover .el-input__inner,
.appointment_page_wrapper .filter_header .book-dropdown .el-select:hover .el-input__inner {
	border: solid 1px #70cbf1;
}

.appointment_page_wrapper .filter-rightcontainer .el-scrollbar .el-scrollbar__bar.is-vertical {
	opacity: 1;
}

/* end: appintment filter dropdown */

.appointment_page_wrapper .filter-rightcontainer .el-select .el-tag {
	width: 112px;
	text-overflow: ellipsis;
	overflow: hidden;
	font-family: RionaSans-Medium;
	margin: 0px 0 0 8px;
}

.appointment_page_wrapper .filter-rightcontainer .el-select .el-tag.el-tag--primary {
	/* width: 72px; */
	height: 20px;
	border-radius: 4px;
	border: solid 1px #9fdcf5;
	background-color: rgba(159, 220, 245, 0.35);
	padding: 0 6px 0 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2px;
}

.appointment_page_wrapper .filter-rightcontainer .el-select .el-tag.el-tag--primary span {
	text-overflow: ellipsis;
	overflow: hidden;
	/* width: 42px; */
	line-height: 19px;
}

.appointment_page_wrapper .filter-rightcontainer .el-select .el-select__tags {
	top: 6px;
	transform: none;
}

.appointment_page_wrapper .filter-rightcontainer .el-select .el-tag .el-icon-close {
	border-radius: 50%;
	text-align: center;
	position: relative;
	cursor: pointer;
	font-size: 11px;
	-ms-transform: scale(0.75, 0.75);
	-webkit-transform: scale(0.75, 0.75);
	transform: scale(0.75, 0.75);
	vertical-align: middle;
	top: 0;
	right: 0;
	margin: 0;
}

.appointment_page_wrapper .filter-rightcontainer .el-select .el-select-dropdown .el-select-dropdown__item {
	text-overflow: normal;
	height: auto;
	font-size: 13px;
	padding-right: 27px;
}

.appointment_page_wrapper .column .el-select .el-input .el-select-dropdown__list .el-select-dropdown__item {
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.appointment_page_wrapper .column .el-select .el-input .el-select-dropdown__list {
	padding: 4px 0;
}

.filter_containter .filter_header .el-select .el-input .el-input__icon {
	color: #525968;
	right: 8px;
	width: auto;
	font-size: 10px;
}

.filter_containter .filter_header .modality-dropdown .el-select .el-input__inner {
	width: 139px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #d8dde4;
	background-color: #ffffff;
	font-family: RionaSans-Medium !important;
	color: #525968;
	font-size: 13px;
	padding: 10px 0 10px 7px;
}

.filter_containter .filter_header .modality-dropdown .el-select .el-input__inner::placeholder {
	color: #525968;
	font-family: RionaSans-Medium;
	font-size: 13px;
}

.filter_containter .filter_header .lessfilter-dropdown button {
	width: 40px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #d8dde4;
	background-color: #ffffff;
	font-family: RionaSans-Medium;
	font-size: 13px;
	color: #525968;
	text-align: left;
	padding: 0;
}

.filter_containter .filter_header .lessfilter-dropdown button img {
	margin: 0 8px;
	width: 28px;
	height: 28px;
	text-align: center;
}

.filter_containter .filter_header .list-view {
	width: 36px;
	height: 36px;
	background-color: rgba(0, 85, 112, 0.15);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
}

/*======================== List =========================*/

.appointment_page_wrapper .app_list_sec {
	padding: 0 8px;
	width: 100%;
	float: left;
}

.appointment_page_wrapper .app_list_container {
	width: 100%;
	float: left;
	margin: 16px 0 24px;
}

.appointment_page_wrapper .app_list_container .appointment_list_view_header {
	display: flex;
	margin-bottom: 8px;
}

.appointment_page_wrapper .app_list_container .appointment_list_view_header div {
	padding-left: 20px;
	font: normal 500 12px/1.42 RionaSans-Medium;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.appointment_page_wrapper .app_list_container .appointment_list_view {
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
}

.appointment_page_wrapper .app_list_container .app_list_header {
	width: 100%;
	display: flex;
	padding: 16px 20px 28px;
	align-items: center;
	justify-content: space-between;
}

.appointment_page_wrapper .app_list_container .app_list_header .appointment_btn {
	width: 216px;
	height: 36px;
	background-color: #40b9ec;
	border-radius: 6px;
	border: none;
	color: #ffffff;
	font-family: RionaSans-Medium;
	font-size: 13px;
	text-align: left;
	display: flex;
	align-items: center;
	padding: 0;
}

.appointment_page_wrapper .app_list_container .app_list_header .appointment_btn img {
	margin-right: 11px;
	margin-left: 12px;
}

.appointment_page_wrapper .appointment_list_view,
.appointment_list_table {
	width: 100%;
	float: left;
}

.appointment_page_wrapper .appointment_list_view .table-container {
	display: block;
}

.appointment_page_wrapper .appointment_list_view .table-container .flex-table {
	display: flex;
	/* flex-flow: row wrap; */
	transition: 0.5s;
	border-bottom: 1px solid #edeff3 !important;
}

.appointment_page_wrapper .appointment_list_view .table-container .flex-table.no-result {
	justify-content: center;
	align-items: center;
	height: 56px;
	font: normal normal 14px/1.43 RionaSans;
	color: #262837;
	transition: unset;
}

.appointment_page_wrapper .appointment_list_view .table-container .flex-table.no-result:hover {
	background-color: #d9f1fb;
}

.appointment_page_wrapper .appointment_list_view .table-container .flex-table:last-child {
	border-bottom: none !important;
}

.app_list_sec .appointment_list_table .flex-row {
	padding: 0 0 0 20px;
}

.appointment_page_wrapper .appointment_list_view .rowspan {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
}

.appointment_page_wrapper .appointment_list_view .column {
	display: flex;
	flex-flow: column wrap;
	width: 92%;
	padding: 0;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .column .appointmentcell_hover {
	display: flex;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .column .appointmentcell_hover:hover {
	background: #d9f1fb;
	transition: 500ms;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .column .appointmentcell_hover:focus {
	background: #d9f1fb;
	transition: 500ms;
	outline: none;
}

.appointment_list_view .table-container .appointment_list_table .column .flex-row {
	display: flex;
	padding: 0;
	border: 0;
	align-items: center;
	height: 56px;
	cursor: pointer;
}

.appointment_list_view .table-container .appointment_list_table .column .appointmentcell_loading:last-child {
	border-bottom: none;
}

.appointment_list_view .table-container .appointment_list_table .column .appointmentcell_loading {
	border-bottom: solid 1px #edeff3;
}

.appointment_page_wrapper .appointment_list_view .column .flex-row .flex-cell {
	padding: 0 0 0 20px;
	font-family: RionaSans;
	font-size: 14px;
	color: #262837;
}

.appointment_page_wrapper .appointment_list_view .app_table_title_date {
	width: 7%;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .app_table_head_date {
	width: 160px;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .app_table_head_time {
	width: 10%;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .app_table_head_fullname {
	text-transform: capitalize;
	width: 20%;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .app_table_head_provider {
	width: 20%;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .app_table_head_provider img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: solid 1px #d4dfe7;
	background-color: #d8d8d8;
	margin-right: 8px;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .app_table_head_type {
	width: 20%;
}

.appointment_list_table .app_table_head_status {
	width: 10%;
}

.appointment_page_wrapper .appointment_list_view .appointment_list_table .app_table_head_payor {
	width: 20%;
}

.appointment_page_wrapper .appointment_list_view .font-bold {
	font-family: RionaSans-Bold !important;
}

.appointment_list_table .status-yellow {
	color: #f1c40f !important;
	font-family: RionaSans-Bold !important;
}

.appointment_list_table .status-red {
	color: #e74c3c !important;
	font-family: RionaSans-Bold !important;
}

.appointment_list_table .status-green {
	color: #00d65b !important;
	font-family: RionaSans-Bold !important;
}

.appointment_list_table .status-blue {
	color: #40b9ec !important;
	font-family: RionaSans-Bold !important;
}

.appointment_list_table .status-gray {
	color: #a0abb9 !important;
	font-family: RionaSans-Bold !important;
}

.appointment_list_table .status-gray-dark {
	color: #525968 !important;
	font-family: RionaSans-Bold !important;
}

.appointment_list_table .app_table_num {
	width: 28px;
	height: 28px;
	background-color: #3abaee;
	border-radius: 50%;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	margin-right: 6px;
	font-family: RionaSans-Medium;
}

.appointment_list_table .app_table_date {
	font-family: RionaSans-Bold;
	font-size: 14px;
	color: #3abaee;
	flex-flow: inherit;
	display: flex;
	align-items: center;
	padding-top: 15.5px;
}

.appointment_page_wrapper .appointment_list_view .flexheader .flex-row {
	margin-bottom: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.appointment_list_table .app_table_num_blank {
	font-family: RionaSans-Medium;
	font-size: 18px;
	color: #262837;
	margin-right: 6px;
	width: 28px;
	text-align: center;
}

.appointment_list_table .app_table_day_blank {
	font-family: RionaSans-Medium;
	font-size: 14px;
	color: #262837;
}

.appointment_list_table .app_table_head_modality .el-input__inner {
	font-family: RionaSans !important;
	font-size: 13px;
	color: #262837;
	border: 1px solid #bac3ce;
	height: 32px;
	background-color: transparent;
}

.appointment_list_table .app_table_head_modality .el-select .el-input .el-input__icon {
	color: #bac3ce;
	font-size: 10px;
}

.app_list_sec .el-icon-close {
	width: 28px;
	font-size: 10px;
}

.appointment_list_table .app_table_head_modality .el-input__inner::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #262837;
	opacity: 1;
	/* Firefox */
}

.appointment_list_table .app_table_head_modality .el-input__inner:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #262837;
}

.appointment_list_table .app_table_head_modality .el-input__inner::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #262837;
}

/* Scheduling appointment */

.scheduleAppointmentContainer {
	margin: 60px 8px 0 68px;
	width: calc(100vw - 76px);
	min-height: calc(100vh - 140px);
	background-color: white;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	display: flex;
	flex-direction: row;
}

.schedule-aptmnt-loader,
.reschedule-aptmnt-loader {
	display: flex;
	width: 100%;
}

.schedule-aptmnt-loader-border-radius>div:not(.schedule-appointment-wrapper),
.reschedule-aptmnt-loader>div:not(.reschedule-appointment-wrapper) {
	border-radius: 5px;
}

.appointmentcell_loading>div:not(.appointmentcell_hover) {
	z-index: 2 !important;
}

.changeAppointmentStatusLoader>div:not(.changeAppointmentStatusMessage) {
	border-radius: 2px;
}

.summary-couponcode-loader>div:nth-child(1):not(.el-row) {
	z-index: 2 !important;
}

.schedule-appointment-wrapper,
.reschedule-appointment-wrapper {
	display: flex;
	width: 100%;
}

.scheduleAppointmentContainer .loading-reschedule-apt .el-loading-spinner,
.scheduleAppointmentContainer .loading-schedule-apt .el-loading-spinner {
	left: 40vw !important;
}

.scheduleAppointmentMainContainer {
	width: 75%;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.17);
	padding: 28px 0px 24px 0px;
	border-radius: 5px;
}

.scheduleAppointmentSideContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-width: 336px;
	max-width: 336px;
	width: 336px;
}

.scheduleAppointmentSideContainerSection {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 24px;
	padding-right: 10px;
}

.sideContainerBorder {
	border-top: 1px dashed rgba(0, 0, 0, 0.15);
	margin: 0px 13px;
}

.scheduleAppointmentSideContainerTitle {
	display: flex;
	font-size: 20px;
	font-family: 'RionaSans-Bold';
	color: #262838;
	padding-top: 24px;
	padding-left: 24px;
}

.scheduleAppointmentSideContainerEmpty {
	opacity: 0.5;
	font-size: 12px;
	color: #262838;
	padding: 24px;
}

.scheduleAppointmentSideContainerName {
	font-size: 14px;
	font-family: 'RionaSans-Bold';
	color: #262838;
	margin-bottom: 12px;
	white-space: nowrap;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
}

.scheduleAppointmentSideContainerPatientText {
	font-size: 12px;
	color: #262838;
	word-break: break-all;
	white-space: nowrap;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
}

.emailAlertText {
	color: #e74c3c !important;
}

.emailAlertIcon {
	position: absolute;
	left: 30px;
	bottom: -6px;
}

.emailAlertIcon>div.el-tooltip__popper {
	top: -60px !important;
}

.scheduleAppointmentSideContainerRow {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.scheduleAppointmentSideContainerHalf {
	display: flex;
	flex-direction: row;
	width: 50%;
	align-items: center;
}

.scheduleAppointmentSideContainerHalf .videoic div,
.scheduleAppointmentSideContainerHalf .videoic svg {
	width: 18px;
	height: 18px;
}

.scheduleAppointmentSideContainerHalf .videoic svg path.GMIVideoIcon {
	fill: #000000;
}

.scheduleAppointmentSideContainerHalf .videoic svg path.GMIPhoneIcon {
	fill: #7b8291;
}

.scheduleAppointmentSideContainerPayment {
	font-size: 28px;
	font-family: 'RionaSans-Bold';
	color: #262838;
	margin-left: 20px;
}


.insuranceVisitDepositSubSection {
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	color: #525968;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
	padding-bottom: 20px;
}

.scheduleAppointmentSideContainerCheckoutBtn {
	width: 100%;
	background-color: #7b26c9;
	border-radius: 6px;
	text-align: center;
	height: 36px;
	padding-top: 9px;
	color: white;
	font-size: 13px;
	cursor: pointer;
}

.summary-payment-container {
	margin-bottom: 16px;
	border: 0;
}

.scheduleAppointmentSideContainerSection .scheduleAppointmentSideContainerCheckoutBtn:focus {
	color: #fff;
}

.scheduleAppointmentSideContainerCheckoutBtn.is-disabled,
.scheduleAppointmentSideContainerCheckoutBtn.is-disabled:hover {
	color: #fff;
	background-color: #7b26c9 !important;
	opacity: 0.35 !important;
}

.el-button.scheduleAppointmentSideContainerCheckoutBtn:hover {
	background-color: #9c5dd7;
	color: #fff;
}

.scheduleAppointmentSideContainerCheckoutBtn:hover {
	background-color: #9c5dd7;
	color: white;
	border-color: unset;
}

.el-button.scheduleAppointmentSideContainerCheckoutBtn:active {
	background-color: #621ea0;
	color: #fff;
}

.scheduleAppointmentNavRow {
	display: flex;
	flex-direction: row;
	align-content: center;
	padding-bottom: 40px;
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 32px;
	height: 28px;
	border-bottom: solid 1px #edeff3;
}

.scheduleAppointmentNavArrow {
	width: 34px;
	padding-left: 3px;
	margin: 0px 3px;
}

.scheduleAppointmentNavArrow>div>svg>g>g {
	fill: #a4b0bd;
}

.scheduleAppointmentNavHeaderSelected {
	font-family: 'RionaSans-Bold';
	font-size: 20px;
	color: #262838;
}

.scheduleAppointmentNavHeader {
	font-size: 20px;
	color: #a4b0bd;
}

.scheduleAppointmentContentContainer {
	height: 100%;
	width: 100%;
	background-color: #edeff3;
	display: flex;
	max-width: calc(100vw - 427px);
}

.scheduleAppointmentSelectPatientContainer {
	height: 70px;
	width: 100%;
}

.scheduleAppointmentInputContainer {
	display: flex;
	flex-direction: column;
	position: relative;
}

.appointmentRequiredField {
	color: red;
	font-size: 15px;
	font-family: 'RionaSans-Bold';
	top: 0px;
	left: -9px;
	position: absolute;
}

.insuranceaddress {
	position: relative;
}

.insuranceaddress .appointmentRequiredField {
	color: red;
	font-size: 15px;
	font-family: 'RionaSans-Bold';
	top: 0px;
	left: -9px;
	position: absolute;
}

.scheduleAppointmentInputTitle {
	height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	color: #525968;
}

.scheduleAppointmentInputField {
	width: 99%;
	height: 32px;
	border-radius: 4px;
	font-size: 14px !important;
	border: 1px solid #bac3ce;
	padding: 0px 6px;
}

.scheduleAppointmentInputField::placeholder {
	opacity: 1;
	font-size: 13px !important;
	color: #bac3ce;
}

.scheduleAppointmentInputField:hover,
.scheduleAppointmentInputField:hover {
	border: solid 1px #70cbf1 !important;
	outline: none;
}

.scheduleAppointmentInputField:focus,
.scheduleAppointmentInputField:focus {
	border: solid 1.5px #40b9ec !important;
	outline: none;
}

.scheduleAppointmentInputSearchResults {
	border-radius: 4px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
	background-color: #ffffff;
	margin-top: 4px;
	width: 99%;
	overflow-y: auto;
	/* overflow: scroll; */
	max-height: 60vh;
	padding: 0px 3px;
	z-index: 1;
}

.scheduleAppointmentInputSearchResults::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

.scheduleAppointmentInputSearchResults::-webkit-scrollbar {
	width: 5px;
	background-color: #f5f5f5;
}

.scheduleAppointmentInputSearchResults::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scheduleAppointmentInputFieldItem {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 4px 5px;
	cursor: pointer;
}

.scheduleAppointmentInputFieldItem:hover {
	background-color: #e4e8f1;
}

.scheduleAppointmentInputFieldItemName {
	font-size: 13px;
	color: #262837;
}

.scheduleAppointmentInputFieldItemEmail {
	font-size: 13px;
	color: #7b8291;
}

.scheduleAppointmentInputFieldEmptyMessage {
	font-size: 13px;
	color: #7b8291;
}

.bolded {
	font-family: 'RionaSans-Bold';
}

/*  */

/* Scheduling appointment end */
.appointment_page_wrapper .appointment_list_view .column .appointmentquickview,
.appointment_page_wrapper .appointment_list_view .column .appointmentquickview_ptp {
	width: 320px !important;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
	border: solid 1px rgba(163, 179, 205, 0.64);
	background-color: #ffffff;
	margin-top: 0;
	padding: 0 20px 20px 20px;
}

.appointment_page_wrapper .appointment_list_view .column .appointmentquickview_ptp {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-100%, 0%);
}

.appointmentquickview .popover_quickview_appname,
.appointmentquickview_ptp .popover_quickview_appname {
	font-family: RionaSans-Bold;
	font-size: 18px;
	color: #262837;
}

.appointmentquickview .popover_quickview_partnername,
.appointmentquickview_ptp .popover_quickview_partnername {
	font-family: RionaSans;
	font-size: 12px;
	color: #262837;
}

.appointmentquickview .popover_quickview_header,
.appointmentquickview_ptp .popover_quickview_header {
	width: 100%;
	margin-bottom: 16px;
}

.appointmentquickview .popover_quickview_midcontent,
.appointmentquickview_ptp .popover_quickview_midcontent {
	width: 100%;
}

.appointmentquickview .popover_quickview_datebox,
.appointmentquickview_ptp .popover_quickview_datebox {
	width: 100%;
	margin-bottom: 1rem;
}

.appointmentquickview .popover_label {
	display: block;
}

.appointmentquickview .popover_value {
	display: block;
	font-family: RionaSans-Bold;
	margin-bottom: 1rem;
}

.appointmentquickview .popover_quickview_result,
.appointmentquickview_ptp .popover_quickview_result {
	font-family: RionaSans-Medium;
	font-size: 16px;
	color: #7b26c9;
	display: flex;
	align-items: center;
	cursor: pointer;
	text-transform: capitalize;
	width: fit-content;
}

.appointmentquickview .popover_quickview_result:hover,
.appointmentquickview_ptp .popover_quickview_result:hover {
	text-decoration: underline;
}

.appointmentquickview .popover_quickview_result .linkWrapper,
.appointmentquickview_ptp .popover_quickview_result .linkWrapper {
	display: flex;
}

.appointmentquickview .popover_quickview_result .linkWrapper .linkIcon>div,
.appointmentquickview_ptp .popover_quickview_result .linkWrapper .linkIcon>div {
	display: flex;
	justify-self: center;
	align-items: center;
}

.appointmentquickview .popover_quickview_result .linkWrapper .linkIcon svg,
.appointmentquickview_ptp .popover_quickview_result .linkWrapper .linkIcon svg {
	margin-left: 4px;
	margin-top: 4px;
	width: 15px;
	height: 15px;
}

.appointmentquickview .popover_quickview_result .linkWrapper .linkIcon svg path.GMILinkIcon,
.appointmentquickview_ptp .popover_quickview_result .linkWrapper .linkIcon svg path.GMILinkIcon {
	fill: #40b9ec;
}

.appointmentquickview .popover_quickview_phonetitle,
.appointmentquickview_ptp .popover_quickview_phonetitle {
	font-family: RionaSans;
	font-size: 12px;
	color: #7d8392;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
}

.popover_quickview_phonetitle .modalityIcon>div,
.appointmentquickview_ptp .popover_quickview_phonetitle .modalityIcon>div {
	display: flex;
}

.popover_quickview_phonetitle .modalityIcon svg,
.appointmentquickview_ptp .popover_quickview_phonetitle .modalityIcon svg {
	width: 18px;
	height: 18px;
}

.popover_quickview_phonetitle .modalityIcon svg path.GMIPhoneIcon,
.appointmentquickview_ptp .popover_quickview_phonetitle .modalityIcon svg path.GMIPhoneIcon {
	fill: #7b8291;
}

.popover_quickview_phonetitle .modalityIcon svg path.GMIVideoIcon,
.appointmentquickview_ptp .popover_quickview_phonetitle .modalityIcon svg path.GMIVideoIcon {
	fill: #000000;
}

.appointmentquickview .popover_quickview_appointmentdate,
.appointmentquickview_ptp .popover_quickview_appointmentdate {
	font-family: RionaSans;
	font-size: 12px;
	color: #262837;
}

.appointmentquickview .popover_quickview_appointmentdate,
.appointmentquickview_ptp .popover_quickview_appointmentdate {
	font-family: RionaSans;
	font-size: 12px;
	color: #262837;
}

.appointmentquickview .popover_quickview_appointmentdate span,
.appointmentquickview_ptp .popover_quickview_appointmentdate span {
	font-family: RionaSans-Medium;
}

.appointmentquickview .popover_quickview_appointmentstatus,
.appointmentquickview_ptp .popover_quickview_appointmentstatus {
	font-family: RionaSans-Bold;
	font-size: 14px;
	color: #262837;
}

.appointmentquickview .popover_quickview_providercontainer,
.appointmentquickview_ptp .popover_quickview_providercontainer {
	width: 100%;
	padding-bottom: 24px;
}

.appointmentquickview .popover_quickview_title,
.appointmentquickview_ptp .popover_quickview_title {
	display: flex;
	justify-content: flex-end;
	margin-top: 5px;
}

.appointment-billing,
.quick-apmnt-close,
.appointmentquickview_ptp .appointment-billing,
.appointmentquickview_ptp .quick-apmnt-close {
	width: 32px;
	height: 32px;
	padding: 2px;
	cursor: pointer;
}

.quick-apmnt-close,
.appointmentquickview_ptp .quick-apmnt-close {
	margin-right: -16px;
}

.appointmentquickview .el-tooltip.item>.el-tooltip__popper.is-dark,
.appointmentquickview_ptp .el-tooltip.item>.el-tooltip__popper.is-dark {
	width: auto !important;
	height: auto !important;
}

.appointmentquickview .el-tooltip.item>.el-tooltip__popper .popper__arrow,
.appointmentquickview_ptp .el-tooltip.item>.el-tooltip__popper .popper__arrow {
	display: block !important;
	border-top-color: #1f2d3d !important;
}

.appointmentquickview .popover_quickview_appointmentprovidername,
.appointmentquickview_ptp .popover_quickview_appointmentprovidername {
	font-family: RionaSans-Medium;
	font-size: 14px;
	color: #262837;
	margin-bottom: 8px;
}

.appointmentquickview .popover_quickview_appointmentprovidername img,
.appointmentquickview_ptp .popover_quickview_appointmentprovidername img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: solid 1px #d4dfe7;
	background-color: #d8d8d8;
	margin-right: 8px;
}

.appointmentquickview .popover_quickview_appointmentemail,
.appointmentquickview_ptp .popover_quickview_appointmentemail {
	font-family: RionaSans;
	font-size: 14px;
	color: #262837;
	overflow: hidden;
	min-width: 278px;
	word-break: break-word;
}

.appointmentquickview .popover_quickview_appointmentproviderno,
.appointmentquickview_ptp .popover_quickview_appointmentproviderno {
	font-family: RionaSans;
	font-size: 14px;
	color: #262837;
	margin-top: 4px;
}

.appointmentquickview .popover_quickview_footer,
.appointmentquickview_ptp .popover_quickview_footer {
	width: 100%;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
	border-top: solid 1px #edeff3;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_editbtn,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_editbtn {
	width: 161px;
	height: 36px;
	border-radius: 6px;
	background-color: #40b9ec;
	display: flex;
	align-items: center;
	color: #fff;
	justify-content: center;
	border: none;
	font-family: RionaSans-Medium;
	font-size: 13px;
	padding: 0;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_editbtn .pencilIcon svg,
.appointmentquickview .popover_quickview_footer .popover_quickview_editbtn .pencilIcon div,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_editbtn .pencilIcon svg,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_editbtn .pencilIcon div {
	width: 28px;
	height: 28px;
	margin-left: -9px;
	display: flex;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_editbtn .pencilIcon svg path.GMIPencilIcon,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_editbtn .pencilIcon svg path.GMIPencilIcon {
	fill: #ffffff;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_editbtn span,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_editbtn span {
	margin-left: 7px;
}

.appointmentquickview .popover_quickview_footer button[type='button']:disabled.popover_quickview_editbtn,
.appointmentquickview_ptp .popover_quickview_footer button[type='button']:disabled.popover_quickview_editbtn {
	opacity: 0.45;
	cursor: not-allowed;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_cancelby,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_cancelby {
	width: 52px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #b8c3d0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	padding: 0;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_cancelby .calendarCloseIcon svg,
.appointmentquickview .popover_quickview_footer .popover_quickview_cancelby .calendarCloseIcon div .appointmentquickview_ptp .popover_quickview_footer .popover_quickview_cancelby .calendarCloseIcon svg,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_cancelby .calendarCloseIcon div {
	width: 28px;
	height: 28px;
	display: flex;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_cancelby .calendarCloseIcon svg path.GMICalendarCloseIcon,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_cancelby .calendarCloseIcon svg path.GMICalendarCloseIcon {
	fill: #a0abb9;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_noshow,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_noshow {
	width: 52px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #b8c3d0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	padding: 0;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_noshow .timerHelpIcon svg,
.appointmentquickview .popover_quickview_footer .popover_quickview_noshow .timerHelpIcon div,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_noshow .timerHelpIcon svg,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_noshow .timerHelpIcon div {
	width: 28px;
	height: 28px;
	display: flex;
}

.appointmentquickview .popover_quickview_footer .popover_quickview_noshow .timerHelpIcon svg path.GMITimerHelpIcon,
.appointmentquickview_ptp .popover_quickview_footer .popover_quickview_noshow .timerHelpIcon svg path.GMITimerHelpIcon {
	fill: #a0abb9;
}

.appointmentquickview.el-popover[x-placement^='bottom'] .popper__arrow,
.appointmentquickview_ptp.el-popover[x-placement^='bottom'] .popper__arrow {
	top: unset !important;
	border-top-width: 6px !important;
	border-bottom-color: unset !important;
}

.appointment_page_wrapper .app_list_sec .el-dialog {
	padding: 8px 8px 0;
}

.appointment_page_wrapper .app_list_sec .el-dialog__header {
	padding: 12px 0 20px 12px;
	position: relative;
}

.appointment_page_wrapper .appointment-dialogpopup .el-dialog__header {
	padding: 0 20px;
}

.appointment_page_wrapper .appointment-dialogpopup .el-dialog__title .title svg {
	width: 19px;
	height: 19px;
	margin-right: 4px;
	float: left;
	margin-top: 1px;
}

.appointment_page_wrapper .appointment-dialogpopup .el-dialog__title .title {
	display: flex;
}

.appointment_page_wrapper .app_list_sec .el-dialog__header .el-dialog__title {
	font-family: RionaSans-Bold;
	font-size: 16px;
	color: #26273a;
}

.appointment_page_wrapper .appointment-dialogpopup .modality-dialog .el-dialog__body {
	padding: 20px;
}

.appointment_page_wrapper .appointment-dialogpopup .modality-dialog .el-dialog__footer {
	padding: 0px 10.9px 0 20px;
	display: flex;
	justify-content: flex-end;
}

.appointment_page_wrapper .app_list_sec .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
	color: #7b8291;
	font-size: 9.4px;
	width: 28px;
	height: 28px;
	line-height: inherit;
}

.appointment_page_wrapper .app_list_sec .el-dialog__header .el-dialog__headerbtn {
	position: absolute;
	top: 0;
	right: 0;
	width: 28px;
	height: 28px;
}

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__header {
	padding: 20px 20px 0;
}

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__body {
	padding: 20px;
}

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__body .error-text ul {
	padding-left: 20px;
	margin-bottom: 0;
}

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__footer {
	padding: 8px 20px 20px;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__body {
	font-family: RionaSans;
	font-size: 12px;
	color: #7a8292;
	padding: 0 12px 28px;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer {
	padding: 0 12px 20px;
	display: flex;
	justify-content: space-between;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .el-button--default {
	width: 94px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #a0abb9;
	color: #a0abb9;
	font-size: 13px;
	font-family: RionaSans-Medium;
	padding: 9px 16px 9px 12px;
	line-height: initial;
	border-radius: 6px;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .cancelpopup {
	width: 145px;
	height: 36px;
	background-color: #e74c3c;
	color: #fff;
	border: none;
	padding: 0;
	display: flex;
	margin: 0;
	font-size: 13px;
	font-family: RionaSans-Medium;
	line-height: initial;
	border-radius: 6px;
	justify-content: left;
	align-items: center;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .cancelpopup svg {
	width: 28px;
	height: 28px;
	margin: 0 8px;
	float: left;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .cancelpopup .cancelIcon svg path.GMICalendarCloseIcon {
	fill: #ffffff;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .cancelpopup .canceltext {
	display: inline-flex;
	padding-top: 6px;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .noshowbtn {
	width: 158px;
	height: 36px;
	background-color: #f1c40f;
	color: #262837;
	border: none;
	padding: 0;
	display: flex;
	margin: 0;
	font-size: 13px;
	font-family: RionaSans-Medium;
	border-radius: 6px;
	line-height: initial;
	justify-content: center;
	align-items: center;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .noshowbtn>span {
	display: flex;
	justify-content: center;
	align-items: center;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .noshowbtn .noShowIcon {
	margin-left: 8px;
	margin-right: 8px;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .noshowbtn .noShowIcon div {
	display: flex;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .noshowbtn .noShowIcon svg path.GMITimerHelpIcon {
	fill: #262837;
}

.appointment_page_wrapper .app_list_sec .el-dialog__wrapper .el-dialog__footer .noshowbtn .canceltext {
	display: inline-flex;
}

.appointment_list_view .modality-dropdown {
	text-transform: capitalize;
	width: 92px;
	height: 32px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	background-color: #ffffff;
	padding: 0 0 0 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.appointment_list_view .modality-dropdown[disabled] {
	color: #262837;
}

.appointment_list_view .modality-dropdown .el-icon--right {
	color: #bac3ce;
	font-size: 10px;
	margin: 0;
	position: absolute;
	right: 11px;
	top: 10px;
}

.appointment_page_wrapper .modality-dialog {
	width: 400px;
	min-height: 169px;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: #ffffff;
	padding: 20px 0;
}

.modality-dialog .error-text {
	width: 360px;
	min-height: 34px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7a8292;
	word-wrap: break-word;
}

.modality-dialog .el-dialog__title {
	width: 39px;
	min-height: 22px;
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #26273a;
}

.appointment-modalitypopup .modality-dialog .el-dialog__title {
	width: auto;
	display: flex;
	align-items: center;
}

.appointment-modalitypopup .modality-dialog .el-dialog__title .title {
	display: flex;
}

.appointment-modalitypopup .modality-dialog .el-dialog__title .error-image svg {
	margin-right: 4px;
	height: 19px;
	width: 19px;
}

.appointment-dialogpopup .modality-dialog .el-dialog__title .error-image svg path.GMIWarningCircleIcon {
	fill: #e74c3c;
}

.appointment-dialogpopup .el-dialog__header .el-dialog__title .title {
	display: flex;
	align-items: center;
}

.appointment-dialogpopup .el-dialog__header .el-dialog__title .title .error-image div,
.appointment-dialogpopup .el-dialog__header .el-dialog__title .title .error-image svg {
	width: 19px;
	height: 19px;
}

.appointment-dialogpopup .el-dialog__header .el-dialog__title .title .error-title {
	margin-top: 2px;
	margin-left: 5px;
}

.modality-dialog .el-dialog__body {
	padding: 20px;
}

.modality-dialog .el-dialog__footer {
	padding: 0px 10px;
}

.appointment_page_wrapper .appointment-dialogpopup .modality-dialog .el-button {
	width: 74px;
	height: 36px;
	background-color: #40b9ec;
	-ms-flex-pack: right;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: none;
}

.appointment_page_wrapper .appointment-dialogpopup .modality-dialog .el-button span {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	color: #ffffff;
}

.appointment-modalitypopup .modality-dialog {
	padding: 20px;
}

.appointment-modalitypopup .el-dialog__header {
	padding: 0 !important;
	display: flex;
	align-items: center;
}

.appointment-modalitypopup .el-dialog__header .el-dialog__title img {
	margin-right: 4px;
	float: left;
	height: 19px;
	width: 19px;
}

.appointment-modalitypopup .modality-dialog .el-dialog__body {
	padding: 20px 0 !important;
}

.appointment-modalitypopup .modality-dialog .el-dialog__body span {
	font-size: 12px;
	font-family: RionaSans;
	color: #7a8292;
}

.appointment-modalitypopup .modality-dialog .el-dialog__footer {
	padding: 0 !important;
	display: flex;
	justify-content: space-between;
}

.appointment-modalitypopup .modality-dialog .el-dialog__footer .el-button--default {
	width: 70px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b8291;
	font-family: RionaSans-Medium;
	font-size: 13px;
	color: #7b8291;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.appointment-modalitypopup .modality-dialog .el-dialog__footer .el-button--primary {
	width: 96px;
	height: 36px;
	background-color: #40b9ec;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	color: #ffffff;
	border: none;
	margin-left: 0;
}

.appointment-modalitypopup .modality-text {
	font-family: RionaSans-Bold;
}

.appointment_list_view .appointmentcell_hover>span:first-child {
	float: none;
	width: 87%;
}

.appointmentcell_hover .el-dropdown-menu {
	left: 0 !important;
}

.scheduleFilterDropdownContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	margin-bottom: 19px;
}

.scheduleRequired {
	font-family: 'RionaSans-Bold';
	font-size: 15px;
	color: red;
	position: absolute;
	left: -8px;
	top: 0px;
}

.scheduleFilterDropdownTitle {
	font-size: 12px;
	color: #525968;
}

.tooltipcontent {
	display: flex;
	margin-bottom: 2px;
}

.scheduleFilterDropdownTitle .scheduleFilterTooltip {
	position: relative;
	top: 2px;
	left: 4px;
}

.scheduleFilterTooltip .el-tooltip__popper {
	width: 150px !important;
}

.scheduleFilterDropdownEl>div.el-input>input.el-input__inner {
	/* height: 32px !important; */
	transition: border-color 0.01s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	font-size: 13px !important;
}

.scheduleFilterDropdownEl>div.el-input>input.el-input__inner:hover {
	border: solid 1px #70cbf1 !important;
}

.scheduleFilterDropdownEl .el-input.is-disabled .el-input__inner:hover {
	border-color: #d1dbe5 !important;
}

.el-input.is-disabled>i {
	cursor: not-allowed;
}

.scheduleFilterDropdownEl>div.el-input>input.el-input__inner:focus {
	border: solid 1.5px #40b9ec !important;
}

.scheduleFilterDropdownEl>div.el-input>input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.scheduleFilterButtonRow {
	margin-top: 29px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.scheduleFilterButtonBack {
	width: 59px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #7b8291;
	text-align: center;
	padding-top: 9px;
	font-size: 13px;
	cursor: pointer;
}

.scheduleFilterButtonBack:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.scheduleFilterButtonBack:active {
	background-color: rgba(123, 130, 145, 0.3);
}

.scheduleFilterButtonContinue {
	width: 82px;
	height: 36px;
	border-radius: 6px;
	text-align: center;
	padding-top: 9px;
	background-color: #40b9ec;
	color: white;
	font-size: 13px;
	cursor: pointer;
}

.scheduleFilterButtonContinue:hover {
	background-color: #70cbf1;
	color: #ffffff;
}

.scheduleFilterButtonContinue:active {
	background-color: #0f80b0;
	color: #ffffff;
}

.scheduleFilterButtonContinue:focus {
	border: 2px dashed #70cbf1;
	color: #ffffff;
}

.scheduleAppointmentSideContainerHalf .tm-platform {
	text-transform: capitalize;
}

.scheduleAppointmentSideContainerHalf .provider-name {
	font: normal 500 11px RionaSans-Medium;
}

.scheduleFilterButtonContinueDisabled {
	cursor: no-drop !important;
	opacity: 0.5;
	font-size: 13px;
	color: #ffff !important;
	font-weight: 500;
	font-family: RionaSans-Medium;
}

.scheduleFilterButtonContinueDisabled:hover {
	background-color: #40b9ec !important;
}

.scheduleFilterButtonContinueDisabled:active {
	background-color: #40b9ec !important;
}

.scheduleFilterButtonContinueDisabled:focus {
	border: unset !important;
}

.appointmentCreateSnackbarContainer {
	padding: 0px;
	z-index: 1000000 !important;
}

.appointmentCreateSnackbarContainerClosing {
	transform: translate(100vw, 0px);
	transition: transform 0.6s ease-in 105ms;
}

.appointmentCreateSnackbarContainer>div {
	padding: 0px;
}

.appointmentCreateSnackbarContainer>div>div {
	padding: 0px;
}

.appointmentCreateSnackbar {
	height: 206px;
	width: 300px;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: #252737;
	display: flex;
	flex-direction: column;
	padding: 16px 8px 12px 12px !important;
}

.appointmentCreateSnackbarTopRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-family: 'RionaSans-Bold';
	font-size: 16px;
	color: #ffffff;
}

.appointmentCreateSnackbarX {
	cursor: pointer;
}

.appointmentCreateSnackbarX>div>svg {
	width: 16px;
	height: 16px;
}

.appointmentCreateSnackbarX>div>svg>g>g:hover {
	fill: #a2abbd;
}

.appointmentCreateSnackbarText {
	font-family: 'RionaSans-Bold';
	color: #ffffff;
	font-size: 14px;
}

.appointmentCreateSnackbarSmallText {
	font-size: 12px;
	color: #ffffff;
}

.appointmentCreateSnackbarPhone {
	height: 18px;
	width: 18px;
}

.appointmentCreateSnackbarPhone {
	filter: brightness(0) invert(1);
}

/* payment stage started*/

.appointmentshedule-maincontainer .appointmentschedule-paymentstage {
	position: relative;
}

.scheduleAppointmentContentContainer .appointmentshedule-maincontainer {
	width: 100%;
	background: white;
	padding-left: 24px;
}

.appointmentshedule-maincontainer .selfpay-label {
	font-family: RionaSans;
	font-size: 12px;
	color: #525968;
	margin: 0 0 4px 0;
	display: block;
	font-weight: 400;
}

.appointmentshedule-maincontainer .appointmentschedule-howtopay .selfpay-radiobtn .el-radio__label {
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0;
	font-weight: 400;
}

.appointmentshedule-maincontainer .appointmentschedule-howtopay .payment-headerbtns .el-radio+.el-radio {
	margin: 4px 0 0 0;
}

.appointmentshedule-maincontainer .appointmentschedule-howtopay .payment-headerbtns {
	display: flex;
	flex-flow: column;
	margin-top: 4px;
}

.appointmentshedule-maincontainer .appointmentschedule-howtopay .payment-headerbtns .el-radio-group {
	display: flex;
	flex-flow: column;
}

.appointmentshedule-maincontainer .appointmentschedule-howtopay .payment-headerbtns .el-radio {
	margin: 0;
	width: fit-content;
	max-width: unset;
}

.payment-headerbtns .selfpay-radiobtn .el-radio__input .el-radio__inner {
	width: 12px;
	height: 12px;
	border-color: #a0abba;
	margin-right: 4px;
}

.payment-headerbtns .selfpay-radiobtn .el-radio__input .el-radio__inner::after {
	background-color: #20a0ff;
}

.payment-headerbtns .selfpay-radiobtn .el-radio__input.is-checked .el-radio__inner {
	border-color: #a0abba;
	background: transparent;
}

.payment-headerbtns .selfpay-radiobtn .el-radio__input .el-radio__original {
	margin: 0;
}

.appointmentschedule-paymentstage .appointment-insurance-heading {
	font-family: RionaSans-Bold;
	font-size: 16px;
	color: #262838;
	margin: 0 0 16px 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail {
	position: relative;
}

.health-insurance-select .el-input__inner {
	height: 32px;
	border: solid 1px #bac3ce;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0 0 0 8px;
}

.health-insurance-select-part {
	width: 115px;
}

.health-insurance-select-part>div>input {
	border-radius: 4px 0 0 4px;
	border-right: none;
}

.health-insurance-select-full {
	width: 100%;
}

.health-other-textbox>input {
	border-radius: 0 4px 4px 0;
}

.health-insurance-select .el-input__inner::placeholder {
	color: #262837;
	font-size: 13px;
}

.health-insurance-select .el-input .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail label.selfpay-label {
	margin: 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .appointmentschedule-companydetail {
	display: flex;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-other-textbox {
	width: 462px;
	/* border: solid 1px #bac3ce; */
	height: 32px;
	border-radius: 0 4px 4px 0;
	text-indent: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.appointmentschedule-insurancesection input {
	font-size: 13px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-text-container {
	display: flex;
	width: 608px;
	margin-right: 16px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-text-container .el-select {
	display: flex;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-container {
	display: flex;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-container input.el-input__inner {
	width: 145px;
	border: solid 1px #bac3ce;
	height: 32px;
	border-radius: 4px;
	text-indent: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-container .full-width-company input.el-input__inner {
	width: 608px;
	border: solid 1px #bac3ce;
	height: 32px;
	border-radius: 4px;
	text-indent: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-other-textbox input.el-input__inner {
	width: 462px;
	border: solid 1px #bac3ce;
	height: 32px;
	border-radius: 4px;
	text-indent: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0;
	display: flex;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .select-dropdown-border .el-input__inner {
	border-radius: 4px 0 0 4px !important;
	border-right: none !important;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .select-textbox-border .el-input__inner {
	border-radius: 0 4px 4px 0 !important;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-container .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancesection>input {
	font-size: 13px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-container input.el-input__inner::placeholder {
	color: #262837;
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 13px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .insuranceid-textbox {
	width: 192px;
	border: solid 1px #bac3ce;
	height: 32px;
	border-radius: 4px;
	text-indent: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 {
	position: relative;
	margin-top: 19px;
	display: flex;
	margin-bottom: 19px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .tooltip-info svg {
	width: 12px;
	height: 12px;
	color: white;
	text-align: center;
	font-size: 10px;
	margin-left: 4px;
	position: relative;
	top: 3px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .insurancce-num {
	display: flex;
	align-items: center;
}

.insurance-num>div.el-tooltip {
	position: relative;
	top: 2px;
	left: 4px;
}

.insurance-num .el-tooltip>div.el-tooltip__popper {
	width: 200px !important;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .insurance-numtextbox {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	text-indent: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	text-indent: 8px;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	padding: 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .el-input__inner::placeholder {
	color: #262837;
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 13px;
}

.insurancebox {
	width: 192px;
	margin-right: 16px;
	position: relative;
}

.appointmentschedule-insurancedetail .health-insurance-part3 {
	margin-bottom: 19px;
}

.appointmentschedule-insurancedetail .health-insurance-part3 .insurance-numtextbox {
	width: 816px;
	height: 32px;
	font-family: RionaSans;
	font-size: 13px;
	color: #525968;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	text-indent: 8px;
	padding: 0;
}

.appointmentschedule-insurancedetail .health-insurance-part3 .insurance-numtextbox::placeholder {
	color: #262837;
	opacity: 0.5;
	font-size: 13px;
	font-family: RionaSans;
}

.insurancePlanType {
	width: 100%;
}

.appointmentschedule-insurancedetail .health-insurance-part4 {
	display: flex;
	margin-bottom: 19px;
}

.appointmentschedule-insurancedetail .health-insurance-part4 .el-input__inner {
	width: 192px;
	height: 32px;
	font-family: RionaSans;
	font-size: 13px;
	color: #525968;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	text-indent: 8px;
	padding: 0;
}

.appointmentschedule-insurancedetail .health-insurance-part4 .el-input__inner::placeholder {
	color: #262837;
	opacity: 0.5;
	font-size: 13px;
	font-family: RionaSans;
}

.appointmentschedule-insurancedetail .health-insurance-part4 .insurance-state-select .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 0;
	font-size: 13px;
}

.AppointmentSectionRight .insurancebox {
	width: 200px;
}

.insuranceShortField {
	width: 92px !important;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part4 .insurance-state-select .el-input__inner::placeholder {
	color: #262837;
	font-size: 13px;
	opacity: 0.5;
	font-family: RionaSans;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part4 .insurance-state-select .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .insurance-state-select .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part7 .insurance-state-select .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancedetail .health-insurance-part5 {
	display: flex;
	position: relative;
	margin-bottom: 17px;
}

.appointmentschedule-insurancedetail .health-insurance-part5 .insurance-state-select .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 0;
	padding-left: 8px;
	font-size: 13px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part5 .insurance-state-select .el-input__inner::placeholder {
	color: #262837;
	font-size: 13px;
	opacity: 0.5;
	font-family: RionaSans;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part5 .insurance-state-select .el-input .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancedetail .health-insurance-part5 .appointmentschedule-howtopay {
	margin-bottom: 0;
}

.appointmentschedule-insurancedetail .health-insurance-part6,
.health-insurance-part7 {
	display: flex;
	position: relative;
}

.appointmentschedule-insurancedetail .health-insurance-part6 .insurance-state-select .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 0;
	padding-left: 8px;
	font-size: 13px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part6 .insurance-state-select .el-input .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancedetail .health-insurance-part7 .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 0;
	padding-left: 8px;
	font-size: 13px;
}

.appointmentschedule-insurancedetail .health-insurance-part7 .el-input__inner::placeholder {
	color: #262837;
	font-size: 13px;
	opacity: 0.5;
	font-family: RionaSans;
}

.insurance-state-select.policyBiologicalSex {
	width: 100%;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part3 .insuranceadd_width .el-input__inner {
	width: 816px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	background-color: #ffffff;
	text-indent: 8px;
	color: #262837;
	font-family: RionaSans;
	font-size: 13px;
	padding: 0;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part3 .insuranceadd_width .el-input__inner::placeholder {
	color: #262837;
	font-size: 13px;
	opacity: 0.5;
	font-family: RionaSans;
}

.appointmentschedule-companydetail .insuranceid-block .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	background-color: #ffffff;
	padding: 0;
	text-indent: 8px;
	color: #262837;
	font-size: 13px;
	font-family: RionaSans;
}

.appointmentschedule-companydetail .insuranceid-block .el-input__inner::placeholder {
	color: #262837;
	font-size: 13px;
	opacity: 0.5;
	font-family: RionaSans;
}

.appointmentschedule-insurancesection input {
	width: 100%;
}

/* credit card details start*/

.appointmentschedule-creditcardsection {
	width: 632px;
	margin: auto;
}

.appointmentschedule-insurancedetail .insurancedetail-part1,
.appointmentschedule-insurancedetail .insurancedetail-part2 {
	display: flex;
	margin-bottom: 19px;
}

.appointmentschedule-insurancedetail .insurancedetail-part1 .insurancebox {
	position: relative;
	margin-right: 16px;
	width: auto;
}

.appointmentschedule-insurancedetail .selfpay-label {
	margin: 0;
}

.appointmentschedule-insurancedetail .insurancedetail-part1 .el-input__inner {
	width: 400px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	text-indent: 8px;
	font-size: 13px;
	color: #262837;
	padding: 0;
}

.appointmentschedule-insurancedetail .insurancedetail-part1 .el-input__inner:hover {
	border-color: #8391a5;
}

.appointmentschedule-insurancedetail .insurancedetail-part1 .el-input__inner:focus {
	outline: 0;
	border-color: #20a0ff;
}

.appointmentschedule-insurancedetail .insurancedetail-part1 .el-input__inner::placeholder {
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.appointmentschedule-insurancedetail .insurancedetail-part2 .insurance-state-select .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 0;
	padding-left: 8px;
	font-size: 13px;
	font-family: RionaSans;
	color: #262837;
}

.appointmentschedule-insurancedetail .insurancedetail-part2 .bill-text {
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
	margin-left: 4px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}

.appointmentschedule-insurancedetail .insurancedetail-part2 .bill-content {
	display: flex;
	/* align-items: center; */
	margin-top: 17px;
}

.appointmentschedule-insurancedetail .insurancedetail-part2 .insurance-state-select .el-input__inner::placeholder {
	color: #262837;
	font-size: 13px;
	opacity: 0.5;
	font-family: RionaSans;
}

.appointmentschedule-insurancedetail .insurancedetail-part2 .insurance-state-select .el-input .el-input__icon {
	font-size: 10px;
	color: #bac3ce;
}

.appointmentschedule-insurancedetail .insurancedetail-part2 .el-input__inner {
	width: 192px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	text-indent: 8px;
	font-size: 13px;
	font-family: RionaSans;
	color: #262837;
	padding: 0;
}

.appointmentschedule-insurancedetail .insurancedetail-part2 .el-input__inner::placeholder {
	color: #262837;
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 13px;
}

.appointmentschedule-creditcardsection .insurance-card-container {
	padding: 20px;
	display: flex;
	width: 100%;
	height: 78px;
	border-radius: 8px;
	background-color: rgba(169, 225, 246, 0.25);
	align-items: center;
}

.appointmentschedule-creditcardsection .insurance-card-container .card-display-btn {
	background: transparent;
	border: none;
	color: #49c0ef;
	font-family: RionaSans-Bold;
	font-size: 14px;
}

.appointmentschedule-creditcardsection .insurance-card-container .card-numdetails {
	margin-left: 12px;
	font-family: RionaSans;
	font-size: 14px;
	color: #2a2d3e;
}

.appointmentschedule-creditcardsection .insurance-card-container .card-numdetails span.card_name {
	font-family: RionaSans-Bold;
}

.appointmentschedule-creditcardsection .insurance-card-container .insurance-carddetail-leftpart {
	display: flex;
	flex: 1;
	align-items: center;
}

.appointmentschedule-creditcardsection .insurance-card-container .insurance-carddetail-leftpart img {
	width: 56px;
	height: 30px;
	border: solid 1px #c2cad4;
}

.aptmnt-smry-modality-icon {
	width: 18px;
	height: 18px;
}

.scheduleAppointmentSideContainerSection .aptmnt-status {
	color: #00d65b;
	font-family: RionaSans-Bold;
	text-transform: capitalize;
	margin-bottom: 3px;
}

/* credit card details end*/

.appointmentshedule-maincontainer .appointmentschedule-paymentstage .insurance-footer-button {
	padding-top: 24px;
	display: block;
}

.appointmentshedule-maincontainer .appointmentschedule-paymentstage .insurance-footer-button .health-backbutton {
	width: 59px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b8291;
	background-color: white;
	font-family: RionaSans-Medium;
	color: #7b8291;
	font-size: 13px;
}

.appointmentshedule-maincontainer .appointmentschedule-paymentstage .insurance-footer-button .health-backbutton:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.appointmentshedule-maincontainer .appointmentschedule-paymentstage .insurance-footer-button .health-backbutton:active {
	background-color: rgba(123, 130, 145, 0.3);
}

.position-rel {
	position: relative;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .insurance-num .el-tooltip__popper.is-dark {
	width: 199px;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 .insurance-num .el-tooltip {
	display: inline-block;
}

.error {
	font-size: 10px;
	color: red;
	display: flex;
}

.appointmentschedule-insurancedetail .el-checkbox__inner {
	width: 10px !important;
	height: 10px !important;
}

.appointmentschedule-insurancedetail .el-checkbox__inner::after {
	top: -5px !important;
}

.appointmentschedule-insurancedetail input:hover {
	border-color: #8391a5;
}

.appointmentschedule-insurancedetail input:focus {
	outline: 0;
	border-color: #20a0ff;
}

/* payment stage css end*/

/* error popup */

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__header {
	padding: 20px 20px 0;
}

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__body {
	padding: 20px;
}

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__body .error-text ul {
	padding-left: 20px;
	margin-bottom: 0;
}

.el-dialog__wrapper .el-dialog.el-dialog--tiny.modality-dialog .el-dialog__footer {
	padding: 8px 20px 20px;
}

/* peyment page input type hover and focus effect */

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 input.el-input__inner:hover {
	border-color: #8391a5;
}

.appointmentschedule-insurancesection .appointmentschedule-insurancedetail .health-insurance-part2 input.el-input__inner:focus {
	border-color: #20a0ff !important;
	outline: 0;
}

.appointmentschedule-insurancedetail input:hover,
.appointmentschedule-insurancedetail .health-insurance-part7 .el-input__inner:hover {
	border-color: #8391a5;
}

.appointmentschedule-insurancedetail input:focus,
.appointmentschedule-insurancedetail .health-insurance-part7 .el-input__inner:focus {
	outline: 0;
	border-color: #20a0ff;
}

/* start: reschedule appointment module */

.reschedule-val-visitfee {
	font-family: RionaSans-Bold;
}

/* end: reschedule appointment module */

.appointment_page_wrapper .filter-rightcontainer .el-scrollbar .el-scrollbar__bar {
	opacity: 1;
}

.appointment_page_wrapper .filter-rightcontainer>div.el-input>input.el-input__inner {
	/* height: 32px !important; */
	transition: border-color 0.01s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	font-size: 13px !important;
}

.appointment_page_wrapper .filter-rightcontainer .el-input>input.el-input__inner:hover {
	border: solid 1px #70cbf1 !important;
}

.appointment_page_wrapper .filter-rightcontainer .el-input.is-disabled .el-input__inner:hover {
	border-color: #d1dbe5 !important;
}

.appointment_page_wrapper .filter-rightcontainer .el-input>input.el-input__inner:focus {
	border: solid 1.5px #40b9ec !important;
}

.scheduleAppointmentSideContainerSection.summary-payment-container .el-tooltip {
	z-index: 1;
}

/* appointment alert summary start */
.scheduleAppointmentSideContainerSection .warning-wrapper {
	border-radius: 4px;
	background-color: #faedac;
	padding: 8px;
	display: flex;
}

.scheduleAppointmentSideContainerSection .warning-wrapper .warning-message {
	font-family: RionaSans-MediumItalic;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #4b3700;
}

.scheduleAppointmentSideContainerSection .warning-wrapper .warning-icon {
	width: 24px;
	height: 24px;
}

.scheduleAppointmentSideContainerSection .warning-wrapper .warning-icon>div>svg>g>path:nth-child(3) {
	fill: #4b3700;
}

/* appointment alert summary end */
