.mlo-results-cap-not-sent-wrapper {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 0 0 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.mlo-results-cap-not-sent-wrapper table {
	margin-top: 16px;
}

.mlo-results-cap-not-sent-wrapper table .el-checkbox__input.is-focus .el-checkbox__inner,
.mlo-results-cap-not-sent-wrapper table .el-checkbox__input .el-checkbox__inner:hover {
	border-color: #7b26c9;
}

.mlo-results-cap-not-sent-wrapper table .el-checkbox__input .el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}

.mlo-results-cap-not-sent-wrapper table .el-checkbox__input.is-checked .el-checkbox__inner,
.mlo-results-cap-not-sent-wrapper table .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	border-color: #7b26c9;
	background-color: #7b26c9 !important;
}

.mlo-results-cap-not-sent-wrapper table .el-checkbox {
	margin: unset;
}

.mlo-results-cap-not-sent-wrapper table thead tr th:first-child>span,
.mlo-results-cap-not-sent-wrapper table tbody tr td:not(.no-results):first-child>div {
	min-width: unset !important;
	max-width: 24px !important;
	display: flex;
	justify-content: flex-end;
}

.mlo-results-cap-not-sent-wrapper table td p {
	margin: unset;
}

.mlo-results-cap-not-sent-wrapper table td .sub {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.mlo-results-cap-not-sent-wrapper table td .capitalize {
	text-transform: capitalize;
}

.mlo-results-cap-not-sent-wrapper table td .capitalize span.cap-needs-amendment,
.mlo-results-cap-not-sent-wrapper table td .capitalize span.cap-incomplete {
	text-transform: capitalize;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #ad1505;
}

.mlo-results-cap-not-sent-wrapper table td .capitalize span.cap-was-amended {
	text-transform: capitalize;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.mlo-results-cap-not-sent-wrapper table td .hover:hover {
	cursor: pointer;
	text-decoration: underline;
}

.mlo-results-cap-not-sent-wrapper table td .high-priority,
.mlo-results-cap-not-sent-wrapper table td .has-guardian {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-left: 4px;
	padding: 4px;
	border-radius: 50%;
	vertical-align: middle;
}

.mlo-results-cap-not-sent-wrapper table td .high-priority {
	background-color: #fde776;
}

.mlo-results-cap-not-sent-wrapper table td .has-guardian {
	background-color: #9fdcf5;
}

.mlo-results-cap-not-sent-wrapper table td .high-priority::before,
.mlo-results-cap-not-sent-wrapper table td .has-guardian::before {
	content: ' ';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #4b3700;
	position: absolute;
}

.mlo-results-cap-not-sent-wrapper table .pioneer span {
	display: flex;
	align-items: center;
}

.mlo-results-cap-not-sent-wrapper table .pioneer .warningIcon {
	margin-right: 0.25rem;
	margin-top: -0.2rem;
}

.mlo-results-cap-not-sent-wrapper table .pioneer .warningIcon svg {
	height: 12px;
	width: 12px;
	vertical-align: middle;
}

.mlo-results-cap-not-sent-wrapper table .pioneer .warningIcon svg path.GMIWarningCircleIcon {
	fill: #e74c3c;
}

.mlo-results-cap-not-sent-wrapper table .pioneer .successIcon {
	margin-right: 0.25rem;
	margin-top: -0.2rem;
}

.mlo-results-cap-not-sent-wrapper table .pioneer .successIcon svg {
	height: 12px;
	width: 12px;
	vertical-align: middle;
}

.mlo-results-cap-not-sent-wrapper table .pioneer .successIcon svg path.GMICheckCircleIcon {
	fill: #65972A;
}

.mlo-results-cap-not-sent-wrapper table td.no-results span.separator {
	cursor: unset;
	margin: 8px;
	vertical-align: text-bottom;
}

.mlo-results-cap-not-sent-wrapper table td.no-results span.separator::before {
	content: '.';
	color: #333;
}

.mlo-results-cap-not-sent-wrapper .table-loading {
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	background: transparent;
	min-width: inherit;
}