/* stylelint-disable selector-class-pattern */
.add-edit-nucleotide-repeat-variant-dialog {
	width: 731px !important;
	position: fixed !important;
	height: min(calc(100% - 118px), 1472px);
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}

/******************************************************************************
								Dialog Header
*******************************************************************************/

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__header {
	border-bottom: 1px solid #edeff3;
	padding: 15px 16px;
}

.add-edit-nucleotide-repeat-variant-dialog .header {
	display: flex;
	justify-content: space-between;
	color: #262837;
	font: bold 20px RionaSans-Bold, sans-serif;
}

.add-edit-nucleotide-repeat-variant-dialog .header .close-btn-icon {
	height: 23px;
	cursor: pointer;
}

.add-edit-nucleotide-repeat-variant-dialog .header .close-btn-icon:hover {
	background-color: #ececec;
	border-radius: 20px;
	height: 27px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__title .close-icon svg .GMICloseIcon {
	fill: #262837;
}

/******************************************************************************
								Dialog Body
*******************************************************************************/
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body {
	padding: 22px 11px;
	height: calc(100% - 118px);
	overflow-y: auto;
	font: normal 12px RionaSans, sans-serif;
	color: #262837;
}

.add-edit-nucleotide-repeat-variant-dialog .el-input {
	font: normal 13px RionaSans, sans-serif;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .el-input,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .el-input__inner {
	width: 195px;
}

.add-edit-nucleotide-repeat-variant-dialog h2.module-header {
	display: flex;
	width: 100%;
	align-items: center;
	font: normal 500 16px RionaSans-Medium, sans-serif;
	margin: 0;
	color: #525968;
	text-transform: capitalize;
}

.add-edit-nucleotide-repeat-variant-dialog .el-row {
	margin-top: 16px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .iscn-sequence .el-input__inner,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .el-input.iscn-sequence {
	width: 98%;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body section:last-child {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 0;
}

.add-edit-nucleotide-repeat-variant-dialog .basic-information-module,
.add-edit-nucleotide-repeat-variant-dialog .identification-module,
.add-edit-nucleotide-repeat-variant-dialog .interpretation-module,
.add-edit-nucleotide-repeat-variant-dialog .additional-information-module {
	border-bottom: 1px solid #edeff3;
	padding-bottom: 24px;
	margin-bottom: 24px;
}

.add-edit-nucleotide-repeat-variant-dialog .repeat-allele-title {
	font: normal 500 14px RionaSans-Medium, sans-serif;
}

.add-edit-nucleotide-repeat-variant-dialog .repeat-allele-subtitle {
	font: normal italic 500 12px RionaSans-Medium, sans-serif;
}

.add-edit-nucleotide-repeat-variant-dialog .or-label {
	font: normal 500 14px RionaSans-Medium, sans-serif;
	color: #525968;
	margin-left: 8px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-input .el-input__inner::placeholder {
	font: normal 13px RionaSans, sans-serif !important;
	color: #bac3ce;
}

.add-edit-nucleotide-repeat-variant-dialog .required::before {
	content: "*";
	font: normal bold 12px RionaSans, sans-serif;
	color: #e74c3c;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .el-radio__label {
	font: normal bold 13px RionaSans, sans-serif;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .dnaChange,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .proteinChange {
	display: block;
	margin-bottom: 8px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .proteinChange {
	padding-left: 6px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .mt-zero {
	margin-top: 0;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .mt-20 {
	margin-top: 20px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .mt-32 {
	margin-top: 32px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__body .input-error-text {
	width: 100%;
	font: normal 10px RionaSans, sans-serif;
	color: #ff0000;
	word-break: break-word;
}

/******************************************************************************
								Dialog Footer
*******************************************************************************/
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 11px 16px;
	display: flex;
	justify-content: space-between;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer button {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	width: 160px;
	padding: 8px 12px;
	border-radius: 7px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .next.is-disabled,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .save.is-disabled,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .is-disabled:active,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .is-disabled:hover {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
	color: #ffffff;
	border: none;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .next,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .save {
	border: 1px solid #7b26c9 !important;
	color: #ffffff;
	background-color: #7b26c9;
	margin-left: 16px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .next:hover,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .save:hover {
	background-color: #9c5dd7;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .next:active,
.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .save:active {
	background-color: #621ea0;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .delete-variant-icon {
	width: 28px;
	height: 28px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .delete-variant {
	border: 1px solid #e74c3c !important;
	color: #e74c3c;
	width: 148px;
	padding: 3px 0 3px 10px;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .delete-variant.is-disabled {
	cursor: no-drop;
	opacity: 0.35;
	user-select: none;
	background-color: transparent !important;
	color: #e74c3c !important;
	border: none;
}

.add-edit-nucleotide-repeat-variant-dialog .el-dialog__footer .delete-variant span {
	display: flex;
	align-items: center;
}
