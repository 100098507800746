.payment-info-tab .el-upload-dragger {
	width: 100% !important;
	height: 124px;
	width: 298px;
}

.payment-info-tab .el-upload-dragger .el-upload__text {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7b8291;
}

.payment-info-tab .cancel-button {
	border-radius: 6px;
	border: solid 1px #525968;
	padding: 4px 14px;
	cursor: pointer;
}

.payment-info-tab .cancel-button:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.payment-info-tab .cancel-button:active {
	background-color: rgba(123, 130, 145, 0.3);
}

.payment-info-tab .cancel-button .text {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #525968;
}

.payment-info-tab .save-button {
	padding: 5px 24px;
	border-radius: 6px;
	background-color: #41b9ec;
	border: 1px solid #41b9ec;
	cursor: pointer;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #ffffff;
}

.payment-info-tab .save-button:hover {
	background-color: #70cbf1 !important;
	border: 1px solid #70cbf1 !important;
	color: #ffffff !important;
}

.payment-info-tab .save-button:active {
	background-color: #0f80b0 !important;
	border: 1px solid #0f80b0 !important;
	color: #ffffff !important;
}

.payment-info-tab .save-button:focus {
	color: #ffffff !important;
	background-color:#40b9ec !important;
}

.payment-info-tab .save-button.is-disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.payment-info-tab .action-buttons {
	margin-top: 24px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.btn-add-primary-insurance .btn-plus-icon,
.btn-add-secondary-insurance .btn-plus-icon,
.btn-add-lab-insurance .btn-plus-icon {
	height: 28px;
	width: 28px;
}

.payment-info-tab .el-radio__label {
	width: 18px;
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	padding-left: 4px;
}

.payment-info-tab .upload-image {
	height: 40px;
	width: 40px;
	margin-top: 29px;
}

.payment-info-tab .el-form-item__label {
	float: none;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	padding: 0;
	margin-bottom: 0;
	text-align: inherit;
}

.payment-info-tab .el-form-item {
	margin-bottom: 0px;
}

.payment-info-tab .full_width {
	width: 100%;
}

.payment-info-tab .full_width .el-select {
	width: 100%;
}

.payment-info-tab .company_name .el-select {
	width: 76.33%;
}

.payment-info-tab .el-form-item.is-required .el-form-item__label:before {
	font-family: RionaSans;
	font-size: 15px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ff0000;
	vertical-align: text-top;
}

.payment-info-tab .policyholder-partition {
	margin-top: 49px;
}

.policyholder-label .el-form-item__label {
	text-align: left;
}

.policyholder-label .insurance-phn {
	display: flex;
	align-items: center;
}

.policyholder-label .phone-tooltip-main {
	margin-left: 4px;
}

.policyholder-label .phone-tooltip-mainb2 {
	margin-left: 4px;
}

.phone-tooltip-main .el-tooltip__popper {
	top: 238px !important;
}

.no-error-required input[type='text'] {
	border: solid 1px #fb3a2e;
	background-color: rgba(254, 145, 137, 0.5);
}

.no-error-required input[type='text']::placeholder {
	color: #ff5a4b;
}

.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-textarea__inner {
	border-color: #ff4949;
}

.other-selection {
	height: 36px;
}

.other-selection input[type='text'] {
	border: solid 1px #bac3ce !important;
	color: #262837 !important;
	background-color: white !important;
	border-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: none !important;
}

.insurance-form .el-input-group__prepend {
	border: none;
}

.is-error .other-selection .el-input .el-icon-caret-top {
	color: #bac3ce !important;
}

.athena-rec-id-label .el-form-item__label {
	text-align: left;
}

.payment-info-tab .company_name .el-form-item {
	float: left;
}

.payment-info-tab .company_name_field .el-form-item__error {
	margin-top: 12px;
	margin-left: -10px;
}

.payment-info-tab .insurance-form .el-form-item__error {
	position: unset;
}

.payment-info-tab .company_name .el-input {
	width: 100%;
}

.payment-info-tab .insurance-loader {
	z-index: 1;
	min-height: 585px;
}

.loader-insurance-form > div:first-child {
	left: -8px !important;
}

.payment-info-tab .el-input__inner {
	border: solid 1px #bac3ce;
	color: #262837;
	font-size: 13px;
}

.payment-info-tab .el-input__inner::placeholder {
	opacity: 0.5;
	color: #262837;
	font-size: 13px !important;
}

.payment-info-tab .el-select .el-input .el-input__icon {
	color: #bac3ce;
	font-size: 10px;
}

.payment-info-tab .el-radio-group .el-radio {
	display: block;
}

.payment-info-tab .el-radio + .el-radio {
	margin-left: 0;
}

.payment-info-tab .el-radio__inner {
	width: 12px;
	height: 12px;
}

.payment-info-tab .el-date-editor.el-input {
	width: 100%;
}

.payment-info-tab .pull-left {
	float: left !important;
}

.payment-info-tab .other-selection .el-input {
	color: #000;
	width: 140px;
	height: 34px;
}

.payment-info-tab .field_company_name {
	width: 97%;
}

.payment-info-tab .el-select-dropdown {
	left: 0 !important;
}

.payment-info-tab .is-submitting .is-error .el-input__inner {
	border: solid 1px #fb3a2e;
	background-color: rgba(254, 145, 137, 0.5);
}

.payment-info-tab .is-company-error .el-input__inner {
	border-left: none;
}

.payment-info-tab .is-submitting .is-error .el-input__inner::placeholder {
	color: #ff5a4b;
}

.payment-info-tab .is-error .el-select .el-input__inner,
.payment-info-tab .is-error .el-select .el-input:hover {
	color: #ff5a4b;
}

.payment-info-tab .is-error .el-icon-date {
	color: #ff5a4b;
}

.payment-info-tab .is-error .el-select .el-input .el-icon-caret-top {
	color: #ff5a4b;
}

.payment-info-tab .el-form-item__content .is-error .el-input-group__prepend {
	border: solid 1px #fb3a2e;
	background-color: rgba(254, 145, 137, 0.5);
	color: #ff5a4b;
}

.payment-info-tab .insurance-form .el-radio__input.is-checked .el-radio__inner {
	border-color: #9eddf6;
	background: #ffffff;
}

.payment-info-tab
	.insurance-form
	.el-radio__input.is-checked
	.el-radio__inner::after {
	background-color: #40b9ec;
}

@media only screen and (max-width: 1366px) {
	.payment-info-tab .el-form-item__label {
		font-size: 11px;
	}
}

.payment-info-tab .insurance-form .phone-tooltip {
	width: 179px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.payment-info-tab .insurance-form .phone-tooltip-icon {
	width: 12px;
	height: 12px;
}

.payment-info-tab .insurance-form .capitalize {
	text-transform: capitalize !important;
}

.payment-info-tab .insurance-form .label-server-error {
	color: #ff4949;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
	font-weight: normal;
}

.phone-star {
	position: relative;
}

.phone-star:before {
	content: '*';
	font-family: RionaSans;
	position: absolute;
	left: -9px;
	font-size: 15px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ff4949;
	vertical-align: text-top;
}

.payment-info-tab .d-flex-primary,
.payment-info-tab .d-flex-secondary {
	display: flex;
	margin-top: 24px;
}

.insurance-type-heading-text {
	font-size: 16px;
	color: #525968;
	font-weight: bold;
	margin-bottom: 16px;
	padding-top: 5px;
}

.insurance-view-loader .el-loading-spinner {
	top: 80px;
}

.insurance-info-action-buttons-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.btn-plus-primary-insurance,
.btn-plus-secondary-insurance {
	background-color: #41b9ec;
	color: white;
	border-radius: 6px;
	display: flex;
    justify-content: center;
	padding: 1px 24px;
	cursor: pointer;
	font: normal 500 12px/2.5 RionaSans, sans-serif;
	margin-left: 16px;
}

.btn-plus-primary-insurance .btn-plus-icon,
.btn-plus-secondary-insurance .btn-plus-icon,
.btn-plus-lab-insurance .btn-plus-icon {
	height: 28px;
	width: 28px;
}

.btn-plus-lab-insurance {
	display: flex;
	border: 1px solid #41b9ec;
	border-radius: 6px;
	background-color: #ffffff;
	color: #41b9ec;
	font: normal 500 12px/2.5 RionaSans, sans-serif;
	cursor: pointer;
	padding: 0 24px;
	align-self: flex-end;
}

.btn-plus-lab-insurance .btn-plus-icon svg > g > path {
	fill: #41b9ec;
}
