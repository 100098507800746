.provider-link-form .provider-link-form-inner {
	padding: 32px 16px;
	max-height: calc(100vh - 235px);
	overflow-y: overlay;
}
.provider-link-form .el-row:not(:last-child) {
	margin-bottom: 24px;
}
.provider-link-form .el-row .el-form-item {
	margin: 0;
}
.provider-link-form .el-row .el-form-item__content {
	font-size: 12px;
	line-height: 0;
}
.provider-link-form .el-row .provider-info {
	padding: 12px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	background-color: #edeff3;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.provider-link-form .el-row .provider-info .provider {
	display: flex;
	max-width: 250px;
}
.provider-link-form .el-row .provider-info .provider .info {
	margin-left: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.provider-link-form .el-row .provider-info .provider .info .pro-name {
	font: bold 14px/1.43 RionaSans-Bold;
	color: #262837;
}
.provider-link-form .el-row .provider-info .provider .info .location {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #262837;
	display: block;
}
.provider-link-form .el-row .provider-info .designation {
	font: bold 12px/1.25 RionaSans-Bold;
	text-align: right;
	color: #7b8291;
}
.provider-link-form .el-row .el-dialog__footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.provider-link-form .el-row .note {
	font: normal 500 13px/1.38 RionaSans-MediumItalic;
	color: #262837;
	margin-top: 8px;
}
.provider-link-form .el-row .el-form-item .el-form-item__label {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #262837;
	float: inherit;
	padding: 0px;
	margin: 0px 0px 8px 0px;
}
.required-asterisk {
	font-family: RionaSans-Bold;
	font-size: 12px;
	color: #e74c3c;
	position: relative; 
	top: 0;
	left: -6px;
  }