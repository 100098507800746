.errvaluediv {
	color: red;
	margin-bottom: 1.1vw;
	padding-left: 0vw;
}
.valuespan {
	font-weight: 900;
}
.suggestion {
	font-size: 1vw;
	color: #848688;
	margin-bottom: 0.9vw;
}
.font-size-14 {
	font-size: 15px;
}
.flot-left {
	float: left;
}
.textdiv {
	float: left;
	padding-left: 0.5vw;
	padding-top: 0.1vw;
	font-size: 0.85vw;
}
.help_button {
	color: #40b9ec;
}

.help_button:hover {
	text-decoration: underline;
	color: #20a0ff;
}

.errorcomp {
	margin-top: 1vw;
	margin-left: 0vw;
	border-top: 1px solid rgb(207, 215, 228);
	width: 100%;
}

.searchHelpDialog {
	width: 75%;
	background: transparent;
	box-shadow: none !important;
}

/*.help_gif:hover{
    background-color: #ccc;
    cursor: pointer;
}*/
