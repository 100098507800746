.notesContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 16px;
}

.commentBoxContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.commentBoxEmptyMessage {
	font-family: 'RionaSans';
	font-size: 14px;
	color: #707070;
	margin-top: 22px;
	-webkit-font-smoothing: antialiased;
}

.notesBottomRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 16px;
}

.notesIconContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	top: 2px;
}

.notesIconContainerActive:hover {
	text-decoration: underline;
	cursor: pointer;
}

.notesIconContainerInactive {
	cursor: no-drop;
}

.commentBoxNotesIcon {
	position: relative;
	top: 4px;
	padding-right: 9px;
}

.commentBoxNotesIconActive > div > svg > g > g > path {
	fill: #7b26c9;
}

.commentBoxNotesIconInactive > div > svg > g > g > path {
	fill: #a0abb9;
}

.notesViewAllText {
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	-webkit-font-smoothing: antialiased;
	padding-top: 2px;
}

.notesViewAllTextActive {
	color: #7b26c9;
	cursor: pointer;
}

.notesViewAllTextInactive {
	color: #a0abb9;
}

.commentBoxAuthorDateTimeDense {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

.commentBoxAuthorDateTimeFull {
	display: flex;
	flex-direction: column;
	margin-bottom: 12px;
}

.commentBoxAuthor {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: 'RionaSans-Medium';
	font-size: 14px;
	color: #262837;
	-webkit-font-smoothing: antialiased;
	height: 20px;
	position: relative;
	right: 5px;
}

.commentBoxAuthorFull {
	position: relative;
	right: 32px;
	margin-bottom: 8px;
}

.commentBoxAuthorName {
	position: relative;
	top: 1px;
}

.commentBoxDateTime {
	font-family: 'RionaSans';
	font-size: 12px;
	color: #525968;
}

.commentBoxDateTimeFull {
	padding-left: 8px;
}

.commentBoxComment {
	font-family: RionaSans-Light;
	font-size: 14px;
}

.commentBoxCommentFull {
	padding-left: 8px;
	margin-bottom: 20px;
	overflow-wrap: break-word;
	white-space: pre-wrap;
}

.viewNotesContainer {
	width: 500px;
	height: 450px;
	position: absolute;
	top: calc(50vh - 225px);
	left: calc(50vw - 250px);
	border-radius: 5px;
	background-color: white;
	box-shadow: 0px 3px 6px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.viewNotesHeaderRow {
	width: 100%;
	height: 56px;
	padding: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #edeff3;
}

.viewNotesXIcon {
	transform: scale(1.38);
	position: relative;
	left: 8px;
	top: 1px;
}

.viewNotesBody {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: overlay;
	padding: 32px 54px 10px 42px;
}

/**********************************************
    CSS for Non Dense variant(cfDNA)
***********************************************/
.notesContainer .btn-add-comment {
	position: absolute;
	right: 8px;
	bottom: 0;
	padding: 3px 16px 3px 4px;
	border: 1px solid #7b26c9;
	border-radius: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #7b26c9;
	background-color: #ffffff;
	font: normal 500 13px RionaSans-Medium;
	text-align: center;
	cursor: pointer;
}

.notesContainer .btn-add-comment:hover {
	background-color: rgba(123, 38, 201, 0.15);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.notesContainer .btn-add-comment:active {
	background-color: rgba(123, 38, 201, 0.3);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.notesContainer .btn-add-comment .icon-plus-purple {
	transform: scale(1.6, 1.6);
	margin-top: 6px;
}

.notesContainer .empty-clinical-comments-msg {
	font: normal 14px 'RionaSans';
	color: #707070;
	margin-top: 8px;
	-webkit-font-smoothing: antialiased;
}

.notesContainer .btn-view-all-container {
	position: absolute;
	top: 0;
	right: 10px;
}
