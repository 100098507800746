.checkboxRowContainer {
	height: 48px;
	min-width: 350px;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 4px;
	background-color: white;
	border: 1px solid #d8dde4;
	border-radius: 4px;
}

.checkboxRowContainerError {
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: #e74c3c;
	margin-top: 6px;
}

.checkboxRowContainerDisabled {
	opacity: 0.35;
	cursor: no-drop !important;
}

.checkboxRowContainerDisabled + div.consent-to-text {
	opacity: 0.35;
	cursor: no-drop !important;
}

.checkboxRowContainerChecked {
	border: 1px solid #7b26c9 !important;
}

.checkboxRowContainer:hover {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
	border: 1px solid #edeff3;
}

.checkboxRowMainRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	padding: 12px;
}

.checkboxRowMainRowLeft {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.checkboxRowMainRowLeftLabel {
	font-size: 13px;
	color: #262837;
	font-family: 'RionaSans-Medium';
	padding-bottom: 8px;
}

.checkboxRowMainRowLeft
	> label.el-checkbox
	.el-checkbox__input.is-checked
	.el-checkbox__inner {
	background-color: #7b26c9 !important;
	border-color: #7b26c9 !important;
}

.checkboxRowMainRowLeft
	> label.el-checkbox
	.el-checkbox__input.is-checked
	.el-checkbox__inner::after {
	border: 2.1px solid #ffffff !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 8px !important;
	left: 4px !important;
	top: 1px !important;
	margin: -1px;
}

.checkboxRowSecondRow {
	width: 100%;
	padding: 0px 12px 0px 12px;
}
