.patient-info-container h3,
.home-address-container h3 {
	padding: 0;
	font: normal bold 16px RionaSans-Bold;
	color: #262838;
	text-transform: uppercase;
}

.patient-info-container h3 {
	margin: 20px 0 16px;
}

.home-address-container h3 {
	margin-top: 24px;
	margin-bottom: 0;
}

.home-address-container .fill-zip-note {
	display: block;
	font: normal 500 10px RionaSans-Medium;
	color: #525968;
	margin-bottom: 16px;
}

/* .continue-button-tooltip {
	margin-left: auto;
} */

/* start: override element react style */

.select-patient-immutablefield .el-input__inner {
	cursor: default;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid #bac3ce;
	cursor: no-drop;
}

.select-patient-immutablefield.el-input.is-disabled .el-input__inner,
.select-patient-immutablefield.el-date-editor
	.el-input.is-disabled
	.el-input__inner,
.select-patient-immutablefield.el-select
	.el-input.is-disabled
	.el-input__inner {
	background-color: #edeff3;
	color: #bac3ce;
}

.patient-info-container .el-form-item,
.home-address-container .el-form-item {
	margin-bottom: 14px;
}

.patient-info-container .emailAlertIcon .warningIcon div {
	transform: scale(0.85);
	padding: 5px;
}

.patient-info-container .emailAlertIcon .warningIcon svg {
	height: 16px;
	width: 16px;
}

.patient-info-container
	.emailAlertIcon
	.warningIcon
	svg
	path.GMIWarningCircleIcon {
	fill: #e74c3c;
}

.select-patient-form .el-date-editor.el-input {
	width: 100%;
}

.txt-dob-field,
.select-referral-prm-field {
	display: block;
}

button.el-button.is-disabled.continue-button,
button.el-button.is-disabled.continue-button:focus,
button.el-button.is-disabled.continue-button:hover {
	background-color: #40b9ec !important;
	color: #fff !important;
}

.continue-button-tooltip .el-tooltip__popper.is-dark {
	left: 700px !important;
}

.continue-button-tooltip .popper__arrow {
	left: 168px !important;
}

.sched-submit-button .tooltip-text {
	width: 105px;
}
.select-patient-form .required::before {
	color: #ff0000;
	content: '*';
	font: normal bold 15px RionaSans-Bold;
	margin-left: -6px;
	margin-right: 2px;
	vertical-align: middle;
	line-height: 1;
	margin-top: 2px;
}

.select-patient-form .required {
	display: flex;
}

/* end: override element react style*/

.appointmentschedule-insurancesection
	.appointmentschedule-insurancedetail
	.health-insurance-part2
	.insurancebox
	.health-text-container
	.error {
	position: absolute;
	bottom: -15px;
}

.select-patient-form .error {
	color: #ff4949;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
}

.select-patient-form .action-buttons {
	margin-top: 5px;
}

.select-patient-form .cancel-button-v2 {
	margin: 0;
}

.select-patient-form .cancel-button {
	width: 70px;
	height: 36px;
	padding: 8px 0;
	border-radius: 6px;
	border: 1px solid #7b8291;
	display: flex;
	justify-content: center;
	align-items: center;
	font: normal 500 13px RionaSans-Medium;
	color: #7b8291;
	cursor: pointer;
}

.select-patient-form .cancel-button:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.select-patient-form .cancel-button:active {
	background-color: rgba(123, 130, 145, 0.3);
}

.select-patient-form .cancel-button-v2 {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	cursor: pointer;
	margin-right: 16px;
}

.select-patient-form .continue-button {
	width: 82px;
	height: 36px;
	padding: 8px 0;
	border-radius: 6px;
	border: 1px solid #40b9ec;
	background-color: #40b9ec;
	display: flex;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font: normal 500 13px RionaSans-Medium;
	color: #ffffff;
	cursor: pointer;
}

.select-patient-form .continue-button:hover {
	background-color: #70cbf1;
}

.select-patient-form .continue-button:active {
	background-color: #0f80b0;
}

.select-patient-form .continue-button-v2:not(.is-disabled) {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
	background-color: #7b26c9;
}
.select-patient-form .continue-button-v2:not(.is-disabled):hover,
.select-patient-form .continue-button-v2:not(.is-disabled):focus {
	background-color: #9c5dd7;
}
.select-patient-form .continue-button-v2:not(.is-disabled):active {
	background-color: #7b26c9;
}
.select-patient-form .continue-button-v2 > span {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #ffffff;
}

.select-patient-form .continue-button-v2.is-disabled {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	opacity: 0.35 !important;
	background-color: #7b26c9 !important;
	cursor: no-drop;
}

.select-patient-form label {
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	font: normal 12px RionaSans !important;
	color: #525968;
}

.select-patient-form .el-input__inner {
	font: normal 13px RionaSans !important;
}

.patient-info-container input::placeholder,
.home-address-container input::placeholder {
	font: normal 13px RionaSans !important;
	color: #bac3ce !important;
}

.scheduleAppointmentContentContainer
	.loading-select-patient-form
	> div:first-child {
	left: -8px !important;
}

.scheduleAppointmentContentContainer .action-buttons-v2 {
	width: 632px;
}

.scheduleAppointmentContentContainer .action-buttons-v2 .sched-submit-button {
	width: auto;
	float: right;
}

.scheduleAppointmentContentContainer
	.action-buttons-v2
	.sched-submit-button-v2 {
	width: auto;
	float: right;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Schedule appointment PATIENT INFO */

.select-patient-form .input-row .el-select {
	width: 100%;
}

.select-patient-form .input-row {
	display: flex;
	flex-flow: column;
}

.select-patient-form .el-date-editor.el-input {
	width: 100%;
}

/* summary appointment Referral program text */

.scheduleAppointmentSideContainerSection.scheduleAppointmentSideContainerPatientText.scheduleAppointmentSideContainerRow
	.scheduleAppointmentSideContainerHalf:nth-child(2) {
	align-items: flex-start;
}

/* coupan code input and button */

.select-patient-form .el-input.is-disabled .el-icon-caret-top {
	cursor: not-allowed;
}

.select-patient-form .el-input.is-disabled .el-input__inner:hover {
	border: 1px solid #d1dbe5 !important;
}
