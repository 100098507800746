.encounter-accordian-body .related-encounter-body-container {
	display: flex;
	flex-direction: column;
	width: 285px;
	height: 112px;
	border: 1px solid #d8dde4;
	border-radius: 4px;
	padding: 14px 16px;
	color: #262837;
}

.encounter-accordian-body .related-encounter-body-container .lbl-prmry {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #0f80b0;
}

.encounter-accordian-body
	.related-encounter-body-container
	.prmry-patient-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.encounter-accordian-body
	.related-encounter-body-container
	.prmry-encoutner-date {
	font: normal 14px/1.43 RionaSans-Regular;
}

.encounter-accordian-body
	.related-encounter-body-container
	.prmry-enctr-prvdr-name-container {
	display: flex;
	font: normal 500 14px/1.43 RionaSans-Medium;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body.editable
	.segment-row
	.top-margin {
	margin-top: 16px;
}

.encounter-body .encounter-sections .title-multi-visit {
	font: normal bold 24px/1.42 RionaSans-Bold;
	padding: 24px 20px 6px;
}

.encounter-body .encounter-sections .title-secondary-visit-wrapper {
	font: normal bold 18px/1.39 RionaSans-Bold;
	padding: 0 20px 24px;
}

.encounter-body .encounter-sections .title-secondary {
	font-family: RionaSans-RegularItalic;
}

/************************************************************************
                        start: payor dropdown  
*************************************************************************/
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.select-payor
	.el-select
	.el-select-dropdown
	.el-select-dropdown__item {
	overflow: unset;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.select-payor
	.el-scrollbar,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.select-payor
	.el-scrollbar
	.el-select-dropdown__wrap {
	overflow: unset !important;
	width: 100% !important;
	margin-right: 0 !important;
	margin-bottom: 0 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.select-payor
	.el-select-dropdown {
	left: 0 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.select-payor
	.el-scrollbar
	.el-scrollbar__bar.is-vertical,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.select-payor
	.el-scrollbar
	.el-scrollbar__bar.is-horizontal {
	display: none !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.select-payor
	.el-scrollbar
	.el-tooltip {
	display: inline-block;
	margin-left: 6px !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.select-payor
	.el-scrollbar
	.el-tooltip
	.el-tooltip__popper.is-dark {
	width: 207px !important;
	white-space: initial !important;
}
/******************************************************
                end: payor dropdown  
*******************************************************/
