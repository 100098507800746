.push-to-pioneer-error-dialog .appointment-scheduling-error-msg-title {
	font: normal 16px RionaSans;
	color: #262837;
	font-weight: 490;
	line-height: 120%;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	z-index: 0;
}

.push-to-pioneer-error-dialog .appointment-scheduling-error-msg-option-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
	width: 314px;
	height: 84px;
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
	z-index: 1;
}

.push-to-pioneer-error-dialog .appointment-scheduling-error-msg-option-container .header {
	width: 297px;
	height: 17px;
	padding-top: 10px;
	font-family: 'Riona Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	color: #262837;
	flex: none;
	order: 0;
	flex-grow: 0;
}


.appointment-scheduling-error-msg-option-container + .solid {
	padding-top: 25px;
  }

.push-to-pioneer-error-dialog .appointment-scheduling-error-msg-option-container .description {
	width: 297px;
	height: 34px;
	padding-top: 10px;
	padding-bottom: 30px;
	font-family: 'Riona Sans';
	font-style: normal;
	font-weight: 490;
	font-size: 14px;
	line-height: 120%;
	color: #525968;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.push-to-pioneer-error-dialog .appointment-scheduling-error-msg-option-container .button {
	width: 105px;
	height: 17px;
	margin-top: 5px;
	font-family: 'Riona Sans';
	font-style: normal;
	font-weight: 490;
	font-size: 14px;
	line-height: 120%;
	text-align: left;
	color: #5B43BD;
	flex: none;
	order: 1;
	flex-grow: 0;
}


.push-to-pioneer-error-dialog .el-dialog__header .el-dialog__title .closeIcon>div,
.push-to-pioneer-error-dialog .el-dialog__header .el-dialog__title .closeIcon>div>svg {
	height: 24px;
	width: 24px;
	position: absolute;
	right: 2px;
	top: 10%;
	transform: translateY(-50%);
}

.push-to-pioneer-error-dialog .el-dialog__header .el-dialog__title .closeIcon.small>div,
.push-to-pioneer-error-dialog .el-dialog__header .el-dialog__title .closeIcon.small>div>svg {
	top: 15%;
}