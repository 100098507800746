.empty-outreach-record-wrapper {
	display: flex;
	border: 1px solid #edeff3;
}

.empty-outreach-record-wrapper .log-empty-icon,
.empty-outreach-record-wrapper .no-reach-content {
	padding: 16px;
}

.empty-outreach-record-wrapper .no-reach-content .inner-outrch-content {
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #7b8291;
}
