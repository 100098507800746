* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

a {
	text-decoration: none;
}

.hidden {
	display: none;
}

.show {
	display: block !important;
}

img {
	vertical-align: middle;
}

.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
	display: table;
	content: ' ';
}

textarea {
	margin: 0;
	font: inherit;
	color: inherit;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: not-allowed;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	opacity: 1;
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	-webkit-transition: border-color ease-in-out 0.15s,
		-webkit-box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

button,
input,
optgroup,
select,
textarea {
	font: inherit;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
}

hr {
	border: 0;
	border-top: 1px solid #eee;
}

.invisible {
	visibility: hidden;
}

.text-right {
	text-align: right;
}

.float-left {
	float: left;
}
