.addressModalContainer {
	position: absolute;
	width: 368px;
	height: 486px;
	top: calc(50vh - 242px);
	left: calc(50vw - 184px);
	display: flex;
	flex-direction: column;
}

.addressModalPlainText {
	font-family: 'RionaSans-MediumItalic';
	font-size: 13px;
	color: #262837;
	margin-bottom: 16px;
}
