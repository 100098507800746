.pa-milestone-warning-container {
	min-width: 366px;
	width: 100%;
	min-height: 52px;
	border-radius: 4px;
	background-color: #faedac;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 19px;
	padding: 2px 8px 2px 12px;
}
.pa-milestone-warning-container .pa-milestone-warning-body {
	display: flex;
	align-items: center;
	justify-content: center;
}
.pa-milestone-warning-container
	.pa-milestone-warning-body
	.pa-milestone-warning-text {
	min-width: 316px;
	width: 100%;
	min-height: 36px;
	font-family: RionaSans-MediumItalic;
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	text-align: left;
	color: #4b3700;
	margin-left: 8px;
}
