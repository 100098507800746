.linked-encounter-wrapper .linked-encounter-label {
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	white-space: pre-wrap;
	word-break: break-word;
}
.linked-encounter-wrapper .linked-encounter-content {
	display: flex;
	align-items: center;
	font: normal 500 14px/1.43 RionaSans-Medium;
	text-transform: capitalize;
	color: #262837;
}

.linked-encounter-wrapper .linked-encounter-content.linked-encounter-link {
	font: normal 500 14px/1.43 RionaSans-Medium;
	color: #7b26c9;
	text-decoration: underline;
	text-transform: capitalize;
	cursor: pointer;
}

.linked-encounter-wrapper .linked-encounter-content .not-scheduled {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: left;
	color: #a0abb9;
	font-style: italic;
}

.linked-encounter-wrapper .linked-encounter-link img,
.linked-encounter-content .icon-lab {
	margin-left: 4px;
	width: 15px;
	height: 15px;
}
