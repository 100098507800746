.medicationsContainer {
	padding: 42px 22px 0px 22px;
	background-color: #edeff3;
	height: 100%;
}

.medicationsContainer > div.custom-table > table > tbody > tr {
	height: 136px !important;
}

.medicationsContainer > div.custom-table > table > tbody > tr > td:last-child {
	vertical-align: baseline;
}

.medicationsContainer
	> div.custom-table
	> table
	> tbody
	> tr
	> td:last-child
	> div {
	max-height: unset !important;
	max-width: calc(100% - 5px);
}
