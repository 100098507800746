.document-tab-section.new-version {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	margin-top: 32px;
}

.document-tab-section.new-version > .switch-to-old-version {
	border-radius: 4px;
	background-color: #edeff3;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	color: #262837;
	padding: 8px;
	font-style: italic;
}

.document-tab-section.new-version > .switch-to-old-version > span {
	cursor: pointer;
}

.document-tab-section.new-version > .switch-to-old-version > span:hover {
	text-decoration: underline;
}

.document-tab-section.new-version > .document-action-row {
	padding: unset;
	margin: 16px 0 0 0;
}

.document-tab-section.new-version
	> .document-action-row
	> .new-version-filters {
	display: flex;
}

.document-tab-section.new-version
	> .document-action-row
	> .new-version-filters
	> .full-screen {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	cursor: pointer;
}

.document-tab-section.new-version
	> .document-action-row
	> .new-version-filters
	> .full-screen
	* {
	width: 36px;
	height: 36px;
}

.document-tab-section.new-version
	> .document-action-row
	> .new-version-filters
	> .full-screen:hover {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
}

.document-tab-section.new-version
	> .document-action-row
	> .new-version-filters
	> .full-screen:active {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
}

.document-tab-section.new-version > .documentAffiliationDocsContainer {
	padding: unset;
	margin: 24px 0 0 0;
}

.document-tab-section.new-version > .document-list-section {
	margin: 32px 0 0 0;
	padding: unset;
}

.document-tab-section.new-version > .document-list-section .groups-list > div {
	margin-bottom: 32px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.header
	label {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.38;
	color: #262837;
	margin: unset;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.header
	span {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	text-align: right;
	color: #7b26c9;
	cursor: pointer;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.header
	span:hover {
	text-decoration: underline;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container {
	padding: 13px 8px 12px 16px;
	border-radius: 4px;
	border: solid 1px #d8dde4;
	margin-bottom: 4px;
	display: flex;
	align-items: center;
}

.document-tab-section.new-version
  .document-list-section
  .groups-list 
  .document-list
  .document-row-container
  .document-row-noncheckbox-section {
	display: flex;
	align-items: center;
}

.document-row-container .chargeICDSearchResultRowCheckbox {
	margin: 0 16px 0 0;
	top: 0;
}

.document-row-loader {
	padding: 13px 8px 12px 16px;
	margin-bottom: 4px;
	display: flex;
	align-items: center;
	z-index: 5 !important;
}

.document-row-container label.el-checkbox.chargeICDSearchResultRowCheckbox span.el-checkbox__input.is-disabled span.el-checkbox__inner:hover {
	border-color: #bfcbd9 !important;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	> div:not(.is-malicious)
	.document-row-container:hover {
	cursor: pointer;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .document-icon {
	margin-right: 12px;
}

.document-tab-section.new-version >
	.document-list-section .groups-list
	.document-list .document-row-container
	.document-row-noncheckbox-section >
	.document-icon .malicious-icon {
	width: 28px;
	height: 28px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content {
	width: 655px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .display-content
	> .italic {
	font-style: italic;
}

.document-tab-section.new-version
	.italic
	.GMITooltip.el-tooltip
	.el-tooltip__popper.is-dark {
	font-style: italic !important;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	.file-name {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	color: #262837;
	margin-bottom: 2px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	.file-name.is-archived {
	color: #e74c3c;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	.patient-info {
	display: flex;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	.patient-info
	span {
	text-transform: capitalize;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	.patient-info
	> div {
	display: flex;
	margin-right: 18px;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	.patient-info
	> div
	.el-tooltip__rel
	> div {
	display: flex;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__rel
	> div
	> div {
	margin-right: 4px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__popper {
	min-width: 160px;
	padding: 12px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__popper
	> div {
	display: flex;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__popper
	> div
	> .separator {
	width: 1px;
	background-color: #7b8291;
	margin: 0 8px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	.description {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	> .doc-status {
	font-family: RionaSans-Bold;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.38;
	height: 24px;
	padding: 3px 12px;
	border-radius: 24px;
	text-transform: capitalize;
	display: flex;
	align-items: flex-end;
	margin-right: 12px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	> .doc-status.yellow {
	color: #4b3700;
	background-color: #fde776;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	> .doc-status.green {
	color: #004e09;
	background-color: #a2ffad;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .display-content
	> .doc-status.orange {
	color: #502500;
	background-color: #ffcb9f;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .three-dots-wrapper,
.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .three-dots-wrapper
	.three-dots-icon
	> *,
.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .three-dots-wrapper
	svg {
	width: 32px;
	height: 32px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.document-row-noncheckbox-section
	> .three-dots-wrapper
	.popovr-segment
	img {
	width: 18px;
	height: 18px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.popovr-segment
	.without-subtype-disable {
	opacity: 0.5;
	float: left;
	width: 90%;
	cursor: no-drop;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.popovr-segment
	.required-subtype-tooltip {
	float: right;
	width: 10%;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.popovr-segment
	.required-subtype-tooltip
	.el-tooltip__popper.is-light {
	width: 250px !important;
	border: none;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	.document-row-container
	.el-loading-spinner {
	height: 100%;
	top: 21px;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	> div.is-malicious
	.document-row-container
	> .document-icon
	> img {
	width: 24px;
}

.document-tab-section.new-version
	> .document-list-section
	.groups-list
	.document-list
	> div.is-malicious
	.document-row-container {
	border: solid 1px red;
}
