/* stylelint-disable selector-class-pattern */
.view-nucleotide-repeat-variant-info {
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: #ffffff;
	padding: 16px;
}

.view-nucleotide-repeat-variant-info .nucleotide-repeat-variant-title {
	border-bottom: 1px solid #edeff3;
	margin-bottom: 16px;
	padding-bottom: 14px;
	font: normal bold 20px RionaSans-Bold, sans-serif;
	color: #262837;
}

.view-nucleotide-repeat-variant-info .tooltip-info {
	margin-left: 3px;
}

.view-nucleotide-repeat-variant-info .tooltip-info .more-info-icon {
	width: 14px;
	height: 14px;
	margin-top: -2px;
}

.view-nucleotide-repeat-variant-info .pp-nucleotide-repeat-variant,
.view-nucleotide-repeat-variant-info .pp-latest-interpretation {
	background-color: #000000;
	color: #ffffff;
	border-radius: 8px;
}

.view-nucleotide-repeat-variant-info .pp-latest-interpretation {
	min-width: auto !important;
	width: auto !important;
}

.view-nucleotide-repeat-variant-info .el-popover.pp-nucleotide-repeat-variant[x-placement^="top"] .popper__arrow::after,
.view-nucleotide-repeat-variant-info .el-popover.pp-latest-interpretation[x-placement^="top"] .popper__arrow::after {
	border-top-color: #000000;
}

.view-nucleotide-repeat-variant-info .el-popover.pp-nucleotide-repeat-variant[x-placement^="bottom"] .popper__arrow::after,
.view-nucleotide-repeat-variant-info .el-popover.pp-latest-interpretation[x-placement^="bottom"] .popper__arrow::after {
	border-bottom-color: #000000;
}

.view-nucleotide-repeat-variant-info .pp-nucleotide-repeat-variant {
	width: 250px !important;
}

.view-nucleotide-repeat-variant-info .nucleotide-repeat-variant-result-section {
	border-right: 1px solid #edeff3;
	padding-right: 0;
}

.view-nucleotide-repeat-variant-info .result-details-label,
.view-nucleotide-repeat-variant-info .clinical-comments-label {
	font: normal 500 18px RionaSans-Medium, sans-serif;
	color: #262837;
}

.view-nucleotide-repeat-variant-info .result-details-label .link-dbsnp {
	font: normal 500 14px RionaSans-Medium, sans-serif;
	color: #40b9ec;
}

.view-nucleotide-repeat-variant-info .result-details-label .link-dbsnp:hover {
	text-decoration: underline;
}

.view-nucleotide-repeat-variant-info .nucleotide-repeat-variant-title .gmi-pill-wrapper {
	display: inline-block;
}

.view-nucleotide-repeat-variant-info .not-detected-pill {
	margin-left: 16px;
	background-color: #f4a4a5;
	color: #400404;
}

.view-nucleotide-repeat-variant-info .el-row {
	margin-bottom: 24px;
}

.view-nucleotide-repeat-variant-info .el-row:last-child {
	margin-bottom: 0;
}

.view-nucleotide-repeat-variant-info .view-all-clinical-comments {
	font: normal 500 14px RionaSans-Medium, sans-serif;
	color: #7b26c9;
}

.view-nucleotide-repeat-variant-info label {
	color: #525968;
	font: normal 12px RionaSans, sans-serif;
	margin-bottom: 0;
}

.view-nucleotide-repeat-variant-info .field-val {
	font: normal bold 14px RionaSans-Bold, sans-serif;
	color: #262838;
	line-height: normal;
	width: 100%;
	display: inline-block;
}

.view-nucleotide-repeat-variant-info .lab-comment {
	white-space: pre;
}

.view-nucleotide-repeat-variant-info .margin-bottom-19 {
	margin-bottom: 19px;
}

.view-nucleotide-repeat-variant-info .nucleotide-repeat-variant-result-section .nucleotide-repeat-variant-view-full-details {
	color: #7b26c9;
	font: normal 500 14px/20px RionaSans-Medium, sans-serif;
}

.view-nucleotide-repeat-variant-info .nucleotide-repeat-variant-result-section .nucleotide-repeat-variant-view-full-details:hover {
	text-decoration: underline;
}

.view-nucleotide-repeat-variant-info .clinical-comments-container {
	position: relative;
}

.view-nucleotide-repeat-variant-info .edit-detail-button {
	padding: 3px 12px;
	border: 1px solid #7b26c9;
	border-radius: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #7b26c9;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	text-align: center;
	cursor: pointer;
}

.view-nucleotide-repeat-variant-info .edit-detail-button:hover {
	background-color: rgb(123 38 201 / 15%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.view-nucleotide-repeat-variant-info .edit-detail-button:active {
	background-color: rgb(123 38 201 / 30%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.view-nucleotide-variant-info .edit-detail-button:focus {
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.view-nucleotide-repeat-variant-info svg .GMI-edit-icon {
	fill: #7b26c9;
}

.view-nucleotide-repeat-variant-info .edit-detail-icon {
	padding-right: 7px;
	height: 28px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}
