.checkbox-button-wrapper {
	-webkit-font-smoothing: antialiased;
}
.checkbox-button-wrapper .el-checkbox-group {
	display: flex;
	flex-direction: column;
}
.checkbox-button-wrapper .el-checkbox-group .el-checkbox:not(:last-child) {
	margin-bottom: 4px;
}
.checkbox-button-wrapper .el-checkbox-group .el-checkbox {
	margin: 0px;
	min-width: 336px;
	min-height: 42px;
	padding: 12px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	display: flex;
	justify-content: left;
	align-items: center;
}
.checkbox-button-wrapper .el-checkbox-group .el-checkbox:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
	border: solid 1px #edeff3;
}
.checkbox-button-wrapper .el-checkbox-group .el-checkbox:active,
.checkbox-button-wrapper .el-checkbox-group .el-checkbox:focus {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
	border: solid 1px #edeff3;
}
.checkbox-button-wrapper .el-checkbox-group .el-checkbox.el-checked {
	border: solid 1px #7b26c9;
}
.checkbox-button-wrapper
	.el-checkbox-group
	.el-checkbox
	.el-checkbox__input.is-checked
	.el-checkbox__inner {
	background-color: #7b26c9 !important;
	border-color: #7b26c9 !important;
}
.checkbox-button-wrapper
	.el-checkbox-group
	.el-checkbox
	.el-checkbox__input.is-checked
	.el-checkbox__inner::after {
	border: 2.1px solid #ffffff !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 8px !important;
	left: 4px !important;
	top: 1px !important;
	margin: -1px;
}
.checkbox-button-wrapper .el-checkbox-group .el-checkbox .el-checkbox__label {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #262837;
	margin-left: 12px;
	white-space: break-spaces;
	width: 100%;
}

.checkbox-button-wrapper
	.el-checkbox-group
	.el-checkbox
	.el-checkbox__label
	> .checkbox-button-main-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
