.provider-unlink-dialog {
	width: 368px !important;
	min-height: 216px;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}

.provider-unlink-dialog .el-dialog__header {
	height: 56px;
	border-bottom: 1px solid #edeff3;
	padding: 17px 16px;
}

.provider-unlink-dialog .el-dialog__title > span {
	display: flex;
	justify-content: left;
	align-items: center;
}

.provider-unlink-dialog .el-dialog__title > span .unlink-title {
	font: bold 16px/1.38 RionaSans-Bold;
	color: #262837;
	margin-left: 16px;
}

.provider-unlink-dialog .el-dialog__body {
	padding: 32px 16px 32px 16px;
	font: normal 500 13px/1.38 RionaSans-Medium;
	text-align: left;
	color: #262837;
}

.provider-unlink-dialog .el-dialog__footer {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
	border-top: 1px solid #edeff3;
}

.provider-unlink-dialog .el-dialog__footer .cancel-button {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
}

.provider-unlink-dialog .el-dialog__footer .cancel-button:hover,
.provider-unlink-dialog .el-dialog__footer .cancel-button:focus {
	background-color: rgba(123, 38, 201, 0.15);
}
.provider-unlink-dialog .el-dialog__footer .cancel-button:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.provider-unlink-dialog .el-dialog__footer .cancel-button > span {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
}

.provider-unlink-dialog .el-dialog__footer .confirm-button {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
	background-color: #7b26c9;
}
.provider-unlink-dialog .el-dialog__footer .confirm-button:hover,
.provider-unlink-dialog .el-dialog__footer .confirm-button:focus {
	background-color: #9c5dd7;
}
.provider-unlink-dialog .el-dialog__footer .confirm-button:active {
	background-color: #7b26c9;
}

.provider-unlink-dialog .el-dialog__footer .confirm-button > span {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #ffffff;
}
