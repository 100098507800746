.select-patient {
	.el-select {
		width: 100%;

		.el-input__icon {
			transition: none;
			&.el-icon-caret-top {
				transform: translateY(-40%);
				&::before {
					content: url('../../../../../assets/search.svg');
				}
				&.is-reverse {
					transform: translateY(-40%);
				}
			}
		}
	}
	.el-select-dropdown__item {
		height: unset;
	}
	.patient-item {
		em {
			font-family: RionaSans-Bold;
		}
	}
}
