.outreach-record-wrapper {
	display: flex;
	justify-content: space-between;
	border: 1px solid #edeff3;
}

.outreach-record-wrapper.css-cursor-pointer {
	cursor: pointer;
}

.outreach-record-wrapper .outrch-icon {
	display: flex;
	justify-content: center;
	align-self: flex-start;
	width: 64px;
	padding: 16px 0 0 0;
}

.outreach-record-wrapper .outrch-content {
	width: calc(100% - 64px);
	padding: 16px 16px 14px 0;
}

.outreach-record-wrapper .outrch-content .inner-outrch-content {
	display: flex;
}

.outreach-sms-body {
	font: normal 14px/1.42 RionaSans !important;
	line-height: 125%;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(0, 0, 0, 0.8) !important;
  }

.outreach-record-wrapper .inner-outrch-content .outreach-date,
.outreach-record-wrapper .inner-outrch-content .ror-group-text,
.outreach-record-wrapper .inner-outrch-content .outrch-created-by {
	font: normal 12px/1.42 RionaSans;
	color: #7b8291;
}

.outreach-record-wrapper .inner-outrch-content .ror-group-text,
.outreach-record-wrapper .inner-outrch-content .outrch-created-by {
	margin-left: auto;
}

.outreach-record-wrapper .inner-outrch-content .outrch-email-subject {
	font: normal 14px/1.43 RionaSans-Bold;
	color: #262837;
}

.outcome {
	font: normal 500 12px/1.42 RionaSans-MediumItalic;
	color: #7b8291;
}

.outcomeText {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #fc4748;
}

/******************************************************************************
                referral outreach enable and disabled section
*******************************************************************************/
.referral-outreach-disabled,
.referral-outreach-enabled {
	display: flex;
	width: 100%;
	border: 1px solid #ffdce0;
	color: #86181d;
}

.referral-outreach-disabled {
	background-color: #ffdce0;
}

.referral-outreach-enabled {
	background-color: #a2ffad;
}

.referral-outreach-disabled .inner-outrch-content .outreach-date,
.referral-outreach-disabled .inner-outrch-content .ror-group-text,
.referral-outreach-disabled .inner-outrch-content .outrch-created-by,
.referral-outreach-disabled .inner-outrch-content .outrch-email-subject,
.referral-outreach-disabled .outcome {
	color: #400404;
}

.referral-outreach-enabled .inner-outrch-content .outreach-date,
.referral-outreach-enabled .inner-outrch-content .ror-group-text,
.referral-outreach-enabled .inner-outrch-content .outrch-created-by,
.referral-outreach-enabled .outcome {
	color: #004e09;
}

/* When Do Not Outreach is enabled, then add the space at the bottom of the first do not outreach card */
.do-not-outreach-enabled {
	margin-bottom: 16px;
}

.outreach-record-wrapper .outrch-icon .icon-disabled-do-not-outreach svg>path {
	fill: #004e09 !important;
}

.outreach-record-wrapper .outrch-icon .icon-do-not-outreach svg>g>path {
	fill: #400404 !important;
}

.outreach-record-wrapper .outrch-icon .icon-disabled-do-not-outreach {
	transform: scale(1.2);
	padding-top: 6px;
	padding-left: 4px;
}

.outreach-record-wrapper .outrch-icon .icon-do-not-outreach {
	transform: scale(1.2);
}