@use '../../Common/V3/system';

.patient-tag-indicator {
	display: inline-block;
	margin-left: system.$quarterUnit;
	
	&.with-text {
		border-radius: 16px;
		padding: 3px 12px;
	}
}
