.list-container .list-header {
	float: right;
}

.test-doctable thead th {
	padding: 0 20px 0 0px;
	border-bottom: none;
}

.test-doctable tbody tr td {
	border-bottom: none;
}

.test-doctable tbody tr td:last-child {
	padding: 0 20px 0 0;
}

.test-doctable tbody tr td .provider {
	display: flex;
	width: 160px;
}

.test-doctable .testcell-date {
	width: 120px;
}

.tests-container .list-container {
	/* padding-top: 28px !important; */
	padding: 20px 22px 0;
	background-color: #edeff3;
	min-height: 100vh;
}

.list-container .edit-result {
	width: 123px;
	height: 32px;
	border-radius: 16px;
	background-color: #00bbf1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-container .edit-result:hover {
	background-color: #70cbf1;
}

.list-container .edit-result:active {
	background-color: #40b9ec;
}

.list-container .edit-result-icon {
	margin-right: 5px;
}

.list-container .edit-result-icon img {
	width: 22px;
	height: 22px;
}

.list-container .edit-result-text {
	min-width: 65px;
	height: 15px;
	font-family: RionaSans-Medium;
	font-size: 11px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	float: left;
	margin-top: 9px;
	margin-bottom: 8px;
	margin-right: 5px;
}

.list-container .order-status-label {
	width: 134px;
	height: 28px;
	border-radius: 4px;
	text-align: center;
}

.list-container .order-status-text {
	width: 114px;
	min-height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	padding: 5px;
}

.list-container .order-status-red {
	border: solid 1px #fe9189;
	background-color: rgba(254, 145, 137, 0.2);
}

.list-container .order-status-red .order-status-text {
	color: #fa392e;
}

.list-container .order-status-gray {
	border: solid 1px #d7dde5;
	background-color: rgba(237, 239, 243, 0.5);
}

.list-container .order-status-gray .order-status-text {
	color: #9eabbb;
}

.list-container .order-status-blue {
	border: solid 1px #8ddff8;
	background-color: rgba(141, 223, 248, 0.2);
}

.list-container .order-status-blue .order-status-text {
	color: #00bbf1;
}

.list-container .order-status-yellow {
	border: solid 1px #ffe85f;
	background-color: rgba(255, 232, 95, 0.2);
}

.list-container .order-status-yellow .order-status-text {
	color: #ffc900;
}

.list-container .order-status-green {
	border: solid 1px #7af0b6;
	background-color: rgba(122, 240, 182, 0.15);
}

.list-container .order-status-green .order-status-text {
	color: #00db44;
}

.list-container img.provider-image {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: solid 1px #d4dfe7;
	background-color: #d8d8d8;
	float: left;
}

.list-container .provider-name {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	margin-left: 8px;
}

.list-container .test-name {
	width: 192px;
	height: 20px;
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

.list-container .lab-name {
	width: 192px;
	height: 20px;
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

.list-container .dispatch-date {
	width: 95px;
	height: 20px;
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

/*headers starts*/

.list-container .header-dispatch-date {
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	margin-bottom: 8px;
	min-width: 112px;
	width: calc(calc(calc(100vw - calc(340px + 64px + 8px)) / 100) * 10);
	/* Must be updated if patient info width or margins change */
}

.list-container .header-lab-name {
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	margin-bottom: 8px;
	/* width: 192px; */
	min-width: 135px;
	width: calc(calc(calc(100vw - calc(340px + 64px + 8px)) / 100) * 12);
	/* Must be updated if patient info width or margins change */
}

.list-container .header-test-name {
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	margin-bottom: 8px;
	/* width: 192px; */
	min-width: 145px;
	width: calc(calc(calc(100vw - calc(340px + 64px + 8px)) / 100) * 21);
	/* Must be updated if patient info width or margins change */
}

.list-container .header-provider-name {
	/* min-width: 52px; */
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	margin-bottom: 8px;
	/* width: 120px; */
	min-width: 120px;
	width: calc(calc(calc(100vw - calc(340px + 64px + 8px)) / 100) * 12);
	/* Must be updated if patient info width or margins change */
}

.list-container .header-order-status {
	/* min-width: 76px; */
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	margin-bottom: 8px;
	/* width: 134px; */
	min-width: 93px;
	width: calc(calc(calc(100vw - calc(340px + 64px + 8px)) / 100) * 9);
	/* Must be updated if patient info width or margins change */
}

.list-container .header-blank {
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	margin-bottom: 8px;
	min-width: 124px;
	width: calc(calc(calc(100vw - calc(340px + 64px + 8px)) / 100) * 12);
	/* Must be updated if patient info width or margins change */
}

/*headers ends starts*/

.list-container .sortableLabelpatientrecords:hover {
	color: #262837 !important;
}

.list-container .cellText {
	padding-left: 0;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 20px;
	display: flex;
	align-items: center;
	height: 48px;
}

.list-container .ellipsed {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.custom-table .test-doctable tbody tr.highlight {
	background-color: transparent;
}
.custom-table .test-doctable tbody tr.highlight:hover {
	background-color: #ecf8fd;
}

.test-doctable tbody tr:focus,
.test-doctable tbody tr:active {
	background-color: #d9f1fb;
}

.list-container .pat-dash {
	float: left;
}

.list-container .font-bold > div {
	font-weight: bold;
	font-family: RionaSans-Bold;
}

.tests-list-loader {
	position: relative;
	top: calc(50vh - 80px);
}

.list-container .test-doctable tbody .no-results {
	pointer-events: none;
}
