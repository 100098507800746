/* stylelint-disable selector-class-pattern */
.tests-container .add-gene-button-wrapper {
	display: flex;
}

.tests-container .add-new-cfDNA-cancer {
	border-radius: 7px;
	background-color: #7b26c9;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 24px 0 24px 24px;
	padding: 9px 12px;
}

.tests-container .add-new-cfDNA-cancer .add-new-cfDNA-cancer-text {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #ffffff;
}

.cfdna-cancer-associated-container {
	width: 368px;
	border-radius: 4px;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 35%);
	background-color: white;
	position: absolute;
	left: calc(50% - 150px);
	top: 175px;
	-webkit-font-smoothing: antialiased;
}

.cfdna-cancer-associated-container .modal-top-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 9px 16px;
	height: 56px;
	border-bottom: 1.5px solid #edeff3a2;
	align-items: center;
}

.cfdna-cancer-associated-container .modal-header {
	display: flex;
	flex-flow: column;
	font: normal bold 16px/1.38 RionaSans-Bold, sans-serif;
	color: #262837;
	padding: 0;
	border-bottom: transparent;
}

.cfdna-cancer-associated-container .modal-header .lab-test-name {
	font-size: 13px;
}

.cfdna-cancer-associated-container .modal-body {
	padding: 24px 16px 5px;
	overflow-y: overlay;
	font: normal 12px/1.42 RionaSans, sans-serif;
}

.cfdna-cancer-associated-container .modal-body .required::before {
	color: #e74c3c;
	content: '*';
	font: normal bold 12px/1.42 RionaSans, sans-serif;
	margin: 0 2px 0 -2px;
}

.cfdna-cancer-associated-container .modal-footer {
	width: 100%;
	height: 60px;
	border-top: 1px solid #edeff3a2;
	display: flex;
	justify-content: space-between;
	padding: 12px 16px;
	font-size: 13px;
	color: white;
	background-color: #ffffff;
	position: relative;
	border-radius: 0 0 4px 4px;
}

.cfdna-cancer-associated-container .cancel-button {
	width: 160px;
	height: 36px;
	border-radius: 7px;
	background-color: white;
	color: #7b26c9;
	text-align: center;
	border: 1px solid #7b26c9;
	padding-top: 9px;
	cursor: pointer;
}

.cfdna-cancer-associated-container .cancel-button:hover {
	background-color: rgb(123 38 201 / 15%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.cfdna-cancer-associated-container .cancel-button:active {
	background-color: rgb(123 38 201 / 30%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.cfdna-cancer-associated-container .save-button {
	padding: 9px 12px;
	width: 160px;
	height: 36px;
	border: 1px solid #7b26c9;
	background-color: #7b26c9;
	text-align: center;
	color: #ffffff;
	border-radius: 7px;
}

.cfdna-cancer-associated-container .save-button:hover {
	background-color: #9c5dd7;
	color: #ffffff !important;
	border: 1px solid #7b26c9;
}

.cfdna-cancer-associated-container .save-button:focus {
	background-color: #7b26c9;
	color: #ffffff !important;
	border: 1px solid #7b26c9;
}

.cfdna-cancer-associated-container .save-button:visited {
	background-color: #621ea0;
	color: #ffffff !important;
	border: 1px solid #7b26c9;
}

.cfdna-cancer-associated-container .gene-result-delete-icon {
	height: 28px;
	width: 28px;
}

.cfdna-cancer-associated-container .cfDNA-delete-finding {
	display: flex;
	align-items: center;
	font: normal 500 13px/18px RionaSans-Medium, sans-serif;
	cursor: pointer;
	color: #e74c3c;
	width: 133px;
}

.cfdna-cancer-associated-container .delete-button:hover,
.cfdna-cancer-associated-container .delete-button:focus {
	color: #ffffff;
}

.cfdna-cancer-associated-container .label-cancer-signal-detected {
	font: normal 500 0.88rem/22px RionaSans-Medium, sans-serif;
}

.cfdna-cancer-associated-container .button-cancer-signal-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.cfdna-cancer-associated-container .button-cancer-signal {
	height: 32px;
	border-radius: 32px;
	text-align: center;
	margin-left: 12px;
	padding: 7px 15px;
	font: normal 500 13px/18px RionaSans-Medium, sans-serif;
	user-select: none;
	cursor: pointer;
}

.cfdna-cancer-associated-container .button-cancer-signal-selected {
	border: 1px solid #e2d3f2;
	background-color: #e2d3f2;
	color: #491678;
}

.cfdna-cancer-associated-container .button-cancer-signal-unselected {
	border: 1px solid #edeff3;
	background-color: white;
	color: #262837;
}

.cfdna-cancer-associated-container .modal-body .el-select-dropdown__wrap {
	max-height: 208px;
}
