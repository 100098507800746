.gmi-radio-group .el-radio {
	display: flex;
}

.gmi-radio-group .el-radio + .el-radio {
	margin: 0;
}

.gmi-radio-group .el-radio .el-radio__label {
	-webkit-font-smoothing: antialiased;
	font: normal 13px/1.43 RionaSans;
	color: #262837;
	display: flex;
	align-items: center;
}

.gmi-radio-group .el-radio__input {
	display: flex;
	justify-content: center;
	align-items: center;
}

.gmi-radio-group .el-radio__input .el-radio__inner {
	border: 1px solid #a0abb9;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	cursor: pointer;
	box-sizing: border-box;
}

.gmi-radio-group .el-radio__input.is-checked .el-radio__inner {
	border-color: #7b26c9;
	background: #ffffff;
}

.gmi-radio-group .el-radio__input .el-radio__inner:hover {
	border: 1px solid #7b26c9;
}

.gmi-radio-group .el-radio__input.is-checked .el-radio__inner::after {
	border: 1px solid #bd92e4;
	background-color: #7b26c9;
	height: 8px;
	width: 8px;
}
