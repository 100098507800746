.affiliation_menu.reports {
	color: #777 !important;
	font-size: 0.7vw !important;
}

.affiliation_selector.provider > div {
	font-size: 0.9vw !important;
	color: #0090ff !important;
}

.report_type_selector_group {
	font-family: 'RionaSans', serif !important;
}

.report_loader > div {
	background-color: rgba(255, 255, 255, 0.7) !important;
}

.self_service_stepper_label,
.self_service_stepper_label > span {
	font-weight: bold !important;
	font-size: 0.9vw;
	-webkit-font-smoothing: antialiased;
	color: #dcd6d6;
}

.css-1pcexqc-container.provider_selection_affiliation_reports {
	font-size: 1vw !important;
}

.self_service_stepper_label > span > svg > path {
	color: #6b6868;
}

.self_service_stepper_label_active > span > span {
	font-weight: bold !important;
	font-size: 0.9vw;
	-webkit-font-smoothing: antialiased;
	color: #6b6868 !important;
}

.self_service_stepper_label > span > span {
	font-size: 0.7vw;
	-webkit-font-smoothing: antialiased;
	color: #6b6868 !important;
}

.self_service_stepper_label_active > span > span > span {
	font-size: 0.9vw;
}

.self_service_stepper_label > span > span > span {
	font-size: 0.7vw;
}

.self_service_stepper_label > span > svg {
	font-size: 1.5vw;
	color: transparent !important;
	border: 1px solid #dcd6d6 !important;
	border-radius: 50px;
	width: 1.5vw;
	height: 1.5vw;
}

.self_service_stepper_label_active > span > svg {
	font-size: 1.5vw;
	color: transparent !important;
	border: 1px solid #6b6868 !important;
	border-radius: 50px;
	width: 1.5vw;
	height: 1.5vw;
}

.customized_type_of_report {
	width: 120px;
	height: 22px;
	font-family: RionaSans;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #464646;
}

.check_labels {
	width: 59px;
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
}

.check_sub_labels {
	width: 521px;
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #a6a6a6;
}

.report_subtypes_selection_group {
	display: grid;
	grid-template-columns: auto;
}

.report_type_checkbox_single {
	display: flex !important;
	margin-left: 15.2px !important;
	margin-top: 12px;
}

.report_type_checkbox_single.report_notes
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	z-index: unset !important;
}

.select_date_range_report {
	height: 22px;
	font: normal 500 14px/1.43 RionaSans-Medium;
	color: #262837;
	margin-bottom: 4px;
}

.self_service_stepper_label > span > svg > text {
	font-size: 0.7vw !important;
	fill: #dcd6d6;
}

.self_service_stepper_label_active > span > svg > text {
	font-size: 0.9vw !important;
	fill: #6b6868;
}

.el-step__head.is-text {
	border-width: 1px !important;
}

.el-step__head.is-finish {
	color: #dcd6d6;
	font-size: 1.5vw !important;
	-webkit-font-smoothing: antialiased;
}

.el-step__head.is-text.is-finish {
	color: rgba(70, 70, 70, 0.5) !important;
	background-color: transparent !important;
	border-color: rgb(70, 70, 70, 0.5) !important;
}

.el-step__head.is-text.is-process {
	background-color: transparent !important;
}

.el-step__title.is-finish {
	color: rgb(70, 70, 70, 0.5) !important;
}

.el-step__head.is-process,
.el-step__head.is-process > span > div {
	color: rgb(70, 70, 70) !important;
	font-weight: 700 !important;
	-webkit-font-smoothing: antialiased;
	font-size: 14px !important;
}

.el-step__title {
	height: 20px;
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 14px !important;
	font-weight: bold !important;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #464646 !important;
}

.el-step__head {
	font-size: 1.4vw;
}

.el-step__title.is-process {
	font-weight: 700 !important;
	-webkit-font-smoothing: antialiased;
	opacity: 1;
}

.left_panel_self_service {
	box-shadow: inset -2px 0px 12px -12px #000000;
	width: 270px;
	min-height: 674px;
}

.el-step__icon > * {
	font-size: 0.8vw !important;
}

.self_service_stepper_wrapper {
	margin-left: 29px;
	margin-top: 116px;
}

.report_type_radio_selection {
	color: #dcd6d6 !important;
}

.report_type_radio_selection.active {
	color: #40b9ec !important;
}

.patients_dashboard.self_service_report {
	min-height: 674px;
	/* width: 1364px; */
	box-shadow: 0 2px 4px 0 #0000002a;
	border: solid 1px #a3b3cda2;
	background-color: #ffffff;
	margin-top: 68px;
	margin-left: 728px;
	left: -648px;
	position: absolute;
}

.report_type_selection-wrapper {
	display: flex;
	flex-direction: column;
	width: 1142px;
}

.report_type_selection-wrapper .report_type_selection {
	margin-top: 116px;
	margin-left: 88px;
}

.report_type_selection-wrapper .provider_selection_affiliation_reports {
	width: 290px;
}

.report_type_selection-wrapper
	.provider_selection_affiliation_reports:not(.show-all-pills)
	.el-select__tags
	.el-tag:nth-child(n + 3) {
	display: none;
}

.report_type_selection-wrapper
	.provider_selection_affiliation_reports.all-selected
	.el-select__tags
	.el-tag:first-child {
	display: none !important;
}

.report_type_selection-wrapper
	.provider_selection_affiliation_reports.show-all-pills
	.el-select__tags {
	max-height: 100px;
	overflow-y: auto;
	overflow-x: hidden;
}

.report_type_selection-wrapper .button-wrapper {
	margin: 31px 0 43px 88px;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.report_type_selection-wrapper .button-wrapper .report_selection_buttons {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9 !important;
	font-weight: 500;
	font-size: 13px !important;
	line-height: 1.38 !important;
	font-family: RionaSans-Medium !important;
	text-align: center;
	color: #ffffff !important;
	border: none;
}

.report_type_selection-wrapper .button-wrapper .report_selection_buttons:hover {
	background-color: #9c5dd7 !important;
	color: #ffffff !important;
	font-weight: 500;
	font-size: 13px !important;
	line-height: 1.38 !important;
	font-family: RionaSans-Medium !important;
	text-align: center;
	border: none;
}

.report_type_selection-wrapper
	.button-wrapper
	.report_selection_buttons:active {
	background-color: #621ea0 !important;
	color: #ffffff !important;
	font-weight: 500;
	font-size: 13px !important;
	line-height: 1.38 !important;
	font-family: RionaSans-Medium !important;
	text-align: center;
	border: none;
}

.report_type_selection-wrapper .button-wrapper .report_selection_button_back {
	color: #7b26c9 !important;
	font-weight: 500;
	font-size: 13px !important;
	line-height: 1.38 !important;
	font-family: RionaSans-Medium !important;
	margin-left: 16px;
	width: 28px;
}

.report_type_selection-wrapper
	.button-wrapper
	.report_selection_button_back:hover {
	color: #9c5dd7 !important;
}

.report_type_selection-wrapper
	.button-wrapper
	.report_selection_button_back:active {
	color: #621ea0 !important;
}

.report_type_selector_wrapper {
	margin-top: 2vw;
}

.report_type_selector_wrapper
	.gmi-radio-group
	.el-radio__input
	.el-radio__inner {
	width: 20px;
	height: 20px;
	border: 2px solid #7b26c9;
}

.report_type_selector_wrapper
	.gmi-radio-group
	.el-radio__input.is-checked
	.el-radio__inner::after {
	width: 11px;
	height: 11px;
}

.report_type_selector_wrapper
	.gmi-radio-group
	.el-radio__input
	.el-radio__inner:hover {
	border: 2px solid #7b26c9;
}

.report_type_selector_wrapper .report_types_desc_labels {
	white-space: initial;
}

.report_type_selector_wrapper .gmi-radio-group .el-radio .el-radio__label {
	margin-left: 10px;
}

.report_type_selector_wrapper .gmi-radio-group label.el-radio {
	padding-bottom: 24px;
}

.select_report_label {
	-webkit-font-smoothing: antialiased;
	width: 418px;
	height: 28px;
	font-family: RionaSans;
	font-size: 20px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgb(70, 70, 70);
}

.report_types_labels {
	height: 22px;
	font-family: RionaSans;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #292b3a;
}

.report_types_desc_labels {
	width: 548px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #a6a6a6;
	margin-top: 0.2vw;
}

.report_selection_buttons {
	background-color: #40b9ec !important;
	width: 7vw;
	font-size: 0.8vw !important;
	font-family: 'RionaSans', serif !important;
}

.report_selection_buttons:hover {
	background-color: #70cbf1 !important;
}

.report_selection_buttons:active {
	background-color: #0f80b0 !important;
}

.report_selection_buttons.disabled {
	background-color: rgba(0, 0, 0, 0.12) !important;
}

.report_selection_button_back {
	width: 7vw;
	font-size: 0.8vw;
	font-size: 0.8vw !important;
	font-family: 'RionaSans', serif !important;
}

.report_label_headers {
	width: 136px;
	height: 22px;
	font-family: RionaSans;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #464646;
}

.report_type_main {
	font-family: RionaSans-Bold;
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #464646;
	-webkit-font-smoothing: antialiased;
}

.report_type_subtype {
	height: 20px;
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #464646;
	margin-top: 1px;
}

.report_label_values {
	margin-left: 22px;
}
