.invite-patient-to-schedule {
	.dropdown-input-outreach {
		width: 320px;
		margin-bottom: 8px;
	}

	.forms {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		width: 100%;
		height: auto;
	}

	.subsection {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		gap: 10px;
		width: 100%;
		height: 20px;

		.label {
			font-family: RionaSans-Medium;
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 20px;
			color: #000000;
		}

		.divider {
			flex-grow: 1;
			height: 0px;
			border: 1px solid #d8dde4;
		}
	}

	.doc-text {
		width: 313px;
		height: 17px;
		font-family: RionaSans;
		font-size: 12px;
		line-height: 17px;
		color: #525968;
		flex: none;
		order: 1;
		flex-grow: 0;
	}
	.alert-box-modal {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 8px 16px;
		margin-bottom: 16px;
		gap: 8px;
		width: 100%;
		background: #faedac;
		border-radius: 4px;

		.text {
			font-family: RionaSans-MediumItalic;
			font-size: 13px;
			line-height: 18px;
			color: #4b3700;
		}
	}

	.btn-container-modal {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 12px 16px;
		gap: 16px;
		width: 100%;
		background: #ffffff;
		.button-outreach {
			border: 1px solid #7b26c9;
		}
	}
}
