/* stylelint-disable max-line-length */
/* stylelint-disable selector-class-pattern */
.view-structural-variant-full-detail-dialog {
	width: 731px !important;
	position: fixed !important;
	height: min(calc(100% - 345px), 1472px);
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}

/******************************************************************************
								Dialog Header
*******************************************************************************/

.view-structural-variant-full-detail-dialog .el-dialog__header {
	border-bottom: 1px solid #edeff3;
	padding: 15px 16px;
}

.view-structural-variant-full-detail-dialog .header {
	display: flex;
	color: #262837;
	font: bold 20px RionaSans-Bold, sans-serif;
}

/* .view-structural-variant-full-detail-dialog .structural-variant-title .gmi-pill-wrapper {
	display: inline-block;
} */

.view-structural-variant-full-detail-dialog
	.el-dialog__header
	.not-detected-pill {
	margin-left: 16px;
	background-color: #f4a4a5;
	color: #400404;
}

.view-structural-variant-full-detail-dialog .header .close-btn-icon {
	height: 23px;
	cursor: pointer;
	margin-left: auto;
}

.view-structural-variant-full-detail-dialog .header .close-btn-icon:hover {
	background-color: #ececec;
	border-radius: 20px;
	height: 27px;
}

.view-structural-variant-full-detail-dialog
	.el-dialog__title
	.close-icon
	svg
	.GMICloseIcon {
	fill: #262837;
}

/******************************************************************************
								Dialog Body
*******************************************************************************/
.view-structural-variant-full-detail-dialog .el-dialog__body {
	padding: 24px 16px;
	height: calc(100% - 118px);
	overflow-y: auto;
	font: normal 12px RionaSans, sans-serif;
	color: #262837;
}

.view-structural-variant-full-detail-dialog .el-dialog__body .field-label {
	font: normal 500 12px RionaSans-Medium, sans-serif;
	color: #525968;
	display: block;
}

.view-structural-variant-full-detail-dialog .el-dialog__body .field-value {
	font: normal bold 14px RionaSans-Bold, sans-serif;
	display: block;
}

.view-structural-variant-full-detail-dialog .el-row {
	margin-bottom: 16px;
}

.view-structural-variant-full-detail-dialog .tooltip-info {
	margin-left: 3px;
}

.view-structural-variant-full-detail-dialog .tooltip-info .more-info-icon {
	width: 14px;
	height: 14px;
	margin-top: -2px;
}

.view-structural-variant-full-detail-dialog .pp-discrete-variant,
.view-structural-variant-full-detail-dialog .pp-latest-interpretation {
	background-color: #000000;
	color: #ffffff;
	border-radius: 8px;
	border: 1px solid #000000;
}

.view-structural-variant-full-detail-dialog
	.el-popover.pp-discrete-variant[x-placement^='top']
	.popper__arrow::after,
.view-structural-variant-full-detail-dialog
	.el-popover.pp-latest-interpretation[x-placement^='top']
	.popper__arrow::after {
	border-top-color: #000000;
}

.view-structural-variant-full-detail-dialog
	.el-popover.pp-discrete-variant[x-placement^='bottom']
	.popper__arrow::after,
.view-structural-variant-full-detail-dialog
	.el-popover.pp-latest-interpretation[x-placement^='bottom']
	.popper__arrow::after {
	border-bottom-color: #000000;
}

.view-structural-variant-full-detail-dialog .pp-discrete-variant {
	width: 250px !important;
}

.view-structural-variant-full-detail-dialog
	.structural-variant-section-top-border {
	border-top: 1px solid #d8dde4;
	padding-top: 20px;
}

/******************************************************************************
								Dialog Footer
*******************************************************************************/
.view-structural-variant-full-detail-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 11px 16px;
	display: flex;
	justify-content: end;
}

.view-structural-variant-full-detail-dialog .el-dialog__footer button {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	width: 160px;
	padding: 8px 12px;
	border-radius: 7px;
}

.view-structural-variant-full-detail-dialog
	.el-dialog__footer
	.edit-detail-button {
	border: 1px solid #7b26c9 !important;
	color: #7b26c9;
	width: 131px;
	padding: 3px 0 3px 10px;
}

.view-structural-variant-full-detail-dialog
	.el-dialog__footer
	.edit-detail-button:hover {
	background-color: rgb(123 38 201 / 15%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.view-structural-variant-full-detail-dialog
	.el-dialog__footer
	.edit-detail-button:active {
	background-color: rgb(123 38 201 / 30%);
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.view-structural-variant-full-detail-dialog
	.el-dialog__footer
	.edit-detail-button
	span {
	display: flex;
	align-items: center;
}

.view-structural-variant-full-detail-dialog
	.el-dialog__footer
	.edit-details-icon {
	width: 28px;
	height: 28px;
}

.view-structural-variant-full-detail-dialog
	.el-dialog__footer
	svg
	.GMI-edit-icon {
	fill: #7b26c9;
}
