@use '../../Common/V3/system';
@use '../core';

$gray: #7b8291;

.#{core.$scopeClass} {
	.main {
		.main-table {
			border-collapse: separate;
			border-spacing: 0;
			min-width: 100%;
			overflow-x: scroll;

			th {
				padding: system.$halfUnit system.$singleUnit system.$halfUnit
					system.$singleUnit;
				text-align: left;
				color: $gray;
				font-size: 14px;
				user-select: none;

				.sortable {
					display: flex;
					gap: system.$halfUnit;
					align-items: center;

					cursor: pointer;
				}
			}
			tbody {
				font-weight: 490;
				$border-style: 1px #d8dde4 solid;
				$border-radius: system.$quarterUnit;

				td:first-child {
					border-left: $border-style;
				}
				td:last-child {
					border-right: $border-style;
				}
				tr:first-child {
					td {
						border-top: $border-style;
					}
					td:first-child {
						border-top-left-radius: $border-radius;
					}
					td:last-child {
						border-top-right-radius: $border-radius;
					}
				}
				tr:last-child {
					td {
						border-bottom: $border-style;
					}
					td:first-child {
						border-bottom-left-radius: $border-radius;
					}
					td:last-child {
						border-bottom-right-radius: $border-radius;
					}
				}
				tr {
					height: system.$quadrupleUnit;
					&:hover td {
						background-color: #d9f1fb;
					}
				}
				td {
					transition: background-color 200ms;
					background-color: white;
					padding: 0px system.$singleUnit 0px system.$singleUnit;
					border-bottom: $border-style;
					font-family: RionaSans-Medium;
					font-size: 14px;
					font-weight: 500;

					.sub {
						color: $gray;
					}

					&.actionable:hover {
						cursor: pointer;
						.highlight {
							color: #7b26c9;
							text-decoration: underline;
						}
					}
				}
			}

			a,
			a:visited {
				color: #7b26c9;
			}
		}
		.tfoot-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-family: RionaSans-Medium;
			font-size: 12px;
			font-weight: 500;			
			color: #7b8291;
			.results-per-page {
				display: flex;
				.label-results-per-page {
					margin-right: 4px;
				}
				.el-dropdown {
					font-size: 12px;
					color: #7b8291;
					cursor: pointer;
					&:hover {
						text-decoration: underline;
					}
				}
				.limit-number-wrapper {
					display: flex;
				}
			}
		}

		.pagination {
			display: flex;
			flex-direction: row-reverse;
		}
	}
}
