.addMultiVisitModal {
	width: 552px;
	min-height: 150px;
	height: min(calc(100% - 120px), 1472px);
	max-height: 623px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: absolute;
	left: calc(50% - 276px);
	top: 50%;
	transform: translateY(-50%);
}

.addMultiVisitModal p {
	margin: unset;
	padding: unset;
}

.addMultiVisitModal .topRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 8px 16px;
	height: 56px;
	border-bottom: 1px solid #edeff3a2;
}

.addMultiVisitModal .topRow.shadow {
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}

.addMultiVisitModal .topRow .title {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.addMultiVisitModal .topRow .breadcrumbs {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #bdc5cf;
	display: flex;
}

.addMultiVisitModal .topRow .breadcrumbs span {
	height: max-content;
}

.addMultiVisitModal .topRow .breadcrumbs span.active {
	color: #262837;
}

.addMultiVisitModal .topRow .breadcrumbs .gtIcon {
	transform: scale(0.65);
	position: relative;
	bottom: 6px;
}

.addMultiVisitModal .topRow .closeModal {
	transform: scale(1.4);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	top: 8px;
	cursor: pointer;
}

.addMultiVisitModal .topRow .closeModal:hover {
	background-color: #ececec;
}

.addMultiVisitModal .topRow .closeModal:active {
	background-color: #c7c7c7;
}

.addMultiVisitModal .topRow .closeModal > div > svg {
	height: 25px;
	width: 25px;
}

.addMultiVisitModal .topRow .closeModal > div > svg > g > g {
	fill: #262837 !important;
}

.addMultiVisitModal .body {
	height: calc(100% - 116px);
	width: 100%;
	overflow: auto;
}

.addMultiVisitModal .body .searchBox {
	height: 48px;
	padding: 8px 16px;
	border-bottom: 1px solid #edeff3a2;
	position: relative;
}

.addMultiVisitModal .body .searchBox .clearSearchIcon {
	width: 28px;
	height: 28px;
	top: 10px;
	right: 18px;
	cursor: pointer;
}

.addMultiVisitModal .body .searchBox .clearSearchIcon svg > g > g {
	fill: #262837;
}

.addMultiVisitModal .body .searchBox > div {
	position: absolute;
	top: 15px;
	right: 24px;
}

.addMultiVisitModal .body .searchBox > div svg > g > g {
	fill: #a0abb9;
}

.addMultiVisitModal .body .searchBox input {
	width: 100%;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	background-color: #edeff3;
	padding: 8px;
	transition: 100ms all;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.addMultiVisitModal .body .searchBox input:focus {
	border: solid 1px #40b9ec;
}

.addMultiVisitModal .body .searchBox input::placeholder {
	font-family: RionaSans-Medium;
	font-size: 12px !important;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
}

.addMultiVisitModal .body .patientsListContainer {
	height: calc(100% - 56px);
}

.addMultiVisitModal .body .patientsListContainer .appointment-pagination {
	display: flex;
	justify-content: flex-end;
	margin-right: 16px;
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.appointment-pagination
	> button {
	width: 2.5vw !important;
}

.addMultiVisitModal .body .patientsListContainer .patientsList {
	margin: 8px 16px 23px;
}

.addMultiVisitModal .body .patientsListContainer .patientsList > div,
.addMultiVisitModal .body .encountersListContainer .encountersList > div {
	height: 50px;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	border: 1px solid transparent;
}

.addMultiVisitModal .body .patientsListContainer .patientsList > div.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.addMultiVisitModal .body .encountersListContainer .encountersList > div {
	margin-bottom: 8px;
	border: solid 1px #edeff3;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div:not(.selected):hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div:not(.selected):active {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.addMultiVisitModal .body .patientsListContainer .patientsList > div.selected,
.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div.selected {
	border: solid 1px #7b26c9;
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div:not(.selected):not(.disabled):hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div:not(.selected):not(.disabled):active {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div
	.radioButton,
.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.radioButton {
	height: 16px;
	width: 16px;
	margin: 16px;
	border: 1px solid #a0abb9;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div.selected
	.radioButton,
.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div.selected
	.radioButton {
	border: 1px solid #bd92e4;
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div.selected
	.radioButton
	> div,
.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div.selected
	.radioButton
	> div {
	background-color: #7b26c9;
	width: 8px;
	height: 8px;
	border-radius: 50%;
}

.addMultiVisitModal .body .patientsListContainer .patientsList > div .content {
	margin: 8px 0;
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div
	.content
	> .name,
.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div
	.content
	> .email {
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div
	.content
	> .name {
	color: #262837;
}

.addMultiVisitModal
	.body
	.patientsListContainer
	.patientsList
	> div
	.content
	> .email {
	color: #7b8291;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content {
	display: flex;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .dateAndTime {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 90px;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .dateAndTime
	> div:first-of-type {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .dateAndTime
	> div:last-of-type {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #474e53;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .encounterInfo {
	display: flex;
	align-items: center;
	margin: 0 27px 0 40px;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .encounterInfo
	> div:first-of-type {
	width: 28px;
	height: 28px;
	margin-right: 4px;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .encounterInfo
	> div:last-of-type
	> div:first-of-type {
	width: 137px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .encounterInfo
	> div:last-of-type
	> div:last-of-type {
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
	text-transform: capitalize;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .providerInfo {
	display: flex;
	align-items: center;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .providerInfo
	> img {
	width: 20px;
	height: 20px;
	border-radius: 3px;
	margin-right: 8px;
}

.addMultiVisitModal
	.body
	.encountersListContainer
	.encountersList
	> div
	.content
	> .providerInfo
	> div {
	width: 110px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.addMultiVisitModal .body .searchForPatient {
	min-height: calc(100% - 48px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: center;
	color: #a0abb9;
}

.addMultiVisitModal .body .searchForPatient .searchIcon {
	width: 80px;
	height: 80px;
	margin-bottom: 16px;
}

.addMultiVisitModal .body .patientInfo {
	display: flex;
	height: 99px;
	border-radius: 4px;
	background-color: #edeff3;
	margin: 32px 16px 0 16px;
	padding: 12px;
}

.addMultiVisitModal .body .patientInfo > div:first-of-type {
	margin-right: 13px;
}

.addMultiVisitModal .body .patientInfo > div * {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.addMultiVisitModal .body .patientInfo > div .name {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	margin-bottom: 4px;
}

.addMultiVisitModal .body .patientInfo > div .refProgram > span {
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	margin-left: 8px;
}

.addMultiVisitModal .body .patientInfo > div .dob,
.addMultiVisitModal .body .patientInfo > div .age {
	margin-right: 10px;
}

.addMultiVisitModal .body .patientInfo > div .gender,
.addMultiVisitModal .body .patientInfo > div .age {
	margin-left: 10px;
}

.addMultiVisitModal .body .patientInfo > div .gender {
	text-transform: capitalize;
}

.addMultiVisitModal .body .encountersListContainer {
	margin: 16px;
}

.addMultiVisitModal .body .encountersListContainer > div:first-of-type {
	margin: 0 0 16px 7px;
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.addMultiVisitModal .body .noEncounters {
	padding: 92px 135px;
	text-align: center;
}

.addMultiVisitModal .body .noEncounters > div:first-of-type {
	font-family: RionaSans-Medium;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #262837;
	margin-bottom: 4px;
}

.addMultiVisitModal .body .noEncounters > div:last-of-type {
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: center;
	color: #7b8291;
}

.addMultiVisitModal .bottomRow {
	width: 100%;
	display: flex;
	padding: 12px 16px;
	font-size: 13px;
	color: white;
	border-top: 1px solid #edeff3a2;
	height: 60px;
	background-color: #fff;
	position: relative;
	border-radius: 0 0 4px 4px;
	justify-content: space-between;
}

.addMultiVisitModal .bottomRow.shadow {
	box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.15);
}

.addMultiVisitModal .bottomRow > button.back {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	border: solid 1px #7b26c9;
	background-color: #fff;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #7b26c9;
	padding: 9px 12px;
}

.addMultiVisitModal .bottomRow > button.back:hover {
	background-color: rgba(123, 38, 201, 0.15) !important;
}

.addMultiVisitModal .bottomRow > button.back:active {
	background-color: rgba(123, 38, 201, 0.3) !important;
}

.addMultiVisitModal .bottomRow > button.continue {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	border: unset;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	padding: 9px 12px;
}

.addMultiVisitModal .bottomRow > button.continue:not(.disabled):hover {
	background-color: #9c5dd7;
}

.addMultiVisitModal .bottomRow > button.continue:not(.disabled):active {
	background-color: #621ea0;
}

.addMultiVisitModal .bottomRow > button.continue.disabled {
	opacity: 0.35;
	cursor: not-allowed;
}
