@import './../../../colors.css';
.Patients,
.PatientDetail,
.EncounterDetails {
	height: 100%;
}

.table-header1 {
	padding: 0;
	margin-bottom: 1.3vh !important;
	display: flex;
	align-items: center;
	margin-top: 1vw !important;
}

.pg-no-list {
	font-size: 14px;
	text-align: right;
	color: #666;
	font-weight: 400;
	opacity: 0.8;
}
.enhanced_search_wrapper_table .patients_list .fixed {
	border-bottom: 2.5px solid #005570 !important;
}

.no_result_enchanced {
	position: absolute;
	top: 20vw;
	left: 35vw;
	font-size: 1.9vw;
	color: #c1bbbb;
	font-weight: 700;
}

.pad-r5 {
	padding-right: 5px;
}

.encount-lbl {
	font-size: 0.65vw;
	color: #858e96;
	display: flex;
	justify-content: flex-end;
	-webkit-font-smoothing: antialiased;
}

.cont-cent-box {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.margin-top-0 {
	margin-top: 0 !important;
}

.margin-right-0 {
	margin-right: 0 !important;
}

.patients_container {
	display: flex;
	margin-left: 5vw;
	height: 100%;
}

.margin-top-1 {
	margin-top: 1vw !important;
}

.addfield svg {
	color: #006bc7 !important;
}

.table-header {
	font-size: 11px;
	color: #615858;
	padding-left: 0;
}

.patients_list {
	width: 100%;
	height: 100%;
	background-color: rgba(1, 1, 1, 0.05);
	padding: 2vw;
	z-index: 1;
	height: 40vw;
	margin-top: 2vw;
	background: #fff;
	box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.14);
	margin-bottom: 30px;
	border: 1px solid #ddd;
}

.search_table {
	height: 100% !important;
}

.back_icon > i.material-icons {
	padding-top: 0.25vw;
}

.dialog_title > h2 {
	font-size: 1vw;
}

.dialog_actions {
	font-size: 1vw !important;
}

.encounter_fields > .lblbox {
	padding-top: 0.4vw;
	padding-bottom: 0.4vw;
}

.stage_title {
	float: left;
	font-weight: bold;
	font-size: 0.92vw;
}

.stage_icon {
	float: right;
}

.page_context {
	float: left;
	font-size: 1.5vw;
	color: #777;
}

.patients_count {
	font-size: 0.8vw;
	opacity: 0.5;
}

i.material-icons.stage {
	font-size: 1.3vw;
	color: var(--main-nav-color);
}

i.material-icons.edit_patient {
	font-size: 0.9vw;
	color: var(--left-nav-color);
}

.stage_patients_count {
	text-align: center;
	padding-top: 2vw;
	font-weight: bold;
	font-size: 1.5vw;
}

.patients_left_nav {
	width: 15vw;
	padding: 2vw;
}

.patients_title_count {
	float: left;
	padding-top: 0.5vw;
	font-size: 1vw;
}

.search_patients_details {
	color: #583752;
	font-weight: bold;
	height: 3vw;
	background-color: transparent;
	margin-top: 2vw;
	margin-bottom: 1vw;
	padding-top: 1.1vw;
	color: #583752;
	font-weight: bold;
}

.patients_details.demographic {
	width: 25vw;
	height: 15vw;
}

.patients_list_wrapper.enhanced_search {
	width: 100%;
	margin-top: 24px;
	margin-left: auto;
	margin-right: auto;
}
.patients_list_wrapper.enhanced_search .enhanced_search {
	padding: 0;
}

.patients_search_form {
	width: 100%;
	height: 4vw;
	border-bottom: 1px solid #d8d1d1;
	border-top: 1px solid #d8d1d1;
	margin-top: 1vw;
}

.backtext {
	text-transform: capitalize;
}

.datepick input {
	height: 1.1875em !important;
	font-size: 1vw !important;
	width: 100% !important;
	border: 0 !important;
	margin: 0 0 0 0px !important;
	padding: 1.4vh 0 !important;
	display: block !important;
	min-width: 0 !important;
	-webkit-box-sizing: content-box !important;
	box-sizing: content-box !important;
	background: none !important;
	-webkit-tap-highlight-color: transparent !important;
	box-shadow: none !important;
}

.rdt::before {
	left: 0;
	right: 0;
	bottom: 0;
	content: '\00a0';
	position: absolute;
	transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-bottom: 1px solid rgba(0, 0, 0, 0.42);
	pointer-events: none;
}

li.patients_li {
	margin-left: 2.5vw;
	margin-top: 2vw;
	width: 66%;
	background-color: #f2f2f2;
}

.total_records {
	margin-top: 1.4vw;
	margin-left: 2vw;
	float: left;
}

ul.pagination {
	margin-top: 0vw;
	font-size: 0.8vw;
	float: right;
}

.patient_pagination {
	float: right;
	display: flex;
	margin-right: 1vw;
	align-items: center;
}

.patient_pagination > label.el-switch,
.patient_pagination > div > label.el-switch {
	top: 0px !important;
	font-size: 10px !important;
	margin-bottom: 0px !important;
	margin-right: 28px !important;
	margin-left: 8px !important;
}

.patient_pagination>label.el-switch--wide>.el-switch__label, .patient_pagination>div>label.el-switch--wide>.el-switch__label /* EHR-4404 */ {
	z-index: 1 !important;
}

.patient_pagination>label.el-switch--wide>.el-switch__label.el-switch__label, .patient_pagination>div>label.el-switch--wide>.el-switch__label.el-switch__label /* EHR-4404 */ {
	top: 2px !important;
	font-size: 10px !important;
}

.patient_pagination>label.el-switch--wide>.el-switch__label.el-switch__label--left>span, .patient_pagination>div>label.el-switch--wide>.el-switch__label.el-switch__label--left>span /* EHR-4404 */ {
	left: 8px !important;
	top: 4px !important;
}

.patient_pagination>label.el-switch--wide>.el-switch__label.el-switch__label--right>span, .patient_pagination>div>label.el-switch--wide>.el-switch__label.el-switch__label--right>span /* EHR-4404 */ {
	right: 6px !important;
}

.create_button,
.expand_button,
.create_lab {
	min-height: 1vw !important;
	min-width: 1vw !important;
	line-height: 1vw !important;
	margin-top: 0.5vw;
	font-size: 0.7vw !important;
	color: #ffffff;
	border-color: #6a305d !important;
}

.create_button.encounter {
	width: 2.2vw;
	height: 2.2vw;
}

.create_button,
.expand_button {
	margin-right: 1vw !important;
}

.adddatefield > div {
	width: 100% !important;
}

.patient_records_pagination button {
	font-size: 15px !important;
	font-weight: bold;
}

.patient_records_pagination {
	font-size: 1vw !important;
	font-weight: bold !important;
	float: right;
	-webkit-font-smoothing: antialiased;
	margin-right: 1vw;
	display: flex;
}

.onlable {
	margin-left: -32px;
}

.ontext {
	color: #777 !important;
	font-size: 0.9vw !important;
	margin-right: 0.5vw !important;
	font-weight: 600;
}

.table-responsive {
	width: 100%;
	color: #262837;
	font-size: 0.8vw;
	overflow: auto;
	height: 37vw;
}

.table-responsive th {
	font-weight: bold;
	color: #583752;
	-webkit-font-smoothing: antialiased;
}

.table-responsive tbody tr:nth-child(odd) {
	background-color: white;
}

.popbox {
	width: 240px !important;
	padding: 20px !important;
	border-top: 1.5px solid var(--main-nav-color);
}

.multiselect-drop hr {
	font: inherit;
	color: currentColor;
	width: 100%;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
	margin: 0;
	padding: 1.4vh 0 !important;
	display: block;
	min-width: 0;
	box-sizing: content-box;
	background: none;
	-webkit-tap-highlight-color: transparent;
	height: 1.1em;
	bottom: 0 !important;
	position: initial !important;
}

.multiselect-drop hr + hr {
	display: none;
}

.multiselect-drop {
	height: 34px !important;
}

.multiselect-drop > *:last-child {
	left: 0;
	right: 0;
	top: 0;
	content: '\00a0';
	position: absolute;
	transition: none !important;
	border-bottom: 1px solid transparent !important;
	pointer-events: none;
}

.multiselect-drop button {
	fill: rgba(0, 0, 0, 0.54) !important;
	padding: 5px !important;
	width: 30px !important;
	height: 30px !important;
}

.timezoneinput {
	font: inherit !important;
	color: rgba(0, 0, 0, 0.87);
	cursor: text;
	display: inline-flex !important;
	line-height: 1.1em !important;
	align-items: center;
	padding-left: 1.2vw;
}

.wordbreak {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 22vw;
}

.timezonebox {
	margin-left: 15px !important;
	width: 100%;
	border-bottom: 0.5px solid #949494;
}

.timezonebox ul {
	z-index: 2;
}

.timezonebox input {
	height: 1.1875em !important;
	color: currentColor !important;
	width: 100% !important;
	border: 0 !important;
	margin: 0 0 0 0px !important;
	padding: 1.4vh 0 !important;
	display: block !important;
	min-width: 0 !important;
	box-sizing: content-box !important;
	background: none !important;
	-webkit-tap-highlight-color: transparent !important;
}

.timezoneinput input {
	border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-top: 0 !important;
	width: 100%;
	box-sizing: content-box !important;
	outline: 0;
	background: #fff;
	color: #474747;
	padding: 1.35vh 0 !important;
	height: 2.4vh !important;
	display: block;
	font-size: 1vw !important;
	border-radius: 0 !important;
}

.multiselect-drop {
	margin: 0 !important;
	border: 0 !important;
	display: inline-flex !important;
	padding: 0 !important;
	position: relative !important;
	min-width: 0 !important;
	flex-direction: column !important;
	vertical-align: top !important;
}

.timezoneinput ul {
	z-index: 2;
}

.popbox:focus {
	outline: none;
}

.form-control {
	font-size: 0.92vw !important;
	height: 2.2vw;
}

.input-group-addon {
	font-size: 0.92vw;
	height: 2.25vw;
}

a#basic-nav-dropdown {
	font-size: 1.1vw;
	padding: 0vw;
}

.patient_demographic {
	padding-left: 1.7vw;
	font-size: 1.8vw;
	font-weight: bold;
	color: #646467;
	padding-top: 1vw;
	height: 5vw;
}

.mrg-l-32 {
	margin-left: -32px;
}

.demographic_edit {
	float: right;
	margin-left: 14vw;
	cursor: pointer;
}

.profile_pic_wrapper {
	border-radius: 50%;
	background: #f5f5f5;
	width: 3vw;
	height: 3vw;
	text-align: center;
	padding: 0vw;
}

i.material-icons.profile_picture_placeholder {
	padding-top: 0.8vw;
	color: #583752;
	font-size: 1.2vw;
}

div#name_tooltip > div {
	font-size: 0.6vw;
}

div#comment_tooltip > div {
	font-size: 0.7vw;
	width: 20vw;
}

.col-md-8.col-sm-8.col-xs-12.mt-drop {
	width: 27vw !important;
}

.col-md-12.col-sm-12.col-xs-12.mt-drop > div {
	height: 8vw !important;
	padding-top: 0.5vw !important;
}

.col-md-8.col-sm-8.col-xs-12.mt-drop > div {
	height: 8vw !important;
	padding-top: 0.5vw !important;
}

.col-md-8.col-sm-8.col-xs-12.mt-drop > div > textarea,
.col-md-12.col-sm-12.col-xs-12.mt-drop > div > textarea {
	height: 100% !important;
}

.mid-dropbox {
	width: 100%;
	float: left;
	background-color: #fbfbfb;
	padding: 1vw 0;
	margin-bottom: 1.09vw;
}

.gender {
	font-size: 0.8vw;
	text-transform: none;
	padding-top: 0.2vw;
	color: #777777;
}

.email {
	font-size: 1vw;
	font-weight: normal;
	text-align: center;
}

.details_dob_gender_container {
	font-size: 1vw;
	margin-top: 5vw;
	font-weight: normal;
	display: flex;
}

.details_dob {
	float: left;
	width: 50%;
	display: flex;
}

.details_gender {
	float: right;
	display: flex;
}

.details_title {
	margin-right: 0.5vw;
}

.patient_demographic_details {
	padding: 1vw;
	padding-left: 1.5vw;
	display: grid;
	grid-template-columns: auto auto auto;
	font-size: 0.7vw;
	color: #777;
	grid-row-gap: 1vw;
}

.entity {
	padding-right: 0.5vw;
	opacity: 0.6;
}

.errp {
	margin: 0 !important;
	padding-left: 2vw !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
	border: 2px solid white;
	border-bottom-color: #00aeef;
	color: #00aeef;
	font-weight: bold;
}

.nav-tabs > li.disabled {
	opacity: 0.5;
}

.nav-tabs > li > a {
	font-size: 1vw;
}

.encounter_type {
	padding-left: 0.5vw;
	padding-top: 0.5vw;
	font-size: 2vw;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	color: #777;
	font-size: 2vw;
	display: flex;
}

.encounter_subinfo {
	font-size: 0.9vw;
	padding-left: 0.8vw;
	color: #777;
	opacity: 0.8;
	padding-top: 1.2vw;
}

.encounter_fields_container {
	height: 68%;
	background-color: white;
	width: 100%;
	margin-top: 2.5vw;
}

.encounter_info_header {
	padding: 2vw;
	font-size: 1.5vw;
	color: #777;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	float: left;
}

.encounter_fields {
	display: block;
}

.list-line {
	margin-top: 0.7vw;
	margin-bottom: 0.7vw;
	border: 0;
	border-top: 1px solid #d8d8d8;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #07afef;
	border-color: #30b5f0;
}

.button#dropdown-basic-3 {
	font-size: 0.9vw;
}

:not(#new-encounter-dropdown-container) > .btn-group-vertical > .btn,
:not(#new-encounter-dropdown-container) > .btn-group > .btn {
	font-size: 0.9vw !important;
	height: 1.5vw !important;
	line-height: 0.9vw !important;
	padding-top: 0.25vw !important;
}

span#example-datepicker_group {
	width: 50%;
}

ul.dropdown-menu {
	font-size: 0.9vw !important;
}

.encounter_edit_clear_button {
	height: 2.4vw;
	line-height: 0vw;
	font-size: 0.9vw;
	margin-left: 1vw;
}

button.patient_comments_submit_button {
	height: 2.2vw;
	line-height: 0.92vw;
	font-size: 0.92vw;
	margin-left: 2vw;
}

button.patient_create_encounter {
	float: right;
	margin-right: 1vw;
	margin-top: 2vw;
	height: 2vw;
	line-height: 0.92vw;
	font-size: 0.92vw;
	width: 10vw;
	color: #33b0ee;
}

div#date-picker-popover-0,
.popover-title,
.modal-title {
	font-family: 'Open Sans', sans-serif;
}

.modal-content {
	width: 45vw;
}

.modal-body {
	padding: 1vw;
}

table.encounter_list.table.table-striped {
	max-height: 16vw !important;
	width: 93%;
	margin: 2vw;
}

td.td_patient_name {
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
}

td.td_patient_name:hover {
	cursor: pointer;
	color: #5bc0de;
}

td.td_expand:hover {
	cursor: pointer;
}

.encounter_values {
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	/*text-transform: capitalize;*/
	/* commenting this because it is formatting time (am, pm) incorrectly. it is changing "am" to "Am" . it should either be both in lowercase or both in uppsercase */
}

.nav_back {
	display: flex;
	font-size: 1vw;
	color: #00aeef;
	padding: 0.5vw;
	padding-left: 0.7vw !important;
}

.react-phone-number-input__icon {
	width: 1vw !important;
	height: 1vw !important;
	border: none !important;
}

.react-phone-number-input__icon-image {
	width: 1.5vw;
	height: 1.5vw;
}

.has-feedback label ~ .form-control-feedback {
	top: 1.6vw;
	height: 2vw;
	width: 2vw;
	font-size: 0.92vw;
	line-height: 2vw;
}

label.control-label {
	font-weight: normal;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
	background-color: #59a7c5;
}

.MuiExpansionPanelSummary-root-85,
.MuiExpansionPanelSummary-root-126,
.encounter_expansion_panels {
	padding: 0 2vw 0 2vw !important;
	min-height: 2vw !important;
	color: white !important;
	background: #59a7c5 !important;
}

.rootCurrent {
	color: purple !important;
}

.patient_detail {
	width: 100%;
	margin-left: 9vw;
	margin-top: 7vw;
}

.margin-top-1 {
	margin-top: 1vw;
}

.patient_encounter_detail {
	margin-left: 9vw;
	margin-top: 7vw;
	width: 100%;
	background: white;
	height: 100vh;
	padding-top: 1vw;
	box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.14);
	padding-left: 1vw;
	margin-bottom: 7vh;
	padding-right: 1vw;
}

.add_comment {
	margin-top: 2vw;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	padding: 0 1vw;
}

.add_comment > div > label {
	font-size: 0.9vw;
	-webkit-font-smoothing: antialiased;
}

.add_comment > button {
	width: 10vw;
	margin-left: 2vw;
	text-transform: capitalize;
}

.entity,
.entityval {
	font-size: 0.9vw;
}

.encounter_fields_container {
	height: auto;
}

.encounter_tabs {
	padding: 0vw;
}

.margin-top-10 {
	margin-top: 2vh;
}

.PageHeader {
	z-index: 1;
}

.PatientsRecords .table-responsive {
	height: auto !important;
}

.patients_list_enchanced {
	height: calc(100vh - 10vh) !important;
}

.patients_list {
	padding: 1vw !important;
}

.header_row.enhanced_search .header_row_th {
	border-bottom: none !important;
}

.patient_encounter_detail {
	margin-left: 8vw !important;
	height: 100% !important;
	padding-bottom: 3vh !important;
}

/* 19-oct-2018 */

.en-details {
	width: 100%;
	float: left;
	margin-top: 1.2vw;
	padding-top: 1vw;
	padding-bottom: 1vw;
}

.lblhead {
	font-size: 1vw;
	color: #999;
	margin-top: 0.8vw;
	float: left;
}

.MuiFormControlLabel-label-80 {
	font-size: 1vw !important;
}

.mt-drop {
	font-size: 0.9vw !important;
}

.text-drop {
	font-size: 1vw !important;
}

.text-drop > ul {
	width: 100%;
}

.maininput {
	width: 50%;
	float: left;
}

.marg-tb-10 {
	margin-top: 1.9vh;
	margin-bottom: 1.9vh;
}

.radioinline {
	display: inline !important;
}

.MuiSvgIcon-root-62 {
	width: 1.6vw !important;
	height: 1.6vw !important;
}

.marg-tb-20 {
	margin-top: 2.9vh;
	margin-bottom: 2.9vh;
}

.margin-r6 {
	margin-right: 1vw !important;
}

.pad-l-0 {
	padding-left: 0;
}

.MuiInputBase-input-28 {
	padding: 1.4vh 0 !important;
}

.pad-tb {
	padding-top: 1.4vh;
	padding-bottom: 1.4vh;
}

h5.pthead.pull-right {
	float: right;
	padding-top: 0.5vw;
}

.MuiTypography-colorTextSecondary-101 {
	font-size: 1.2vw !important;
}

input::placeholder {
	font-size: 1vw !important;
}

.padleft-box {
	padding-left: 10%;
}

.margin-btm-0 {
	margin-bottom: 0;
}

.patient_records_table.enhanced_search th {
	padding-left: 1vw !important;
	font-size: 0.8vw !important;
	color: #39444e;
}

.patient_records_table.enhanced_search td {
	font-size: 0.8vw !important;
	padding: 0.8vw !important;
	color: #39444e;
	opacity: 0.8;
	border-bottom: 1px solid rgba(148, 148, 148, 42%);
}

.border-bottom-0 {
	border-bottom: none !important;
	padding: 0.2vw !important;
}

.patient_records_table.enhanced_search .encounter_th {
	font-weight: 600 !important;
	padding: 1vw !important;
	font-size: 0.8vw !important;
	color: #39444e;
	opacity: 0.65;
	border-bottom: 2.5px solid rgba(222, 222, 222, 1) !important;
}

.patient_records_table .encount_records_table td {
	font-size: 0.9vw !important;
	padding: 1vw !important;
	color: rgb(128, 136, 143);
	border-bottom: 1px solid rgba(148, 148, 148, 42%);
}

.header_row.enhanced_search th {
	border-bottom: 2.5px solid #005570 !important;
	color: #525968 !important;
	padding: 0.5vw !important;
	padding-left: 0.5vw !important;
}

.header_row.enhanced_search {
	height: 1.2vw !important;
}

.patient_records_table.enhanced_search th svg {
	width: 2vw !important;
	height: 1.5vw !important;
	margin: 0 0 -0.3vw -0.1vw;
}

i.material-icons.expand_icons {
	width: 1vw;
	height: 0.5vw;
	font-size: 1vw;
}

.phoneinput input {
	font: inherit;
	width: 100%;
	color: currentColor;
	border: 0;
	margin: 0;
	padding: 6px 0 7px;
	display: block;
	min-width: 0;
	box-sizing: content-box;
	background: none;
	-webkit-tap-highlight-color: transparent;
	font-size: 1vw;
}

.selectplaceholder {
	color: #666666 !important;
	opacity: 0.7;
}

.radioact {
	color: #000 !important;
}

hr.separator {
	border-top: 1px solid #dedede;
}

.placecolor input::-webkit-input-placeholder {
	color: #666666 !important;
	opacity: 0.7;
}

.placecolor input:-ms-input-placeholder {
	color: #666666 !important;
	opacity: 0.7;
}

.formcntrlradio span:last-child {
	color: #666666 !important;
	opacity: 0.7;
	font-size: 1vw !important;
}

.MuiTypography-body1-99 {
	color: #666666 !important;
	opacity: 0.7;
}

.placecolor input:-moz-placeholder {
	color: #666666 !important;
	opacity: 0.7;
}

.placecolor input::placeholder {
	opacity: 0.7;
	color: #666666 !important;
}

.addptbox {
	margin-top: 7vw;
	padding: 0.9vw 1vw;
	background-color: white;
	box-shadow: 1px 1px 1px #ccc;
	width: 100%;
}

.pthead {
	font-family: 'Riona Sans', sans-serif;
	font-weight: 600;
}

.errortext {
	font-size: 0.8vw !important;
}

.react-phone-number-input__input {
	border-bottom: 1px solid rgb(145, 146, 148) !important;
	background-color: transparent;
}

.react-phone-number-input__input:focus {
	border-bottom: 2px solid #3f51b5 !important;
}

.react-phone-number-input__input:hover {
	border-bottom: 2px solid rgba(8, 8, 8, 0.8) !important;
}

.react-phone-number-input__row {
	padding-top: 10px;
}

.phn-focus {
	border-bottom: 2px solid #f44336 !important;
}

.MuiIconButton-root-39 {
	padding: 0.8vw !important;
}

.addbtn {
	width: 2.2vw !important;
	height: 2.2vw !important;
	border-radius: 50% !important;
	padding: 0 !important;
	color: #fff;
	float: left;
	border: none;
	cursor: pointer;
	min-width: 2.2vw !important;
	min-height: 2.2vw !important;
}

.color-white {
	color: #fff !important;
}

.headt {
	margin-top: 0.7vw;
	margin-right: 1vw;
	float: left;
}

.pthead {
	margin: 0;
}

td.tablecell_expanded,
td.tablecell_expanded_header {
	border-bottom: none;
}

td.tablecell_expanded_header {
	color: #6a305d;
}

tr.expanded_encounter_rows:hover,
tr.expanded_encounter_rows.last:hover {
	cursor: pointer;
	background-color: #ddd;
}

tr.expanded_encounter_rows,
tr.expanded_encounter_rows.last,
tr.expanded_encounter_header_rows {
	background-color: #f5f5f5;
	border-left: 1px solid #63345b;
	border-right: 1px solid #63345b;
}

tr.expanded_encounter_rows.last td {
	border-bottom: 1px solid #63345b;
}

i.material-icons.report_flag {
	color: crimson;
	font-size: 1.5vw;
}

.pattr {
	background-color: white;
}

.pattr:hover {
	background-color: #f3f3f3;
}

.ptcolor {
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 10vw;
}

.part-heading {
	margin-bottom: 0;
	color: #006bc7;
	font-weight: 500;
	font-size: 1.14vw;
	padding-bottom: 0.7vw;
	margin-top: 1.6vw;
}

hr {
	margin: 0;
}

td.patient_note,
.patient_note {
	max-width: 40vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.patient_note {
	max-width: 50vw;
}

.pattr:hover .ptcolor {
	color: rgb(0, 174, 239);
}

.pattr.expanded_patient {
	background: #63345b;
	border-radius: 50px;
	border-left: 1px solid #63345b;
	border-right: 1px solid #63345b;
}

.pattr.expanded_patient td {
	color: white;
	font-size: 1.1vw !important;
}

.height_500 {
	min-height: 500px !important;
}

.editic {
	font-size: 1.2vw;
}

.editbtn span.material-icons {
	font-size: 1.2vw;
}

.main-part {
	width: 100%;
	float: left;
	border-top: none !important;
}

.inputfont > div {
	font-size: 0.9vw !important;
}

.addressbox {
	border: 0.04vw dashed #e4e4e4;
	padding: 0.8vw 2vw;
	float: left;
	margin: 1vw 5vw 0 0;
	background-color: #f7f7f7;
}

.padleft-box1 {
	padding-left: 5%;
}

.formcntrlradio > span > span svg {
	width: 1.6vw !important;
	height: 1.6vw !important;
}

/*-------------------media-----------------------*/

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.errp {
		margin-top: 0.7vw !important;
		margin-bottom: 0.7vw !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
	.multiselect-drop hr {
		height: 1.68em;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.table-responsive > .table > tbody > tr > td,
	.btntext {
		font-size: 1.4vw !important;
	}
	.table-responsive th {
		font-size: 1.4vw !important;
	}
	.datepick input {
		font-size: 1.5vw !important;
	}
	.MuiFlatPageButton-rootEnd-82 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.mrg-l-32 {
		margin-left: 0px;
	}
	.tab-pr-0 {
		padding-right: 0;
	}
	.droplbl {
		margin-top: 0.9vw !important;
	}
	.encounter_fields > .lblbox {
		padding-top: 0.8vw;
		padding-bottom: 0.8vw;
	}
	.errp {
		margin-top: 1vw !important;
		margin-bottom: 1vw !important;
	}
	.maininput {
		width: 58%;
		float: left;
	}
	.addressbox {
		width: 100%;
	}
	.padleft-box1 {
		padding-left: 0%;
	}
	.editic {
		font-size: 1.8vw;
	}
	.editbtn span.material-icons {
		font-size: 1.8vw;
	}
	.padleft-box {
		padding-left: 0%;
	}
	.headt {
		margin-top: 1vw;
	}
	.react-phone-number-input__phone:focus {
		border-bottom: 2px solid #03b2cb !important;
	}
	.formcntrlradio span:last-child {
		font-size: 1.4vw !important;
	}
	.addbtn {
		width: 3.5vw !important;
		height: 3.5vw !important;
	}
	.errortext {
		font-size: 1.3vw !important;
	}
	.patient_encounter_detail {
		margin-left: 10vw !important;
	}
	input::placeholder {
		font-size: 1.4vw !important;
	}
	.MuiFormControlLabel-label-80 {
		font-size: 1.2vw !important;
	}
	.MuiIconButton-root-39 {
		padding: 0.9vw !important;
	}
	.patient_records_table.enhanced_search th {
		font-size: 1.3vw !important;
	}
	.patient_records_table.enhanced_search th svg {
		width: 1.5vw !important;
		height: 1.5vw !important;
	}
	.patient_records_table.enhanced_search td {
		font-size: 1.3vw !important;
	}
	.lblhead,
	.text-drop,
	.mt-drop,
	.MuiTypography-colorTextSecondary-101,
	.part-heading {
		font-size: 1.5vw !important;
	}
	.marg-tb-10 {
		margin-top: 1vh;
		margin-bottom: 1vh;
	}
	.search_patients_details {
		margin-top: 2vw;
		margin-bottom: 3vw;
	}
}

@media (min-width: 960px) {
	.tabhead {
		min-width: 60px !important;
	}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.list-line {
		margin-top: 0.7vw;
		margin-bottom: 0.7vw;
		border: 0;
	}
	.timezoneinput {
		margin-top: 0.7vw;
	}
	.timezonebox {
		margin-left: 0 !important;
	}

	.mrg-l-32 {
		margin-left: 0px;
	}
	.mob100 {
		width: 100%;
	}
	.encounter_fields > .lblbox {
		padding-top: 1.2vw;
		padding-bottom: 1.2vw;
	}
	.lblbox {
		width: 100%;
		float: left;
		border-bottom: 0.2vw solid #eaeaea;
	}
	.top-mobi {
		margin-top: 13vh !important;
	}
	.patients_list_wrapper {
		margin-left: 0vw;
		width: 100%;
	}
	.errp {
		margin-top: 1vw !important;
		margin-bottom: 1vw !important;
	}
	.add_comment {
		margin-top: 5vw;
	}
	.react-phone-number-input__icon {
		width: 3.1vw !important;
		height: 3.1vw !important;
	}
	.formcntrlradio span:last-child {
		font-size: 2.2vw !important;
	}
	.mob-pad-r0 {
		padding-right: 0;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.patient_records_table.enhanced_search td,
	.btntext {
		font-size: 1.8vw !important;
	}
	.datepick input {
		font-size: 2.3vw !important;
	}
	.mob-pad-0 {
		padding: 0;
	}
	.formcntrlradio > span > span > svg {
		width: 3vw !important;
		height: 3vw !important;
	}
	.addressbox {
		width: 100%;
	}
	.padleft-box1 {
		padding-left: 0%;
	}
	.editic {
		font-size: 3vw;
	}
	.editbtn span.material-icons {
		font-size: 3vw;
	}
	.formcntrlradio span:last-child {
		font-size: 2.2vw !important;
	}
	.padleft-box {
		padding-left: 0%;
	}
	.MuiIconButton-root-39 {
		padding-left: 2.8vw !important;
	}
	.errortext {
		font-size: 1.8vw !important;
	}
	.MuiFormControlLabel-label-80 {
		font-size: 2.2vw !important;
	}
	.patient_records_table.enhanced_search th svg {
		width: 2.5vw !important;
		height: 2.5vw !important;
	}
	i.material-icons.profile_picture_placeholder {
		padding-top: 1.8vw;
		color: #583752;
		font-size: 3.2vw;
	}
	.add_comment > div > label {
		font-size: 1.6vw;
	}
	.no_data_found {
		font-size: 1.5vw;
	}
	.patient_detail {
		margin-top: 10vw;
		margin-left: 16vw !important;
		width: 83%;
	}
	.profile_pic_wrapper {
		width: 7vw;
		height: 7vw;
	}
	input#PageHeader-search-1 {
		font-size: 2.3vw !important;
	}
	i.material-icons.search {
		font-size: 3vw !important;
	}
	i.material-icons.logged_in_user {
		font-size: 3vw !important;
	}
	.patients_title_count {
		font-size: 2vw !important;
	}
	.patient_records_pagination button {
		font-size: 12px !important;
	}
	.patient_records_table.enhanced_search th {
		font-size: 1.8vw !important;
	}
	.table-responsive > .table > tbody > tr > td {
		font-size: 1.9vw !important;
	}
	.name,
	.gender {
		font-size: 2vw;
	}
	.patient_demographic_details {
		padding-left: 2.5vw;
	}
	.entity,
	.entityval,
	.encounter_list th {
		font-size: 1.9vw !important;
	}
	.tab_labels {
		font-size: 2vw;
	}
	.backtext {
		font-size: 2vw !important;
	}
	.labtext {
		font-size: 3vw !important;
	}
	.encounter_subinfo {
		font-size: 2vw !important;
	}
	.labeltx,
	.encounter_values {
		font-size: 1.8vw !important;
	}
	.patients_list_wrapper {
		margin-left: 0vw;
		width: 100%;
	}
	.lblhead,
	.text-drop,
	.mt-drop,
	.MuiTypography-colorTextSecondary-101,
	.part-heading {
		font-size: 2.3vw !important;
	}
	.mb-center {
		text-align: left !important;
		width: 100%;
	}
	.headt {
		margin-top: 1.2vw;
		margin-right: 2vw;
	}
	.addbtn {
		width: 4.8vw !important;
		height: 4.8vw !important;
	}
	.pthead {
		font-size: 2vw !important;
		float: left !important;
	}
	input::placeholder {
		font-size: 2.2vw !important;
	}
	.addptbox {
		margin-top: 5vw;
	}
	.btntx {
		font-size: 1.8vw !important;
	}
	.MuiSvgIcon-root-62 {
		width: 3vw !important;
		height: 3vw !important;
	}
	.maininput {
		width: 100%;
		float: left;
	}
}

@media only screen and (min-width: 2000px) {
	.add_comment > button {
		height: 2.15vw !important;
	}
	.back_icon > i.material-icons {
		font-size: 1vw;
		padding-top: 0.5vw;
	}
	.text-drop {
		font-size: 0.6vw !important;
	}
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.MuiFormControlLabel-label-80 {
		font-size: 2.8vw !important;
	}
	.formcntrlradio span:last-child {
		font-size: 3vw !important;
	}
	.datepick input {
		font-size: 3vw !important;
	}
	.formcntrlradio > span > span > svg {
		width: 4.6vw !important;
		height: 4.6vw !important;
	}
	.mob-pad-0 {
		padding: 0;
	}
	.addressbox {
		width: 100%;
	}
	.padleft-box1 {
		padding-left: 0%;
	}
	.editic {
		font-size: 4.2vw;
	}
	.editbtn span.material-icons {
		font-size: 4.2vw;
	}
	.padleft-box {
		padding-left: 0%;
	}
	.MuiIconButton-root-39 {
		padding-left: 2.8vw !important;
	}
	.MuiFlatPageButton-rootEnd-82 {
		padding-left: 1.9vw !important;
		padding-right: 1.9vw !important;
	}
	.pthead {
		font-size: 2.8vw !important;
		float: left !important;
	}
	.headt {
		margin-top: 2.7vw;
		margin-right: 3vw;
		float: left;
		font-size: 3vw;
	}
	.errortext {
		font-size: 2.3vw !important;
	}
	.addbtn {
		width: 7.5vw !important;
		height: 7.5vw !important;
	}
	.react-phone-number-input__icon {
		width: 3.1vw !important;
		height: 3.1vw !important;
	}
	.maininput {
		width: 100%;
		float: left;
	}
	input {
		font-size: 3.1vw !important;
	}
	.MuiSvgIcon-root-62 {
		width: 4.6vw !important;
		height: 4.6vw !important;
	}
	input::placeholder {
		font-size: 3vw !important;
	}
	.addptbox {
		margin-top: 12vw;
	}
	.patient_records_table.enhanced_search th {
		font-size: 3vw !important;
	}
	.patient_records_table.enhanced_search th svg {
		width: 3vw !important;
		height: 3vw !important;
	}
	.table-responsive > .table > tbody > tr > td {
		font-size: 2.9vw !important;
	}
	.profile_pic_wrapper {
		width: 9vw;
		height: 9vw;
	}
	.patient_detail {
		margin-top: 10vw;
		margin-left: 16vw !important;
		width: 83%;
		padding: 0 1vw;
	}
	.MuiFlatPageButton-rootEnd-82 {
		padding-left: 0.3vw !important;
		padding-right: 0.3vw !important;
	}
	.patient_records_pagination button {
		font-size: 3.2vw !important;
	}
	.name,
	.gender {
		font-size: 3vw;
	}
	.name_dob {
		margin-top: 1.2vh;
	}
	.entity {
		font-size: 2.9vw !important;
	}
	.entityval {
		font-size: 2.9vw !important;
	}
	.tab_labels {
		font-size: 1.4vh;
	}
	.no_data_found {
		font-size: 2.9vw;
	}
	.encounter_list th {
		font-size: 2.5vw !important;
	}
	i.material-icons.profile_picture_placeholder {
		padding-top: 2vw;
		font-size: 5.2vw;
	}
	encounter_type {
		font-size: 2vw;
	}
	.encounter_values,
	labeltx {
		font-size: 2.7vw !important;
	}
	.encounter_subinfo {
		font-size: 2.9vw !important;
		padding-top: 2.2vw;
	}
	.encounter_fields {
		padding: 2vw;
		grid-template-columns: inherit;
	}
	.lblbox {
		width: 100%;
		float: left;
		border-bottom: 0.2vw solid #eaeaea;
	}
	.labeltx,
	.btntext {
		font-size: 3vw !important;
	}
	.backtext {
		font-size: 3vw !important;
		margin-top: 1vw !important;
	}
	.labtext {
		font-size: 3.8vw !important;
	}
	.patient_demographic_details {
		padding-left: 2.5vw;
	}
	.add_comment > div > label {
		font-size: 2.5vw;
	}
	.MuiTab-labelContainer-73 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.add_comment {
		margin-top: 6vw;
	}
	.lblhead,
	.text-drop,
	.mt-drop,
	.MuiTypography-colorTextSecondary-101,
	.part-heading {
		font-size: 3.1vw !important;
	}
	.mob-icon {
		font-size: 4.5vw;
		margin-top: 1.2vw;
	}
	.marg-tb-10 {
		margin-top: 1vh;
		margin-bottom: 1vh;
	}
	.mb-center {
		text-align: left !important;
		width: 100%;
	}
	.patient_records_table.enhanced_search td {
		font-size: 3.2vw !important;
	}
}

.custom-table2 {
	max-width: calc(100% - 2vw) !important;
	width: calc(100% - 2vw) !important;
	min-width: calc(100% - 2vw) !important;
}

.enhanced_search .appointment-pagination {
	margin-left: 0;
}
