/* stylelint-disable selector-id-pattern */
/* stylelint-disable selector-class-pattern */
@import "./../../colors.css";

.Patients,
.PatientDetail {
	height: 100%;
}

.patient_detail_loader {
	top: calc(50vh - 50px);
	left: calc(50vw - 15px);
}

.alert_dob {
	color: #e74c3c;
}

.alert_dob_svg > div > svg {
	height: 16px;
}

.alert_dob_svg > div > svg > path:nth-child(2) {
	fill: #e74c3c !important;
}

.alert_dob_tooltip > div.el-tooltip__popper.is-light {
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
	background-color: white;
	font-size: 12px;
	color: #262837;
}

button.el-button.el-button--default.tab_labels > span {
	color: rgb(158 158 158);
}

button.el-button.el-button--default.tab_labels {
	border: transparent;
}

.cascader_popper > ul > li {
	color: rgb(0 0 0 / 87%) !important;
}

.el-cascader-menu__item.is-active {
	background-color: transparent !important;
}

.cascader_popper > ul > li:hover {
	background-color: #e4e8f1 !important;
}

tr.el-table__row.expanded_row_found td.el-table__expand-column.expand_column {
	border-bottom: transparent !important;
}

tr.el-table__row {
	cursor: pointer;
}

tr.el-table__row.disabled_expand.patient_records_table.comments_list {
	cursor: default !important;
}

tr.el-table__row.disabled_expand.patient_records_table.comments_list td > div.cell > div.el-table__expand-icon,
tr.el-table__row.disabled_expand.patient_records_table.comments_list > .el-table__expand-icon,
tr.el-table__row.disabled_expand.patient_records_table.commens_list td > div > .el-table__expand-icon {
	display: none !important;
	pointer-events: none !important;
	cursor: default !important;
}

.el-table__expanded-cell {
	background-color: white !important;
	box-shadow: none !important;
}

.el-table::after,
.el-table::before {
	background-color: transparent !important;
}

.el-table__expand-icon > .el-icon {
	font-size: 0.6vw !important;
}

.patient_records_table.comments_list td,
.patient_records_table.comments_list th {
	padding: 0 !important;
}

th.el-table__expand-column.el-table_1_column_1.is-leaf.patient_records_table.comments_list {
	background: transparent;
}

.el-table th.is-leaf,
.el-table__footer-wrapper thead div,
.el-table__header-wrapper thead div {
	background-color: transparent !important;
	color: #525968 !important;
}

.patients_list {
	width: 100%;
	background-color: rgb(1 1 1 / 5%);
	z-index: 1;
	margin-top: 2vw;
	background: #ffffff;
	box-shadow: 0 3px 4px 1px rgb(0 0 0 / 14%);
	margin-bottom: 30px;
	border: 1px solid #dddddd;
	height: auto !important;
	padding: 1vw !important;
}

.back_icon > i.material-icons {
	padding-top: 0.25vw;
}

.dialog_actions {
	font-size: 1vw !important;
}

.comments_input > div > input {
	font-size: 14px;
}

.comments_input > div {
	height: 2.8vw;
}

i.material-icons.stage {
	font-size: 1.3vw;
	color: var(--main-nav-color);
}

i.material-icons.edit_patient {
	font-size: 0.9vw;
	color: var(--left-nav-color);
}

.patients_list_wrapper {
	margin-left: 6.5vw;
	width: 100%;
}

.datepick input {
	height: 1.1875em !important;
	font-size: 1vw !important;
	width: 100% !important;
	border: 0 !important;
	margin: 0 !important;
	padding: 1.4vh 0 !important;
	display: block !important;
	min-width: 0 !important;
	box-sizing: content-box !important;
	background: none !important;
	-webkit-tap-highlight-color: transparent !important;
	box-shadow: none !important;
}

.rdt::before {
	left: 0;
	right: 0;
	bottom: 0;
	content: "\00a0";
	position: absolute;
	transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-bottom: 1px solid rgb(0 0 0 / 42%);
	pointer-events: none;
}

ul.pagination {
	margin-top: 0;
	font-size: 0.8vw;
	float: right;
}

.create_lab {
	min-height: 1vw !important;
	min-width: 1vw !important;
	line-height: 1vw !important;
	margin-top: 0.5vw;
	font-size: 0.7vw !important;
	border-color: #6a305d !important;
	background: #995c90 !important;
	font-weight: bold !important;
	color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
	font-family: inherit !important;
}

.patient_records_pagination button {
	font-weight: bold;
	font-family: inherit;
}

table {
	font-family: inherit !important;
}

.patient_records_pagination {
	font-size: 1vw !important;
	font-weight: bold !important;
	float: right;
	-webkit-font-smoothing: antialiased;
	margin-right: 1vw;
	display: flex;
}

.table-responsive {
	width: 100%;
	color: #262837;
	font-size: 0.8vw;
	overflow: auto;
	height: 37vw;
}

.table-responsive th {
	font-weight: bold;
	color: #525968 !important;
	-webkit-font-smoothing: antialiased;
}

.table-responsive tbody tr:nth-child(odd) {
	background-color: white;
}

.multiselect-drop hr {
	font: inherit;
	color: currentColor;
	width: 100%;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
	border-bottom: 1px solid rgb(0 0 0 / 42%) !important;
	margin: 0;
	padding: 1.4vh 0 !important;
	display: block;
	min-width: 0;
	box-sizing: content-box;
	background: none;
	-webkit-tap-highlight-color: transparent;
	height: 1.1em;
	bottom: 0 !important;
	position: initial !important;
}

.multiselect-drop hr + hr {
	display: none;
}

.multiselect-drop {
	height: 34px !important;
	margin: 0 !important;
	border: 0 !important;
	display: inline-flex !important;
	padding: 0 !important;
	position: relative !important;
	min-width: 0 !important;
	flex-direction: column !important;
	vertical-align: top !important;
}

.multiselect-drop > *:last-child {
	left: 0;
	right: 0;
	top: 0;
	content: "\00a0";
	position: absolute;
	transition: none !important;
	border-bottom: 1px solid transparent !important;
	pointer-events: none;
}

.multiselect-drop button {
	fill: rgb(0 0 0 / 54%) !important;
	padding: 5px !important;
	width: 30px !important;
	height: 30px !important;
}

.timezonebox {
	margin-left: 15px !important;
	width: 100%;
	border-bottom: 0.5px solid #949494;
}

.timezonebox ul {
	z-index: 2;
}

.patient-pagination {
	font-size: 12px !important;
}

.timezonebox input {
	height: 1.1875em !important;
	color: currentColor !important;
	width: 100% !important;
	border: 0 !important;
	margin: 0 !important;
	padding: 1.4vh 0 !important;
	display: block !important;
	min-width: 0 !important;
	box-sizing: content-box !important;
	background: none !important;
	-webkit-tap-highlight-color: transparent !important;
}

.popbox:focus {
	outline: none;
}

.form-control {
	font-size: 0.92vw !important;
	height: 2.2vw;
}

a#basic-nav-dropdown {
	font-size: 1.1vw;
	padding: 0;
}

button.active {
	color: #7b26c9 !important;
	font-family: RionaSans-Bold, sans-serif;
}

button.non-active {
	color: #525969 !important;
	opacity: 1;
	font-family: RionaSans, sans-serif;
}

button.activeDemographic {
	font-size: 14px;
	font-family: RionaSans-Bold, sans-serif;
	color: #ffffff !important;
}

button.non-activeDemographic {
	font-size: 14px;
	font-family: RionaSans, sans-serif;
	opacity: 0.76;
	color: #ffffff;
}

.patient_info {
	width: 340px;

	/* conditional min-width */
	min-width: 340px;
	transition: min-width 0.62s, padding 0.62s;
	transition-timing-function: cubic-bezier(0.2, -0.12, 0.01, 0.91);
	background: transparent;
	height: 100%;
	background-color: #262837;
	color: #d8dde4;
	border-top-left-radius: 5px;
	padding: 24px;
	display: flex;
	flex-direction: column;
}

.patient_info .patient-external-care-team .ext-care-team {
	font: bold 12px/1.25 RionaSans-Bold, sans-serif;
	color: #ffffff;
	margin-bottom: 8px;
}

.patient_demographic {
	padding-left: 1.7vw;
	font-size: 1.8vw;
	font-weight: bold;
	color: #646467;
	padding-top: 1vw;
	height: 5vw;
}

.mrg-l-32 {
	margin-left: -32px;
}

.demographic_edit {
	float: right;
	margin-left: 14vw;
	cursor: pointer;
}

.profile_pic_wrapper {
	border-radius: 50%;
	background: #f5f5f5;
	width: 3vw;
	height: 3vw;
	text-align: center;
	padding: 0;
}

i.material-icons.profile_picture_placeholder {
	padding-top: 0.8vw;
	color: #583752;
	font-size: 1.2vw;
}

div#name_tooltip > div {
	font-size: 0.6vw;
}

div#comment_tooltip > div {
	font-size: 0.7vw;
	width: 20vw;
}

.col-md-8.col-sm-8.col-xs-12.mt-drop {
	width: 27vw !important;
}

.col-md-12.col-sm-12.col-xs-12.mt-drop > div {
	height: 8vw !important;
	padding-top: 0.5vw !important;
}

.col-md-8.col-sm-8.col-xs-12.mt-drop > div {
	height: 8vw !important;
	padding-top: 0.5vw !important;
}

.col-md-8.col-sm-8.col-xs-12.mt-drop > div > textarea,
.col-md-12.col-sm-12.col-xs-12.mt-drop > div > textarea {
	height: 100% !important;
}

.copyIcon {
	font-size: 12px;
	margin-left: 4px;
	cursor: pointer;
	position: relative;
	top: 2px;
}

.name,
.gender {
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
}

.name {
	font-family: RionaSans-Bold, sans-serif;
	font-size: 20px;
	color: #ffffff;
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
	display: flex;
	height: 24px;
}

.patient_info .name:hover {
	text-decoration: underline;
}

.noOutreachName {
	color: #e74c3c;
}

.patientTopPanel {
	margin-bottom: 8px;
}

.nameRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-self: flex-start;
	margin-bottom: -14px;
	height: 24px;
}

.patientInfoTop {
	color: #edeff3;
	font-size: 12px;
	margin-bottom: 2px;
	display: flex;
	flex-direction: row;
}

.patientInfoTopBounded {
	max-width: 95%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.patientInfoTopMargin {
	margin-bottom: 8px;
}

@media (max-width: 1230px) {
	.patientInfoTab {
		width: fit-content;
		min-width: 100%;
	}
}

.bottom {
	align-self: flex-end;
}

.patient_detail .patient_info .sex-gender-wrapper {
	display: flex;
	flex-direction: column;
	color: #edeff3;
	font-size: 12px;
	margin-bottom: 8px;
}

.patient_detail .patient_info .gender-wrapper {
	display: flex;
}

.age-referral-wrapper {
	display: flex;
	color: #edeff3;
	font-size: 12px;
	margin-bottom: 2px;
}

.ageSexReferral {
	display: flex;
	flex-direction: row;
}

.itemColumn {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.patientDetailTopFieldValue {
	font-family: RionaSans-Medium, sans-serif;
}

.threeDotIconContainer-wrapper {
	width: 36px;
	height: 36px;
}

.threeDotIconContainer {
	width: 36px;
	height: 36px;
	border-radius: 6px;
	position: relative;
	bottom: 7px;
}

.threeDotIconContainer:hover,
.threeDotIconContainerActive {
	background-color: #edeff32d;
}

.threeDotIcon {
	z-index: 1;
	fill: #edeff3;
	position: relative;
	top: 4px;
	left: 4px;
}

.mid-dropbox {
	width: 100%;
	float: left;
	background-color: #fbfbfb;
	padding: 1vw 0;
	margin-bottom: 1.09vw;
}

.gender {
	font-size: 0.8vw;
	text-transform: none;
	padding-top: 0.2vw;
	color: #777777;
}

.email {
	font-size: 1vw;
	font-weight: normal;
	text-align: center;
}

.details_dob_gender_container {
	font-size: 1vw;
	margin-top: 5vw;
	font-weight: normal;
	display: flex;
}

.details_dob {
	float: left;
	width: 50%;
	display: flex;
}

.details_gender {
	float: right;
	display: flex;
}

.details_title {
	margin-right: 0.5vw;
}

.patient_demographic_details {
	padding: 1vw;
	padding-left: 1.5vw;
	display: grid;
	grid-template-columns: auto auto auto;
	font-size: 0.7vw;
	color: #777777;
	grid-row-gap: 1vw;
}

.EncounterList {
	min-width: calc(100% - 340px);
	height: 100%;
	background-color: white;
	border-top-left-radius: 6px;
}

.patient_encounter_list {
	width: 100%;
	height: 100%;
	background: white;
	padding-top: 15px;
	box-shadow: 0 3px 4px 1px rgb(0 0 0 / 14%);
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 5px;
}

.encounter_tabs {
	padding: 10px;
	font-size: 16px;
}

.encounter_tabs > div > div > span {
	height: 5px;
	padding-top: 2px;
}

.topMargin {
	margin-top: 28px;
}

.generalTabContainer {
	display: flex;
	flex-direction: column;
}

.consent {
	display: flex;
	flex-direction: row;
	margin: 16px 0;
}

.consentIcon {
	margin-right: 10px;
}

.consentText {
	font-size: 14px;
	font-family: RionaSans-Bold, sans-serif;
	height: 20px;
}

.consented {
	color: #00d65b;
}

.notConsented,
.authToRecontactRevokedText {
	color: #e74c3c;
}

.consent.consentToTextContainer {
	margin-top: 0;
	margin-bottom: 10px;
}

.consent.consentToTextContainer.notConsentedToText .consentIcon > div > svg > g > g {
	fill: #f1c40f;
}

.consent.consentToTextContainer.authToRecontactSkippedText .consentIcon > div > svg > path {
	fill: #fddc3f;
}

.consent.consentToTextContainer.authToRecontactNotOfferedText .consentIcon > div > svg > g > path {
	fill: #a0abb9;
}

.consent.consentToTextContainer .consentedToText {
	color: #00d65b;
}

.consent.consentToTextContainer .notConsentedToText,
.consent.consentToTextContainer .authToRecontactSkippedText {
	color: #f1c40f;
}

.consent.consentToTextContainer .authToRecontactSkippedText {
	margin-left: -2px;
}

.consent.consentToTextContainer.authToRecontactNotOfferedText {
	color: #a0abb9;
	margin-left: -2px;
}

.demographicGroupContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.demographicGroupContainer:not(:last-child) {
	margin-bottom: 40px;
}

.demographicGroupContainer.patient-info {
	margin-bottom: 0px;
}

.demographicItem {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	margin-bottom: 16px;
}

.patientSideBarAddressHeader {
	font-family: RionaSans-Bold, sans-serif;
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
	color: white;
	margin-bottom: 8px;
}

.demographicItemHeader {
	font-size: 12px;
	color: #d8dde4;
}

.demographicItemData {
	font-size: 14px;
	font-family: RionaSans-Bold, sans-serif;
	color: #d8dde4;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
}

.demographicGroupContainer.patient-info .demographicItemData {
	font-size: 12px;
	width: calc(100% - 1px);
}

.demographicItemIds {
	max-width: 146px;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.demographicItemNonIds {
	max-width: 138px;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.demographicItemTooltipText {
	font-family: RionaSans-Bold, sans-serif;
	text-overflow: ellipsis;
	max-width: 100%;
	overflow-x: hidden;
	white-space: nowrap;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
	border: 2px solid white;
	border-bottom-color: #00aeef;
	color: #00aeef;
	font-weight: bold;
}

.nav-tabs > li.disabled {
	opacity: 0.5;
}

.nav-tabs > li > a {
	font-size: 1vw;
}

.encounter_type {
	padding-left: 0.5vw;
	padding-top: 0.5vw;
	font-size: 2vw;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	color: #777777;
	display: flex;
}

.encounter_subinfo {
	font-size: 0.9vw;
	padding-left: 0.8vw;
	color: #777777;
	opacity: 0.8;
	padding-top: 1.2vw;
}

.encounter_fields {
	display: block;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #07afef;
	border-color: #30b5f0;
}

:not(#new-encounter-dropdown-container) > .btn-group-vertical > .btn,
:not(#new-encounter-dropdown-container) > .btn-group > .btn {
	font-size: 0.9vw !important;
	height: 1.5vw !important;
	line-height: 0.9vw !important;
	padding-top: 0.25vw !important;
}

ul.dropdown-menu {
	font-size: 0.9vw !important;
}

.menuItemIconActive > div > svg > g > path {
	fill: #7b26c9;
}

.menuItemIconWhite > div > svg > g > path {
	fill: #ffffff;
}

.mt-drop.saveButton {
	color: #40b9ec;
	border-color: #40b9ec;
}

.modal-body {
	padding: 1vw;
}

table.encounter_list.table.table-striped {
	max-height: 16vw !important;
	width: 93%;
	margin: 2vw;
}

.encounter_values {
	font-weight: bold;
	-webkit-font-smoothing: antialiased;

	/* commenting this because it is formatting time (am, pm) incorrectly. it is changing "am" to "Am" .
	it should either be both in lowercase or both in uppsercase */

	/* text-transform: capitalize; */
}

.react-phone-number-input__icon {
	width: 1vw !important;
	height: 1vw !important;
	border: none !important;
}

.react-phone-number-input__icon-image {
	width: 1.5vw;
	height: 1.5vw;
}

.has-feedback label ~ .form-control-feedback {
	top: 1.6vw;
	height: 2vw;
	width: 2vw;
	font-size: 0.92vw;
	line-height: 2vw;
}

label.control-label {
	font-weight: normal;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
	background-color: #59a7c5;
}

.patient_detail {
	width: calc(100% - 80px) !important;
	max-width: calc(100% - 80px) !important;
	overflow: hidden;
	margin-left: 70px !important;
	margin-top: 60px !important;
	min-height: calc(100vh - 75px);
	display: flex;
	flex-direction: row;
	background-color: #262837;
	border-top-left-radius: 5px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 5px;
}

li {
	font-family: RionaSans, serif !important;
}

.no_data_found {
	padding: 4vw;
	text-align: center;
	font-size: 14px;
	color: #b5b5b5;
}

.add_comment {
	margin-top: 2vw;
	width: 100%;
	display: flexbox;
	display: flex;
	padding: 0 1vw;
}

.add_comment > div > label {
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
}

.add_comment > div,
.add_comment > button {
	margin-top: 0 !important;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	height: 2.8vw;
	font-family: RionaSans, serif !important;
}

.add_comment > button {
	width: 10vw;
	margin-left: 2vw;
	text-transform: capitalize;
}

.encounter_tabs {
	padding: 0;
}

.PageHeader {
	z-index: 1;
}

.PatientsRecords .table-responsive {
	height: auto !important;
}

.lblhead {
	font-size: 1vw;
	color: #999999;
	margin-top: 0.8vw;
	float: left;
}

.mt-drop {
	font-size: 0.9vw !important;
	font-family: RionaSans, serif !important;
}

.radioinline {
	display: inline !important;
}

h5.pthead.pull-right {
	float: right;
	padding-top: 0.5vw;
}

input::placeholder {
	font-size: 1vw !important;
}

.margin-btm-0 {
	margin-bottom: 0;
}

.patient_records_table th {
	font-family: RionaSans, sans-serif !important;
	padding: 1vw !important;
	font-size: 0.85vw !important;
	color: #525968;
	text-align: left;
	flex-direction: inherit;
}

.sortableLabelpatientrecords.current_sort,
.sortableLabeldashboardencounters.current_sort {
	font-family: RionaSans-Bold, sans-serif;
	-webkit-font-smoothing: antialiased;
	color: #262837 !important;
}

.sortableLabelpatientrecords:hover,
.sortableLabeldashboardencounters:hover {
	font-family: RionaSans-Medium, sans-serif !important;
	color: #262837 !important;
}

.sortableLabelpatientrecords:hover > svg {
	opacity: 1;
}

.patient_records_table td {
	font-size: 0.8vw !important;
	padding: 1vw !important;
	font-family: RionaSans, sans-serif;
	color: #262837;
	border-bottom: none;
}

.patient_records_table th svg {
	width: 1vw !important;
	height: 1vw !important;
}

a.toggle_icon i.material-icons.expand_icons {
	width: 1vw;
	height: 0.5vw;
	font-size: 2vw;
	color: #7b26c9;
}

.phoneinput input {
	font: inherit;
	width: 100%;
	color: currentColor;
	border: 0;
	margin: 0;
	padding: 6px 0 7px;
	display: block;
	min-width: 0;
	box-sizing: content-box;
	background: none;
	-webkit-tap-highlight-color: transparent;
	font-size: 1vw;
}

hr.separator {
	border-top: 1px solid #dedede;
}

.placecolor input::input-placeholder {
	color: #666666 !important;
	opacity: 0.7;
}

.placecolor input:input-placeholder {
	color: #666666 !important;
	opacity: 0.7;
}

.MuiTypography-body1-99 {
	color: #666666 !important;
	opacity: 0.7;
}

.placecolor input:placeholder {
	color: #666666 !important;
	opacity: 0.7;
}

.placecolor input::placeholder {
	opacity: 0.7;
	color: #666666 !important;
}

.pthead {
	font-family: RionaSans, serif !important;
	font-weight: 600;
	margin: 0;
}

.create_encounter_dialog,
.create_encounter_dialog > h2,
.create_encounter_dialog > button {
	font-family: RionaSans, serif !important;
}

.create_encounter_dialog {
	z-index: 100000 !important;
}

h2.MuiTypography-root-90.MuiTypography-title-96 {
	font-family: RionaSans, serif !important;
}

.errortext,
p#component-error-text {
	font-size: 0.8vw !important;
	font-family: RionaSans, serif !important;
	color: #f44336;
}

.react-phone-number-input__input {
	border-bottom: 1px solid rgb(145 146 148) !important;
	background-color: transparent;
}

.react-phone-number-input__input:focus {
	border-bottom: 2px solid #3f51b5 !important;
}

.react-phone-number-input__input:hover {
	border-bottom: 2px solid rgb(8 8 8 / 80%) !important;
}

.react-phone-number-input__row {
	padding-top: 10px;
}

.color-white {
	color: #ffffff !important;
}

td.tablecell_expanded,
td.tablecell_expanded_header {
	border-bottom: none;
}

td.tablecell_expanded_header {
	color: #525968 !important;
}

tr.expanded_encounter_rows:hover,
tr.expanded_encounter_rows.last:hover {
	cursor: pointer;
}

table.patient_records_table > tbody > tr.expanded_encounter_rows,
table.patient_records_table > tbody > tr.expanded_encounter_rows.last,
table.patient_records_table > tbody > tr.expanded_encounter_header_rows {
	background-color: #ffffff;
	border-left: none;
	border-right: none;
}

tr.expanded_encounter_rows.last td {
	border-bottom: 1px solid #63345b;
}

i.material-icons.report_flag {
	color: crimson;
	font-size: 1.5vw;
}

.pattr {
	background-color: #ffffff !important;
}

.pattr:hover {
	background-color: #ffffff !important;
}

.ptcolor {
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 10vw;
}

.part-heading {
	margin-bottom: 0;
	color: #40b9ec !important;
	font-weight: 500;
	font-size: 1.14vw;
	padding-bottom: 0.7vw;
	margin-top: 1.6vw;
}

hr {
	margin: 0;
}

.pattr:hover .ptcolor {
	color: rgb(0 174 239);
}

.editbtn span.material-icons {
	font-size: 1.2vw;
}

.main-part {
	width: 95%;
	float: left;
}

.inputfont > div {
	font-size: 0.9vw !important;
}

.addressbox {
	border: 0.04vw dashed #e4e4e4;
	padding: 0.8vw 2vw;
	float: left;
	margin: 1vw 5vw 0 0;
	background-color: #f7f7f7;
}

span[class^="TabIndicator"] {
	height: 5px;
}

.noMinWidth {
	min-width: unset !important;
}

.outreachLogContainer {
	padding-top: 28px;
	display: flex;
	flex-direction: column;
}

.doNotOutreachMenuRow {
	height: 36px;
	width: 100%;
	margin-bottom: 28px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.doNotOutreachMenu {
	justify-self: flex-end;
	cursor: pointer;
}

.noOutreachText {
	color: #e74c3c;
}

.outreachHeaders {
	display: flex;
	flex-direction: row;
	font-size: 13px;
	color: #525968;
	border-bottom: 2px solid #d8dde4;
	padding-bottom: 5px;
}

.outreachRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	padding: 0 20px;
	width: 100%;
	height: 50px;
	position: relative;
}

.outreachRow:hover {
	background-color: #e9f8fe;
	cursor: pointer;
}

.noOutreachRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 20px;
	width: 100%;
	height: 50px;
	background-color: rgb(254 145 137 / 25%);
	border-bottom: 1px solid #d8dde4a1;
}

.noOutreachRowDate {
	font-family: RionaSans-Bold, sans-serif;
	font-size: 14px;
	color: #525968;
	margin-right: 135px;
}

.noOutreachRowMessage {
	font-family: RionaSans-Bold, sans-serif;
	font-size: 14px;
	color: #e74c3c;
	margin-right: 91px;
}

.noOutreachRowReason {
	font-size: 12px;
	color: #262837;
}

.rowBottomBound {
	border-bottom: 1px solid #d8dde4a1;
	padding-top: 4px;
	padding-bottom: 9px;
}

.outreachData,
.outreachActions {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.outreachActions {
	position: relative;
	top: 4px;
}

.test_field_detail .select-lab {
	display: flex;
	height: 32px;
	padding: 7px 8px;
}

.select-lab .lab-name {
	font: normal 13px/1.38 RionaSans, sans-serif;
}

.select-lab .lab-paper-trf {
	width: 48px;
	height: 14px;
	margin: 2px 0 2px auto;
	font: normal 10px/1.4 RionaSans, sans-serif;
}

.outreach_log_select:hover {
	background-color: #bac3ce !important;
}

.arrowIcon {
	position: relative;
	top: 5px;
}

.arrowIcon,
.outreachDate {
	margin-right: 40px;
}

.outreachData > .outreachInput {
	width: 162px !important;
	height: 32px !important;
}

.outreachData > .outreachInputLong {
	width: 284px !important;
	height: 32px !important;
}

.outreachInput > div > input.el-input__inner::placeholder,
.outreachInputLong > div > input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.outreachDate > .el-date-editor.el-input {
	width: 164px !important;
}

.el-form-item__content .el-date-editor input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.elitem__content .el-date-editor input.el-input__inner::-moz-placeholder {
	font-size: 13px !important;
}

.outreachMethodOption {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	color: #262837;
}

.outreachMethodHover:hover {
	background-color: #bac3ce;
}

.outreachMethodIcon {
	position: relative;
	bottom: 5px;
	right: 2px;
}

.outreachMethodOptionText {
	position: relative;
	left: 5px;
	bottom: 7px;
	font-size: 14px;
}

.saveExit {
	display: flex;
	flex-direction: row;
}

.uneditable {
	opacity: 0.35;
	cursor: not-allowed;
}

.whiteOverlay {
	width: calc(100vw - 808px);
	height: calc(100vh - 74px);
	min-height: 100%;
	background-color: white;
	opacity: 0.5;
	z-index: 1;
	position: absolute;
	border-radius: 6px;
}

.patientEditBottomFields {
	display: flex;
	flex-direction: column;
}

.patientEditBottomFieldsHeaders {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.patientEditBottomFieldsHeader {
	width: 50%;
	border-bottom: 1px solid #c6c6c6;
	color: #ffffff;
	font-size: 14px;
	font-family: RionaSans-Bold, sans-serif;
}

.patientEditBottomFieldsBody {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-top: 10px;
	margin-bottom: 32px;
}

.patientEditBottomFieldsBodyHalf {
	width: calc(50% - 1px);
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.patientEditBottomFieldsBodyHalfLoading > div:not(.patientEditBottomFieldsBodyHalfInsideLoading) {
	background-color: #262837 !important;
}

.patientEditBottomFieldsDivider {
	width: 1px;
	opacity: 0.25;
	background-color: #cfd7e4;
}

.patientEditInputTitle {
	font-size: 12px;
	color: #d8dde4;
	padding-left: 8px;
}

.invalidFields {
	color: red;
	position: absolute;
	top: -19px;
	width: 100%;
	text-align: center;
}

.patientEditTextInput {
	font-size: 13px;
	color: white;
	margin-right: 16px;
	margin-bottom: 20px;
	border-radius: 4px;
	border: solid 1px #7a8292;
	background-color: transparent;
	padding: 8px 0 6px 8px;
	transition: border-color 0.01s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.patientEditTextInputImmutable {
	background-color: rgb(158 171 186 / 25%);
	color: #d7dde5;
	opacity: 0.5;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: no-drop;
}

li.el-select-dropdown__item.hover.patientEditTextOption {
	background-color: #7b8291;
}

li.el-select-dropdown__item.patientEditTextOption {
	color: #ffffff;
	font-size: 13px !important;
}

.patientEditTextInput::placeholder {
	font-size: 13px !important;
	opacity: 0.5 !important;
	color: #ffffff !important;
}

.patientEditTextInput:hover {
	border: solid 1px #70cbf1 !important;
}

.patientEditTextInputImmutable:hover {
	border: 1px solid #7a8292 !important;
}

.patientEditTextInput:focus {
	border: solid 1.5px #40b9ec !important;
}

.errorInput::placeholder,
.errorDropdown > div.el-input > input.el-input__inner::placeholder {
	color: #fe5c50 !important;
	opacity: 1 !important;
}

.errorDropdown > div.el-input > i.el-input__icon {
	color: #ec6759 !important;
}

.errorDropdown > div.el-input > input.el-input__inner::placeholder {
	width: 146px !important;
}

.patientEditTextDropdown.multiselect > div.el-select__tags {
	top: unset !important;
	transform: unset !important;
	max-height: 12vh;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: rgb(0 136 204 / 80%) rgb(0 0 0 / 10%) !important;
}

/* There are a few polyfill attempts for scrollbar styling */

.patientEditTextDropdown.multiselect > div.el-select__tags > span.el-tag--primary {
	background-color: rgb(159 220 245 / 35%) !important;
	border: solid 1px #9fdcf5 !important;
	border-radius: 4px !important;
	color: #40b9ec !important;
	font-size: 11px !important;
}

.errorInput {
	border: 1px solid #fb3a2e !important;
	color: #fe5c50 !important;
	background-color: rgb(254 145 137 / 50%);
}

@media only screen and (max-height: 860px) {
	.patientEditTextDropdown.multiselect > div.el-select__tags {
		top: unset !important;
		transform: unset !important;
		max-height: 8vh;
		overflow-y: auto;
	}

	.patientEditTextDropdown.multiselect > div.el-input {
		max-height: 8vh;
		overflow-y: auto;
	}

	.patientEditTextDropdown.multiselect > div.el-input > input.el-input__inner {
		max-height: 8vh;
		overflow-y: auto;
	}
}

@media only screen and (min-height: 861px) and (max-height: 915px) {
	.patientEditTextDropdown.multiselect > div.el-select__tags {
		top: unset !important;
		transform: unset !important;
		max-height: 13vh;
		overflow-y: auto;
	}

	.patientEditTextDropdown.multiselect > div.el-input {
		max-height: 13vh;
		overflow-y: auto;
	}

	.patientEditTextDropdown.multiselect > div.el-input > input.el-input__inner {
		max-height: 13vh;
		overflow-y: auto;
	}
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-webkit-scrollbar {
	width: 4px;
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-moz-scrollbar {
	width: 4px;
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-o-scrollbar {
	width: 4px;
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-ms-scrollbar {
	width: 4px;
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-moz-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-o-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-ms-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-webkit-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-moz-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-o-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-ms-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-webkit-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-moz-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-o-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.patientEditTextDropdown.multiselect > div.el-select__tags::-ms-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-webkit-scrollbar {
	width: 4px;
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-moz-scrollbar {
	width: 4px;
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-o-scrollbar {
	width: 4px;
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-ms-scrollbar {
	width: 4px;
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-moz-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-o-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-ms-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-webkit-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-moz-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-o-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-ms-scrollbar-thumb {
	background: rgb(0 136 204 / 80%);
	box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-webkit-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-moz-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-o-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.patientEditTextDropdown > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__wrap::-ms-scrollbar-thumb:window-inactive {
	background: rgb(0 136 204 / 10%);
}

.cancelButton,
.saveButton {
	cursor: pointer;
	user-select: none;
}

.cancelButton {
	width: 66px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #bac3ce;
	color: #bac3ce;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	font-weight: 500;
	padding: 8px 11px;
}

.cancelButton:hover {
	background-color: rgb(186 195 206 / 15%);
}

.cancelButton:active {
	background-color: rgb(186 195 206 / 30%);
}

.saveButton {
	width: 54px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #40b9ec;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	font-weight: 500;
	color: #ffffff;
	padding: 9px 12px;
}

.saveButton:hover {
	background-color: #70cbf1;
}

.saveButton:active {
	background-color: #0f80b0;
}

.patientEditLoader > div {
	background-color: #262837 !important;
}

.patientEditLoader {
	z-index: 1 !important;
	height: 100%;
}

.Encounter-details-Copy-Host-icon {
	width: 11px;
	height: 13px;
	color: #40b9ec;
}

/* Encounter Details */

.appointmentMenuContainer {
	width: 52px;
	height: 36px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.appointmentMenuPlaceholderRow {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 2px 5px 1px 3px;
	border-radius: 6px;
	background-color: #ffffff;
	margin-left: 10px;
}

.encounterThreeDotMenu {
	height: 36px;
	width: 36px;
	border-radius: 4px;
	position: relative;
	bottom: 0;
	left: 5px;
}

.encounterThreeDotMenuOpen {
	background-color: rgb(216 216 216 / 40%);
}

.encounterThreeDotMenu > div > svg {
	position: relative;
	left: 4px;
	top: 3px;
}

.encounterThreeDotMenu:hover {
	background-color: rgb(216 216 216 / 40%);
}

.encounterThreeDotMenu:active {
	background-color: rgb(210 210 210);
}

.encounterThreeDotMenu > div > svg > g > g {
	fill: #262837;
}

.appointmentDropdown {
	width: 8px;
	height: 8px;
}

.appointmentMenuContainerClicked {
	border-radius: 6px;
}

.appointmentMenuOptionContainer {
	width: 200px;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 35%);
	position: absolute;
	top: 36px;
	right: 4px;
	z-index: 1;
	margin-top: 4px;
	background-color: white;
}

.appointmentMenuOption {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #262837;
	font-size: 13px;
	padding: 0 8px;
	height: 36px;
	width: 100%;
	background-color: #ffffff;
	cursor: pointer;
}

.appointmentMenuOption:first-child {
	margin-top: 4px;
}

.appointmentMenuOption:last-child {
	margin-bottom: 4px;
}

.appointmentMenuOptionDisabled {
	opacity: 0.35;
	cursor: no-drop;
}

.appointmentMenuOption:hover {
	background-color: #edeff3 !important;
}

.appointmentMenuOptionIcon {
	position: relative;
	top: 2px;
	margin-right: 4px;
	width: 28px;
}

.appointmentMenuOptionText {
	position: relative;
	top: 1px;
}

.appointmentAlertBackground {
	background-color: rgb(0 0 0 / 35%);
	height: 100vh;
	width: 100vw;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 10000;
}

.appointmentAlertContainer {
	opacity: 1;
	position: absolute;
	top: calc(50% - 150px);
	left: calc(50% - 200px);
	display: flex;
	flex-direction: column;
	width: 400px;
	height: fit-content;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgb(0 0 0 / 35%);
	background-color: #ffffff;
	padding: 8px 20px 20px;
}

.loadingAppointmentAlert > div {
	background-color: white !important;
}

.appointmentAlertTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: #262739;
	font-size: 16px;
	font-family: RionaSans-Bold, sans-serif;
	margin-bottom: 20px;
}

.appointmentAlertTextRow {
	width: 100%;
	color: #7a8292;
	font-size: 12px;
	padding-right: 48px;
	margin-bottom: 20px;
}

.patient_detail .chargeICDSearchResultRowCheckboxSelected > span.el-checkbox__input > span.el-checkbox__inner {
	border-color: #bfcbd9 !important;
}

.patient_detail .chargeICDSearchResultRowCheckboxSelected > span.el-checkbox__input.is-checked > span.el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.patient_detail .el-checkbox__input.is-checked .el-checkbox__inner {
	background-color: #7b26c9 !important;
}

.patient_detail .chargeICDSearchResultRowCheckboxSelected > span.el-checkbox__input > span.el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}

.appointmentAlertButtonRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.appointmentAlertKeepButton,
.appointmentCancelButton,
.appointmentNoShowButton {
	cursor: pointer;
}

.appointmentAlertKeepButton {
	width: 94px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #a0abb9;
	font-size: 13px;
	color: #a0abb9;
	padding: 9px 0 0 12px;
}

.appointmentAlertKeepButton:hover {
	background-color: rgb(123 130 145 / 15%);
}

.appointmentAlertKeepButton:active {
	background-color: rgb(123 130 145 / 30%);
}

.appointmentCancelButton {
	width: 145px;
	height: 36px;
	background-color: #e74c3c;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: white;
	font-size: 13px;
	padding: 3px 0 0 7px;
}

.appointmentAlertButtonIcon {
	margin-right: 9px;
}

.appointmentAlertCancelledByUserIcon > div > svg > g > path {
	fill: white;
}

.appointmentAlertCancelledByNoShowIcon > div > svg > g > path {
	fill: #262837;
}

.appointmentNoShowButton {
	width: 158px;
	height: 36px;
	background-color: #f1c40f;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #262837;
	font-size: 13px;
	padding: 3px 0 0 7px;
}

.testExitEditButton {
	width: 158px;
	height: 36px;
	background-color: #40b9ec;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #262837;
	font-size: 13px;
	cursor: pointer;
	padding: 3px 0 0 7px;
}

.testExitEditButton:hover {
	background-color: #70cbf1;
}

.testExitEditButton:active {
	background-color: #0f80b0;
}

.doNotOutreachAlertContainer {
	width: 336px;
	height: 332px;
	box-shadow: 0 2px 4px 0 rgba (0, 0, 0, 0.17);
	background-color: #ffffff;
	border-radius: 2px;
	display: flex;
	flex-direction: column;
	padding: 24px;
	position: absolute;
	top: calc(50% - 166px);
	left: calc(50% - 168px);
}

.updatedDoNotOutreachAlertContainer {
	width: 368px;
	box-shadow: 0 2px 4px 0 rgba (0, 0, 0, 0.17);
	background-color: #ffffff;
	border-radius: 2px;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 120px);
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.updatedDoNotOutreachAlertBody {
	overflow-y: auto;
	max-height: calc(100vh - 240px);
	padding-top: 30px;
}

.doNotOutreachAlertContainerTextRow {
	display: flex;
	flex-direction: column;
	font-size: 20px;
	color: #262838;
	margin-bottom: 28px;
}

.updatedDoNotOutreachAlertContainerTextRow {
	display: flex;
	flex-direction: row;
	font-size: 16px;
	font-family: RionaSans-Bold, sans-serif;
	color: #262837;
	height: 56px;
	border-bottom: 2px solid #edeff359;
	align-items: center;
	padding: 16px;
}

.updatedOutreachAlertIcon {
	position: relative;
	transform: scale(1.3);
	margin-right: 14px;
	top: 5px;
}

.doNotOutreachAlertContainerTextRowBottom {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #e74c3c;
	position: relative;
	bottom: 3px;
	font-family: RionaSans-Bold, sans-serif;
}

.doNotOutreachAlertInputContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 21px;
}

.updatedDoNotOutreachAlertInputContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;
	padding: 0 16px;
	min-height: 340px;
}

.doNotOutreachAlertInputFieldContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	margin-bottom: 16px;
}

.disable-do-not-outreach-patient-name {
	color: #262837;
	font-family: RionaSans-Bold, sans-serif;
}

.updatedDoNotOutreachAlertInputFieldRow > div.relative-section > i.el-icon-date {
	top: 8px;
}

.doNotOutreachAlertInputFieldHeader {
	font-size: 12px;
	color: #525968;
}

.doNotOutreachAlertInputFieldRequired {
	position: absolute;
	color: red;
	left: -8px;
	top: 29px;
}

.updatedDoNotOutreachAlertInputFieldRequired {
	position: absolute;
	color: red;
	left: -8px;
	top: -1px;
}

.doNotOutreachAlertInputField {
	width: 100%;
}

.updatedDoNotOutreachAlertInputField {
	height: 32px;
	margin-bottom: 14px;
}

.updatedDoNotOutreachAlertInputField > div.el-input > input {
	height: 32px !important;
	font-size: 13px !important;
}

.updatedDoNotOutreachAlertInputField > div.el-input > input::placeholder {
	font-size: 13px !important;
}

.updatedDoNotOutreachAlertInputField > input,
.updatedDoNotOutreachAlertInputField > div.rdt > input {
	height: 32px !important;
	font-size: 13px !important;
	font-family: RionaSans, sans-serif !important;
	color: #262837 !important;
	border-radius: 4px !important;
	border: 1px solid #bac3ce !important;
	background-color: white !important;
	padding-top: 7px !important;
	padding-bottom: 7px !important;
	padding-left: 8px !important;
}

.updatedDoNotOutreachAlertInputField > div.rdt > input::placeholder {
	color: #97a8be !important;
	font-size: 13px !important;
}

.updatedDoNotOutreachAlertInputField > input::before,
.updatedDoNotOutreachAlertInputField > div.rdt::before {
	content: none !important;
}

.doNotOutreachAlertInputField > div.el-date-editor {
	width: 100%;
}

.doNotOutreachAlertButtonRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.updatedDoNotOutreachAlertButtonRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 60px;
	padding: 12px 16px;
	border-top: 1.5px solid #edeff359;
	width: 100%;
}

.doNotOutreachAlertButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 6px;
	height: 36px;
	cursor: pointer;
	font-size: 13px;
}

.doNotOutreachAlertButtonCancel {
	text-align: center;
	background-color: #ffffff;
	border: 1px solid #a0abb9;
	color: #a0abb9;
	width: 70px;
	padding-left: 13px;
}

.updatedDoNotOutreachAlertButtonCancel {
	height: 36px;
	width: 160px;
	border-radius: 6.5px;
	border: 1px solid #e74c3c;
	background-color: white;
	text-align: center;
	user-select: none;
	display: grid;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	color: #e74c3c;
	margin-right: 16px;
}

.updatedDoNotOutreachAlertButtonCancel:hover {
	background-color: rgb(231 76 60 / 15%);
}

.updatedDoNotOutreachAlertButtonCancel:active {
	background-color: rgb(231 76 60 / 30%);
}

.cancel-disabled-do-not-outreach {
	height: 36px;
	width: 160px;
	border-radius: 6.5px;
	border: 1px solid #7b26c9;
	background-color: white;
	text-align: center;
	user-select: none;
	display: grid;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	color: #7b26c9;
	margin-right: 16px;
}

.cancel-disabled-do-not-outreach:hover {
	background-color: rgb(123 38 201 / 15%);
}

.cancel-disabled-do-not-outreach:active {
	background-color: rgb(123 38 201 / 30%);
}

.updatedDoNotOutreachAlertButtonSave {
	height: 36px;
	width: 160px;
	border-radius: 6.5px;
	border: 1px solid #e74c3c;
	background-color: #e74c3c;
	text-align: center;
	user-select: none;
	display: grid;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	color: white;
}

.updatedDoNotOutreachAlertButtonSave:hover {
	background-color: #ec6759;
}

.updatedDoNotOutreachAlertButtonSave:active {
	background-color: #ad1505;
}

.updatedDoNotOutreachAlertButtonSaveDisabled {
	opacity: 0.35 !important;
	cursor: no-drop !important;
	background-color: #e74c3c !important;
}

.disabledDoNotOutreachAlertButtonSaveDisabled {
	opacity: 0.35 !important;
	cursor: no-drop !important;
	background-color: #7b26c9 !important;
}

.save-disabled-do-not-outreach {
	height: 36px;
	width: 160px;
	border-radius: 6.5px;
	border: 1px solid #7b26c9;
	background-color: #7b26c9;
	text-align: center;
	user-select: none;
	display: grid;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	color: white;
}

.save-disabled-do-not-outreach:hover {
	background-color: #9c5dd7;
}

.save-disabled-do-not-outreach:active {
	background-color: #621ea0;
}

.do-not-outreach.black-variant-item {
	height: 40px !important;
}

.doNotOutreachAlertButtonDisable {
	background-color: #e74c3c;
	color: white;
	width: 182px;
	padding-left: 12px;
}

.doNotOutreachAlertButtonDisable > div > div > svg > g > path {
	fill: white;
}

.doNotOutreachErrorMessage {
	position: absolute;
	font-size: 13px;
	color: red;
	top: 2px;
	left: 91px;
}

.initialOutreachAlertContainer {
	height: 216px;
	width: 368px;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgb(0 0 0 / 35%);
	background-color: white;
	padding: 16px 0 12px;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: calc(50% - 184px);
	top: calc(50% - 108px);
}

.initialOutreachAlertTopRow {
	display: flex;
	flex-direction: row;
	color: #262837;
	font-size: 16px;
	font-family: RionaSans-Bold, sans-serif;
	margin-bottom: 32px;
	height: 40px;
	border-bottom: 1px solid #edeff3;
	width: 100%;
	padding: 0 20px;
}

.initialOutreachAlertMessage {
	color: #262837;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	margin-bottom: 32px;
	line-height: 18px;
	width: 100%;
	padding: 0 20px;
}

.initialOutreachAlertButtonRow {
	width: 100%;
	height: 48px;
	padding: 12px 16px 0;
	border-top: 1px solid #edeff3;
}

.initialOutreachAlertButton {
	width: 100%;
	height: 36px;
	background-color: #7b26c9;
	cursor: pointer;
	text-align: center;
	padding-top: 18px;
	border-radius: 6px;
	color: #ffffff;
	font-size: 13px;
}

.initialOutreachAlertButton:hover {
	background-color: #9c5dd7;
}

.initialOutreachAlertButton:active {
	background-color: #621ea0;
}

.patient-enctr-detail-edit {
	cursor: pointer;
	margin-top: 3px;
}

.patient_info_tabs {
	height: 49px;
}

.patient_info_tabs > .el-tabs__header {
	height: 49px;
	padding: 0 21px;
}

.patient_info_tabs > .el-tabs__header > .el-tabs__nav-wrap {
	padding: 3px 28px 0;
}

.patient_info_tabs > .el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
	height: 48px;
}

.patient_info_tabs > .el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll > .el-tabs__nav {
	padding: 0 4px;
}

.patient_info_tabs > .el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll > .el-tabs__nav > .el-tabs__item {
	padding: 0 19px;
}

.patient_info_tabs > .el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll > .el-tabs__nav > .el-tabs__item > .tab_labels {
	font-size: 14px;
}

.el-tabs__nav > .el-tabs__item {
	color: #525969;
}

.el-tabs__nav > .el-tabs__item.is-active {
	color: #7b26c9 !important;
	font-family: RionaSans-Bold, sans-serif;
}

.el-tabs__nav > .el-tabs__item.is-disabled {
	cursor: no-drop !important;
	color: #bbbbbb !important;
}

.patient_info_tabs > .el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll > .el-tabs__nav > .el-tabs__active-bar {
	background-color: #7b26c9 !important;
	bottom: -3px !important;
	height: 5px;
}

.patient_sidebar_tabs > .el-tabs__header > .el-tabs__nav-wrap.is-scrollable {
	padding: 0 !important;
}

.patient_sidebar_tabs > .el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll > .el-tabs__nav {
	width: 100% !important;
}

.patient_sidebar_tabs > .el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll > .el-tabs__nav > .el-tabs__item {
	padding: 0 17px !important;
	min-width: 98px;
}

.el-tabs__item > .sidebar_tab_labels {
	font-size: 14px !important;
	font-family: RionaSans, sans-serif !important;
	opacity: 0.76 !important;
	color: white !important;
	-webkit-font-smoothing: antialiased;
	padding: 0 5px !important;
}

.el-tabs__item.is-active > .sidebar_tab_labels {
	font-family: RionaSans-Bold, sans-serif !important;
	opacity: 1 !important;
}

/* -------------------media----------------------- */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.errp {
		margin-top: 0.7vw !important;
		margin-bottom: 0.7vw !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
	.multiselect-drop hr {
		height: 1.68em;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.table-responsive > .table > tbody > tr > td {
		font-size: 1.4vw !important;
	}

	.table-responsive th {
		font-size: 1.4vw !important;
	}

	.datepick input {
		font-size: 1.5vw !important;
	}

	.tab-pr-0 {
		padding-right: 0;
	}

	.droplbl {
		margin-top: 0.9vw !important;
	}

	.encounter_fields > .lblbox {
		padding-top: 0.8vw;
		padding-bottom: 0.8vw;
	}

	.errp {
		margin-top: 1vw !important;
		margin-bottom: 1vw !important;
	}

	.addressbox {
		width: 100%;
	}

	.editbtn span.material-icons {
		font-size: 1.8vw;
	}

	.react-phone-number-input__phone:focus {
		border-bottom: 2px solid #03b2cb !important;
	}

	.errortext {
		font-size: 1.3vw !important;
	}

	input::placeholder {
		font-size: 1.4vw !important;
	}

	.patient_records_table th {
		font-size: 1.3vw !important;
	}

	.patient_records_table th svg {
		width: 1.5vw !important;
		height: 1.5vw !important;
	}

	.patient_records_table td {
		font-size: 1.3vw !important;
	}

	.lblhead,
	.text-drop,
	.mt-drop,
	.part-heading {
		font-size: 1.5vw !important;
	}

	.patients_details {
		margin-top: 5vw;
		margin-bottom: 3vw;
	}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.list-line {
		margin-top: 0.7vw;
		margin-bottom: 0.7vw;
		border: 0;
	}

	.timezonebox {
		margin-left: 0 !important;
	}

	.mob100 {
		width: 100%;
	}

	.encounter_fields > .lblbox {
		padding-top: 1.2vw;
		padding-bottom: 1.2vw;
	}

	.top-mobi {
		margin-top: 13vh !important;
	}

	.patients_list_wrapper {
		margin-left: 16vw;
		width: 100%;
	}

	.errp {
		margin-top: 1vw !important;
		margin-bottom: 1vw !important;
	}

	.add_comment {
		margin-top: 5vw;
	}

	.react-phone-number-input__icon {
		width: 3.1vw !important;
		height: 3.1vw !important;
	}

	.mob-pad-r0 {
		padding-right: 0;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.btntext {
		font-size: 1.8vw !important;
	}

	.datepick input {
		font-size: 2.3vw !important;
	}

	.mob-pad-0 {
		padding: 0;
	}

	.addressbox {
		width: 100%;
	}

	.editbtn span.material-icons {
		font-size: 3vw;
	}

	.errortext {
		font-size: 1.8vw !important;
	}

	i.material-icons.profile_picture_placeholder {
		padding-top: 1.8vw;
		color: #583752;
		font-size: 3.2vw;
	}

	.add_comment > div > label {
		font-size: 1.6vw;
	}

	.no_data_found {
		font-size: 1.5vw;
	}

	.patient_detail {
		margin-top: 10vw;
		margin-left: 16vw !important;
		width: 83%;
	}

	.profile_pic_wrapper {
		width: 7vw;
		height: 7vw;
	}

	input#PageHeader-search-1 {
		font-size: 2.3vw !important;
	}

	i.material-icons.search {
		font-size: 3vw !important;
	}

	i.material-icons.logged_in_user {
		font-size: 3vw !important;
	}

	.patients_title_count {
		font-size: 2vw !important;
	}

	.table-responsive > .table > tbody > tr > td {
		font-size: 1.9vw !important;
	}

	.name,
	.gender {
		font-size: 2vw;
	}

	.patient_demographic_details {
		padding-left: 2.5vw;
	}

	.encounter_list th {
		font-size: 1.9vw !important;
	}

	.tab_labels {
		font-size: 2vw;
	}

	.backtext {
		font-size: 2vw !important;
	}

	.labtext {
		font-size: 3vw !important;
	}

	.encounter_subinfo {
		font-size: 2vw !important;
	}

	.encounter_values {
		font-size: 1.8vw !important;
	}

	.patients_list_wrapper {
		margin-left: 16vw;
		width: 100%;
	}

	.lblhead,
	.text-drop,
	.mt-drop,
	.part-heading {
		font-size: 2.3vw !important;
	}

	.mb-center {
		text-align: left !important;
		width: 100%;
	}

	.pthead {
		font-size: 2vw !important;
		float: left !important;
	}

	input::placeholder {
		font-size: 2.2vw !important;
	}
}

@media only screen and (min-width: 2000px) {
	.back_icon > i.material-icons {
		font-size: 1vw;
		padding-top: 0.5vw;
	}

	.text-drop {
		font-size: 0.6vw !important;
	}
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.datepick input {
		font-size: 3vw !important;
	}

	.mob-pad-0 {
		padding: 0;
	}

	.addressbox {
		width: 100%;
	}

	.editbtn span.material-icons {
		font-size: 4.2vw;
	}

	.pthead {
		font-size: 2.8vw !important;
		float: left !important;
	}

	.errortext {
		font-size: 2.3vw !important;
	}

	.react-phone-number-input__icon {
		width: 3.1vw !important;
		height: 3.1vw !important;
	}

	input {
		font-size: 3.1vw !important;
	}

	input::placeholder {
		font-size: 3vw !important;
	}

	.table-responsive > .table > tbody > tr > td {
		font-size: 2.9vw !important;
	}

	.profile_pic_wrapper {
		width: 9vw;
		height: 9vw;
	}

	.patient_detail {
		margin-top: 10vw;
		margin-left: 16vw !important;
		width: 83%;
		padding: 0 1vw;
	}

	.name,
	.gender {
		font-size: 3vw;
	}

	.name_dob {
		margin-top: 1.2vh;
	}

	.tab_labels {
		font-size: 1.4vh;
	}

	.no_data_found {
		font-size: 2.9vw;
	}

	.encounter_list th {
		font-size: 2.5vw !important;
	}

	i.material-icons.profile_picture_placeholder {
		padding-top: 2vw;
		font-size: 5.2vw;
	}

	.encounter_values {
		font-size: 2.7vw !important;
	}

	.encounter_subinfo {
		font-size: 2.9vw !important;
		padding-top: 2.2vw;
	}

	.encounter_fields {
		padding: 2vw;
		grid-template-columns: inherit;
	}

	.lblbox {
		width: 100%;
		float: left;
		border-bottom: 0.2vw solid #eaeaea;
	}

	.backtext {
		font-size: 3vw !important;
		margin-top: 1vw !important;
	}

	.labtext {
		font-size: 3.8vw !important;
	}

	.patient_demographic_details {
		padding-left: 2.5vw;
	}

	.add_comment > div > label {
		font-size: 2.5vw;
	}

	.add_comment {
		margin-top: 6vw;
	}

	.lblhead,
	.MuiTypography-colorTextSecondary-101,
	.part-heading {
		font-size: 3.1vw !important;
	}

	.mob-icon {
		font-size: 4.5vw;
		margin-top: 1.2vw;
	}

	.mb-center {
		text-align: left !important;
		width: 100%;
	}
}

.pat_next_back_buttons button {
	min-height: auto !important;
}

.soc_patient_toggle_disabled button[disabled] {
	background: #cdcdcd !important;
}

.patient-action-buttons button {
	padding: 10px !important;
	min-width: 36px !important;
}

.upcoming-icon {
	font-size: 14px;
	color: #7b26c9;
	float: left;
}

.upcoming-label {
	font-size: 10px;
	color: #7b26c9;
	float: left;
	font-weight: bold;
	margin-top: 1px;
	margin-left: 3px;
}

#delete-tooltip div {
	font-size: 12px;
	font-family: RionaSans, serif !important;
}

.cascader-tooltip {
	position: relative;
	display: inline-block;
}

.cascader-tooltip .cascader-tooltip-text {
	visibility: hidden;
	background-color: black;
	color: #ffffff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 10px;
	position: absolute;
	z-index: 1111111;
	bottom: 100%;
	left: 30%;
	margin-left: -60px;
	font-size: 12px;
}

.cascader-tooltip:hover .cascader-tooltip-text {
	visibility: visible;
}

.el-cascader-menus {
	z-index: 9999999 !important;
}

.patientActionNoOutreachIcon > div > svg > g > path {
	fill: white;
}

.confirm-img-warning {
	margin-right: 4px;
	vertical-align: text-top;
}

/**
 * Start: patient notes tab
 */

/**** Add Note ****/

.add-notes-container {
	position: relative;
	padding: 16px 28px 20px;
}

.add-notes-container-expanded {
	padding-bottom: 32px;
}

#txt-add-comment {
	background: transparent url("../../assets/edit.png") no-repeat 16px 20px;
	width: 100%;
	font: normal 14px RionaSans, sans-serif;
	color: #7b8291;
	border: 2px solid #edeff3;
}

.collapse-add-comment textarea {
	max-height: 60px;
	height: 60px;
	padding: 20px 24px 16px 40px;
}

.expand-add-comment textarea {
	min-height: 98px;
	padding: 20px 24px 30px 40px;
	margin-bottom: 18px;
}

.note-type-button-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.note-type-options {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.note-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.btn-cancel-comment {
	width: 66px;
	height: 36px;
	border-radius: 6px;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	letter-spacing: normal;
	color: #bac3ce;
	border: 1px solid #bac3ce;
	background-color: transparent;
	display: none;
	margin-right: 30px;
}

.btn-cancel-comment:hover {
	background-color: rgb(186 195 206 / 15%);
}

.btn-cancel-comment:active {
	background-color: rgb(186 195 206 / 30%);
}

#btn-add-comment {
	width: 69px;
	height: 36px;
	border-radius: 6px;
	background-color: #7b26c9;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #ffffff;
	border: none;
	display: none;
}

#btn-add-comment:hover {
	background-color: #9c5dd7;
}

#btn-add-comment:active {
	background-color: #621ea0;
}

/**** Note List ****/

.patient_notes_tabs > .el-tabs__header {
	border-bottom: none;
}

.patient_notes_tabs > .el-tabs__header .el-tabs__item {
	font-size: 12px;
	font-family: RionaSans, sans-serif;
	color: #525968;
	-webkit-font-smoothing: antialiased;
	padding: 0 21px;
}

.patient_notes_tabs > .el-tabs__header .el-tabs__nav > .el-tabs__active-bar {
	background-color: #7b26c9;
}

.note-list-container {
	max-width: 100%;
	padding: 0 28px 36px;
	background-color: #edeff3;
	min-height: 700px;
}

.note-list-title {
	font: normal bold 12px RionaSans-Bold, sans-serif;
	color: #51596a;
	padding: 5px 2px 0;
}

.note-list-card {
	width: 100%;
	padding: 20px 24px 24px;
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: #ffffff;
	display: flex;
	word-break: break-word;
	margin-bottom: 4px;
}

.note-list-card:last-child {
	margin-bottom: 0;
}

.note-meta-data {
	width: 200px;
	text-align: right;
}

.note-detail {
	width: 72%;
	min-width: 536px;
	padding-left: 28px;
	font: normal 300 14px RionaSans, sans-serif;
	color: #262739;
}

.note-list-card .note-email {
	display: block;
	min-height: 17px;
	font: normal 500 12px RionaSans-Medium, sans-serif;
	color: #00bbf1;
	margin-bottom: 4px;
}

.note-list-card .note-date {
	display: inline-block;
	height: 18px;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #262739;
}

.note-list-card .note-time {
	display: block;
	height: 17px;
	font: normal 500 12px RionaSans-Medium, sans-serif;
	color: #262739;
}

.note-detail .read-more {
	display: block;
	margin-top: 12px;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #7a8293;
	cursor: pointer;
	width: 92px;
}

.patientInfoTab > .add-notes-container > .notes-error {
	font-size: 0.8vw !important;
	font-family: RionaSans, serif !important;
	padding-left: 0 !important;
	position: absolute;
	bottom: -3px;
}

/*
 *  End: patient notes tab
 */

/* override style for deceased patient dialog */

.deceased-dialog {
	width: 400px !important;
	border-radius: 5px !important;
	box-shadow: 1px 2px 6px 1px rgb(0 0 0 / 35%) !important;
	padding: 20px;
}

.deceased-dialog .el-dialog__headerbtn {
	display: none;
}

.deceased-dialog .el-dialog__header {
	padding: 0 0 20px;
}

.deceased-dialog .el-dialog__title {
	font: normal bold 16px RionaSans-Bold, sans-serif;
	color: #26273a;
}

.deceased-dialog .el-dialog__body {
	padding: 0;
	font-size: 12px;
	color: #7a8292;
}

.deceased-dialog .el-dialog__footer {
	padding: 28px 0 0;
	text-align: start;
}

.deceased-dialog button {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	text-transform: capitalize;
	border-radius: 6px;
	padding-top: 4px;
	padding-bottom: 4px;
	min-height: 36px;
}

.deceased-dialog button:first-child {
	padding-left: 12px;
	padding-right: 12px;
	border: 1px solid #7b8291;
	color: #7b8291;
}

.deceased-dialog button:last-child {
	width: 197px;
	background-color: #e74c3c;
	color: #ffffff;
	float: right;
}

.btn-deceased-cancel:hover {
	text-decoration: none;
	background-color: rgb(0 0 0 / 8%);
}

.btn-deceased:hover {
	border-color: none;
	cursor: pointer;
}

.btn-deceased {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.btn-deceased > span {
	margin-left: 12px;
	display: flex;
}

.btn-deceased-icon {
	position: relative;
	top: 0;
	right: 10px;
	width: 22px;
	height: 22px;
}

.btn-deceased-text {
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-deceased-icon > div {
	width: 22px;
	height: 22px;
}

.patient_info .hp-patinent-info {
	position: relative;
}

.patient_info .hp-patinent-info .high-priority {
	position: absolute;
	left: -28px;
	top: 4px;
}

/* start: override element react style */

.patientEditInputTitle .tooltip-email {
	margin-left: 4px;
	margin-top: -2px;
	display: inline-block;
	vertical-align: middle;
}

.patientEditInputTitle .el-tooltip .more-info {
	width: 12px;
	height: 12px;
}

.patientEditInputTitle .el-tooltip__popper.is-dark {
	background-color: #191919 !important;
	height: 24px !important;
	padding: 6px 10px !important;
	top: 75px !important;
	left: 15% !important;
}

.patientEditInputTitle .popper__arrow,
.patientEditInputTitle .el-tooltip__popper[x-placement^="top"] .popper__arrow::after {
	border-top-color: #191919 !important;
}

.patient_encounter_list .encounter-tabs-button button {
	min-width: auto !important;
}

/* end: override element react style */

.patient_encounter_list .opacity-one {
	opacity: 1 !important;
}

.patientInfoBoxContainer {
	width: 100%;
	border-radius: 4px;
	background-color: #525968;
	display: flex;
	flex-direction: row;
	padding: 8px;
	margin-bottom: 8px;
	margin-top: 8px;
	overflow: hidden;
}

.patientInfoBoxIconContainer {
	width: 24px;
	margin-right: 8px;
	height: 25px;
}

.patientInfoBoxTextContainer {
	display: flex;
	flex-direction: column;
	width: calc(100% - 32px);
}

.patientInfoBoxData {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.patientInfoBoxData:nth-child(2) {
	padding-top: 7px;
}

.patientInfoBoxTag {
	height: 24px;
	border-radius: 24px;
	text-align: center;
	font-size: 13px;
	font-family: RionaSans-Bold, sans-serif;
	color: #00394b;
	-webkit-font-smoothing: antialiased;
	margin-bottom: 8px;
	padding: 3px 12px;
	width: fit-content;
	margin-right: 4px;
	line-height: 1.4;
}

.patientInfoBoxTagContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.patientInfoBoxDropdown {
	cursor: pointer;
	transition: transform 0.5s;
	height: 20px;
	position: relative;
	top: 1px;
	right: 5px;
}

.patientInfoBoxDropdown > div > svg > g > g {
	fill: #edeff3 !important;
}

.patientInfoBoxTagRow {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.patientInfoBoxIcon > div > svg > path:nth-child(2) {
	fill: #edeff3 !important;
}

.patientInfoBoxTextDemographicInfoContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.patientInfoBoxTextDemographicInfoRow {
	font-family: RionaSans, sans-serif;
	color: #edeff3;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-direction: row;
	white-space: pre;
	max-width: 233px;
}

.patientInfoBoxTextDemographicInfoRow.alert_dob {
	color: #e74c3c;
	margin-right: 4px;
}

.readonly-test-info {
	opacity: 0.45;
	pointer-events: none;
}

/******************************************************************
			Send Auth To Recontact Dialog
*******************************************************************/

.send-auth-to-recontact-dialog {
	width: 369px !important;
	height: auto !important;
	position: fixed !important;
	max-height: calc(100vh - 120px);
	top: 25% !important;
	left: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgb(0 0 0 / 35%) !important;
}

.send-auth-to-recontact-dialog .el-dialog__header {
	padding: 16px 16px 17px;
}

.send-auth-to-recontact-dialog .el-dialog__headerbtn {
	color: #525968;
}

.send-auth-to-recontact-dialog .loader-send-auth-recontact {
	position: static !important;
}

.send-auth-to-recontact-dialog .el-dialog__headerbtn:hover .el-dialog__close {
	color: #525968;
}

.send-auth-to-recontact-dialog .el-dialog__title {
	font: normal bold 16px RionaSans-Bold, sans-serif;
	color: #26273a;
}

.send-auth-to-recontact-dialog .el-dialog__body {
	min-height: 105px;
	padding: 16px;
	font-size: 12px;
	color: #525968;
	border: 1px solid #edeff3;
}

.send-auth-to-recontact-dialog .el-dialog__footer {
	display: flex;
	justify-content: space-between;
	padding: 12px 16px;
}

.btn-send-auth-to-recontact-cancel {
	border-radius: 6px !important;
	padding: 7px 50px !important;
	border: 1px solid #5b43bd !important;
	color: #5b43bd !important;
	font: normal 16px/19px 'RionaSans-Medium' !important;
}

.btn-send-auth-to-recontact-confirm {
	border-radius: 6px !important;
	padding: 7px 50px !important;
	background-color: #5b43bd !important;
	color: #ffffff !important;
	font: normal 16px/19px 'RionaSans-Medium' !important;
}

.btn-send-auth-to-recontact-cancel:hover {
	text-decoration: none;
	background-color: rgb(0 0 0 / 8%);
}

.btn-send-auth-to-recontact-confirm:hover {
	border-color: #5b43bd !important;
	cursor: pointer;
}
