.testAuthContainer {
	margin-left: 61px;
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 90vh;
	padding: 16px 0 0 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.testAuthFilterActionRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 16px;
}

.testauth-filter-container {
	display: flex;
}

@media screen and (max-width: 1380px) {
	.testAuthApproveSelectedButton span {
		font-size: 0.9vw !important;
	}
	.testauth-filter-container .selectPillContainer {
		max-width: 150px !important;
		font-size: 0.9vw !important;
	}
}

.testAuthApproveSelectedButton {
	background-color: #00a044;
	border-radius: 6.5px;
	width: fit-content;
	height: 36px;
	padding: 9px 12px;
	display: flex;
	flex-direction: row;
	user-select: none;
	color: white;
	font: normal 500 13px/1.38 RionaSans-Medium;
	cursor: pointer;
}

.testAuthApproveSelectedButton:hover {
	background-color: #61e097;
}

.testAuthApproveSelectedButton:active {
	background-color: #00a044;
}

.testAuthApproveSelectedButton.disabled {
	opacity: 0.5;
	cursor: no-drop;
}

.testAuthApproveSelectedButton.disabled span {
	pointer-events: none;
}

.testAuthApproveSelectedButton.disabled:hover {
	background-color: #00a044;
}

.testAuthPatientSearch {
	display: flex;
	flex-direction: row;
	width: 200px;
	height: 36px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: white;
	font-size: 13px;
}

.testAuthPatientSearchIcon {
	transform: scale(0.85);
	position: relative;
	top: 2px;
	left: 2px;
	margin-right: 5px;
}

.testAuthPatientSearchIcon > div > svg > path {
	fill: #bac3ce;
}

.testAuthPatientSearchInput {
	border: none;
	width: 160px;
	font-size: 13px;
}

.testAuthPatientSearchInput::placeholder {
	font-size: 13px !important;
	color: #bac3ce;
}

.testAuthTableSpinner {
	position: absolute !important;
	left: calc(50vw - 15px);
	top: calc(50vh - 20px);
}

.testAuthTableHeaderRow {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 12px 18px 8px 18px;
	height: 40px;
	color: #262837;
	font-size: 14px;
	font-family: 'RionaSans-Medium';
}

.testAuthSortableField {
	cursor: pointer;
	display: flex;
	flex-direction: row;
}

.testAuthSortableField:hover {
	color: #262837 !important;
}

.testAuthSortableFieldIcon {
	position: relative;
	top: 0px;
	left: 4px;
}

.testAuthSortableFieldIcon > div > svg > g > g > path {
	fill: #262837;
}

.testAuthSortableFieldIconAsc {
	transform: rotate(180deg);
	top: -1px;
}

.testAuthSortableFieldIconDesc {
	/* no rotation */
	top: 0px;
}

.testAuthCheckboxCell {
	width: 47px;
	margin-right: 20px;
}

.testAuthDateCell {
	min-width: 120px;
	width: 8%;
	margin-right: 20px;
}

.testAuthNameCell {
	min-width: 120px;
	width: 10%;
	margin-right: 20px;
}

.testAuthPDFCell {
	min-width: 24px !important;
	width: 24px !important;
}

.testAuthPDFCell .pdfButton {
	cursor: pointer;
	margin-right: 10px;
}

.testAuthTestCell {
	min-width: 250px;
	width: 19%;
	margin-right: 20px;
}

.testAuthFlagCell {
	min-width: 180px;
	width: 20%;
	margin-right: 20px;
}

.testAuthStatusCell {
	min-width: 145px;
	width: 15%;
	text-align: right !important;
}

.testAuthCTACell {
	min-width: 186px;
	width: 19%;
}

.testAuthNameCell .patient-name-state-wrapper {
	display: flex;
}

.testAuthNameCell .patient-name-state-separator {
	margin: 0 4px;
}

.testAuthNameCell
	.patient-name-state-wrapper
	.tt-patient-shipping-address
	.el-tooltip__popper.is-dark {
	width: auto !important;
}

.testAuthTableEmptyBodyContainer {
	width: 100%;
	height: 576px;
	background-color: white;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
	border-radius: 4px;
	padding-top: 72px;
}

.testAuthTableEmptyBodyMessage {
	font-family: 'RionaSans-RegularItalic';
	font-size: 14px;
	color: #bac3ce;
	text-align: center;
	width: 100%;
	-webkit-font-smoothing: antialiased;
}

.testAuthTableBodyContainer {
	width: 100%;
	min-width: 1068px;
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 1px 3px 0px;
	border-radius: 5px;
}

.testAuthTableRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	border-top: 1px solid #edeff3;
	min-height: 72px;
	padding: 0px 18px;
	cursor: pointer;
}

.testAuthTableRow:hover {
	background-color: #ecf8fd;
}

.testAuthTableRow:active {
	background-color: #d9f1fb;
}

.testAuthTableRow:nth-child(1) {
	border-top: none !important;
}

.testAuthTableRow:nth-child(1) {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 5px !important;
}

.testAuthTableRow:last-child {
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.testAuthTableCellSorted {
	font-family: 'RionaSans-Bold';
}

.testAuthBodyRowCell {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.testAuthBodyRowMajorText {
	font-size: 14px;
	color: #262837;
}

.testAuthBodyRowMinorText {
	font-size: 12px;
	font-weight: 500;
	color: #7b8291;
}

.testAuthFlagCellFull {
	font-size: 14px;
	font-family: 'RionaSans-Medium';
	color: #262837;
}

.testAuthFlagCellEmpty {
	font-size: 12px;
	font-style: italic;
	color: #bac3ce;
}

.testAuthCTACellButtons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	cursor: pointer;
}

.testAuthCTAButtons {
	width: 104px;
	height: 36px;
	border-radius: 6.5px;
	background-color: white;
	user-select: none;
	padding-top: 9px;
	text-align: center;
	font-size: 13px;
}

.testAuthCTAApprove {
	color: #00a044;
	border: 1px solid #00a044;
	margin-right: 8px;
}

.testAuthCTAApprove:hover {
	background-color: rgba(0, 214, 91, 0.15);
}

.testAuthCTAApprove:active {
	background-color: rgba(0, 214, 91, 0.3);
}

.testAuthCTAReject {
	color: #ad1505;
	border: 1px solid #ad1505;
}

.testAuthCTAReject:hover {
	background-color: rgba(231, 76, 60, 0.15);
}

.testAuthCTAReject:active {
	background-color: rgba(231, 76, 60, 0.3);
}

.testAuthCheckboxCell
	> label.el-checkbox
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	border-color: #bd92e4 !important;
}

.testAuthCheckboxCell
	> label.el-checkbox
	> span.el-checkbox__input.is-checked
	> span.el-checkbox__inner::after,
.testAuthCheckboxCell
	> label.el-checkbox
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 1.7px solid #7b26c9 !important;
	border-top: none !important;
	border-left: none !important;
}

.testAuthContainer table .LinkPopover:hover {
	cursor: pointer;
	text-decoration: underline;
}

/* start: overriding checkbox style */
.testAuthContainer table .el-checkbox__input.is-focus .el-checkbox__inner,
.testAuthContainer table .el-checkbox__input .el-checkbox__inner:hover {
	border-color: #7b26c9;
}

.testAuthContainer table .el-checkbox__input.is-checked .el-checkbox__inner,
.testAuthContainer
	table
	.el-checkbox__input.is-indeterminate
	.el-checkbox__inner {
	border-color: #7b26c9;
	background-color: #7b26c9 !important;
}

.testAuthContainer table .el-checkbox__input .el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}
/* end: overriding checkbox style */

/* Red/Green Flag and their reason */
.testAuthContainer .red-flag-icon,
.testAuthContainer .green-flag-icon {
	margin-right: 8px;
	cursor: pointer;
	height: 18px;
	width: 18px;
}

/* Tooltip for flag reason */
.testAuthContainer table .el-tooltip__popper.is-dark {
	width: 200px !important;
}

.testAuthContainer table .green-flag-tooltip .el-tooltip__popper.is-dark {
	word-break: break-all;
}
