@use '../../../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.patient-demographics-and-contact {
		.demographics {
			.input-row {
				@include core.threeColumnLayout;
				.el-select,
				.el-date-editor {
					width: 100%;
				}
			}
			.gm-checkbox {
				margin-top: system.$halfUnit;
				margin-bottom: system.$halfUnit;
			}
		}
		.external-patient-identifiers {
			@include core.threeColumnLayout;
		}
		.external-patient-identifiers {
			@include core.threeColumnLayout;
		}
	}
}
