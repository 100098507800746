.release-document-modal-container {
	width: 368px;
	height: fit-content;
	min-height: 150px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 120px);
}
.release-document-modal-container .releaseDocumentTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	min-height: 56px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.release-document-modal-container .releaseDocumentTopRow .title-container {
	display: flex;
	flex-direction: column;
}
.release-document-modal-container
	.releaseDocumentTopRow
	.title-container
	.releaseDocumentTitle {
	width: 100%;
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.release-document-modal-container
	.releaseDocumentTopRow
	.title-container
	.steps-container {
	display: flex;
}

.release-document-modal-container
	.releaseDocumentTopRow
	.title-container
	.steps-container
	.step {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
}
.release-document-modal-container
	.releaseDocumentTopRow
	.title-container
	.steps-container
	.step.active {
	color: #262837;
}

.release-document-modal-container
	.releaseDocumentTopRow
	.title-container
	.steps-container
	.step-arrow {
	padding-left: 2px;
	padding-right: 2px;
}

.release-document-modal-container
	.releaseDocumentTopRow
	.title-container
	.steps-container
	.step-arrow
	> div
	> svg {
	transform: scale(0.9);
	width: 16px;
	height: 16px;
}
.release-document-modal-container .releaseDocumentTopRow .releaseDocumentClose {
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
}
.release-document-modal-container
	.releaseDocumentTopRow
	.releaseDocumentClose
	.el-dialog__close {
	font-size: 12px;
	font-weight: bold;
}

.release-document-modal-container .releaseDocumentBody {
	padding: 16px 16px;
	max-height: calc(100vh - 240px);
	overflow-y: auto;
}

.release-document-modal-container .releaseDocumentBody .body-section {
	margin-bottom: 32px;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.finalise-notice-container {
	border-radius: 4px;
	background-color: #edeff3;
	display: flex;
	padding: 12px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.finalise-notice-container
	.icon {
	margin-right: 8px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.finalise-notice-container
	.notice {
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

/* select Recipients Step */
.release-document-modal-container .selectRecipientsContainer {
	height: fit-content;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 19px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputHeaderContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputHeaderContainer
	.required {
	text-indent: -6px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #e74c3c;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputHeaderContainer
	.releaseDocInputHeader {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	text-transform: capitalize;
	margin-bottom: 4px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer {
	margin-top: 4px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container {
	padding: 12px;
	border-radius: 4px;
	border: solid 1px #edeff3;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container.active {
	border: solid 1px #7b26c9;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container.is_disabled,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container.is_disabled:hover,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container.is_disabled:active {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.release-document-modal-container .control-row.is-disabled,
.release-document-modal-container .control-row.is-disabled:hover,
.release-document-modal-container .control-row.is-disabled:active {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

/*checkbox styling */

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	border-color: #a0abb9 !important;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input.is-checked
	.el-checkbox__inner {
	background-color: #7b26c9 !important;
	border-color: #7b26c9 !important;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer.checkbox-container
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 1.5px solid #ffffff !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 6px !important;
	width: 3px !important;
	left: 4px !important;
	top: 1px !important;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.control-row {
	display: flex;
	flex-direction: row;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.control-row.clickable {
	cursor: pointer;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.left-info {
	width: 22px;
	min-width: 22px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-top: 2px;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls {
	display: flex;
	flex-direction: column;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls.disbled {
	pointer-events: none;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.provider-input {
	margin-left: 20px;
	margin-bottom: 12px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.el-radio {
	margin: 0px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.el-radio
	.el-radio__inner {
	height: 16px;
	width: 16px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.el-radio__input.is-checked
	.el-radio__inner {
	background: #fff;
	border-color: #7b26c9;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.el-radio__input.is-checked
	.el-radio__inner::after {
	height: 8px;
	width: 8px;
	background-color: #7b26c9;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.el-radio__label {
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.right-info {
	padding-top: 2px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.provider-input
	.error-msg {
	margin-top: 2px;
	font-family: RionaSans;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.4;
	letter-spacing: normal;
	text-align: left;
	color: #e74c3c;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.provider-input
	.quick-actions {
	margin-top: 2px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.provider-input
	.quick-actions
	span {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #7b26c9;
	cursor: pointer;
	margin-right: 8px;
	text-transform: capitalize;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.provider-input
	.quick-actions
	span.disabled {
	pointer-events: none;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.center-info
	.provider-medium-controls
	.provider-input
	.quick-actions
	span:hover {
	text-decoration: underline;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input
	> input.el-input__inner,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input {
	height: 32px !important;
	width: 100% !important;
	font-size: 13px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	font-family: 'RionaSans';
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 3px 10px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input::placeholder,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input::-webkit-input-placeholder {
	color: #bac3ce !important;
	font-family: RionaSans-Medium;
	font-size: 13px !important;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input
	> input.el-input__inner:hover,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input:hover {
	border: solid 1px #70cbf1;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input
	> input.el-input__inner:active,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainerinput:active {
	border: solid 1.5px #40b9ec;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.is-disabled
	> input.el-input__inner,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.is-disabled
	> input.el-input__inner:active,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.is-disabled
	> input.el-input__inner:hover,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input[disabled],
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input[disabled]:active,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input[disabled]:hover {
	border: solid 1px #bac3ce !important;
	background-color: #edeff3 !important;
	color: #bac3ce !important;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.input-error
	> input.el-input__inner,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.input-error
	> input.el-input__inner:active,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.input-error
	> input.el-input__inner:hover,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input.input-error,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input.input-error:active,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input.input-error:hover {
	border: solid 1px #e74c3c;
	background-color: rgba(254, 145, 137, 0.5);
	color: #fe5c50;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input.input-error::placeholder,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	input.input-error::-webkit-input-placeholder,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.input-error
	> input.el-input__inner::placeholder,
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.el-input.input-error
	> input.el-input__inner::-webkit-input-placeholder {
	color: #fe5c50 !important;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.hint-contianer {
	padding: 8px 0px;
	border-radius: 4px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.hint-contianer
	span {
	font-family: RionaSans-MediumItalic;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.extra-info {
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.29px;
	color: #7b8291;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.extra-medium-info {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.extra-bold-info {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.el-checkbox {
	margin-left: 0px;
	margin-right: 8px;
	margin-bottom: 0px;
}
.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocinputcontainer
	.releaseDocInputElementContainer
	.el-checkbox
	.el-checkbox__input {
	line-height: 1.2 !important;
}

.release-document-modal-container .releaseDocumentBody .body-section .note {
	font-family: RionaSans-MediumItalic;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.release-document-modal-container .rncContainer {
	height: fit-content;
	margin-top: 16px;
}
.release-document-modal-container .releaseDocValueContainer {
	margin-top: 4px;
}
.release-document-modal-container .releaseDocValueContainer .row-container {
	display: flex;
}
.release-document-modal-container
	.releaseDocValueContainer
	.row-container
	.icon
	svg
	path.GMIStethoscopeIcon {
	fill: #7b8291;
}
.release-document-modal-container
	.releaseDocValueContainer
	.row-container
	.right-side-container {
	display: flex;
	flex-direction: column;
	margin-left: 12px;
	flex-grow: 1;
}
.release-document-modal-container
	.releaseDocValueContainer
	.row-container
	.lable-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-right: 12px;
}
.release-document-modal-container
	.releaseDocValueContainer
	.row-container
	.full-label-container {
	width: 100%;
	display: flex;
	margin-right: 12px;
	flex-direction: column;
}
.release-document-modal-container
	.releaseDocValueContainer
	.row-container
	.full-label-container
	.flex-container {
	flex-direction: row;
	display: flex;
}

.release-document-modal-container .archived-text {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #ad1505;
}

.release-document-modal-container
	.releaseDocValueContainer
	.row-container
	.extra-info-container {
	align-self: end;
}

.release-document-modal-container
	.releaseDocumentBody
	.body-section
	.releaseDocValueContainer {
	padding: 12px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	background-color: #edeff3;
}

.release-document-modal-container .releaseDocValueContainer .text-bold-info {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}
.release-document-modal-container .releaseDocValueContainer .text-medium-info {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
	word-break: break-all;
}
/* footer */
.release-document-modal-container .releaseDocumentFooter {
	display: flex;
	padding: 12px 16px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	justify-content: space-between;
	height: 60px;
	flex-direction: row-reverse;
}

.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-continue-btn,
.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-continue-btn.el-button.is-disabled:hover {
	border: 1px solid #7b26c9;
	color: #ffffff;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
}

.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-continue-btn:hover {
	background-color: #9c5dd7 !important;
	border: solid 1px #9c5dd7 !important;
}

.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-continue-btn.el-button.is-disabled:hover {
	background-color: #7b26c9 !important;
}

.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-continue-btn:active {
	border: #7b26c9;
	border: 1px solid #7b26c9;
	color: #ffffff;
}

.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-continue-btn {
	border-radius: 6.5px;
	background-color: #7b26c9 !important;
	width: 160px;
	height: 36px;
	text-align: center;
}

.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-continue-btn.is-disabled {
	border-radius: 6.5px;
	background-color: #7b26c9 !important;
	width: 160px;
	height: 36px;
	text-align: center;
}

.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-skip-btn,
.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-skip-btn.el-button.is-disabled:hover {
	border-radius: 6.5px;
	width: 160px;
	height: 36px;
	border: solid 1px #7b26c9;
	color: #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: 0;
}
.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-skip-btn:hover {
	border: solid 1px #7b26c9 !important;
	background-color: rgba(123, 38, 201, 0.15) !important;
}
.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-skip-btn:active {
	border: solid 1px #7b26c9 !important;
	background-color: rgba(123, 38, 201, 0.3) !important;
}
.release-document-modal-container
	.releaseDocumentFooter
	.releasedocument-skip-btn:focus {
	border: solid 2px rgba(123, 38, 201, 0.5) !important;
	background-color: rgba(123, 38, 201, 0.15) !important;
}
