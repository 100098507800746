@use 'system';

.duplicate-patient-tag {
	.uuid-container {
		display: flex;
		flex-direction: column;
		margin-left: 27px;
		margin-top: 12px;
		.uuid-wrapper {
			margin-bottom: 12px;
			.uuid-input-container {
				display: flex;
				align-items: end;
				.remove-uuid-icon-wrapper {
					cursor: pointer;
				}
			}
			.error {
				font-size: 12px;
				color: red;
			}
		}
		.add-another-uuid-button {
			display: flex;
			align-items: center;
			cursor: pointer;
			label {
				margin-bottom: 0;
			}
		}
	}
}
