.provider-linked {
	min-width: 416px;
	padding: 12px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
}
.provider-linked .provider {
	display: flex;
	justify-content: center;
}
.provider-linked .provider .icon {
	background-color: #e2d3f2;
	height: 32px;
	width: 32px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.provider-linked .provider .icon .stethoscope-icon div,
.provider-linked .provider .icon .stethoscope-icon svg {
	height: 24px;
	width: 24px;
}
.provider-linked .provider .icon .stethoscope-icon svg path.cls-1 {
	fill: none;
}
.provider-linked .provider .info {
	margin-left: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.provider-linked .provider .info .provider-name {
	font: bold 14px/1.43 RionaSans-Bold;
	color: #262837;
	max-width: 264px;
}
.provider-linked .provider .info .location {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #262837;
}
.provider-linked .provider .info .link {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b8291;
}
.provider-linked .provider .info .link .red {
	color: #ad1505;
}
.provider-linked .actions {
	display: flex;
	justify-content: center;
	align-items: center;
}
.provider-linked .actions .designation {
	font: bold 12px/1.25 RionaSans-Bold;
	text-align: right;
	color: #7b8291;
	margin-right: 4px;
	margin-top: -4px;
}
