.documentNoticeContainer {
	padding: 12px;
	border-radius: 4px;
	background-color: #faedac;
	margin-bottom: 32px;
}
.documentNoticeContainer .documentFileBody {
	font: bold 14px/1.43 RionaSans-Bold;
	color: #4b3700;
}
.documentNoticeContainer .documentFileBody .documentFile {
	display: flex;
}
.documentNoticeContainer .documentFileBody .documentFile .fileName {
	margin-left: 12px;
}
.documentNoticeContainer .documentFileBody .fileType {
	font: normal 500 12px/1.42 RionaSans-Medium;
	margin-left: 36px;
	color: #bc932c;
}
.documentNoticeContainer .documentFileBody .fileDownload {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b26c9;
	cursor: pointer;
	margin-left: 36px;
}
.documentNoticeContainer .documentNoticeBody {
	display: flex;
	justify-content: center;
	padding-top: 12px;
	margin-top: 12px;
	border-top: solid 1px #fddc3f;
}
.documentNoticeContainer .documentNoticeBody .documentNoticeText {
	-webkit-font-smoothing: antialiased;
	width: calc(100% - 24px);
	min-height: 36px;
	font: normal 500 13px/1.38 RionaSans-MediumItalic;
	color: #4b3700;
	margin-left: 8px;
}

/*Gray theme overwrite*/
.documentNoticeContainer.gray {
	background-color: #edeff3 !important;
}
.documentNoticeContainer.gray .documentFileBody {
	color: #262837 !important;
}
.documentNoticeContainer.gray .documentNoticeBody {
	border-top: solid 1px #d8dde4 !important;
}
.documentNoticeContainer.gray .documentNoticeBody .documentNoticeText {
	color: #262837 !important;
	font: normal 13px/1.38 RionaSans !important;
}
.documentNoticeContainer.gray .documentFileBody .fileType {
	color: #7b8291 !important;
}
