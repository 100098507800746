.ror-fu-declined-dialog {
	width: 368px !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	max-height: calc(100vh - 120px);
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
}

.ror-fu-declined-dialog .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.ror-fu-declined-dialog .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.ror-fu-declined-dialog .el-dialog__title .warningicon {
	transform: scale(1.3);
	padding-right: 10px;
}

.ror-fu-declined-dialog .el-dialog__body {
	margin: 32px 0;
	padding: 0 16px !important;
	font: normal 12px/1.42 RionaSans;
	color: #262837;
}

.ror-fu-declined-dialog .el-dialog__body .required::before {
	color: #e74c3c;
	content: '*';
	margin-right: 2px;
}

.ror-fu-declined-dialog .el-dialog__body .lst-ror-reason-decline-fu {
	width: 100%;
}

.ror-fu-declined-dialog .el-dialog__footer {
	padding: 12px 16px;
	border-top: 1px solid #edeffe;
	display: -ms-flexbox;
	display: flex;
}

.ror-fu-declined-dialog .el-dialog__footer button {
	height: 36px;
	width: 160px;
	border-radius: 6px;
	font: normal 500 13px/1.38 RionaSans-Medium;
	text-transform: capitalize;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-cancel {
	margin-right: 16px;
	color: #e74c3c;
	border: 1px solid #e74c3c;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-cancel:hover {
	background-color: rgba(231, 76, 60, 0.16);
	color: #e74c3c;
	border: 1px solid #e74c3c;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-cancel:active {
	background-color: rgba(231, 76, 60, 0.3);
	color: #e74c3c;
	border: 1px solid #e74c3c;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-cancel:focus {
	background-color: rgba(231, 76, 60, 0.16);
	color: #e74c3c;
	border: 1px dotted #e74c3c;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-fu-declined {
	border: 1px solid #e74c3c;
	background-color: #e74c3c !important;
	color: #ffffff;
	margin-left: 0 !important;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-fu-declined:hover {
	border: 1px solid #ec6759;
	background-color: #ec6759 !important;
	color: #fff;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-fu-declined:active {
	border: 1px solid #ad1505;
	background-color: #ad1505;
}

.ror-fu-declined-dialog .el-dialog__footer .btn-fu-declined:focus {
	border: 2px solid #f0968d;
	background-color: #e74c3c !important;
	color: #fff;
}

/* For disabled button */
.ror-fu-declined-dialog .el-dialog__footer .is-disabled.btn-fu-declined:hover {
	border: 1px solid #e74c3c !important;
	background-color: #e74c3c !important;
}

/* Overrider Element React Style */
.ror-fu-declined-dialog .el-dialog__body .el-input {
	font: normal 13px/1.38 RionaSans;
}

.ror-fu-declined-dialog .el-dialog__body .el-input__inner {
	color: #262837;
}

.ror-fu-declined-dialog .el-dialog__body input::placeholder {
	color: #bac3ce;
}
