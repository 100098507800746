@use '../../../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.gmi-radio-group {
		display: flex;
		gap: system.$singleUnit;
		.el-radio {
			margin-bottom: 0px;
		}
		margin-bottom: system.$oneHalfUnit;
	}

	.insurance-card-container {
		margin-bottom: system.$singleUnit;
	}
	.credit-card-heading {
		margin-bottom: system.$singleUnit;
		margin-top: system.$oneHalfUnit;
	}

}
