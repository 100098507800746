/* stylelint-disable selector-class-pattern */
.view-cfdna-prenatal-screening-fulldetail-dialog {
	width: 731px !important;
	position: fixed !important;
	height: min(calc(100% - 88px), 1472px);
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}

/******************************************************************************
								Dialog Header
*******************************************************************************/

.view-cfdna-prenatal-screening-fulldetail-dialog .el-dialog__header {
	border-bottom: 1px solid #edeff3;
	padding: 15px 16px;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .header {
	display: flex;
	color: #262837;
	font: bold 20px RionaSans-Bold, sans-serif;
}

.view-cfdna-prenatal-screening-fulldetail-dialog
	.el-dialog__header
	.not-detected-pill {
	margin-left: 16px;
	background-color: #f4a4a5;
	color: #400404;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .header .close-btn-icon {
	height: 23px;
	cursor: pointer;
	margin-left: auto;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .header .close-btn-icon:hover {
	background-color: #ececec;
	border-radius: 20px;
	height: 27px;
}

.view-cfdna-prenatal-screening-fulldetail-dialog
	.el-dialog__title
	.close-icon
	svg
	.GMICloseIcon {
	fill: #262837;
}

/******************************************************************************
								Dialog Body
*******************************************************************************/
.view-cfdna-prenatal-screening-fulldetail-dialog .el-dialog__body {
	padding: 24px 16px;
	height: calc(100% - 118px);
	overflow-y: auto;
	font: normal 12px RionaSans, sans-serif;
	color: #262837;
}

.view-cfdna-prenatal-screening-fulldetail-dialog h2.module-header {
	display: flex;
	width: 100%;
	align-items: center;
	font: normal 500 16px/23px RionaSans-Medium, sans-serif;
	margin: 0;
	color: #525968;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .group-section {
	border-bottom: 1px solid #edeff3;
	padding-bottom: 24px;
	margin-bottom: 24px;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .group-section:last-child {
	border-bottom: none;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .risk-group-label {
	font: normal 500 14px/20px RionaSans-Medium, sans-serif;
	margin-bottom: 8px;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .el-dialog__body .field-label {
	font: normal 500 12px/17px RionaSans-Medium, sans-serif;
	color: #525968;
	display: block;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .el-dialog__body .field-value {
	font: normal 700 14px/20px RionaSans-Bold, sans-serif;
	display: block;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .el-row {
	margin-bottom: 26px;
}

.view-cfdna-prenatal-screening-fulldetail-dialog .el-row:last-child {
	margin-bottom: 0;
}

.view-cfdna-prenatal-screening-fulldetail-dialog h2.module-header {
	font: normal 500 16px/23px RionaSans-Medium, sans-serif;
	margin-top: 0;
	margin-bottom: 24px;
	color: #525968;
}

/******************************************************************************
								Dialog Footer
*******************************************************************************/
.view-cfdna-prenatal-screening-fulldetail-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 11px 16px;
	display: flex;
	justify-content: end;
}
