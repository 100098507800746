.addedit-insunance-form h3 {
	margin: 0 0 32px 0;
	font: normal bold 20px RionaSans-Bold;
	color: #262838;
}

.addedit-insunance-form .el-row {
	margin-bottom: 6px;
}

.addedit-insunance-form label {
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-family: 'RionaSans';
	font-weight: normal;
	font-size: 12px;
	color: #262837;
}

.addedit-insunance-form .addedit-insurance-btn-container {
	margin-top: 20px;
	margin-bottom: 12px;
	padding-top: 12px;
	border-top: 1.5px solid #edeff3a2;
	margin-left: -16px;
	margin-right: -16px;
	padding-right: 16px;
}

.addedit-insunance-form .btn-create-insurance {
	width: 160px;
	height: 36px;
	padding: 8px 0;
	border-radius: 6.5px;
	background-color: #7b26c9;
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font: normal 500 13px RionaSans-Medium;
	color: #ffffff;
	cursor: pointer;
}

.addedit-insunance-form .btn-create-insurance:hover {
	background-color: #9c5dd7;
	border: none;
}

.addedit-insunance-form .btn-create-insurance:active {
	background-color: #621ea0;
	border: none;
}

.addedit-insunance-form .required {
	display: flex;
}

.addedit-insunance-form .required::before {
	color: #ff0000;
	content: '*';
	font: normal bold 15px RionaSans-Bold;
	margin-left: -6px;
	margin-right: 2px;
	vertical-align: middle;
}

.addedit-insurance-btn-container > div > button.el-button.is-disabled,
.addedit-insurance-btn-container > div > button.el-button.is-disabled:hover {
	border: unset !important;
	background-color: #7b26c9 !important;
	opacity: 0.35;
}

.addedit-insunance-form .error {
	color: #ff4949;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
	min-height: 14px;
}

.addedit-insunance-form .no-error {
	font-size: 1px;
}

.addedit-insunance-form input::placeholder {
	font: normal 13px RionaSans !important;
	color: #bac3ce !important;
}

.loading-addedit-insurance-form > div:first-child {
	bottom: -4px !important;
	left: -6px !important;
}

/* start: override element react style */

.addedit-insunance-form .el-input__inner,
.addedit-insunance-form input::placeholder {
	font: normal 13px RionaSans !important;
	color: #262837;
}

button.el-button.is-disabled.btn-create-insurance,
button.el-button.is-disabled.btn-create-insurance:focus,
button.el-button.is-disabled.btn-create-insurance:hover {
	background-color: #40b9ec !important;
	color: #fff !important;
}

.edit-insunance-immutablefield .el-input__inner {
	background-color: #edeff3 !important;
	color: #bac3ce !important;
	border: 1px solid #bac3ce !important;
}

/* end: override element react style */

.addEditInsuranceContainer {
	width: 368px;
	border-radius: 4px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
	background-color: white;
	position: absolute;
	top: 60px;
	display: flex;
	flex-direction: column;
}

.addEditInsuranceContainerDetails {
	left: calc(50vw - 184px);
}

.addEditInsuranceContainerExceptions {
	left: calc(50vw - 320px);
	width: 640px;
}

.addEditInsuranceDetailsContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 30px 16px 0px 16px;
	overflow-y: auto;
	max-height: calc(100vh - 148px);
}

.addEditInsuranceButtonRow {
	width: 100%;
	height: 60px;
	border-top: 1.5px solid #edeff3a2;
	display: flex;
	flex-direction: row;
	padding: 12px 16px;
}

.disabledInputField {
	height: 32px;
	width: 80px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: #edeff3;
	cursor: no-drop;
	font-size: 13px;
	color: #bac3ce;
	padding: 7px 8px;
	margin-right: 6px;
}

.enabledInputField {
	width: 80px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: white;
	font-size: 13px;
	color: #262837;
	padding: 0 8px;
	margin-right: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.enabledInputFieldInner {
	height: 30px;
	width: 60px;
	border: none;
	background: white;
	position: relative;
	font-size: 13px;
}

.enabledInputFieldInner::placeholder {
	font-size: 13px !important;
	-webkit-font-smoothing: antialiased;
}

.insurance-container
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	border-color: #bfcbd9 !important;
}

.insurance-container
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input.is-checked
	> span.el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.tbl-insurance-list
	label.el-checkbox.chargeICDSearchResultRowCheckbox
	> span.el-checkbox__input.is-disabled
	> span.el-checkbox__inner:hover {
	border-color: #bfcbd9 !important;
}

.insurance-container .el-checkbox__input.is-checked .el-checkbox__inner {
	background-color: #7b26c9 !important;
}

.insurance-container
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}
