.view-cfdna-prenatal-screening-info {
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: #ffffff;
	padding: 16px;
}

.view-cfdna-prenatal-screening-info .cfdna-prenatal-screening-title {
	border-bottom: 1px solid #edeff3;
	margin-bottom: 16px;
	padding-bottom: 16px;
	font: normal bold 20px RionaSans-Bold, sans-serif;
	color: #262837;
}

.view-cfdna-prenatal-screening-info .cfdna-prenatal-screening-section {
	border-right: 1px solid #edeff3;
	padding-right: 0;
}

.view-cfdna-prenatal-screening-info .result-details-label,
.view-cfdna-prenatal-screening-info .clinical-comments-label {
	font: normal 500 18px RionaSans-Bold, sans-serif;
	color: #262837;
}

.view-cfdna-prenatal-screening-info .el-row {
	margin-bottom: 16px;
}

.view-cfdna-prenatal-screening-info .el-row:last-child {
	margin-bottom: 0;
}

.view-cfdna-prenatal-screening-info label {
	color: #525968;
	font: normal 500 12px RionaSans-Medium, sans-serif;
	margin-bottom: 0;
}

.view-cfdna-prenatal-screening-info .field-val {
	font: normal 700 14px RionaSans-Bold, sans-serif;
	color: #262837;
	line-height: normal;
	width: 100%;
	display: inline-block;
}

.view-cfdna-prenatal-screening-info
	.cfdna-prenatal-screening-section
	.structural-variant-view-full-details {
	color: #7b26c9;
	font: normal 500 14px/20px RionaSans-Medium, sans-serif;
}

.view-cfdna-prenatal-screening-info
	.cfdna-prenatal-screening-section
	.structural-variant-view-full-details:hover {
	text-decoration: underline;
}

.view-cfdna-prenatal-screening-info .clinical-comments-container {
	position: relative;
}
