.gmiTextarea .el-textarea__inner {
	border-radius: 4px;
	color: #262837;
	margin-bottom: 19px;
	height: 88px;
	border: 1px solid #bac3ce;
	padding: 8px;
	font-size: 13px !important;
	resize: none;
	overflow: auto;
}

.gmiTextarea .el-textarea__inner:hover {
	border: 1px solid #70cbf1 !important;
}

.gmiTextarea .el-textarea__inner:focus {
	border: 1px solid #40b9ec !important;
	outline: unset !important;
}

.gmiTextarea .el-textarea__inner::placeholder {
	font-family: 'RionaSans' !important;
	font-size: 13px !important;
	color: #bac3ce !important;
	-webkit-font-smoothing: antialiased;
	opacity: 1 !important;
}
