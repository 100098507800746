/* stylelint-disable selector-class-pattern */
.add-finding-select-dialog {
	width: 368px !important;
	position: fixed !important;
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}

.tests-container .add-new-finding-button {
	border-radius: 7px;
	background-color: #7b26c9;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 24px 0 24px 24px;
	padding: 9px 12px;
}

.tests-container .add-new-finding-button .add-new-finding-icon {
	width: 18px;
	height: 18px;
	object-fit: contain;
}

.tests-container .add-new-finding-button .add-new-finding-text {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #ffffff;
}

/******************************************************************************
								Dialog Header
*******************************************************************************/

.add-finding-select-dialog .el-dialog__header {
	border-bottom: 1px solid #edeff3;
	padding: 16px;
}

.add-finding-select-dialog .header {
	display: flex;
	justify-content: space-between;
	color: #262837;
	font: bold 16px/1.38 RionaSans-Bold, sans-serif;
}

.add-finding-select-dialog .header .close-btn-icon {
	height: 23px;
	cursor: pointer;
}

.add-finding-select-dialog .header .close-btn-icon:hover {
	background-color: #ececec;
	border-radius: 20px;
}

.add-finding-select-dialog .el-dialog__title .close-icon svg .GMICloseIcon {
	fill: #262837;
}

/******************************************************************************
								Dialog Body
*******************************************************************************/
.add-finding-select-dialog .el-dialog__body {
	padding: 6px 16px;
}

.add-finding-select-dialog-body .gmi-radio-group {
	display: flex;
	flex-flow: column;
}

.add-finding-select-dialog .el-dialog__body .el-radio {
	padding: 14px 0;
	margin-bottom: 0;
}

.add-finding-select-dialog .gmi-radio-group .el-radio .el-radio__label {
	font: normal 500 14px RionaSans-Medium, sans-serif;
}

.add-finding-select-dialog
	.el-dialog__body
	.el-radio__input.is-disabled
	.el-radio__inner:hover {
	border: 1px solid #d1dbe5;
	background-color: #eef1f6;
	cursor: not-allowed;
}

.add-finding-select-dialog
	.el-dialog__body
	.el-radio__input.is-disabled
	+ .el-radio__label {
	color: #bbbbbb;
	cursor: not-allowed;
}

.add-finding-select-dialog
	.el-dialog__body
	.el-radio__input.is-checked
	+ .el-radio__label {
	color: #7b26c9;
}

/******************************************************************************
								Dialog Footer
*******************************************************************************/
.add-finding-select-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 12px 16px;
	display: flex;
	justify-content: center;
}

.add-finding-select-dialog .el-dialog__footer button {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	width: 160px;
	padding: 8px 12px;
	border-radius: 7px;
}

.add-finding-select-dialog .el-dialog__footer .cancel {
	border: 1px solid #7b26c9;
	color: #7b26c9;
}

.add-finding-select-dialog .el-dialog__footer .next {
	border: 1px solid #7b26c9;
	color: #ffffff;
	background-color: #7b26c9;
	margin-left: 16px;
}

.add-finding-select-dialog .el-dialog__footer .el-button.is-disabled,
.add-finding-select-dialog .el-dialog__footer .is-disabled:active,
.add-finding-select-dialog .el-dialog__footer .is-disabled:hover {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
	color: #ffffff;
	border: none;
}

.add-finding-select-dialog .el-dialog__footer .cancel:hover {
	background-color: rgb(123 38 201 / 15%);
}

.add-finding-select-dialog .el-dialog__footer .cancel:active {
	background-color: rgb(123 38 201 / 30%);
}

.add-finding-select-dialog .el-dialog__footer .next:hover {
	background-color: #9c5dd7;
}

.add-finding-select-dialog .el-dialog__footer .next:active {
	background-color: #621ea0;
}
