.calendar-toolbar {
	background-color: aquamarine;
}

.rbc-today {
	color: #40b9ec;
}

.rbc-time-header .rbc-allday-cell {
	/* remove all day row*/
	display: none;
}

.rbc-current-time-indicator {
	display: none;
}

.schedule-container {
	/* padding-left: 20px; */
	/* width: 785px; */
	margin-right: 8px;
	padding-bottom: 30px;
	width: calc(100% - 228px);
}

.schedule-filters {
	/* width: 100%; */
	padding-top: 8px;
	padding-left: 24px;
	padding-right: 20px;
	width: 220px;
	min-width: 220px;
	max-width: 220px;
}

.schedule-container .schedule-calendar {
	width: 100%;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
	background-color: #ffffff;
	margin-top: 8px;
}

.schedule-container.disabled-calendar {
	opacity: 0.45;
	cursor: not-allowed;
}

.schedule-container .disabled-pointerevents {
	pointer-events: none;
}

.schedule-container .schedule-calendar .schedule-header {
	padding-right: 20px;
	padding-left: 52px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 45px;
}

.schedule-container .schedule-calendar .schedule-header .schedule-headerleft {
	display: flex;
	align-items: center;
	border-left: 1px solid #d8dde4;
	height: 100%;
}

.schedule-container
	.schedule-calendar
	.schedule-header
	.schedule-headerleft
	.arrowpage {
	margin: 0 8px;
	width: 48px;
	height: 24px;
	display: flex;
}

.schedule-container
	.schedule-calendar
	.schedule-header
	.schedule-headerleft
	.arrowpage
	.pre-arrow {
	/* transform: rotate(180deg);
    width: 8px; */
	cursor: pointer;
}

.schedule-container
	.schedule-calendar
	.schedule-header
	.schedule-headerleft
	.arrowpage
	.next-arrow {
	cursor: pointer;
}

.schedule-calendar .rbc-calendar .rbc-time-view {
	border: none;
	border-top: solid 1px #d8dde4;
}

.schedule-container
	.schedule-calendar
	.schedule-header
	.schedule-headerleft
	.calendar-month {
	font-family: RionaSans-Bold;
	font-size: 15px;
	font-weight: bold;
	color: #2f3542;
}

.schedule-container .schedule-calendar .schedule-header .schedule-day {
	font-family: RionaSans;
	font-size: 10px;
	text-align: center;
	color: #a0abba;
	width: 44px;
	height: 21px;
	border-radius: 19px;
	border: solid 1px #bac3ce;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 2px;
	background-color: transparent;
}

.schedule-container .schedule-calendar .schedule-header .schedule-day:hover {
	border: solid 1px #bac3ce;
	background-color: rgba(123, 130, 145, 0.15);
}

.schedule-container .schedule-calendar .schedule-header .daydisabled {
	cursor: not-allowed;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-content
	.rbc-day-slot
	.rbc-timeslot-group {
	border-bottom: solid 1px #d8dde4;
	min-height: 48px;
	border-left: solid 1px #d8dde4;
	pointer-events: none;
	z-index: 200;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-timeslot-group
	.rbc-day-slot
	.rbc-time-slot {
	/* border-top: 1px solid #f5f7f9; */
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-content
	> *
	+ *
	> * {
	border-left: none;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-gutter
	.rbc-timeslot-group {
	border-bottom: none;
	text-align: right;
	min-height: 48px;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-gutter
	.rbc-timeslot-group
	.rbc-time-slot {
	min-height: 22px;
	max-height: 22px;
	height: 22px;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-gutter
	.rbc-timeslot-group
	.rbc-label {
	font-family: RionaSans;
	font-size: 11px;
	text-align: right;
	color: #7b8291;
	padding-right: 4px;
	padding-left: 0px;
	display: flex;
	justify-content: flex-end;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-content
	> .rbc-time-gutter {
	width: 52px;
}

.schedule-container .schedule-calendar .rbc-calendar .rbc-time-header-gutter {
	width: 52px !important;
	max-width: 52px !important;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-header-content
	.rbc-header {
	border-bottom: none;
	font-family: RionaSans;
	font-size: 11px;
	color: #2f3542;
	height: 54px;
	text-align: left;
	padding-left: 8px;
	padding-top: 6.4px;
	/* min-width: 103px; */
	opacity: 1;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-header-content
	.rbc-today {
	font-family: RionaSans-Bold;
	font-size: 11px;
	font-weight: bold;
	color: #34bbf0;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-header-content
	.rbc-today
	~ .rbc-header {
	/* border-bottom:  none;
  font-family: RionaSans-Bold;
  font-size: 11px;
  color: #2f3542;
  height: 54px;
  text-align: left;
  padding-left: 8px;
  padding-top: 6.4px;
  min-width: 103.9px;
  opacity: 1; */
}

.schedule-calendar .rbc-calendar .rbc-time-header-content .rbc-today a,
.schedule-calendar .rbc-calendar .rbc-time-header-content .rbc-header a {
	cursor: auto;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-content
	> .rbc-day-slot {
	/* width: 104px;
    max-width: 104px;
    min-width: 104px; */
}

.rbc-today {
	background-color: #97edba;
	color: #ffffff;
}


	

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.na-slot {
	background-size: 8px;
	border-left: solid 1px #d8dde4;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot
	.slot:hover {
	background-color: #a2ffad;
	color: #004e09;
	font-family: RionaSans-Medium;
	font-size: 11px;
	border-radius: 2px;
	top: 1px !important;
	left: 2px;
	right: 2px;
	font-weight: bold;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot
	.slot
	.time {
	margin: 0;
	padding: 0;
	line-height: inherit;
	display: flex;
	align-items: center;
	padding-left: 8px;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot
	.slot:active {
	background-color: #00a044;
	border-radius: 2px;
	top: 1px !important;
	left: 2px;
	right: 2px;
	color: #ffffff;
	font-weight: bold;
}

.schedule-container .schedule-calendar .rbc-calendar .rbc-time-header-content {
	border-bottom: solid 1px #d8dde4;
	border-left: solid 1px #d8dde4;
}

.schedule-container .schedule-calendar .rbc-calendar .rbc-time-content {
	border-top: none;
	overflow: hidden;
}

/* calendar updated css */

.schedule-container .rbc-day-slot .rbc-events-container {
	margin-right: 0px;
}

.nav_container .scheduleAppointmentMainContainer .schedulepatientbg {
	background-color: white;
	display: block;
	padding: 0px 214px 51px 212px;
	width: 100%;
}

.nav_container
	.scheduleAppointmentMainContainer
	.schedulepatientbg
	.loading-select-patient-form {
	width: 632px;
}

@media only screen and (max-width: 1200px) {
	.nav_container .scheduleAppointmentMainContainer .schedulepatientbg {
		padding: 32px;
	}
}

.rbc-day-slot .rbc-time-slot:nth-child(1) {
	border-top: none;
}

.rbc-day-slot .rbc-time-slot:nth-child(2) {
	border-top: solid 1px #f5f7f9;
}

.rbc-time-content .rbc-today {
	background-color: transparent !important;
}

.rbc-time-header-cell .rbc-today {
	background-color: transparent !important;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot {
	/* border-top: solid 1px #f5f7f9;*/
	border-left: solid 1px #d8dde4;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot
	.solid {
	border-bottom: none;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot.active {
	border-radius: 2px;
	background-color: #60e096;
	top: 1px !important;
	left: 2px;
	right: 2px;
}

.rbc-calendar .rbc-time-view .rbc-time-header.rbc-overflowing {
	border-right: none;
	margin-right: 0 !important;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-time-header-content
	.rbc-header
	+ .rbc-header {
	border-left: solid 1px #d8dde4;
}
