.encounter-accordian-body .testorder-rows .testorder-row {
	border-radius: 8px;
	background-color: #edeff3;
	width: 100%;
	min-height: 168px;
	padding: 16px;
	margin-top: 5px;
	display: flex;
}

li.el-select-dropdown__item.mlo-test-name-list,
li.el-select-dropdown__item.aoe-dropdown-list {
	overflow: initial;
	white-space: pre-wrap;
	height: 100%;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-testname-bar-contain {
	width: 100%;
}

.testorder-testname-bar,
.testorder-tracking-bar {
	display: flex;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-testname-bar {
	font-family: RionaSans-Bold;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.4;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.testorder-testname-bar .val-test-name {
	max-width: calc(100% - 220px);
	padding: 0;
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #262837;
}

.testorder-testname-bar .testorder-label {
	max-width: calc(100% - 220px);
	padding: 0;
	font: normal bold 18px/1.43 RionaSans-Bold;
	color: #7b26c9;
}

.testorder-testname-bar .val-test-name .text-with-tooltip:hover,
.testorder-testname-bar .val-test-name .text-with-tooltip:active {
	text-decoration: underline;
	color: #262837;
}

.testorder-testname-bar .testorder-label .text-with-tooltip:hover,
.testorder-testname-bar .testorder-label .text-with-tooltip:active {
	text-decoration: underline;
	color: #9c5dd7;
}

.testorder-testname-bar .disable-btn-extrnl-user {
	pointer-events: none;
}

.testorder-testname-bar-contain .button-text {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	padding: 0;
}

.testorder-testname-bar-contain .button-text:hover,
.testorder-testname-bar-contain .button-text:active,
.testorder-testname-bar-contain .button-text:focus {
	text-decoration: underline;
	color: #7b26c9;
}

.testorder-testname-bar .divider-dot {
	margin: 0 8px 4px;
	font: normal bold 13px/1.38 RionaSans-Bold;
	color: #7b8291;
}

.testorder-testname-bar .link-icd-10 {
	margin-left: auto !important;
}

.testorder-testname-bar .link-icd-10,
.testorder-testname-bar .link-notes {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	padding: 0;
}

.testorder-testname-bar .link-icd-10:hover,
.testorder-testname-bar .link-icd-10:active,
.testorder-testname-bar .link-icd-10:focus,
.testorder-testname-bar .link-notes:hover,
.testorder-testname-bar .link-notes:active,
.testorder-testname-bar .link-notes:focus {
	text-decoration: underline;
	color: #7b26c9;
}

.encounter-accordian-body .testorder-rows .testorder-row .progressbar-wrapper {
	z-index: 1;
	width: 100%;
	overflow: hidden;
}

.testorder-row .testorder-tracking-bar .progressbar-wrapper .outreach-pill {
	float: right;
	margin-top: 28px;
}

.encounter-accordian-body .testorder-rows .testorder-row .progressbar-container,
.encounter-accordian-body .testorder-rows .testorder-row .testorder-tracking-bar .preauth-progressbar-wrapper {
	z-index: 1;
	min-width: 64%;
}

.encounter-accordian-body .testorder-rows .testorder-row .progressbar-container {
	float: left;
}

.encounter-accordian-body .testorder-rows .testorder-row .progressbar-container .val-test-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #7b26c9;
}

.encounter-accordian-body .testorder-rows .testorder-row .progressbar-container .val-test-name .testorder-label {
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #7b26c9;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.preauth-progressbar-wrapper {
	margin-top: 36px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.testorder-left-side
	.lab-name {
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
}

.encounter-accordian-body .testorder-rows .testorder-row .testorder-tracking-bar .testorder-right-side {
	margin-left: auto;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.testorder-right-side
	.testorder-progress-track {
	display: flex;
	width: 100px;
}

.encounter-accordian-body .testorder-rows .testorder-row .progressbar-container .progressbar {
	list-style: none;
	padding: 0;
	margin: 0;
	overflow: auto;
}

.encounter-accordian-body .testorder-rows .testorder-row .progressbar-container .reflex-order-pill {
	display: inline-block;
	margin-left: 6px;
}

.encounter-accordian-body .testorder-rows .testorder-row .testorder-tracking-bar .test-order-divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #a0abb9;
	display: inline-block;
	margin: 32px 0;
}

.encounter-accordian-body .testorder-rows .testorder-row .testorder-tracking-bar .test-order-divider:last-child {
	display: none;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li {
	float: left;
	width: 18.2%;
	position: relative;
	text-align: center;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li
	span.circle::after {
	width: 18px;
	height: 18px;
	display: block;
	margin: 0 0 8px 0;
	border-radius: 52%;
	line-height: 20px;
	text-align: center;
	font-weight: bold;
	font-size: 10px;
	background: #ffffff;
	content: ' ';
	color: #ffffff;
	border-color: #bac3ce;
	border-width: 1px;
	border-style: solid;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.done
	span.circle::after {
	border-color: #00a044;
	background-image: url('../../../../assets/check-bg.svg');
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.stop
	span.circle::after {
	border-color: #ad1505;
	background-image: url('../../../../assets/x-bg.svg');
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.stop::before {
	border-top-style: solid;
	border-top-color: #ad1505;
	border-top-width: 1px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.inprogress
	span.circle::after {
	border-color: #bc932c;
	background-image: url('../../../../assets/awaiting.svg');
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li::before {
	content: '';
	position: absolute;
	width: 100%;
	top: 9px;
	left: -100%;
	z-index: -1;
	border-top-style: solid;
	border-top-color: #bac3ce;
	border-top-width: 1.5px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.done::before {
	border-top-style: solid;
	border-top-color: #00a044;
	border-top-width: 1px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.inprogress::before {
	border-top-style: solid;
	border-top-color: #bc932c;
	border-top-width: 1px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li:first-child::before {
	content: none;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li
	div.step-name {
	width: 88px;
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #a0abb9;
	text-transform: capitalize;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li
	div.result-actionable {
	width: 160px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.done
	div.step-name {
	color: #00a044;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.inprogress
	div.step-name {
	color: #bc932c;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li.stop
	div.step-name {
	color: #ad1505;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.progressbar
	li
	div.step-date {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.4;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar {
	width: 45%;
	display: flex;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar {
	width: 58%;
	display: flex;
}

.encounter-accordian-body .testorder-rows .testorder-row .testorder-tracking-bar .ordering-sec,
.encounter-accordian-body .testorder-rows .testorder-row .testorder-extra-info-bar .ordering-sec {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	align-items: flex-end;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.ordering-sec
	.lbl-ordering-physician {
	font: normal 12px/1.42 RionaSans;
	text-align: right;
	color: #7b8291;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.ordering-sec
	.val-ordering-physician {
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #262837;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-tracking-bar
	.ordering-sec
	.val-ordering-physician
	img,
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar
	.ordering-sec
	.value-class
	img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	border-color: #edeff3;
	margin-right: 2px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.ordering-sec {
	display: flex;
	flex-direction: column;
	min-width: 43%;
	margin-right: 42px;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar
	.icd10-codes-sec,
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.icd10-codes-sec {
	display: flex;
	flex-direction: column;
}
/* .encounter-accordian-body .testorder-rows .testorder-row .testorder-testname-bar span{
    display: inline-block;
    margin-left: 8px;
} */
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar
	.label-class {
	margin-bottom: 4px;
}
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar
	.label-class,
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.label-class {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar
	.value-class {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.testorder-info-bar-alter-testlab-label {
	font: normal 12px/1.42 RionaSans;
	color: #262837;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.testorder-info-bar-alter-test-name {
	font: normal 500 13px/1.38 RionaSans-MediumIt;
	color: #262837;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.testorder-info-bar-alter-lab-name {
	font: italic normal 12px/1.42 RionaSans-Italic;
	color: #262837;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar
	.value-class.icd-10-containers,
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.value-class.icd-10-containers {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.testorder-extra-info-bar
	.value-class.icd-10-containers
	.icd10-bubble,
.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.pre-auth-testorder-extra-info-bar
	.value-class.icd-10-containers
	.icd10-bubble {
	border-radius: 4px;
	border: solid 1px #bd92e4;
	background-color: #ddcaef;
	font-family: RionaSans;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: left;
	color: #491678;
	height: 20px;
	margin-right: 8px;
	margin-bottom: 4px;
	padding: 2px;
}

/**************************************
 start: pre auth test order progressbar
 ***************************************/
.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar {
	list-style: none;
	overflow: auto;
	padding: 0;
	margin: 0;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li {
	float: left;
	width: 145px;
	position: relative;
	text-align: center;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li
	span::after {
	width: 18px;
	height: 18px;
	display: block;
	margin: 0 0 8px 0;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	font-weight: bold;
	font-size: 10px;
	background: #ffffff;
	content: ' ';
	color: #ffffff;
	border: 1px solid #bac3ce;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.done
	span::after {
	border-color: #00a044;
	background-image: url('../../../../assets/check-bg.svg');
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.stop
	span::after {
	border-color: #ad1505;
	background-image: url('../../../../assets/x-bg.svg');
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.stop::before,
.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.done::before {
	border-top-style: dotted;
	border-top-color: #cbd2da;
	border-top-width: 1.5px;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.inprogress::before {
	border-top-style: solid;
	border-top-color: #bc932c;
	border-top-width: 1.5px;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.inprogress
	span::after {
	border-color: #bc932c;
	background-image: url('../../../../assets/awaiting.svg');
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li::before {
	content: '';
	position: absolute;
	width: 100%;
	top: 9px;
	left: -87%;
	z-index: -1;
	border-top-style: solid;
	border-top-color: #bac3ce;
	border-top-width: 1.5px;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li:first-child::before {
	content: none;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li
	div.step-name {
	font: normal bold 12px/1.42 RionaSans-Bold;
	text-align: left;
	color: #a0abb9;
	width: 88px;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li
	span {
	display: flex;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.done
	div.step-name {
	color: #00a044;
}
.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.inprogress
	div.step-name {
	color: #bc932c;
}
.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li.stop
	div.step-name {
	color: #ad1505;
}

.encounter-accordian-body
	.testorder-row
	.testorder-tracking-bar
	.pre-auth-progressbar
	li
	div.step-date {
	font: normal bold 10px/1.4 RionaSans;
	text-align: left;
	color: #7b8291;
}
/***********************************
 end: pre auth test order progressbar
 ************************************/
