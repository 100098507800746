@use '../../../Common/V3/system';
@use '../../core';

.#{core.$scopeClass} {
	.filter {
		display: flex;
		flex-direction: column;
		gap: system.$halfUnit;
		.row {
			display: flex;
			gap: system.$halfUnit;
			justify-content: flex-end;
		}
	}
}
