@use '../core' as core;

.#{core.$scopeClass} {
	.service-and-program {
		.body {
			@include core.threeColumnLayout;
			.el-select {
				width: 100%;
			}
		}
	}
}
