.note-input-field {
	textarea {
		min-height: 100px;
	}

	&.expand-add-comments {
		textarea {
			min-height: 150px;
			margin-bottom: 18px;
		}
	}
}
