@media only screen and (max-width: 1280px) and (min-width: 1101px) {
	.patients_list_part {
		width: 94%;
	}

	.provp1 {
		min-width: 120px;
		width: 10%;
	}

	.provp2 {
		width: 212px;
	}

	.prov-typemain {
		min-width: 160px;
		width: 14px;
	}

	.provp3 {
		min-width: 180px;
		width: 12%;
	}

	.provp4,
	.provp5 {
		min-width: 160px;
		width: 18%;
	}

	.patient-name {
		min-width: 100px;
		width: 9%;
	}

	.patient-lname {
		min-width: 120px;
		width: 11%;
	}

	.patient-dob {
		min-width: 120px;
		width: 7%;
	}

	.patient-phn {
		min-width: 152px;
		width: 12%;
	}

	.patient-prgm {
		min-width: 140px;
		width: 14%;
	}

	.patient-email {
		min-width: 208px;
		width: 30%;
	}

	.prv-apprv {
		display: flex;
	}
}

@media only screen and (max-width: 1100px) and (min-width: 992px) {
	.patient-name {
		min-width: 100px;
		width: 9%;
	}

	.patient-lname {
		min-width: 120px;
		width: 11%;
	}

	.patient-dob {
		min-width: 120px;
		width: 7%;
	}

	.patient-phn {
		min-width: 152px;
		width: 12%;
	}

	.patient-prgm {
		min-width: 140px;
		width: 14%;
	}

	.heading div {
		padding: 0 0 8px 15px;
	}

	.patient-email {
		min-width: 208px;
		width: 30%;
	}

	.patients_list_part {
		/* width: 94%; */
	}

	.provp1 {
		min-width: 120px;
		width: 10%;
	}

	.provp2 {
		min-width: 212px;
		/* width: 14%; */
	}

	.prov-typemain {
		min-width: 160px;
		width: 14px;
	}

	.provp3 {
		min-width: 180px;
		width: 12%;
	}

	.provp4,
	.provp5 {
		min-width: 160px;
		width: 18%;
	}

	.patients_list_part .patients_details {
		margin: 61px 0 0 0;
	}

	.patient_records_pagination button {
		font-size: 12px !important;
	}

	.prv-apprv {
		display: flex;
	}
}

@media only screen and (min-width: 0px) and (max-width: 1059px) {
	.patientscroll {
		overflow-x: scroll !important;
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	.patients_list_part .patients_details {
		margin: 61px 0 0 0;
	}
}

@media only screen and (max-width: 991px) and (min-width: 0px) {
	.patient-name {
		min-width: 100px;
		width: 9%;
	}

	.patient-lname {
		min-width: 120px;
		width: 11%;
	}

	.patient-dob {
		min-width: 120px;
		width: 7%;
	}

	.patient-phn {
		min-width: 152px;
		width: 12%;
	}

	.patient-prgm {
		min-width: 140px;
		width: 14%;
	}

	.patient-email {
		min-width: 208px;
		width: 30%;
	}

	.provp1 {
		min-width: 120px;
		width: 10%;
	}

	.provp2 {
		min-width: 212px;
		/* width: 14%; */
	}

	.prov-typemain {
		min-width: 160px;
		width: 14px;
	}

	.provp3 {
		min-width: 180px;
		width: 12%;
	}

	.provp4,
	.provp5 {
		min-width: 160px;
		width: 18%;
	}

	.prv-apprv {
		display: flex;
	}
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
	.search-box {
		float: none !important;
		text-align: left;
		width: 100%;
		margin-left: 75px;
	}

	.fix-header {
		height: 100px !important;
	}

	.patient_search_field_form > div {
		max-width: 250px !important;
		width: 195px !important;
		min-width: 100px !important;
	}

	.patients_list_part .patients_details {
		margin: 31px 0 0 0;
	}
}

@media only screen and (max-width: 480px) and (min-width: 0px) {
	.advanced-search-bar {
		padding: 18px 5px !important;
	}

	.search-box {
		flex-wrap: wrap;
	}

	.fix-header {
		height: 145px !important;
	}
	.patients_list_part .patients_details {
		margin: 80px 0 0 0;
	}
}
