.assign-modal-background {
	background-color: rgb(0 0 0 / 35%);
	height: 100vh;
	width: 100vw;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 10000;
}

.assign-modal-container {
	max-height: calc(100vh - 120px);
	position: fixed;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	width: 369px;
	border-radius: 4px;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 35%);
	background-color: white;
}

.assign-modal-header {
	border-bottom: 1px solid #d8dde4;
	width: 100%;
	height: 56px;
	padding: 9px 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.assign-modal-header-title {
	font-family: RionaSans-Bold, sans-serif;
	font-size: 16px;
	color: #262837;
}

.assign-modal-close-x {
	transform: scale(1.4);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	top: -2px;
	cursor: pointer;
}

.assign-modal-close-x-icon {
	position: relative;
	bottom: 1px;
	right: 1px;
}

.assign-modal-body {
	padding: 0 0 0 16px;
	width: 100%;
	max-height: calc(100vh - 180px);
	overflow-y: auto;
}

.assign-modal-filter-row {
	padding: 16px 16px 0;
	border-bottom: 1px solid #d8dde4;
	max-height: calc(100vh - 180px);
	overflow-y: auto;
}

.assign-modal-filter-row > .filter-tab-button {
	cursor: pointer;
	font-size: 14px !important;
	display: inline-block;
	color: #7b8291;
	padding-bottom: 8px;
}

.assign-modal-filter-row > .filter-tab-button:not(:last-child) {
	margin-right: 32px !important;
}

.assign-modal-filter-row > .filter-tab-button.active {
	color: #7b26c9;
	border-bottom: 4px solid #7b26c9;
}

.assign-modal-body > .gmi-radio-group {
	display: flex;
	padding-top: 16px;
	flex-direction: column;
	min-height: 100px;
	max-height: 152px;
	overflow-y: scroll;
}

.assign-modal-body > .gmi-radio-group > label:not(:last-child) {
	margin-bottom: 17px !important;
}

.assign-modal-body > .gmi-radio-group > label:last-child {
	margin-bottom: 8px !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.assign-modal-body > .gmi-radio-group > label .el-radio__label {
	font-size: 14px !important;
	color: #262837;
}

.assign-modal-footer {
	height: 56px;
	padding: 10px 16px;
	border-top: 1px solid #d8dde4;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.assign-modal-footer > .footer-button {
	cursor: pointer;
	border: 1px solid #7b26c9;
	border-radius: 6px;
	height: 36px;
	width: 158px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	color: #7b26c9;
}

.assign-modal-footer > .footer-button:hover {
	color: #621ea0;
	border-color: #621ea0;
	background-color: #f8f4fc;
}

.assign-modal-footer > .footer-button:last-child {
	color: white;
	background-color: #7b26c9;
}

.assign-modal-footer > .footer-button:last-child:hover {
	border-color: #621ea0;
	background-color: #621ea0;
}

.assign-modal-footer > .footer-button.disabled {
	cursor: not-allowed;
	pointer-events: none;
	border-color: #bd92e4;
	background-color: #bd92e4;
}
