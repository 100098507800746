.prov-date.encounter_list {
	height: 14px;
	font-family: RionaSans-Medium !important;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837 !important;
	-webkit-font-smoothing: antialiased;
}

.heading.encounter_list {
	padding-left: 0px;
}

.heading.encounter_list > div {
	font-family: RionaSans;
}

.encounter_body_area {
	display: flex;
}

.patient_records_pagination.encounter_list button {
	font-family: RionaSans-Bold !important;
	font-size: 15px !important;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

.heading_column.dos {
	min-width: 100px;
	width: 14%;
	margin-right: 20px;
	padding-left: 20px;
	margin-right: 20px;
}

.heading_column.type {
	min-width: 130px;
	width: 15%;
	padding-left: 0px;
	margin-right: 20px;
}

.heading_column.provider {
	min-width: 120px;
	width: 13%;
	padding-left: 0px;
}

.tbl-date.encounter_list {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.prov-type.encounter_list {
	height: 20px;
	font-family: RionaSans-Medium;
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.provider-typeimg.encounter_list {
	margin-top: 12px;
	margin-right: 4px;
}

.menuItemIconWhite.new_encounter > div > svg > g > g {
	fill: #fff;
}

.prov-typemain.encounter_list {
	min-width: 100px;
	width: 100%;
	padding-top: 8px;
}

.prov-typemain.encounter_list.non-visit {
	padding-top: 14px;
}

.visit-status.encounter_list {
	height: 17px;
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	-webkit-font-smoothing: antialiased;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.div_row.encounter_list {
	/*width: 1008px;*/
	width: 100%;
	height: 52px;
	border-radius: 10px;
	border: solid 1.5px #d4dfe7;
	background-color: #ffffff;
	cursor: pointer;
	box-shadow: none;
}

.div_row.encounter_list.upcoming {
	box-shadow: 0 0 6px 0 #66c7ef99;
	border: solid 1.5px #3caaeb;
	background-color: #ffffff;
}

.encounter_list_dos {
	padding-left: 20px;
	padding-top: 8px;
	min-width: 120px;
	width: 14%;
	margin-right: 20px;
}

.heading_column.request_status {
	min-width: 140px;
	width: 12%;
	margin-right: 20px !important;
}

.heading_column.order_status {
	min-width: 140px;
	width: 19%;
	margin-right: 20px;
}

.order_request_status_col_encounter_list {
	min-width: 140px;
	width: 12%;
}
.created_at_encounter_list {
	padding-top: 8px;
	padding-top: 8px;
	min-width: 100px;
	width: 10%;
}
.heading_column.created_at {
	min-width: 100px;
	padding-left: 20px;
	width: 10%;
}
.order_status_encounter_list {
	padding-top: 16px;
	margin-right: 20px;
	min-width: 140px;
	width: 15%;
}

#new-encounter-dropdown-container {
	display: flex;
	align-items: center;
	gap: 15px;
  }
#new-encounter-dropdown-container ul {
	width: 173px;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
	background-color: #fff;
	padding: 4px 0;
}

#new-encounter-dropdown-container ul li {
	height: 32px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	display: flex;
	align-items: center;
	cursor: pointer;
}

#new-encounter-dropdown-container ul li > a {
	width: 100%;
	height: 100%;
	padding: 7px 0 7px 8px;
	outline: unset;
}

#new-encounter-dropdown-container ul li > a:hover,
#new-encounter-dropdown-container ul li > a:active {
	background-color: #edeff3;
}

#new-encounter-dropdown-container .new-encounter-dropdown {
	display: flex;
	align-items: center;
	width: 173px;
	height: 36px !important;
	border-radius: 6.5px;
	background-color: #7b26c9;
	border: unset;
}

#new-encounter-dropdown-container .new-encounter-dropdown:hover {
	background-color: #9c5dd7;
}

#new-encounter-dropdown-container .new-encounter-dropdown:active,
#new-encounter-dropdown-container .new-encounter-dropdown:focus {
	background-color: #621ea0;
}

#new-encounter-dropdown-container .new-encounter-dropdown .addIcon {
	width: 28px;
	height: 28px;
}

#new-encounter-dropdown-container .new-encounter-dropdown span {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
}

#new-encounter-dropdown-container .new-encounter-dropdown .new-encounter-text {
	width: 115px;
	text-align: center;
}

#new-encounter-dropdown-container .new-encounter-dropdown.el-button {
	padding: 0;
}

#new-encounter-dropdown-container .dropdownIcon {
	margin-top: 5px;
}

#new-encounter-dropdown-container > .open .dropdownIcon {
	transform: rotate(180deg);
	margin: unset;
}

#new-encounter-dropdown-container .dropdownIcon > div > svg > g > g {
	fill: #fff;
}

.encounter_list .short-text-with-tooltip .el-tooltip__rel > div {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
