.gmi-pill-wrapper .pill {
	padding: 3px 12px;
	border-radius: 24px;
	font: normal bold 13px/1.38 RionaSans-Bold;
}

.gmi-pill-wrapper .gray-pill {
	background-color: #d8dde4;
	color: #262837;
}

.gmi-pill-wrapper .yellow-pill {
	background-color: #fde776;
	color: #4b3700;
}

.gmi-pill-wrapper .green-pill {
	background-color: #a2ffad;
	color: #004e09;
}

.gmi-pill-wrapper .pink-pill {
	background-color: #f4a4a5;
	color: #400404;
}
