.circle-icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	padding: 4px;
	background-color: #fde776;
	border-radius: 50%;
	vertical-align: middle;

	&::before {
		content: ' ';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #4b3700;
		position: absolute;
	}
}
