/*****************************************************
         Encounter/Test Request Tab Section
******************************************************/
.test-order-edit-notes-wrapper {
	display: flex;
	height: 100%;
}

.test-order-edit-notes-wrapper .el-tabs {
	background-color: #edeff3;
}

.test-order-edit-notes-wrapper .el-tabs .el-tabs__content {
	height: calc(100% - 40px);
	overflow-y: auto;
}

.test-order-edit-notes-wrapper .el-tabs__header {
	border-bottom: 1px solid #edeff3;
}

.test-order-edit-notes-wrapper .el-tabs__item {
	width: 126px;
	height: 40px;
	padding-top: 11px !important;
	text-align: center;
	font: normal 12px RionaSans;
	background-color: #edeff3;
	border-bottom: 1px solid #d8dde4;
	color: #7b8291;
}

.test-order-edit-notes-wrapper .el-tabs__item:active {
	background-color: transparent;
	color: #7b8291;
	opacity: 1;
	height: 40px;
}

/*****************************************************
         Notes Section
******************************************************/
.test-order-edit-notes-wrapper .edit-notes-fields-container {
	width: 400px;
	padding: 32px 16px;
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	padding-bottom: 0;
	height: 100%;
	overflow-y: auto;
}

.edit-notes-fields-container .txt-note .el-textarea__inner {
	border: 1px solid #bac3ce;
}

.edit-notes-fields-container .txt-note .el-textarea__inner:hover {
	border: 1px solid #70cbf1;
}

.edit-notes-fields-container .txt-note .el-textarea__inner:active {
	border: 1px solid #40b9ec;
}

.edit-notes-fields-container .el-row {
	margin-bottom: 8px;
}

/*****************************************************
         Notes Section: Button
******************************************************/
.edit-notes-fields-container .btn-add-note {
	width: 82px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	font: normal 500 13px/1.38 RionaSans-Medium;
	text-align: center;
	color: #fff !important;
	border: none;
	padding: 10px 12px 9px;
}

.edit-notes-fields-container .btn-add-note.is-disabled {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
}

.edit-notes-fields-container .btn-add-note.is-disabled:focus,
.edit-notes-fields-container .btn-add-note.is-disabled.is-disabled:hover {
	background-color: #7b26c9 !important;
}

.edit-notes-fields-container .btn-clear-note {
	padding: 0;
	font: normal 500 13px/1.38 RionaSans-Medium;
	width: 40px;
	color: #7b26c9 !important;
}

.edit-notes-fields-container .btn-clear-note:hover {
	text-decoration: underline;
	color: #7b26c9;
}

/*****************************************************
         Notes Section: note list
******************************************************/
.edit-notes-fields-container .notes-list-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 24px;
}

.edit-notes-fields-container .user-imgbox img {
	height: 24px;
	width: 24px;
	border-radius: 4px;
	border: 1px solid #d4dfe7;
}

.edit-notes-fields-container .user-imgbox {
	position: relative;
}

.edit-notes-fields-container
	.notes-list-container:not(:last-child)
	.user-imgbox::after {
	position: absolute;
	border: 1px solid #edeff3;
	width: 0;
	height: 100%;
	height: stretch;
	display: block;
	content: '';
	margin-left: 10px;
}

.notes-list-container {
	display: flex;
	padding-bottom: 24px;
}

.notes-list-container .note-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-left: 8px;
}

.notes-list-container .username-wrapper {
	display: flex;
}

.edit-notes-fields-container .note-content .draft-username {
	font: italic bold 14px/1.43 RionaSans;
	color: #7b8291;
}

.edit-notes-fields-container .note-content .note-username {
	font: normal bold 14px/1.43 RionaSans;
	color: #262837;
}

.edit-notes-fields-container .note-content .btn-delete-draft {
	font: normal 500 13px/1.38 RionaSans-Medium;
	padding: 0;
	color: #7b26c9 !important;
	margin-left: auto;
}

.edit-notes-fields-container .note-content .btn-delete-draft:hover {
	text-decoration: underline;
	color: #7b26c9;
}

.edit-notes-fields-container .note-content .note-time {
	font: normal 12px/1.42 RionaSans;
	color: #7b8291;
	padding-bottom: 4px;
}

.edit-notes-fields-container .note-content .draft-text {
	font: italic normal 14px/1.43 RionaSans;
	color: #7b8291;
	word-break: break-all;
}

.edit-notes-fields-container .note-content .note-text {
	font: normal 14px/1.43 RionaSans;
	color: #262837;
	word-break: break-all;
}
