/* stylelint-disable selector-class-pattern */
.add-edit-structural-variant-dialog {
	width: 733px !important;
	position: fixed !important;
	height: min(calc(100% - 118px), 1472px);
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;

	/* position: absolute;
	left: calc(50% - 325px);
	top: 60px; */
}

/******************************************************************************
								Dialog Header
*******************************************************************************/

.add-edit-structural-variant-dialog .el-dialog__header {
	border-bottom: 1px solid #edeff3;
	padding: 15px 16px;
}

.add-edit-structural-variant-dialog .header {
	display: flex;
	justify-content: space-between;
	color: #262837;
	font: bold 20px RionaSans-Bold, sans-serif;
}

.add-edit-structural-variant-dialog .header .close-btn-icon {
	height: 23px;
	cursor: pointer;
}

.add-edit-structural-variant-dialog .header .close-btn-icon:hover {
	background-color: #ececec;
	border-radius: 20px;
	height: 27px;
}

.add-edit-structural-variant-dialog
	.el-dialog__title
	.close-icon
	svg
	.GMICloseIcon {
	fill: #262837;
}

/******************************************************************************
								Dialog Body
*******************************************************************************/
.add-edit-structural-variant-dialog .el-dialog__body {
	padding: 22px 11px;
	height: calc(100% - 118px);
	overflow-y: auto;
	font: normal 12px RionaSans, sans-serif;
	color: #262837;
}

.add-edit-structural-variant-dialog .el-input {
	font: normal 13px RionaSans, sans-serif;
}

.add-edit-structural-variant-dialog .el-dialog__body .el-input,
.add-edit-structural-variant-dialog .el-dialog__body .el-input__inner {
	height: 32px;
}

.add-edit-structural-variant-dialog h2.module-header {
	display: flex;
	width: 100%;
	align-items: center;
	font: normal 500 16px RionaSans-Medium, sans-serif;
	margin: 0;
	color: #525968;
	text-transform: capitalize;
}

.add-edit-structural-variant-dialog .el-row {
	margin-top: 16px;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.iscn-sequence
	.el-input__inner,
.add-edit-structural-variant-dialog .el-dialog__body .el-input.iscn-sequence {
	width: 98%;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.el-textarea.iscn-characterization {
	width: 422px;
}

.add-edit-structural-variant-dialog .basic-information-module,
.add-edit-structural-variant-dialog .identification-module,
.add-edit-structural-variant-dialog .interpretation-module,
.add-edit-discrete-variant-dialog .additional-information-module {
	border-bottom: 1px solid #edeff3;
	padding-bottom: 24px;
	margin-bottom: 24px;
}

.add-edit-structural-variant-dialog .nomenclature-label,
.add-edit-structural-variant-dialog .geneticExtent-label {
	font: normal 500 14px RionaSans-Medium, sans-serif;
}

.add-edit-structural-variant-dialog .el-input .el-input__inner::placeholder,
.add-edit-structural-variant-dialog textarea.el-textarea__inner::placeholder {
	font: normal 13px RionaSans, sans-serif !important;
	color: #bac3ce;
	opacity: 1;
}

.add-edit-structural-variant-dialog .required::before {
	content: '*';
	font: normal bold 12px RionaSans, sans-serif;
	color: #e74c3c;
}

.add-edit-structural-variant-dialog .el-dialog__body .el-radio {
	margin-right: 16px;
	display: inline-flex;
}

.add-edit-structural-variant-dialog
	.nomenclature-radiobutton-container
	.el-radio {
	margin-right: 22px;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.gmi-radio-group
	.el-radio
	+ .el-radio {
	margin-bottom: 5px;
}

.add-edit-structural-variant-dialog .el-dialog__body .el-radio__label {
	font: normal 13px RionaSans, sans-serif;
}

.add-edit-structural-variant-dialog .el-dialog__body .dnaChange,
.add-edit-structural-variant-dialog .el-dialog__body .proteinChange {
	display: block;
	margin-bottom: 8px;
}

.add-edit-structural-variant-dialog .el-dialog__body .proteinChange {
	padding-left: 6px;
}

.add-edit-structural-variant-dialog .el-dialog__body .mt-zero {
	margin-top: 0;
}

.add-edit-structural-variant-dialog .el-dialog__body .input-error-text {
	width: 100%;
	font: normal 10px RionaSans, sans-serif;
	color: #ff0000;
	word-break: break-word;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.validation-field-hightlight
	.el-input__inner {
	border: 1px solid #e74c3c;
	background-color: rgb(240 150 141 / 50%);
	color: #e74c3c;
}

.add-edit-structural-variant-dialog
	.el-input.validation-field-hightlight
	.el-input__inner::placeholder {
	color: #e74c3c !important;
}

.add-edit-structural-variant-dialog
	.identification-module
	.unitCopyNumContainer {
	display: flex;
	justify-content: space-around;
	flex-flow: column wrap;
	width: 18.66%;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.el-radio__input.is-disabled
	.el-radio__inner,
.add-edit-structural-variant-dialog
	.el-dialog__body
	.el-radio__input.is-disabled
	.el-radio__inner:hover {
	border: 1px solid #d1dbe5 !important;
}

/******************************************************************************
					Display finding validation message
*******************************************************************************/
.add-edit-structural-variant-dialog
	.el-dialog__body
	.validation-message-container {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	margin: 16px auto 0;
	font: normal 12px RionaSans, sans-serif;
	color: #262837;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.validation-message-container
	.message-type-title {
	display: flex;
	align-items: center;
	font: normal 500 16px RionaSans-Medium, sans-serif;
	color: #000000;
	margin: 16px 0 8px;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.validation-message-container
	.message-type-title
	.warning-icon {
	width: 18px;
	height: 18px;
	margin-right: 4px;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.validation-message-container
	.more-info-icon {
	width: 14px;
	height: 14px;
	margin-left: 3px;
}

.add-edit-structural-variant-dialog
	.el-dialog__body
	.validation-message-container
	.pp-variant-validation {
	min-width: auto !important;
	width: auto !important;
	background-color: #000000;
	color: #ffffff;
	border-radius: 8px;
}

.add-edit-structural-variant-dialog
	.el-popover.pp-variant-validation[x-placement^='top']
	.popper__arrow::after {
	border-top-color: #000000;
}

.add-edit-structural-variant-dialog
	.el-popover.pp-variant-validation[x-placement^='bottom']
	.popper__arrow::after {
	border-bottom-color: #000000;
}

/******************************************************************************
								Dialog Footer
*******************************************************************************/
.add-edit-structural-variant-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 11px 16px;
	display: flex;
	justify-content: end;
}

.add-edit-structural-variant-dialog .el-dialog__footer button {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	width: 160px;
	padding: 8px 12px;
	border-radius: 7px;
}

.add-edit-structural-variant-dialog .el-dialog__footer .save.is-disabled,
.add-edit-structural-variant-dialog .el-dialog__footer .is-disabled:active,
.add-edit-structural-variant-dialog .el-dialog__footer .is-disabled:hover {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
	color: #ffffff;
	border: none;
}

.add-edit-structural-variant-dialog .el-dialog__footer .save {
	border: 1px solid #7b26c9 !important;
	color: #ffffff;
	background-color: #7b26c9;
}

.add-edit-structural-variant-dialog .el-dialog__footer .save:hover {
	background-color: #9c5dd7;
}

.add-edit-structural-variant-dialog .el-dialog__footer .save:active {
	background-color: #621ea0;
}

.add-edit-structural-variant-dialog .el-dialog__footer .delete-variant {
	border: 1px solid #e74c3c !important;
	color: #e74c3c;
	width: 148px;
	padding: 3px 0 3px 10px;
}

.add-edit-structural-variant-dialog
	.el-dialog__footer
	.delete-variant.is-disabled {
	cursor: no-drop;
	opacity: 0.35;
	user-select: none;
	background-color: transparent !important;
	color: #e74c3c !important;
	border: none;
}

.add-edit-structural-variant-dialog .el-dialog__footer .delete-variant span {
	display: flex;
	align-items: center;
}

.add-edit-structural-variant-dialog .el-dialog__footer .delete-variant-icon {
	width: 28px;
	height: 28px;
}

.add-edit-structural-variant-dialog .el-dialog__footer .check-entries {
	width: unset;
	margin: 0 30px 0 auto !important;
	font: normal 500 13px RionaSans-Medium, sans-serif;
	color: #7b26c9;
}

.add-edit-structural-variant-dialog .el-dialog__footer .check-entries:hover,
.add-edit-structural-variant-dialog .el-dialog__footer .check-entries:active,
.add-edit-structural-variant-dialog .el-dialog__footer .check-entries:focus {
	text-decoration: underline;
	color: #7b26c9;
}

.add-edit-structural-variant-dialog
	.el-dialog__footer
	.el-button.check-entries.is-disabled,
.add-edit-structural-variant-dialog
	.el-dialog__footer
	.check-entries.is-disabled:active,
.add-edit-structural-variant-dialog
	.el-dialog__footer
	.check-entries.is-disabled:hover {
	cursor: no-drop;
	opacity: 0.35;
	user-select: none;
	background-color: transparent !important;
	color: #7b26c9 !important;
	border: none;
	text-decoration: none;
}
