.warningbox-container {
	width: 100%;
	border-radius: 4px;
	-webkit-font-smoothing: antialiased;
}

.warningbox-container.bg-gray {
	background-color: #d8dde4;
}

.warningbox-container.bg-yellow {
	background-color: #faedac;
}

.warningbox-container .warningbox-body {
	display: flex;
	justify-content: center;
	padding: 12px 8px;
}

.warningbox-container .outreach-hold-warn-icon {
	width: 24px;
	height: 24px;
}

.warningbox-container .warningbox-body .outreach-hold-warn-text {
	display: flex;
	flex-direction: column;
	width: calc(100% - 24px);
	padding-left: 8px;
	font: normal 500 13px/1.38 RionaSans-MediumItalic;
	color: #262837;
	align-self: center;
}

.warningbox-container .warningbox-body .outreach-start-warn-text {
	display: flex;
	flex-direction: column;
	width: calc(100% - 24px);
	padding-left: 8px;
	font: normal 500 13px/1.38 RionaSans-MediumItalic;
	color: #4b3700;
}
