.extrnl-dashbrd-upcoming-apntmnt-wrapper {
	padding: 0 17px;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-range {
	display: inline-block;
	margin-bottom: 31px;
	font: normal bold 16px/1.38 RionaSans-Bold;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper table {
	border-top: 2px solid #c3cad4;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .cell-text {
	font-family: 'RionaSans-Medium';
	font-size: 12px;
	color: #262837;
	padding: 4px;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper table tr {
	border-top: 1px solid #edeff3;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .td-apntmnt-date {
	vertical-align: baseline;
	padding-top: 13px;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-date {
	display: flex;
	font: normal bold 12px/1.42 RionaSans-Bold;
	align-items: center;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-day-num {
	margin-right: 10px;
	font-size: 13px !important;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-today-day-date {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 21px;
	height: 21px;
	background-color: #7b26c9;
	border-radius: 50%;
	color: #fff;
	margin-right: 4px;
	font: normal bold 13px/1.38 RionaSans-Bold;
	-webkit-font-smoothing: antialiased;
	padding: 7px 7px 4px;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-day {
	font: normal bold 12px/1.42 RionaSans-Bold;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-today-day {
	font: normal bold 12px/1.42 RionaSans-Bold;
	color: #7b26c9;
	-webkit-font-smoothing: antialiased;
	width: 53px;
	white-space: nowrap;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-time {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #262837;
	-webkit-font-smoothing: antialiased;
	width: 92px;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .apntmnt-type {
	font: normal 500 11px/1.36 RionaSans-Medium;
	color: #7b26c9;
	display: inline-block;
	width: 100%;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-upcoming-apntmnt-wrapper .patient-fullname {
	font: normal 500 11px/1.36 RionaSans-Medium;
	color: #262837;
	text-transform: capitalize;
	display: inline-block;
	-webkit-font-smoothing: antialiased;
	cursor: pointer;
}

.extrnl-dashbrd-upcoming-apntmnt-pagination {
	display: flex;
	justify-content: flex-end;
	margin-top: 38px;
	-webkit-font-smoothing: antialiased;
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.extrnl-dashbrd-upcoming-apntmnt-pagination .prev-page-button,
.extrnl-dashbrd-upcoming-apntmnt-pagination .next-page-button {
	color: #7b26c9;
	bottom: 0px;
	top: 1px;
}

.extrnl-dashbrd-upcoming-apntmnt-pagination button {
	font: normal 14px/1.43 RionaSans;
}
.extrnl-dashbrd-upcoming-apntmnt-wrapper .no-appointment {
	font-family: 'RionaSans-Medium';
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: center;
	color: #a0abb9;
	border-top: 1px solid #a0abb9;
	padding-top: 30px;
}
