/* new css code here*/
.scp-encounter-accordian-body {
	width: 100%;
	padding: 8px;
	border: solid 1px #edeff3;
	border-top: 0;
}
.scp-encounter-accordian-body .scp-testorder-rows {
	border-radius: 8px;
	border: 1px solid #d8dde4;
}
.scp-encounter-accordian-body .scp-testorder-rows .scp-testorder-row {
	width: 100%;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container {
	border-radius: 8px 8px 0 0;
	background-color: #edeff3;
	width: 100%;
	min-height: 108px;
	padding: 16px;
}

.scp-testorder-row .testorder-tracking-bar {
	display: flex;
}

.scp-testorder-row .testorder-tracking-bar {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.scp-testorder-row .testorder-tracking-bar .testorder-left-side {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-right: 10px;
}

.scp-testorder-row .testorder-testname-bar .val-test-name {
	padding: 0;
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #262837;
	max-width: calc(100% - 220px);
}

.scp-testorder-row .testorder-testname-bar .val-test-name span {
	text-align: left;
	width: 100%;
	max-width: 100% !important;
}

.scp-testorder-row
	.testorder-testname-bar
	.val-test-name
	.text-with-tooltip:hover,
.scp-testorder-row
	.testorder-testname-bar
	.val-test-name
	.text-with-tooltip:active {
	text-decoration: underline;
	color: #262837;
}

.scp-testorder-row .testorder-testname-bar .disable-btn-extrnl-user {
	pointer-events: none;
}

.scp-testorder-row .testorder-testname-bar .button-text {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	padding: 0;
}

.scp-testorder-row .testorder-testname-bar .button-text:hover,
.scp-testorder-row .testorder-testname-bar .button-text:active,
.scp-testorder-row .testorder-testname-bar .button-text:focus {
	text-decoration: underline;
	color: #7b26c9;
}

.testorder-testname-bar .divider-dot {
	margin: 0 8px 4px;
	font: normal bold 13px/1.38 RionaSans-Bold;
	color: #7b8291;
}

.scp-testorder-row .testorder-testname-bar .link-icd-10,
.scp-testorder-row .testorder-testname-bar .link-notes {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	padding: 0;
}

.scp-testorder-row .testorder-testname-bar .link-icd-10:hover,
.scp-testorder-row .testorder-testname-bar .link-icd-10:active,
.scp-testorder-row .testorder-testname-bar .link-icd-10:focus,
.scp-testorder-row .testorder-testname-bar .link-notes:hover,
.scp-testorder-row .testorder-testname-bar .link-notes:active,
.scp-testorder-row .testorder-testname-bar .link-notes:focus {
	text-decoration: underline;
	color: #7b26c9;
}

.scp-testorder-row .testorder-testname-bar .link-icd-10 {
	margin-left: auto !important;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.testorder-testname-bar {
	font-family: RionaSans-Bold;
	font-size: 15px;
	font-weight: bold;
	font-style: normal;
	line-height: 1.4;
	color: #262837;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.testorder-testname-bar
	img {
	cursor: pointer;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.lab-name {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	color: #525968;
}

/*progress bar css*/
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper {
	z-index: 1;
	min-width: 64%;
	margin-top: 16px;
}

.scp-encounter-accordian-body .scp-testorder-rows .scp-testorder-row .scp-testorder-row-container .unsolicited-result-progressbar {
	min-width: 87%;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li {
	float: left;
	min-width: 20%;
	position: relative;
	text-align: center;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li
	span.circle::after {
	width: 18px;
	height: 18px;
	display: block;
	margin: 0 0 8px 0;
	border-radius: 52%;
	line-height: 20px;
	text-align: center;
	font-weight: bold;
	font-size: 10px;
	background: #ffffff;
	content: ' ';
	color: #ffffff;
	border-color: #bac3ce;
	border-width: 1px;
	border-style: solid;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.done
	span.circle::after {
	border-color: #00a044;
	background-image: url('../../../../../../assets/check-bg.svg');
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.stop
	span.circle::after {
	border-color: #ad1505;
	background-image: url('../../../../../../assets/x-bg.svg');
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.inprogress
	span.circle::after {
	border-color: #bc932c;
	background-image: url('../../../../../../assets/awaiting.svg');
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li::before {
	content: '';
	position: absolute;
	width: 100%;
	top: 9px;
	left: -100%;
	z-index: -1;
	border-top-style: solid;
	border-top-color: #bac3ce;
	border-top-width: 1px;
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.done::before {
	border-top-style: solid;
	border-top-color: #00a044;
	border-top-width: 1px;
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.inprogress::before {
	border-top-style: solid;
	border-top-color: #bc932c;
	border-top-width: 1px;
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li:first-child::before {
	content: none;
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li
	div.step-name {
	width: 88px;
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #a0abb9;
	text-transform: capitalize;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li
	div.result-actionable {
	width: 160px;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.done
	div.step-name {
	color: #00a044;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.inprogress
	div.step-name {
	color: #bc932c;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li.stop
	div.step-name {
	color: #ad1505;
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.progressbar-wrapper
	.progressbar
	li
	div.step-date {
	font-family: RionaSans;
	font-size: 12px;
	font-style: normal;
	line-height: 1.4;
	text-align: left;
	color: #7b8291;
}

.scp-testorder-row .scp-testorder-row-middle {
	padding: 20px 29px;
	border-bottom: 1px solid #d8dde4;
}
.scp-testorder-row .scp-testorder-row-middle .inner-scp-testorder-middle {
	display: flex;
	width: 100%;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half {
	width: calc(100% / 2);
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.clinical-flag-title {
	margin: 0 0 8px 0;
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-style: normal;
	line-height: 1.43;
	color: #262837;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.medical-history-content {
	margin-bottom: 8px;
	font-family: RionaSans;
	font-size: 12px;
	font-style: italic;
	line-height: 1.42;
	color: #7b8291;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.add-flag {
	width: 100%;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.add-flag
	> a {
	font-family: RionaSans-Medium;
	font-size: 10px;
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	line-height: 1.4;
	color: #7b26c9;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.add-flag
	> a
	.add-flag-icon {
	width: 17px;
	margin-right: 5px;
	display: inline-block;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.add-flag
	> a
	.add-flag-icon
	img {
	width: 100%;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.scp-content-list {
	width: 100%;
	padding-left: 20px;
	margin-bottom: 11px;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.scp-content-list
	ul {
	padding: 0;
	margin: 0;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.scp-content-list
	ul
	li {
	font-family: RionaSans;
	font-size: 12px;
	margin-bottom: 5px;
	font-weight: normal;
	line-height: 1.67;
	color: #262837;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.questionnaire-link {
	width: 100%;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.questionnaire-link
	ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.questionnaire-link
	ul
	li {
	margin-bottom: 5px;
}

.scp-testorder-row .scp-testorder-row-middle .inner-scp-testorder-middle .scp-testorder-half .unsolicited-result ul li {
	display: inline-block;
    margin-right: 48px;
}

.scp-testorder-row .scp-testorder-half .unsolicited-result .related-documents-title {
    font: normal bold 14px RionaSans-Bold;
    color: #262837;
    text-transform: uppercase;
}

.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.questionnaire-link
	ul
	li
	a {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	text-decoration: underline;
	color: #40b9ec;
}
.scp-testorder-row
	.scp-testorder-row-middle
	.inner-scp-testorder-middle
	.scp-testorder-half
	.questionnaire-link
	ul
	li
	a.disabled {
	opacity: 0.35;
	cursor: not-allowed;
}
.scp-testorder-row .scp-testorder-row-footer {
	display: flex;
	justify-content: flex-end;
	padding: 12px 29px;
}
.scp-testorder-row .scp-testorder-row-footer .btn-segment {
	display: inline-flex;
}
.scp-testorder-row .scp-testorder-row-footer .btn-segment > button {
	margin-left: 8px;
}
.scp-testorder-row .scp-testorder-row-footer .btn-segment .reject-btn,
.scp-testorder-row .scp-testorder-row-footer .btn-segment .approve-btn {
	min-width: 157px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	border-radius: 6.5px;
	line-height: 1.38;
	transition: 0.4s ease all;
}
.scp-testorder-row-footer .btn-segment .reject-btn .reject-btn-icon,
.scp-testorder-row-footer .btn-segment .approve-btn .approve-btn-icon {
	width: 18px;
	margin-right: 14px;
	display: inline-block;
}
.scp-testorder-row-footer .btn-segment .reject-btn .reject-btn-icon img,
.scp-testorder-row-footer .btn-segment .approve-btn .approve-btn-icon img {
	width: 100%;
}
.scp-testorder-row-footer .btn-segment .reject-btn {
	border: 1px solid #ec6759;
	color: #ec6759;
	background-color: #ffffff;
}
.scp-testorder-row-footer .btn-segment .reject-btn:hover,
.scp-testorder-row-footer .btn-segment .reject-btn.selected {
	background-color: rgba(231, 76, 60, 0.16);
}
.scp-testorder-row-footer .btn-segment .reject-btn:active {
	background-color: rgba(231, 76, 60, 0.3);
}

.scp-testorder-row-footer .btn-segment .reject-btn:focus {
	border: 1px dotted #ec6759;
	background-color: rgba(236, 103, 89, 0.15);
}

.scp-testorder-row-footer .btn-segment .reject-btn.disabled {
	opacity: 0.35;
}

.scp-testorder-row-footer .btn-segment .approve-btn {
	background-color: #00d65b;
	color: #ffffff;
	border: 1px solid #00d65b;
}
.scp-testorder-row-footer .btn-segment .approve-btn:hover {
	background-color: rgba(0, 214, 91, 0.5);
}
.scp-testorder-row-footer .btn-segment .approve-btn:active {
	background-color: rgba(0, 214, 91, 0.7);
}
.scp-testorder-row-footer .btn-segment .approve-btn.selected {
	background-color: #00d65b;
}
.scp-testorder-row-footer .btn-segment .approve-btn:focus {
	border: 1px dotted #00d65b;
	background-color: rgba(0, 214, 91, 0.35);
}
.scp-testorder-row-footer .btn-segment .approve-btn.disabled {
	opacity: 0.35;
	background-color: #00d65b;
}
/*PERTINENT CLINICAL INFO*/
.encounter-accordian-body .pci-encounter-accordian-body {
	padding: 2px 8px 0;
}
.encounter-accordian-body .pci-encounter-accordian-body .pci-segment-row {
	width: 100%;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row.requested-test-segment {
	padding: 32.5px 0 7px;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-label-row {
	margin-bottom: 9px;
	width: 100%;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-label-row
	.pci-encounter-field-header {
	width: 100%;
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	color: #262837;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-content-row {
	width: 100%;
	display: flex;
	margin-bottom: 26px;
}

.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-content-row
	.pci-left-segment-content {
	width: 37.2%;
	padding-right: 50px;
}

.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-content-row
	.pci-right-segment-content {
	width: 37.2%;
	padding-left: 18px;
	padding-right: 50px;
}

.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-content-header {
	font-family: RionaSans;
	font-size: 12px;
	line-height: 1.42;
	color: #262837;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-content-bottom {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	color: #262837;
}

.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-list {
	width: 100%;
	display: flex;
	padding-bottom: 44.5px;
	border-bottom: 1px solid #edeff3;
}

.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-list
	.pci-list-segment-col {
	margin: 0;
	padding-left: 18px;
	padding-right: 50px;
	width: 100%;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-list.no-border {
	border-bottom: none !important;
	padding-bottom: 0px !important;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-list
	.pci-list-segment-col
	li {
	font-family: RionaSans;
	font-size: 14px;
	line-height: 1.43;
	color: #262837;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-content {
	width: 100%;
}
.encounter-accordian-body
	.pci-encounter-accordian-body
	.pci-segment-row
	.pci-segment-content
	p {
	font-family: RionaSans;
	font-size: 14px;
	font-style: italic;
	line-height: 1.43;
	color: #262837;
	margin: 0;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-right-side
	.status-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-right-side
	.status-wrapper
	.status-text {
	height: 20px;
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	text-align: left;
	color: #00d65b;
	margin-left: 5px;
	text-transform: uppercase;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-right-side
	.status-wrapper
	.request_status_image {
	height: 16px;
	width: 16px;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-right-side
	.status-wrapper
	.status-text.rejected {
	color: #a0abb9 !important;
}

/* TODO:: start: remove once EHR-7069 will go to prod */
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.test-result
	.el-button {
	height: 32px;
	width: 123px;
	background-color: #7b26c9;
	font-size: 11px;
	font-family: RionaSans-Medium;
	border-radius: 6.5px;
	padding: 6px 27px;
	margin-top: 5px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	border: none;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.testorder-tracking-bar
	.testorder-left-side
	.edit-result {
	height: 32px;
	width: 123px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.testorder-tracking-bar
	.testorder-left-side
	.edit-result
	.edit-result-icon {
	margin-right: 5px;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.testorder-tracking-bar
	.testorder-left-side
	.edit-result
	.edit-result-icon
	img {
	width: 22px;
	height: 22px;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.testorder-tracking-bar
	.testorder-left-side
	.edit-result
	.edit-result-text {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.test-result {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #40b9ec;
	text-decoration: underline;
	cursor: pointer;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.test-result
	img {
	width: 15px;
	height: 15px;
	margin-left: 5px;
	cursor: pointer;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.testorder-extra-info-bar {
	display: flex;
	margin-top: 24px;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.ordering-sec {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-left: auto;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.ordering-sec
	.lbl-ordering-physician,
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.testorder-extra-info-bar
	.label-class {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.ordering-sec
	img {
	width: 20px;
	height: 20px;
	margin-right: 4px;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.testorder-tracking-bar
	.ordering-sec
	.val-ordering-physician,
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.ordering-sec
	.value-class {
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #262837;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.testorder-extra-info-bar
	.icd10-codes-sec
	.icd-10-containers {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-top: 4px;
}

.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.testorder-extra-info-bar
	.icd10-codes-sec
	.icd-10-containers
	.icd10-bubble {
	border: 1px solid #bd92e4;
	border-radius: 4px;
	height: 20px;
	margin-right: 8px;
	margin-bottom: 4px;
	padding: 3px 4px;
	background-color: #ddcaef;
	font: normal 500 11px/1.36 RionaSans;
	color: #491678;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.test-result
	.el-button {
	background-color: #7b26c9;
	font-size: 11px;
	font-family: RionaSans-Medium;
	border-radius: 6.5px;
	padding: 6px 27px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	border: none;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.test-result
	.el-button:hover {
	background-color: #9c5dd7;
}

.encounter-accordian-body
	.testorder-rows
	.testorder-row
	.scp-testorder-row-container
	.testorder-left-side
	.test-result
	.el-button:active {
	background-color: #621ea0;
}
/* TODO:: end: remove once EHR-7069 will go to prod */

/******** start: rejected test request reason tooltip *******************/
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-right-side
	.progressbar
	li.stop
	.tt-rejected-test-reason
	.div.elel-tooltip__popper.is-da {
	min-width: 250px;
	width: 200px !important;
	text-align: left !important;
}
.scp-encounter-accordian-body
	.scp-testorder-rows
	.scp-testorder-row
	.scp-testorder-row-container
	.testorder-right-side
	.progressbar
	li.stop
	.tt-rejected-test-reason {
	cursor: pointer;
}
/******** end: rejected test request reason tooltip *******************/

.flaggedForReviewContainer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 6px;
}

.flaggedForReviewNoItems {
	font-family: 'RionaSans-RegularItalic';
	font-size: 12px;
	color: #7b8291;
}

.flaggedForReviewAddItems {
	font-size: 12px;
	color: #7b26c9;
	margin-top: 6px;
	cursor: pointer;
	width: fit-content;
}

.flaggedForReviewAddItems:hover {
	text-decoration: underline;
}

.flaggedForReviewFlag {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-radius: 9px;
	margin-bottom: 3px;
}

.flaggedForReviewFlagMain {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: calc(100% - 60px);
	padding-left: 4px;
}

.flaggedForReviewFlagArchive {
	padding-right: 12px;
	font-size: 10px;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	color: #7b26c9;
	cursor: pointer;
}

.flaggedForReviewFlagArchive:hover {
	text-decoration: underline;
}

.flaggedForReviewFlag:hover {
	background-color: #edeff3;
}

.flaggedForReviewNewFlag {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.flaggedForReviewIconContainer {
	padding-left: 6px;
	padding-top: 3px;
}

.flaggedForReviewIcon > div > svg > path {
	fill: #7b26c9;
}

.flaggedForReviewFlagBulletPoint {
	font-size: 18px;
	transform: scale(1.7);
	margin-right: 12px;
	padding-bottom: 2px;
}

.flaggedForReviewNewFlagInput {
	width: calc(100% - 35px);
	height: 32px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: white;
	padding-left: 6px;
	font-size: 13px !important;
	margin-bottom: 4px;
}

.flaggedForReviewNewFlagX {
	height: 22px;
	width: 22px;
	position: relative;
	top: -1px;
	left: 6px;
	cursor: pointer;
	border-radius: 25px;
}

.flaggedForReviewNewFlagX:hover {
	background-color: #ececec;
}

.flaggedForReviewNewFlagX:active {
	background-color: #d8d8d8;
}

.flaggedForReviewNewFlagX > div > div > svg > g > g {
	fill: #262837;
}

.flaggedForReviewNewFlagInput:hover {
	border: 1px solid #70cbf1;
}

.flaggedForReviewNewFlagInput:focus,
.flaggedForReviewNewFlagInput:active {
	border: 1.5px solid #40b9ec;
}

.flaggedForReviewNewFlagInput::placeholder {
	font-size: 13px !important;
	color: #bac3ce;
	-webkit-font-smoothing: antialiased;
}

.flaggedForReviewFlagText {
	font-size: 12px;
	color: #262837;
	padding: 2px 0px;
}

.flaggedForReviewFlagArchived {
	text-decoration: line-through;
}

.flaggedForReviewIconContainer > div.el-tooltip__popper {
	min-width: 250px;
}

.flaggedForReviewTooltip {
	font-size: 12px;
	color: white;
	display: flex;
	flex-direction: column;
}

.flaggedForReviewTooltipHeader {
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
}

.flaggedForReviewTooltipBody {
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
}

.flaggedForReviewButtonRow {
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.flagArchiveModalContainer {
	width: 368px;
	height: 380px;
	position: absolute;
	top: calc(50vh - 188px);
	left: calc(50vw - 184px);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
}

.modalAlertIcon {
	margin-right: 12px;
	transform: scale(1.25);
	padding-top: 3px;
}

.flagArchiveModalBody {
	display: flex;
	flex-direction: column;
	padding: 32px 16px 35px 16px;
}

.flagArchiveModalLabel {
	font-size: 13px;
	color: #262837;
}

.flagArchiveModalDescription {
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 15px;
	color: #262837;
	height: 64px;
	max-height: 64px;
	overflow-y: auto;
	padding-top: 1px;
	margin-bottom: 3px;
}

.flagArchiveModalInputContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
}

.flagArchiveModalRequired {
	color: red;
	font-size: 15px;
	font-family: RionaSans-Bold;
	top: 0;
	left: -9px;
	position: absolute;
}

.flagArchiveModalInputLabel {
	font-size: 12px;
	color: #525968;
}

.flagArchiveModalInputTextArea {
	width: 336px;
	height: 79px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: white;
	resize: none;
	outline: none;
	padding: 8px;
	font-size: 13px !important;
}

.flagArchiveModalInputTextArea::placeholder {
	font-size: 13px !important;
	color: rgb(186, 195, 206);
	-webkit-font-smoothing: antialiased;
}

.flagArchiveModalInputTextArea:hover {
	border: 1px solid #70cbf1;
}

.flagArchiveModalInputTextArea:focus,
.flagArchiveModalInputTextArea:active {
	border: 1.5px solid #40b9ec;
}

.flagArchiveModalInputRemainingCount {
	margin-left: auto;
	font-size: 12px;
	color: #a0abb9;
	padding-top: 1px;
}

.flagArchiveModalButtonRow {
	height: 60px;
	width: 100%;
	padding: 11px 18px;
	border-top: 2px solid #edeff3;
}

.flagArchiveModalButton {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	border: 1px solid #ad1505;
	background-color: #ad1505;
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	color: white;
	text-align: center;
	user-select: none;
	padding-top: 9px;
	margin-left: auto;
	cursor: pointer;
}

.flagArchiveModalButton:hover {
	background-color: #ec6759;
}

.flagArchiveModalButton:active {
	background-color: #e74c3c;
}

.flagArchiveModalButtonDisabled {
	cursor: no-drop;
	background-color: #e74c3c !important;
	opacity: 0.35;
}

.flagArchiveLoader {
	top: 50%;
}
