.insurance-container {
	margin: 60px 0 0 68px;
	min-height: calc(100vh - 84px);
	min-width: 1204px;
	background-color: #edeff3;
	border-radius: 6px;
	padding: 16px 0 0 60px;
	margin: 46px auto;
}

.insurance-list-header {
	display: flex;
}

.btn-add-insurance {
	width: 164px;
	height: 36px;
	display: flex;
	align-items: center;
	background-color: #7b26c9;
	font: 500 13px RionaSans;
	color: #ffffff;
	border-radius: 6px;
	border: none;
	margin-left: 28px;
	cursor: pointer;
}

.btn-add-insurance:hover {
	background-color: #9c5dd7;
}

.btn-add-insurance:active {
	background-color: #621ea0;
}

.btn-add-insurance .btn-add-create-template {
	line-height: 0.8;
}

.btn-add-insurance .text_space {
	padding-left: 13px;
}

.tbl-insurance-list {
	margin-top: 16px;
	font: normal 14px RionaSans;
	color: #525968;
}

.prePayText:hover {
	text-decoration: underline;
	cursor: pointer;
}

.insurance-container .tbl-insurance-list .is-disabled .el-checkbox__inner {
	opacity: 0.5;
}
