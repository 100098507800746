@use '../../../../Common/V3/system';

.insurance-fields {
	.gmi-radio-group {
		display: flex;
		gap: system.$singleUnit;
		.el-radio {
			margin-bottom: 0px;
		}
	}
}
