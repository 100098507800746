@use '../core';

.#{core.$scopeClass} {
	.additional-patient-info {
		.insurance-fields {
			@include core.threeColumnLayout;
			.el-select {
				width: 100%;
			}
		}
		.other-demographic-identifiers-fields {
			@include core.threeColumnLayout;
			.el-select {
				width: 100%;
			}
		}
	}
}
