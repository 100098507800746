.PatientDeleteDialog {
	z-index: 100000 !important;
	position: fixed !important;
	width: inherit !important;
}

.PatientDeleteDialog .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.PatientDeleteDialog .el-dialog__body {
	padding: 16px;
}

.PatientDeleteDialog .el-dialog__footer {
	padding: 12px 16px;
	border-top: 1px solid #edeffe;
	display: flex;
	justify-content: space-between;
}

.PatientDeleteDialog .el-dialog__body .dob {
	margin-top: 19px;
}

.PatientDeleteDialog .chk-confirm-delete-patient {
	margin-top: 19px;
	display: inline-flex;
	white-space: inherit;
}

.PatientDeleteDialog .chk-confirm-delete-patient .el-checkbox__label {
	padding-left: 9px !important;
	font: normal 13px/1.38 RionaSans-Medium;
}

.PatientDeleteDialog .el-tooltip__popper {
	text-align: left;
}

.PatientDeleteDialog .el-tooltip__rel div {
	pointer-events: none;
}

.PatientDeleteDialog .title {
	color: #464646;
	font-size: 1.31vw;
	margin-bottom: 5px;
}

.PatientDeleteDialog .error-title {
	color: #e74c3c;
}

.PatientDeleteDialog .error-summary {
	background: #e74c3c;
	color: #ffffff;
	padding: 0.87vw;
	text-align: center;
}

.PatientDeleteDialog .error-image {
	border-radius: 1.09vw;
	height: 1.61vw;
	border: solid 0.07vw #ffff;
	margin-right: 0.21vw;
}

.PatientDeleteDialog #patient-form-dialog-title {
	padding: 0 !important;
}

.PatientDeleteDialog .dialog-content {
	font-size: 0.87vw;
}

.PatientDeleteDialog .encounters {
	margin-right: 20%;
}

.PatientDeleteDialog .el-dialog__footer button {
	font: normal 500 13px/1.38 RionaSans-Medium;
	border-radius: 6px;
	height: 36px;
	width: 140px;
}

button.delete-patient,
button.delete-patient:hover {
	background: #e74c3c;
	color: #ffffff;
	text-transform: none;
	box-shadow: none;
	border: 0.07vw solid #ccc;
}

button.cancel-patient,
button.cancel-patient:hover {
	background: #ffffff;
	color: #979197;
	text-transform: none;
	box-shadow: none;
	border: 1px solid #ccc;
}

/* EHR-4364 */
div#patient-delete-tooltip {
	z-index: 100000;
}

#patient-delete-tooltip div {
	font-size: 12px;
	max-width: 100%;
	font-family: 'RionaSans', serif !important;
}

button.disabled,
button.disabled:hover {
	opacity: 0.45;
	background: #f26058;
	cursor: not-allowed;
}

span.delete-patient-text {
	font-size: 0.8vw;
	font-weight: bold;
}

span.cancel-patient-text {
	font-size: 0.8vw;
	font-weight: bold;
}

.dialog-content {
	overflow: hidden;
}

.dialog-content .header {
	font-size: 1.17vw;
	margin-top: 10px;
}

.dialog-content .error-message {
	font-size: 0.87vw;
}

.dialog-content .impact-area-summary {
	font-size: 0.95vw;
	margin-left: -0.51vw;
}

.dialog-content .form-group label span {
	font-size: 0.87vw;
	font-family: 'RionaSans', serif !important;
}

.el-checkbox {
	margin-top: 0 px;
}

.dialog-content fieldset textarea {
	font-size: 0.87vw;
	font-family: 'RionaSans', serif !important;
	line-height: 1.5vw;
}

.clear {
	clear: 'both';
}

.el-notification__content {
	text-align: initial !important;
}

.el-notification {
	font-family: 'RionaSans';
}
