.outreachlog-tab {
	padding-top: 28px;
	display: flex;
	flex-direction: column;
}

.outrch-tab {
	padding: 24px 0 0 0;
	display: flex;
	justify-content: center;
}

.outrch-tab .inner-outrch-tab {
	max-width: 720px;
	width: 100%;
}

.outrch-tab .inner-outrch-tab .outrch-header-section {
	margin: 0 0 16px 0;
	display: flex;
	justify-content: space-between;
}

.outrch-tab .inner-outrch-tab .outrch-header-section .outreach-btn {
	padding: 4px 16px 4px 8px;
	background-color: #7b26c9;
	border: 0;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
	/* transition: ease 0.4s all; */
}

.outrch-tab .inner-outrch-tab .outrch-header-section .outreach-btn:hover {
	background-color: #9c5dd7;
}

.outrch-tab .inner-outrch-tab .outrch-header-section .outreach-btn img {
	margin-right: 8px;
}

.outrch-tab .inner-outrch-tab .outrch-body-section {
	width: 100%;
}

.outrch-tab .inner-outrch-tab .outrch-body-section .Outrch-list {
	width: 100%;
	margin-bottom: 12px;
}

.outrch-tab .inner-outrch-tab .outrch-body-section .Outrch-list .Outrch-record {
	display: flex;
	justify-content: space-between;
	border: solid 1px #edeff3;
	border-bottom: 0;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record:last-child {
	border-bottom: solid 1px #edeff3;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record
	.outrch-icon {
	width: 64px;
	display: flex;
	justify-content: center;
	align-self: flex-start;
	padding: 16px 0 0 0;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record
	.outrch-content {
	width: calc(100% - 64px);
	padding: 16px 16px 11px 0;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record
	.outrch-content
	.inner-outrch-content {
	display: flex;
	justify-content: space-between;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record
	.outrch-content
	.light-font {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	color: #7b8291;
	white-space: nowrap;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record
	.outrch-content
	.bold-font {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #262837;
	margin-right: 16px;
	white-space: pre-wrap;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record
	.outrch-content
	.italic-font {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: italic;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record.no-outreach
	.light-bold-font {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
}

/* outreach list empty */

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record.no-outreach {
	align-items: center;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record.no-outreach
	.log-empty-icon {
	width: 64px;
	padding: 16px;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record
	.outrch-content.no-reach-content {
	padding: 0;
}

/* outreach popup */

.outrch-tab .outrch-dialog {
	width: 368px;
	max-height: calc(100vh - 120px);
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.outrch-tab .outrch-dialog .el-dialog__header {
	padding: 17px 22px 17px 16px;
	height: 56px;
	border-bottom: solid 1px #edeff3;
}

.outrch-tab .outrch-dialog .el-dialog__header .el-dialog__title {
	font-family: RionaSans;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__header
	.el-dialog__headerbtn
	.el-dialog__close {
	font-size: 12px;
	color: #262837;
	font-weight: bold;
}

.outrch-tab .outrch-dialog .el-dialog__body {
	padding: 32px 16px;
	overflow-y: auto;
	max-height: calc(100vh - 240px);
}

.outrch-tab .outrch-dialog .el-dialog__body .outrch-dialog-body {
	width: 100%;
}

.outrch-tab .outrch-dialog .el-dialog__body .outrch-dialog-body .segment {
	margin-bottom: 19px;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segememt-header {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	position: relative;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segememt-header:after {
	content: '*';
	margin-left: -6px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: red;
	vertical-align: text-top;
	position: absolute;
	left: 0;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select {
	width: 100%;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select
	.el-input__inner,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control {
	height: 32px;
	color: #262837;
	font-size: 13px !important;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select
	.el-input__inner::placeholder,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control::placeholder {
	color: #bac3ce;
	font-size: 13px !important;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-textarea
	.el-textarea__inner {
	font-size: 13px;
	min-height: 84px;
	padding: 7px 8px;
}

.outrch-tab .outrch-dialog .el-dialog__footer {
	border-top: solid 1px #edeff3;
	padding: 12px 16px;
}

.outrch-tab .outrch-dialog .el-dialog__footer .outreach-btn {
	padding: 9px 12px;
	background-color: #7b26c9;
	min-width: 160px;
	border: solid 1px #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.outrch-tab .outrch-dialog .el-dialog__footer .outreach-btn:hover {
	background-color: #9c5dd7;
	border-color: solid 1px #9c5dd7;
}

.outrch-tab .outrch-dialog .el-dialog__footer .outreach-btn:disabled {
	border: unset;
}

/* custom date css popup */

/*START HERE*/

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt::before {
	display: none;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control {
	border: 1px solid #bfcbd9;
	color: #1f2d3d;
	box-shadow: unset;
	height: 32px;
	font-size: 13px !important;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control:disabled {
	color: #bbb;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control:hover {
	border-color: #8391a5;
}

/*datepicker when focus*/

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control:focus {
	border-color: #20a0ff;
	box-shadow: unset;
}

/*datepicker body*/

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker {
	padding: 12px;
	box-shadow: 0 2px 6px #ccc;
	border: 1px solid #f9f9f9;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tr,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tr,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tr {
	border-bottom: 0 !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th {
	font-size: 14px;
	vertical-align: inherit;
	border-bottom: 0;
	font-weight: 400;
	color: #48576a;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th.rdtPrev,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th.rdtNext,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtPrev,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtNext,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtPrev,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtNext {
	font-size: 21px;
	color: #97a8be;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:first-child
	th:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr:first-child
	th:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr:first-child
	th:hover {
	background-color: transparent;
	color: #20a0ff;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:nth-child(2)
	th {
	font-size: 12px;
	vertical-align: inherit;
	color: #8391a5;
	height: 30px;
	padding-top: 15px;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td {
	font-size: 12px;
	width: 32px;
	height: 32px;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear {
	color: #48576a;
	font-size: 12px;
	height: 32px;
	width: 48px;
	text-shadow: unset;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear:hover {
	background-color: #e4e8f1;
	text-decoration: underline;
}

/*prev-month and next-month css*/

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtOld,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtOld,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew {
	color: #ddd;
}

/*prev-month and next-month css on hover*/

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtOld:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew:hover {
	background: transparent !important;
}

/*rdt day active .rdtActive rdtDay rdtActive*/

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive:hover {
	background-color: #20a0ff;
	color: #ffffff;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtToday:before {
	content: ' ';
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	border-top: 0.5em solid #20a0ff;
	border-left: 0.5em solid transparent;
	bottom: unset;
	border-bottom: 0;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay:hover {
	background-color: #e4e8f1;
}

/*date and time*/

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot {
	border-top: 0;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td {
	font-size: 14px;
	font-weight: 400;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td:hover,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td:hover {
	background-color: transparent;
	color: #20a0ff;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th {
	height: 32px;
	font-size: 14px;
	font-weight: 400;
	color: #48576a;
	border-bottom: 0;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th:hover,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th:hover {
	background-color: transparent;
	color: #20a0ff;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr,
.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr {
	border-bottom: 0 !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtCount {
	font-size: 14px;
	color: #48576a;
}

/* error msg */

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.input-error {
	width: 100%;
	font-family: RionaSans;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ff0000;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.error-field
	.form-control,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.error-field
	.el-input__inner,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.error-field
	.el-textarea__inner {
	border: solid 1px #fb3a2e !important;
	background-color: rgba(254, 145, 137, 0.5) !important;
}

.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.error-field
	.form-control::placeholder,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.error-field
	.el-textarea__inner::placeholder,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.error-field
	.el-input__inner::placeholder {
	color: #fb3a2e !important;
}

.Outrch-record.outrchedit {
	cursor: pointer;
}

.Outrch-record.outrchnonedit {
	cursor: not-allowed;
}

.outrch-tab
	.inner-outrch-tab
	.outrch-body-section
	.Outrch-list
	.Outrch-record:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
	border: solid 1px #edeff3;
	background-color: #ffffff;
}

.noOutreachRowContainer {
	width: 100%;
	height: 64px;
	border: 1px solid #ffdce0;
	background-color: #ffdce0;
	display: flex;
	flex-direction: row;
	padding: 16px;
	color: #86181d;
	margin-bottom: 16px;
}

.noOutreachRowIcon {
	transform: scale(1.5);
	position: relative;
	top: 5px;
	left: 8px;
}

.noOutreachRowIcon > div > svg > g > path {
	fill: #86181d;
}

.noOutreachRowMain {
	display: flex;
	flex-direction: column;
	width: calc(100% - 32px);
}

.noOutreachRowMainTopRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 12px;
}
.noOutreachRowMainTopRow .reason {
	font-style: italic;
}

.noOutreachRowMainCenterRow {
	font-size: 14px;
	font-family: 'RionaSans-Bold';
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.noOutreachRowMainBottomRow {
	font-size: 12px;
	font-style: italic;
}

/****************************************************************
                start: Outreach Log V2  
*****************************************************************/
.outrchlog-tab {
	padding: 16px 22px 20px;
}

.outrchlog-tab .Outrch-list .Outrch-record.no-outreach {
	align-items: center;
}

.outrchlog-tab .Outrch-list .Outrch-record.no-outreach .log-empty-icon {
	width: 64px;
	padding: 16px;
}

/*************************************************************************** 
                            Add Outreach Popup    
 ***************************************************************************/

.outrchlog-tab .outrch-dialog {
	width: 368px;
	position: fixed;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.outrchlog-tab .outrch-dialog .el-dialog__header {
	padding: 17px 12px 12px 16px;
	height: 56px;
	border-bottom: 1px solid #edeff3;
}

.outrchlog-tab .outrch-dialog .el-dialog__header .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	color: #262837;
}

.outrchlog-tab .outrch-dialog .el-dialog__header .el-dialog__headerbtn {
	font-size: 13px;
	font-weight: bolder;
	color: #262837;
	height: 32px;
	width: 32px;
	margin-top: -4px;
	border-radius: 16px;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__header
	.el-dialog__headerbtn
	.el-icon-close {
	color: #000;
	font-weight: bolder;
}

.outrchlog-tab .outrch-dialog .el-dialog__header .el-dialog__headerbtn:hover {
	background-color: #ececec !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__header
	.el-dialog__headerbtn:hover
	.el-dialog__close {
	color: #262837;
}

.outrchlog-tab .outrch-dialog .el-dialog__header .el-dialog__headerbtn:active {
	background-color: #c7c7c7 !important;
}

.outrchlog-tab .outrch-dialog .el-dialog__body {
	padding: 32px 16px;
	overflow-y: auto;
	max-height: calc(100vh - 235px);
}

.outrchlog-tab .outrch-dialog .el-dialog__body .outrch-dialog-body {
	width: 100%;
}

.outrchlog-tab .outrch-dialog .el-dialog__body .outrch-dialog-body .segment {
	margin-bottom: 19px;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segememt-header {
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	position: relative;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segememt-header:after {
	content: '*';
	margin-left: -6px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: red;
	vertical-align: text-top;
	position: absolute;
	left: 0;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select {
	width: 100%;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt::before {
	display: none;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select
	.el-input__inner,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control {
	height: 32px;
	color: #262837;
	font-size: 13px !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.el-input.is-disabled
	.el-input__inner {
	color: #bac3ce !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select
	.el-input__inner::placeholder,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control::placeholder {
	color: #bac3ce;
	font-size: 13px !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-textarea
	.el-textarea__inner {
	font-size: 13px;
	min-height: 84px;
	padding: 7px 8px;
}

.outrchlog-tab .outrch-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 12px 16px;
}

.outrchlog-tab .outrch-dialog .el-dialog__footer .outreach-btn {
	padding: 9px 12px;
	background-color: #7b26c9;
	min-width: 160px;
	border: 1px solid #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__footer
	.outreach-btn.el-button.is-disabled {
	background-color: #7b26c9 !important;
	opacity: 0.35;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__footer
	.outreach-btn.el-button.is-disabled:focus {
	background-color: #7b26c9 !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__footer
	.outreach-btn.el-button.is-disabled:hover {
	background-color: #9c5dd7 !important;
}

.outrchlog-tab .outrch-dialog .el-dialog__footer .outreach-btn:hover {
	background-color: #9c5dd7;
	border-color: solid 1px #9c5dd7;
}

.outrchlog-tab .outrch-dialog .el-dialog__footer .outreach-btn:disabled {
	border: unset;
}
