.documents-list-dialog {
	width: 512px !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	-webkit-font-smoothing: antialiased;
	overflow-y: hidden;
}

/*************************************************************** 
                        Header  
***************************************************************/
.documents-list-dialog .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.documents-list-dialog .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.documents-list-dialog .el-dialog__title .title-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.documents-list-dialog .el-dialog__title .close-btn-icon {
	transform: scale(1.2);
	height: 25px;
	width: 25px;
	border-radius: 16px;
	position: relative;
	bottom: 2px;
	cursor: pointer;
}

.documents-list-dialog .el-dialog__title .close-btn-icon:hover {
	background-color: #ececec;
}

.documents-list-dialog .el-dialog__title .disabled-close-btn-icon {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.4;
}

.documents-list-dialog .el-dialog__title .close-icon > div,
.documents-list-dialog .el-dialog__title .close-icon > div > svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.documents-list-dialog .el-dialog__title .close-icon svg .GMICloseIcon {
	fill: #262837;
}

/*************************************************************** 
                        Body  
****************************************************************/
.documents-list-dialog .el-dialog__body {
	margin: 14px 0;
	padding: 0 16px !important;
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	max-height: 500px;
	overflow-y: auto;
}

.documents-list-dialog .update-flag-patient-info-container {
	display: flex;
	border-radius: 4px;
	padding: 12px;
	background-color: #edeff3;
	margin-bottom: 20px;
}

.documents-list-dialog .contacts-icon {
	margin-right: 13px;
}

.documents-list-dialog .patient-info-wrapper {
	display: flex;
	flex-direction: column;
	font: normal 12px/1.42 RionaSans;
}

.documents-list-dialog .update-flag-patient-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
	margin-bottom: 4px;
}

.documents-list-dialog .patient-gender {
	text-transform: capitalize;
}

.documents-list-dialog .refral-prgrm-val {
	font: normal bold 12px/1.42 RionaSans-Bold;
	margin-left: 8px;
}

.documents-list-dialog .update-flag-test-info-container {
	border-radius: 8px;
	border: 1px solid #d8dde4;
	margin-bottom: 20px;
}

.documents-list-dialog .update-flag-test-info-head {
	border-radius: 8px 8px 0 0;
	background-color: #edeff3;
	width: 100%;
	display: flex;
	padding: 10px 16px 10px;
}

.update-flag-test-info-head .update-flag-red-flag-icon {
	margin-right: 8px;
	margin-top: 3px;
}

.update-flag-test-info-head .update-flag-red-flag-icon svg {
	height: 18px;
	width: 18px;
}

.update-flag-test-info-head .update-flag-test-info-wrapper {
	display: flex;
	flex-direction: column;
}

.update-flag-test-info-head .test-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.update-flag-test-info-head .lab-name,
.update-flag-test-info-head .red-flag-created-date-timestamp {
	font: normal 12px/1.42 RionaSans;
	color: #525968;
}

.update-flag-new-flag-status-container {
	display: flex;
}

.update-flag-new-flag-status-container .label-new-flag-status {
	font: normal bold 13px/1.43 RionaSans-Bold;
}

.update-flag-new-flag-status-container .update-flag-green-flag-icon {
	margin-left: 8px;
}

.update-flag-new-flag-status-container .update-flag-green-flag-icon svg {
	height: 18px;
	width: 18px;
}

.documents-list-dialog .lbl-flag-change-reason {
	font: normal 12px/1.42 RionaSans;
	margin-bottom: 6px;
}

.documents-list-dialog .lbl-flag-change-reason::before {
	color: #e74c3c;
	content: '*';
	font: normal 700 12px/1.42 RionaSans;
}

.flag-reason-list-body {
	padding: 16px 50px 24px;
}

.flag-reason-list-body ul {
	margin: 0;
	padding: 0;
}

.documents-list-dialog .el-dialog__body .required::before {
	color: #e74c3c;
	content: '*';
	margin-right: 2px;
}

.documents-list-dialog .loader {
	min-height: 75px;
}

/* TODO */
.documents-list-dialog .document-list .document-row-container {
	padding: 13px 8px 12px 16px;
	border-radius: 4px;
	border: solid 1px #d8dde4;
	margin-bottom: 4px;
	display: flex;
	align-items: center;
}

.documents-list-dialog
	.document-list
	> div:not(.is-malicious)
	.document-row-container:hover {
	cursor: pointer;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.documents-list-dialog .document-list .document-row-container > .document-icon {
	margin-right: 12px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content {
	width: 700px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	> .italic {
	font-style: italic;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.file-name {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	color: #262837;
	margin-bottom: 2px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.file-name.is-archived {
	color: #e74c3c;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info {
	display: flex;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	span {
	text-transform: capitalize;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div {
	display: flex;
	margin-right: 18px;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__rel
	> div {
	display: flex;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__rel
	> div
	> div {
	margin-right: 4px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__popper {
	min-width: 160px;
	padding: 12px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__popper
	> div {
	display: flex;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.patient-info
	> div
	.el-tooltip__popper
	> div
	> .separator {
	width: 1px;
	background-color: #7b8291;
	margin: 0 8px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	.description {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	> .doc-status {
	font-family: RionaSans-Bold;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.38;
	height: 24px;
	padding: 3px 12px;
	border-radius: 24px;
	text-transform: capitalize;
	display: flex;
	align-items: flex-end;
	margin-right: 12px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	> .doc-status.yellow {
	color: #4b3700;
	background-color: #fde776;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	> .doc-status.green {
	color: #004e09;
	background-color: #a2ffad;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .display-content
	> .doc-status.orange {
	color: #502500;
	background-color: #ffcb9f;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .three-dots-wrapper,
.documents-list-dialog
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.three-dots-icon
	> *,
.documents-list-dialog
	.document-list
	.document-row-container
	> .three-dots-wrapper
	svg {
	width: 32px;
	height: 32px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.popovr-segment
	img {
	width: 18px;
	height: 18px;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.popovr-segment
	.without-subtype-disable {
	opacity: 0.5;
	float: left;
	width: 90%;
	cursor: no-drop;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.popovr-segment
	.required-subtype-tooltip {
	float: right;
	width: 10%;
}

.documents-list-dialog
	.document-list
	.document-row-container
	> .three-dots-wrapper
	.popovr-segment
	.required-subtype-tooltip
	.el-tooltip__popper.is-light {
	width: 250px !important;
	border: none;
}

.documents-list-dialog
	.document-list
	.document-row-container
	.el-loading-spinner {
	height: 100%;
	top: 21px;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.documents-list-dialog
	.document-list
	> div.is-malicious
	.document-row-container
	> .document-icon
	> img {
	width: 24px;
}

.documents-list-dialog
	.document-list
	> div.is-malicious
	.document-row-container {
	border: solid 1px red;
}
