@import './../../colors.css';
@import './custom.css';
.patient_cards {
	/* display: grid;
    grid-template-columns: auto auto; */
	/* grid-row-gap: 2vw; */
	margin-top: 3vw;
	/* width: 75%; */
}

th.checkbox_th {
	padding-left: 7px !important;
}

.bucket_cards {
	width: 20vw;
	height: 15vw;
	background-color: white;
	box-shadow: 0.05vw 0.05vw 0.05vw #777;
	transition: 0.25s;
	color: #777;
}

.initialLoad {
	position: relative;
	top: 25%;
}

.pop_report_loader > div {
	background-color: rgba(237, 239, 243, 0.6) !important;
	left: 63px !important;
}

.pop_report_loader.sat_survey > div {
	left: 0px !important;
}

.pop_report_loader.sat_survey > div > div.el-loading-spinner {
	top: 20%;
}

.sat_survey_li {
	font-size: 13px !important;
}

.popInitialLoader {
	background-color: rgba(237, 239, 243, 1) !important;
	height: 400px;
	position: relative;
	z-index: -1;
	width: 100vw;
}

tr.tr_pop_table_2 {
	background-color: #edeff3;
}

tbody.tbody_pop_table_2 {
	width: 1363px;
	height: 578px;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: #ffffff;
}

.patients-popcards-container tbody.tbody_pop_table_22 {
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16) !important;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16) !important;
}

.patients-popcards-container tbody.tbody_pop_table_22 > tr:hover {
	background-color: #ecf8fd !important;
}
.patients-popcards-container tbody.tbody_pop_table_22 > tr:focus,
.patients-popcards-container tbody.tbody_pop_table_22 > tr:active {
	background-color: #d9f1fb !important;
}

tbody.tbody_pop_table_2 > tr {
	border-spacing: 3px;
	border-collapse: separate;
}

.pop_table_2 {
	/*border-spacing: 3px !important;
    border-collapse: separate !important;
    background-color: #edeff3;*/
}

.sortIcon_pop {
	opacity: 0;
}

.pop_table_2 th {
	height: 18px;
	font-family: RionaSans;
	font-size: 13px !important;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

.pop_table_2 td {
	height: 20px;
	font-family: RionaSans;
	font-size: 14px !important;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	-webkit-font-smoothing: antialiased;
	position: relative;
}

.pop_table_2 .dashboard_encounter_rows td:first-child {
	position: relative;
	padding: 0 !important;
	width: 15px;
}

.pop_table_2 th:first-child {
	position: relative;
	padding: 0 !important;
	width: 15px;
}

.pop_table_2 .dashboard_encounter_rows td:nth-child(2) {
	padding-left: 0 !important;
}

.pop_table_2 .dashboard_checktable td:nth-child(2) {
	padding-left: 0 !important;
}
.pop_table_2 .dashboard_checktable td:first-child {
	position: relative;
	padding: 0 !important;
	width: 15px;
}

.dashboard_checktable .high-priority {
	position: absolute;
	top: 26%;
	left: -10px;
}

.tablecheck td:nth-child(2) {
	width: 10px !important;
	padding: 0 !important;
}

.tablecheck th:nth-child(2) {
	width: 10px !important;
	padding-left: 0 !important;
	position: relative;
	left: 0px;
}

.dashboard_pt_tblheader th:nth-child(2) {
	width: 10px !important;
	padding-left: 0 !important;
	position: relative;
	left: -10px;
}

.tablecheck th:first-child {
	position: relative;
	padding: 0 !important;
	width: 2px;
}

.tablecheck td:first-child {
	position: relative;
	padding: 0 !important;
	width: 2px;
}

.filterDropdownDashboard > ul.el-dropdown-menu {
	max-height: 300px;
	overflow: hidden;
	left: 0px !important;
	width: 185px;
	border-radius: 4px;
	margin: 0 !important;
	padding: 0 !important;
}

.filterDropdownDashboard
	> ul.el-dropdown-menu
	> div.filterDropdownDashboardList {
	max-height: 300px;
	overflow-y: scroll;
}

.filterDropdownButtonDashboard {
	min-width: 105px !important;
	width: unset !important;
	margin-right: 16px !important;
}

.filterDropdownButtonDashboard > div.filterDropdownButtonIcon {
	padding-left: 8px;
}

.patients_dashboard.pop_dashboard_2 {
	box-shadow: none;
	border: none;
	background: transparent;
	padding: 0vw;
	min-height: 500px;
}

.ccAssignmentDropdown > div.el-input > input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.cardSpinner {
	height: 70vh;
	position: unset !important;
}

.cardSpinner > div {
	background-color: none !important;
	position: unset !important;
}

.cardEmptyResultsContainer {
	width: 100%;
	background-color: white;
	border-radius: 4px;
	border: 1px solid #d8dde4;
	height: 242px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 64px 0px;
}

.cardEmptyResultsMain {
	font-family: 'RionaSans-Medium';
	font-size: 24px;
	color: #262837;
}

.cardEmptyResultsMessage {
	text-align: center;
	font-size: 14px;
	color: #7b8291;
	width: 320px;
	white-space: normal;
	margin-bottom: 16px;
}

.cardEmptyResultsFilters {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.ccAssignmentDropdownGroup > li.el-select-group__title {
	font-family: 'RionaSans-Bold' !important;
	font-size: 13px;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.menuItemIconWhite.pop {
	float: left;
}

.menuItemIconWhite.pop > div > svg {
	height: 28px !important;
	width: 28px !important;
	margin-top: -7px;
	margin-right: 3px;
}

.menuItemIconWhite.pop > div > svg > g > g {
	fill: white;
}

.export-icon-purple {
	float: left;
}

.export-icon-purple > div > svg {
	height: 28px !important;
	width: 28px !important;
	margin-top: -7px;
	margin-right: 3px;
}

.cardEmptyResultsFiltersLeft {
	margin-right: 8px !important;
	margin-left: 0px;
	font-size: 14px !important;
}

.cardEmptyResultsFiltersRight {
	margin-right: 0px;
	margin-left: 8px !important;
	font-size: 14px !important;
}

.callAttemptsDropdown > div.el-input > input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.export_enabled,
.export_disabled {
	/*width: 119px;*/
	height: 38px;
	border-radius: 6.5px !important;
	/* background-color: #7b26c9 !important;
    border-color: #7b26c9 !important; */
	display: flex;
	color: #7b26c9 !important;
	margin-right: 24px;
	cursor: pointer;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	padding-top: 11px;
}

.export_enabled:hover {
	/* background-color: #9c5dd7 !important;
    border-color: #9c5dd7 !important; */
	text-decoration: underline;
}

/* .export_enabled:active {
    background-color: #7b26c9 !important;
    border-width: 2px;
    border-color: #9c5dd7 !important;
    border-style: dashed;
} */

.export_disabled,
.export_disabled:hover {
	opacity: 0.35;
	cursor: not-allowed !important;
}

.pop_export_label {
	/*width: 42px;*/
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.pop_separator {
	width: 1px;
	height: 38px;
	border: solid 1px #bac3cd;
	margin-right: 24px;
}

.add_outreach_button {
	width: 100% !important;
	height: 38px !important;
	border-radius: 6.5px !important;
	background-color: #40b9ec !important;
	border-color: #40b9ec !important;
}

.add_outreach_button.sat_survey_button {
	width: 100% !important;
	height: 36px !important;
}

.add_outreach_button:hover {
	background-color: #70cbf1 !important;
}

.add_outreach_button:active {
	background-color: #40b9ec !important;
	border-width: 2px;
	border-color: #70cbf1;
	border-style: dashed;
}

.add_outreach_dark {
	width: 100% !important;
	height: 38px !important;
	border-radius: 6.5px !important;
	background-color: #307dbb !important;
	border-color: #307dbb !important;
}

.add_outreach_dark.sat_survey_button {
	width: 100% !important;
	height: 36px !important;
}

.outreach_oval.dark {
	color: #307dbb;
}

.current_sorted {
	font-family: RionaSans-Bold !important;
	-webkit-font-smoothing: antialiased;
}

.unsorted {
	font-family: RionaSans !important;
}

/* .sortableLabeldashboardencounters svg {
    opacity: 0;
}

.sortableLabeldashboardencounters:hover svg {
    opacity: 1
}

.sortableLabeldashboardencounters:hover>.sortIcon_pop {
    opacity: 1
} */

.sortableLabeldashboardencounters:hover > .sortIcon_pop,
.sortableLabeldashboardencounters.current_sort > .sortIcon_pop {
	opacity: 1;
}

.sortableLabelpatientrecords:hover > .sortIcon_pop,
.sortableLabelpatientrecords.current_sort > .sortIcon_pop {
	opacity: 1;
}

.card-title {
	text-transform: capitalize;
	min-height: 2.98vw;
}

.dashboard_breadcrumb {
	font-size: 1vw;
	display: flex;
	color: #262837;
	font-weight: bold;
	margin-top: 2vw;
	margin-bottom: 2vw;
	text-transform: capitalize;
	-webkit-font-smoothing: antialiased;
}

.dashboard_nodata {
	font-size: 1vw;
	display: flex;
	align-items: center;
	margin-top: 0;
	margin-left: 0;
	margin-bottom: 28px;
	color: rgb(82, 89, 104);
	-webkit-font-smoothing: antialiased;
}
.dashboard_checkbox {
	font-size: 1vw;
	display: flex;
	align-items: center;
	margin-top: 0;
	margin-left: 0;
	margin-bottom: 28px;
	color: #262837;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
}
.dashboard_checkbox label {
	margin-left: 0;
	margin-right: 7px;
}
.dashboard_checkbox .multiSelectCheckboxLabel {
	font-size: 12px !important;
	font-family: 'RionaSans';
}

span.patient_selection_checkbox > span > svg {
	width: 2vw !important;
	height: 2vw !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
	/*width: 14px;
    height: 14px;*/
	/*opacity: 0.5;*/
	border-radius: 2px;
	border: solid 1px #a0abb9;
	background-color: #ffffff !important;
}

.el-checkbox__inner {
	width: 14px !important;
	height: 14px !important;
	border-radius: 2px !important;
}

.el-checkbox__inner::after {
	border: 1.7px solid #3caaeb !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 10px !important;
	left: 4px !important;
	top: -1px !important;
}

.el-checkbox__input {
	line-height: 0.5 !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: #525968 !important;
	border-color: #525968 !important;
}

.all_selector_checkbox_wrapper {
	text-align: center;
	padding-left: 10px;
	width: 88%;
	/*padding-top: 10px;*/
	width: 52px;
	height: 23px;
	border-radius: 6.5px;
	background-color: #d4dfe7;
	display: flex;
	align-items: center;
}

.all_selector_checkbox_wrapper > label {
	margin-bottom: 0px !important;
}

.el-checkbox__label {
	padding-left: 0px !important;
}

.outreach_oval {
	height: 18px;
	width: 18px;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	border-radius: 50%;
	background: white;
	color: #3caaeb;
	font-size: 11px;
	line-height: 1.75;
	margin-right: 0.5vw;
	margin-top: -0.5px;
	font-family: RionaSans-Bold !important;
}

.pop_referral_selection > div > input {
	width: 185px;
	height: 38px;
	border-radius: 6px;
	border: solid 1px #d8dde4;
	background-color: #ffffff;
	font-size: 13px !important;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

.pop_referral_selection > .el-input {
	width: 185px !important;
}

.pop_referral_selection > div > .el-icon-caret-top:before {
	width: 10px;
	height: 7px;
	border-radius: 1px;
	color: #525968;
}

.pop_referral_selection_dropdown {
	font-size: 13px !important;
}

.tooltip_left_enc_type_labels {
	width: 82px;
	height: 68px;
	font-family: RionaSans;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.55;
	margin-right: 10px;
	letter-spacing: normal;
	text-align: right;
	color: rgb(255, 255, 255);
}

.tooltip_right_enc_count_labels {
	width: 23px;
	height: 68px;
	font-family: RionaSans;
	font-size: 11px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: rgb(255, 255, 255);
}

.cap_not_sent_tooltip > .el-tooltip__popper.is-dark {
	width: 130px;
	height: 140px;
}

.el-tooltip.item > .el-tooltip__popper .popper__arrow {
	display: none !important;
}

.no_results_pop {
	height: 32px;
	font-family: RionaSans;
	font-size: 22px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #686d76;
	text-align: center;
	padding: 30px;
}

.no_attention_pop {
	/* width: 345px; */
	height: 22px;
	font-family: RionaSans;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #686d76;
	/* padding-left: 5vw; */
	/* height: 2vw; */
	text-align: center;
	margin-top: 35px;
	margin-bottom: 35px;
	padding-bottom: 60px;
}

.el-date-editor.outreach_date_picker > .el-date-editor.el-input {
	width: 94% !important;
}

.el-date-editor.outreach_date_picker > .el-date-editor.el-input > input {
	font-family: RionaSans-Medium !important;
	height: 32px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
}

.today {
	font-size: unset !important;
}

.outreach_mode_selector > div > input {
	height: 32px;
	font-family: RionaSans;
	font-size: 13px !important;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
}

.outreach_post_update_button {
	width: 148px;
	height: 38px;
	border-radius: 6.5px !important;
	background-color: #3caaeb !important;
}

.Latest-outreach-date {
	/*width: 126px;*/
	height: 18px;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
}

.el-select.outreach_mode_selector > .el-input,
.el-select.outreach_mode_selector > .el-select-dropdown {
	width: 94% !important;
}

.el-date-picker {
	z-index: 100001 !important;
}

/*tr.dashboard_encounter_rows {
    cursor: pointer;
}

tr.dashboard_encounter_rows:hover {
    background-color: #ddd;
}*/

.dashboard_encounter_rows:hover .ptcolor {
	color: #40b9ec;
}

.dashboard_encounter_rows .high-priority {
	position: absolute;
	top: 30%;
	left: -10px;
}

i.material-icons.breadcrumb_arrow {
	font-size: 1.3vw;
	margin-left: 1vw;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
}

.dashboard_encounter_filter {
	margin-left: 1vw;
}

.dashboard_encounter_filter.clickable {
	cursor: pointer;
}

.dashboard_grid_container {
	display: grid !important;
	flex-wrap: wrap;
	grid-template-columns: auto auto auto auto auto;
	width: 100%;
	display: flex;
	box-sizing: border-box;
	margin: 0;
	flex-grow: 0;
	max-width: 100%;
	flex-basis: 100%;
}

.patient-stage-card {
	margin: 0;
	box-sizing: border-box;
	padding: 0 1vw;
	max-width: 33% !important;
	flex-basis: 33% !important;
}

.patient-stage-card > div {
	margin-left: 0 !important;
	margin-bottom: 3.5vw !important;
	border-radius: 0.4vw !important;
	-o-box-shadow: 0 0.073vw 0.29vw 0 rgba(0, 0, 0, 0.14) !important;
	box-shadow: 0 0.073vw 0.29vw 0 rgb(0 0 0 / 14%) !important;
	padding: 0 !important;
	display: flex;
	position: relative;
	word-wrap: break-word;
	flex-direction: column;
	background-color: white;
	border: 0.073vw solid transparent;
	transition: all 300ms linear;
	height: 6vw !important;
	width: 14vw !important;
	text-align: center;
	white-space: nowrap;
}

.bucket_title_container {
	padding: 1vw;
}

.bucket_cards:hover {
	background-color: rgb(248, 248, 248) !important;
	box-shadow: 0.05vw 0.05vw 0.05vw rgb(235, 235, 235);
}

.bucket_partition {
	height: 0.15vw;
	background-color: #5bc0de;
	width: 100%;
	margin-top: 1vw;
}

.btn-info {
	color: #5bc0de !important;
	background-color: transparent !important;
	border-color: transparent !important;
	margin-top: 3vw !important;
	width: 10vw;
	font-size: 0.92vw;
	height: 2vw;
	line-height: 0.8vw;
}

.patient_name_span {
	cursor: pointer;
}

.patient_name_span:hover {
	color: rgb(0, 174, 239);
}

.patients_dashboard {
	box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.14);
	margin: 160px 0 3vw 80px;
	/* margin-top: 6vw; */
	width: 93%;
	/* padding-bottom: 4vw;
    padding-left: 4vw; */
	padding: 0px 1vw 0vw;
	border: 1px solid #ddd;
	/*border-top: 1.5px solid var(--main-nav-color);*/
	background: white;
}

.non_pop_dashboard {
	margin-top: 65px;
}

tr {
	border-bottom: 1px solid #d8dde4 !important;
}

.pop_table_2 thead tr,
.pop_table_2 thead tr th {
	border-bottom-width: 0 !important;
}

.patient-outreach-pending-container {
	margin-top: 75px;
	margin-left: 80px;
	height: 45px;
}

.patient-outreach-pending-container .export_enabled {
	/* border: 1px solid #7b26c9; */
	/*background-color: #ffffff !important;*/
}

.patient-outreach-pending-container .pop_export_label {
	/*color: #7b26c9;*/
}

.pop_dashboard_2 .pop_table_2 th {
	font-size: 14px !important;
	color: #262837;
}

.dashboard-patient-tbl tbody tr:hover {
	cursor: pointer;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}

.dashboard-patient-tbl tbody tr:hover .outreach-ptent-name {
	text-decoration: none;
	color: #262837;
}
.dashboard-patient-tbl .outreach-ptent-name:hover {
	text-decoration: underline !important;
}

.outreachPopupBackground {
	background-color: rgba(0, 0, 0, 0.35);
	height: 100vh;
	width: 100vw;
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 10000;
}

/*------------------media---------------------- */

@media only screen and (min-width: 992px) and (max-width: 1100px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.card-title {
		/*font-size: 1.2vw;*/
	}
	.buttonbox {
		font-size: 1.1vw;
	}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
	.patients_dashboard {
		margin-left: 16vw !important;
		/*height: 100vh;*/
	}
	.iconcolor-box {
		line-height: 7vw;
	}
	.card-title {
		/*font-size: 1.4vw;*/
	}
	.patients_dashboard {
		margin-top: 0vw !important;
	}
	.search_bar > div {
		height: 6vw !important;
	}
	.buttonbox {
		font-size: 1.17vw;
	}
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
	.card-title {
		/*font-size: 1.8vw;*/
	}
	.Patient-text {
		font-size: 2.2vw;
	}
	.contentbox {
		height: 10vw;
	}
	.buttonbox {
		font-size: 1.5vw;
		line-height: 3.4vw;
	}
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.patients_dashboard {
		margin-left: 16vw !important;
		/* height: 100vh; */
	}
	.top-mobi {
		margin-top: 11vh !important;
	}
	.search_bar > div {
		height: 6vw !important;
	}
}

.patients-popcards-container table tr .blank-column {
	min-width: 16px !important;
	width: 0.8% !important;
}
.patients-popcards-container table tr .name-column {
	min-width: 233px !important;
	width: 19% !important;
}
.patients-popcards-container table tr .email-column {
	min-width: 233px !important;
	width: 19% !important;
}
.patients-popcards-container table tr .phone-column {
	min-width: 116px !important;
	width: 10% !important;
}
.patients-popcards-container table tr .time-column {
	min-width: 78px !important;
	width: 7% !important;
}
.patients-popcards-container table tr .ref-program-column {
	min-width: 233px !important;
	width: 19% !important;
}
.patients-popcards-container table tr .last-call-column {
	min-width: 108px !important;
	width: 9% !important;
}
.patients-popcards-container table tr .call-attempt-column {
	min-width: 108px !important;
	width: 9% !important;
}
.patients-popcards-container tr:first-child td:first-child {
	border-top-left-radius: 4px;
}
.patients-popcards-container tr:first-child td:last-child {
	border-top-right-radius: 4px;
}
.patients-popcards-container tr:last-child td:first-child {
	border-bottom-left-radius: 4px;
}
.patients-popcards-container tr:last-child td:last-child {
	border-bottom-right-radius: 4px;
}
.patients-popcards-container .dashboard_checktable .high-priority {
	left: -5px;
}
.patients-popcards-container table td.tablecell {
	word-break: break-all !important;
}
div#email_tooltip > div {
	font-size: 0.6vw;
	word-break: break-word;
}

/***********************************************************
 * Results Cap Not Sent Card dashboard encounter list table 
**********************************************************/

.tbl-results-cap-not-sent td:nth-child(2),
.tbl-results-cap-not-sent th:nth-child(2) {
	width: 5px !important;
}

.tbl-results-cap-not-sent tbody tr,
.tbl-results-cap-not-sent tbody tr td {
	height: 64px !important;
	padding: 10px 13px !important;
}

.tbl-results-cap-not-sent .cap-was-amended {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b8291;
	-webkit-font-smoothing: antialiased;
	display: block;
}

.tbl-results-cap-not-sent .cap-incomplete,
.tbl-results-cap-not-sent .cap-needs-amendment {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #ad1505;
	-webkit-font-smoothing: antialiased;
	display: block;
}

.dashboardAppointmentsTable {
	min-width: unset !important;
	max-width: unset !important;
	width: 100% !important;
}

.dashboardAppointmentsTable > tbody {
	box-shadow: unset !important;
}

.dashboardAppointmentsTable > tbody > tr > td {
	max-width: 100% !important;
	font-family: 'RionaSans' !important;
}
