.provider-wrapper {
	display: flex;
}

.provider-wrapper .provider-name {
	font: normal 500 14px/1.43 RionaSans-Medium;
	color: #262837;
	margin-left: 4px;
}

.provider-wrapper .provider-image {
	width: 20px;
	height: 20px;
	margin-right: 4px;
	object-fit: contain;
}

.provider-wrapper .provider-image img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: solid 1px #edeff3;
}
