@use '../../../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	footer {
		padding: system.$oneHalfUnit system.$singleUnit;
		border-top: 1px #edeff3 solid;

		display: flex;
		justify-content: flex-end;
		gap: system.$doubleUnit;
	}
	.edit-patient-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 24px;
		gap: 24px;

		width: 900px;
		height: 72px;
	}
	.legacy-link {
		font-family: RionaSans;
		font-style: normal;
		font-size: 14px;
		color: #5b43bd;
		text-decoration: none;
		transition:
			color 0.3s ease,
			text-decoration 0.3s ease;
	}

	.legacy-link:hover {
		cursor: pointer;
	}
}
