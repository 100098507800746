/* stylelint-disable selector-class-pattern */
.add-edit-cfdna-prenatalscreening-dialog {
	width: 733px !important;
	position: fixed !important;
	height: min(calc(100% - 118px), 1472px);
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}

/******************************************************************************
								Dialog Header
*******************************************************************************/

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__header {
	border-bottom: 1px solid #edeff3;
	padding: 15px 16px;
}

.add-edit-cfdna-prenatalscreening-dialog .header {
	display: flex;
	justify-content: space-between;
	color: #262837;
	font: bold 20px RionaSans-Bold, sans-serif;
}

.add-edit-cfdna-prenatalscreening-dialog .header .close-btn-icon {
	height: 23px;
	cursor: pointer;
}

.add-edit-cfdna-prenatalscreening-dialog .header .close-btn-icon:hover {
	background-color: #ececec;
	border-radius: 20px;
	height: 27px;
}

.add-edit-cfdna-prenatalscreening-dialog
	.el-dialog__title
	.close-icon
	svg
	.GMICloseIcon {
	fill: #262837;
}

/******************************************************************************
								Dialog Body
*******************************************************************************/
.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body {
	padding: 22px 16px;
	height: calc(100% - 118px);
	overflow-y: auto;
	font: normal 12px RionaSans, sans-serif;
	color: #262837;
}

.add-edit-cfdna-prenatalscreening-dialog .el-input {
	font: normal 13px RionaSans, sans-serif;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body .el-input,
.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body .el-input__inner {
	height: 32px;
}

.add-edit-cfdna-prenatalscreening-dialog h2.module-header {
	display: flex;
	width: 100%;
	align-items: center;
	font: normal 500 16px/23px RionaSans-Medium, sans-serif;
	margin: 0;
	color: #525968;
}

.add-edit-cfdna-prenatalscreening-dialog .el-row {
	margin-top: 16px;
}

.add-edit-cfdna-prenatalscreening-dialog .basic-information-module,
.add-edit-cfdna-prenatalscreening-dialog .aneuploidies-module,
.add-edit-cfdna-prenatalscreening-dialog .microdeletions-module,
.add-edit-cfdna-prenatalscreening-dialog .sex-aneuploides-module,
.add-edit-cfdna-prenatalscreening-dialog .group-section {
	border-bottom: 1px solid #edeff3;
	padding-bottom: 24px;
	margin-bottom: 24px;
}

.add-edit-cfdna-prenatalscreening-dialog .risk-group-label {
	font: normal 700 14px/20px RionaSans-Bold, sans-serif;
	margin-bottom: 8px;
}

.add-edit-cfdna-prenatalscreening-dialog .prior-test-risk-col,
.add-edit-cfdna-prenatalscreening-dialog .after-test-risk-col {
	width: 23%;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.add-edit-cfdna-prenatalscreening-dialog .after-test-risk-col {
	margin-left: 3%;
}

.add-edit-cfdna-prenatalscreening-dialog .risk-label,
.add-edit-cfdna-prenatalscreening-dialog .prior-test-risk-label,
.add-edit-cfdna-prenatalscreening-dialog .after-test-risk-label {
	font: normal 400 12px/13px RionaSans, sans-serif;
	margin-bottom: 6px;
}

.add-edit-cfdna-prenatalscreening-dialog
	.el-input
	.el-input__inner::placeholder,
.add-edit-cfdna-prenatalscreening-dialog
	textarea.el-textarea__inner::placeholder {
	font: normal 13px RionaSans, sans-serif !important;
	color: #bac3ce;
	opacity: 1;
}

.add-edit-cfdna-prenatalscreening-dialog .required::before {
	content: '*';
	font: normal bold 12px RionaSans, sans-serif;
	color: #e74c3c;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body .el-radio {
	display: inline-flex;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body .el-radio + .el-radio {
	padding-left: 8px;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body .el-radio__label {
	font: normal 500 14px/15px RionaSans-Medium, sans-serif;
	padding-left: 3px;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body .mt-zero {
	margin-top: 0;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__body .input-error-text {
	width: 100%;
	font: normal 10px RionaSans, sans-serif;
	color: #ff0000;
	word-break: break-word;
}

.add-edit-cfdna-prenatalscreening-dialog
	.el-dialog__body
	.validation-field-hightlight
	.el-input__inner {
	border: 1px solid #e74c3c;
	background-color: rgb(240 150 141 / 50%);
	color: #e74c3c;
}

.add-edit-cfdna-prenatalscreening-dialog
	.el-input.validation-field-hightlight
	.el-input__inner::placeholder {
	color: #e74c3c !important;
}

.add-edit-cfdna-prenatalscreening-dialog
	.el-dialog__body
	.el-radio__input.is-disabled
	.el-radio__inner,
.add-edit-cfdna-prenatalscreening-dialog
	.el-dialog__body
	.el-radio__input.is-disabled
	.el-radio__inner:hover {
	border: 1px solid #d1dbe5 !important;
}

/******************************************************************************
								Dialog Footer
*******************************************************************************/
.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 11px 16px;
	display: flex;
	justify-content: end;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer button {
	font: normal 500 13px RionaSans-Medium, sans-serif;
	width: 160px;
	padding: 8px 12px;
	border-radius: 7px;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer .save.is-disabled,
.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer .is-disabled:active,
.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer .is-disabled:hover {
	cursor: no-drop;
	opacity: 0.35;
	background-color: #7b26c9 !important;
	user-select: none;
	color: #ffffff;
	border: none;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer .save {
	border: 1px solid #7b26c9 !important;
	color: #ffffff;
	background-color: #7b26c9;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer .save:hover {
	background-color: #9c5dd7;
}

.add-edit-cfdna-prenatalscreening-dialog .el-dialog__footer .save:active {
	background-color: #621ea0;
}
