.auditTrailContainer {
	height: 570px;
	width: 368px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: absolute;
	top: calc(50vh - 270px);
	left: calc(50vw - 184px);
	display: flex;
	flex-direction: column;
}

.auditTrailTitleRow {
	width: 100%;
	height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	border-bottom: 2px solid #edeff3;
	padding: 4px 6px 0px 16px;
}

.auditTrailClose {
	transform: scale(1.25);
	height: 40px;
	width: 45px;
	position: relative;
	top: -2px;
	cursor: pointer;
	border-top-right-radius: 4px;
}

.auditTrailClose:hover {
	background-color: #ececec;
}

.auditTrailClose:active {
	background-color: #d8d8d8;
}

.auditTrailClose > div > div > svg > g > g {
	fill: #262837;
}

.auditTrailBody {
	display: flex;
	flex-direction: column;
	padding: 32px 16px;
	max-height: 570px;
	height: 570px;
	overflow-y: auto;
	width: 100%;
}

.auditTrailPatientInfoContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	border: 1px solid #edeff3;
	background-color: #edeff3;
	padding: 0px 12px 0px 12px;
	margin-bottom: 16px;
}

.auditTrailPatientInfoRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	color: #262837;
	padding: 12px 0px 12px 0px;
	border-bottom: 2px solid #d8dde4;
}

.auditTrailPatientInfoRow:last-child {
	border-bottom: none;
}

.auditTrailPatientInfoRow .auditTrailPatientInfoText {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	color: #7b8291;
	width: 100%;
	-webkit-font-smoothing: antialiased;
}

.auditTrailInfoIcon {
	margin-top: -4px;
	margin-right: 12px;
	display: flex;
	justify-content: center;
}

.auditTrailPatientInfoContainer
	.auditTrailPatientInfoRow
	.auditTrailInfoIcon
	div {
	height: 24px;
	width: 24px;
	display: flex;
	justify-self: center;
	align-items: center;
}

.auditTrailPatientInfoContainer .auditTrailPatientInfoRow .infoIcon svg {
	height: 16px;
	width: 16px;
}

.auditTrailPatientInfoContainer .auditTrailPatientInfoRow .infoIcon svg path {
	fill: #7b8291;
}

.auditTrailPatientInfoRow .multivisit-type {
	font-family: RionaSans-RegularItalic;
	font-weight: 900;
}

.auditTrailInfoSubtitle {
	font-family: 'RionaSans-Medium';
	font-size: 12px;
	color: #262837;
}

.auditTrailDataContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.auditTrailLoading > div {
	top: 75px !important;
}

.auditTrailSelectContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	margin-bottom: 44px;
}

.auditTrailSelectRequired {
	color: red;
	font-size: 15px;
	font-family: 'RionaSans-Bold';
	top: 0px;
	left: -9px;
	position: absolute;
}

.auditTrailSelectTitle {
	font-family: 'RionaSans';
	font-size: 12px;
	color: #525968;
}

.auditTrailSelectContainer
	> div.el-select
	> div.el-input
	> input.el-input__inner,
.auditTrailSelectContainer
	> div.el-select
	> div.el-input
	> input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.auditTrailSelectContainer > div.el-select > div.el-select-dropdown {
	top: 32px !important;
}

.auditTrailHistoryInitialPromptContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 6px;
}

.auditTrailHistoryInitialPromptMajor {
	font-family: 'RionaSans-Medium';
	font-size: 16px;
	color: #262837;
	-webkit-font-smoothing: antialiased;
	padding-top: 14px;
}

.auditTrailHistoryInitialPromptMinor {
	font-family: 'RionaSans-Medium';
	font-size: 14px;
	color: #7b8291;
	-webkit-font-smoothing: antialiased;
	padding-top: 4px;
}

.auditTrailHistoryContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.auditTrailHistoryRecordContainer {
	/* height: 70px; */
	width: 100%;
	position: relative;
	border-left: 2px solid #edeff3;
	margin-bottom: 20px;
	margin-left: 4px;
}

.auditTrailHistoryRecordPoint {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: black;
	position: absolute;
	left: -5px;
	top: -8px;
}

.auditTrailHistoryRecordData {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	bottom: 13px;
	padding-left: 20px;
}

.auditTrailHistoryRecordDataMajor {
	font-family: 'RionaSans';
	font-size: 14px;
	color: #262837;
	display: flex;
	flex-direction: row;
}

.auditTrailHistoryRecordMajorLabel {
	margin-right: 6px;
}

.auditTrailHistoryRecordDataMajorChanged {
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
}

.auditTrailHistoryRecordDataMinor {
	font-family: 'RionaSans';
	font-size: 12px;
	color: #7b8291;
	white-space: pre;
	overflow: hidden;
	text-overflow: ellipsis;
}

.auditTrailPatientInfoRow .inline-tooltip .el-tooltip {
	max-width: 188px;
	float: left;
}
.auditTrailPatientInfoRow .inline-tooltip .el-tooltip .el-tooltip__rel > div {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.auditTrailPatientInfoRow .inline-tooltip .el-tooltip .el-tooltip__popper {
	max-width: 330px;
	width: 90%;
}
