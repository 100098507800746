@use '../../../Common/V3/system';

$scopeClass: createPatientV3;
$width: 900px;

@mixin threeColumnLayout {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: system.$singleUnit;
}

@mixin twoColumnLayout {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: system.$singleUnit;
}
