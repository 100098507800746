@use '../../../../Common/V3/system';
@use '../core';

$purple: #7b26c9;
$gray: #edeff3;

.scheduling-calendar {
	margin-top: system.$doubleUnit;

	.title {
		font-family: RionaSans;
		font-size: 19px;
		margin-bottom: system.$singleUnit;
		margin-top: 0px;
	}

	.calendar-header {
		display: flex;
		justify-content: center;

		.month {
			display: inline-block;
			font-family: RionaSans-Bold;
			font-size: 19px;
			width: 100px;
			margin-left: system.$singleUnit;
			margin-right: system.$singleUnit;
			text-align: center;
		}

		.MuiButtonBase-root {
			color: $purple;
		}
	}

	.MuiDateCalendar-root {
		width: 100%;
	}
	.MuiButtonBase-root.MuiPickersDay-root,
	.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
		font-family: RionaSans-Bold;
		font-size: 16px;
		margin-left: 10px;
		margin-right: 10px;
		width: 36px;
		&.Mui-selected {
			background-color: $purple;
			&:hover {
				background-color: $purple;
			}
		}
		&.Mui-disabled {
			font-family: RionaSans;
			text-decoration: line-through;
		}
	}
	.MuiDayCalendar-weekDayLabel {
		margin-left: 10px;
		margin-right: 10px;
		font-family: RionaSans;
		font-size: 16px;
	}

	.available-times {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		border-left: 1px $gray solid;
		.container {
			width: 343px;

			.time-options {
				height: 350px;
				overflow-y: scroll;
				.gmi-radio-group {
					flex-direction: column;
					font-family: RionaSans-Medium;
				}
			}

			.error {
				color: red;
				font-size: 12px;
			}
		}
	}
}
