.view-risk-assessment-predictions-form {
	border-radius: 4px;
	border: 1px solid #d7dde5;
	background-color: #ffffff;
	padding: 16px;
}

.view-risk-assessment-predictions-form .risk-assessment-title {
	border-bottom: 1px solid #edeff3;
	margin-bottom: 16px;
	padding-bottom: 14px;
	font: normal bold 20px RionaSans-Bold;
	color: #262837;
	text-transform: capitalize;
}

.view-risk-assessment-predictions-form .risk-assessment-section {
	height: 200px;
	overflow-y: auto;
	border-right: 1px solid #edeff3;
}

.view-risk-assessment-predictions-form .predictions-label {
	margin-bottom: 16px;
	font: normal 500 18px RionaSans-Medium;
	color: #262837;
}

.view-risk-assessment-predictions-form .predictions-container .outcome-label {
	font: normal 500 15px RionaSans-Medium;
	color: #262837;
	text-transform: capitalize;
	width: 250px;
}

.view-risk-assessment-predictions-form .predictions-container .assessed-text {
	font: normal 14px RionaSans;
	color: #262837;
	text-transform: capitalize;
}

.view-risk-assessment-predictions-form .prediction-accordian-wrapper {
	border-bottom: 1px solid #d8dde4;
}

.view-risk-assessment-predictions-form
	.predictions-container
	.prediction-accordian-wrapper:only-child {
	border-bottom: none;
}

.view-risk-assessment-predictions-form .prediction-accordian-header {
	display: flex;
	align-items: center;
	margin: 12px 0;
}

.view-risk-assessment-predictions-form
	.prediction-accordian-header
	.accordian-icon {
	cursor: pointer;
	margin-left: auto;
}

.view-risk-assessment-predictions-form
	.prediction-accordian-body
	.rationale-label {
	font: normal 500 12px RionaSans-Medium;
	color: #525968;
}

.view-risk-assessment-predictions-form
	.prediction-accordian-body
	.rationale-value {
	font: normal 300 14px RionaSans-Light;
	color: #262837;
	margin-bottom: 16px;
}

.view-risk-assessment-predictions-form .riskassessment-clinical-comments-label {
	margin-bottom: 16px;
	font: normal 500 18px RionaSans-Medium;
	color: #000000;
}

.view-risk-assessment-predictions-form .view-all-clinical-comments {
	font: normal 500 14px RionaSans-Medium;
	color: #7b26c9;
}

.view-risk-assessment-predictions-form label {
	color: #262838;
	font: normal 12px RionaSans;
	margin-bottom: 0;
}

.view-risk-assessment-predictions-form .field-val {
	font: normal 500 14px RionaSans-Medium;
	color: #262838;
	line-height: normal;
	width: 100%;
	display: inline-block;
}

.view-risk-assessment-predictions-form .lab-comment {
	white-space: pre;
}

.view-risk-assessment-predictions-form .margin-bottom-19 {
	margin-bottom: 19px;
}

.view-risk-assessment-predictions-form .request-another-img {
	padding-right: 7px;
	height: 28px;
}

.view-risk-assessment-predictions-form .clinical-comments-container {
	position: relative;
	min-height: 130px;
}
