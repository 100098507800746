.provider-add-another {
	min-width: 416px;
	min-height: 40px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 12px;
	cursor: pointer;
}

.provider-add-another .add-another {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
}

.provider-add-another-disabled {
	cursor: no-drop;
	opacity: 0.5;
}
