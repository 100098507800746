.external_providers_wrapper {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 0 0 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.external_providers_wrapper .three-dots-wrapper > span {
	display: flex;
}

.external_providers_wrapper .three-dots-wrapper button,
.external_providers_wrapper .three-dots-wrapper button * {
	width: 32px;
	height: 32px;
}
