.appointment-scheduling-error-dialog {
	padding: 20px;
	position: fixed;
	margin: auto;
	width: 380px;
	border-radius: 4px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: #ffffff;
	padding: 0px 0px 32px;
	border-radius: 4px;
}

.appointment-scheduling-error-dialog .el-dialog__title {
	width: 100%;
	padding-left: 10px;
	padding-right: 16px;
  }

.appointment-scheduling-error-dialog .el-dialog__header {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px 0px 32px;
	padding-bottom: 20px;
	border-bottom: 1px solid #D8DDE4;
	background: #FFFFFF;
	border-radius: 4px 4px 0px 0px;
	order: 0;
	flex-grow: 0;
}

.appointment-scheduling-error-dialog .title {
	font: normal bold 16px RionaSans-Bold;
	color: #26273a;
	display: flex;
}

.appointment-scheduling-error-dialog .title .error-title{
	flex-grow: 1;
}

.appointment-scheduling-error-dialog .el-dialog__body {
	padding: 0px 22px 0px;
	margin-top: 22px;
}

.appointment-scheduling-error-dialog .appointment-scheduling-error-msg {
	font: normal 14px RionaSans;
	color: #525968;
}

.appointment-scheduling-error-dialog .appointment-scheduling-error-msg div:nth-child(1) {
	padding-bottom: 22px;

}

.appointment-scheduling-error-dialog .el-dialog__footer {
	display: flex;
	justify-content: space-between;
	padding: 0;
	margin-top: 20px;
}

.appointment-scheduling-error-dialog .el-dialog__title .title {
	display: flex;
	justify-content: left;
	align-items: flex-end;
}

.appointment-scheduling-error-dialog .el-dialog__title .warningIcon>div,
.appointment-scheduling-error-dialog .el-dialog__title .warningIcon>div>svg {
	height: 24px;
	width: 24px;
	margin-right: 15px;
}

.appointment-scheduling-error-dialog .el-dialog__title .warningIcon>div>svg path.GMIWarningCircleIcon {
	fill: #e74c3c;
}

.appointment-scheduling-error-dialog .el-dialog__title .closeIcon>div,
.appointment-scheduling-error-dialog .el-dialog__title .closeIcon>div>svg {
	height: 24px;
	width: 24px;
	margin-left: 10px;
}

.appointment-scheduling-error-dialog .el-dialog__title .closeIcon>div>svg path.closeIcon {
	fill: #000000;
}

.appointment-scheduling-error-dialog .el-dialog__title .closeIcon>div :hover,
.appointment-scheduling-error-dialog .el-dialog__title .closeIcon>div>svg :hover {
	cursor: pointer;
}