.div_row {
	background-color: #ffffff;
	-o-box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.17);
	-moz-box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.17);
	-webkit-box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.17);
	border-radius: 4px;
	box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.17);
	margin-bottom: 8px;
	float: left;
	width: 100%;
}

.div_row:not(.div_row_pressed):hover {
	background-color: #ecf8fd;
}

.div_row:not(.div_row_pressed):focus,
.div_row:not(.div_row_pressed):active {
	background-color: #d9f1fb;
	outline: none;
}

.heading {
	display: flex;
	width: 100%;
}

.heading div {
	list-style: none;
	padding: 0 0 8px 20px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	-webkit-font-smoothing: antialiased;
}

.toggle_icon {
	width: 28px;
	height: 28px;
}

.toggle_area div {
	list-style: none;
	width: 16.67%;
	margin: 0 !important;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #474e53;
	-webkit-font-smoothing: antialiased;
}

.body_area div {
	list-style: none;
	padding: 15px 0px 11px 20px;
	margin: 0 !important;
	height: 46px;
}

.body_area div span {
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #474e53;
}

.font-bold {
	font-weight: bold !important;
}

.table td,
.table th {
	border: none;
}

.table thead th {
	border-bottom: none;
}

.table {
	padding: 0 10px;
	width: 100%;
}

.table div {
	width: 100%;
	padding: 2px 0;
}

.rotate {
	-moz-transition: all 0.5s linear;
	-webkit-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.rotate.down {
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.tbl-title {
	font-family: RionaSans;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7b8291;
	-webkit-font-smoothing: antialiased;
}

.prv-name {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #474e53;
	-webkit-font-smoothing: antialiased;
}

.prv-apprv {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #2dd675;
	text-transform: uppercase;
	float: left;
	/* margin-top: 5px; */
	padding: 0 !important;
	-webkit-font-smoothing: antialiased;
	width: 90%;
}

.tbl-time {
	opacity: 0.82;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #474e53;
	padding: 0;
	-webkit-font-smoothing: antialiased;
}

.tbl-date {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #474e53;
	padding: 0;
	-webkit-font-smoothing: antialiased;
}

.patient_encounters {
	margin-bottom: 0px !important;
}

.patient_encounters .prov-date,
.patient_encounters .prov-time {
	padding: 0;
}

.patient_encounters > tbody > tr > td {
	vertical-align: middle;
	/*padding: 0 20px; /*comment this line if put width in encounter cell*/
	height: 52px;
}

.prov-type {
	padding: 1px 0 0 0 !important;
	font-family: RionaSans-Medium;
	font-size: 15px !important;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #474e53;
	-webkit-font-smoothing: antialiased;
	width: 90% !important;
}

.pad-0 {
	padding: 0 !important;
}

.padding-bottom-8 {
	padding-bottom: 8px;
}

.patient-imgbox {
	height: 20px;
	width: 20px;
	margin-right: 8px;
	float: left;
}

.patient-imgbox img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: solid 1px #d4dfe7;
}

.patient-imgbox-no-border {
	border: none !important;
}

.provp1 {
	width: 132px;
	padding: 8px 0 7px 20px !important;
	float: left;
}

.provp2 {
	width: 218px;
	padding: 0 0 0 20px !important;
	display: inline-flex;
	align-items: center;
}

.provp2 .provider-typeimg {
	width: 28px;
	float: left;
	height: 28px;
	padding: 0;
}

.provp2 .provider-typeimg.provider-typeimg-scp {
	height: 22px;
}

.provp2 .prov-typemain {
	padding: 0 !important;
	margin-left: 4px !important;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.provp3 {
	width: 160px;
	padding: 0 0 0 20px !important;
}

.provp4,
.provp5 {
	width: 300px;
	padding: 0 0 0 20px !important;
}

.provp2 .visit-status {
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #f1c40f;
	width: 90%;
	float: left;
	padding: 0 !important;
	text-transform: capitalize;
	-webkit-font-smoothing: antialiased;
}

.status-yellow {
	color: #f1c40f !important;
}

.status-red {
	color: #e74c3c !important;
}

.status-green {
	color: #00d65b !important;
}

.status-blue {
	color: #40b9ec !important;
}

.status-gray {
	color: #a0abb9 !important;
}

.status-gray-dark {
	color: #525968 !important;
}

.toggle_area .patient_encounters {
	display: flex;
	padding: 0;
}

.nextPageButton,
.prevPageButton {
	width: 24px;
	height: 24px;
	border-radius: 6px;
	font-size: 21px !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.btn-bg {
	width: 180px;
	height: 36px;
	border-radius: 6px;
	background-color: #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	border: none;
	display: flex;
	align-items: center;
	padding: 0 0px 0 0px;
}

.btn-bg svg {
	vertical-align: middle;
}

button:focus {
	outline: none;
}

.clear-btn {
	color: #870dd1 !important;
	width: 138px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #870dd1;
	display: flex;
	align-items: center;
	background-color: transparent;
	padding: 0px 0 0 0px;
}

.clear-btn svg {
	width: 28px;
	height: 28px;
	vertical-align: middle;
}

button.clear-btn:disabled {
	opacity: 0.35;
	cursor: not-allowed;
}

div.toggle_area .table-container {
	width: 100%;
	border-radius: 10px;
	border: solid 1.5px #d4dfe7;
	background-color: #ffffff;
	float: left;
	margin-bottom: 8px !important;
	cursor: pointer;
}

div.toggle_area .table-container:last-child {
	margin-bottom: 0px !important;
}

div.toggle_area .table-container:hover {
	border: solid 1.5px #40b9ec;
}

.provp2 .prov-img img {
	width: 20px;
}

div.table-container .table > tbody > tr > td,
div.table-container .table > tbody > tr > th,
div.table-container .table > tfoot > tr > td,
div.table-container .table > tfoot > tr > th,
div.table-container .table > thead > tr > td,
div.table-container .table > thead > tr > th {
	border-top: none !important;
}

.patient-name {
	width: 120px;
	word-break: break-all;
}

.patient-lname {
	width: 140px;
	word-break: break-all;
}

.patient-dob {
	width: 132px;
	word-break: break-all;
}

.patient-phn {
	width: 164px;
	word-break: break-all;
}

.patient-prgm {
	width: 250px;
	word-break: break-all;
}

.patient-email {
	width: 400px;
	word-break: break-all;
}

.toggle-arrow {
	padding-left: 0 !important;
}

.toggle-arrow img {
	position: absolute;
	right: 8px;
	top: 9px;
}

.toggle-arrow img {
	-o-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

i.el-icon-caret-top.arrow-top,
i.el-icon-caret-bottom.arrow-bottom {
	margin-left: 4px;
	font-size: 10px;
}

.patients_list_part {
	margin-left: 60px !important;
	width: 100%;
}

.pat-main-container {
	width: 100%;
}

.patients_list_part .patients_details {
	margin: 61px 0 0 0;
	padding: 0 28px;
	height: 36px;
}

.order-statusname {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #2dd675;
}

.pat-dash {
	font-weight: normal;
	color: #474e53;
	font-family: Rianosans !important;
}

.button-space {
	padding: 0 8px;
}

div[role='tooltip'] > div {
	font-size: 12px !important;
	font-family: RionaSans !important;
}

.table-container .patient_encounters tbody tr {
	border-bottom: none !important;
	display: flex;
}

.main-sec {
	width: 100%;
	float: left;
}

.patient-enctr-details-visiticon {
	margin: 4px 4px 0 0;
}

.encounter_detail_dropdwn {
	width: 52px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #b8c3d0;
	background-color: white;
}

.encounter_fields > .lblbox {
	padding-left: 20px;
	padding-right: 20px;
}

.patientscroll {
	height: 100%;
}

.ellipsed {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.fix-header {
	position: fixed !important;
	z-index: 2;
}

.header-right {
	width: 100%;
	display: flex;
}

.el-picker-panel__content table {
	border-collapse: unset !important;
}

.patients_list_wrapper .patient-listview .hp-label {
	padding: 0;
	position: absolute;
	top: 25%;
}

.patients_list_wrapper .patient-listview .high-priority {
	padding-top: 2px;
	margin-left: -4px;
}
