@use 'sass:math';

$baseUnit: 16px;

$quarterUnit: math.div($baseUnit, 4);
$halfUnit: math.div($baseUnit, 2);
$singleUnit: $baseUnit;
$oneHalfUnit: $baseUnit * 1.5;
$doubleUnit: $baseUnit * 2;
$quadrupleUnit: $baseUnit * 4;
