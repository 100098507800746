.three-dots-wrapper {
	position: relative;
}

.three-dots-wrapper.disabled {
	opacity: 0.3;
	background-color: unset;
	cursor: no-drop;
}

.three-dots-wrapper .el-button {
	padding: 0;
	border: none;
}
.three-dots-wrapper .el-button:hover,
.three-dots-open .el-button {
	background-color: #d8dde4;
}

.three-dots-wrapper .el-button:active {
	background-color: #bac3ce;
}

.three-dots-wrapper .three-dots-icon div,
.three-dots-wrapper .three-dots-icon svg {
	height: 28px;
	width: 28px;
}
.three-dots-wrapper .el-popover {
	padding: 4px 0;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35) !important;
	background-color: #ffffff;
	border: none;
	width: max-content !important;
	right: 0 !important;
	left: unset !important;
}
.three-dots-wrapper ul.three-dots-menu {
	padding: 0;
	margin: 0;
	list-style: none;
}
.three-dots-wrapper ul.three-dots-menu li {
	font: normal 13px/1.38 RionaSans;
	min-height: 32px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0 8px;
	color: #262837;
	cursor: pointer;
}

.three-dots-wrapper ul.three-dots-menu li:hover {
	background-color: #e4e8f1;
}

.three-dots-wrapper ul.three-dots-menu li.disabled {
	color: #bfcbd9;
	cursor: not-allowed;
}
.three-dots-wrapper ul.three-dots-menu li.disabled:hover {
	background-color: #fff;
}

/* Black variant */

.three-dots-wrapper.three-dots-black-variant .el-button {
	background-color: #262837 !important;
	color: white !important;
}

.three-dots-wrapper.three-dots-black-variant .el-button:hover {
	background-color: rgba(237, 239, 243, 0.18) !important;
}

.three-dots-wrapper.three-dots-black-variant .three-dots-icon div,
.three-dots-wrapper.three-dots-black-variant .three-dots-icon svg {
	height: 38px !important;
	width: 38px !important;
}

.three-dots-wrapper.three-dots-black-variant
	.three-dots-icon
	svg
	> g
	> path:nth-child(2) {
	fill: #edeff3 !important;
}

.three-dots-wrapper.three-dots-black-variant .el-popover {
	background-color: rgb(82, 89, 104);
	margin-top: 4px !important;
	z-index: 1000;
}

.three-dots-wrapper.three-dots-black-variant ul.three-dots-menu li,
.black-variant-item {
	min-height: 32px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0 8px;
	color: white !important;
	cursor: pointer;
}

.three-dots-wrapper.three-dots-black-variant ul.three-dots-menu li:hover,
.black-variant-item:hover {
	background-color: #a0abb9 !important;
}

.black-variant-item {
	height: 30px;
	display: flex;
	flex-direction: row;
	padding-left: 4px;
	font-size: 13px;
}

.menu-divider {
	border-top: 1.5px solid rgba(237, 239, 243, 0.2);
	margin-top: 8px;
	margin-bottom: 8px;
}
