.no-outreach-wrapper {
	display: flex;
	width: 100%;
	padding: 14px 16px 13px;
	margin: 16px 0;
	border: 1px solid #ffdce0;
	background-color: #ffdce0;
	color: #86181d;
}

.no-outreach-wrapper .donot-outrch-icon {
	display: flex;
	transform: scale(1.5);
}

.no-outreach-wrapper .nooutrch-content {
	width: calc(100% - 32px);
	padding-left: 16px;
}

.no-outreach-wrapper .inner-nooutrch-content {
	display: flex;
}

.no-outreach-wrapper .inner-nooutrch-content .nooutreach-display-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #86181d;
}

.no-outreach-wrapper .inner-nooutrch-content .nooutreach-date {
	font: normal 12px/1.42 RionaSans;
	color: #86181d;
	margin-left: auto;
}

.no-outreach-wrapper .inner-nooutrch-content .nooutrch-outcome {
	font: normal 500 12px/1.42 RionaSans-RegularItalic;
	color: #86181d;
}

.no-outreach-wrapper .inner-nooutrch-content .nooutrch-created-by {
	font: normal 12px/1.42 RionaSans;
	margin-left: auto;
}
