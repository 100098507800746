.add-edit-user-modal-container {
	width: 368px;
	min-height: 412px;
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	overflow: hidden;
	position: fixed;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.add-edit-user-modal-loading {
	position: relative;
	top: 50%;
}

.add-edit-user-modal-top-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 56px;
	border-bottom: 1px solid #edeff3;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	padding: 17px 16px;
}

.add-edit-user-modal-top-row-x-icon {
	width: 43px !important;
	height: 43px !important;
	border-top-right-radius: 4px;
	transform: scale(1.3);
	position: relative;
	bottom: 11px;
	left: 10px;
}

.add-edit-user-modal-top-row-x-icon > div > svg {
	top: 8px !important;
	left: 9px !important;
}

.add-edit-user-modal-body {
	width: 100%;
	padding: 32px 16px 0px 16px;
	display: flex;
	flex-direction: column;
	min-height: 310px;
	max-height: calc(100vh - 240px);
	overflow-y: overlay;
	overflow-x: hidden;
}

.add-edit-user-modal-fields-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.add-edit-user-modal-fields-container > .inputFieldContainer {
	margin-bottom: 19px;
}

.add-edit-user-modal-fields-container
	> .inputFieldContainer
	.el-select
	.el-select-dropdown
	.el-select-dropdown__list
	.el-select-dropdown__item {
	max-width: 336px;
	overflow: unset;
	white-space: unset;
	height: unset;
}

.add-edit-user-modal-fields-container .inputField {
	margin-bottom: unset;
}

.add-edit-user-modal-fields-container .inputField:disabled {
	color: #bac3ce;
	background-color: #edeff3;
	cursor: not-allowed;
	border: 1px solid #bac3ce !important;
}

.add-edit-user-modal-fields-container .inputFieldDropdown .el-input {
	height: unset;
}

.add-edit-user-modal-fields-container
	.inputFieldDropdown.add-edit-user-modal-one-quarter-width-dropdown
	> div.el-input,
.add-edit-user-modal-fields-container
	.add-edit-user-modal-one-quarter-width-text {
	width: 72px !important;
}

.add-edit-user-modal-fields-container
	.inputFieldDropdown.add-edit-user-modal-tTwo-quarter-width-dropdown
	> div.el-input,
.add-edit-user-modal-fields-container
	.add-edit-user-modal-two-quarter-width-text {
	width: 160px !important;
}

.add-edit-user-modal-fields-container
	.inputFieldDropdown.add-edit-user-modal-three-quarter-width-dropdown
	> div.el-input,
.add-edit-user-modal-fields-container
	.add-edit-user-modal-three-quarter-width-text {
	width: 247px !important;
}

.add-edit-user-modal-fields-container
	.inputFieldDropdown.add-edit-user-modal-four-quarter-width-dropdown
	> div.el-input,
.add-edit-user-modal-fields-container
	.add-edit-user-modal-four-quarter-width-text {
	width: 336px !important;
}

.add-edit-user-modal-button-row {
	width: 100%;
	padding: 11.5px 16px;
	border-top: 1px solid #edeff3;
}

.add-edit-user-modal-button-row .modalButtonDisabled {
	cursor: not-allowed;
}

.add-edit-user-modal-container .relative-section > i.el-icon-date {
	display: none;
}

.add-edit-user-modal-container .rdtPicker {
	position: inherit;
}

.updatedDoNotOutreachAlertInputField {
	height: fit-content;
	margin-bottom: 0px;
}
