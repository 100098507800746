.hold-result-release-wrapper
	> .chk-hold-result-release
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	border-color: #bfcbd9 !important;
}

.hold-result-release-wrapper
	.chk-hold-result-release
	> span.el-checkbox__input.is-checked
	> span.el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.hold-result-release-wrapper
	.el-checkbox__input.is-checked
	.el-checkbox__inner {
	background-color: #7b26c9 !important;
}

.hold-result-release-wrapper
	.chk-hold-result-release
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}
