.patients-list-wrapper {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 0 0 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.patients-list-wrapper .list-loading .el-loading-spinner {
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	background: transparent;
	min-width: inherit;
}

.patients-list-wrapper > div .custom-table table td > div > p {
	margin: unset;
}

.patients-list-wrapper > div .custom-table table td > div > .sub {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.patients-list-wrapper > div .custom-table table td > div > .small {
	font-family: RionaSans;
	font-size: 11px;
	font-weight: 400;
	color: #7b8291;
}

.patients-list-wrapper
	> div
	.child-wrapper
	.custom-table
	table
	td.encounter-type
	> div {
	display: flex;
	align-items: center;
}

.patients-list-wrapper
	> div
	.child-wrapper
	.custom-table
	table
	td.encounter-type
	> div
	> .provider-typeimg {
	width: 28px;
	height: 28px;
	text-align: center;
}

.patients-list-wrapper
	> div
	.child-wrapper
	.custom-table
	table
	td.encounter-type
	> div
	.prov-typemain
	> div {
	margin-left: 4px;
	width: unset !important;
}

.patients-list-wrapper
	> div
	.child-wrapper
	.custom-table
	table
	td.encounter-type
	> div
	.prov-typemain
	.visit-status {
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: 700;
}

.patients-list-wrapper .create-patient {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #7b26c9;
	margin-right: auto;
	width: 149px;
	height: 36px;
	color: #ffffff;
	padding-left: 8px;
	border-radius: 5px;
	cursor: pointer;
	font: normal 500 13px RionaSans-Medium;
}

.patients-list-wrapper .create-patient div {
	width: 28px;
	height: 28px;
	margin-right: 8px;
}

.patients-list-wrapper .create-patient:hover {
	background-color: #9c5dd7;
}

.patients-list-wrapper .create-patient:active {
	background-color: #621ea0;
}

.patients-list-wrapper .create-lab-order {
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	color: #7b26c9;
	margin-left: 16px;
	cursor: pointer;
}

.patients-list-wrapper .create-lab-order:hover,
.patients-list-wrapper .create-lab-order:active {
	text-decoration: underline;
}

.patients-list-wrapper .moreFiltersModalContainer {
	width: 368px;
}
