.AppointmentSection {
	display: flex;
	flex-direction: row;
	padding-bottom: 24px;
	width: 632px;
}
.AppointmentSection.AppointmentSectionHorizontalLine {
	border-bottom: 1px solid #edeff3;
	margin-bottom: 24px;
}
.AppointmentSection .AppointmentSectionLeft {
	width: 200px;
}
.AppointmentSection .AppointmentSectionLeft .AppointmentSectionHeading {
	font: bold 14px/1.43 RionaSans-Bold;
	color: #262837;
	margin-bottom: 4px;
}
.AppointmentSection .AppointmentSectionLeft .AppointmentSectionDescription {
	font: normal 12px/1.33 RionaSans;
	color: #7b8291;
	margin-bottom: 4px;
}
.AppointmentSection .AppointmentSectionLeft .AppointmentSectionRequired {
	font: bold 12px/1.43 RionaSans-Bold;
	color: #e74c3c;
}
.AppointmentSection .AppointmentSectionLeft .AppointmentSectionOptional {
	font: bold 12px/1.43 RionaSans-Bold;
	color: #525968;
}
.AppointmentSection .AppointmentSectionRight {
	width: 416px;
	margin-left: 16px;
}
.AppointmentSection
	.AppointmentSectionRight
	.scheduleAppointmentInputContainer
	.appointmentRequiredField {
	font: bold 12px/1.42 RionaSans-Bold;
	color: #e74c3c;
	top: 0px;
	left: -5px;
	position: absolute;
}
.AppointmentSection
	.AppointmentSectionRight
	.scheduleAppointmentInputContainer
	.scheduleAppointmentInputField {
	font: normal 13px RionaSans !important;
}
.AppointmentSection .AppointmentSectionRight .required::before {
	font: bold 12px/1.42 RionaSans-Bold;
	color: #e74c3c;
	top: -2px;
	left: 2px;
	position: relative;
}
.AppointmentSection .AppointmentSectionRight label {
	font: normal 12px/1.42 RionaSans !important;
	color: #262837;
}
.AppointmentSection
	.AppointmentSectionRight
	.scheduleAppointmentInputContainer
	.scheduleAppointmentInputTitle {
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	text-transform: capitalize;
}
.AppointmentSection .AppointmentSectionRight .bold-label {
	font: normal 13px/1.38 RionaSans-Medium !important;
	font-weight: 500 !important;
	color: #262837;
	margin-bottom: 4px;
}
.AppointmentSection input::placeholder {
	font: normal 13px/1.38 RionaSans;
	color: #bac3ce;
}
.AppointmentSection input.el-input__inner {
	border: 1px solid #bac3ce;
	height: 32px;
}
.AppointmentSection input.el-input__inner:hover {
	border: solid 1px #70cbf1 !important;
}
.AppointmentSection input.el-input__inner:focus {
	border: solid 1.5px #40b9ec !important;
}
.shippingAddressWarningText {
	color: #400404;
	font-size: 13px;
	font-family: 'RionaSans-MediumItalic';
	padding-left: 4px;
}
