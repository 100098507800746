.modalTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 56px;
	border-bottom: 1px solid #edeff3;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	padding: 17px 16px;
}

.modalTopRowXIcon {
	width: 43px;
	height: 43px;
	border-top-right-radius: 4px;
	transform: scale(1.3);
	position: relative;
	bottom: 10px;
	left: 9px;
}

.modalTopRowXIcon > div > svg {
	top: 8px;
	left: 9px;
}

.formModal {
	background-color: white;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
}

.selectPillFormContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 16px;
	margin-top: 2px;
}

.formModalBodyContainer {
	padding: 32px 16px 25px 16px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.formModalLoader {
	position: unset;
}

.formModalLoader > div {
	position: unset;
}
