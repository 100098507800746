.radio-filter {
	width: 100%;
	padding: 8px 19px;
	border-bottom: 1px solid #edeff3;
}

.radio-filter > span {
	color: #7b8291;
	font-size: 12px;
}

.radio-filter .gmi-radio-group {
	display: flex !important;
}

.radio-filter .gmi-radio-group > label {
	padding: 0;
	margin: 0 32px 0 0;
}
