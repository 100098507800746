.no-recipients-text{
      color: #606266;
      font-size: 16px;
      font-family: RionaSans;
      font-weight: 400;
      line-height: 20px;
      overflow-wrap: break-word;
      word-wrap: break-word;
  }
  .trash-icon-style {
    position: relative;
    top: 2px;
    margin-right: 4px;
    cursor: pointer;
  }