.test-view-container {
	padding-top: 15px;
	padding-left: 28px;
	padding-right: 28px;
}

.test-view-container::after {
	content: '';
	display: table;
	clear: both;
}

.test-view-loader {
	height: 100%;
}

.test-view-container .test-back-link {
	width: 94px;
	height: 20px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #40b9ec;
	cursor: pointer;
}

.test-view-container .test-header {
	min-width: 570px;
	min-height: 36px;
	font-family: RionaSans-Bold;
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	/* display: flex; */
	align-items: center;
}

.test-view-container .test-sub-header {
	height: 20px;
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

.test-view-container .separator hr {
	/* width: 968px; */
	border: solid 1px #d8dde4;
}

.test-view-container .col-12 {
	width: 100%;
	float: left;
}

.test-view-container .col-4 {
	float: left;
	width: 33.33%;
}

.test-view-container .col-3 {
	float: left;
	width: 25%;
}

.test-view-container .col-2 {
	float: left;
	width: 12.5%;
}

.test-view-container .col-6 {
	float: left;
	width: 50%;
}

.test-view-container .col-8 {
	float: left;
	width: 66.66%;
}

.test-view-container .width-20 {
	width: 20%;
	float: left;
}

.test-view-container .width-40 {
	width: 40%;
	float: left;
}

.test-view-container .width-10 {
	width: 10%;
	float: left;
}

.test-view-container .width-30 {
	width: 30%;
	float: left;
}

.test-view-container .width-50 {
	width: 50%;
	float: left;
}

.test-view-container .width-60 {
	width: 60%;
	float: left;
}

/*****************************************************
        Start: Indicate results below: radio button  
*****************************************************/
.test-view-container .view-question-label {
	min-height: 21px;
	font: normal bold 15px/1.4 RionaSans-Bold;
	letter-spacing: normal;
	color: #000000;
}

.test-view-container .result-actionable-label .el-popover.pp-result-actionable,
.test-view-container .view-question-label .el-popover.pp-finding-result {
	background-color: #000000;
	color: #ffffff;
}

.test-view-container .result-actionable-label .tooltip-info,
.test-view-container .view-question-label .tooltip-info {
	margin-left: 3px;
}

.test-view-container
	.el-popover.pp-result-actionable[x-placement^='top']
	.popper__arrow::after,
.test-view-container
	.el-popover.pp-finding-result[x-placement^='top']
	.popper__arrow::after {
	border-top-color: #000000;
}

.test-view-container
	.el-popover.pp-result-actionable[x-placement^='bottom']
	.popper__arrow::after,
.test-view-container
	.el-popover.pp-finding-result[x-placement^='bottom']
	.popper__arrow::after {
	border-bottom-color: #000000;
}

.test-view-container
	.result-actionable-label
	.el-popover.pp-result-actionable
	a {
	color: #337ab7;
}

.test-view-container
	.result-actionable-label
	.el-popover.pp-result-actionable
	a:focus,
.test-view-container
	.result-actionable-label
	.el-popover.pp-result-actionable
	a:hover {
	text-decoration: underline;
}

.test-view-container .view-question-label .tt-finding-result,
.test-view-container .result-actionable-label .tt-result-actionable {
	display: inline-block;
	margin-left: 4px;
}

.view-question-text .el-radio {
	padding: 12px;
	border: 1px solid #edeff3;
	border-radius: 4px;
	width: 100%;
}

.view-question-text .el-radio:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.view-question-text .el-radio:active {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.view-question-text .is-radio-checked {
	border: 1px solid #7b26c9;
}

.view-question-text .is-radio-checked:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

/*****************************************************
        Start: Indicate results below: radio button  
*****************************************************/
.test-view-container .result-actionable-label {
	font: normal bold 15px/1.4 RionaSans-Bold;
	color: #000000;
	margin-top: 32px;
}

.test-view-container .result-actionable-label .el-tooltip__popper.is-dark {
	width: 265px !important;
}

.test-view-container .result-actionable-yesno-button-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 50%;
}

.test-view-container
	.result-actionable-yesno-button-wrapper
	.result-actionable-yesno-button {
	width: 72px;
	height: 32px;
	border-radius: 32px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	padding-top: 7px;
	user-select: none;
	cursor: pointer;
}

.test-view-container
	.result-actionable-yesno-button-wrapper
	.yesno-button-unselected {
	border: 1px solid #edeff3;
	background-color: white;
	color: #262837;
}

.test-view-container
	.result-actionable-yesno-button-wrapper
	.yesno-button-selected {
	border: 1px solid #e2d3f2;
	background-color: #e2d3f2;
	color: #491678;
}

.test-view-container .result-actionable-yesno-button-wrapper .yes-button {
	margin-right: 10px;
}

.test-view-container .result-select-action-wrapper {
	border-radius: 6px;
	margin-top: 32px;
}

.test-view-container .result-select-action-wrapper .btn-result-select-save {
	font: normal 500 13px/1.38 RionaSans-Medium;
	background-color: #7b26c9;
	color: #ffffff;
	margin-left: 0 !important;
	border: none;
	width: 160px;
}

.test-view-container
	.result-select-action-wrapper
	.btn-result-select-save:hover {
	background-color: #9c5dd7;
}

.test-view-container
	.result-select-action-wrapper
	.btn-result-select-save:active {
	background-color: #621ea0;
}

.test-view-container .result-select-action-wrapper .btn-result-select-cancel {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
}

.test-view-container .test-detail-separator {
	float: left;
	width: 100%;
	margin-top: 24px;
}

/***************************************************** 
        End: Indicate results below: radio button  
*****************************************************/

.test-view-container .padding-top-32px {
	padding-top: 32px;
}

.test-view-container .padding-top-24px {
	padding-top: 20px;
}

.test-view-container .padding-left-20px {
	padding-left: 20px;
}

.error-star {
	color: #fa392e;
	width: 10px;
	float: left;
	margin-left: -10px;
	font-size: 15px;
}

.test-view-container .view-label {
	min-height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.test-view-container .view-text {
	/* height: 20px; */
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.test-view-container .el-radio-group .el-radio {
	display: block;
	margin-top: 4px;
}

.test-view-container .el-radio + .el-radio {
	margin-left: 0;
}

.test-view-container .el-radio__inner {
	width: 12px;
	height: 12px;
}

.test-view-container .el-radio__label {
	font-size: 13px;
}

.test-view-container .el-radio__input.is-checked .el-radio__inner {
	border-color: #bd92e4;
	background: #ffffff;
}

.test-view-container .el-radio__input.is-checked .el-radio__inner::after {
	background-color: #7b26c9;
}

.test-view-container .required-field {
	width: 10px;
	height: 22px;
	font-family: RionaSans;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fa392e;
}

.test-view-container .order-status-label {
	width: 134px;
	height: 28px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.test-view-container .order-status-text {
	font-size: 13px;
	font-family: RionaSans-Medium;
	padding: 5px;
}

.test-view-container .order-status-red {
	border: solid 1px #fe9189;
	background-color: rgba(254, 145, 137, 0.2);
}

.test-view-container .order-status-red .order-status-text {
	color: #fa392e;
}

.test-view-container .order-status-gray {
	border: solid 1px #d7dde5;
	background-color: rgba(237, 239, 243, 0.5);
}

.test-view-container .order-status-gray .order-status-text {
	color: #9eabbb;
}

.test-view-container .order-status-blue {
	border: solid 1px #8ddff8;
	background-color: rgba(141, 223, 248, 0.2);
}

.test-view-container .order-status-blue .order-status-text {
	color: #00bbf1;
}

.test-view-container .order-status-yellow {
	border: solid 1px #ffe85f;
	background-color: rgba(255, 232, 95, 0.2);
}

.test-view-container .order-status-yellow .order-status-text {
	color: #ffc900;
}

.test-view-container .order-status-green {
	border: solid 1px #7af0b6;
	background-color: rgba(122, 240, 182, 0.15);
}

.test-view-container .order-status-green .order-status-text {
	color: #00db44;
}

.test-view-container img.provider-image {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: solid 1px #d4dfe7;
	background-color: #d8d8d8;
	float: left;
}

.test-view-container .provider-name {
	height: 20px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	margin-left: 8px;
	float: left;
}

.test-view-container label {
	font-weight: 500;
}

.test-view-container .view-text-link {
	/* min-width: 210px; */
	height: 20px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7b26c9;
	text-transform: capitalize;
	cursor: pointer;
}

.test-view-container .view-text-link:hover {
	text-decoration: underline;
}

.test-view-container .view-text-link-inner {
	cursor: pointer;
}

.test-view-container img.link-image {
	width: 15px;
	height: 15px;
	margin-left: 1px;
	vertical-align: sub;
}

.test-view-container .text-sub-header {
	min-width: 241px;
	height: 20px;
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
}

.test-view-container .pat-dash {
	float: left;
}

.test-view-container .test-edit-image {
	cursor: pointer;
}

.test-view-container .el-form-item__label {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	padding: 0;
}

.tests-form .test-view-container .el-form-item__label {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	padding: 0;
	height: 17px;
	margin: 0;
	width: 100%;
	text-align: left;
}

.test-view-container .cancel-button {
	width: 70px;
	border-radius: 6px;
	border: solid 1px #7b8291;
	float: left;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 12px;
	padding-right: 12px;
	cursor: pointer;
}

.test-view-container .cancel-button .text {
	width: 42px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7b8291;
}

.test-view-container .save-button {
	width: 54px;
	height: 36px;
	border-radius: 6px;
	background-color: #40b9ec;
	float: right;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 12px;
	padding-right: 12px;
	cursor: pointer;
}

.test-view-container .save-button .text {
	width: 30px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	margin-top: 9px;
	margin-bottom: 9px;
}

.test-view-container .action-buttons {
	padding-top: 24px;
	padding-bottom: 24px;
	width: 100%;
	float: left;
	margin-top: 55px;
}

.test-view-container .el-cascader-menu__item.is-active {
	background-color: #20a0ff !important;
}

.test-view-container .el-cascader-menu__item.is-active:hover {
	background-color: #1c8de0 !important;
}

.test-view-container .el-form-item {
	margin-bottom: 0px;
}

.test-view-container .el-date-editor.el-input {
	width: 100%;
}

.tests-form .el-form-item.is-required .el-form-item__label:before {
	content: '*';
	color: #ff4949;
	font-size: 15px;
	width: 8px;
	float: left;
	text-align: left;
}

.tests-form .test-orderstatus .el-input__inner {
	border: 1px solid #bac3ce;
	width: 178px;
	height: 32px;
}

.tests-form .el-form-item__content {
	line-height: 32px;
}

.tests-form .el-cascader__label {
	padding-left: 8px;
}

.test-divider {
	height: 19px;
}

.tests-form .el-input__inner {
	height: 32px;
}

.tests-form .el-input__icon .el-icon-caret-bottom {
	font-size: 12px;
}

.test-view-container hr {
	border-color: #d8dde4;
}

.tests-form .cancel-button {
	height: 36px;
}

.tests-form .el-radio__input.is-checked .el-radio__inner {
	border-color: #9eddf6;
	background: #ffffff;
}

.tests-form .el-radio__input.is-checked .el-radio__inner::after {
	background-color: #40b9ec;
}

.tests-container .add-another-gene-button {
	width: 173px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 24px;
}

.tests-container .add-another-gene-button.add-finding {
	width: 140px;
}
.tests-container .add-another-gene-button.add-another-finding {
	width: 190px;
}
.tests-container .add-another-gene-button .add-another-gene-image {
	width: 28px;
	height: 28px;
	object-fit: contain;
	margin: 0 8px;
}

.tests-container .add-another-gene-button .add-another-gene-text {
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.test-view-container .test-header-data {
	float: left;
	height: 36px;
	display: flex;
	align-items: center;
}

.test-view-container .el-radio {
	white-space: pre-wrap;
}

.word-break {
	word-break: break-word;
}

.disable-indicate-result-section {
	opacity: 0.45;
	pointer-events: none;
}

/* gene negative result dialog */
.dlg-gene-negative-result {
	width: 368px !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	max-height: calc(100vh - 120px);
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
}

.dlg-gene-negative-result .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.dlg-gene-negative-result .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.dlg-gene-negative-result .el-dialog__title img {
	height: 24px;
	width: 24px;
	margin-right: 16px;
}

.dlg-gene-negative-result .el-dialog__body {
	margin: 32px 0;
	padding: 0 16px !important;
	font: normal 500 13px/1.38 RionaSans;
	color: #262837;
}

.dlg-gene-negative-result .bold-fontfamily {
	font-family: RionaSans-Bold;
}

.dlg-gene-negative-result .dialog-footer {
	padding: 12px 16px;
	border-top: 1px solid #edeffe;
	display: flex;
}

.dlg-gene-negative-result .dialog-footer button {
	font: normal 500 13px/1.38 RionaSans-Medium;
	text-transform: capitalize;
	border-radius: 6px;
	height: 36px;
	width: 160px;
}

.dlg-gene-negative-result .dialog-footer button:first-child {
	margin-right: 16px;
	color: #7b26c9;
	border: 1px solid #7b26c9;
}

.dlg-gene-negative-result .dialog-footer button:first-child:hover {
	background-color: rgba(123, 38, 201, 0.15);
}

.dlg-gene-negative-result .dialog-footer button:first-child:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.dlg-gene-negative-result .dialog-footer button:last-child {
	background-color: #7b26c9;
	color: #ffffff;
	margin-left: 0 !important;
	border: none;
}

.dlg-gene-negative-result .dialog-footer button:last-child:hover {
	background-color: #9c5dd7;
}

.dlg-gene-negative-result .dialog-footer button:last-child:active {
	background-color: #621ea0;
}

/* gene-result-cancel-dlg */

.test-view-container .clinical-comments-textarea .el-textarea {
	height: 99.1%;
	/* width: 77.4%    */
}
.test-view-container .c-action-buttons {
	padding-top: 19px !important;
}

.test-view-container .c-action-buttons .c-cancel-button {
	width: 124px;
	height: 36px;
	border-radius: 6.5px;
	border: solid 1px #7b26c9;
	background-color: #ffffff;
	justify-content: center;
	align-items: center;
	display: flex;
	float: left;
	cursor: pointer;
}

.test-view-container .c-action-buttons .c-cancel-button .c-text {
	width: 100px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #7b26c9;
	float: left;
}

.test-view-container .c-action-buttons .c-save-button {
	width: 124px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	justify-content: center;
	align-items: center;
	display: flex;
	float: right;
	cursor: pointer;
}

.test-view-container .c-action-buttons .c-save-button .c-text {
	width: 100px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.test-view-container .e-action-buttons {
	padding-top: 36px !important;
}

.test-view-container .e-action-buttons .e-cancel-button {
	width: 124px;
	height: 36px;
	border-radius: 6.5px;
	border: solid 1px #7b26c9;
	background-color: #ffffff;
	justify-content: center;
	align-items: center;
	display: flex;
	float: left;
	cursor: pointer;
}

.test-view-container .e-action-buttons .e-cancel-button .e-text {
	width: 100px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #7b26c9;
	float: left;
}

.test-view-container .e-action-buttons .e-save-button {
	width: 124px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	justify-content: center;
	align-items: center;
	display: flex;
	float: right;
	cursor: pointer;
}

.test-view-container .e-action-buttons .e-save-button .e-text {
	width: 100px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

/* unable to change finding dialog */
.unable-change-finding-dialog {
	width: 368px !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	max-height: calc(100vh - 56px);
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
}

.unable-change-finding-dialog .el-dialog__header {
	padding: 17px 16px !important;
	border-bottom: 1px solid #edeff3;
}

.unable-change-finding-dialog .el-dialog__title {
	font: normal bold 16px RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.unable-change-finding-dialog .el-dialog__title img {
	height: 24px;
	width: 24px;
	margin-right: 16px;
}

.unable-change-finding-dialog .el-dialog__title .title-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.unable-change-finding-dialog .el-dialog__title .close-btn-icon {
	margin-left: auto;
}

.unable-change-finding-dialog .el-dialog__title .close-icon > div,
.unable-change-finding-dialog .el-dialog__title .close-icon > div > svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.unable-change-finding-dialog .el-dialog__title .close-icon svg .GMICloseIcon {
	fill: #262837;
}

.unable-change-finding-dialog .el-dialog__body {
	padding: 8px 36px 16px 20px;
	font: normal 14px RionaSans;
	color: #262837;
}

.unable-change-finding-dialog .bold-fontfamily {
	font-family: RionaSans-Bold;
}
