.tags > span {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-left: 4px;
	padding: 4px;
	border-radius: 50%;
	vertical-align: middle;
	position: relative;
	margin-left: 4px;
}

.tags > span::before {
	content: ' ';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #4b3700;
	position: absolute;
}

.tags > span.yellow {
	background-color: #fde776;
}

.tags > span.red {
	background-color: #f4a4a5;
}

.tags > span.orange {
	background-color: #ffcb9f;
}
