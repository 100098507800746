.payment-info-tab .insurance-auth-info {
	width: 964px;
	min-height: 150px;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.35);
	background-color: #ffffff;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	max-height: calc(100vh - 120px);
}

.insurance-auth-info .el-dialog__body {
	padding-top: 0;
	padding-left: 20px;
	padding-right: 28px;
	padding-bottom: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: calc(100vh - 180px);
}

.payment-info-tab .insurance-auth-header {
	width: 355px;
	min-height: 28px;
	font-family: RionaSans-Bold;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.payment-info-tab .insurance-auth-form .el-form-item:first-child {
	padding-left: 0;
	width: 256px;
}

.payment-info-tab .insurance-auth-form .el-form-item {
	padding-left: 20px;
}

.payment-info-tab .insurance-auth-form .auth-datepicker .el-form-item {
	padding-left: 0px;
}

.insurance-auth-form .datepicker-section {
	display: flex;
}

.insurance-auth-form .disabled-view {
	border: solid 1px #bac3ce;
	background-color: #edeff3;
}

.insurance-auth-form .el-input {
	width: 200px;
}

.insurance-auth-form .el-form-item__error {
	font-size: 10px;
}

.auth-datepicker {
	width: 116px;
	position: relative;
}
/* .payment-info-tab .insurance-auth-form .auth-datepicker .label-server-error{position: absolute;bottom: -30px} */
.payment-info-tab .insurance-auth-form .el-form-item {
	position: relative;
	width: 220px;
}
.payment-info-tab .insurance-auth-form .visit-section .el-form-item {
	width: auto;
}
.payment-info-tab .insurance-auth-form .ins-auth-action-buttons {
	display: flex;
	justify-content: space-between;
}

.insurance-auth-form .auth-datepicker .el-input__inner {
	width: 116px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 0;
	color: #262837;
	font-size: 13px;
}

.insurance-auth-form .auth-date-dash {
	width: 24px;
	height: 37px;
	font-family: RionaSans;
	font-size: 26px;
	color: #525968;
	margin-top: 15px;
	text-align: center;
}

.insurance-auth-form .visit-section {
	width: 200px;
}

.insurance-auth-form .error-inputbox {
	border: solid 1px #fb3a2e;
	background-color: rgba(254, 145, 137, 0.5);
}

.insurance-auth-form .authid-athena {
	display: flex;
	justify-content: flex-end;
	margin-top: 19px;
}

.insurance-auth-form .authid-athena .el-form-item {
	width: 200px !important;
}

.insurance-auth-form .ins-auth-action-buttons .save-button {
	width: 65px !important;
	height: 36px;
	border-radius: 6px;
	background-color: #40b9ec;
	float: right;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 12px;
	padding-right: 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
	/* opacity: 0.5; */
}

.insurance-auth-form .ins-auth-action-buttons .cancel-button {
	width: 70px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b8291;
	display: flex;
	align-items: center;
}
.insurance-auth-form .datepicker-container {
	display: flex;
	justify-content: space-between;
}

.insurance-auth-form .el-input__inner {
	width: 200px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 0;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.insurance-auth-form .authpkg-id.el-input {
	width: 256px;
}

.insurance-auth-form div.el-select > div.el-input > input,
.insurance-auth-form span.el-date-editor > div.el-input > input {
	padding-left: 8px;
}

.insurance-auth-form .insurance-auth-part1,
.insurance-auth-part2,
.insurance-auth-part3 {
	display: flex;
	margin-bottom: 19px;
	justify-content: space-around;
}

.insurance-auth-form .el-input__inner::placeholder {
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 13px;
	color: #262837;
}

.payment-info-tab .insurance-auth-form {
	margin-top: 32px;
}

.payment-info-tab .insurance-auth-form .el-row {
	margin-bottom: 19px;
}

.payment-info-tab .insurance-auth-form .el-form-item__label {
	width: 256px;
	height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	float: left;
	text-align: left;
}

.payment-info-tab .insurance-auth-form .ins-auth-action-buttons {
	margin-top: 24px;
	margin-bottom: 24px;
}

.payment-info-tab .insurance-auth-form .ins-auth-action-buttons .save-button {
	width: auto;
}
.payment-info-tab .insurance-auth-form .ins-auth-action-buttons .small-save {
	width: 55px !important;
}
.payment-info-tab
	.insurance-auth-form
	.datepicker-section
	.auth-datepicker
	.el-form-item {
	width: auto !important;
}
.payment-info-tab
	.insurance-auth-form
	.datepicker-section
	.auth-datepicker
	.el-form-item {
	width: auto !important;
}
.payment-info-tab .insurance-auth-form .label-server-error {
	color: #ff4949;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
	font-weight: normal;
}

.loader-auth-insurance-form > div:first-child {
	left: -8px !important;
}

/* .payment-info-tab .insurance-auth-form input::placeholder {
    font-size: 12px !important;
}     */
