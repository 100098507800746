.full-empty-slot {
	background-color: white;
	width: 100%;
	height: 100%;
	cursor: pointer;
	position: relative;
}
.full-empty-slot .slot {
	position: absolute;
	left: 0;
	right: 0;
	background-color: #cee7ad;
	z-index: 5;
	display: flex;
}
.time {
	margin: 10px 0 0 6px;
	font-size: 11px;
	line-height: 12px;
	padding: 0 7px 0 7px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.line {
	position: absolute;
	left: 0;
	right: 0;
}
.solid {
	border-bottom: 1px solid #ddd;
}
.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot
	.active {
	height:100%;
	border-radius: 2px;
	background-color: #00a044;
	top: 1px !important;
	left: 2px;
	color: #ffffff;
	right: 2px;
	font-weight: bold;
}

.schedule-container
	.schedule-calendar
	.rbc-calendar
	.rbc-events-container
	.gm-event
	.full-empty-slot
	.active:hover {
	background-color: #00a044 !important;
	color: #ffffff !important;
}
