.cardleftgap {
	margin-left: 0 !important;
	margin-bottom: 3.5vw !important;
	border-radius: 0.4vw !important;
	-moz-box-shadow: 0 0.073vw 0.29vw 0 rgba(0, 0, 0, 0.14) !important;
	-o-box-shadow: 0 0.073vw 0.29vw 0 rgba(0, 0, 0, 0.14) !important;
	-webkit-box-shadow: 0 0.073vw 0.29vw 0 rgba(0, 0, 0, 0.14) !important;
	box-shadow: 0 0.073vw 0.29vw 0 rgba(0, 0, 0, 0.14) !important;
	padding: 0 !important;
	width: 100%;
	display: flex;
	position: relative;
	word-wrap: break-word;
	flex-direction: column;
	height: auto !important;
	background-color: white;
	border: 0.073vw solid transparent;
	-moz-transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;

	height: 6vw !important;
	width: 14vw !important;
	/*cursor: pointer;*/
}

.cardleftgap:hover {
	background-color: #f7f7f7;
	border: 0.073vw solid #ddd;
	-moz-transition: all 100ms linear;
	-o-transition: all 100ms linear;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
}

.cardgap {
	padding: 0 1vw;
	margin-bottom: 6vw;
	max-width: 33% !important;
	flex-basis: 33% !important;
}

.iconleftgap {
	margin-left: 0.5vw;
}

.iconcolor-box {
	border-radius: 0.2vw;
	margin-top: -1vw;
	width: 3vw;
	height: 3vw;
	line-height: 4.5vw;
	float: left;
	margin-right: 1.09vw;
}

.iconcolor-box > i.material-icons {
	font-size: 2vw;
	color: white;
}

.card-title {
	color: #999999;
	margin: 0;
	font-size: 0.8vw;
	font-weight: bold;
	padding-top: 0.73vw;
	-webkit-font-smoothing: antialiased;
}

.textbox {
	/* padding-right: 0.5vw; */
	white-space: initial;
}

.Patient-text {
	margin-bottom: 0;
	margin-top: 0.6vw;
	color: #262837;
	font-size: 1.2vw;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
}

.contentbox {
	color: #ffffff;
	margin: 0 1.09vw;
	padding: 0;
	position: relative;
	height: 6vw;
}

.btm-btnbox {
	margin: 1.46vw 1.09vw 0.7vw;
	border: 0;
	padding: 0;
	display: flex;
	padding-top: 0.7vw;
	align-items: center;
	border-top: 0.07vw solid #eee;
	border-radius: 0;
	justify-content: space-between;
	background-color: transparent;
}

.buttonbox {
	color: #999999;
	display: inline-flex;
	font-size: 0.9vw;
	line-height: 1.4vw;
	-moz-transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}

.buttonbox button {
	background-color: transparent;
	border: none;
	color: #337ab7;
}

.buttonbox button:hover {
	color: rgba(0, 0, 0, 0.7);
	-moz-transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
	text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.contentbox {
		margin: 0 1.09vw;
		height: 15vw;
	}

	.iconcolor-box {
		margin-top: -2vw;
		width: 10vw;
		height: 10vw;
		line-height: 11vw;
	}

	.iconcolor-box > i.material-icons {
		font-size: 4vw;
	}

	.card-title {
		/*font-size: 2.7vw;*/
	}

	.Patient-text {
		font-size: 3.6vw;
	}

	.btm-btnbox {
		margin: 1.46vw 3.09vw 0.7vw;
	}

	.buttonbox {
		font-size: 2.9vw;
		line-height: 6.4vw;
	}

	.cardleftgap {
		margin-bottom: 4.5vw !important;
	}
}
