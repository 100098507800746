.audit-trail-modal-container {
	width: 368px;
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	overflow: hidden;
	position: fixed;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.audit-trail-modal-loading {
	position: relative;
	top: 50%;
}

.audit-trail-modal-top-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 56px;
	border-bottom: 1px solid #edeff3;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #262837;
	padding: 17px 16px;
}

.audit-trail-modal-top-row-x-icon {
	width: 43px !important;
	height: 43px !important;
	border-top-right-radius: 4px;
	transform: scale(1.3);
	position: relative;
	bottom: 11px;
	left: 10px;
}

.audit-trail-modal-top-row-x-icon > div > svg {
	top: 8px !important;
	left: 9px !important;
}

.audit-trail-modal-body {
	width: 100%;
	padding: 32px 16px 0px 16px;
	display: flex;
	flex-direction: column;
	min-height: 150px;
	max-height: calc(100vh - 240px);
	overflow-y: overlay;
}

.audit-trail-modal-body > .log {
	padding-bottom: 24px;
	border-left: solid 2px #edeff3;
}

.audit-trail-modal-body > .log:last-of-type {
	border-left: solid 2px white !important;
}

.audit-trail-modal-body > .log {
	padding-left: 20px;
}

.audit-trail-modal-body > .log > div {
	display: block;
	font-family: RionaSans;
	font-size: 12px;
	line-height: 1.42;
	color: #7b8291;
}

.audit-trail-modal-body > .log > .title {
	font-size: 14px;
	line-height: 1.43;
	color: #262837;
	position: relative;
}

.audit-trail-modal-body > .log > .title::before {
	position: absolute;
	content: '';
	display: inline-block;
	background: #262837;
	width: 18px;
	height: 18px;
	left: -30px;
	border-radius: 50%;
	border: 5px solid white;
}

.audit-trail-modal-body > .log > .title > span {
	font-family: RionaSans-Bold;
	font-weight: bold;
}

.audit-trail-modal-body > .log > .status {
	opacity: 0.5;
	font-family: RionaSans;
	font-size: 12px;
	font-style: italic;
	color: #e74c3c;
}

.audit-trail-modal-body > .log > .retract {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #e74c3c;
}

.audit-trail-modal-body > .log > .retract span {
	cursor: pointer;
}

.audit-trail-modal-body > .log > .retract span:hover {
	text-decoration: underline;
}

.audit-trail-modal-body > .log > .retract > div {
	white-space: pre;
}

.audit-trail-modal-body > .log > .retract > div .dot {
	display: inline-block;
	width: 3px;
	height: 3px;
	background-color: black;
	vertical-align: middle;
	border-radius: 50%;
}
