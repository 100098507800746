.btn-coupon-code {
	width: 76px;
	height: 32px;
	padding: 6px 0;
	border-radius: 5px;
	background-color: #40b9ec;
	font: normal 500 13px RionaSans;
	text-align: center;
	color: #ffffff;
}

.lbl-coupon-code {
	font: normal 12px RionaSans;
	color: #525968;
}

.summary-couponcode-container {
	padding-bottom: 0 !important;
}

.scheduleAppointmentSideContainer .val-coupon-code {
	font: normal 500 14px RionaSans-Medium;
	color: #262838;
	width: fit-content;
	padding-right: 16px !important;
	max-width: 230px;
}

.scheduleAppointmentSideContainerSection.summary-couponcode-container
	.btn-remove-coupon-code {
	padding-left: 0 !important;
	width: fit-content;
}

.scheduleAppointmentSideContainer .btn-remove-coupon-code {
	font: normal 500 14px RionaSans;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
	background-color: #40b9ec !important;
}

.summary-couponcode-container .error-message {
	font: normal 10px RionaSans;
	color: #ff0000;
	padding-top: 2px;
}

/* start: override element react style */
.txt-coupon-code {
	border: 1px solid #bac3ce;
	height: 32px;
	border-radius: 4px;
	padding: 5px;
	width: 100%;
}

.txt-coupon-code::placeholder {
	font: normal 13px RionaSans !important;
	color: #262837 !important;
	opacity: 0.5 !important;
}

.scheduleAppointmentSideContainer .btn-remove-coupon-code .el-button {
	padding: 0;
	color: #40b9ec !important;
}

.txt-coupon-code:hover {
	border: 1px solid #70cbf1 !important;
}

.txt-coupon-code:focus {
	border: 1.5px solid #40b9ec !important;
}

.el-button.is-disabled.btn-coupon-code,
.el-button.is-disabled.btn-coupon-code:focus,
.el-button.is-disabled.btn-coupon-code:hover {
	color: #fff !important;
}
/* end: override element react style */
