.referral-date {
	.singleSelectModalBodyContainer {
		gap: 8px;
		label {
			margin-bottom: 0px;
		}
		.gmi-radio-group {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding-left: 8px;
		}

		.dateRangeContainer {
			input::placeholder {
				font-size: 14px !important;
			}
		}
	}
}
