.billing_table_wrapper {
	margin: 14px 0px 14px 0px;
	display: flex;
	flex: 1;
	padding-right: 8px;
}

.arrow_forward {
	font-size: 2vw;
	font-style: normal;
	margin-top: 1vw;
	margin-left: 3vw;
	margin-right: 2vw;
	color: #afafaf;
}

.DayPickerInput > input {
	padding: 0.3vw !important;
	font-size: 0.8vw !important;
	border-radius: 4px;
	box-sizing: border-box;
	border-width: 1px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.23);
	border-radius: 4px;
	/*padding-top: 0.5vw !important;*/
}

.partner_invoice_input {
	width: 7vw !important;
	margin-top: 0vw !important;
	height: 2vw !important;
	font-size: 0.8vw !important;
}

.partner_invoice_input > div {
	height: 2vw !important;
}

.affiliation_selector.referral_channel,
.affiliation_selector.referral_program {
	width: 12vw;
	margin-bottom: 0vw !important;
}

.affiliation_selector.patient_billing_status {
	width: 8vw;
	margin-bottom: 1vw !important;
	height: 2.3vw !important;
	font-size: 0.8vw !important;
}

.affiliation_selector.patient_billing_status > div {
	font-size: 0.9vw !important;
	color: rgb(57 68 78) !important;
	font-family: 'RionaSans', serif !important;
}

.affiliation_menu.patient_billing_status {
	color: rgb(57 68 78) !important;
	font-weight: normal !important;
	-webkit-font-smoothing: antialiased;
	font-size: 0.8vw !important;
}

.billing_filters_wrapper {
	width: 12vw;
}

.billing_date_picker {
	font-size: 1vw;
}

.InputFromTo
	.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #f0f8ff !important;
	color: #4a90e2;
}

.chevron_right {
	width: 1vw;
	height: 2vw;
	margin-left: 3vw;
	margin-top: 2vw;
	margin-right: 2vw;
}

.filter_chip {
	font-size: 0.75vw !important;
	margin-right: 0.5vw;
	min-width: 4vw;
	height: 1.7vw !important;
	font-family: inherit !important;
	background-color: #d8dde4 !important;
}

.actionRequired > div > svg > g > g {
	fill: #f1c40f;
}

.completed > div > svg > g > g {
	fill: #00d65b;
}

.actionRequired > div > svg,
.completed > div > svg {
	width: 2vw;
	height: 2vw;
}

.pattr.billing.selected {
	background: #d9f1fb !important;
}

.lab {
	width: 1vw;
	height: 1vw;
}

.patient_invoice_status {
	height: 1.2vw;
}

.patient_invoice_status_icon {
	width: 32px;
	height: 16px;
}

.na {
	width: 3vw;
	height: 1.2vw;
}

.textField {
	padding-left: 0.2vw !important;
	height: 3.5vw;
	margin-top: 0vw !important;
}

.textField > div {
	padding: 0.5vw;
	width: 10vw;
	margin-top: 1vw;
	width: 12vw;
	font-size: 1vw;
	height: 2vw;
}

.textField > input {
	font-size: 0.8vw !important;
}

.export-buttons-span > span {
	position: absolute;
	display: flex;
	align-items: center;
}

.aply-svg-margin {
	margin-right: 0.5vw;
}

.set-on-views {
	height: 0.8vw;
	font-size: 0.7vw;
}

@media only screen and (min-width: 1600px) {
	.aply-svg-margin {
		margin-right: 0.3vw;
	}
}

.icons-btn-left {
	height: auto !important;
	line-height: 1;
}

.sliding_detail_panel {
	width: calc(40% - 16px);
	margin-left: 16px;
	background: white;
	box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.14);
	border: 1px solid #ddd;
	transition-property: all;
	transition-duration: 1s;
	transition-timing-function: ease;
	border-radius: 6px;
	height: 100%;
}

.sub_grid_one {
	display: grid;
	grid-template-columns: auto auto auto;
	grid-row-gap: 0.7vw;
	/*margin-bottom: 0.8vw;*/
}

.value_labels_enc.info_grid {
	width: 8vw !important;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.affiliation_menu.billing {
	font-size: 0.8vw !important;
	color: #353535 !important;
	-webkit-font-smoothing: antialiased;
	font-weight: normal !important;
}

.title_enc_info {
	padding-top: 1vw;
	padding-left: 1vw;
	font-weight: bold;
	font-size: 1vw;
}

.partner_invoice_input > div > input {
	font-size: 0.8vw !important;
}

.detail_grid {
	display: grid;
	grid-template-columns: auto auto;
	grid-row-gap: 1.5vw;
	padding: 1vw;
	padding-right: 0vw;
	color: #edeff3;
	-webkit-font-smoothing: antialiased;
}

.detail_patient_uuid {
	font-size: 0.65vw;
}

.sliding_detail_panel.show {
	-webkit-transform: translateX(0);
	transition: all 0.75s ease;
}

@keyframes slide-in {
	0% {
		-webkit-transform: translateX(-100%);
	}
	100% {
		-webkit-transform: translateX(0%);
	}
}

@-webkit-keyframes slide-in {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0%);
	}
}

::-webkit-scrollbar-corner {
	background: #262837;
}

.expand_button.billing {
	width: 7vw;
	margin-top: 0.5vw;
	background-color: #7b26c9 !important;
}

/* DON'T REMOVE THIS COMMENT */

/* .scrollableDetailSection {
    max-height: 75;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.14);
} */

.stepper_button.billing {
	width: 7vw;
	margin-top: 1.75vw;
	margin-left: 1.5vw;
	min-height: 1.85vw !important;
	height: 1.85vw !important;
	font-size: 0.7vw !important;
	font-family: 'RionaSans', serif !important;
}

.stepper_button.billing.export {
	margin-left: 0vw;
	height: 2.5vw !important;
	width: 12.5vw;
	font-family: 'RionaSans', serif !important;
}

/*************************************************************************
                      GMI 2.0 Design Billing Module
**************************************************************************/

.billing-db-wrapper {
	margin-top: 60px;
	margin-left: 68px;
	width: calc(100vw - 68px);
	min-height: calc(100vh - 75px);
	overflow-x: auto;
}

/*********************************************************************
                    billing dashboard panel
**********************************************************************/

.billing-db-fltr-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 28px;
}

.btn-export-billing-db {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #7b26c9;
	width: 102px;
	min-width: 102px;
	height: 36px;
	color: #ffffff;
	padding-left: 8px;
	padding-bottom: 6px;
	border-radius: 6px;
	padding-top: 9px;
	margin-left: 20px;
	cursor: pointer;
	font: normal 500 13px/1.38 RionaSans-Medium;
	margin-right: 39px;
	color: #ffffff;
	background-color: #7b26c9;
}

.btn-export-billing-db:hover {
	background-color: #9c5dd7;
}

.btn-export-billing-db:active {
	background-color: #621ea0;
}

.btn-export-txt {
	padding: 16px 9px 16px 9px;
	user-select: none;
}

.billing-db-fltr-wrapper .search-box {
	display: flex;
	position: relative;
	width: 14%;
	height: 36px;
	float: none;
}

.billing-db-fltr-wrapper .search-box .search-textbox {
	border-radius: 6px;
	border: solid 1px #d8dde4;
	background-color: #ffffff;
	font-family: RionaSans-Medium !important;
	color: rgba(82, 89, 104, 0.5);
	font-size: 13px;
	text-indent: 30px;
	color: #1f2d3d;
	min-width: 40px;
}

.billing-db-fltr-wrapper .search-box,
.billing-db-fltr-wrapper .search-box .search-textbox {
	width: 200px;
}

.billing-db-fltr-wrapper .search-box .search-textbox::placeholder {
	color: rgba(82, 89, 104, 0.5);
	font-size: 13px !important;
}

.billing-db-fltr-wrapper .search-box .search-icon {
	position: absolute;
	left: 1px;
	top: 6px;
	background: #ffff;
}

.billing-db-fltr-wrapper .search-box .search-icon img {
	width: 14px;
	height: 22px;
	margin-left: 11px;
}

.billing-db-fltr-wrapper .referral_channel,
.billing-db-fltr-wrapper .referral_program {
	width: 160px;
	height: 36px;
	margin-right: 12px;
}

.billing-db-fltr-wrapper .billing-db-fromdate,
.billing-db-fltr-wrapper .billing-db-todate {
	width: 116px;
	height: 36px;
	border-radius: 4px;
	font: normal 13px/1.38 RionaSans;
	color: #262837;
}

.billing-db-fltr-wrapper .billing-db-fromdate input::placeholder,
.billing-db-fltr-wrapper .billing-db-todate input::placeholder {
	font: normal 13px/1.38 RionaSans !important;
	color: #262837 !important;
}

.billing-db-fltr-wrapper .divider {
	width: 1px;
	height: 32px;
	border: 1px solid #d5d9de;
	display: inline-block;
	margin: 0 20px;
}

.billing-db-pagination > button {
	width: 2vw !important;
	padding-top: 0vw !important;
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.billing-db-pagination {
	margin-left: auto;
}

.billing-db-pagination button:first-child,
.billing-db-pagination button:last-child {
	color: rgba(160, 103, 214);
}

/*****************************************************************
                    billing detail panel
******************************************************************/

.detail_header_bar {
	background-color: #262837;
	padding-bottom: 20px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

/*******************************************************************
                        override element react style
********************************************************************/

.billing-db-fltr-wrapper .billing-db-fromdate .el-date-editor.el-input,
.billing-db-fltr-wrapper .billing-db-todate .el-date-editor.el-input {
	width: auto;
}

.billing-db-fltr-wrapper .el-input__inner {
	font: normal 13px/1.38 RionaSans;
	color: #262837;
}

.billing-db-fltr-wrapper .el-select .el-input__inner {
	text-overflow: ellipsis;
}

.billing-db-fltr-wrapper .el-input__icon + .el-input__inner {
	padding-right: 28px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table {
	table-layout: fixed;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	.custom-table,
.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	.custom-table
	> table {
	width: 100%;
	min-width: unset;
	max-width: unset;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	.custom-table
	> table
	.GMITooltip
	.el-tooltip__rel {
	max-width: fit-content !important;
}
.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> tbody
	> tr
	> td {
	padding: 0px 0px 0px 20px;
	word-wrap: break-word;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> tbody
	> tr
	> td
	.multiple-labs {
	font-family: RionaSans-RegularItalic;
	-webkit-font-smoothing: antialiased;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th {
	padding-left: 20px;
	padding-right: 0px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(1) {
	width: 40px;
	margin-left: unset;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(2) {
	width: 10%;
	min-width: 100px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(3) {
	width: 15%;
	min-width: 100px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(4) {
	width: 9%;
	min-width: 104px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(5) {
	width: 54px;
	min-width: 54px;
	max-width: 54px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(6) {
	width: 54px;
	min-width: 54px;
	max-width: 54px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(7) {
	width: 66px;
	min-width: 66px;
	max-width: 66px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(8) {
	width: 10%;
	min-width: 150px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(9) {
	width: 9%;
	min-width: 80px;
}

.billing_table_wrapper
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(10) {
	width: 9%;
	min-width: 64px;
}

.billing_table_wrapper.billing_table_side_panel_open
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(1) {
	width: 40px;
	margin-left: unset;
}

.billing_table_wrapper.billing_table_side_panel_open
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(2) {
	width: 20%;
}

.billing_table_wrapper.billing_table_side_panel_open
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(3) {
	width: 30%;
}

.billing_table_wrapper.billing_table_side_panel_open
	> div.billing_dashboard
	> div.billing_enc_list_wrapper
	> table
	> thead
	> tr:nth-child(1)
	> th:nth-child(4) {
	width: 18%;
}

.billing_enc_list_row .name_tooltop > div {
	max-width: '215px';
	overflow: 'hidden';
	text-overflow: 'ellipsis';
	white-space: 'nowrap';
}

.noBackgroundSpinner > div {
	background-color: unset !important;
}

.billingBulkUpdateModalContainer {
	width: 368px;
	height: 320px;
	border-radius: 4px;
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
	background-color: white;
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 44px;
	left: 42px;
	z-index: 1;
}

.billingBulkUpdateModalTopRow {
	width: 100%;
	height: 40px;
	border-bottom: 2px solid #edeff38c;
}

.billingBulkUpdateModalTopRowText {
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	color: #262837;
	padding: 10px 16px;
}

.billingBulkUpdateModalBodyContainer {
	padding: 15px 16px 9px 16px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.billingBulkUpdateModalInputSectionContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 17px;
}

.billingBulkUpdateModalInputHeader {
	font-size: 12px;
	color: #262837;
}

.billingBulkUpdateModalInputSectionContainer
	> div.el-select
	> div.el-input
	> input::placeholder {
	font-size: 13px !important;
}

.billingBulkUpdateModalInputSectionContainer
	> div.el-select
	> div.el-input
	> input:hover {
	border-color: #8391a5;
}

.billingBulkUpdateModalInputSectionContainer
	> div.el-select
	> div.el-input
	> input:active,
.billingBulkUpdateModalInputSectionContainer
	> div.el-select
	> div.el-input
	> input:focus {
	border-color: #20a0ff;
}

.billingBulkUpdateModalInputSectionContainer
	> span.el-date-editor
	> div.el-input {
	width: 100% !important;
}

.billingBulkUpdateModalInputSectionContainer
	> span.el-date-editor
	> div.el-input
	> input.el-input__inner::placeholder {
	font-size: 13px !important;
}

.billingBulkUpdateModalInputSectionContainer
	> span.el-date-editor
	> div.el-input
	> input.el-input__inner:hover {
	border-color: #8391a5;
}

.billingBulkUpdateModalInputSectionContainer
	> span.el-date-editor
	> div.el-input
	> input.el-input__inner:active,
.billingBulkUpdateModalInputSectionContainer
	> span.el-date-editor
	> div.el-input
	> input.el-input__inner:focus {
	border-color: #20a0ff;
}

.billingBulkUpdateModalInputText {
	font-size: 13px;
	border: 1px solid rgb(191, 203, 217);
	border-radius: 4px;
	color: rgb(38, 40, 55);
	height: 36px;
	width: 100%;
	padding: 3px 28px 3px 10px;
}

.billingBulkUpdateModalInputText:hover {
	border-color: #8391a5;
}

.billingBulkUpdateModalInputText:active,
.billingBulkUpdateModalInputText:focus {
	border-color: #20a0ff;
}

.billingBulkUpdateModalInputText::placeholder {
	font-size: 13px !important;
	color: rgb(151, 168, 190);
}

.billingBulkUpdateModalBottomRow {
	height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border-top: 2px solid #edeff38c;
	align-items: center;
	padding: 0px 8px;
}

.billingBulkUpdateModalReset {
	font-family: 'RionaSans-Medium';
	color: #7b26c9;
	font-size: 13px;
	cursor: pointer;
	width: 50px;
}

.billingBulkUpdateModalReset:hover {
	text-decoration: underline;
}

.billingBulkUpdateModalResetSave {
	color: white;
	background-color: #7b26c9;
	border-radius: 4px;
	width: 160px;
	height: 32px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	padding-top: 7px;
	cursor: pointer;
}

.billingBulkUpdateModalResetSave:hover {
	background-color: #9c5dd7;
}

.billingBulkUpdateModalResetSave:active {
	background-color: #621ea0;
}

.billingBulkUpdateModalResetSaveDisabled,
.billingBulkUpdateModalResetSaveDisabled:hover,
.billingBulkUpdateModalResetSaveDisabled:active {
	background-color: #7b26c9;
	opacity: 0.35;
	cursor: no-drop;
}

.billingBulkUpdateTriggerButton {
	background-color: #621ea0 !important;
}
