.app-slot {
	background-color: #d1f0fc;
	height: 100%;
	overflow: hidden;
}
.app-slot .left-line {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 4px;
	background-color: #3daef2;
}
.app-slot .title {
	font-size: 11px;
	line-height: 14px;
	padding: 0 0 0 10px;
}
