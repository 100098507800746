.gm-modal-window {
	// width: 369px;
	.el-dialog__header {
		display: flex;
	}
	.el-dialog__title {
		display: flex;
		flex-grow: 1;
		align-items: center;
		gap: 8px;
	}
	.icon {
		svg {
			width: 16px;
			height: 16px;
			.GMIWarningCircleIcon {
				fill: red;
			}
		}
	}
	.title {
		display: flex;
		font-family: RionaSans-Bold;
		font-size: 16px;
	}

	hr {
		margin-top: 16px;
	}
}
