.dashboard-encounter-container {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 0 0 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.dashboard-encounter-container table td {
	padding: 0 16px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: left;
	color: #262837;
}

.dashboard-encounter-container table td .statusIcon {
	width: 18px;
	height: 18px;
}

.dashboard-encounter-container table td p {
	padding: 0;
	margin: 0;
}

.dashboard-encounter-container table td .sub {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.dashboard-encounter-container table td .appointmentStatus {
	font-weight: bold;
	vertical-align: middle;
	margin-left: 8px;
}
.dashboard-encounter-container table td .appointmentWarning {
	color: #f1c40f;
}
.dashboard-encounter-container table td .appointmentAlert {
	color: #e74c3c;
}

.dashboard-encounter-container table td .capitalize {
	text-transform: capitalize;
}

.external-list-loading {
	top: 30vh;
}
