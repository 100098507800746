.view-template-section iframe#google_docs_iframe {
	height: calc(100vh - 0px);
	border: none;
	/* box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.19); */
	background-color: #ffffff;
	margin: 0 auto;
	display: table;
}

.view-template-section {
	padding: 15px 0 0;
	background-color: #ffffff;
}

.printBtnB-templates-ref-programs {
	width: calc(100% - 92px);
	height: 62px;
	background-color: white;
	position: absolute;
	z-index: 1;
}

.view-template-head {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.referral-program-navigation {
	font: normal 12px RionaSans;
	color: #40b9ec;
	display: flex;
	align-items: center;
	height: 20px;
	padding: 0 18px;
	cursor: pointer;
	width: 210px;
}

.view-template-section .template-detail-toolbar {
	display: flex;
	align-items: center;
	padding: 0 24px;
}

.view-template-section .template-detail-toolbar .icon-divider {
	margin-right: 6px;
	margin-left: 6px;
	width: 1px;
	height: 21px;
	border-left: 1px solid #bac3ce;
}

.view-template-section .template-detail-toolbar .template-detail-toolbar-right {
	margin-left: auto;
}

.view-template-section button:disabled {
	cursor: default;
}

.view-template-section .template-detail-toolbar-left {
	flex-basis: 57%;
}

.view-template-section
	.template-detail-toolbar
	.template-detail-toolbar-left
	.template-detail-template-name {
	font-family: RionaSans-Bold;
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	display: flex;
}

.view-template-section .txt-tmplt-name {
	margin-right: 8px;
	flex-basis: 90%;
}

.view-template-section .btn-save-tmplt-name {
	font: normal 13px RionaSans;
	color: #40b9ec;
}

.template-detail-toolbar-right {
	display: flex;
	align-items: center;
}

.template-detail-toolbar-right .icon-gap {
	margin: 0 4px;
}

.template-detail-toolbar-right .icon-gap button {
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
}

.template-detail-toolbar-right .icon-gap :disabled {
	cursor: not-allowed;
}

.template-action-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.template-action-row-finalize-icon {
	margin-right: 11px;
}

.template-action-row-finalize-icon > div > svg > g > path:nth-child(2) {
	fill: white;
}

.template-detail-template-name > div.template-edit-icon > div > svg > g > g {
	fill: #7b26c9;
}

.template-edit-icon {
	transform: scale(1.1);
	margin-left: 5px;
	position: relative;
	bottom: 1px;
}
