.workflow-form .link-note {
	min-width: 386px;
	min-height: 14px;
	font-family: RionaSans;
	font-size: 10px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525969;
}

.workflow-form .el-form-item__label {
	width: 392px;
	height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525968;
	padding: 0;
	text-align: left;
}

.workflow-form {
	width: 392px;
}

.workflow-form .cancel-button {
	width: 70px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b8291;
	float: left;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.workflow-form .cancel-button:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.workflow-form .cancel-button:active {
	background-color: rgba(123, 130, 145, 0.3);
}

.workflow-form .save-button {
	width: 54px;
	height: 36px;
	border-radius: 6px;
	background-color: #40b9ec;
	float: right;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.workflow-form .save-button:hover {
	background-color: #70cbf1;
}

.workflow-form .save-button:active {
	background-color: #0f80b0;
}

.workflow-form .cancel-button .text {
	width: 42px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7b8291;
}

.workflow-form .save-button .text {
	width: 30px;
	height: 18px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	margin-top: 9px;
	margin-bottom: 9px;
}

.workflow-form .action-buttons {
	margin-top: 24px;
}

.workflow-form .el-select {
	width: 100%;
}

.workflow-form .is-disabled .el-icon-caret-top {
	cursor: not-allowed;
}

.loader-workflow-document > div:first-child {
	left: -8px !important;
	height: 324px;
}

.default-providers-table .custom-table,
.default-providers-table .custom-table table {
	min-width: 0px !important;
	width: 100%;
}
