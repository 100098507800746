.stopwatchContainer {
	height: 32px;
	width: 32px;
	border-radius: 4px;
	padding: 3px 0px 0px 3px;
	border: 1px solid #d8dde4;
	background-color: #edeff3;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	color: #333845;
	margin-right: 17px;
}

.stopwatchContainerExtended {
	width: 176px;
	padding: 5px 0px 1px 12px;
}

.stopwatchClockDisplayContainer {
	margin-right: 16px;
	min-width: 61px;
	text-align: left;
}

.stopwatchClockDisplayNumber {
	min-width: 21px;
	text-align: left;
}

.stopwatchIconButton {
	margin-right: 8px;
	padding-top: 2px;
	transform: scale(0.85);
	cursor: pointer;
}

.stopwatchIconPlay {
	margin-right: 12px;
	min-width: 18px;
}

.stopwatchIconPause {
	transform: scale(1);
	margin-right: 16px;
	position: relative;
	bottom: 1px;
	left: 2px;
	min-width: 14px;
}
