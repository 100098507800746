.custom-table,
.custom-table > table {
	width: fit-content;
	min-width: 1204px;
	max-width: 1364px;
}

.custom-table > table > thead > tr {
	border: unset !important;
}

.custom-table tr.on-click {
	cursor: pointer;
}

.custom-table tr.on-click:not(.is-expanded):hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.custom-table tr.on-click:not(.is-expanded):active {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.custom-table > table > thead > tr > th {
	height: 48px;
	padding: 0 16px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: left;
	color: #7b8291;
	position: relative;
}

.custom-table > table > thead > tr > th:not(.focused):not(.no-hover) {
	cursor: pointer;
}

.custom-table > table > thead > tr > th:hover:not(.focused):not(.no-hover),
.custom-table > table > thead > tr > th.focused {
	background-color: #d8dde4;
	color: #262837;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.custom-table
	> table
	> thead
	> tr
	> th:hover:not(.focused):not(.no-hover)
	svg
	path:first-of-type,
.custom-table > table > thead > tr > th.focused svg path:first-of-type,
.custom-table
	> table
	> thead
	> tr
	> th
	.sortable.is-sorted
	svg
	path:first-of-type {
	fill: #262837;
}

.custom-table
	> table
	> thead
	> tr
	> th:hover:not(.focused):not(.no-hover)
	.invisible {
	visibility: unset;
}

.custom-table > table > thead > tr > th .sortable {
	display: flex;
}

.custom-table > table > thead > tr > th .sortable > span {
	margin-right: 4px;
}

.custom-table > table > thead > tr > th .sortable > div {
	width: 16px;
	height: 16px;
	margin-top: 2px;
	position: relative;
}

.custom-table > table > thead > tr > th .sortable > div.single.asc,
.custom-table > table > thead > tr > th .sortable > div > div.multiple.desc {
	transform: rotate(180deg);
	width: 16px;
	height: 16px;
}

.custom-table > table > thead > tr > th .sortable > div > div.invisible {
	visibility: hidden;
}

.custom-table > table > thead > tr > th.focused .sortable > div > div.multiple {
	display: block !important;
	transform: rotate(180deg);
	width: 16px;
	height: 16px;
	visibility: visible;
}

.custom-table > table > thead > tr > th .sortable.is-sorted span {
	color: #262837;
}

.custom-table > table > thead > tr > th .sortables {
	width: 160px;
	padding: 16px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
	background-color: white;
	border-radius: 4px;
	position: absolute;
	top: 30px;
	left: -19px;
	z-index: 1;
}

.custom-table > table > thead > tr > th .sortables::before {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0px 8px 8px;
	border-color: #ffffff transparent;
	top: -8px;
	left: 20px;
}

.custom-table
	> table
	> thead
	> tr
	> th
	.sortables
	.option:hover
	.el-radio__label {
	color: #7b26c9;
}

.custom-table
	> table
	> thead
	> tr
	> th
	.sortables
	.option:hover
	.el-radio__inner {
	border-color: #7b26c9;
}

.custom-table > table > thead > tr > th .sortables > div {
	margin-bottom: 20px;
}

.custom-table > table > thead > tr > th .sortables > div > span {
	display: block;
	font-family: RionaSans-Bold;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.25;
	letter-spacing: 0.29px;
	color: #262837;
	text-transform: uppercase;
	margin-bottom: 8px;
	min-height: 17px;
}

.custom-table > table > thead > tr > th .sortables > div .el-radio {
	display: block;
	margin-left: unset;
	margin-bottom: 8px;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	text-align: left;
	color: #262837;
}

.custom-table
	> table
	> thead
	> tr
	> th
	.sortables
	> div
	.el-radio
	> .el-radio__label {
	padding-left: 8px;
}

.custom-table
	> table
	> thead
	> tr
	> th
	.sortables
	> div
	.el-radio
	.is-checked
	.el-radio__inner,
.custom-table
	> table
	> thead
	> tr
	> th
	.sortables
	> div
	.el-radio
	.el-radio__inner:hover {
	border-color: #7b26c9;
	background: unset;
}

.custom-table
	> table
	> thead
	> tr
	> th
	.sortables
	> div
	.el-radio
	.is-checked
	.el-radio__inner::after {
	background-color: #7b26c9;
	width: 8px;
	height: 8px;
}

.custom-table
	> table
	> thead
	> tr
	> th
	.sortables
	> div
	.el-radio
	.is-checked
	+ .el-radio__label {
	color: #7b26c9;
}

.custom-table > table > tbody {
	background-color: white;
	box-shadow: 0 0 0 1px #d8dde4;
	border-radius: 4px;
}

.custom-table > table > tbody > tr {
	height: 64px;
	border-bottom: unset !important;
	border-top: 1px solid #d8dde4;
}

.custom-table > table > tbody > tr.highlight {
	background-color: #f8f4fc;
}

.custom-table > table > tbody > tr > td {
	padding: 0 16px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.43;
	text-align: left;
	color: #262837;
}

.custom-table > table > tbody > tr > td.no-results {
	text-align: center;
	padding: 64px 0;
}

.custom-table > table > tbody > tr > td.no-results div {
	font-family: RionaSans-Medium;
	font-size: 24px;
	font-weight: 500;
	line-height: 1.42;
	color: #262837;
	margin-bottom: 4px;
}

.custom-table > table > tbody > tr > td.no-results p {
	font-family: RionaSans;
	font-size: 14px;
	line-height: 1.43;
	color: #7b8291;
	margin-bottom: 16px;
}

.custom-table > table > tbody > tr > td.no-results span {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	color: #7b26c9;
	cursor: pointer;
}

.custom-table > table > tbody > tr > td.no-results span:not(.separator):hover {
	text-decoration: underline;
}

.custom-table > table > tbody > tr > td:not(.no-results) > div {
	width: auto;
	min-width: inherit;
	max-width: inherit;
	white-space: unset;
	max-height: 40px;
}

.custom-table > table > tbody > tr > td:not(.no-results) > div.multi-line {
	overflow: hidden;
	white-space: unset;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	/* autoprefixer: off */
	/*
    NOTE: Above comment is intentional and serves purpose to tell bundler to ignore prefixing
          all the rules from this block.
          DO NOTE REMOVE IT!
    REF : https://github.com/postcss/autoprefixer#readme,
          https://stackoverflow.com/questions/33331570/use-inline-style-in-react-with-plain-css-string
  */
}

.custom-table > table > tbody > tr > td.overflow > div {
	overflow: hidden;
	text-overflow: ellipsis;
}

.custom-table > table > tbody > tr > td.normal > div {
	white-space: unset;
}

.custom-table > table > tbody tr:first-child td:first-child {
	border-top-left-radius: 4px;
}

.custom-table > table > tbody tr:first-child td:last-child {
	border-top-right-radius: 4px;
}

.custom-table > table > tbody tr:last-child td:first-child {
	border-bottom-left-radius: 4px;
}

.custom-table > table > tbody tr:last-child td:last-child {
	border-bottom-right-radius: 4px;
}

.custom-table > table > tbody > tr:first-of-type {
	border-top: unset;
}

.custom-table > table > tfoot tr {
	border: unset !important;
}

.custom-table > table > tfoot .tfoot-wrapper {
	display: flex;
	justify-content: space-between;
	height: 48px;
	align-items: center;
}

.custom-table > table > tfoot .tfoot-wrapper > div {
	flex-basis: 0;
	flex-grow: 1;
	display: flex;
}

.custom-table > table > tfoot .tfoot-wrapper .back-to-top {
	max-width: fit-content;
	justify-content: center;
}

.custom-table > table > tfoot .tfoot-wrapper .back-to-top,
.custom-table > table > tfoot .tfoot-wrapper .results-per-page,
.custom-table > table > tfoot .tfoot-wrapper .results-per-page span {
	display: flex;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.custom-table > table > tfoot .tfoot-wrapper .back-to-top > span,
.custom-table > table > tfoot .tfoot-wrapper .results-per-page > span {
	margin-right: 4px;
}

.custom-table > table > tfoot .tfoot-wrapper .back-to-top,
.custom-table > table > tfoot .tfoot-wrapper .results-per-page .el-dropdown {
	cursor: pointer;
}

.custom-table > table > tfoot .tfoot-wrapper .back-to-top:hover,
.custom-table
	> table
	> tfoot
	.tfoot-wrapper
	.results-per-page
	.el-dropdown:hover {
	text-decoration: underline;
}

.custom-table > table > tfoot .tfoot-wrapper .material-icons {
	font-size: 18px !important;
}

.custom-table > table .toggle-button {
	width: 48px;
	padding-right: 12px;
}

.custom-table > table .toggle-button.has-child div {
	width: 32px;
	height: 32px;
	cursor: pointer;
	border-radius: 4px;
}

.custom-table > table .toggle-button.has-child div:hover {
	background-color: #d8dde4;
}

.custom-table > table .toggle-button.has-child div:active {
	background-color: #bac3ce;
}

.custom-table > table .toggle-button.is-expanded svg {
	transform: rotate(180deg);
}

/* Child table CSS */
.custom-table tbody > tr.child {
	border-top: unset;
}

.custom-table tbody > tr.child > td {
	padding: 8px 16px;
}

.custom-table tbody > tr.child > td > div.child-wrapper {
	max-height: unset;
}

.custom-table tbody > tr.child > td > div.child-wrapper > .custom-table,
.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table {
	min-width: unset;
	width: 100%;
	border-collapse: separate;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody {
	box-shadow: unset;
	border-radius: unset;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody
	> tr:not(.separator) {
	border-top: unset;
	height: 52px;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody
	> tr:not(.separator)
	> td {
	border-top: 1px solid #d8dde4;
	border-bottom: 1px solid #d8dde4;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody
	> tr:not(.separator)
	> td:first-child {
	border-left: 1px solid #d8dde4;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody
	> tr:not(.separator)
	> td:last-child {
	border-right: 1px solid #d8dde4;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody
	> tr.separator {
	height: 8px;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody
	> tr
	> td:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.custom-table
	tbody
	> tr.child
	> td
	> div.child-wrapper
	> .custom-table
	> table
	> tbody
	> tr
	> td:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
