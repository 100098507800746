.provider-form .provider-form-inner {
	max-height: calc(100vh - 240px);
	overflow-y: overlay;
	padding: 32px 16px 0px 16px;
}
.provider-form .section-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.provider-form .section-container .section-label {
	font: bold 14px/1.43 RionaSans-Bold;
	flex-grow: 0;
	line-height: 20px;
	text-align: left;
	color: #262837;
}
.provider-form .section-container .section-divider {
	flex-grow: 1;
	height: 1px;
	background-color: #edeff3;
	margin-left: 8px;
	margin-right: 4px;
}
.provider-form .el-row:not(:last-child) {
	margin-bottom: 19px;
}
.provider-form .el-row .el-form-item {
	margin: 0;
}
.provider-form .el-row .el-form-item .el-form-item__label {
	font: normal 12px/1.42 RionaSans;
	width: 336px;
	text-align: left;
	color: #262837;
	padding: 0;
	margin: 0;
}
.provider-form .el-row .el-form-item.is-required .el-form-item__label::before,
.provider-form .el-row .el-form-item.recommended .el-form-item__label::before {
	content: '*';
	font: bold 12px/1.42 RionaSans-Bold;
	text-align: left;
	color: #e74c3c;
	position: relative;
	left: 3px;
}
.provider-form .el-row .el-form-item.recommended .el-form-item__label::before {
	color: #7b8291;
	position: relative;
	left: 0px;
}
.provider-form .el-row .el-col:not(:first-child) .el-form-item {
	margin-left: 8px;
}
.provider-form .el-row .el-form-item .el-input__inner,
.provider-form .el-row .el-form-item .el-select {
	width: 100%;
	height: 32px;
}
.provider-form .el-row .el-col-12 .el-form-item .el-input__inner,
.provider-form .el-row .el-col-12 .el-form-item .el-select {
	width: 160px;
}
.provider-form .el-input__inner::placeholder,
.provider-form .el-textarea__inner::placeholder {
	font: normal 13px/1.38 RionaSans;
	color: #bac3ce !important;
	opacity: 1 !important;
}
.provider-form .el-row .additional-fields {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
	display: flex;
	cursor: pointer;
}
.provider-form .el-row .additional-fields .down-arrow {
	margin-left: 4px;
}
.provider-form .el-row .additional-fields .down-arrow.rotate > div > svg {
	transform: rotate(180deg);
}

.provider-form .el-row .el-form-item__error {
	position: inherit !important;
}
.provider-form .el-row .el-form-item__content {
	font-size: 12px;
	line-height: 0;
}
.provider-form .externalProviderModalBody {
	padding: 0;
	overflow: inherit;
	max-height: 1200px;
}
.provider-form .el-row .add-edit-ext-proivder {
	min-height: 600px;
}
.provider-form .el-row.or-add-new.disabled,
.provider-form .el-row .el-col.disabled {
	cursor: not-allowed;
	opacity: 0.4;
}
.provider-form .el-row .add-edit-ext-proivder.disabled {
	pointer-events: none;
}
.provider-form .el-row .add-edit-ext-proivder .fullModalBackground,
.provider-form .el-row .add-edit-ext-proivder .fullModalBackgroundFlex {
	background: none;
	position: unset;
	left: unset;
	right: unset;
	width: unset;
	height: unset;
}
.provider-form .el-row .add-edit-ext-proivder .externalProviderModalContainer {
	position: unset;
	width: unset;
	box-shadow: unset;
	min-height: auto;
	overflow: inherit !important;
	margin: unset;
	top: unset;
	bottom: unset;
	left: unset;
	right: unset;
	transform: unset;
}
.provider-form
	.el-row
	.add-edit-ext-proivder
	.externalProviderModalContainer
	.externalProviderModalLoading {
	display: none;
}
.provider-form
	.el-row
	.add-edit-ext-proivder
	.externalProviderModalContainer
	.externalProviderModalTopRow {
	display: none;
}
.provider-form
	.el-row
	.add-edit-ext-proivder
	.externalProviderModalContainer
	.externalProviderModalButtonRow {
	display: none;
}
.provider-form .el-row .ext-provider-filter ul li.el-select-dropdown__item {
	max-width: 335px;
	padding: 8px;
	height: auto;
}
.provider-form .el-row .ext-provider-filter p.el-select-dropdown__empty {
	height: 30px !important;
}
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item.selected,
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item.selected.hover,
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item:hover,
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item:active,
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item:focus {
	background-color: #edeff3;
}
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-item
	.pro-info {
	display: flex;
	flex-direction: column;
}
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-item
	.pro-info
	.pro-name {
	font: bold 14px/1.38 RionaSans-Bold;
	color: #262837;
}
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-item
	.pro-info
	.pro-location {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #262837;
}
.provider-form
	.el-row
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-specialty {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b8291;
}
.provider-form
	.el-row
	.ext-provider-filter
	> div.el-input
	> input.el-input__inner {
	font: normal 13px/1.38 RionaSans !important;
	color: #262837 !important;
}
