@use '../../../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.coupon-code-section {
		margin-bottom: system.$singleUnit;
	}

	.coupon-code-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.error-message {
		font: normal 10px RionaSans;
		color: #ff0000;
		padding-top: 2px;
	}

	.btn-apply-coupon-code {
		width: auto !important;
		height: 32px;
		padding: 6.5px 24px;
		margin: 18px 0 0 16px;
		background: none;
		color: #5B43BD;
		cursor: pointer;
	}

	.btn-apply-coupon-code:disabled {
		cursor: not-allowed;
	}

	.btn-remove-coupon-code {
		width: auto !important;
		font-size: 14px;
		line-height: 16.8px;
		border: none;
		color: #5B43BD;
		background: none;
		cursor: pointer;
		padding: 0;
		padding-left: 15px;
		display: flex;
		align-items: center;
	}
}