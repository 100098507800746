.react-tags {
	position: relative;

	font-size: 1em;
	line-height: 1.2;

	height: 2vw;
	cursor: text;

	background: white;
	border-radius: 50px;
	padding-left: 0vw;
}

.ReactTags__tagInput {
	float: right;
	margin-left: 0.5vw;
}

.react-tagsinput {
	margin-top: 10vw;
	margin-left: 10vw;
}

.react-tags.is-focused {
	border-color: #b1b1b1;
	display: flex;
}

.react-tags__selected {
	display: inline;
	pointer-events: none;
}

.react-tags__selected > button,
.ReactTags__selected > button {
	cursor: auto;
	pointer-events: none;
}

.react-tags__selected-tag,
span.tag-wrapper.ReactTags__tag {
	display: inline-block;
	padding: 9px 8px;
	border: 0px solid #d1d1d1;
	border-radius: 2px;
	background: white;
	font-size: inherit;
	line-height: inherit;
}

span.tag-wrapper.ReactTags__tag > a {
	display: none;
}

.react-tags__selected-tag:after,
span.tag-wrapper.ReactTags__tag:after {
	color: #aaa;
	margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
	border-color: #b1b1b1;
}

.react-tags__search {
	display: inline-block;
	padding: 0.4vw;
	margin-bottom: 6px;
}

.span.tag-wrapper.ReactTags__tag {
	padding-top: 0.8vw !important;
	cursor: default;
}

@media screen and (min-width: 30em) {
	.react-tags__search {
		position: relative;
	}
}

.react-tags__search input,
.ReactTags__tagInput input {
	width: 50vw !important;
	height: 2.3vw;

	margin: 0;
	padding: 0.5vw;
	border: 0;
	outline: none;

	font-size: 0.9vw !important;
	line-height: inherit;
	color: rgb(82 97 115);
}

.react-tags__search input.animate {
	animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
	0% {
		margin-left: 0rem;
	}
	25% {
		margin-left: 0.5rem;
	}
	75% {
		margin-left: -0.5rem;
	}
	100% {
		margin-left: 0rem;
	}
}

.search_tags_wrapper {
	display: flex;
	border-radius: 50px;
	background: white;
	border: 1px solid #d8dde4;
	width: 84%;
	height: 3vw;
}

button.el-button.el-button--primary.advanced_search_button {
	border-radius: 50px;
	height: 36px;
	width: 120px;
	color: white;
	background-color: #40b9ec;
	border-color: #40b9ec;
	font-size: 15px;
}

button.el-button.el-button--primary.advanced_search_button:hover {
	background-color: #70cbf1;
	border-color: #70cbf1;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
	background-color: #ffffff !important;
	opacity: 0.35;
}

.advanced_search_button.active {
	background-color: rgb(50, 152, 218);
}

.advanced_search_button.export_report {
	color: rgb(163, 179, 205);
	background-color: #ffffff;
	border-color: rgb(163, 179, 205);
}

.category_suggestions {
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	font-size: 0.9vw;
	margin-top: 0.5vw;
	color: rgb(82, 97, 115);
	grid-row-gap: 0.5vw;
}

.suggestions_label {
	font-size: 0.8vw;
	color: rgba(82, 97, 115, 0.65);
	height: 1vw;
	margin-right: 12px;
}

i.material-icons.search_enhaced {
	height: 3vw;
	width: 3vw;
	padding: 0.9vw;
	font-size: 1.4vw;
	color: rgb(50, 152, 219);
	transform: rotate(90deg);
}
i.material-icons.search_error {
	color: red;
	height: 3vw;
	font-size: 1.4vw;
	margin-right: 0.5vw;
}
.react-tags__search input::placeholder,
.ReactTags__tagInput input::placeholder {
	color: rgba(82, 97, 115, 0.4);
	font-size: 0.9vw !important;
}

.span.tag-wrapper.ReactTags__tag {
	padding-top: 0.8vw !important;
	cursor: default;
}
input.ReactTags__tagInputField {
	padding-top: 1vw !important;
}

.react-tags__suggestions,
.ReactTags__suggestions {
	color: rgb(82 97 115);
	font-size: 0.9vw;
	z-index: 1;
	background: white;
	margin-top: 0vw;
	width: 17vw;
	position: relative !important;
}
.ReactTags__suggestions {
	position: relative;
}

.react-tags__suggestions ul,
.ReactTags__suggestions ul {
	border: 1px solid rgb(207, 215, 228);
	box-shadow: none !important;
}

.react-tags__suggestions li mark,
.ReactTags__suggestions li mark {
	text-decoration: none !important;
	background: none;
	font-weight: 600;
}
.mark,
mark {
	padding: 0vw !important;
}
.react-tags__search input::-ms-clear {
	display: none;
}

.react-tags__suggestions li,
.ReactTags__suggestions li {
	border-bottom: none !important;
}

.react-tags__selected-tag,
span.tag-wrapper.ReactTags__tag {
	font-size: 0.9vw;
	line-height: inherit;
	margin-top: 1vw !important;
	padding: 0vw !important;
	color: rgb(82, 97, 115);
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	cursor: default;
	margin-left: 0.4vw;
}

.react-tags__suggestions {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
}

@media screen and (min-width: 30em) {
	.react-tags__suggestions {
		width: 240px;
	}
}

.react-tags__suggestions ul,
.ReactTags__suggestions ul {
	margin: 4px -1px;
	padding: 0;
	list-style: none;
	background: white;
	border: 1px solid #d1d1d1;
	border-radius: 2px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
	max-height: 20vw;
	overflow-y: scroll;
}

.react-tags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 6px 8px;
}

.react-tags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.react-tags__suggestions li:hover,
li.ReactTags__activeSuggestion:hover,
.ReactTags__suggestions li:hover {
	cursor: pointer;
	background: #eee;
}

.react-tags__suggestions li.is-active,
.ReactTags__suggestions li.is-active {
	background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
	opacity: 0.2;
	cursor: auto;
}

.el-dialog__body {
	padding-top: 0.2vw;
}

.advanced-search-row:hover {
	background-color: #ecf8fd !important;
}
.advanced-search-row:focus,
.advanced-search-row:active {
	background-color: #d9f1fb !important;
}

.advanced-search-container {
	min-height: calc(100vh - 84px);
	background-color: #edeff3;
	margin-top: 46px;
	margin-left: 60px;
	width: calc(100% - 60px);
	padding-bottom: 20px;
}
.advanced-search-container .advanced-search-container-wrapper {
	width: 100%;
	margin: auto;
	padding-left: 3vw;
}
