.radio-group-pill-wrapper {
	-webkit-font-smoothing: antialiased;
}

.radio-group-pill-wrapper .el-radio-group {
	margin: 0;
}
.radio-group-pill-wrapper .el-radio-group .el-radio-button__inner {
	font: normal 500 13px/1.38 RionaSans-Medium;
	border: solid 1px #edeff3 !important;
	border-radius: 24px !important;
	color: #262837;
	min-width: 72px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.radio-group-pill-wrapper .el-radio-group .el-radio-button__inner:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
}
.radio-group-pill-wrapper .el-radio-group .el-radio-button__inner:active {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
	background-color: #e2d3f2 !important;
}
.radio-group-pill-wrapper .el-radio-group .el-radio-button {
	margin-bottom: 0px;
}
.radio-group-pill-wrapper .el-radio-group .el-radio-button:not(:first-child) {
	margin-left: 8px;
}
.radio-group-pill-wrapper
	.el-radio-button__orig-radio:checked
	+ .el-radio-button__inner {
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	background-color: #e2d3f2 !important;
	color: #491678 !important;
}

.radio-group-pill-wrapper .el-radio-group .el-radio-button.is-radio-disabled {
	pointer-events: none;
}
