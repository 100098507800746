.gm-dropdown-old {
	input::placeholder {
		font-size: 14px !important;
	}
	&.has-error {
		input {
			border-color: red;
			background-color: #fe5c5065;
		}
	}
}
