body {
	overflow-x: hidden;
}

.logout_menu {
	font-size: 13px !important;
	font-family: 'RionaSans', serif !important;
}

.PageHeader {
	width: 100%;
	background: #fff;
	height: 5vw;
	position: absolute;
	top: 0px;
	box-shadow: 0 1px 0 0 #d8dde4;
}

.top-nav {
	position: absolute;
	top: 0px;
	width: 100%;
	height: 60px;
	box-shadow: 0 1px 0 0 #d8dde4;
	background-color: #ffffff;
	padding: 14px 22px 14px 0;
	display: flex;
	justify-content: space-between;
}

/* .nav-avtar>button {
	padding: 0 !important;
	border-radius: 0% !important;
} */

.current-menu-item {
	height: 22px;
	opacity: 0.77;
	font-family: RionaSans;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #a4b0bd;
	float: left;
}

.current-menu-item:hover {
	color: #a4b0bd;
	opacity: 0.77;
	text-decoration: none;
}

.main-menu-item {
	height: 22px;
	font-family: RionaSans;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #2f3542;
	float: left;
}

.rectangle {
	width: 28px;
	height: 28px;
	opacity: 0.5;
	float: left;
	margin-top: -3px;
}

.rectangle-left,
.rectangle-right {
	width: 16px;
	height: 60px;
	opacity: 0.5;
	float: left;
}

.menu-breadcrumb {
	width: 28px;
	height: 28px;
	object-fit: contain;
}

.logo_container {
	float: left;
	display: flex;
	margin-left: calc(28px + 60px);
	-webkit-font-smoothing: antialiased;
	margin-top: 5px;
}

.search-box {
	float: right;
	text-align: right;
	display: flex;
	flex-direction: row;
}

.advanced-search,
.advanced-search:hover {
	height: 15px;
	font-family: RionaSans;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #40b9ec;
}

.advanced-search-bar {
	float: right;
	text-align: right;
	display: flex;
	padding: 18px 20px 0px 0px;
}

.nav-avtar {
	display: flex;
	min-width: 76px;
	align-self: center;
	flex-direction: column;
	padding-left: 4px;
	cursor: unset;
}

.nav-avtar > .el-dropdown > .popoverTarget {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.nav-avtar > .el-dropdown > .popoverTarget > .user-name {
	max-width: 160px !important;
	font-family: RionaSans-Medium;
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	letter-spacing: normal;
	color: #7b8291;
	text-align: left;
}

.nav-avtar > .el-dropdown > .popoverTarget > .user-name.truncate {
	max-width: 65px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.nav-avtar > .el-dropdown > .popoverTarget > .user-name > b {
	font-family: RionaSans-Bold;
	font-weight: 500;
}

.nav-avtar > .el-dropdown > ul.el-dropdown-menu {
	top: 16px !important;
	min-width: 243px;
	border-radius: 4px;
	cursor: unset;
}

.nav-avtar.small > .el-dropdown > ul.el-dropdown-menu {
	min-width: 150px;
}

.nav-avtar > .el-dropdown > ul.el-dropdown-menu > li {
	text-align: left;
	height: 35px;
	padding: 0 8px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	color: #262837;
	position: relative;
}

.nav-avtar > .el-dropdown > ul.el-dropdown-menu > li.roles {
	padding-left: 17px;
	font-family: RionaSans !important;
	font-size: 12px;
	color: #525968;
}

.nav-avtar > .el-dropdown > ul.el-dropdown-menu > li.roles.active {
	font-family: RionaSans-Bold !important;
}

.nav-avtar > .el-dropdown > ul.el-dropdown-menu > li.roles.active div {
	height: 21px;
	width: 21px;
	position: absolute;
	top: 3px;
	right: 3px;
}

.nav-avtar > .el-dropdown > ul.el-dropdown-menu > li.is-disabled {
	cursor: unset;
}

.nav-avtar > .el-dropdown > ul.el-dropdown-menu > li.is-disabled.divider {
	height: 17px;
	padding: 8px 10px;
}

.nav-avtar .user-caret {
	margin-top: -2px;
	width: 16px;
	height: 16px;
}

.nav-avtar button:hover,
.nav-avtar button:focus,
.nav-avtar button:active {
	background: #ffffff !important;
}

.patient_search_field_form > div {
	width: 328px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #d8dde4;
	background-color: #edeff3;
	margin-right: 16px;
}

.patient_search_field_form > div:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.patient_search_field_form.has_value > div {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
}
.patient_search_field_form.has_focus > div {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
	border: solid 1px #d8dde4;
}

.input-andorment-button i.material-icons.search {
	font-size: 15px;
}

.search-bar > input {
	font-size: 1vw !important;
}

.patient_search_field input::placeholder,
.patient_search_field input::-webkit-input-placeholder {
	font-size: 12px !important;
	color: #7b8291;
	font-weight: 500;
	font-family: RionaSans-Medium;
	-webkit-font-smoothing: antialiased;
	opacity: 1;
}

.patient_search_field > div {
	margin-right: 0 !important;
}

.patient_search_field input {
	padding: 4px 4px 4px 10px !important;
	height: 17px;
	font-family: RionaSans-Medium;
	-webkit-font-smoothing: antialiased;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	text-align: left;
	color: #262837;
}

.patient_search_field .el-tooltip__popper {
	width: 123px !important;
	height: calc(46px - 8px) !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px !important;
}

.patient_search_field_form .el-tooltip__popper .adv-search-text {
	height: 17px;
	font-family: RionaSans;
	-webkit-font-smoothing: antialiased;
	font-size: 12px;
	line-height: 1.42;
	text-align: left;
	color: #ffffff;
	white-space: nowrap !important;
}

.patient_search_field_form .svgIcon > div {
	display: flex;
}

.patient_search_field_form .el-tooltip:hover {
	background: #ffffff;
	border: solid 1px #d8dde4;
	border-radius: 0px 4px 4px 0px;
}

.patient_search_field_form .el-tooltip:focus,
.patient_search_field_form .el-tooltip:active {
	background: #edeff3;
	border: solid 1px #d8dde4;
	border-radius: 0px 4px 4px 0px;
}

.capitalize {
	text-transform: capitalize;
}

.input-andorment-button {
	background: none !important;
}

.search_bar {
	float: right;
	margin-right: 1vw;
	margin-top: 1.5vw;
}

.search_bar > div {
	height: 2vw;
	font-size: 1vw !important;
	padding-top: 0.4vw;
}

.search_bar > input {
	font-size: 1vw !important;
}

.patient_search_field {
	font-size: 1vw !important;
	margin-left: -5px !important;
}

.patient_search_field > div > button.input-andorment-button,
.patient_search_field > div .el-tooltip {
	padding: 0px !important;
	width: 32px;
	height: 32px;
	border-left: 1px solid #d8dde4;
	border-radius: 0px;
	cursor: pointer;
}
.patient_search_field > div .el-tooltip {
	justify-content: center;
	align-items: center;
	display: flex;
}
.patient_search_field > div .no-border {
	border: none !important;
}

.menu-item-icon {
	padding: 0px 10px 0px 10px;
	color: #d8dde4;
	font-size: 25px;
	margin-top: 0vw;
}

i.material-icons.logged_in_user {
	color: black;
	font-size: 1.7vw;
}

i.material-icons.search {
	font-size: 1.2vw;
}

i.material-icons.page_header_icon {
	padding-top: 0.3vw;
	margin-right: 0.5vw;
	font-size: 1.2vw;
}

button.MuiButtonBase-root-45.MuiIconButton-root-39.user {
	margin-right: 4vw;
	margin-top: 0.8vw;
}

.main-part {
	width: 100%;
	float: left;
}

.float-right {
	float: right;
}

.searchbox {
	float: right;
	text-align: right;
	display: flex;
}

.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > tfoot > tr > td,
.table-responsive > .table > tfoot > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > thead > tr > th {
	font-size: 0.9vw;
}

.backtext {
	font-size: 0.9vw;
	margin-top: 0.3vw;
}

.labeltx {
	float: left;
	margin-right: 0.69vw;
	color: #999;
	font-size: 0.9vw;
}

.encounter_subinfo {
	font-size: 0.9vw;
	padding-top: 0.6vw !important;
}

.encounter_values {
	color: #777;
	font-size: 0.9vw;
}

.labtext {
	font-size: 1.4vw;
}

.logomain {
	width: 7vw;
	height: auto;
}

input {
	font-size: 1vw;
}

.stage_icon {
	line-height: 0.9vw;
}

.patient_detail {
	margin-left: 8vw;
	/* height: 100vh; */
}

/* .fullheight
	{
		height: calc(100vh);
	} */

@media only screen and (min-width: 768px) and (max-width: 1100px) {
	.leftNavContainer {
		width: 9vw;
	}
	input {
		font-size: 1.5vw !important;
	}
	/* .PageHeader {
		
		height: 6vw;
	} */
	.logo_container {
		/* margin-left: 12vw; */
	}
	.search_bar {
		float: right;
		margin-right: 1vw;
		/* margin-top: 2.5vw; */
	}
	i.material-icons.search {
		font-size: 1.6vw;
	}
	i.material-icons.logged_in_user {
		font-size: 1.6vw;
	}
	.logomain {
		width: 11vw;
	}
	button.MuiButtonBase-root-45.MuiIconButton-root-39.user {
		margin-right: 4vw;
		margin-top: 1.8vw;
	}
	.patients_dashboard {
		/* margin-left: 11vw;
		margin-top: 8vw;
		margin-right: 0;
		margin-bottom: 2.08vw; */
		/*height: 100vh;*/
	}
	.patient_cards {
		width: 100%;
	}
	.bucket_cards {
		width: 36vw;
		height: 20vw;
	}
	.stage_title {
		font-size: 1.3vw;
	}
	.bucket_title_container {
		float: left;
		width: 100%;
	}
	.bucket_partition {
		margin-top: 0;
		float: left;
	}
	i.material-icons.stage {
		font-size: 1.3vw;
	}
	.stage_patients_count {
		font-size: 1.8vw;
		float: left;
		width: 100%;
	}
	.patients_list {
		height: 65vh;
	}
	.PatientsRecords .table-responsive {
		height: 56vh;
	}
	.btn-info {
		width: auto;
		font-size: 1.3vw;
	}
	.navigation_titles {
		color: white;
		padding: 1vw;
		font-size: 1.31vw;
		height: 8vw;
	}
	.patients_list_wrapper {
		/* margin-top: 3vh; */
		margin-left: 11vw;
	}
	.patients_title_count {
		float: left;
		padding-top: 0.5vw;
		font-size: 1.4vw;
	}
	/* .MuiButton-label-104 {
		font-size: 1.21vw;
	} */
	.table-responsive th {
		font-size: 1.1vw !important;
	}
	.table-responsive > .table > tbody > tr > td {
		font-size: 1.1vw;
	}
	.tab_labels {
		font-size: 1.2vw;
	}
	.profile_pic_wrapper {
		width: 4.5vw;
		height: 4.5vw;
	}
	i.material-icons.profile_picture_placeholder {
		font-size: 2.4vw;
		padding-top: 1.5vh;
	}
	.entity {
		font-size: 1.2vw;
	}
	.entityval {
		font-size: 1.2vw;
	}
	.add_comment > div,
	.add_comment > button {
		font-size: 1.2vw;
	}
	.no_data_found {
		font-size: 1.2vw;
	}
	.add_comment > div > label {
		font-size: 1.21vw;
	}
	.patient_records_pagination button {
		font-size: 1.21vw !important;
	}
	.backtext {
		font-size: 1.2vw;
	}
	.encounter_subinfo {
		font-size: 0.9vw;
		padding-top: 0.8vw;
	}
	.labeltx {
		font-size: 1.2vw;
	}
	.encounter_values {
		font-size: 1.2vw;
	}
	.patient_encounter_detail {
		margin-left: 10vw !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.encounter_subinfo {
		font-size: 1.2vw;
	}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.logomain {
		/* width: 22vw; */
		width: 13vw;
		height: auto;
	}
	/* 

	input {
		font-size: 2.3vw !important;
	} */
	button.MuiButtonBase-root-45.MuiIconButton-root-39.user {
		margin-right: 4vw;
		padding: 2.3vw;
		margin-top: 0;
	}
	/* .PageHeader
    {
		height: auto;
		padding: 1.67vw 0;
    } */
	.logo_container {
		/* margin-left: 0;
		text-align: center; */
		width: 100%;
	}
	.navigation_titles {
		padding: 0vw;
		height: 12vw;
	}
	.title {
		display: none;
	}
	.leftNavContainer {
		width: 14vw;
	}
	.patients_dashboard {
		margin-left: 16vw;
		margin-top: 10vw;
		margin-right: 0;
		margin-bottom: 6.25vw;
		padding-left: 1.04vw;
		padding-right: 1.04vw;
	}
	i.material-icons.search {
		font-size: 5vw;
	}
	i.material-icons.logged_in_user {
		color: black;
		font-size: 5vw;
	}
	.bucket_cards {
		width: 100%;
		margin: 0vw;
		height: auto;
		padding-bottom: 6.25vw;
	}
	.bucket_cards:hover {
		transform: none;
	}
	.patient_cards {
		grid-template-columns: auto;
		margin-top: 5vw;
		width: 100%;
	}
	.search_bar {
		float: left;
		margin-left: 19%;
		width: 75vw;
	}
	.bucket_partition {
		float: left;
		width: 100%;
	}
	i.material-icons.stage {
		font-size: 4.8vw;
		line-height: 1.4;
	}
	.stage_patients_count {
		padding-top: 6vw;
		font-size: 4vw;
		float: left;
		width: 100%;
	}
	.btn-info {
		width: auto;
		font-size: 2.4vw;
		height: auto;
	}
	.searchbox {
		float: left;
		text-align: center;
		display: flex;
		margin-left: 5%;
	}
	.patients_list {
		float: left;
		width: auto;
		height: 83vh;
		margin-bottom: 12.75vw;
	}
	.patients_title_count {
		font-size: 3vw;
		text-align: center;
		width: 85%;
	}
	.patient_pagination {
		width: 85%;
		text-align: center;
		float: left;
	}
	.patient_encounter_detail {
		margin-left: 16vw !important;
		margin-top: 10vw;
		/* height: auto; */
		margin-right: 1vw !important;
	}
	.EncounterList {
		height: auto;
		width: 100%;
		float: left;
		margin-top: 3vh;
	}
	.name_wrapper {
		padding: 2.8vw;
	}
	.patient_info {
		width: 100%;
		height: auto;
		padding-bottom: 3vw;
	}
	.add_comment > div,
	.add_comment > button {
		font-size: 2vh;
	}
	.patient_encounter_list {
		height: 64vw;
	}
	.patients_list .table-responsive {
		height: 80vh;
		overflow: hidden;
	}
}
