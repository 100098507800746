.encounter-body {
	width: 100%;
	font-family: RionaSans;
}

.encounter-body .encounter-sections {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 32px;
}

.encounter-body .encounter-sections .encounter-section {
	width: 100%;
	margin-top: 8px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-header {
	width: 100%;
	height: 44px;
	background-color: #edeff3;
	padding: 0 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
	border: solid 1px #edeff3;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-header-expanded {
	background-color: #edeff3;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-header
	span {
	font-family: RionaSans-Bold;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.4;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 24px;
	padding-bottom: 32px;
	border: solid 1px #edeff3;
	border-top: 0;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body.editable
	.segment-row {
	margin-bottom: 19px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-research-row {
	margin-bottom: 24px;
}

.header-container .second-row-wrapper .enctr-typr-cnslt-type {
	display: flex;
	align-items: center;
}

.header-container .second-row-wrapper .enctr-typr-cnslt-type .multivisit-type {
	font-family: RionaSans-RegularItalic;
	margin: 0 3px;
}

.header-container
	.second-row-wrapper
	.enctr-typr-cnslt-type
	.patient-enctr-details-visiticon {
	margin: 0 4px 0 0;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-header
	.accordian-title {
	display: inline-block;
	margin-right: 8px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-header
	.accordian-icon {
	position: absolute;
	right: 14px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-header
	.accordian-icon
	img {
	transform: rotate(-90deg);
	transition: 0.4s ease all;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-research-row
	.segment-5cols {
	margin-left: 0 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-research-row
	.segment-2cols {
	margin-left: 0 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-research-row
	.segment-5cols
	.el-checkbox__input {
	margin-right: 8px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-research-row
	.segment-5cols
	.el-checkbox__input
	.el-checkbox__inner::after {
	height: 7px !important;
	width: 2px !important;
	top: 1px !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-research-row
	.segment-research-fix-width {
	width: 315px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row-title.segment-billing-row {
	padding-top: 0;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-billing-accordiant-body {
	padding-bottom: 24px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row:last-child {
	margin-bottom: 0px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row:last-child {
	margin-top: 0px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col {
	width: calc((100% - 64px) / 5);
	margin-left: 16px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-2cols {
	width: calc((100% - 16px) * 2 / 5);
	margin-left: 16px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols {
	width: calc((100% - 64px) * 3 / 5);
	margin-left: 16px;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-field-value {
	width: 100%;
	margin-bottom: 8px;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-field-value
	.alerticondiv {
	width: 100%;
	position: relative;
	display: flex;
	font-family: RionaSans;
	line-height: 1.42;
	font-size: 12px;
	color: #262837;
	padding: 0 0 0 20px;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-field-value
	.alerticondiv
	> div {
	width: 19px;
	position: absolute;
	top: -5px;
	left: -4px;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-field-value
	.alerticondiv
	> div
	svg {
	width: 100%;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-5cols {
	width: calc((100%-64px) * 5 / 5);
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-5cols.with-test-recommendation-button {
	display: flex;
	align-items: center;
	width: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-accordian-body
	.segment-5cols.with-test-recommendation-button
	.divider {
	height: 38px;
	border: 1px solid #edeff3;
	margin: 0 16px;
}

.encounter-body
	.encounter-sections
	.encounter-accordian-body
	.segment-5cols.with-test-recommendation-button
	.hold-result-release-wrapper {
	display: flex;
	flex-direction: column;
}

.encounter-body
	.encounter-sections
	.encounter-accordian-body
	.segment-5cols.with-test-recommendation-button
	.hold-result-release-wrapper
	.chk-hold-result-release {
	font: normal 13px/1.38 RionaSans;
	opacity: 0.5;
	pointer-events: none;
}

.encounter-body
	.encounter-sections
	.encounter-accordian-body
	.segment-5cols.with-test-recommendation-button
	.hold-result-release-wrapper
	.chk-hold-result-release
	.el-checkbox__label {
	font-size: 13px;
}

.encounter-body
	.encounter-sections
	.encounter-accordian-body
	.segment-5cols.with-test-recommendation-button
	.hold-result-release-wrapper
	.btn-result-release-change-flag {
	padding: 0;
	font: normal 500 13px/1.38 RionaSans-Medium;
	width: 74px;
	color: #7b26c9;
}

.encounter-body
	.encounter-sections
	.encounter-accordian-body
	.segment-5cols.with-test-recommendation-button
	.hold-result-release-wrapper
	.btn-result-release-change-flag:hover {
	text-decoration: underline;
}

.encounter-body
	.encounter-sections
	.encounter-accordian-body
	.segment-5cols.with-test-recommendation-button
	.chk-hold-result-release
	.el-checkbox__label {
	margin-left: 8px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col:first-child {
	margin-left: 0px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row-title {
	padding-top: 16px;
	padding-bottom: 12px;
	margin: 0;
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.encounter-field-billing-value {
	font-family: RionaSans;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row-title
	.segment-col {
	width: 100%;
	margin-left: 16px;
	text-transform: uppercase;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-select
	.el-select-dropdown
	.el-scrollbar__view
	.el-select-dropdown__item
	span {
	max-width: 345px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;

	white-space: pre-wrap;
	height: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-select
	.el-select-dropdown
	.el-scrollbar__view
	.el-select-dropdown__item {
	height: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-field-header,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-2cols
	.encounter-field-header,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-field-header,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-5cols
	.encounter-field-header,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.via_seprator {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-5cols
	.edit-recommend-test-header {
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-5cols
	.encounter-patient-value {
	font-size: 14px;
	color: #7b8291;
	font-weight: 500;
	font-family: RionaSans-Medium;
	margin-right: 5px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.el-checkbox__label {
	padding-left: 5px !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-field-header
	> b {
	font-family: RionaSans-Medium;
	font-weight: bold;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-field-value,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-5cols
	.encounter-field-value {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	word-break: break-all;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-field-value
	.encounter-input-field
	.el-input-number {
	width: 100%;
}

.encounter-input-error-field > div.rdt > input::placeholder {
	font-size: 13px !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.el-checkbox__inner::after {
	border: 1.5px solid #3caaeb !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 6px !important;
	width: 3px !important;
	left: 4px !important;
	top: 1px !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section {
	width: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-field {
	width: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.is_escalated_section
	.el-radio
	.el-radio__input.is-checked
	.el-radio__inner {
	border: 1px solid #bd92e4;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.is_escalated_section
	.el-radio
	.el-radio__input.is-checked
	.el-radio__inner::after {
	background-color: #7b26c9 !important;
	height: 8px;
	width: 8px;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.is_escalated_section
	.el-radio
	.el-radio__label {
	font-family: RionaSans-Medium;
	font-size: 14px;
	line-height: 1.43;
	color: #262837;
}
@media screen and (min-width: 1200px) {
	.encounter-body
		.encounter-sections
		.encounter-section
		.encounter-accordian-body
		.segment-row
		.segment-col
		.is_escalated_section
		.el-radio:nth-child(2) {
		margin-left: 44px;
	}
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.is_escalated_section {
	padding-top: 8px;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.is_escalated_section.is-disabled {
	opacity: 0.45;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.is_escalated_section
	.encounter-input-field
	.el-input__inner {
	width: 100%;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	background-color: #ffffff;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-2cols
	.encounter-input-section
	.encounter-input-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-input-section
	.encounter-input-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-error-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-2cols
	.encounter-input-section
	.encounter-input-error-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-input-section
	.encounter-input-error-field
	.el-input__inner::placeholder {
	font-family: RionaSans;
	font-size: 13px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.form-control::placeholder {
	font-family: RionaSans;
	font-size: 13px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-error-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-2cols
	.encounter-input-section
	.encounter-input-error-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.encounter-input-section
	.encounter-input-error-field
	.el-input__inner::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.form-control::placeholder {
	color: #fe5c50;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-field
	.is-disabled
	.el-input__inner {
	width: 100%;
	border-radius: 4px;
	border: solid 1px #bac3ce;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #bac3ce;
	background-color: #edeff3;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-field
	.is-disabled
	.el-input__inner:hover,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-field
	.is-disabled
	.el-input__inner:active,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-field
	.is-disabled
	.el-input__inner:focus {
	border: solid 1px #bac3ce !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.el-input__inner:hover,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-field
	.el-textarea__inner:hover,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.form-control:not(:disabled):hover,
.encounter-body
	.encounter-accordian-body
	.gm-phone-number input:not(:disabled):hover {
	border: solid 1px #70cbf1 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.el-input__inner:focus,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.el-input__inner:active,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-field
	.el-textarea__inner:focus,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-field
	.el-textarea__inner:active,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.form-control:focus,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.form-control:active {
	border: solid 1.5px #40b9ec !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.el-input__inner:not(:disabled):hover::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-field
	.el-textarea__inner:not(:disabled):hover::placeholder,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.form-control:not(:disabled):hover::placeholder {
	color: #262837;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.el-input__inner,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.el-cascader
	.el-cascader__label,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.form-control,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-error-field
	.el-textarea
	.el-textarea__inner,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-field-value
	.encounter-input-error-field
	.el-input__inner {
	width: 100%;
	border-radius: 4px;
	border: solid 1px #fb3a2e;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #fe5c50;
	background-color: rgba(254, 145, 137, 0.5);
}

textarea.el-textarea__inner::placeholder {
	color: #262837;
	opacity: 0.5;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.el-input__inner,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-field
	.el-cascader
	.el-cascader__label {
	width: 100%;
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.el-cascader
	.el-cascader__label {
	font-size: 13px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-error,
.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.gm-phone-number .error {
	width: 100%;
	font-family: RionaSans;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ff0000;
	word-break: break-word;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-2cols
	.el-button {
	width: 66px;
	height: 36px;
	border-radius: 6.5px;
	border: solid 1px #7b8291;
	background-color: #ffffff;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-3cols
	.el-button {
	width: 152px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #40b9ec;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-cascader-menu__item.is-active {
	background-color: #20a0ff !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-cascader-menu__item.is-active:hover {
	background-color: #1c8de0 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-field
	.el-date-editor.el-input,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-error-field
	.el-date-editor.el-input,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-input-section
	.encounter-input-error-field
	.form-control {
	width: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-label-row,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: flex-end;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-select,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-cascader {
	width: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row {
	align-items: flex-start;
}

/* new css for via  */

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-first-col {
	width: calc(100% / 2 - 22px);
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col {
	width: 35%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.via_seprator {
	padding: 9px 7px;
	font-size: 13px;
}

.encounter-multi-cols-section
	.encounter-input-section
	.encounter-input-field
	.el-date-editor
	.el-input {
	width: auto;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.segment-2cols
	.encounter-input-field
	.el-date-editor,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.segment-col
	.encounter-input-error-field
	.el-date-editor,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.segment-col
	.encounter-input-field
	.el-date-editor,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.segment-2cols
	.encounter-input-error-field,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.segment-2cols
	.encounter-input-error-field
	.el-date-editor {
	width: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.segment-2cols
	.encounter-input-field
	.el-date-editor
	.el-input__inner,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.segment-2cols
	.encounter-input-error-field
	.el-date-editor
	.el-input__inner {
	font-size: 13px;
}

/* footer buttons */

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container {
	display: flex;
	justify-content: space-between;
	margin-top: 3px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	> button,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	div.el-tooltip.GMITooltip
	> div
	> div
	> button.editVisitSave {
	width: 160px;
	height: 36px;
	background-color: #7b26c9;
	color: #fff;
	font-size: 13px;
	border: 1px solid #7b26c9;
	border-radius: 6.5px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	> button:hover,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	div.el-tooltip.GMITooltip
	> div
	> div
	> button.editVisitSave:hover {
	background-color: #9c5dd7;
}

.encounterTT > .GMITooltip > div > div > button.is-disabled {
	background-color: #7b26c9 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	> button:active,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	div.el-tooltip.GMITooltip
	> div
	> div
	> button.editVisitSave:active {
	background-color: #621ea0;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	button:disabled,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.save-btn-link
	button.editVisitSave:disabled {
	border: none;
	background-color: #7b26c9 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.cancel-btn-link
	.el-button {
	border: 1px solid #7b8291;
	font-size: 13px;
	width: 66px;
	height: 36px;
	padding: 12px 12px 9px 12px;
	border-radius: 6.5px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.cancel-btn-link
	.el-button:hover {
	background-color: rgba(123, 130, 145, 0.15);
	color: #1f2d3d !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.cancel-btn-link
	.el-button:active {
	background-color: rgba(123, 130, 145, 0.3);
	color: #1f2d3d !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-field
	.el-select
	.el-select__tags
	.el-tag,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-error-field
	.el-select
	.el-select__tags
	.el-tag {
	display: flex;
	width: calc(100% - 5px);
	align-items: center;
}

.resubmit-claim > button,
.resubmit-claim > div > div > div > button {
	width: 100% !important;
	height: 36px !important;
	border-radius: 6.5px !important;
	border: solid 1px #7b26c9 !important;
	background-color: white;
	color: #7b26c9 !important;
	font-size: 13px !important;
}

.resubmit-claim > button:hover,
.resubmit-claim > div > div > div > button:hover {
	background-color: rgba(123, 38, 201, 0.15) !important;
}

.resubmit-claim > button:active,
.resubmit-claim > div > div > div > button:active {
	background-color: rgba(123, 38, 201, 0.3) !important;
}

.resubmit-claim > .el-button--default.is-disabled,
.resubmit-claim > .el-button.is-disabled:hover,
.resubmit-claim > div > div > div > .el-button--default.is-disabled,
.resubmit-claim > div > div > div > .el-button.is-disabled:hover,
.reclaim-disabled {
	background-color: transparent !important;
	color: #7b26c9 !important;
	border-color: #7b26c9 !important;
}

button.el-button.el-button--default.reclaim-disabled {
	background-color: transparent !important;
	color: #7b26c9 !important;
	border-color: #7b26c9 !important;
	opacity: 0.35;
	cursor: not-allowed;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-footer-container
	.cancel-btn-link
	.el-button {
	border: 1px solid #7b8291;
	font-size: 13px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-field
	.el-select
	.el-select__tags
	.el-tag,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-error-field
	.el-select
	.el-select__tags
	.el-tag {
	display: flex;
	width: calc(100% - 5px);
	align-items: center;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-field
	.el-select
	.el-select__tags
	.el-tag
	.el-select__tags-text,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-error-field
	.el-select
	.el-select__tags
	.el-tag
	.el-select__tags-text {
	overflow: hidden;
	width: calc(100% - 15px);
	text-overflow: ellipsis;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-field
	.el-select
	.el-select__tags
	.el-tag
	.el-icon-close,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.encounter-input-error-field
	.el-select
	.el-select__tags
	.el-tag
	.el-icon-close {
	top: 0;
	right: 0;
}

/* For disable multi select field */
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.multi-selectfield-disable
	.el-select
	.el-select__tags
	.el-tag {
	display: inline-block;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-cascader
	.el-cascader-menus
	.el-cascader-menu {
	height: 100%;
}

/*datepicker style change css*/

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.relative-section,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-error-field
	.relative-section {
	position: relative;
}

/* remove date icons*/

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.relative-section
	.el-input__icon {
	height: 36px;
	display: none;
}

/* end remove date icons*/

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.rdt::before {
	display: none;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.rdt
	.form-control {
	border: 1px solid #bfcbd9;
	height: 36px;
	font-size: 13px !important;
	color: #262837;
	box-shadow: unset;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.rdt
	.form-control:disabled {
	color: #bbb;
	background-color: #edeff3;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.rdt
	.form-control:hover {
	border-color: #8391a5;
}

/*datepicker when focus*/
.encounter-input-field .rdt .form-control:focus {
	border-color: #20a0ff;
	box-shadow: unset;
}

/*datepicker body*/
.encounter-input-error-field
	.rdt
	.rdtPicker
	.encounter-input-field
	.rdt
	.rdtPicker {
	padding: 12px;
	box-shadow: 0 2px 6px #ccc;
	border: 1px solid #f9f9f9;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table tr,
.encounter-input-field .rdt .rdtPicker .rdtMonths table tr,
.encounter-input-field .rdt .rdtPicker .rdtYears table tr,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table tr,
.encounter-input-error-field .rdt .rdtPicker .rdtMonths table tr,
.encounter-input-error-field .rdt .rdtPicker .rdtYears table tr {
	border-bottom: 0 !important;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table thead tr th,
.encounter-input-field .rdt .rdtPicker .rdtMonths table thead tr th,
.encounter-input-field .rdt .rdtPicker .rdtYears table thead tr th,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table thead tr th,
.encounter-input-error-field .rdt .rdtPicker .rdtMonths table thead tr th,
.encounter-input-error-field .rdt .rdtPicker .rdtYears table thead tr th {
	font-size: 14px;
	vertical-align: inherit;
	border-bottom: 0;
	font-weight: 400;
	color: #48576a;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table thead tr th.rdtPrev,
.encounter-input-field .rdt .rdtPicker .rdtDays table thead tr th.rdtNext,
.encounter-input-field .rdt .rdtPicker .rdtMonths table thead tr th.rdtPrev,
.encounter-input-field .rdt .rdtPicker .rdtMonths table thead tr th.rdtNext,
.encounter-input-field .rdt .rdtPicker .rdtYears table thead tr th.rdtPrev,
.encounter-input-field .rdt .rdtPicker .rdtYears table thead tr th.rdtNext,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table thead tr th.rdtPrev,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table thead tr th.rdtNext,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtPrev,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtNext,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtPrev,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtNext {
	font-size: 21px;
	color: #97a8be;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table thead tr th.rdtPrev,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.claim-tooltip {
	width: fit-content;
}

.encounter-input-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:first-child
	th:hover,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr:first-child
	th:hover,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr:first-child
	th:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:first-child
	th:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr:first-child
	th:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr:first-child
	th:hover {
	background-color: transparent;
	color: #20a0ff;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table thead tr:nth-child(2) th,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:nth-child(2)
	th {
	font-size: 12px;
	vertical-align: inherit;
	color: #8391a5;
	height: 30px;
	padding-top: 15px;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table tbody tr td {
	font-size: 12px;
	width: 32px;
	height: 32px;
}

.encounter-input-field .rdt .rdtPicker .rdtMonths table tbody tr td.rdtMonth,
.encounter-input-field .rdt .rdtPicker .rdtYears table tbody tr td.rdtYear,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear {
	color: #48576a;
	font-size: 12px;
	height: 32px;
	width: 48px;
}

.encounter-input-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth:hover,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear:hover {
	background-color: #e4e8f1;
	text-decoration: underline;
}

/*prev-month and next-month css*/
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtOld,
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtNew,
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtOld,
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtNew,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtOld,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtNew,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtOld,
.encounter-input-error-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtNew {
	color: #ddd;
}

/*prev-month and next-month css on hover*/
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtOld:hover,
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtNew:hover,
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtNew:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtOld:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtNew:hover {
	background: transparent !important;
}

/*rdt day active .rdtActive rdtDay rdtActive*/
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive:hover,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive:hover,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive:hover {
	background-color: #20a0ff;
	color: #ffffff;
}

.encounter-input-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtToday:before,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtToday:before {
	content: ' ';
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	border-top: 0.5em solid #20a0ff;
	border-left: 0.5em solid transparent;
	bottom: unset;
	border-bottom: 0;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtDay:hover,
.encounter-input-field .rdt .rdtPicker .rdtDays table tbody tr td.rdtDay:hover {
	background-color: #e4e8f1;
}

/*date and time*/

.encounter-input-field .rdt .rdtPicker .rdtDays table tfoot,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot {
	border-top: 0;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table tfoot tr td,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td {
	font-size: 14px;
	font-weight: 400;
}

.encounter-input-field .rdt .rdtPicker .rdtDays table tfoot tr td:hover,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td:hover {
	background-color: transparent;
	color: #20a0ff;
}

.encounter-input-field .rdt .rdtPicker .rdtTime table thead tr th,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th {
	height: 32px;
	font-size: 14px;
	font-weight: 400;
	color: #48576a;
	border-bottom: 0;
}

.encounter-input-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn:hover,
.encounter-input-field .rdt .rdtPicker .rdtTime table thead tr th:hover,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn:hover,
.encounter-input-error-field .rdt .rdtPicker .rdtTime table thead tr th:hover,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th:hover {
	background-color: transparent;
	color: #20a0ff;
}

.encounter-input-field .rdt .rdtPicker .rdtTime table thead tr,
.encounter-input-field .rdt .rdtPicker .rdtTime table tbody tr,
.encounter-input-error-field .rdt .rdtPicker .rdtTime table thead tr,
.encounter-input-error-field .rdt .rdtPicker .rdtTime table tbody tr,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr,
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr {
	border-bottom: 0 !important;
}

.encounter-input-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn,
.encounter-input-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtCount,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn,
.encounter-input-error-field
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtCount {
	font-size: 14px;
	color: #48576a;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.el-cascader
	.el-cascader-menus
	.el-cascader-menu {
	height: 100%;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.segment-col
	.encounter-field-value
	img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	border-color: #edeff3;
	margin-right: 2px;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.encounter-input-error-field
	.form-control {
	border: solid 1px #fb3a2e;
	color: #fb3a2e;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.calendar-right-encounter
	.rdtPicker {
	right: 0;
}

.encounter-body .el-radio + .el-radio {
	margin-left: 0;
}

.encounter-body .encounter-sections .encounter-section .el-radio {
	margin-bottom: 8px;
}

.encounter-body .encounter-sections .encounter-section .el-radio__label {
	font-family: RionaSans;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-input-field
	.el-radio
	.el-radio__input
	.el-radio__inner {
	height: 16px;
	width: 16px;
	border: 1px solid #a0abb9;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-input-field
	.el-radio
	.el-radio__input.is-checked
	.el-radio__inner {
	height: 16px;
	width: 16px;
	border: 1px solid #40b9ec;
	background-color: #fff;
}
.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-input-field
	.el-radio
	.el-radio__input.is-checked
	.el-radio__inner::after {
	background-color: #40b9ec;
}

.encounter-input-field.noTextBreak
	.inputFieldDropdown
	.el-select-dropdown
	.el-scrollbar__view
	.el-select-dropdown__item
	span {
	white-space: pre !important;
}

.icdEditIcon {
	width: 50px;
	height: 20px;
	border-radius: 4px;
	background-color: #7b26c9;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 10px;
	font-family: 'RionaSans';
	color: #ffffff;
	cursor: pointer;
	user-select: none;
}

.icdEditIcon:hover {
	background-color: #9c5dd7;
}

.icdEditIcon:active {
	background-color: #621ea0;
}

.icdEditIconSVG {
	transform: scale(0.7);
	position: relative;
	right: 4px;
}

.icdEditIcon > div.icdEditIconSVG > div > svg > g > g {
	fill: white;
}

.transparentBtn {
	width: 178px;
	height: 36px;
	border-radius: 6.5px;
	border: 1px solid #7b26c9;
	background-color: white;
	padding-top: 8px;
	text-align: center;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: #7b26c9;
	user-select: none;
	cursor: pointer;
}

.transparentBtn:hover {
	background-color: rgba(123, 38, 201, 0.15);
}

.transparentBtn:active {
	background-color: rgba(123, 38, 201, 0.3);
}


.transparentBtn.disabled {
	background-color: white;
	color: #dddddd;
	cursor: default;
}

.encounter-body .addencounter-footer-container {
	margin-top: 32px;
	margin-bottom: 51px;
	display: flex;
	justify-content: space-between;
}

.encounter-body .addencounter-footer-container .save-btn-link > button {
	width: 160px;
	height: 36px;
	background-color: #7b26c9;
	color: #fff;
	font-size: 13px;
	border: 1px solid #7b26c9;
	border-radius: 6.5px;
	margin-right: 42px;
}

.encounter-body .addencounter-footer-container .save-btn-link > button:hover {
	background-color: #9c5dd7;
}

.encounter-body .addencounter-footer-container .save-btn-link > button:active {
	background-color: #621ea0;
}

.encounter-body .addencounter-footer-container .save-btn-link button:disabled {
	border: none;
	background-color: #7b26c9 !important;
}

.encounter-body .addencounter-footer-container .cancel-btn-link .el-button {
	border: 1px solid #7b8291;
	font-size: 13px;
	width: 66px;
	height: 36px;
	padding: 12px 12px 9px 12px;
	border-radius: 6.5px;
	margin-left: 29px;
	width: 160px;
	border: 1px solid #7b26c9;
	color: #7b26c9;
}

.encounter-body
	.addencounter-footer-container
	.cancel-btn-link
	.el-button:hover {
	background-color: rgba(123, 130, 145, 0.15);
	color: #1f2d3d !important;
}

.encounter-body
	.addencounter-footer-container
	.cancel-btn-link
	.el-button:active {
	background-color: rgba(123, 130, 145, 0.3);
	color: #1f2d3d !important;
}

.encntr-detail .add-encounter-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	margin-left: 21px;
	margin-bottom: 34px;
}

.encntr-detail .add-encounter-wrapper .lbl-encntr-type {
	font: normal bold 12px/1.42 RionaSans;
	color: #262837;
}

.encounter-body .encounter-accordian-body .required::before,
.encntr-detail .add-encounter-wrapper .required::before {
	color: #e74c3c;
	content: '*';
	font: normal bold 12px/1.42 RionaSans;
	margin-left: -3px;
}

.encntr-detail .add-encounter-wrapper .select-encntr-type {
	font: normal 13px/1.38 RionaSans;
	color: #262837;
	width: 372px;
	height: 32px;
}

.encntr-detail .add-encounter-wrapper .select-encntr-type .el-input {
	height: 32px !important;
}

.encntr-detail .add-encounter-wrapper .select-encntr-type .el-input__inner,
.encntr-detail
	.add-encounter-wrapper
	.select-encntr-type
	.el-input__inner::placeholder {
	font: normal 13px/1.38 RionaSans !important;
	color: #262837 !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.is-disabled
	.el-input__icon {
	cursor: not-allowed;
}

.encounter-body .el-dialog__footer .el-button {
	font-size: 13px !important;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.encounter-input-section
	.rdt
	.form-control:disabled:hover {
	border-color: #bfcbd9;
}

.encounter-body
	.encounter-sections
	.segment-value-row
	.encounter-multi-cols-section
	.multi-two-col
	.el-select
	.is-multiple {
	width: 140px !important;
}

.encounter-accordian-body .add-encntr-lbl-billing-notes,
.encounter-accordian-body .add-encntr-lbl-cc-notes,
.encounter-accordian-body .edit-encntr-lbl-billing-notes,
.encounter-accordian-body .edit-encntr-lbl-cc-notes {
	margin: 0;
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #262837;
}

.post-creation-notice {
	font: italic 500 13px/1.38 RionaSans-Medium;
	font-size: 13px;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

/******************************************
 start: Hold Result Release for Test Order 
*******************************************/
.hold-result-release-modal-container {
	width: 368px;
	min-height: 150px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: fixed;
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
}

.hold-result-release-modal-top-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 9px 16px;
	height: 56px;
	border-bottom: 1.5px solid #edeff3a2;
}

.hold-result-release-modal-header {
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #262837;
	margin-bottom: 2px;
	position: unset;
	top: 5px;
	left: 5px;
	margin-top: 8px;
}

.hold-result-release-modal-body {
	max-height: calc(100vh - 180px);
	padding: 32px 16px;
	overflow-y: auto;
}

.hold-result-release-modal-body .chk-hold-result-release {
	display: block;
	margin-bottom: 0;
	padding-bottom: 16px;
}

.hold-result-release-modal-body .chk-hold-result-release .el-checkbox__label {
	padding-left: 9px !important;
	font: normal 13px/1.38 RionaSans;
}

.hold-result-release-modal-body
	.chk-hold-result-release
	.el-checkbox__input.is-checked
	.el-checkbox__inner {
	border-color: #7b26c9;
}

.encounter-body
	.encounter-sections
	.encounter-section
	.encounter-accordian-body
	.segment-row
	.hold-result-release-modal-container
	.el-checkbox__inner::after {
	border: 1.5px solid #7b26c9 !important;
}

.hold-result-release-modal-body .chk-hold-result-release-note {
	font: normal 500 13px/1.38 RionaSans-MediumItalic;
	color: #262837;
}

.chk-hold-result-release .el-checkbox__input.is-checked .el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.chk-hold-result-release .el-checkbox__inner::after {
	border: 1.7px solid #7b26c9 !important;
	border-left: 0 !important;
	border-top: 0 !important;
}
.hold-result-release-modal-buttonrow {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 12px 16px;
	font-size: 13px;
	color: white;
	border-top: 1px solid #edeff3a2;
	height: 60px;
	background-color: #fff;
	position: relative;
}

.hold-result-release-save-button.el-button {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	font: normal 500 13px/1.38 RionaSans;
	text-align: center;
	color: #ffffff !important;
	border: none;
}
/******************************************
 end: Hold Result Release for Test Order 
*******************************************/

.encounterContentReviewedContainer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.encounterContentReviewedItem {
	width: 178px;
	height: 95px;
	border-radius: 4px;
	background-color: #edeff3;
	font-family: 'RionaSans-Bold';
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	color: #262837;
	padding: 12px;
	margin-right: 16px;
	margin-bottom: 16px;
}

.riskFactorItemContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-bottom: 2px;
	padding-left: 4px;
}

.riskFactorItemText {
	font-size: 14px;
	color: #262837;
	padding-bottom: 1px;
}

.riskFactorItemTextArchived {
	text-decoration: line-through;
}

.riskFactorEditItemContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 12px;
	margin-bottom: 4px;
}

.riskFactorEditButtonRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 26px;
}

/****************************************************************
 start: Related encounter(s) Link Section 
*****************************************************************/
.encounter-body
	.encounter-accordian-body
	.encntr-link-related-info-container
	.body {
	padding: 0;
}

/*************************************************************************** 
                            Add Outreach Popup    
 ***************************************************************************/

.encounter-sections .outrch-dialog {
	width: 368px;
	position: fixed;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.encounter-sections .outrch-dialog .el-dialog__header {
	padding: 17px 22px 17px 16px;
	height: 56px;
	border-bottom: 1px solid #edeff3;
}

.encounter-sections .outrch-dialog .el-dialog__header .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	color: #262837;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__header
	.el-dialog__headerbtn
	.el-dialog__close {
	font-size: 12px;
	font-weight: bold;
	color: #262837;
}

.encounter-sections .outrch-dialog .el-dialog__body {
	padding: 32px 16px;
	overflow-y: auto;
	max-height: calc(100vh - 240px);
}

.encounter-sections .outrch-dialog .el-dialog__body .outrch-dialog-body {
	width: 100%;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment {
	margin-bottom: 19px;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segememt-header {
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	position: relative;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segememt-header:after {
	content: '*';
	margin-left: -6px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: red;
	vertical-align: text-top;
	position: absolute;
	left: 0;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select {
	width: 100%;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt::before {
	display: none;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select
	.el-input:not(.is-disabled)
	.el-input__inner,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control {
	height: 32px;
	color: #262837;
	font-size: 13px !important;
}

.outrchlog-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.el-input.is-disabled
	.el-input__inner {
	color: #bac3ce !important;
}

.outrch-dialog-body > div.segment > div > div.input-error {
	font-family: 'RionaSans-Medium';
	font-size: 10px;
	color: #e74c3c;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-select
	.el-input__inner::placeholder,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control::placeholder {
	color: #bac3ce;
	font-size: 13px !important;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.el-textarea
	.el-textarea__inner {
	font-size: 13px;
	min-height: 84px;
	padding: 7px 8px;
}

.encounter-sections .outrch-dialog .el-dialog__footer {
	border-top: 1px solid #edeff3;
	padding: 12px 16px;
}

.encounter-sections .outrch-dialog .el-dialog__footer .outreach-btn {
	padding: 9px 12px;
	background-color: #7b26c9;
	min-width: 160px;
	border: 1px solid #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__footer
	.outreach-btn.el-button.is-disabled {
	background-color: #7b26c9 !important;
	opacity: 0.35;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__footer
	.outreach-btn.el-button.is-disabled:focus {
	background-color: #7b26c9 !important;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__footer
	.outreach-btn.el-button.is-disabled:hover {
	background-color: #9c5dd7 !important;
}

.encounter-sections .outrch-dialog .el-dialog__footer .outreach-btn:hover {
	background-color: #9c5dd7;
	border-color: solid 1px #9c5dd7;
}

.encounter-sections .outrch-dialog .el-dialog__footer .outreach-btn:disabled {
	border: unset;
}

/* Add Outreach Popup: date and time */
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.form-control {
	border: 1px solid #bfcbd9;
	color: #1f2d3d;
	box-shadow: unset;
	height: 32px;
	font-size: 13px !important;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker {
	padding: 12px;
	box-shadow: 0 2px 6px #ccc;
	border: 1px solid #f9f9f9;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot {
	border-top: 0;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td {
	font-size: 14px;
	font-weight: 400;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td:hover,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tfoot
	tr
	td:hover {
	background-color: transparent;
	color: #20a0ff;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th {
	height: 32px;
	font-size: 14px;
	font-weight: 400;
	color: #48576a;
	border-bottom: 0;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th:hover,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th:hover {
	background-color: transparent;
	color: #20a0ff;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tr,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tr,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tr {
	border-bottom: 0 !important;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtBtn,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	tbody
	tr
	td
	.rdtCounters
	.rdtCounter
	.rdtCount {
	font-size: 14px;
	color: #48576a;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th.rdtPrev,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th.rdtNext,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtPrev,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th.rdtNext,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtPrev,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th.rdtNext {
	font-size: 21px;
	color: #97a8be;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th,
.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtTime
	table
	thead
	tr
	th {
	height: 32px;
	font-size: 14px;
	font-weight: 400;
	color: #48576a;
	border-bottom: 0;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr:nth-child(2)
	th {
	font-size: 12px;
	vertical-align: inherit;
	color: #8391a5;
	height: 30px;
	padding-top: 15px;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	thead
	tr
	th,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	thead
	tr
	th,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	thead
	tr
	th {
	font-size: 14px;
	vertical-align: inherit;
	border-bottom: 0;
	font-weight: 400;
	color: #48576a;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtDay.rdtActive:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtMonths
	table
	tbody
	tr
	td.rdtMonth.rdtActive:hover,
.outrch-tab
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtYears
	table
	tbody
	tr
	td.rdtYear.rdtActive:hover {
	background-color: #20a0ff;
	color: #ffffff;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td {
	font-size: 12px;
	width: 32px;
	height: 32px;
}

.encounter-sections
	.outrch-dialog
	.el-dialog__body
	.outrch-dialog-body
	.segment
	.segmeent-body
	.input-section
	.rdt
	.rdtPicker
	.rdtDays
	table
	tbody
	tr
	td.rdtToday:before {
	content: ' ';
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	border-top: 0.5em solid #20a0ff;
	border-left: 0.5em solid transparent;
	bottom: unset;
	border-bottom: 0;
}

.encounter-sections .ext-providers-wrapper {
	width: 840px;
}
.encounter-sections .ext-providers-wrapper .ext-providers {
	display: flex;
	flex-wrap: wrap;
}
.encounter-sections .ext-providers-wrapper .ext-providers .provider-linked {
	flex: 0 0 416px;
	margin-bottom: 8px;
	align-items: center;
}
.encounter-section
	.ext-providers-wrapper
	.ext-providers
	.provider-linked:nth-of-type(even) {
	margin-left: 8px;
}
.encounter-sections .provider-add-another {
	width: 416px;
}
.encounter-section
	.ext-providers-wrapper
	.ext-providers
	.provider-linked
	.provider
	.info {
	display: flex;
	justify-content: center;
}

.relative-section > div > div.rdt > input::placeholder {
	font-size: 13px !important;
}

/*************************************************************************** 
                    Encounter Detail: Test Order Segment     
 ***************************************************************************/
.encounter-body .encounter-accordian-body .disable-testrecommended-selection {
	opacity: 0.5;
	cursor: not-allowed;
}
