@use '../../../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.select-a-patient {
		.select-patient {
			width: 60%;
		}
	}
	.el-select,
	.el-date-editor {
		width: 100%;
	}

	.patient-info {
		.guardian-checkbox {
			margin-top: system.$halfUnit;
			margin-bottom: system.$halfUnit;
		}
		p {
			margin-top: system.$singleUnit;
		}
	}
}
