.templates-wrapper {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 10px 0 70px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.templates-wrapper table td p {
	margin: unset;
}

.templates-wrapper table td .sub {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.templates-wrapper table thead tr th:first-child > span,
.templates-wrapper table tbody tr td:not(.no-results):first-child > div {
	min-width: unset !important;
	max-width: 24px !important;
	display: flex;
	justify-content: flex-end;
}

.templates-wrapper table .el-checkbox__input.is-focus .el-checkbox__inner,
.templates-wrapper table .el-checkbox__input .el-checkbox__inner:hover {
	border-color: #7b26c9;
}

.templates-wrapper table .el-checkbox__input .el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}

.templates-wrapper table .el-checkbox__input.is-checked .el-checkbox__inner,
.templates-wrapper
	table
	.el-checkbox__input.is-indeterminate
	.el-checkbox__inner {
	border-color: #7b26c9;
	background-color: #7b26c9 !important;
}

.templates-wrapper table .el-checkbox {
	margin: unset;
}

.templates-wrapper .three-dots-wrapper > span {
	display: flex;
}

.templates-wrapper .three-dots-wrapper button,
.templates-wrapper .three-dots-wrapper button * {
	width: 32px;
	height: 32px;
}

.templatesContainer {
	margin-top: 46px;
	margin-left: 61px;
	min-height: calc(100vh - 84px);
	width: calc(100vw - 61px);
	background-color: #edeff3;
	padding: 16px 8px 0px 8px;
}

.templatesFilterActionRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.printBtnB-templates {
	width: calc(100% - 90px);
	height: 62px;
	background-color: white;
	position: absolute;
	z-index: 1;
}

.filterDropdownButton {
	width: 118px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	padding: 9px 12px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	user-select: none;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: white;
	margin-right: 60px;
}

.filterDropdownButtonIcon {
	position: relative;
	top: 1px;
}

.filterDropdownButtonIcon > div > svg > g > g {
	fill: white;
}

.filterDropdownButton:hover {
	background-color: #9c5dd7;
}

.filterDropdownButton:active {
	background-color: #621ea0;
}

.filterDropdownButtonDisabled {
	cursor: no-drop !important;
	opacity: 0.35 !important;
	background-color: #7b26c9 !important;
}

.filterDropdownButtonDisabled:hover {
	background-color: #7b26c9 !important;
}

.filterDropdownButtonDisabled:active {
	background-color: #7b26c9 !important;
}

.templatesFilterActionRow
	> div
	> div.el-dropdown.filterDropdownElement
	> ul.el-dropdown-menu {
	left: unset !important;
}

.templatesFilterDropdown {
	margin-right: 12px;
	width: 170px;
}

.templatesFilterDropdownMultiselect
	> div.el-input
	> input.el-input__inner::placeholder {
	color: #1f2d3d !important;
}

.templatesFilterDropdownMultiselect > div.el-select__tags {
	max-height: 30px;
	overflow-y: auto;
	max-width: 138px !important;
	overflow-y: auto !important;
	overflow-x: hidden !important;
}

.templatesFilterDropdownMultiselect > div.el-select__tags > span.el-tag {
	display: flex;
	align-items: center;
}

.templatesFilterDropdownMultiselect > div.el-select__tags > span.el-tag > span {
	/* max-width: 115px; */
	overflow: hidden;
	/* white-space: nowrap; */
	text-overflow: ellipsis;
}

.templatesFilterDropdownMultiselect > div.el-select-dropdown.is-multiple {
	right: 0px !important;
	left: unset !important;
}

.templatesFilterDropdown,
.templatesFilterDropdown > div.el-input > input,
.templatesFilterDropdown > div.el-input > input::placeholder {
	font-size: 13px !important;
}

.templatesLoader {
	width: 100%;
	height: 100%;
}

.templatesLoader > div {
	background-color: unset !important;
}

.noTemplatesMessage {
	margin-top: 80px;
	text-align: center;
	width: 100%;
}

.templateTableContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.templateTable {
	table-layout: fixed;
}

.templateTableHead {
	background-color: #edeff3 !important;
	font-size: 14px !important;
}

.templateTableHeadCell {
	font-family: 'RionaSans-Medium' !important;
	margin-right: 20px !important;
}

.templateTableCheckbox {
	position: relative;
	top: 2px;
	left: 4px;
}

.templateTableRow {
	background-color: white !important;
	color: #262837 !important;
	font-size: 14px !important;
	font-family: 'RionaSans' !important;
}

.templateTableCell {
	font-size: 14px !important;
	color: #262837 !important;
	padding: 4px 10px !important;
}

.templateTableContainer
	> table.templateTable
	> thead.templateTableHead
	> tr
	> th:first-child {
	width: 44px !important;
	max-width: 44px !important;
	min-width: 44px !important;
}

.templateTableContainer
	> table.templateTable
	> tbody
	> tr:first-child
	> td:first-child {
	border-top-left-radius: 8px;
}

.templateTableContainer
	> table.templateTable
	> tbody
	> tr:first-child
	> td:last-child {
	border-top-right-radius: 8px;
}

.templateTableContainer
	> table.templateTable
	> tbody
	> tr:last-child
	> td:first-child {
	border-bottom-left-radius: 8px;
}

.templateTableContainer
	> table.templateTable
	> tbody
	> tr:last-child
	> td:last-child {
	border-bottom-right-radius: 8px;
}

.templateRowDraftName {
	font-style: italic;
}

.templateRowArchiveName {
	color: #e74c3c !important;
}

.templateTableReferralProgramsCell {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.templateTableReferralProgramsText {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
}

.templateTableReferralProgramsText:hover {
	text-decoration: underline;
}

.templateTableReferralProgramsIcon {
	padding-left: 8px;
	padding-top: 2px;
	cursor: pointer;
}

.templatesThreeDotMenu {
	height: 36px;
	width: 36px;
	border-radius: 4px;
	position: relative;
	bottom: 0px;
	left: 5px;
}

.templatesThreeDotMenuOpen {
	background-color: rgba(216, 216, 216, 0.4);
}

.templatesThreeDotMenu > div > svg {
	position: relative;
	left: 4px;
	top: 3px;
}

.templatesThreeDotMenu > div > svg > g > g {
	fill: #7b8291 !important;
}

.templatesThreeDotMenu:hover {
	background-color: rgba(216, 216, 216, 0.4);
}

.templatesThreeDotMenu:active {
	background-color: rgb(210, 210, 210);
}

.templatesThreeDotMenuDisabled,
.templatesThreeDotMenuDisabled:hover,
.templatesThreeDotMenuDisabled:active {
	opacity: 0.3 !important;
	background-color: unset !important;
	cursor: no-drop !important;
}

.templateTableReferralProgramsCell
	> div.filterDropdownElement
	> ul.el-dropdown-menu {
	position: absolute !important;
	left: unset !important;
	right: -5px !important;
	width: 180px !important;
	border-radius: 6px;
}

.confirmationModalContainer {
	width: 368px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
}

.confirmationModalHeaderRow {
	width: 100%;
	height: 56px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 1px solid #edeff3;
	font-family: 'RionaSans-Bold';
	font-size: 16px;
	color: #262837;
	padding: 16px;
}

.confirmationModalHeaderRowIcon {
	transform: scale(1.1);
	margin-right: 4px;
}

.confirmationModalHeaderRowIcon > div > svg > g > path {
	fill: #f1c40f;
}

.confirmationModalBodyContainer {
	width: 100%;
	padding: 32px 16px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: #262837;
	white-space: pre-wrap;
}

.confirmationModalBodyDropdownContainer {
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 230px);
	overflow-y: auto;
}

.confirmationModalRequiredField {
	color: red;
	font-size: 15px;
	font-family: 'RionaSans-Bold';
	top: 0px;
	left: -9px;
	position: absolute;
}

.confirmationModalDropdownFieldTitle {
	height: 17px;
	font-family: 'RionaSans';
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.confirmationModalDropdownContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	font-size: 13px;
	font-weight: 500;
	color: #262838;
}

.confirmationModalDropdownEl {
	width: 100%;
	font-family: 'RionaSans';
	font-size: 13px;
	color: #262837;
}

.confirmationModalDropdownEl > div.el-input {
	font-size: 13px !important;
}

.confirmationModalDropdownElementText {
	font-family: 'RionaSans' !important;
	font-size: 13px !important;
	color: #262837 !important;
}

.confirmationModalDropdownEl
	> div.el-input
	> input.el-input__inner::placeholder {
	font-size: 13px !important;
	font-family: 'RionaSans' !important;
	color: rgb(117, 117, 117) !important;
}

.confirmationModalInput {
	width: 100%;
	font-size: 13px;
	color: #262837;
	height: 36px;
	padding-left: 10px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: white;
	text-align: left;
	font-family: 'RionaSans';
}

.confirmationModalInput::placeholder {
	font-size: 13px !important;
	font-family: 'RionaSans' !important;
}

.confirmationModalButtonRow {
	width: 100%;
	height: 60px;
	padding: 12px 16px;
	border-top: 1px solid #edeff3;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.confirmationModalButtonRowCancel,
.confirmationModalButtonRowConfirm {
	height: 36px;
	padding-top: 9px;
	width: 160px;
	border-radius: 6px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	user-select: none;
	cursor: pointer;
}

.confirmationModalButtonRowCancel {
	border: 1px solid #7b26c9;
	color: #7b26c9;
	background-color: white;
}

.confirmationModalButtonRowCancel:hover {
	background-color: rgba(122, 38, 201, 0.15);
}

.confirmationModalButtonRowCancel:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.confirmationModalButtonRowConfirm {
	color: white;
	background-color: #7b26c9;
}

.confirmationModalButtonRowConfirm:hover {
	background-color: #9c5dd7;
}

.confirmationModalButtonRowConfirm:active {
	background-color: #621ea0;
}

.confirmationModalButtonRowConfirmDisabled {
	background-color: #7b26c9 !important;
	opacity: 0.35;
	cursor: no-drop;
}

.referralProgramsModalContainer {
	width: 368px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-height: calc(100vh - 120px);
}

.referralProgramsModalTopRowContainer {
	width: 100%;
	height: 56px;
	padding: 9px 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #edeff3;
}

.referralProgramsModalTopRowTitleSubtitleContainer {
	display: flex;
	flex-direction: column;
}

.referralProgramsModalTopRowTitle {
	font-family: 'RionaSans-Bold';
	font-size: 16px;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.referralProgramsModalTopRowSubtitle {
	font-size: 12px;
	color: #262837;
	max-width: 310px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.referralProgramsModalBodyContainer {
	width: 100%;
	max-height: calc(100vh - 180px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding: 16px;
}

.referralProgramsModalBodyRow {
	font-size: 12px;
	color: #262837;
	margin-bottom: 8px;
}
