.extrnl-dashbrd-card-wrapper {
	height: 128px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.extrnl-dashbrd-card-wrapper .patient-stage-cards {
	width: calc((100% - 15px) / 3);
	/* max-width: 297px; */
	height: 128px;
	padding: 17px 16px;
	border: 1px solid #edeff3;
	border-radius: 4px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
	cursor: default;
}

.extrnl-dashbrd-card-wrapper .title-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 14px;
}

.extrnl-dashbrd-card-wrapper .patient-stage-card-title {
	width: 151px;
	font: normal bold 14px/1.43 RionaSans-Bold;
	color: #262837;
	text-transform: capitalize;
	display: inline-block;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-card-wrapper .patient-stage-card-icon-wrapper {
	width: 40px;
	height: 40px;
	padding: 8px 9px;
	border-radius: 20px;
	background-color: #edeff3;
	display: inline-block;
}

.extrnl-dashbrd-card-wrapper .data-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.extrnl-dashbrd-card-wrapper .patient-stage-card-total {
	font: normal bold 28px/1.43 RionaSans-Bold;
	color: #262837;
	display: inline-block;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-card-wrapper .view-detail {
	font: normal 500 11px/1.36 RionaSans-Medium;
	color: #a0abb9;
	display: inline-block;
	/* cursor: pointer; */
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-card-wrapper .patient-stage-cards:hover {
	box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.16);
}

.extrnl-dashbrd-card-wrapper .patient-stage-cards:focus,
.extrnl-dashbrd-card-wrapper .patient-stage-cards:active {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.extrnl-dashbrd-card-wrapper .no-patient-stage-cards {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	cursor: not-allowed;
}

.extrnl-dashbrd-card-wrapper .no-patient-stage-cards:hover,
.extrnl-dashbrd-card-wrapper .no-patient-stage-cards:focus,
.extrnl-dashbrd-card-wrapper .no-patient-stage-cards:active {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.extrnl-dashbrd-card-wrapper .no-patient-stage-cards .patient-stage-card-title,
.extrnl-dashbrd-card-wrapper .no-patient-stage-cards .patient-stage-card-total {
	color: #a0abb9 !important;
}

.extrnl-dashbrd-card-wrapper
	.no-patient-stage-cards
	.patient-stage-card-icon-wrapper
	> img {
	opacity: 0.4;
}

.extrnl-dashbrd-card-wrapper .has-patient-stage-cards {
	cursor: pointer;
}
