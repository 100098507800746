.document-notifications .link-note {
	min-width: 386px;
	min-height: 14px;
	font-family: RionaSans;
	font-size: 10px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #525969;
}

.doc-notification-container-div {
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	display: inline-flex;
}

.doc-notification-container-title {
	color: #262837;
	font-size: 19px;
	font-family: RionaSans-Bold;
	font-weight: 700;
	line-height: 22.80px;
	word-wrap: break-word;
}

.doc-notification-container-description {
	align-self: stretch;
	color: #606266;
	font-size: 16px;
	font-family: RionaSans;
	font-weight: 400;
	line-height: 20px;
	word-wrap: break-word;
}

.default-recipients-table .custom-table,
.default-recipients-table .custom-table table {
	min-width: 0px !important;
	width: 100%;
}


.top-text-div {
	color: #262837;
	font-size: 14px;
	font-family: RionaSans-Medium;
	font-weight: 490;
	line-height: 17.50px;
	word-wrap: break-word;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 16px;
	padding-top: 16px;
}

.auto-complete-div {
	padding-left: 16px;
	padding-right: 8px;
}

.table-div {
	width: 685px;
	padding-left: 16px;
	position: fixed;
	max-height: 150px;
	overflow-y: auto;
}

.autocomplete-item {
	background: white;
	padding-left: 12px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.autocomplete-item-highlighted {
	background: lightgray;
	padding-left: 12px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.user-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

.table-header {
	background-color: #f5f5f5;
	font-weight: bold;
	padding: 8px;
	text-align: left;
	border-bottom: 2px solid #ccc;
}

.table-row:nth-child(even) {
	background-color: #f2f2f2;
}

.table-row:hover {
	background-color: #ddd;
}

.table-cell {
	padding: 8px;
	border-bottom: 1px solid #ccc;
}

.recipient-add-dialog {
	width: 700px !important;
	position: fixed !important;
	max-height: calc(100vh - 120px);
	min-height: 200px;
	left: 50% !important;
	top: 50% !important;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	background-color: #ffffff !important;
	-webkit-font-smoothing: antialiased;
}

.recipient-add-dialog .recipient-wrapper .recipient-loading {
	width: 100% !important;
}

.recipient-add-dialog .recipient-wrapper .recipient-loading>div {
	height: 99%;
}

.recipient-add-dialog .el-dialog__header {
	height: 56px;
	border-bottom: 1px solid #edeff3;
	padding: 0;
}

.recipient-add-dialog .el-dialog__header .close-btn-icon {
	width: 56px;
	height: 56px;
	border-top-right-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.recipient-add-dialog .el-dialog__header .close-btn-wrapper.isReadOnly {
	cursor: not-allowed;
}

.recipient-add-dialog .el-dialog__header .close-btn-wrapper.isReadOnly span.close-btn-icon {
	pointer-events: none;
}

.recipient-add-dialog .el-dialog__header .close-btn-icon:hover {
	background-color: #edeff3;
}

.recipient-add-dialog .el-dialog__header .close-btn-icon:active {
	background-color: #d8dde4;
}

.recipient-add-dialog .el-dialog__title {
	display: flex;
	flex-direction: row;
}

.recipient-add-dialog .el-dialog__title .header {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.recipient-add-dialog .el-dialog__title .dialog-title {
	font: bold 16px/1.38 RionaSans-Bold;
	text-align: left;
	color: #262837;
	margin: 9px 0px 8px 16px;
}

.recipient-add-dialog .el-dialog__title .dialog-title.no-breadcrumbs {
	align-items: center;
	display: flex;
}

.recipient-add-dialog .el-dialog__title .breadcrumbs {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b8291;
	display: flex;
	justify-content: center;
	align-items: center;
}

.recipient-add-dialog .el-dialog__title .breadcrumbs .active {
	color: #262837;
}

.recipient-add-dialog .el-dialog__title .breadcrumbs .right-arrow-thin {
	margin-left: 4px;
	margin-right: 4px;
}

.recipient-add-dialog .el-dialog__title .breadcrumbs .right-arrow-thin>div,
.recipient-add-dialog .el-dialog__title .breadcrumbs .right-arrow-thin>div>svg {
	width: 16px;
	height: 16px;
}

.recipient-add-dialog .el-dialog__title .close-icon>div,
.recipient-add-dialog .el-dialog__title .close-icon>div>svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.recipient-add-dialog .el-dialog__title .close-icon svg .GMICloseIcon {
	fill: #262837;
}

.recipient-add-dialog .el-dialog__body {
	padding: 0;
}

.recipient-add-dialog .el-dialog__footer {
	height: 60px;
	padding: 12px 16px;
	border-top: 1px solid #edeff3;
	position: absolute;
	bottom: 16px;
	right: 16px;
	width: 95%;
}

.recipient-add-dialog .el-dialog__footer .cancel-button {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
}

.recipient-add-dialog .el-dialog__footer .cancel-button:hover,
.recipient-add-dialog .el-dialog__footer .cancel-button:focus {
	background-color: rgba(123, 38, 201, 0.15);
}

.recipient-add-dialog .el-dialog__footer .cancel-button:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.recipient-add-dialog .el-dialog__footer .cancel-button>span {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #7b26c9;
}

.recipient-add-dialog .el-dialog__footer .confirm-button:not(.is-disabled) {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	border: solid 1px #7b26c9;
	background-color: #7b26c9;
}

.recipient-add-dialog .el-dialog__footer .confirm-button:not(.is-disabled):hover,
.recipient-add-dialog .el-dialog__footer .confirm-button:not(.is-disabled):focus {
	background-color: #9c5dd7;
}

.recipient-add-dialog .el-dialog__footer .confirm-button:not(.is-disabled):active {
	background-color: #7b26c9;
}

.recipient-add-dialog .el-dialog__footer .confirm-button>span {
	font: normal 500 13px/1.38 RionaSans-Medium;
	color: #ffffff;
}

.recipient-add-dialog .el-dialog__footer .confirm-button.is-disabled {
	width: 160px;
	height: 36px;
	border-radius: 6px;
	opacity: 0.35 !important;
	background-color: #7b26c9 !important;
	cursor: no-drop;
}

.autocomplete-menu {
	max-height: 90px;
	overflow-y: auto;
	border: 1px solid #BAC3CE;
	background: white;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
	border-radius: 6px;
	position: fixed;
	left: 0;
	width: 96%;
	z-index: 1000;
	margin-left: 16px;
  }

.autocomplete-input-field {
	width: 670px !important;
	flex: 1;
	border: none;
	padding-right: 32px;
}

.autocomplete-input-container {
	position: relative;
	display: flex;
	align-items: center;
}

.doc-notification-recipients-search-icon {
	position: absolute;
	right: 8px;
	transform: translateY(-30%);
}
