.testDetailViewContainer {
	margin: auto;
	margin-top: 76px;
	position: relative;
	left: 30px;
	min-width: 992px;
	max-width: calc(100vw - 260px);
	min-height: 740px;
	height: 100%;
	border-radius: 5px;
	-webkit-box-shadow: 1px 2px 6px 1px #00000059;
	box-shadow: 1px 2px 6px 1px #00000059;
	background-color: #ffffff;
}

.testDetailLoader {
	background-color: white;
	opacity: 1;
	border-radius: 5px;
	z-index: 1;
}

.test_list_row:hover {
	background-color: #ecf8fd;
}

.detailView {
	width: 920px !important;
	max-width: 920px !important;
	padding-left: 28px;
	margin-top: 16px;
}

.testTag.genes {
	background-color: rgba(159, 220, 245, 0.35);
	border: 1px solid #9fdcf5;
	color: #40b9ec;
}

.test_list_navigator {
	width: 256px;
	height: 20px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #40b9ec;
	margin-top: 16px;
	margin-left: 28px;
	cursor: pointer;
}

.test_field_title.lab_info {
	margin-top: 16px;
	margin-left: 28px;
}

.test_field_detail > div.inputFieldDropdown {
	font-family: 'RionaSans';
}

.testInformation > .formMultiselect > div.el-input > input::placeholder {
	color: #bac3ce;
	-webkit-font-smoothing: antialiased;
}

.test_field_detail.lab_info {
	width: 936px;
	height: 51px;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.test_display_name {
	/* width: 208px; */
	max-width: 90%;
	height: 36px;
	font-family: RionaSans-Bold;
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262837;
	-webkit-font-smoothing: antialiased;
	margin-left: 28px;
	display: flex;
	flex-direction: row;
}

.test_information_limited {
	max-height: 20px;
	max-width: calc(100% - 10px);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	position: relative;
}

.test_information_limitedFullWidth {
	width: 100%;
}

.testInformationComments {
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	white-space: normal;
	-webkit-line-clamp: 4;
	-moz-line-clamp: 4;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 80px;
	max-width: unset;
}

/* .testInformationComments::after {
	content: "...";
	position: absolute;
	bottom: 0px;
	right: -4px;
	font-size: 14px;
	color: #262838;
} */

.test_field_title {
	height: 17px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.test_field_detail {
	height: 20px;
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
}

.test_category_headers {
	margin-top: 32px;
	margin-left: 28px;
	width: 157px;
	height: 22px;
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #262838;
	-webkit-font-smoothing: antialiased;
}

.testInformationContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding: 0px 28px;
}

.testInformation {
	display: flex;
	flex-direction: column;
	min-width: calc(20% - 20px);
	margin-top: 16px;
	position: relative;
	margin-right: 20px;
}

.testDetailViewContainer .hide-lab-integration-field {
	display: none;
}

.testInformationBottomRow {
	min-width: calc(20% - 20px);
	max-width: calc(20% - 20px);
}

.testInformationUneditable {
	height: 32px;
	border-radius: 4px;
	border: 1px solid #bac3ce;
	background-color: #edeff3;
	font-size: 13px;
	font-family: 'RionaSans';
	color: #bac3ce;
	/* padding: 8px 0px 0px 7px; */
	cursor: no-drop;
}

.testInformationUneditableText {
	position: relative;
	top: 8px;
	left: 7px;
}

.testEditRequiredField {
	color: red;
	font-size: 15px;
	font-family: 'RionaSans-Bold';
	top: 0px;
	left: -9px;
	position: absolute;
}

.testButtonRow {
	display: -ms-flexbox;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	padding: 0px 28px;
	position: absolute;
	bottom: 24px;
}

.testCancelButton,
.testSaveButton {
	height: 36px;
	border-radius: 6px;
	font-size: 13px;
	cursor: pointer;
	text-align: center;
	user-select: none;
}

.testCancelButton {
	background-color: white;
	border: 1px solid #7b8291;
	padding-top: 8px;
	width: 70px;
	color: #7b8291;
}

.testCancelButton:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.testCancelButton:active {
	background-color: rgba(123, 130, 145, 0.3);
}

.testSaveButton {
	background-color: #40b9ec;
	padding-top: 10px;
	color: white;
	width: 54px;
}

.testSaveButton:hover {
	background-color: #70cbf1;
}

.testSaveButton:active {
	background-color: #0f80b0;
}

.testSaveButton:focus {
	border: 2px dashed #70cbf1;
}

.testSaveButtonDisabled {
	cursor: no-drop;
	opacity: 0.35;
}

.testSaveButtonDisabled:hover {
	background-color: #40b9ec;
}

.testSaveButtonDisabled:active {
	background-color: #40b9ec;
}

.testSaveButtonDisabled:focus {
	border: none;
}

.testEditTitle {
	color: #525968;
}

.testEditInput {
	width: 172px;
	height: 32px;
	border-radius: 4px;
	font-size: 14px !important;
}

.testEditInput > div.el-input > input.el-input__inner::placeholder {
	opacity: 0.5;
	font-size: 13px !important;
	color: #262837;
}

.testEditTextInputPadded {
	padding-left: 8px;
}

.testEditInput > div.el-date-editor {
	width: unset !important;
	height: 32px !important;
}

.testEditInput > div.el-date-editor > input.el-input__inner {
	height: 32px !important;
}

.testEditInput > div.el-date-editor > input.el-input__inner:hover {
	border: solid 1px #70cbf1 !important;
}

.testEditInput > div.el-date-editor > input.el-input__inner:focus {
	border: solid 1.5px #40b9ec !important;
}

.el-input__icon:hover {
	border: unset;
}

.el-input__icon:focus {
	border: unset;
}

.testEditTextInputSelect {
	border: none !important;
}

.testEditTextInputSelect > div.el-input {
	height: 32px !important;
}

.testEditTextInputSelect > div.el-input > input.el-input__inner {
	height: 32px !important;
	transition: border-color 0.01s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.testEditTextInputSelect > div.el-input > input.el-input__inner:hover {
	border: solid 1px #70cbf1 !important;
}

.testEditTextInputSelect > div.el-input > input.el-input__inner:focus {
	border: solid 1.5px #40b9ec !important;
}

.testEditTextInputSelect > div.el-input > input.el-input__inner::placeholder {
	/* font-size: 14px !important; */
}

.testEditTextInputSelect
	> div.el-select-dropdown
	> div.el-scrollbar
	> div.el-select-dropdown__wrap
	> ul.el-scrollbar__view
	> li.el-select-dropdown__item
	> div {
	font-size: 13px !important;
}

.testCreateMultiselect
	> div.el-select-dropdown
	> div.el-scrollbar
	> div.el-select-dropdown__wrap
	> ul.el-scrollbar__view
	> li.el-select-dropdown__item::after {
	bottom: 10px;
}

div.cancelEditTestAlertTopRow > div > div > svg > g > path {
	fill: #f1c40f;
}

.cancelEditTestAlertIcon {
	position: relative;
	top: 1px;
	width: 28px;
	height: 34px;
}
.inputFieldTextArea-mb-0 {
	margin-bottom: 0 !important;
}
