.self-pay-dialog-container .self-pay-dialog {
	width: 480px;
	min-height: 281px;
	border-radius: 4px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
	background-color: #ffffff;
	-webkit-font-smoothing: antialiased;
}
.self-pay-dialog-container .self-pay-dialog .title {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.38;
	text-align: left;
	color: #262837;
}
.self-pay-dialog-container .self-pay-dialog .el-dialog__header {
	font-family: RionaSans-Bold;
	min-height: 56px;
	border-bottom: solid 1px #edeff3;
	padding: 16px 16px 0;
}
.self-pay-dialog-container .self-pay-dialog .el-dialog__body {
	margin-top: 32px;
	padding: 0px 16px 0px 16px;
	font-family: RionaSans;
}
.self-pay-dialog-container
	.self-pay-dialog
	.el-dialog__body
	.description
	.bold {
	font-weight: bold;
}
.self-pay-dialog-container
	.self-pay-dialog
	.el-dialog__body
	.description
	.bold.pre {
	white-space: pre;
}
.self-pay-dialog-container .self-pay-dialog .dialog-footer {
	padding: 0;
	margin: 46px 0px 40px 0px;
	display: flex;
	justify-content: center;
}
.self-pay-dialog-container .self-pay-dialog .dialog-footer button {
	font-family: RionaSans;
	width: 248px;
	height: 50px;
	border-radius: 100px;
	background-color: #7b26c9;
	border: 1px solid #7b26c9;
}
.self-pay-dialog-container .self-pay-dialog .dialog-footer button:hover {
	background-color: #9c5dd7 !important;
}
.self-pay-dialog-container .self-pay-dialog .dialog-footer button:focus {
	background-color: #7b26c9 !important;
}
.self-pay-dialog-container .self-pay-dialog .dialog-footer button:active {
	background-color: #621ea0 !important;
}
.self-pay-dialog-container .self-pay-dialog .dialog-footer button span {
	font-family: RionaSans-Medium;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.6;
	text-align: center;
	color: #ffffff;
}
