.billing-encount-detail-wrapper {
	margin: 12px 8px;
}

.billing-encount-detail-wrapper .billing-segment-wrapper {
	border: 1px solid #edeff3;
	margin-bottom: 4px;
}

.billing-segment-wrapper .header {
	display: flex;
	align-items: center;
	padding: 12px 8px;
	font: normal bold 15px/1.4 RionaSans-bold;
	text-transform: uppercase;
	color: #262837;
	cursor: pointer;
	height: 44px;
	width: 100%;
}

.billing-encount-detail-wrapper .header .img-edit {
	cursor: pointer;
}

.billing-segment-wrapper .active {
	background-color: #edeff3;
}

.billing-segment-wrapper .body {
	padding: 20px 14px 8px 9px;
}

.billing-segment-wrapper .body .el-row {
	margin-bottom: 24px;
}

.billing-segment-wrapper .patient-fee-section {
	display: flex;
	align-items: flex-end;
}

.billing-encount-detail-wrapper .action-buttons {
	margin-bottom: 0 !important;
}

.billing-encount-detail-wrapper .cancel-button {
	width: 66px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #7b8291;
	font: 500 13px RionaSans-Medium;
	color: #7b8291;
	padding: 9px 12px;
}

.billing-encount-detail-wrapper .cancel-button:hover {
	background-color: rgba(123, 130, 145, 0.15);
}

.billing-encount-detail-wrapper .cancel-button:active {
	background-color: rgba(123, 130, 145, 0.3);
}

.billing-encount-detail-wrapper .save-button {
	width: 55px;
	height: 36px;
	border-radius: 6px;
	background-color: #40b9ec;
	font: 500 13px RionaSans-Medium;
	color: #ffffff;
	padding: 9px 12px;
}

.billing-encount-detail-wrapper .save-button:hover {
	background-color: #70cbf1;
}

.billing-encount-detail-wrapper .save-button:active {
	background-color: #0f80b0;
}

.val-enctr-sgmnt-field.billing-encount-link {
	font: normal 500 14px/1.43 RionaSans-Medium;
	color: #7b26c9;
	text-transform: capitalize;
	cursor: pointer;
}

.val-enctr-sgmnt-field.billing-encount-link:hover {
	text-decoration: underline;
}

.billing-encount-link img,
.val-enctr-sgmnt-field .icon-lab {
	margin-left: 4px;
	width: 15px;
	height: 15px;
}

.billing-segment-wrapper .lbl-enctr-sgmnt {
	font: normal 12px/1.42 RionaSans;
	color: #262837;
	white-space: pre-wrap;
	word-break: break-word;
}

.billing-segment-wrapper .lbl-enctr-sgmnt .action-required {
	margin-bottom: 2px;
	margin-left: 4px;
	height: 12px;
}

.val-enctr-sgmnt-field {
	display: flex;
	align-items: center;
	font: normal 500 14px/1.43 RionaSans-Medium;
	text-transform: capitalize;
	color: #262837;
}

.val-enctr-sgmnt-field .el-input__inner {
	text-transform: capitalize !important;
}

.val-enctr-sgmnt-field > .lab-comments.text-area {
	font-size: 13px;
	font-family: 'RionaSans';
}

.billing-encount-detail-wrapper .val-enctr-sgmnt-field input::placeholder {
	font: normal 13px RionaSans;
	color: #262837;
	opacity: 0.5;
}

/****************************************** 
         patient invoice info segment  
******************************************/

.billing-segment-wrapper .el-date-editor.el-input {
	width: 145px !important;
}

.billing-encount-detail-wrapper .test-number-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.billing-encount-detail-wrapper .test-number-container .test-number {
	flex-grow: 0;
	font-family: RionaSans-Bold;
	-webkit-font-smoothing: antialiased;
	line-height: 20px;
	color: #262837;
	font-size: 14px;
}

.billing-encount-detail-wrapper .test-number-container .divider {
	flex-grow: 1;
	height: 1px;
	background-color: #edeff3;
	margin-left: 8px;
	margin-right: 4px;
}
