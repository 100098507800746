.addressCardContainer {
	border-radius: 4px;
	margin-bottom: 4px;
}

.addressCardMainVariant {
	max-width: 416px;
	min-height: 56px;
	width: 100%;
	padding: 10px 12px;
	border: 1px solid #d8dde4;
	background-color: white;
}

.addressCardSidebarVariant {
	max-width: 313px;
	min-height: 50px;
	padding: 8px;
	border: 1px solid #525968;
}

.addressCardActionable {
	cursor: pointer;
	border: 1px solid #edeff3;
}

.addressCardActionable:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.addressCardActionable:focus {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.addressCardSelected {
	border: 1px solid #7b26c9;
}

.addressCardActionableError {
	border: 1px solid #e74c3c !important;
}

.addressCardErrorText {
	margin-top: 8px;
	width: 100%;
	background-color: #f4a4a5;
	border-radius: 4px;
	padding: 8px 16px;
	height: 36px;
	font-family: 'RionaSans';
	font-size: 14px;
	color: #400404;
}

.addressCardDataContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.addressCardIconAndAddressContainer {
	display: flex;
	flex-direction: row;
	align-content: center;
}

.addressCardRadioSelection {
	height: 39px;
	padding-left: 5px;
	width: 38px;
}

.addressCardAddress {
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
}

.addressCardAddress > div:not(.addressCardAddressTypes) {
	overflow-wrap: anywhere;
}

.addressCardAddressMainVariant {
	font-size: 13px;
	color: #262837;
	padding-top: 2px;
}

.addressCardAddressSidebarVariant {
	font-size: 12px;
	color: white;
	padding-top: 4px;
}

.addressCardAddressSidebarVariantMainAddress {
	white-space: pre-wrap;
}

.addressCardAddressTypes {
	-webkit-font-smoothing: antialiased;
	font-size: 12px;
	color: #7b8291;
}

.addressCardAddressTypesMainVariant {
	font-family: 'RionaSans-Bold';
	text-transform: uppercase;
	max-width: 108px;
	text-align: end;
}

.addressCardAddressTypesSidebarVariant {
	font-family: 'RionaSans-Medium';
	text-transform: capitalize;
}

.addressCardSelectRadioButton {
	position: relative;
	top: 6px;
	left: 1px;
}

.addressCardLocationIcon {
	margin-right: 12px;
	margin-top: 3px;
}

.addressCardLocationIcon.addressCardSidebarVariantIcon {
	position: relative;
	right: 6px;
	margin-right: -2px;
}

.addressCardLocationIcon.addressCardSidebarVariantIcon
	> div
	> svg
	> g
	> circle {
	fill: none;
}

.addressCardLocationIcon.addressCardSidebarVariantIcon > div > svg > g > g {
	fill: white;
}

.addressCardLocationIcon.addressCardSidebarVariantIcon
	> div
	> svg
	> g
	> g
	> path:nth-child(2) {
	fill: white;
}

.addressCardLocationIcon.addressCardSidebarVariantIcon
	> div
	> svg
	> g
	> g
	> circle {
	fill: white;
}

.addressCardCopyIcon {
	padding-right: 5px;
}

.addressCardCopyIcon > div {
	cursor: pointer;
}

.addressCardCopyIcon > div > svg > path:nth-child(2) {
	fill: white;
}

.addressCardRightHalf {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.addressCardRightHalf > div.three-dots-wrapper {
	position: relative;
	top: 2px;
	left: 3px;
}
