.billing-patient-detail-wrapper {
	background-color: #262837;
	padding: 16px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.billing-patient-detail-wrapper .external-patient-id {
	display: flex;
}

.billing-patient-detail-wrapper .patient-name {
	font: normal bold 18px/1.39 RionaSans-Bold;
	color: #ffffff;
}

.billing-patient-detail-wrapper .icon-close {
	cursor: pointer;
	color: #fff;
}

.billing-patient-detail-wrapper .patient-uuid,
.billing-patient-detail-wrapper .external-patient-id,
.billing-patient-detail-wrapper .lbl-state,
.billing-patient-detail-wrapper .lbl-homeaddress {
	font: normal 10px/1.4 RionaSans;
	color: #d8dde4;
}

.billing-patient-detail-wrapper .patient-uuid .copy-icon,
.billing-patient-detail-wrapper .external-patient-id .copy-icon {
	font-size: 10px;
	margin-left: 4px;
	cursor: pointer;
}

.billing-patient-detail-wrapper .dob,
.billing-patient-detail-wrapper .patient-yrs,
.billing-patient-detail-wrapper .biological-sex,
.billing-patient-detail-wrapper .referral-prgm,
.billing-patient-detail-wrapper .patient-email,
.billing-patient-detail-wrapper .patient-phone {
	font: normal 10px/1.4 RionaSans;
	color: #edeff3;
	width: 100% !important;
	min-width: 100%;
}

.billing-patient-detail-wrapper .biological-sex {
	text-transform: capitalize;
}

.billing-patient-detail-wrapper .patient-state,
.billing-patient-detail-wrapper .patient-address {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #ffffff;
}
