.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper .scrorerow,
.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper .countrow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper .scrorerow .lblscore {
	min-width: 70px;
	max-width: 70px;
	text-align: start !important;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.scrorerow.totalcounts {
	display: flex;
	flex-direction: column;
	align-items: unset;
}
.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper .refferalrow {
	margin-top: 27px;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.refferalrow
	.lbltitle {
	width: 52px;
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.refferalrow
	.lblscore {
	font-family: RionaSans-Bold;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.39;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}
.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper .totalrow {
	margin-top: 40px;
}

.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper .totalrow .lbltitle {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.extrnl-dashbrd-graphs-wrapper .encounter-scores-wrapper .totalrow .lblscore {
	font-family: RionaSans-Bold;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.39;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow {
	margin-top: 18px;
	padding-left: 8px;
}

.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow
	.lbltitle {
	font-family: RionaSans-Medium;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: left;
	-webkit-font-smoothing: antialiased;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow.getstarted
	.lbltitle {
	color: #491678;
	width: 110px;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow.ror
	.lbltitle {
	color: #005570;
	width: 70px;
}

.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow.other
	.lbltitle {
	color: #525968;
	width: 50px;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow
	.lblscore {
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: right;
	color: #525968;
	-webkit-font-smoothing: antialiased;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow.getstarted
	.lblscore {
	color: #491678;
}
.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow.ror
	.lblscore {
	color: #005570;
}

.extrnl-dashbrd-graphs-wrapper
	.encounter-scores-wrapper
	.totalcounts
	.countrow.other
	.lblscore {
	color: #525968;
}
