.reject-testrequest-modal-container {
	width: 512px;
	height: min(calc(100% - 114px), 1472px);
	position: absolute;
	left: calc(50% - 225px);
	top: 57px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	color: #262837;
	-webkit-font-smoothing: antialiased;
}

.reject-testrequest-modal-container > div:first-of-type {
	display: flex;
	flex-direction: column;
	height: 100%;
}

/************************************************************
                header  section
*************************************************************/

.reject-testrequest-modal-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 56px;
	width: 100%;
	padding: 17px 16px;
	border-bottom: 1.5px solid #edeff3a2;
}

@media only screen and (max-height: 700px) {
	.reject-testrequest-modal-header {
		padding-right: 30px;
	}
}

.reject-testrequest-modal-header-text {
	font: normal bold 16px/1.38 RionaSans-Bold;
	display: flex;
	align-items: center;
}

.alert-reject-test-head {
	height: 24px;
	width: 24px;
	margin-right: 16px;
}

.reject-testrequest-modal-close {
	transform: scale(1.4);
	height: 26px;
	width: 26px;
	border-radius: 16px;
	position: relative;
	bottom: 2px;
	cursor: pointer;
}

.reject-testrequest-modal-close:hover {
	background-color: #ececec;
}

.reject-testrequest-modal-close:active {
	background-color: #c7c7c7;
}

.reject-testrequest-modal-close > div > div > svg > g > g {
	fill: black !important;
}

/************************************************************
                Body  section
************************************************************/

.reject-testrequest-modal-body {
	overflow-y: auto;
	padding: 32px 16px;
}

.reject-testrequest-modal-body .reject-test-patient-info-container {
	display: flex;
	border-radius: 4px;
	padding: 12px;
	background-color: #edeff3;
}

.reject-testrequest-modal-body .contacts-icon {
	margin-right: 13px;
}

.reject-testrequest-modal-body .patient-info-wrapper {
	display: flex;
	flex-direction: column;
	font: normal 12px/1.42 RionaSans;
}

.reject-testrequest-modal-body .reject-test-patient-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
	margin-bottom: 4px;
}

.reject-testrequest-modal-body .patient-gender {
	text-transform: capitalize;
}

.reject-testrequest-modal-body .refral-prgrm-val {
	font: normal bold 12px/1.42 RionaSans-Bold;
	margin-left: 8px;
}

.reject-testrequest-modal-body .reject-test-info-container {
	border-radius: 8px;
	border: 1px solid #d8dde4;
	margin: 8px 0 26px;
}

.reject-test-info-container .reject-test-info-head {
	border-radius: 8px 8px 0 0;
	background-color: #edeff3;
	width: 100%;
	height: 62px;
	display: flex;
	align-items: center;
	padding: 10px 16px 10px;
}

.reject-test-info-head .icon-lab {
	margin-right: 9px;
}

.reject-test-info-head .reject-test-info-wrapper {
	display: flex;
	flex-direction: column;
}

.reject-test-info-head .test-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.reject-test-info-head .lab-name {
	font: normal 12px/1.42 RionaSans;
	color: #525968;
}

.reject-testrequest-modal-body .lbl-rejecting-reason {
	font: normal 12px/1.42 RionaSans;
}

.reject-testrequest-modal-body .lbl-rejecting-reason::before {
	color: #e74c3c;
	content: '*';
	font: normal 700 12px/1.42 RionaSans;
}

.reject-testrequest-modal-body #rejecting-test-reason {
	height: 79px;
}

.reject-test-info-container .reject-test-info-body {
	display: flex;
	flex-direction: column;
	padding: 16px 50px 24px;
}

.reject-test-info-body .clinical-flag-title {
	font: normal bold 12px/1.42 RionaSans-Bold;
}

.reject-test-info-body .medical-history-content {
	font: italic normal 12px/1.42 RionaSans-Italic;
	color: #7b8291;
}

.reject-test-info-body .scp-content-list {
	margin-top: 8px;
}

.reject-test-info-body .scp-content-list ul {
	margin-bottom: 0;
	padding-left: 15px;
	font: normal 12px/1.67 RionaSans;
}

/***********************************************************
                Footer  section
************************************************************/

.reject-testrequest-modal-footer {
	display: flex;
	justify-content: flex-end;
	height: 60px;
	width: 100%;
	padding: 12px 16px;
	border-radius: 0 0 4px 4px;
	position: relative;
	border-top: 1px solid #edeff3a2;
	color: white;
	background-color: #fff;
	font-size: 13px;
}

@media only screen and (max-height: 700px) {
	.reject-testrequest-modal-footer {
		padding-right: 30px;
	}
}

.reject-testrequest-modal-footer .btn-reject-testrequest.el-button {
	width: 160px;
	height: 36px;
	border-radius: 6.5px;
	background-color: #e74c3c;
	font: normal 500 13px/1.38 RionaSans-Medium;
	text-align: center;
	color: #fff !important;
	border: none;
}

.reject-testrequest-modal-footer .btn-reject-testrequest.el-button:hover {
	font-family: RionaSans-Medium;
	font-weight: 500;
}

.reject-testrequest-modal-footer .btn-reject-testrequest.el-button.is-disabled {
	background-color: #e74c3c !important;
}
