.text-with-tooltip {
	vertical-align: middle;
}

.text-with-tooltip.single-line .el-tooltip__rel > div {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-with-tooltip.multi-line {
	overflow: hidden;
	white-space: unset;
	word-break: break-all;
	/* autoprefixer: off */
	/*
    NOTE: Above comment is intentional and serves purpose to tell bundler to ignore prefixing
          all the rules from this block.
          DO NOTE REMOVE IT!
    REF : https://github.com/postcss/autoprefixer#readme,
          https://stackoverflow.com/questions/33331570/use-inline-style-in-react-with-plain-css-string
  */
}

.text-with-tooltip.single-line.overflowing {
	display: inline-block;
}

.text-with-tooltip.multi-line.overflowing {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
