@use './system';

.tags-component {
	.tags {
		.tag {
			height: min-content;
			border: 1px #edeff3 solid;
			padding: 10px;
			border-radius: 4px;
			.gm-dropdown {
				margin-top: 12px;
			}
			&.selected,
			&.selected:hover {
				border-color: #7b26c9;
			}

			&:hover {
				box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
				border: 1px solid #edeff3;
			}

			.el-checkbox {
				width: 100%;
			}
		}
	}
	.show-hide-button {
		display: block;
		margin-top: system.$halfUnit;
	}
}
