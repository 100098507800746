/* stylelint-disable selector-class-pattern */
/* stylelint-disable max-line-length */
.db-ror-outreach-list {
	min-width: 1204px;
	background-color: #edeff3;
	min-height: 50vh;
	padding: 16px 0 0 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
}

.db-ror-list-loading .el-loading-spinner {
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
}

.db-ror-outreach-list table {
	margin-top: 16px;
}

.db-ror-outreach-list tbody tr {
	transition: box-shadow 0.1s;
}

.db-ror-outreach-list tbody tr:hover {
	cursor: pointer;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
}

.db-ror-outreach-list tbody tr:active {
	cursor: pointer;
	box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
}

.db-ror-outreach-list table td .high-priority {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-left: 8px;
	padding: 4px;
	background-color: #fde776;
	border-radius: 50%;
	vertical-align: middle;
}

.db-ror-outreach-list table td .high-priority::before {
	content: ' ';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #4b3700;
	position: absolute;
}

.db-ror-outreach-list table td .has-guardian {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-left: 8px;
	padding: 4px;
	background-color: #9fdcf5;
	border-radius: 50%;
	vertical-align: middle;
}

.db-ror-outreach-list table td .has-guardian::before {
	content: ' ';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00394b;
	position: absolute;
}

.db-ror-outreach-list table thead tr th:first-child > span,
.db-ror-outreach-list table tbody tr td:not(.no-results):first-child > div {
	min-width: unset !important;
	max-width: 24px !important;
	display: flex;
	justify-content: flex-end;
}

.db-ror-outreach-list
	table
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner {
	border-color: #bfcbd9 !important;
}

.db-ror-outreach-list
	table
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input.is-checked
	> span.el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.db-ror-outreach-list
	table
	label.el-checkbox.chargeICDSearchResultRowCheckbox
	> span.el-checkbox__input
	> span.el-checkbox__inner:hover,
.db-ror-outreach-list table .el-checkbox__input .el-checkbox__inner:hover {
	border-color: #7b26c9 !important;
}

.db-ror-outreach-list table .el-checkbox__input.is-checked .el-checkbox__inner,
.db-ror-outreach-list
	table
	.el-checkbox__input.is-indeterminate
	.el-checkbox__inner {
	border-color: #7b26c9;
	background-color: #7b26c9 !important;
}

.db-ror-outreach-list
	table
	.chargeICDSearchResultRowCheckboxSelected
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}

.db-ror-outreach-list table .el-checkbox__input .el-checkbox__inner::after {
	border: 2px solid white !important;
	border-left: 0 !important;
	border-top: 0 !important;
	height: 9px !important;
	left: 3px !important;
	top: -1px !important;
}

.db-ror-outreach-list table .el-checkbox {
	top: auto;
}

.db-ror-outreach-list table .el-checkbox,
.db-ror-outreach-list table td p {
	margin: unset;
}

.db-ror-outreach-list table td .sub {
	font-family: RionaSans-Medium, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.db-ror-outreach-list table td .capitalize {
	text-transform: capitalize;
}

.db-ror-outreach-list table td .hover:hover {
	cursor: pointer;
	text-decoration: underline;
}

.db-ror-outreach-list table td.no-results span.separator {
	cursor: unset;
	margin: 8px;
	vertical-align: text-bottom;
}

.db-ror-outreach-list table td.no-results span.separator::before {
	content: '.';
	color: #333333;
}

.db-ror-outreach-list table td .overdue-by {
	font: normal 500 12px/1.42 RionaSans-Medium, sans-serif;
}

.db-ror-outreach-list table td .overdue-by-today {
	color: #7b8291;
}

.db-ror-outreach-list table td .overdue-by-1day {
	color: #bc932c;
}

.db-ror-outreach-list table td .overdue-by-2days,
.db-ror-outreach-list table td .overdue-by-3days,
.db-ror-outreach-list table td .overdue-by-4days,
.db-ror-outreach-list table td .overdue-by-5days,
.db-ror-outreach-list table td .overdue-by-6days {
	color: #ad1505;
}

.assign-cc-gca-button {
	width: fit-content;
	height: 36px;
	border-radius: 6.5px;
	background-color: #7b26c9;
	padding: 9px 22px;
	display: flex;
	justify-content: space-between;
	font-family: RionaSans-Medium, sans-serif;
	font-size: 13px;
	color: white;
	margin-right: 16px;
	cursor: pointer;
}

.assign-cc-gca-button:hover {
	background-color: #621ea0;
}

.assign-cc-gca-button.disabled {
	cursor: not-allowed;
	pointer-events: none;
	background-color: #bd92e4;
}
