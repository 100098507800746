.patientCreateComponentContainer {
	width: calc(100% - 60px) !important;
	margin-left: 60px !important;
	margin-top: 46px !important;
	min-height: calc(100vh - 62px);
	position: relative;
}

.patientCreateV2Container {
	position: absolute;
	width: 664px;
	left: calc(50% - 332px);
	top: 32px;
	display: flex;
	flex-direction: column;
	background-color: white;
	color: #262837;
	border-radius: 4px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
	margin-bottom: 32px;
}

.patientCreateV2Spinner {
	position: unset !important;
}

.patientCreateV2Spinner > div:nth-child(1):not(.patientCreateV2TitleRow) {
	background-color: unset !important;
	overflow: auto;
}

.patientCreateV2TitleRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 70px;
	font-size: 24px;
	font-family: 'RionaSans-Bold';
	border-bottom: 2px solid #edeff3;
	padding: 17px 16px;
	-webkit-font-smoothing: antialiased;
}

.patientCreateV2BodyContainer {
	max-height: 100% - 56px;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0px 16px;
}

.patientCreateV2SectionContainer {
	padding: 32px 0px 0px 0px;
	border-bottom: 2px solid #edeff3;
	display: flex;
	flex-direction: row;
}

.patientCreateV2SectionContainerNoBottomBorder {
	border-bottom: none;
}

.patientCreateV2SectionInformationSegment {
	width: 200px;
	margin-right: 16px;
	display: flex;
	flex-direction: column;
}

.patientCreateV2SectionInformationTitle {
	font-family: 'RionaSans-Bold';
	font-size: 14px;
	color: #262837;
	margin-bottom: 4px;
	-webkit-font-smoothing: antialiased;
}

.patientCreateV2SectionInformationDescription {
	font-size: 12px;
	color: #7b8291;
	margin-bottom: 4px;
}

.patientCreateV2SectionInformationRequired {
	font-family: 'RionaSans-Bold';
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
}

.patientCreateV2SectionInformationRequired:last-child {
	margin-bottom: 24px;
}

.patientCreateV2SectionInformationCheckbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
}

.templateTableCheckbox
	> span.el-checkbox__input.is-checked
	> span.el-checkbox__inner {
	border-color: #7b26c9 !important;
}

.templateTableCheckbox
	> span.el-checkbox__input.is-checked
	> span.el-checkbox__inner::after {
	border: 1.7px solid #7b26c9 !important;
	border-left: 0 !important;
	border-top: 0 !important;
}

.templateTableCheckbox
	> span.el-checkbox__input
	> span.el-checkbox__inner::after {
	transition: all 0.5s ease !important;
}

.templateTableCheckbox
	> span.el-checkbox__input
	> span.el-checkbox__inner:hover {
	border-color: #7b26c9 !important;
}

.patientCreateV2SectionInformationCheckboxText {
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: #262837;
	margin-left: 9px;
}

.patientCreateV2SectionInputSegment {
	display: flex;
	flex-direction: column;
}

.patientCreateV2SectionYesNoContainer {
	display: flex;
	flex-direction: column;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: #262837;
	margin-bottom: 24px;
}

.patientCreateV2SectionYesNoContainer .required {
	font-family: 'RionaSans-Bold';
	font-size: 12px;
	color: #e74c3c;
	content: '*';
	margin-left: -4px;
}

.patientCreateV2SectionYesNoChoices {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 152px;
	justify-content: space-between;
	margin-top: 4px;
}

.patientCreateV2SectionYesNoChoice {
	width: 72px;
	height: 32px;
	border-radius: 32px;
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	text-align: center;
	padding-top: 7px;
	user-select: none;
	cursor: pointer;
}

.patientCreateV2SectionYesNoChoiceSelected {
	border: 1px solid #e2d3f2;
	background-color: #e2d3f2;
	color: #491678;
}

.patientCreateV2SectionYesNoChoiceUnselected {
	border: 1px solid #edeff3;
	background-color: white;
	color: #262837;
}

.patientCreateV2SectionYesNoChoice:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.patientCreateV2SectionYesNoChoiceDisabled {
	border: solid 1px #edeff3;
	background-color: #edeff3;
	cursor: no-drop;
	color: #bac3ce;
	pointer-events: none;
}

.patientCreateV2SectionYesNoChoiceDisabled:hover {
	box-shadow: unset !important;
}

.patientCreateV2SectionInputContainer {
	width: 416px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-bottom: 34px;
}

.patientCreateV2SectionInputContainer .provider-linked:not(:last-child) {
	margin-bottom: 4px;
}

.patientCreateComponentContainer .provider-add-another {
	margin-bottom: 34px;
}

.patientCreateV2SectionInputFieldContainer {
	position: relative;
	display: flex;
	flex-direction: column;
}

.patientCreateV2RequiredField {
	font-family: 'RionaSans-Bold';
	font-size: 12px;
	color: #e74c3c;
	position: absolute;
	top: 0px;
	left: -6px;
}

.patientCreateV2InputError {
	font-family: 'RionaSans-Medium';
	font-size: 10px;
	color: #e74c3c;
	width: 200px;
	margin-top: -19px;
}

.patientCreateV2InputAddAnother {
	font-family: 'RionaSans-Medium';
	font-size: 13px;
	color: #7b26c9;
	position: absolute;
	top: 48px;
	left: 1px;
	cursor: pointer;
	margin-top: 1px;
}

.patientCreateV2InputAddAnother:hover {
	text-decoration: underline;
}

.patientCreateV2InputAddAnotherIcons {
	display: flex;
	flex-direction: column;
	height: 32px;
	align-items: center;
	position: absolute;
	top: 12px;
	left: 178px;
}

.patientCreateV2InputAddAnotherIconCheck {
	transform: scale(0.95);
	cursor: pointer;
}

.patientCreateV2InputAddAnotherIconCheckDisabled {
	cursor: no-drop !important;
}

.patientCreateV2InputAddAnotherIconX {
	transform: scale(0.73);
	position: relative;
	bottom: 5px;
	cursor: pointer;
}

.patientCreateV2InputAddAnotherIconX > div > svg > g > g {
	fill: #7b26c9;
}

.patientCreateV2InputAddAnotherIconX > div > svg > g > g:hover,
.patientCreateV2InputAddAnotherIconCheck > div > svg > path:nth-child(2):hover {
	fill: #9c5dd7 !important;
}

.patientCreateV2InputAddAnotherIconX > div > svg > g > g:active,
.patientCreateV2InputAddAnotherIconCheck
	> div
	> svg
	> path:nth-child(2):active {
	fill: #621ea0 !important;
}

.patientCreateV2InputAddAnotherIconCheckDisabled
	> div
	> svg
	> path:nth-child(2),
.patientCreateV2InputAddAnotherIconCheckDisabled
	> div
	> svg
	> path:nth-child(2):hover,
.patientCreateV2InputAddAnotherIconCheckDisabled
	> div
	> svg
	> path:nth-child(2):active {
	opacity: 0.35;
	fill: #7b26c9 !important;
}

.patientCreateV2SectionInputFieldHeader {
	font-size: 12px;
	color: #262837;
	display: flex;
	flex-direction: row;
}

.patientCreateV2SectionInputFieldContainer .radio-group-pill-wrapper {
	margin-top: 4px;
}

.patientCreateV2SectionInputFieldContainer
	.radio-group-pill-wrapper
	.phoneConsentToTextPill {
	padding-bottom: 22px;
}

.patientCreateV2SectionInputFieldSubHeader {
	font-family: 'RionaSans-Medium';
	-webkit-font-smoothing: antialiased;
	color: #7b8291;
}

.inputFieldHeaderInfoIconTooltip {
	position: relative;
	left: 5px;
	top: 1px;
}

.inputFieldHeaderInfoIconTooltip > div.el-tooltip__popper > div > div {
	white-space: pre;
}

.inputFieldHeaderInfoIcon > div > svg > path {
	fill: #7b26c9;
}

.patientCreateV2SectionInputField {
	border-radius: 4px;
	color: #262837;
	margin-bottom: 19px;
	margin-right: 16px;
}

.patientCreateV2SectionInputFieldMargin {
	margin-right: 16px;
}

.patientCreateV2SectionTextInputField {
	height: 32px;
	width: 200px;
	border: 1px solid #bac3ce;
	padding-left: 8px;
	font-size: 13px !important;
}

.patientCreateV2SectionTextInputField:hover {
	border: solid 1px #70cbf1;
}

.patientCreateV2SectionTextInputField:focus {
	border: solid 1.5px #40b9ec;
}

.patientCreateV2SectionInputFieldEdgeFullWidthText {
	width: 416px !important;
}

.patientCreateV2SectionInputFieldEdgeQuarterWidthText,
.patientCreateV2SectionInputFieldEdgeQuarterWidthDropdown > div.el-input {
	width: 92px !important;
}

.patientCreateV2SectionInputFieldEdgeFullWidthSelect > div.el-input {
	width: 416px !important;
}

.patientCreateV2SectionTextInputFieldDisabledFullWidth {
	max-width: 416px !important;
	width: 416px !important;
}

.patientCreateV2SectionTextInputFieldDisabled {
	user-select: none;
	cursor: no-drop;
	opacity: 0.35;
	font-size: 13px;
	padding-top: 7px;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: #edeff3;
}

.patientCreateV2SectionTextInputFieldDisabled:hover {
	border: 1px solid #bac3ce !important;
}

.patientCreateV2SectionInputDisabledDropdown
	> div.el-input.is-disabled
	> i.el-input__icon {
	cursor: no-drop !important;
}

.patientCreateV2SectionInputDatepicker {
	padding-left: 0px;
}

.patientCreateV2SectionInputDatepicker > div.el-input {
	height: 32px !important;
	position: relative;
	right: 1px !important;
	bottom: 1px !important;
	width: 200px !important;
}

.patientCreateV2SectionInputDatepicker > div.el-input > input.el-input__inner {
	height: 32px !important;
}

.patientCreateV2SectionDropdownInputField {
}

.patientCreateV2SectionDropdownInputField > div.el-input {
	height: 32px;
	width: 200px;
}

.patientCreateV2SectionDropdownInputFieldWidth {
	width: 200px;
}

.patientCreateV2SectionDropdownInputFieldWidth div i.el-icon-caret-top {
	pointer-events: none;
}

.patientCreateV2SectionDropdownInputField
	> div.el-input
	> input.el-input__inner {
	font-size: 13px !important;
	height: 32px;
}

.patientCreateV2SectionDropdownInputField
	> div.el-input
	> input.el-input__inner:hover {
	border: solid 1px #70cbf1;
}

.patientCreateV2SectionDropdownInputField
	> div.el-input
	> input.el-input__inner:focus {
	border: solid 1.5px #40b9ec;
}

.patientCreateV2SectionMultiselect > div.el-input > input.el-input__inner {
	min-height: 32px !important;
	max-height: 60px !important;
	overflow-y: auto;
}

.patientCreateV2SectionMultiselect
	> div.el-input
	> input.el-input__inner:hover {
	border: solid 1px #70cbf1;
}

.patientCreateV2SectionMultiselect
	> div.el-input
	> input.el-input__inner:active {
	border: solid 1.5px #40b9ec;
}

.patientCreateV2SectionMultiselect > div.el-select__tags {
	top: unset !important;
	transform: unset !important;
	max-height: 60px;
	overflow-y: auto;
}

.scrollGapColor > div.el-select__tags::-webkit-scrollbar-corner {
	background-color: #bac3ce;
}

.patientCreateV2SectionMultiselect > div.el-select__tags > .el-tag {
	margin: 7px 0px 3px 6px;
}

.patientCreateV2SectionMultiselect > div.el-select__tags > div.el-input {
	max-height: 60px;
}

.patientCreateV2SectionDropdownInputField
	> div.el-input
	> input.el-input__inner::placeholder {
	font-size: 13px !important;
	color: #bac3ce !important;
}

.patientCreateV2SectionInputFieldEdge {
	margin-right: 0px;
}

.patientCreateV2SectionInputFieldShorterHalf {
	width: 182px !important;
}

.patientCreateV2SectionInputField::placeholder {
	color: #bac3ce !important;
	font-size: 13px !important;
}

.patientCreateV2SectionInputFieldDropdownError
	> div.el-input
	> input.el-input__inner,
.patientCreateV2SectionInputFieldTextError {
	border: 1px solid #fb3a2e !important;
	color: #fe5c50 !important;
	background-color: rgba(254, 145, 137, 0.5);
}

.patientCreateV2SectionInputFieldTextError::placeholder,
.patientCreateV2SectionInputFieldDropdownError
	> div.el-input
	> input.el-input__inner::placeholder {
	color: #fe5c50 !important;
	opacity: 1 !important;
}

.patientCreateV2ButtonRow {
	height: 60px;
	border-top: 2px solid #edeff38a;
	padding: 12px 16px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	position: relative;
}

.patientCreateV2Button {
	height: 36px;
	padding-top: 9px;
	width: 160px;
	border-radius: 6px;
	font-family: RionaSans-Medium;
	font-size: 13px;
	text-align: center;
	user-select: none;
	cursor: pointer;
	background-color: #7b26c9;
	color: white;
}

.patientCreateV2Button:hover {
	background-color: #9c5dd7;
}

.patientCreateV2Button:active {
	background-color: #621ea0;
}

.patientCreateV2ButtonDisabled,
.patientCreateV2ButtonDisabled:hover,
.patientCreateV2ButtonDisabled:active {
	background-color: #7b26c9 !important;
	cursor: no-drop !important;
	opacity: 0.35;
}

.duplicatePatientCreateV2Button {
	height: 36px;
	padding-top: 9px;
	width: 160px;
	border-radius: 6px;
	border: 1px solid #7b26c9;
	font-family: RionaSans-Medium;
	font-size: 13px;
	text-align: center;
	user-select: none;
	cursor: pointer;
	background-color: white;
	color: #7b26c9;
}

.duplicatePatientCreateV2Button:hover {
	background-color: rgba(123, 38, 201, 0.15);
}

.duplicatePatientCreateV2Button:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.duplicatePatientTooltipContainer {
	position: absolute;
	width: 368px;
	background-color: white;
	box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	display: column;
	font-family: 'RionaSans-Medium';
	padding: 10px 0px;
	right: 16px;
	bottom: 58px;
	z-index: 1002;
}

.duplicatePatientTooltipTitleRow {
	font-size: 14px;
	color: #262837;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	border-bottom: 2px solid #edeff3;
}

.duplicatePatientTooltipBodyContainer {
	width: 100%;
	max-height: 330px;
	overflow-y: auto;
	padding: 16px;
	padding-bottom: 8px;
	display: flex;
	flex-direction: column-reverse;
}

.duplicatePatientTooltipRecordContainer {
	width: 100%;
	height: 72px;
	border-radius: 4px;
	/* box-shadow: 0px 1px 6px 0px rgba(255, 255, 255, 0.16); */
	border: 1px solid #edeff3;
	background-color: white;
	display: flex;
	flex-direction: row;
	padding: 8px 12px;
	margin-bottom: 4px;
	cursor: pointer;
}

.duplicatePatientTooltipRecordContainer:hover {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.duplicatePatientTooltipRecordInfoContainer {
	width: 90%;
	display: flex;
	flex-direction: column;
}

.duplicatePatientTooltipRecordInfoName {
	font-size: 14px;
	font-family: 'RionaSans-Bold';
	color: #262837;
	-webkit-font-smoothing: antialiased;
	white-space: nowrap;
	max-width: 260px;
	overflow-x: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;
}

.duplicatePatientTooltipRecordInfoEmail {
	white-space: nowrap;
	max-width: 260px;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.duplicatePatientTooltipRecordInfoOther {
	font-size: 12px;
	color: #7b8291;
	font-family: 'RionaSans';
}

.duplicatePatientTooltipRecordLaunchIconContainer {
	width: 10%;
}

.duplicatePatientTooltipRecordLaunchIcon {
	cursor: pointer;
	position: relative;
	top: 17px;
	width: 20px;
	height: 20px;
}

.duplicatePatientTooltipRecordLaunchIcon > div > svg {
	transform: scale(1.1);
}

.duplicatePatientTooltipTail {
	height: 12px;
	width: 12px;
	transform: rotate(45deg);
	background-color: white;
	position: absolute;
	bottom: -6px;
	right: 48px;
}

.duplicatePatientTooltipContainer > div.patient_records_pagination {
	font-size: 14px !important;
	max-width: 315px;
	overflow-x: hidden;
}

.uuidInputContainer {
	position: relative;
	display: flex;
	align-items: center;
}

.inputFieldText.existingDuplicatesInput {
	opacity: 0.55;
	cursor: not-allowed;
	color: rgba(0, 0, 0, 0.35);
	background-color: rgb(237, 239, 243);
	border-color: rgb(186, 195, 206);
	width: 270px;
}

.inputFieldText.pendingDuplicatesInput {
	width: 270px;
}

.updateExistingUuidUndoDelete {
	font-size: 13px;
	color: #7b26c9;
	font-family: RionaSans-Medium;
	margin-left: -6px;
	cursor: pointer;
}

.updateExistingUuidSvgWrapper {
	display: inline-block;
	height: fit-content;
	margin-left: -12px;
	cursor: pointer;
}

.addAnotherUuidButton {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 16px;
}

.addAnotherUuidButton * {
	cursor: inherit;
}

.addAnotherUuidButton label {
	font-size: 13px;
	color: rgb(38, 40, 55);
	font-family: 'RionaSans-Medium';
	margin: 0 8px 0 0;
}

.addAnotherUuidSvgWrapper {
	height: 28px;
	margin: 0 4px 0 -5px;
}

.addAnotherUuidSvgWrapper svg {
	margin-bottom: 10px;
}

.addAnotherUuidSvgWrapper path {
	fill: #7b26c9;
}

.tooltipPositionPatientEmail {
	position: absolute;
	left: 90px;
	top: -4px;
}

.tooltipPositionGuardianEmail {
	position: absolute;
	left: 98px;
	top: -4px;
}

.tooltipPositionPatientEmail > div.el-tooltip__popper.is-dark,
.tooltipPositionGuardianEmail > div.el-tooltip__popper.is-dark {
	top: -38px !important;
	left: -112px !important;
}

.tooltipPositionPatientEmail
	> div.el-tooltip__popper.is-dark
	> div:nth-child(1),
.tooltipPositionGuardianEmail
	> div.el-tooltip__popper.is-dark
	> div:nth-child(1) {
	white-space: pre !important;
}

.tooltipPositionPatientEmail
	> div.el-tooltip__popper.is-dark
	> div.popper__arrow,
.tooltipPositionGuardianEmail
	> div.el-tooltip__popper.is-dark
	> div.popper__arrow {
	left: 111px !important;
}

.patientTagsSwitch {
	margin-top: 8px;
	user-select: none;
	width: fit-content;
}

.patientCreateV2SectionInputFieldContainer
	.checkboxRowContainer.consent-to-text {
	border: unset !important;
	height: unset !important;
	box-shadow: unset !important;
	margin: unset !important;
}
.patientCreateV2SectionInputFieldContainer
	.checkboxRowContainer.consent-to-text
	.checkboxRowMainRow {
	padding: unset;
}
.patientCreateV2SectionInputFieldContainer
	.checkboxRowContainer.consent-to-text
	.checkboxRowMainRow
	.el-checkbox {
	margin-right: 10px !important;
	margin-bottom: unset !important;
}
.patientCreateV2SectionInputFieldContainer
	.checkboxRowContainer.consent-to-text
	.checkboxRowMainRow
	.checkboxRowMainRowLeftLabel {
	padding-bottom: 2px !important;
}
.patientCreateV2SectionInputFieldContainer
	.checkboxRowContainer.consent-to-text
	.checkboxRowMainRow
	.checkboxRowMainRowLeftLabel {
	font-size: 13px;
}
.patientCreateV2SectionInputFieldContainer > .consent-to-text {
	margin-bottom: 24px;
	font-size: 12px;
	color: #525968;
	font-family: 'RionaSans';
}

.is_policyholder {
	padding-bottom: 34px;
}
