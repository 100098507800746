.milestone-warning-container {
	width: 100%;
	min-height: 52px;
	border-radius: 4px;
	background-color: #faedac;
	margin-top: 8px;
}
.milestone-warning-container .milestone-warning-body {
	display: flex;
	justify-content: center;
	padding: 10px 8px 6px 8px;
}
.milestone-warning-container .milestone-warning-body .milestone-warning-text {
	width: calc(100% - 24px);
	min-height: 36px;
	font-family: RionaSans-MediumItalic;
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	text-align: left;
	color: #4b3700;
	padding-left: 8px;
}
.milestone-error-container {
	width: 100%;
	min-height: 52px;
	border-radius: 4px;
	background-color: rgb(244, 164, 165);
	margin-top: 8px;
}
.milestone-error-container .milestone-error-body {
	display: flex;
	justify-content: center;
	padding: 10px 8px 6px 8px;
}
.milestone-error-container .milestone-error-body .milestone-error-text {
	width: calc(100% - 24px);
	min-height: 36px;
	font-family: RionaSans-MediumItalic;
	-webkit-font-smoothing: antialiased;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	text-align: left;
	color: rgb(64, 4, 4);
	padding-left: 8px;
}
