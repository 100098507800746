@use '../../../Common/V3/system';
@use './core';

$width: 900px;
$navbarHeight: 60px;

@mixin threeColumnLayout {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: system.$singleUnit;
}

@mixin twoColumnLayout {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: system.$singleUnit;
}

.#{core.$scopeClass} {
	.scheduleAppointmentV2Container {
		position: absolute;
		top: system.$doubleUnit;
		left: system.$doubleUnit;
		width: 100%;
		display: flex;
		gap: system.$doubleUnit;
		justify-content: center;
		align-items: flex-start;

		.main-form {
			width: 900px;
			flex-shrink: 0;
		}
		.summary {
			position: sticky;
			top: $navbarHeight + system.$doubleUnit;
			width: 377px;
			button {
				width: 100%;
			}
		}
	}
}
