.pillbar {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0.5vw;
	margin-top: 16px;
}
.pillbar > div {
	margin-bottom: 0.5vw;
	margin-right: 0.5vw;
}

.colorwhite {
	color: white !important;
}
.chiplabel {
	font-size: 0.8vw;
	margin-right: 0.1vw;
	font-family: 'RionaSans', serif !important;
}
.chiplablecategoty {
	font-weight: bolder;
	font-family: 'RionaSans', serif !important;
}
.clearpill {
	padding-left: 0.5vw;
	padding-right: 0.5vw;
}

.category_images {
	width: 1.2vw;
	height: 1.2vw;
	margin-left: 0.8vw;
	margin-right: 0.2vw;
}

.category_images.referral_program {
	width: 1.7vw;
	height: 1.7vw;
}
.pill-tag {
	color: white;
	margin: 2.4px;
	background-color: #40b9ec;
	height: 32px;
	cursor: default;
	border: none;
	display: inline-flex;
	outline: none;
	padding: 0 2px 0 2px;
	font-size: 0.8125rem;
	transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-sizing: border-box;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	align-items: center;
	white-space: nowrap;
	border-radius: 16px;
	vertical-align: middle;
	justify-content: center;
	text-decoration: none;
}
.pill-tag > span {
	display: flex;
	align-items: center;
	vertical-align: middle;
}
.pill-tag i {
	margin: 0 4px 0 -8px;
}

.pill-tag .el-icon-close:hover {
	background-color: transparent;
	color: #fff;
}
.pill-tag:hover,
.pill-tag:focus,
.pill-tag:active {
	color: white;
	background-color: #63a0c8;
}
.pill-tag .el-tag .el-icon-close {
	font-size: 14px;
	top: 0px;
}

.category-icon-span {
	margin-right: -5px;
}
.pill-clear-btn {
	color: #40b9ec;
	background-color: white;
	border-radius: 50px;
	border-color: #fff;
	width: 70px;
	height: 35px;
}
.pill-clear-btn:hover {
	color: #63a0c8;
	background-color: white;
	border-color: white;
}
.pill-clear-btn span {
	font-weight: bolder;
	font-family: 'RionaSans', serif !important;
	font-size: 0.8vw;
	padding: 0;
}
