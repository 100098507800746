.unable-stop-outreach-dialog {
	width: 368px !important;
	border-radius: 4px !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35) !important;
	max-height: calc(100vh - 120px);
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%) !important;
}

.unable-stop-outreach-dialog .el-dialog__header {
	padding: 16px !important;
	border-bottom: 1px solid #edeffe;
}

.unable-stop-outreach-dialog .el-dialog__title {
	font: normal bold 16px/1.38 RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.unable-stop-outreach-dialog .el-dialog__title .warning-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.unable-stop-outreach-dialog .el-dialog__body {
	margin: 32px 0;
	padding: 0 16px !important;
	font: normal 12px/1.42 RionaSans-MediumItalic;
	color: #262837;
}

.unable-stop-outreach-dialog .el-dialog__footer {
	padding: 12px 16px;
	border-top: 1px solid #edeffe;
}

.unable-stop-outreach-dialog .el-dialog__footer button {
	height: 36px;
	width: 160px;
	border-radius: 6px;
	font: normal 500 13px/1.38 RionaSans-Medium;
	text-transform: capitalize;
}

.unable-stop-outreach-dialog .el-dialog__footer .btn-continue {
	border: 1px solid #7b26c9;
	background-color: #7b26c9 !important;
	color: #ffffff;
	margin-left: 0 !important;
}

.unable-stop-outreach-dialog .el-dialog__footer .btn-continue:hover {
	background-color: #9c5dd7;
	border-color: 1px solid #9c5dd7 !important;
}

.unable-stop-outreach-dialog .el-dialog__footer .btn-continue:active {
	border: 1px solid #621ea0;
	background-color: #621ea0;
}

.unable-stop-outreach-dialog .el-dialog__footer .btn-continue:focus {
	border: 1px solid #9c5dd7;
	background-color: #9c5dd7 !important;
}
