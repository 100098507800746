@use '../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.header {
		display: flex;
		.placeholder {
			flex-grow: 1;
		}
	}
}
