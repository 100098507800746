.ref-programs-details-container {
	background-color: #b9b2b2;
	border-radius: 6px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	min-height: calc(100vh - 84px);
	width: calc(100vw - 92px);
	margin: 60px 0 0 68px;
	display: flex;
	flex-direction: column;
}

.referral-program-header {
	border-bottom: 2px solid #d8dde4;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	background-color: #ffffff;
}

.ref-programs-details-body {
	display: flex;
	flex: 1;
}

.ref-programslist-backlink {
	display: inline-flex;
	align-items: center;
	padding-top: 16px;
	padding-left: 20px;
	font: normal 12px RionaSans;
	color: #40b9ec;
	cursor: pointer;
}

.referral-program-header h4 {
	width: 416px;
	height: 36px;
	margin-top: 0;
	padding-left: 28px;
	font: bold 18px RionaSans-Bold;
	color: #262837;
}

.left-nav-container {
	float: left;
	width: 21%;
	min-height: 100vh;
	background-color: #edeff3;
}

.left-nav-container ul {
	list-style-type: none;
	margin: 3px 0 0 0;
	padding: 0;
}

.left-nav-container li {
	display: block;
	height: 40px;
	line-height: 34px;
	padding: 10px 0px 10px 28px;
	text-decoration: none;
	font: normal 14px RionaSans;
	color: #525969;
	cursor: pointer;
}

.left-nav-container li.active {
	height: 40px;
	line-height: 34px;
	background-color: rgba(255, 255, 255, 0.62);
	color: #252737;
	font: bold 14px RionaSans-Bold;
	border-left: 8px solid #40b9ec;
	text-indent: -8px;
}

.ref-program-detail-section {
	float: left;
	width: 79%;
	min-height: 100vh;
	padding: 16px 28px 0 28px;
	background-color: #fff;
}

/* start: template archive popup */
.templateModalContainer {
	width: 400px;
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: calc(50% - 200px);
	top: calc(50% - 80px);
	padding: 20px;
}

.templateModalHeaderRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #26273a;
	margin-bottom: 20px;
}

.templateModalDescription {
	font-size: 12px;
	color: #7a8292;
	margin-bottom: 28px;
	width: 100%;
}

.templateModalButtonRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.templateModalCancelButton {
	width: 70px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #7b8291;
	background-color: white;
	cursor: pointer;
	padding-top: 9px;
	padding-left: 12px;
	font-size: 13px;
	color: #7b8291;
}
/* end: template archive popup */
