.logReleaseDocumentContainer {
	width: 368px;
	min-height: 150px;
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 120px);
}
.logReleaseDocumentContainer .logDocumentTopRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 9px 16px;
	min-height: 56px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.logReleaseDocumentContainer .logDocumentTopRow .logDocumentTitle {
	width: 100%;
	font-family: RionaSans-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}
.logReleaseDocumentContainer .logDocumentTopRow .logDocumentClose {
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
}
.logReleaseDocumentContainer
	.logDocumentTopRow
	.logDocumentClose
	.el-dialog__close {
	font-size: 12px;
	font-weight: bold;
}

.logReleaseDocumentContainer .logDocumentBodySection {
	padding: 32px 16px;
	overflow-y: auto;
	max-height: calc(100vh - 240px);
}

.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocumentBodySectionContainer {
	width: 100%;
}

.logReleaseDocumentContainer .logDocumentBodySection .document-card {
	padding: 12px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	display: flex;
	flex-direction: row;
}

.logReleaseDocumentContainer
	.logDocumentBodySection
	.document-card
	.doc-icon-container {
	margin-right: 12px;
	padding-top: 5px;
}

.logReleaseDocumentContainer
	.logDocumentBodySection
	.document-card
	.doc-details-container
	.doc-title {
	font-family: RionaSans-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.document-card
	.doc-details-container
	.doc-type {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b8291;
	text-transform: capitalize;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.document-card
	.doc-details-container
	.download-link
	span {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #7b26c9;
	cursor: pointer;
}

.logReleaseDocumentContainer .logDocumentBodySection .logDocBodysectioncol {
	margin-bottom: 19px;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocBodysectioncol:first-of-type {
	margin-bottom: 24px;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocBodysectioncol
	.logDocinputcontainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 19px;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocBodysectioncol
	.logDocinputcontainer
	.logDocInputHeaderContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocBodysectioncol
	.logDocinputcontainer.isReadonly {
	cursor: not-allowed;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocBodysectioncol
	.logDocinputcontainer.isReadonly
	.logDocInputElementContainer {
	pointer-events: none;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocBodysectioncol
	.logDocinputcontainer
	.logDocInputHeaderContainer
	.required {
	text-indent: -6px;
	font-family: RionaSans;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #e74c3c;
}
.logReleaseDocumentContainer
	.logDocumentBodySection
	.logDocBodysectioncol
	.logDocinputcontainer
	.logDocInputHeaderContainer
	.logDocInputHeader {
	font-family: RionaSans;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.logDocinputcontainer .logDocInputElementContainer .el-select {
	width: 100%;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.el-input
	> input.el-input__inner,
.logDocinputcontainer .logDocInputElementContainer input {
	height: 32px !important;
	width: 100% !important;
	font-size: 13px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
	font-family: 'RionaSans';
	border-radius: 4px;
	border: solid 1px #bac3ce;
	padding: 3px 10px;
}
.logDocinputcontainer .logDocInputElementContainer input::placeholder,
.logDocinputcontainer
	.logDocInputElementContainer
	input::-webkit-input-placeholder {
	color: #bac3ce !important;
	font-family: RionaSans-Medium;
	font-size: 13px !important;
}

.logDocinputcontainer
	.logDocInputElementContainer
	.el-input
	> input.el-input__inner:hover,
.logDocinputcontainer .logDocInputElementContainer input:hover {
	border: solid 1px #70cbf1;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.el-input
	> input.el-input__inner:active,
.logDocinputcontainer .logDocInputElementContainer input:active {
	border: solid 1.5px #40b9ec;
}

.logDocinputcontainer
	.logDocInputElementContainer
	.el-input.is-disabled
	> input.el-input__inner,
.logDocinputcontainer
	.logDocInputElementContainer
	.el-input.is-disabled
	> input.el-input__inner:active,
.logDocinputcontainer
	.logDocInputElementContainer
	.el-input.is-disabled
	> input.el-input__inner:active {
	border: solid 1px #bac3ce !important;
	background-color: #edeff3 !important;
	color: #bac3ce !important;
}

.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item {
	max-width: 335px;
	padding: 8px;
	height: auto;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item.selected.hover,
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item:hover,
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item:active,
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item:focus {
	background-color: #edeff3;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-item
	.pro-info {
	display: flex;
	flex-direction: column;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-item
	.pro-info
	.pro-name {
	font: bold 14px/1.38 RionaSans-Bold;
	color: #262837;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-item
	.pro-info
	.pro-location {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #262837;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	ul
	li.el-select-dropdown__item
	.pro-wrapper
	.pro-specialty {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b8291;
}
.logDocinputcontainer
	.logDocInputElementContainer
	.ext-provider-filter
	> div.el-input
	> input.el-input__inner {
	font: normal 13px/1.38 RionaSans !important;
	color: #262837 !important;
}

.logReleaseDocumentContainer .logDocumentFooterSection {
	display: flex;
	padding: 12px 16px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	justify-content: flex-end;
	height: 60px;
}

.logReleaseDocumentContainer .logDocBodysectioncol .note {
	padding-top: 24px;
	font-family: RionaSans-MediumItalic;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: #262837;
}

.logReleaseDocumentContainer .logDocumentFooterSection .logreleasebtn,
.logReleaseDocumentContainer
	.logDocumentFooterSection
	.el-button.is-disabled:hover {
	border: 1px solid #7b26c9;
	color: #ffffff;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
}

.logReleaseDocumentContainer .logDocumentFooterSection .logreleasebtn:hover {
	background-color: #9c5dd7 !important;
	border: solid 1px #9c5dd7 !important;
}

.logReleaseDocumentContainer
	.logDocumentFooterSection
	.el-button.is-disabled:hover {
	background-color: #7b26c9 !important;
}

.logReleaseDocumentContainer .logDocumentFooterSection .logreleasebtn:active {
	border: #7b26c9;
	border: 1px solid #7b26c9;
	color: #ffffff;
}

.logReleaseDocumentContainer .logDocumentFooterSection .logreleasebtn {
	border-radius: 6.5px;
	background-color: #7b26c9 !important;
	width: 160px;
	height: 36px;
	text-align: center;
}

.logReleaseDocumentContainer
	.logDocumentFooterSection
	.logreleasebtn.is-disabled {
	border-radius: 6.5px;
	background-color: #7b26c9 !important;
	width: 160px;
	height: 36px;
	text-align: center;
}
