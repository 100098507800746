.releaseNotesContainer {
	min-width: 1204px;
	background-color: white;
	min-height: calc(100vh - 46px);
	padding: 0px 0px 0px 60px;
	display: flex;
	flex-direction: column;
	margin: 46px auto;
	margin-bottom: 0px;
}

.releaseNotesBanner {
	overflow: hidden;
	width: calc(100vw - 60px);
	height: 120px;
	background-color: #621ea0;
	color: white;
	text-align: center;
	-webkit-font-smoothing: antialiased;
}

.releaseNotesBannerTitle {
	font-family: 'RionaSans-Medium';
	font-size: 32px;
	padding-top: 20px;
}

.releaseNotesBannerSubtitle {
	font-family: 'RionaSans';
	font-size: 24px;
}

.releaseNotesMainContainer {
	width: 70vw;
	max-width: 1024px;
	margin: auto;
	margin-top: 0px;
	display: flex;
	flex-direction: column;
}

.releaseNotesFilterRow {
	height: 84px;
	margin-left: auto;
	padding: 24px 0px;
}

.releaseNotesSprintContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	border-top: 1px solid #d8dde4;
	padding-top: 32px;
}

.releaseNotesSprintDate {
	font-family: 'RionaSans-Medium';
	font-size: 18px;
	color: #262837;
	width: 284px;
	min-width: 284px;
}

.releaseNotesSprintItemContainer:not(:first-child) {
	margin-top: 12px;
}

.releaseNotesSprintItemsContainer,
.releaseNotesSprintItemContainer,
.releaseNotesSprintOtherContainer,
.releaseNotesSprintOtherList {
	display: flex;
	flex-direction: column;
}

.releaseNotesSprintItemHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 11px;
}

.releaseNotesSprintItemHeaderText {
	font-family: 'RionaSans-Medium';
	font-size: 28px;
	color: #262837;
	margin-right: 16px;
}

.releaseNotesSprintItemSubheaderText {
	font-family: 'RionaSans-Medium';
	font-size: 22px;
	color: #262837;
	margin-bottom: 16px;
}

.releaseNotesSprintItemMedia {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 800px;
}

.releaseNotesSprintItemMedia > img {
	max-width: 740px;
	margin-bottom: 24px;
	cursor: pointer;
}

.releaseNotesSprintItemDescription {
	font-family: 'RionaSans';
	font-size: 16px;
	color: #262837;
	margin-bottom: 28px;
	white-space: pre-line;
}

.releaseNotesSprintOtherContainer {
	margin-top: 4px;
}

.releaseNotesSprintOtherListItem {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.releaseNotesSprintItemMedia > div > div.el-dialog__wrapper > div.el-dialog {
	width: unset;
}

.releaseNotesSprintItemMedia
	> div
	> div.el-dialog__wrapper
	> div.el-dialog
	> div.el-dialog__body {
	padding-top: 5px;
}

.releaseNotesSprintItemMedia
	> div
	> div.el-dialog__wrapper
	> div.el-dialog
	> div.el-dialog__body
	> div
	> img.releaseNotesSprintItemDialogImage {
	max-width: 95vw;
	max-height: 95vh;
}

.sublist-item{
	font-family: 'RionaSans-Medium';
	color: #262837;
}

.sublist-item-description{
	white-space: pre-wrap;
}
