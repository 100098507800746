@use '../../../../Common/V3/system';
@use '../core';

.#{core.$scopeClass} {
	.external-care-team {
		h2 {
			margin-bottom: 0px;
		}
		.linked-providers {
			@include core.twoColumnLayout;
			margin-bottom: system.$singleUnit;
		}
	}
}
