.search-patient-container {
	margin-right: 16px !important;
	position: relative;
}

.search-patient-container > .search-field {
	width: 328px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #d8dde4;
	transition: width 0.2s ease-out;
	display: flex;
}

.search-patient-container > .search-field > .el-input__inner {
	border: unset;
	background-color: #edeff3;
	height: 30px;
	padding: 8px 0 7px 12px;
}

.search-patient-container > .search-field > .el-input__inner,
.search-patient-container > .search-field > .el-input__inner::placeholder,
.search-patient-container
	> .patients-list-container
	> .list
	> .patient-detail
	> div.email-dob
	> span:first-child {
	font-family: RionaSans-Medium;
	font-size: 12px !important;
	font-weight: 500;
	line-height: 1.42;
	color: #262837;
}

.search-patient-container > .search-field > .el-input__inner::placeholder {
	color: #7b8291;
	opacity: 1;
}

.search-patient-container > .search-field > .el-input-group__append {
	height: 30px;
	width: 64px;
	border: unset;
	background-color: #edeff3;
	padding: unset;
	display: flex;
}

.search-patient-container > .search-field > .el-input-group__append > * {
	height: 30px;
	width: 32px;
	padding: unset;
	margin: unset;
	border: unset;
	display: flex;
	justify-content: center;
	align-items: center;
}

.search-patient-container
	> .search-field
	> .el-input-group__append
	> .el-button {
	border-right: 1px solid #d8dde4;
	height: 30px;
	border-radius: unset;
}

.search-patient-container
	> .search-field
	> .el-input-group__append
	> .el-tooltip:hover {
	background-color: #fff;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.search-patient-container
	> .search-field
	> .el-input-group__append
	> .el-tooltip:hover
	path {
	fill: #7b26c9 !important;
	stroke: #7b26c9 !important;
}

.search-patient-container
	> .search-field
	> .el-input-group__append
	> .el-tooltip
	div,
.search-patient-container
	> .search-field
	> .el-input-group__append
	> .el-button
	div,
.search-patient-container
	> .search-field
	> .el-input-group__append
	> .el-button
	span {
	height: max-content;
	width: max-content;
}

.search-patient-container
	> .search-field
	> .el-input-group__append
	.el-tooltip
	button {
	margin: unset;
	padding: unset;
}

.search-patient-container.has-focus > .search-field,
.search-patient-container.has-value > .search-field {
	width: 400px;
}

.search-patient-container.has-focus > .search-field > *,
.search-patient-container.has-value > .search-field > * {
	background-color: #fff;
}

.search-patient-container.has-focus > .search-field {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3) !important;
}

.search-patient-container.has-value > .search-field {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
}

.search-patient-container > .patients-list-container {
	width: 400px;
	margin-top: 4px;
	border-radius: 4px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
	background-color: #fff;
	text-align: left;
}

.search-patient-container > .patients-list-container > .list {
	padding: 4px 0;
	max-height: 400px;
	overflow: auto;
	position: relative;
}

.search-patient-container > .patients-list-container > .list > div {
	min-height: 53px;
	padding: 8px;
}

.search-patient-container
	> .patients-list-container
	> .list
	> .patient-detail:first-of-type {
	border-top: solid 1px #edeff3;
}

.search-patient-container > .patients-list-container > .list > .patient-detail {
	border-bottom: solid 1px #edeff3;
}

.search-patient-container
	> .patients-list-container
	> .list
	> .patient-detail:hover {
	cursor: pointer;
	background-color: #edeff3;
}

.search-patient-container
	> .patients-list-container
	> .list
	> .patient-detail
	> div {
	display: flex;
	justify-content: space-between;
}

.search-patient-container
	> .patients-list-container
	> .list
	> .patient-detail
	> div.name-tags
	> span:first-child {
	font-family: RionaSans-Medium;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	color: #262837;
}

.search-patient-container
	> .patients-list-container
	> .list
	> .patient-detail
	> div.email-dob
	> span:last-child,
.search-patient-container
	> .patients-list-container
	> .list
	> .patient-detail
	> div.highlight {
	font-family: RionaSans-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.42;
	color: #7b8291;
}

.search-patient-container > .patients-list-container > .search-in-patient {
	height: 42px;
	padding-left: 8px;
	display: flex;
	align-items: center;
	font-family: RionaSans-Medium;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.38;
	color: #7b26c9;
	cursor: pointer;
	border-top: solid 1px #edeff3;
}

.search-patient-container > .patients-list-container > .search-in-patient:hover,
.search-patient-container
	> .patients-list-container
	> .search-in-patient:active {
	text-decoration: underline;
}

.scrollbox {
	background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		radial-gradient(
			farthest-side at 50% 0,
			rgba(0, 0, 0, 0.2),
			rgba(0, 0, 0, 0)
		),
		radial-gradient(
				farthest-side at 50% 100%,
				rgba(0, 0, 0, 0.2),
				rgba(0, 0, 0, 0)
			)
			0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	background-attachment: local, local, scroll, scroll;
}

.search-patient-container .adv-search-text {
	height: 17px;
	font-family: RionaSans;
	-webkit-font-smoothing: antialiased;
	font-size: 12px;
	line-height: 1.42;
	text-align: left;
	color: #ffffff;
	white-space: nowrap !important;
}
