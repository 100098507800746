.patientCreateOutreachModalContainer {
	max-height: calc(100vh - 120px);
	position: fixed !important;
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	width: 369px;
	border-radius: 4px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
	background-color: white;
}

.patientCreateOutreachModalContainerLoader
	> div:nth-child(1):not(.patientCreateOutreachModalTopRow):not(.patientCreateOutreachModalBody) {
	background-color: rgba(255, 255, 255, 1) !important;
}

.patientCreateOutreachModalContainerLoader {
	height: 100%;
}

.patientCreateOutreachModalTopRow {
	width: 100%;
	height: 56px;
	padding: 9px 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #edeff3;
	width: 100%;
}

.patientCreateOutreachModalBody {
	padding: 32px 16px;
	width: 100%;
	max-height: calc(100vh - 180px);
	overflow-y: auto;
}

.patientCreateOutreachModalPatientDetailContainer {
	background-color: #edeff3;
	border-radius: 4px;
	width: 100%;
	padding: 12px;
	font-size: 13px;
	color: #262837;
	margin-bottom: 32px;
}

.referral-outreach-modal-patient-detail-wrapper,
.referral-outreach-modal-referral-detail-wrapper,
.referral-outreach-modal-encntr-detail-wrapper,
.referral-outreach-modal-test-detail-wrapper {
	width: 100%;
	display: flex;
}

.referral-outreach-modal-patient-detail-wrapper,
.referral-outreach-modal-encntr-detail-wrapper,
.referral-outreach-modal-test-detail-wrapper {
	margin-bottom: 12px;
	padding-bottom: 12px;
}

.referral-outreach-modal-patient-detail-wrapper:last-child {
	padding-bottom: 0px;
}

.referral-outreach-modal-referral-program-wrapper {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	padding: 8px 0px 12px;
}

.crt-outrh-modal-pill-wrapper {
	display: flex;
}

.referral-outreach-modal-patient-detail-wrapper .priority-pill-wrapper {
	height: 24px;
	border-radius: 24px;
	text-align: center;
	font: normal 13px/1.42 RionaSans-Bold;
	color: #4b3700;
	margin-bottom: 8px;
	padding: 3px 12px;
	background-color: #fde776;
	width: fit-content;
	margin-right: 4px;
}

.referral-outreach-modal-patient-detail-wrapper .guardian-pill-wrapper {
	height: 24px;
	border-radius: 24px;
	text-align: center;
	font: normal 13px/1.42 RionaSans-Bold;
	color: #00394b;
	margin-bottom: 8px;
	padding: 3px 12px;
	background-color: #9fdcf5;
	width: fit-content;
}

.referral-outreach-modal-patient-detail-wrapper,
.referral-outreach-modal-encntr-detail-wrapper,
.referral-outreach-modal-test-detail-wrapper,
.referral-outreach-modal-referral-program-wrapper {
	border-bottom: 1px solid #d8dde4;
}

.referral-outreach-modal-patient-detail-wrapper:last-child {
	border-bottom: none;
}

.referral-outreach-modal-patient-detail-wrapper .person-icon,
.referral-outreach-modal-patient-detail-wrapper .patient-icon,
.referral-outreach-modal-referral-detail-wrapper .info-icon,
.referral-outreach-modal-encntr-detail-wrapper .visit-icon,
.referral-outreach-modal-test-detail-wrapper .test-icon {
	margin-right: 11px;
	position: relative;
	bottom: 2px;
}

.referral-outreach-modal-encntr-detail-wrapper .visit-icon svg g g {
	fill: #7b8291;
}

.referral-outreach-modal-patient-detail-wrapper .outreach-modal-patient-detail,
.referral-outreach-modal-referral-detail-wrapper
	.outreach-modal-referral-detail,
.referral-outreach-modal-encntr-detail-wrapper .outreach-modal-encntr-detail,
.referral-outreach-modal-test-detail-wrapper .outreach-modal-test-detail {
	display: flex;
	flex-direction: column;
	font: normal 12px/1.42 RionaSans;
}

.referral-outreach-modal-test-detail-wrapper
	.outreach-modal-test-detail
	.test-name {
	font: normal bold 14px/1.43 RionaSans-Bold;
}

.referral-outreach-modal-patient-detail-wrapper
	.outreach-modal-patient-detail
	.patient-email {
	width: 100%;
	word-break: break-all;
}

.outreach-patient-name {
	font: normal 14px/1.42 RionaSans-Bold;
	text-transform: capitalize;
	cursor: pointer;
	color: #7b26c9;
}

.outreach-patient-name-no-link {
	font: normal 14px/1.42 RionaSans-Bold;
	text-transform: capitalize;
	color: #262837;
}

.outreach-patient-name:hover {
	text-decoration: underline;
}

.patientCreateOutreachModalSelectContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.patientCreateOutreachModalOutcomeRow {
	width: 100%;
	padding: 12px 10px 12px 12px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	border-radius: 4px;
	border: solid 1px #edeff3;
	background-color: white;
	margin-bottom: 4px;
	cursor: pointer;
}

.patientCreateOutreachModalOutcomeRow:hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
}

.patientCreateOutreachInputContainer {
	position: relative;
	display: flex;
	flex-direction: column;
}

.requiredInputField {
	position: absolute;
	top: 0px;
	left: -5px;
	font-family: 'RionaSans-Bold';
	font-size: 12px;
	color: #e74c3c;
}

.patientCreateOutreachTextarea {
	width: 100%;
	font-size: 13px;
	height: 84px;
	padding: 7px 8px;
	display: block;
	resize: none;
	padding: 5px 7px;
	line-height: 1.5;
	width: 100%;
	color: #1f2d3d;
	background-color: #fff;
	background-image: none;
	border: 1px solid #bfcbd9;
	border-radius: 4px;
	transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.patientCreateOutreachTextarea:hover {
	border-color: #70cbf1;
}

.patientCreateOutreachTextarea:active,
.patientCreateOutreachTextarea:focus {
	border-color: #00baf1;
	outline: none;
}

.patientCreateOutreachButtonRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 14px;
}

.patientCreateOutreachAddBackButton {
	border: 1px solid #7b26c9;
	background-color: #fff;
	text-align: center;
	user-select: none;
	display: grid;
	font-family: RionaSans-Medium;
	font-size: 13px;
	color: #7b26c9;
	height: 36px;
	width: 160px;
	border-radius: 6.5px;
	align-items: center;
	cursor: pointer;
	margin-right: 16px;
}

.patientCreateOutreachAddBackButton:hover {
	background-color: rgba(123, 38, 201, 0.15);
}

.patientCreateOutreachAddBackButton:active {
	background-color: rgba(123, 38, 201, 0.3);
}

.patientCreateOutreachAddConfirmButton {
	background-color: #7b26c9;
	text-align: center;
	user-select: none;
	display: grid;
	font-family: RionaSans-Medium;
	font-size: 13px;
	color: white;
	height: 36px;
	width: 160px;
	border-radius: 6.5px;
	align-items: center;
	cursor: pointer;
}

.patientCreateOutreachAddConfirmButton:hover {
	background-color: #9c5dd7;
}

.patientCreateOutreachAddConfirmButton:active {
	background-color: #621ea0;
}

.patientCreateOutreachAddConfirmButtonDisabled {
	background-color: #7b26c9 !important;
	opacity: 0.35 !important;
	cursor: no-drop !important;
}

.referral-outreach-modal-referral-detail-wrapper .refral-detail-field-val {
	font: normal bold 12px/1.42 RionaSans-Bold;
	text-align: left;
	color: #262837;
	margin-left: 6px;
}
