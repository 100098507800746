.appointment-summary {
	display: flex;
	justify-content: space-between;
	border: 1px #0099d1 solid;
	border-radius: 8px;
	padding: 8px 24px 8px 24px;
	font-size: 16px;
	font-family: RionaSans-Medium;
	font-weight: 490;

	& > div {
		display: flex;
		align-items: center;
	}

	.with-image {
		gap: 8px;
		img {
			height: 24px;
		}
	}
}
