.external-care-team-wrapper .external-care-team {
	max-width: 313px;
	padding: 8px;
	border-radius: 4px;
	border: solid 1px #525968;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.external-care-team-wrapper:not(:last-child) {
	margin-bottom: 4px;
}
.external-care-team-wrapper .external-care-team .provider {
	display: flex;
}
.external-care-team-wrapper
	.external-care-team
	.provider
	.icon
	.stethoscope
	svg
	path.GMIStethoscopeIcon {
	fill: #ffffff;
}
.external-care-team-wrapper
	.external-care-team
	.provider
	.icon
	.stethoscope
	svg
	path.cls-1 {
	fill: none !important;
}
.external-care-team-wrapper .external-care-team .provider .info {
	margin-left: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.external-care-team-wrapper .external-care-team .provider .info .provider-name {
	font: bold 14px/1.43 RionaSans-Bold;
	color: #ffffff;
	max-width: 180px;
}
.external-care-team-wrapper .external-care-team .provider .info .link {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #7b8291;
}
.external-care-team-wrapper .external-care-team .provider .info .link.red {
	color: #e74c3c;
}
.external-care-team-wrapper .external-care-team .provider .info .location {
	font: normal 500 12px/1.42 RionaSans-Medium;
	color: #ffffff;
}
.external-care-team-wrapper .external-care-team .designation {
	font: bold 12px/1.25 RionaSans-Bold;
	text-align: right;
	color: #7b8291;
}
