@use '../../../Common/V3/system';
@use 'core';

.#{core.$scopeClass} {
	.patientCreateV3Container {
		position: absolute;
		width: core.$width;
		left: calc(50% - core.$width/2);
		top: system.$doubleUnit;
		display: flex;
		flex-direction: column;
	}
}
