.testManagementContainer {
	min-height: calc(100vh - 84px);
	background-color: #edeff3;
	border-radius: 6px;
	padding: 16px 0 0 60px;
	margin: 46px auto;
	min-width: 1204px;
}

.testManagementTopRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
}

.testManagementContainer .custom-table .test-management-table {
	margin-top: 16px;
}

.testManagementContainer .custom-table .test-management-table .test-tag div {
	max-height: inherit;
	padding-top: 2px;
}

.addTestButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #7b26c9;
	margin-right: auto;
	width: 194px;
	height: 36px;
	color: #ffffff;
	padding-left: 8px;
	border-radius: 5px;
	cursor: pointer;
	font: normal 500 13px RionaSans-Medium;
}

.addTestButton:hover {
	background-color: #9c5dd7;
}

.addTestButton:active {
	background-color: #621ea0;
}

.error-no-test-result {
	margin-top: 50px;
	margin-left: 20px;
	text-align: center;
}

/*
 * start: styles for test filter dropdowns, paginations and add new test type
 */

.testManagementTopRow .el-input,
.testManagementTopRow .el-input__inner::placeholder,
.testManagementTopRow .el-select-dropdown li.el-select-dropdown__item {
	font: normal 500 13px RionaSans-Medium;
	color: #525968;
}

.testManagementTopRow
	.el-select.test-filter-dropdown:hover
	.el-input
	.el-input__inner {
	border-color: #70cbf1;
}

.testManagementTopRow li.el-select-dropdown__item.selected {
	color: #fff !important;
}

.testManagementTopRow li.el-select-dropdown__item.selected.hover {
	color: #fff;
}

.testManagementTopRow
	.el-select.test-filter-dropdown
	.el-scrollbar
	.el-scrollbar__bar.is-vertical {
	opacity: 1;
}

.testManagementTopRow .el-select-dropdown .el-select-dropdown__wrap {
	max-height: 256px;
}

.testManagementTopRow .el-input__inner {
	padding: 10px 35px 10px 7px;
	border-radius: 6px;
	border: 1px solid #d8dde4;
	background-color: #fff;
}

.testManagementTopRow .el-select-dropdown li.el-select-dropdown__item {
	color: #525968;
}

/* .testManagementTopRow li.el-select-dropdown__item.hover {
  color: #000;
} */

/*
 * end: styles for test filter dropdowns, paginations and add new test type
 */

.cellText {
	font-size: 14px !important;
	color: #525968 !important;
	font-family: 'RionaSans' !important;
}

.testTagContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 15vw;
}

.testTagContainerDetails {
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
}

.testTag {
	padding: 0px 8px;
	background-color: rgba(159, 220, 245, 0.35);
	border: 1px solid #9fdcf5;
	border-radius: 4px;
	/* height: 19px; */
	line-height: 15px;
	font-size: 11px;
	color: #7b26c9;
	margin-right: 4px;
	margin-bottom: 4px;
	border: solid 1px #bd92e4;
	background-color: #bd92e459;
}

.testTagEditSelected,
.testTagEdit {
	cursor: pointer;
}

.testTagEdit {
	padding: 2px 8px;
	background-color: rgba(159, 220, 245, 0.35);
	border: 1px solid #9fdcf5;
	border-radius: 4px;
	height: 19px;
	line-height: 15px;
	font-size: 11px;
	color: #7b26c9;
	margin-right: 4px;
	margin-bottom: 4px;
	border: solid 1px #bd92e4;
	background-color: rgba(189, 146, 228, 0.35);
	opacity: 0.4;
}

.testTagEdit:hover {
	opacity: 0.8;
}

.testTagEdit:focus {
	opacity: 1;
	background-color: rgba(168, 83, 222, 0.35);
}

.testTagEditSelected {
	padding: 2px 8px;
	border: 1px solid #9fdcf5;
	border-radius: 4px;
	height: 19px;
	line-height: 15px;
	font-size: 11px;
	color: #7b26c9;
	margin-right: 4px;
	margin-bottom: 4px;
	border: solid 1px #bd92e4;
	background-color: rgba(189, 146, 228, 0.35);
	opacity: 1;
}

.loaderRow {
	height: 50px;
	border-bottom: 2px solid #d8dde4a1;
	padding-bottom: 2px;
}

.loaderTestManagement {
	position: relative;
	top: calc(50vh - 80px);
}

.fullScreenDimmer {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.35);
	z-index: 100000;
}

.addTestContainer {
	display: flex;
	flex-direction: column;
	width: 694px;
	overflow-y: auto;
	background-color: #ffffff;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
	border: 1px solid rgba(163, 179, 205, 0.64);
	padding: 24px;
	position: fixed !important;
	max-height: calc(100vh - 120px);
	top: 50% !important;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.addTestLoader {
	position: absolute;
	top: calc(50% - 336px);
	left: calc(50% - 347px);
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
	padding: 24px;
	opacity: 1;
}

.addTestLoader > div:nth-child(1) {
	background-color: white !important;
}

.addTestHeader {
	display: flex;
	flex-direction: row;
	/* align-items: center; */
	font-size: 20px;
	color: #a4b0bd;
	margin-bottom: 24px;
}

.addTestSubheader,
.addTestSubheaderArrow,
.addTestSubheaderSelected {
	margin-right: 8px;
}

.addTestSubheaderArrow > div > div > svg > g > g {
	fill: #a4b0bd;
}

.addTestSubheaderSelected {
	font-family: 'RionaSans-Bold';
	color: #262838;
}

.testInformationNew {
	margin-bottom: 20px;
}

.testInformationNewEnd {
	margin-right: 0px;
}

.addTestContainer .hide-lab-integration-field {
	display: none;
}

.testEditInputWide {
	width: 100%;
}

.testCreateInput {
	width: 100%;
}

.testCreateHeaders {
	width: 100%;
	margin: 0px 0px 4px 0px;
}

.addTestButtonRow {
	display: flex;
	flex-direction: row;
	/* width: calc(100% - 48px); */
	width: 100%;
	justify-content: space-between;
	/* position: absolute; */
	bottom: 20px;
}

.cancelEditTestAlertBackground {
	width: 100vw;
	height: 100vw;
	background-color: rgba(0, 0, 0, 0.35);
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 100000;
	border-radius: 5px;
}

.cancelEditTestAlertContainer {
	height: 197px;
	width: 400px;
	position: absolute;
	top: calc(50vh - 100px);
	left: calc(50vw - 200px);
	border-radius: 5px;
	box-shadow: 1px 2px 6px 1px rgba (0, 0, 0, 0.35);
	background-color: white;
	padding: 20px;
	display: flex;
	flex-direction: column;
	z-index: 2;
}

.cancelEditTestAlertTopRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-family: 'RionaSans-Bold';
	color: #26273a;
	margin-bottom: 12px;
}

.cancelEditTestAlertText {
	font-size: 12px;
	color: #7a8292;
}

.errorDropdown > div.el-input > input.el-input__inner,
.errorInput {
	border: 1px solid #fb3a2e !important;
	color: #ff5a4b !important;
	background-color: rgba(254, 145, 137, 0.5);
}

.fieldErrMsgTestCreate {
	position: absolute;
	bottom: -26px;
	left: 0px;
	font-size: 10px;
	color: #e74c3c;
}

.fieldErrMsgRadio {
	position: absolute;
	bottom: -16px;
}
